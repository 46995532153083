import { globalFetcher } from "components/Utils/CRUDUtils";

export class TokenUtils {
  // Token methods
  public static async getAuthHeader(): Promise<HeadersInit> {
    const accessToken = await globalFetcher.getTokenSilently();

    return {
      Authorization: `Bearer ${accessToken}`,
    };
  }
}
