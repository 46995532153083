import React from "react";
import clsx from "clsx";
import { HiddenProps, Hidden, makeStyles } from "@material-ui/core";
import entityTableRowsetStyle from "../Sections/entityTableRowsetStyle";
import { px } from "components/Utils/LayoutUtils";

export type CellContainerType = "td" | "div";

export interface EntityTableUnifiedTableCellProps {
  children: JSX.Element | Array<JSX.Element>;
  hide?: HiddenProps;
  align?: "inherit" | "left" | "center" | "right" | "justify";
  zeroPadding?: boolean;
  className?: string;
  noWrapColumnContent?: boolean;
  width?: number | string;
  widthOnCard?: number;
  cellContainerElement?: CellContainerType;
}

const useStyles = makeStyles(entityTableRowsetStyle, { name: "EntityTableUnifiedTableCell" });

export function EntityTableUnifiedTableCell(props: EntityTableUnifiedTableCellProps) {
  const classes = useStyles({});
  const cellContainerElement = props.cellContainerElement || "td";

  if (cellContainerElement === "td") {
    return (
      <Hidden {...props.hide}>
        <td
          // align={props.align} TODO: support horizontal alignment
          className={clsx(
            props.className,
            classes.regularTableCell,
            classes.defaultCellFont,
            props.noWrapColumnContent && classes.noWrapColumnContent,
            props.zeroPadding ? classes.zeroPadding : classes.mdSpecCellPaddingForMuiPaddingNone
          )}
        >
          {props.children}
        </td>
      </Hidden>
    );
  } else {
    let cellWidth;

    if (props.width || props.width >= 0) {
      cellWidth = typeof props.width === "number" ? px(props.width) : props.width;
    }

    return (
      <Hidden {...props.hide}>
        <div className="cell_content" style={{ width: cellWidth }}>
          {props.children}
        </div>
      </Hidden>
    );
  }
}
