import { createMuiTheme, ThemeOptions } from "@material-ui/core/styles";
import { orange } from "@material-ui/core/colors";

// # 307fbe - Primary Denim (Blue) - Used as the primary foundations for any Farmbot marketing collateral
// # 5dbde2 - Secondary Viking (Light Blue) - Used for secondary detail
// # 414959 - River Bed (Navy) - Text
// # 2B877C - Lochinvar (Green)  - Highlights important details and to create depth in designs

export type SpacingNameType = "regularSpacing" | "cardSpacing" | "buttonSpacing" | "noSpacing";

export interface CustomProps {
  customProps: Record<SpacingNameType, any>;
}

export type CustomThemeColorsType = { customColors: { modifiedField: string } };
export type CustomThemeType = ThemeOptions & CustomThemeColorsType & CustomProps;

export const globalMuiTheme = createMuiTheme(
  {
    palette: {
      primary: {
        // light: will be calculated from palette.primary.main,
        main: "#307FBE",
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
      warning: {
        main: orange[700], // #F57C00
      },
      text: {
        primary: "#414959", // blueGrey[900], // '#263238',
        secondary: "#414959e6", // blueGrey[600] // #546E7A
      },
    },
  },
  {
    customProps: {
      regularSpacing: 16,
      cardSpacing: 24,
      buttonSpacing: 10,
      noSpacing: 0,
    },
    customColors: {
      modifiedField: "#e3f2fd", // shade 50 of main color 307fbe
    },
  }
);
