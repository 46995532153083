import React from "react";

import { RouteComponentProps } from "react-router";
import { SensorGraphDataView } from "./SensorGraphDataView";
import { Paper } from "@material-ui/core";

type SensorGraphPageProps = RouteComponentProps<{ sensorId: number }>

function SensorGraphPage(props: SensorGraphPageProps) {
  return (
    <Paper style={{ boxSizing: "border-box", height: "100%" }}>
      <SensorGraphDataView title="Sensor Graph" sensorId={props.match.params.sensorId} />
    </Paper>
  );
}

export default SensorGraphPage;
