import { StyleRulesCallback, Theme } from "@material-ui/core";

export const activeAlertCardStyle: StyleRulesCallback<Theme, {}> = (theme) => ({
  rootDesktop: {
    height: "173px",
    paddingBottom: "24px",
  },
  rootMobile: {
    height: "203px",
    paddingBottom: "24px",
  },
  paper: {
    height: "100%",
  },
  // paperContent: {
  //   padding: '24px',
  //   // display: 'flex'
  // },
  alertInformation: {
    flexGrow: 1,
  },
  title: {
    height: "40px",
    display: "table-cell",
    verticalAlign: "middle",
  },
  acknowledgeButton: {
    height: "36px",
    paddingTop: 0,
    paddingBottom: 0,
    backgroundColor: theme.palette.warning.main,
    color: "white",
  },
  messageStyle: {
    fontWeight: 400,
    color: theme.palette.text.primary,
  },
});
