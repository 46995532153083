import React, { useMemo, useEffect, useState } from "react";
import { makeStyles, Paper } from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";
import { ColumnLayout } from "layouts/ColumnLayout";
import { ObjectIdType } from "components/Utils/Object.d";
import { FlexSpaceFiller } from "layouts/FlexSpaceFiller";
import { ColumnWrapper } from "layouts/ColumnWrapper";
import { ENTITY_LIB } from "components/Utils/CRUDEntityList";
import { TabbedView } from "layouts/TabbedView";
import { EntityNameScreenTitle } from "../../../layouts/EntityNameScreenTitle";
import { MainScreenContentHeader } from "layouts/MainScreenContentHeader";
import { useMediaQueryMatchDown } from "components/Utils/LayoutUtils";
import { ConditionalRender } from "layouts/ConditionalRender";
import { ScreenSecondaryActionButtonFace } from "components/Buttons/ScreenSecondaryActionButtonFace";
import { ActionButtonHandlerComponent } from "components/Buttons/ActionButtonHandlerComponent";
import { getAttributeByPath } from "components/Crud/EntityUtils";
import { EDIT_ACTION } from "components/Crud/Entity.const";
import { useVersion } from "components/Utils/CustomHooks";
import { UserParams } from "views/User/UserParams.d";
import { monitorSummaryStyles } from "./MonitorSummaryContent.style";
import { MonitorEditForm } from "./MonitorEditForm";
import { MONITOR_API_URL } from "./Monitor.const";
import { PacketLogList } from "./PacketLog/PacketLogList";
import { MonitorConfigurationList } from "./Configurations/MonitorConfigurationList";
import { SensorGraphDataView } from "../Sensor/SensorGraphDataView";
import { DataSourceParamsType } from "components/Crud/DataProvider/DataProviderCommon.d";
import { SENSOR_API_URL } from "../Sensor/Sensor.const";
import { SORT_ASC, UnifiedDataRequestType } from "components/Utils/CRUDUtils.d";
import { useDatasetManager } from "components/Crud/DataProvider/DatasetManager";
import { QueryEventAction } from "components/Crud/DataProvider/DataQuery.d";
import clsx from "clsx";
import { isNonEmptyArray } from "components/Utils/MiscUtils";
import { ActionButtonConfig } from "components/Buttons/Buttons.d";

export interface AssetSummaryScreenProps {
  monitorId: ObjectIdType;
  userData: UserParams;
  initialTabId?: number;
}

export const VIEW_LOCATION_ACTION = "viewLocation";
export const NAVIGATE_TO_ALL_ASSET_ALERTS_ACTION = "navigateToAllAlerts";

const useStyles = makeStyles(monitorSummaryStyles, { name: "MonitorSummaryContent" });

export function MonitorSummaryContent(props: AssetSummaryScreenProps) {
  const [version, increaseVersion] = useVersion();

  const classes = useStyles({});

  const isSmallScreen = useMediaQueryMatchDown("sm");

  const settingsButtonConfig: ActionButtonConfig = {
    labelElement: (
      <ScreenSecondaryActionButtonFace>
        <SettingsIcon fontSize="small" />
        <ConditionalRender condition={!isSmallScreen}>
          <span style={{ paddingLeft: "6px" }}>SETTINGS</span>
        </ConditionalRender>
      </ScreenSecondaryActionButtonFace>
    ),
    doNotGroup: true,
    name: "monitorDetails",
    onClick: {
      type: "dialog",
      dialogId: MonitorEditForm,
      onCloseCallbacks: [increaseVersion],
      extraPropsObject: { title: "Monitor Details" },
    },
    actionCode: EDIT_ACTION,
    actionObject: ENTITY_LIB.ASSET_INVENTORY_ITEM,
  };

  // SENSORS
  const dataSource: DataSourceParamsType = {
    apiUrl: `${SENSOR_API_URL}/by_monitor/${props.monitorId}`,
    // customUrlRequestFragment: `filter=triggerId::eq::${props.id}`
  };

  const initialRequest: UnifiedDataRequestType = useMemo(
    () => ({
      // fieldList: [ ],
      filter: {
        baseFilters: [
          // filterBy('triggerId', '=', props.id)
        ],
      },
      isPaginationEnabled: true,
      rowsPerPage: 10,
      pageNum: 0,
      sortByColumn: "id",
      sortDirection: SORT_ASC,
    }),
    []
  );

  const [dataSet, dispatchRequest] = useDatasetManager(dataSource);

  // INITIAL LOAD ON MOUNT
  useEffect(() => {
    dispatchRequest({
      action: QueryEventAction.REQUEST_DATA,
      payload: initialRequest,
      originator: "Initial Sensor Load on Mount",
    });
  }, [dispatchRequest, initialRequest]);

  const [activeSensorId, setActiveSensorId] = useState(undefined);

  const [initialLoadSensorSet, setInitialLoadSensorSet] = useState(false);

  const sensorsData = dataSet.dataArray;
  useEffect(() => {
    if (
      isNonEmptyArray(sensorsData) &&
      initialLoadSensorSet === false /*  && !isSpecified(activeSensorId) */
    ) {
      setActiveSensorId(sensorsData[0].id);
      setInitialLoadSensorSet(true);
    }
  }, [sensorsData, initialLoadSensorSet, activeSensorId]);

  return (
    <div className={classes.root}>
      <MainScreenContentHeader subTitle={"MONITOR"}>
        <EntityNameScreenTitle
          id={props.monitorId}
          entityName={ENTITY_LIB.ASSET_INVENTORY_ITEM}
          apiUrl={MONITOR_API_URL}
          externalTriggerValues={[version]}
        />
        <FlexSpaceFiller />
        <div style={isSmallScreen ? { flexGrow: 1 } : undefined}>
          <ColumnLayout spacingName="buttonSpacing" equalWidth={isSmallScreen ? true : undefined}>
            <ColumnWrapper>
              <ActionButtonHandlerComponent
                buttonConfig={settingsButtonConfig}
                id={props.monitorId}
                object={null}
              />
            </ColumnWrapper>
          </ColumnLayout>
        </div>
      </MainScreenContentHeader>
      <div className={classes.contentRoot}>
        <TabbedView
          tabLabels={["SENSORS", "CONFIGURATION", "PACKETS" /* , 'GRAPH' */]}
          initialTabId={props.initialTabId}
          render={[
            (switchToTab: (tabId: number) => void) => {
              return (
                <div className={classes.tabbedPanel}>
                  <div
                    style={{ height: "100%", display: "flex", flexDirection: "row", paddingBottom: "20px" }}
                  >
                    <div className={classes.sensorSubList}>
                      <Paper>
                        <div className={classes.sensorListHeader}>
                          <b>Sensors</b>
                        </div>
                        {dataSet.dataArray.map((item, index) => {
                          const sensorId = item.id;
                          const sampleTypeName = getAttributeByPath(item, "sensorLib.sampleType.name");
                          const serialNumber = getAttributeByPath(item, "device.serialNumber") || "";

                          return (
                            <div
                              key={index}
                              className={clsx(
                                classes.sensorListItem,
                                sensorId === activeSensorId && classes.selectedItem
                              )}
                              onClick={() => setActiveSensorId(sensorId)}
                            >
                              <div>
                                <div>{`${sensorId} ${sampleTypeName}`}</div>
                                <div>{`${serialNumber}`}</div>
                              </div>
                            </div>
                          );
                        })}
                      </Paper>
                    </div>
                    <div
                      style={{
                        marginLeft: "20px",
                        flexGrow: 1,
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Paper style={{ flexGrow: 1 }}>
                        <SensorGraphDataView title="Sensor Graph" sensorId={activeSensorId} />
                      </Paper>
                    </div>
                  </div>
                </div>
              );
            },
            (switchToTab: (tabId: number) => void) => {
              return (
                <div className={classes.tabbedPanel}>
                  <MonitorConfigurationList userData={props.userData} monitorId={props.monitorId} />
                </div>
              );
            },
            (switchToTab: (tabId: number) => void) => {
              return (
                <div className={classes.tabbedPanel}>
                  <PacketLogList userData={props.userData} monitorId={props.monitorId} />
                </div>
              );
            },
          ]}
        />
      </div>
    </div>
  );
}
