import React from "react";
import { ObjectIdType } from "components/Utils/Object.d";
import { PortletCard } from "components/Cards/PortletCard";
import { UnifiedDataRequestType, SORT_DESC } from "components/Utils/CRUDUtils.d";
import { TableColumnParameters } from "components/Crud/Table/EntityTable.d";
import { TableCellRendererProps } from "components/Crud/Table/CellRenderers/TableCellRenderer.d";
import { DimmedAlertRenderer } from "components/Crud/Table/CellRenderers/AssetCardCells/DimmedAlertRenderer";
import { DataSourceParamsType } from "components/Crud/DataProvider/DataProviderCommon.d";
import { API_LIB } from "components/Utils/API_LIB";
import { filterBy } from "components/Utils/MiscUtils";
import { CrudQueryTableBinding } from "components/Crud/Table/CrudQueryTableBinding";
import { UserParams } from "views/User/UserParams.d";
import { ActionButtonConfig } from "components/Buttons/Buttons.d";

export interface AssetAlertHistorySummaryPortletProps {
  assetId: ObjectIdType;
  portletAction: Array<ActionButtonConfig>;
  userData: UserParams;
}

export function AssetAlertHistorySummaryPortlet(props: AssetAlertHistorySummaryPortletProps) {
  const createColumns = (): TableColumnParameters[] => {
    const columns: TableColumnParameters[] = [
      {
        sourceName: ["message", "date"],
        label: "",
        valueTransformationFunction: (rendererProps: TableCellRendererProps) => (
          <DimmedAlertRenderer {...rendererProps} />
        ),
      },
    ];

    return columns;
  };

  const dataSource: DataSourceParamsType = {
    apiUrl: API_LIB.EVENT,
  };

  const initialRequest: UnifiedDataRequestType = {
    sortByColumn: "date", //'event.date',
    sortDirection: SORT_DESC,
    // fieldList: // ['event.message', 'event.date'],
    filter: {
      baseFilters: [
        filterBy("assetId", "=", props.assetId),
        // filterBy('userId', '=', props.userData.userId),
      ],
    },
    isPaginationEnabled: true,
    rowsPerPage: 5,
    pageNum: 0,
  };

  return (
    <PortletCard title="Alert History" portletAction={props.portletAction}>
      <CrudQueryTableBinding
        externalTriggerValues={[props.assetId]}
        dataSource={dataSource}
        intialQuery={initialRequest}
        tableColumns={createColumns()}
        // onRowClickFunction={props.onRowClickFunction}
        actionsAllowed={[]}
        // highlightedRowsPrimaryKeys={props.activeRowPrimaryKeys}
        disableFilters={true}
        disablePaginationSection={true}
        disableTableColumns={true}
        paperless={true}
        contentToDisplayWhenNoData={<div style={{ padding: "24px" }}>None</div>}
      />
    </PortletCard>
  );
}
