import { StyleRulesCallback, Theme } from "@material-ui/core";

export const graphComponentStyle: StyleRulesCallback<Theme, {}> = (theme) => ({
  graphSubGroupStyle: {
    boxSizing: "border-box",
    marginBottom: "16px",
  },
  groupTitleItem: {
    boxSizing: "border-box",
    height: "24px", // Note different value from the font on the side bar
  },
  invisible: {
    display: "none",
  },
});
