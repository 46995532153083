import { FieldConfiguration, MenuItemType } from "components/Crud/Form/Fields/FieldConfiguration.d";
import { ObjectIdType } from "components/Utils/Object.d";

export const groupEditFields = (
  currentEnterpriseId: ObjectIdType,
  sampleTypeDictionary: Array<MenuItemType>,
  assetTypeDictionary: Array<MenuItemType>,
  siteDictionary: Array<MenuItemType>
): Array<FieldConfiguration> => [
  {
    label: "Group Name",
    fieldName: "name",
    type: "text",
    validation: {
      required: true,
    },
  },
  {
    label: "Comments",
    fieldName: "comments",
    type: "text",
  },
  {
    label: "Enterprise",
    fieldName: "enterpriseId",
    type: "number",
    hideIf: () => true,
    defaultValue: currentEnterpriseId,
    validation: {
      required: true,
    },
  },
  {
    label: "Sample Types",
    fieldName: "sampleTypes",
    type: "select-multiple",
    fieldParams: {
      options: sampleTypeDictionary || [],
    },
  },
  {
    label: "Asset Type",
    fieldName: "assetTypes",
    type: "select-multiple",
    fieldParams: {
      options: assetTypeDictionary || [],
    },
  },
  {
    label: "Site",
    fieldName: "sites",
    type: "select-multiple",
    fieldParams: {
      options: siteDictionary || [],
    },
  },
];
