import React from "react";
import { makeStyles } from "@material-ui/core";
import { mainScreenContentHeaderStyle } from "./mainScreenContentHeaderStyle";
import { CardSubTitle } from "components/Elements/Typography/CardSubTitle";
import { RenderIfSpecified } from "./RenderIfSpecified";
import { useMediaQueryMatchDown } from "components/Utils/LayoutUtils";
import clsx from "clsx";

export interface MainScreenContentHeaderProps {
  subTitle?: string;
  children: JSX.Element | Array<JSX.Element>;
  subChildren?: JSX.Element;
}

const useStyles = makeStyles(mainScreenContentHeaderStyle, { name: "MainScreenContentHeader" });

export function MainScreenContentHeader(props: MainScreenContentHeaderProps) {
  const classes = useStyles({});

  const isSmallScreen = useMediaQueryMatchDown("sm");

  return (
    <div className={classes.root}>
      <RenderIfSpecified value={props.subTitle}>
        {(subTitle) => (
          <div className={classes.subTitleSection}>
            <CardSubTitle>{subTitle}</CardSubTitle>
          </div>
        )}
      </RenderIfSpecified>
      <div
        className={clsx(
          classes.contentRow,
          isSmallScreen ? classes.contentRowSmallScreen : classes.contentRowLargeScreen
        )}
      >
        {props.children}
      </div>
      <div className={classes.subChildren}>{props.subChildren}</div>
    </div>
  );
}
