interface TextFieldConfiguration {
  rows?: number;
}

export type FieldIsRequiredFlagFunctionType = (
  value: string | number,
  obj: Record<string, any>,
  editType: string
) => boolean;
export type FieldIsDisabledFlagFunctionType = (value: string | any, obj: Record<string, any>) => boolean;
export type HideIfFlagFunctionType = (
  value: string,
  obj: Record<string, any>,
  editType: string,
  initialValues: Record<string, any>
) => boolean;
export type ValidationFunctionType = (value: string | number, obj: Record<string, any>) => boolean;
export type DefaultValueFunction = (
  obj: Record<string, any>,
  editType: string,
  initialValues: Record<string, any>
) => any;

export interface ValidationParams {
  required?: boolean | FieldIsRequiredFlagFunctionType;
  minLength?: number;
  maxLength?: number;
  regExp?: RegExp;
  validationFunction?: ValidationFunctionType;
  errorMessage?: string;
}

// TODO: Consider revising this field, because it mixes HTML\HTML5 types with FarmBot types
export type FieldConfigType =
  | "boolean"
  | "password"
  | "email"
  | "select"
  | "text"
  | "number"
  | "select-multiple"
  | "hidden"
  | "select-async"
  | "json"
  | "date"
  | "date-time";
export type FieldParamsType =
  | SelectAsyncConfiguration
  | SelectConfiguration
  | TextFieldConfiguration
  | MultipleSelectConfiguration
  | CheckboxConfiguration;

export interface FieldConfiguration {
  placeholder?: string;
  label?: string;
  fieldName: string; // name is too ambigous, key is react property
  dateTimeFormat?: string; //TODO: move under field params
  type: FieldConfigType;
  defaultValue?: string | number | boolean | DefaultValueFunction;
  disabled?: boolean | FieldIsDisabledFlagFunctionType;
  fieldParams?: FieldParamsType;
  hideIf?: HideIfFlagFunctionType;
  validation?: ValidationParams;
  groupName?: string;
  dependsOnField?: Array<string>;
}

export interface MultipleSelectConfiguration {
  options?: Array<MenuItemType>;
}

export interface CheckboxConfiguration {
  color?: string;
}

export interface SelectConfiguration {
  renderEmptyValueAs?: string;
  options?: Array<MenuItemType>;
}

export interface MenuItemType {
  code: string | number;
  label: string;
  emphasize?: boolean;
  hideForInput?: boolean;
}

export type FilterUrlFunctionType = (obj: Record<string, any>) => string;
export type DisableFetchingFunctionType = (obj: Record<string, any>) => boolean;

export type FetchResultTransformerType = (
  input: Record<string, any> | Array<Record<string, any>>
) => Array<Record<string, any>>;

// TODO: NWP-64 associate definition API with (select-asynch type AND fieldParams SelectConfiguration)
export interface SelectAsyncConfiguration {
  optionDefinitionEndpoint: string;
  optionCodeField: string;
  optionLabelField: string;
  filterUrl?: string | FilterUrlFunctionType;
  renderEmptyValueAs?: string;
  extraStaticValues?: MenuItemType[];
  autoComplete?: boolean;
  placeholder?: string; // only works with autocomplete
  fetchResultTransformer?: FetchResultTransformerType;
  disableFetching?: boolean | DisableFetchingFunctionType;
}

export const NONE_SELECT_CODE = "";
export const NONE_SELECT_LABEL = "None";

export type Input = "edit" | "show" | "create" | "delete" | "list";

export type INPUT_VARIANT_TYPE = "outlined";
export const INPUT_VARIANT: INPUT_VARIANT_TYPE = "outlined";
