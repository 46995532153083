import React from "react";
import { makeStyles } from "@material-ui/core";
import { screenTitleStyle } from "./screenTitleStyle";
import { useMediaQueryMatchDown } from "components/Utils/LayoutUtils";
import clsx from "clsx";

export interface ScreenTitleProps {
  children: string;
}

const useStyles = makeStyles(screenTitleStyle, { name: "ScreenTitle" });

export function ScreenTitle(props: ScreenTitleProps) {
  const classes = useStyles({});

  // TODO: change to span, double check impacts
  const isSmallScreen = useMediaQueryMatchDown("sm");

  return (
    <span className={clsx(classes.root, isSmallScreen ? classes.smallScreen : classes.largeScreen)}>
      {props.children}
    </span>
  );
}
