import { StyleRulesCallback, Theme } from "@material-ui/core";

export const materialUIPickersStyle: StyleRulesCallback<Theme, {}> = (theme) => ({
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  coolStyle: {
    padding: 0,
    backgroundColor: "red",
  },
  dateBetween: {
    backgroundColor: theme.palette.grey[300],
  },
  singleDayRange: {
    // Extension point if we need to somehow indicate that range is a single day
  },
  startDateBackground: {
    backgroundColor: theme.palette.grey[300],
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
  },
  startDate: {
    backgroundColor: theme.palette.grey[400],
    borderRadius: "50%",
  },
  endDateBackground: {
    backgroundColor: theme.palette.grey[300],
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
  },
  endDate: {
    backgroundColor: theme.palette.grey[400],
    borderRadius: "50%",
  },
});
