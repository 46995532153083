import React from "react";
import { graphComponentHeaderContainerStyle } from "./graphComponentHeaderContainerStyle";
import { makeStyles } from "@material-ui/core";

export interface GraphComponentHeaderContainerProps {
  children: JSX.Element | Array<JSX.Element>;
}

const useStyles = makeStyles(graphComponentHeaderContainerStyle, { name: "GraphComponentHeaderContainer" });

export function GraphComponentHeaderContainer(props: GraphComponentHeaderContainerProps) {
  const classes = useStyles({});

  return <div className={classes.root}>{props.children}</div>;
}
