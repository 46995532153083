import React, { useEffect, useMemo } from "react";
import { DataSourceParamsType } from "../../components/Crud/DataProvider/DataProviderCommon.d";
import { useDatasetManager } from "../../components/Crud/DataProvider/DatasetManager";
import { QueryEventAction } from "../../components/Crud/DataProvider/DataQuery.d";
import { isSpecified, filterBy } from "components/Utils/MiscUtils";
import { SORT_ASC, UnifiedDataRequestType } from "components/Utils/CRUDUtils.d";
import { FetchlessFormikEntityForm } from "../../components/Crud/Form/FetchlessFormikEntityForm";
import { CRUD_DISPATCHER } from "../../components/Crud/DataProvider/DataCrud";
import { EventDispatchersType } from "../../components/Crud/Table/StatelessTable.d";
import { generateCreateButtonConfig } from "components/Buttons/ButtonsCommon";
import { ENTITY_LIB } from "components/Utils/CRUDEntityList";
import { TriggerCard } from "./TriggerCard";
import { ObjectIdType } from "components/Utils/Object.d";
import { ActionButtonHandlerComponent } from "components/Buttons/ActionButtonHandlerComponent";
import SettingsIcon from "@material-ui/icons/Settings";
import ActionDelete from "@material-ui/icons/Delete";
import { deleteAttributeByPath } from "components/Crud/EntityUtils";
import { EDIT_ACTION, DELETE_ACTION } from "components/Crud/Entity.const";
import { CrudEventAction } from "components/Crud/DataProvider/DataCrud.d";
import { API_LIB } from "components/Utils/API_LIB";
import { useTheme } from "@material-ui/core";
import { usePopupOperationController } from "popups/usePopupOperationController";
import {
  triggerEditFormFieldsSpec,
  FAKE_UNITS_FIELD_PATH_TO_DELETE,
  FAKE_UNITS_FIELD,
} from "./Trigger.formFields";
import RefreshIcon from "@material-ui/icons/Refresh";
import IconButton from "@material-ui/core/IconButton";
import { FlexSpaceFiller } from "layouts/FlexSpaceFiller";
import { ScreenSectionTitle } from "components/Elements/Typography/ScreenSectionTitle";
import { FieldConfiguration } from "components/Crud/Form/Fields/FieldConfiguration.d";
import { TriggerFormLayout } from "./Trigger.formLayout";
import produce from "immer";
import { OnClickDialog, OnClickDispatchEvent } from "components/Buttons/Buttons.d";

export interface TriggerListProps {
  assetId: ObjectIdType;
}

export function TriggerList(props: TriggerListProps) {
  const dataSource: DataSourceParamsType = {
    apiUrl: API_LIB.TRIGGER,
    //    customUrlRequestFragment: `filter=assetId::eq::${props.assetId}`
  };

  const initialRequest: UnifiedDataRequestType = useMemo(
    () => ({
      fieldList: [
        "assetId",
        "comments",
        "eventLevel",
        "id",
        "isEnabled",
        "message",
        "name",
        "templateId",
        "timeBeforeReTrigger",
        "template.name",
        "template.message",
        "template.eventLevel",
        // conditions field
        "conditions.value",
        "conditions.triggerId",
        "conditions.samplePoint.id",
        "conditions.samplePoint.sampleType.name",
        "conditions.sampleField.id",
        "conditions.sampleField.name",
        FAKE_UNITS_FIELD,
        "conditions.valueType",
        "conditions.params[thresholdSensitivity]",
        "conditions.params[window]",
        "conditions.params[duration]",
        "conditions.conditionType",
        "conditions.useForecast",
      ],
      filter: {
        baseFilters: [filterBy("assetId", "=", props.assetId)],
      },
      isPaginationEnabled: true,
      rowsPerPage: 10,
      pageNum: 0,
      sortByColumn: "name",
      sortDirection: SORT_ASC,
    }),
    [props.assetId]
  );

  const [dataset, dispatchRequest] = useDatasetManager(dataSource, { notifyOnMutationSuccess: false });
  const theme = useTheme();

  const triggerEditFormFields = triggerEditFormFieldsSpec(props.assetId, theme);
  // TODO: uncomment when it is needed
  // let templateBasedTriggerEditFormFields = templateBasedTriggerEditFormFieldsSpec(props.assetId, theme);

  // Bits copied from DataCrudEntityFormBinding
  const eventDispatchersForForm: EventDispatchersType = {};
  eventDispatchersForForm[CRUD_DISPATCHER] = dispatchRequest;

  const removeFakeUnitsField = (object: Record<string, any>, editType: string) => {
    let result = object;

    result = produce(result, (draftResult) => {
      // delete draftResult[FAKE_UNITS_FIELD];
      deleteAttributeByPath(draftResult, FAKE_UNITS_FIELD_PATH_TO_DELETE);
    });

    return result;
  };

  const TriggerEditForm = (formFields: FieldConfiguration[]) => {
    return (props: any) => (
      <FetchlessFormikEntityForm
        // tempOperationResult={operationResult}
        // triggerCloseOnSuccesOf={[CrudEventAction.CREATE, CrudEventAction.UPDATE, CrudEventAction.DELETE]}
        formData={props.dataObject}
        id={props.id}
        action={props.action}
        title={props.title}
        isDataLoading={false} //{crudStatus.state === DataCrudOperationStatus.EXECUTING}
        fieldConfigurations={formFields}
        eventDispatchers={eventDispatchersForForm}
        onCloseAdditionalActions={props.onCloseAdditionalActions}
        showCloseButton={props.showCloseButton}
        userData={props.userData}
        currentEnterpriseId={props.currentEnterpriseId}
        layoutComponent={formFields === triggerEditFormFields ? TriggerFormLayout : undefined}
        conditionalFieldConfiguration={props.conditionalFieldConfiguration}
        formDataPreSubmitProcessor={removeFakeUnitsField}
        doNotPreFetchData={true} //{props.doNotPreFetchData}
        warning={props.warning}
      />
    );
  };

  // let warningValue = dataset.lastOperationResult && dataset.lastOperationResult.operationType;

  const createTriggerButtonConfig = generateCreateButtonConfig(
    ENTITY_LIB.TRIGGER,
    "Add Trigger",
    TriggerEditForm(triggerEditFormFields),
    [],
    {
      title: "Add Trigger",
      // warning: warningValue
    }
  );

  // TODO: uncomment when it is needed
  // let createTemplateBasedTriggerButtonConfig = generateCreateButtonConfig(
  //   ENTITY_LIB.TRIGGER,
  //   'Add Template Based Trigger',
  //   TriggerEditForm(templateBasedTriggerEditFormFields),
  //   [],
  //   {
  //     title: 'Add Template Based Trigger',
  //     // warning: warningValue
  //   }
  // );

  const editTriggerButtonConfig = {
    actionCode: EDIT_ACTION,
    actionObject: ENTITY_LIB.TRIGGER,
    name: "edit",
    labelElement: <SettingsIcon fontSize="small" />,
    onClick: {
      type: "dialog",
      dialogId: TriggerEditForm(triggerEditFormFields),
      onCloseCallbacks: [] as Array<() => void>,
      extraPropsObject: {
        title: "Edit Trigger",
        // warning: warningValue,
      },
    } as OnClickDialog,
    doNotGroup: true,
  };

  // TODO: uncomment when it is needed
  // let editTemplateBasedTriggerButtonConfig = {
  //   actionCode: EDIT_ACTION,
  //   actionObject: ENTITY_LIB.TRIGGER,
  //   name: 'edit',
  //   labelElement: <SettingsIcon fontSize="small"/>,
  //   onClick: {
  //     type: 'dialog',
  //     dialogId: TriggerEditForm(templateBasedTriggerEditFormFields),
  //     onCloseCallbacks: [] as Array<() => void>,
  //     extraPropsObject: {
  //       title: 'Trigger Edit',
  //       // warning: warningValue,
  //     }
  //   } as OnClickDialog,
  //   doNotGroup: true
  // };

  const deleteTriggerButtonConfig = {
    actionObject: ENTITY_LIB.TRIGGER,
    actionCode: DELETE_ACTION,
    name: "delete",
    labelElement: <ActionDelete fontSize="small" />,
    onClick: {
      type: "dispatchEvent",
      dispatcherName: CRUD_DISPATCHER,
      action: CrudEventAction.DELETE,
    } as OnClickDispatchEvent,
    confirm: { message: "Are you sure you want to delete this trigger?", dialogButton: "Delete" },
  };

  const operationResult = dataset.lastOperationResult;
  usePopupOperationController(operationResult);

  // const { updatePopupParams } = useContext(GlobalPopupContext);
  // useEffect(() => {
  //   updatePopupParams({
  //     extraPropsObject: {
  //       title: 'Modified Edit Asset',
  //       warning: warningValue
  //     }
  //   });
  // }, [updatePopupParams, warningValue]);

  useEffect(() => {
    dispatchRequest({
      action: QueryEventAction.REQUEST_DATA,
      payload: initialRequest,
      originator: "TemporaryTriggerList - load on mount",
    });
  }, [dispatchRequest, initialRequest]);

  return (
    <div>
      <div style={{ display: "flex" }}>
        <ScreenSectionTitle>Triggers</ScreenSectionTitle>
        <FlexSpaceFiller />
        <IconButton
          onClick={() =>
            dispatchRequest({
              action: QueryEventAction.REQUEST_DATA,
              payload: initialRequest,
              originator: "Click Request Button",
            })
          }
        >
          <RefreshIcon />
        </IconButton>
        {/* TODO: uncomment when it is needed
        <ActionButtonHandlerComponent
          buttonConfig={createTemplateBasedTriggerButtonConfig}
          id={null}
          object={null}
          eventDispatchers={eventDispatchersForForm}
        /> */}
        <ActionButtonHandlerComponent
          buttonConfig={createTriggerButtonConfig}
          id={null}
          object={null}
          eventDispatchers={eventDispatchersForForm}
        />
      </div>
      <p style={{ color: "red" }}>
        {dataset.lastOperationResult &&
        !dataset.lastOperationResult.isSuccess &&
        !isSpecified(dataset.lastOperationResult.relatedId)
          ? dataset.lastOperationResult.message
          : ""}
      </p>
      {dataset.dataArray.map((arrayItem: any, index: number) => {
        // TODO: uncomment when templates are neded
        // let editButtonConfig = isSpecified(arrayItem.templateId) ? editTemplateBasedTriggerButtonConfig : editTriggerButtonConfig
        const editButtonConfig = editTriggerButtonConfig;

        return (
          <TriggerCard
            key={index}
            assetId={props.assetId}
            actionsSet={[editButtonConfig, deleteTriggerButtonConfig]}
            id={arrayItem.id}
            name={arrayItem.name}
            dataObject={arrayItem}
            errorMessage={
              dataset.lastOperationResult &&
              dataset.lastOperationResult &&
              dataset.lastOperationResult.relatedId === arrayItem.id
                ? dataset.lastOperationResult.message
                : ""
            }
            eventDispatchers={eventDispatchersForForm}
            triggerEventDispatcher={dispatchRequest}
          />
        );
      })}
    </div>
  );
}

// Legacy Config:
/* <button 
          onClick={() => {
            dispatchRequest({
              action: CrudEventAction.CREATE, 
              payload: {
                name: 'my first trigger',
                comments: 'some comments',
                message: 'Somethings wrong',
                assetId: props.assetId
                // name: 'New TESTO Asset',
                // siteId: 2,
                // assetTypeId: 1
              },
              originator: 'Click Create Button'
            })
          }}
        >
          Add
        </button>   */
/*       <button 
        onClick={() => {
          dispatchRequest({
            action: CrudEventAction.UPDATE, 
            payload: {
              id: props.id, 
              name: 'New Name For update',
              // siteId: 2,
              // assetTypeId: 1
            },
            originator: 'Click Update Button'
          })
        }}
      >
        U
      </button> */
/*       <button 
        onClick={() => {
          dispatchRequest({
            action: CrudEventAction.DELETE, 
            payload: {id: props.id},
            originator: 'Click Delete Button'
          })
        }}
      >
        X
      </button> */
