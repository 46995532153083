import { ObjectWithKnownFieldsType, ObjectIdType } from "components/Utils/Object.d";
import { SampleTypeEnum } from "components/Dictionaries/Dictionaries.d";

export interface RequestedDataRangeType {
  displayedRangeStartDate: Date;
  displayedRangeEndDate: Date;
  requestedRangeStartDate: Date;
  requestedRangeEndDate: Date;
}

export enum IntervalOptions {
  CUSTOM_INTERVAL = "CUSTOM_INTERVAL",
  ONE_DAY = "ONE_DAY",
  TWO_DAYS = "TWO_DAYS",
  TWO_WEEKS = "TWO_WEEKS",
  ONE_MONTH = "ONE_MONTH",
}

export type AlphaNumericField = string | number;

export type Sample = AlphaNumericField[];

export interface FieldDescription {
  name: string;
  units?: string;
}

export type GraphDataProviderDataType = ObjectWithKnownFieldsType<{
  id: ObjectIdType;
  name: string;
  sampleTypeId: SampleTypeEnum;
}>;

export interface SampleEndpointResponse {
  data: Array<SampleEndpointResponseSegment>;
}

export interface SampleEndpointResponseSegment {
  analysisType: AnalysisType;
  samples: Sample[];
  fields: FieldDescription[];
  isValueForecasted?: boolean;
}

export enum SampleDateType {
  INSTANT = 1,
  TIME_INTERVAL = 2,
}

export enum AnalysisType {
  UNDEFINED = -1,
  SIMPLE = 1,
  FORECAST = 2,
  MAJOR_TREND = 3,
}

export interface ChartSegmentDescriptionType {
  analysisType: AnalysisType;
}

export interface GraphPointValueType {
  x: any;
  y: any;
}

export interface DataProvider {
  id: number;
  name: string;
}

export interface GraphDataRequestParametersType {
  definedInterval: IntervalOptions;
  requestedRange: RequestedDataRangeType;
}

export interface DataSourceDescriptorType {
  id: ObjectIdType;
  name: string;
  color?: string;
  sampleTypeName: string;
  sampleTypeId: SampleTypeEnum;
  dateType: SampleDateType;
  units: string;
}

export enum ComponentModeType {
  GRAPH = "graph",
  TABLE = "table",
}

export type TimeUnit = "day" | "hour";
export type TypeLegendPosition = "none" | "top" | "bottom" | "left" | "right";

export interface ChartConfigurationOptions {
  xAxisUnits: TimeUnit;
  title?: string;
  legendPosition?: TypeLegendPosition;
  disableAnimation?: boolean;
  disableCurvedLine?: boolean;
  leftHandAxisId: string;
  rightHandAxisId?: string;
  rightHandAxisLabel?: string;
  xAxisLabel?: string;
  yAxisLabel?: string;
  timeZone: string;
  minX?: Date;
  maxX?: Date;
  minLeftY?: number;
  maxLeftY?: number;
  stepLeftY?: number;
  minRightY?: number;
  maxRightY?: number;
  stepRightY?: number;
  useRelativeScale?: boolean;
}

export interface TicksConfigOptions {
  min?: number | Date;
  suggestedMin?: number | Date;
  max?: number | Date;
  stepSize?: number | Date;
}

export type TypeChartJsSteppedLine = "before" | "after" | "middle" | boolean; // need to force cast to avoid TS\linter complaints

export enum SupportedChartTypes {
  BAR = "bar",
  LINE = "line",
  STEPPED_LINE = "steppedLine",
}

export interface ConfigInstruction {
  chartType: SupportedChartTypes;
  gradientFill?: boolean;
}

export interface TableDataType {
  joinedArray: Array<any>;
  extractedDayMarkers: Array<any>;
}

export type ChartType = "line" | "bar" | "custom" | "LineWithLine";
