export const PACKET_LOG_STATUSES = [
  { code: 1, label: "Processing" },
  { code: 2, label: "Scheduled for retry" },
  { code: 3, label: "Processed" },
  { code: 400, label: "Unknown transmission format" },
  { code: 401, label: "Unknown config block" },
  { code: 402, label: "Unknown bus type" },
  { code: 403, label: "Unknown packet type" },
  { code: 404, label: "Unknown Device Lib" },
  { code: 405, label: "Malformed Packet" },
  { code: 406, label: "Packet size mismatch" },
  { code: 407, label: "Sample count zero" },
  { code: 500, label: "Retry Count Exceeded" },
  { code: 501, label: "Invalid Library config" },
];

export const PACKET_LOG_PACKET_TYPES = [
  { code: 4, label: "MK6 Conf" },
  { code: 5, label: "MK6 Data" },
];
