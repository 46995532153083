import { isNonEmptyArray } from "components/Utils/MiscUtils";
import { arrayETL, etlNonSpecifiedDefault } from "components/Utils/ObjectUtils";
import { SamplePoint, SamplePointCurrentValueType } from "./SamplePoint.d";

export function extractSamplePointsCurrentValues(samplePoints: Array<SamplePoint>) {
  let valuesToDisplay: Array<SamplePointCurrentValueType>;

  if (isNonEmptyArray(samplePoints)) {
    valuesToDisplay = arrayETL<SamplePointCurrentValueType>(samplePoints, [
      { e: "name", t: etlNonSpecifiedDefault("-"), l: "name" },
      { e: "currentStatus.value", t: etlNonSpecifiedDefault("-"), l: "valueUnits" },
      { e: "currentStatus.trend", l: "trend" },
    ]);
  } else {
    valuesToDisplay = [];
  }

  return valuesToDisplay;
}
