import { UserRole } from "components/Crud/EntityGuard.d";
import { isSpecified } from "components/Utils/MiscUtils";
import { ObjectIdType } from "components/Utils/Object.d";
import { MessageBannerDispatcherContextType } from "components/Dashboard/Message/MessageBannerFrame";
import { OPERATION_ERROR, OPERATION_SUCCESS } from "components/Utils/CRUDUtils.d";
import { createOrEditEntity } from "components/Utils/CRUDUtils";
import { EDIT_ACTION } from "components/Crud/Entity.const";
import { UserParams } from "./UserParams.d";
import {
  SUPER_ADMIN_STR,
  ADMIN_ROLE_STR,
  SUPERVISOR_ROLE_STR,
  USER_ROLE_STR,
  USER_REQUEST_EMAIL_VERIFICATION_API_URL,
  USER_REQUEST_RESET_PASSWORD_API_URL,
} from "./User.const";

export function getRoleDescription(role: UserRole): string {
  let retVal: string;

  switch (role) {
    case UserRole.SUPER_ADMIN:
      retVal = SUPER_ADMIN_STR;
      break;
    case UserRole.ADMIN:
      retVal = ADMIN_ROLE_STR;
      break;
    case UserRole.SUPERVISOR:
      retVal = SUPERVISOR_ROLE_STR;
      break;
    case UserRole.USER:
      retVal = USER_ROLE_STR;
      break;
    default:
      retVal = "";
      break;
  }
  return retVal;
}

export const handleClickRerequestEmailVerificataion = async (
  userId: ObjectIdType,
  inputMessageBannerDispatcher?: MessageBannerDispatcherContextType
) => {
  const result = await createOrEditEntity(
    EDIT_ACTION,
    userId,
    {}, // POST BODY
    USER_REQUEST_EMAIL_VERIFICATION_API_URL
  );

  if (result.status.type === OPERATION_ERROR) {
    if (inputMessageBannerDispatcher) {
      inputMessageBannerDispatcher(
        /* .dispatchMessage */ {
          messageType: OPERATION_ERROR,
          messageText: `Request to resend email verification failed`,
        }
      );
    }

    console.error(
      `Error ${result.status.message} while requesting email verification for user id =  ${userId}`
    );
  } else {
    if (inputMessageBannerDispatcher) {
      inputMessageBannerDispatcher(
        /* .dispatchMessage */ {
          messageType: OPERATION_SUCCESS,
          messageText: `Successfully resent email verification`,
        }
      );
    }
  }
};

export const handleClickRequestResetPassowrd = async (
  userId: ObjectIdType,
  inputMessageBannerDispatcher?: MessageBannerDispatcherContextType
) => {
  const result = await createOrEditEntity(
    EDIT_ACTION,
    userId,
    {}, // POST BODY
    USER_REQUEST_RESET_PASSWORD_API_URL
  );

  if (result.status.type === OPERATION_ERROR) {
    if (inputMessageBannerDispatcher) {
      inputMessageBannerDispatcher(
        /* .dispatchMessage */ {
          messageType: OPERATION_ERROR,
          messageText: `Request to reset password failed`,
        }
      );
    }

    console.error(
      `Error ${result.status.message} while requesting resetting password for user id =  ${userId}`
    );
  } else {
    window.open(result.data.passwordResetUrl, '_blank');
  }
};

export class UserUtils {
  public static getFullName(userData: UserParams): string {
    if (!isSpecified(userData)) {
      return "";
    }

    return `${userData.firstName} ${userData.lastName}`;
  }

  public static getInitials(userData: UserParams): string {
    if (!isSpecified(userData)) {
      return "";
    }

    return `${userData.firstName.charAt(0).toUpperCase()}${userData.lastName.charAt(0).toUpperCase()}`;
  }

  public static hasMultipleMemberships(userData: UserParams) {
    if (!isSpecified(userData)) {
      return null;
    }

    return userData.memberships.length > 1;
  }
}
