import { StyleRulesCallback, Theme } from "@material-ui/core";

export const secondaryCardValueStyle: StyleRulesCallback<Theme, {}> = (theme) => ({
  root: {
    fontSize: "29px",
    lineHeight: "32px",
    color: theme.palette.text.secondary,
    fontWeight: 500,
  },
});
