// tslint:disable:max-line-length
import { CSSProperties } from "@material-ui/core/styles/withStyles";

export const singlePaddingValue = 8;

export const card: CSSProperties = {
  display: "inline-block",
  position: "relative",
  width: "98%",
  margin: "45px 0",
  boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.14)",
  borderRadius: "3px",
  color: "rgba(0, 0, 0, 0.87)",
  background: "#fff",
  overflow: "visible",
};

export const defaultFont: CSSProperties = {
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  // fontWeight: 300,
  // lineHeight: '1.5em',
};

// TODO: move to Theme
export const warningColor = "#ff9800";
export const yellowColor = "#22CECE";
export const greenColor = "#4EA952";
export const dangerColor = "#f44336";
export const successColor = "#4caf50";
export const infoColor = "#00acc1";
export const roseColor = "#e91e63";
export const greyColor = "#9e9e9e";

export const infoBoxShadow: CSSProperties = {
  boxShadow:
    "0 12px 20px -10px rgba(0, 188, 212, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(0, 188, 212, 0.2)",
};
export const successBoxShadow: CSSProperties = {
  boxShadow:
    "0 12px 20px -10px rgba(76, 175, 80, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(76, 175, 80, 0.2)",
};
export const warningBoxShadow: CSSProperties = {
  boxShadow:
    "0 12px 20px -10px rgba(255, 152, 0, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 152, 0, 0.2)",
};
export const dangerBoxShadow: CSSProperties = {
  boxShadow:
    "0 12px 20px -10px rgba(244, 67, 54, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(244, 67, 54, 0.2)",
};

export const blueCardHeader: CSSProperties = {
  background: "linear-gradient(60deg, #26c6da, #307fbe)",
  ...infoBoxShadow,
};

export const cardHeader: CSSProperties = {
  margin: "-20px 5px 0",
  borderRadius: "3px",
  padding: "5px",
  textAlign: "center",
};
