import { StyleRulesCallback, Theme } from "@material-ui/core";

export const graphComponentRangeNavigationStyle: StyleRulesCallback<Theme, {}> = (theme) => ({
  root: {},
  graphSubGroupStyle: {
    marginBottom: "16px",
  },
  groupTitleItem: {
    height: "24px", // Note different value from the font on the side bar
  },
});
