import React, { MouseEventHandler } from "react";
import { SamplePointGraphDataView } from "../SamplePoint/SamplePointGraphDataView";
import { Paper, makeStyles, Button } from "@material-ui/core";
import NotesIcon from "@material-ui/icons/Notes";
import { assetSummaryStyles } from "./assetSummaryStyles";
import { ColumnLayout } from "layouts/ColumnLayout";
import { AssetAlertHistorySummaryPortlet } from "../../Alert/AssetAlertHistorySummaryPortlet";
import { ActiveAlertCard } from "../../Alert/ActiveAlertCard";
import { AssetCurrentValuesPortlet } from "./AssetCurrentValuesPortlet";
import { ObjectIdType, BasicEntityDataType } from "components/Utils/Object.d";
import { FlexSpaceFiller } from "layouts/FlexSpaceFiller";
import { ColumnWrapper } from "layouts/ColumnWrapper";
import { API_LIB } from "components/Utils/API_LIB";
import { ENTITY_LIB } from "components/Utils/CRUDEntityList";
import { TabbedView } from "layouts/TabbedView";
import { EntityNameScreenTitle } from "../../../layouts/EntityNameScreenTitle";
import { MainScreenContentHeader } from "layouts/MainScreenContentHeader";
import { useMediaQueryMatchDown } from "components/Utils/LayoutUtils";
import { ConditionalRender } from "layouts/ConditionalRender";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { ScreenSecondaryActionButtonFace } from "components/Buttons/ScreenSecondaryActionButtonFace";
import { ActionButtonHandlerComponent } from "components/Buttons/ActionButtonHandlerComponent";
import { getAttributeByPath } from "components/Crud/EntityUtils";
import { EDIT_ACTION, SHOW_ACTION } from "components/Crud/Entity.const";
import { useVersion } from "components/Utils/CustomHooks";
import { AssetEditForm } from "./AssetEditForm";
import { GeoMapPopup } from "components/GeoMap/GeoMapPopup";
import { AssetSamplePointConfiguration } from "./AssetSamplePointConfigurationCombined";
import { UserParams } from "views/User/UserParams.d";
import { ReactComponent as MapIcon } from "assets/icons/ProfessionalSet/Map.svg";
import { TriggerList } from "layouts/AlertTriggers/TriggerList";
import { AssetAlertList } from "views/Alert/AssetAlertList";
import { isActionAllowed } from "access-control/RBACEngine";
import clsx from "clsx";
import { isFormSpecified } from "components/Utils/MiscUtils";
import MoreVert from "@material-ui/icons/MoreVert";
import PopupMenu from "components/PopupMenu/PopupMenu";
import { ActionButtonConfig, OnClickAction } from "components/Buttons/Buttons.d";
import { VIEW_LOCATION_ACTION, NAVIGATE_TO_ALL_ASSET_ALERTS_ACTION } from "./Asset.const";

export interface AssetSummaryScreenProps {
  assetId: number;
  userData: UserParams;
  siteData: BasicEntityDataType;
  initialTabId?: number;
  currentEnterpriseId: ObjectIdType;
}

const useStyles = makeStyles(assetSummaryStyles, { name: "AssetSummaryScreen" });

export function AssetSummaryScreenContent(props: AssetSummaryScreenProps) {
  const [version, increaseVersion] = useVersion();

  const classes = useStyles({});

  const isSmallScreen = useMediaQueryMatchDown("sm");

  const viewLocationButtonConfig: ActionButtonConfig = {
    labelElement: (
      <ScreenSecondaryActionButtonFace>
        <MapIcon />
        <span style={{ paddingLeft: "6px", whiteSpace: "nowrap" }}>VIEW LOCATION</span>
      </ScreenSecondaryActionButtonFace>
    ),
    doNotGroup: true,
    name: "viewLocation",
    onClick: {
      type: "dialog",
      dialogId: GeoMapPopup,
      onCloseCallbacks: undefined,
      extraPropsObject: undefined,
    },
    actionCode: VIEW_LOCATION_ACTION,
    actionObject: ENTITY_LIB.ASSET_INVENTORY_ITEM,
  };

  const isAssetEditAllowed = isActionAllowed(
    EDIT_ACTION,
    props.currentEnterpriseId,
    props.userData,
    [],
    ENTITY_LIB.ASSET_INVENTORY_ITEM
  );

  const settingsButtonConfig: ActionButtonConfig = {
    labelElement: (
      <ScreenSecondaryActionButtonFace>
        <NotesIcon fontSize="small" />
        <span style={{ paddingLeft: "6px", whiteSpace: "nowrap" }}>ASSET DETAILS</span>
      </ScreenSecondaryActionButtonFace>
    ),
    doNotGroup: true,
    name: "assetDetails",
    onClick: {
      type: "dialog",
      dialogId: AssetEditForm,
      onCloseCallbacks: [increaseVersion],
      extraPropsObject: { title: "Asset Details" },
    },
    actionCode: isAssetEditAllowed ? EDIT_ACTION : SHOW_ACTION,
    actionObject: ENTITY_LIB.ASSET_INVENTORY_ITEM,
  };

  const siteName = isFormSpecified(getAttributeByPath(props.siteData, "name"))
    ? getAttributeByPath(props.siteData, "name")
    : "Loading...";

  return (
    <div className={classes.root}>
      <MainScreenContentHeader
        subTitle={"ASSET"}
        subChildren={
          <React.Fragment>
            <div
              style={{ whiteSpace: "nowrap", overflowX: "hidden", textOverflow: "ellipsis", height: "24px" }}
            >
              {siteName}
            </div>
            <div> </div>
          </React.Fragment>
        }
      >
        <EntityNameScreenTitle
          id={props.assetId}
          entityName={ENTITY_LIB.ASSET_INVENTORY_ITEM}
          apiUrl={API_LIB.ASSET}
          externalTriggerValues={[version]}
        />
        <FlexSpaceFiller />
        <ConditionalRender
          condition={isSmallScreen}
          onTrue={
            <PopupMenu
              clickableVisibleItem={(handleClick: MouseEventHandler) => (
                <Button onClick={handleClick}>
                  <span>MORE</span>
                  <MoreVert fontSize="small" className={classes.configItem} />
                </Button>
              )}
            >
              {(menuCloseActions: () => void, disableMenuInput: () => void) => {
                return (
                  // NOTE: without this menu stays Open after a click
                  <Paper>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <ActionButtonHandlerComponent
                        buttonConfig={viewLocationButtonConfig}
                        id={props.assetId}
                        object={null}
                        preActionCallback={disableMenuInput}
                        postActionCallback={menuCloseActions}
                      />
                      <ActionButtonHandlerComponent
                        buttonConfig={settingsButtonConfig}
                        id={props.assetId}
                        object={null}
                        preActionCallback={disableMenuInput}
                        postActionCallback={menuCloseActions}
                      />
                    </div>
                  </Paper>
                );
              }}
            </PopupMenu>
          }
          onFalse={
            <div>
              <ColumnLayout spacingName="buttonSpacing" equalWidth={true}>
                <ColumnWrapper>
                  <ActionButtonHandlerComponent
                    buttonConfig={viewLocationButtonConfig}
                    id={props.assetId}
                    object={null}
                  />
                </ColumnWrapper>
                <ColumnWrapper>
                  <ActionButtonHandlerComponent
                    buttonConfig={settingsButtonConfig}
                    id={props.assetId}
                    object={null}
                  />
                </ColumnWrapper>
              </ColumnLayout>
            </div>
          }
        />
      </MainScreenContentHeader>
      <div className={classes.contentRoot}>
        <TabbedView
          tabLabels={["ASSET SUMMARY", "ALERT HISTORY", "SENSORS", "TRIGGERS"]}
          initialTabId={props.initialTabId}
          hideTabs={isSmallScreen ? [2] : []}
          render={[
            (switchToTab: (tabId: number) => void) => {
              return (
                <div className={classes.tabbedPanel}>
                  <div
                    style={{
                      height: "100%",
                      display: "flex",
                      flexDirection: isSmallScreen ? "column" : "row",
                      paddingBottom: "20px",
                    }}
                  >
                    <div style={{ flexGrow: 1, height: "100%", display: "flex", flexDirection: "column" }}>
                      <ActiveAlertCard
                        assetId={props.assetId}
                        userData={props.userData}
                        portletAction={{
                          labelElement: (
                            <div style={{ display: "flex" }}>
                              {"SEE ALL"}&nbsp;
                              <ArrowForwardIcon fontSize="small" />
                            </div>
                          ),
                          name: "viewDetail",
                          onClick: {
                            type: "action",
                            fn: (id: ObjectIdType, object: Record<string, any>) => switchToTab(1),
                          } as OnClickAction,
                          actionCode: NAVIGATE_TO_ALL_ASSET_ALERTS_ACTION,
                          actionObject: ENTITY_LIB.ASSET_INVENTORY_ITEM,
                        }}
                      />
                      <Paper style={{ flexGrow: 1 }}>
                        <SamplePointGraphDataView
                          title={"Data"}
                          assetId={props.assetId} /* TODO: inject entity name title={'Graph'}*/
                        />
                      </Paper>
                    </div>
                    <div
                      className={clsx(
                        classes.additionalContent,
                        !isSmallScreen && classes.additionalContentBigScreen,
                        isSmallScreen && classes.additionalContentSmallScreen
                      )}
                    >
                      <AssetCurrentValuesPortlet assetId={props.assetId} />
                      <ConditionalRender condition={!isSmallScreen}>
                        <React.Fragment>
                          <div style={{ paddingTop: "20px" }} />
                          <AssetAlertHistorySummaryPortlet
                            assetId={props.assetId}
                            userData={props.userData}
                            portletAction={[
                              {
                                labelElement: (
                                  <div style={{ display: "flex" }}>
                                    {"SEE ALL"}&nbsp;
                                    <ArrowForwardIcon fontSize="small" />
                                  </div>
                                ),
                                name: "viewDetail",
                                onClick: {
                                  type: "action",
                                  fn: (id: ObjectIdType, object: Record<string, any>) => switchToTab(1),
                                } as OnClickAction,
                                actionCode: NAVIGATE_TO_ALL_ASSET_ALERTS_ACTION,
                                actionObject: ENTITY_LIB.ASSET_INVENTORY_ITEM,
                              },
                            ]}
                          />
                        </React.Fragment>
                      </ConditionalRender>
                    </div>
                  </div>
                </div>
              );
            },
            (switchToTab: (tabId: number) => void) => {
              return <AssetAlertList userData={props.userData} assetId={props.assetId} />;
            },
            (switchToTab: (tabId: number) => void) => {
              return (
                <div className={classes.tabbedPanel}>
                  <AssetSamplePointConfiguration assetId={props.assetId} siteData={props.siteData} />
                </div>
              );
            },
            (switchToTab: (tabId: number) => void) => {
              return (
                <div className={classes.tabbedPanel}>
                  <TriggerList assetId={props.assetId} />
                </div>
              );
            },
          ]}
        />
      </div>
    </div>
  );
}
