export const USER_API_URL = "/user";
export const USER_ROUTE = "/user";
export const USER_ENTITY_NAME = "user";
export const USER_REQUEST_EMAIL_VERIFICATION_API_URL = "/user/send-verification";
export const USER_REQUEST_RESET_PASSWORD_API_URL = "/user/reset-password";

export const CHANGE_USER_PASSWORD_EVENT_ENTITY = "changeUserPassword";

// TODO: we already have a similar type somwhere
export const ADMIN_ROLE_STR = "Administrator";
export const SUPERVISOR_ROLE_STR = "Supervisor";
export const USER_ROLE_STR = "User";
export const SUPER_ADMIN_STR = "Super Admin";

export const USER_MEMBERSHIP_LIST_ACTION = "membership_list";
