import { StyleRulesCallback, Theme } from "@material-ui/core/styles";

export const switchEnterpriseStyle: StyleRulesCallback<Theme, {}> = (theme) => ({
  titleContainer: {
    paddingBottom: "20px",
  },
  formContainer: {
    padding: "16px",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
});
