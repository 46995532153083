import { useContext, useEffect, useReducer } from "react";
import { MessageBannerDispatcherContext } from "components/Dashboard/Message/MessageBannerFrame";
import { OPERATION_SUCCESS, OPERATION_ERROR } from "components/Utils/CRUDUtils.d";
import { fetchDataByFullURL } from "components/Utils/CRUDUtils";
import { preUrl } from "views/constants";
import { getAttributeByPath } from "../EntityUtils";
import { SimpleAsyncDataStateType, SimpleAsyncEvent, NotificationFlagsType } from "./useSimpleAsyncData.d";
import { simpleAsyncDataReducer } from "./useSimpleAsyncData.reducer";

const initialState: SimpleAsyncDataStateType = {
  isLoading: false,
  data: null,
  error: null,
};

export function useSimpleAsyncData(
  queryUrl: string,
  pathToListInData?: string,
  notificationFlags?: NotificationFlagsType
): any {
  const messageBannerDispatcher = useContext(MessageBannerDispatcherContext);

  const [dataset, dispatchRequest] = useReducer(simpleAsyncDataReducer, initialState);

  const { notifyOnSuccess = false, notifyOnFailure = false } = notificationFlags || {};

  useEffect(() => {
    let didCancel = false;

    const fetchData = async () => {
      dispatchRequest({
        action: SimpleAsyncEvent.LOADING,
      });

      const result = await fetchDataByFullURL(`${preUrl}${queryUrl}`, "fetch as is");

      if (!didCancel && result.status.type === OPERATION_SUCCESS) {
        dispatchRequest({
          action: SimpleAsyncEvent.LOAD_SUCCESS,
          payload: pathToListInData ? getAttributeByPath(result.data, pathToListInData) : result.data,
        });
      } else if (!didCancel && result.status.type !== OPERATION_SUCCESS) {
        dispatchRequest({
          action: SimpleAsyncEvent.LOAD_ERROR,
          payload: result.status.message,
        });
      }

      if (result.status.type === OPERATION_SUCCESS && notifyOnSuccess) {
        messageBannerDispatcher({ messageType: OPERATION_SUCCESS, messageText: "Loaded successfully" });
      } else if (!(result.status.type === OPERATION_SUCCESS) && notifyOnFailure) {
        messageBannerDispatcher({ messageType: OPERATION_ERROR, messageText: "Error while loading data" });
      }
    };

    if (queryUrl) {
      fetchData();
    }

    return () => {
      didCancel = true;
    };
  }, [queryUrl, messageBannerDispatcher, pathToListInData, notifyOnSuccess, notifyOnFailure]);

  return dataset;
}
