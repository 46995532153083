// TODO: this file resides in the view folder, but contains not only view related elements

export const ENTERPRISE_API_URL = "/enterprise";
export const ENTERPRISE_ROUTE = "/enterprise";
export const ENTERPRISE_ENTITY_NAME = "enterprise";

export const ALL_ENTERPRISES_CODE = -1;
export const ALL_ENTERPRISES_LABEL = "All Enterprises";

export const LOADING_ENTERPRISE_DATA = "Loading...";
export const ENTERPRISE_NOT_SELECTED = "";

export const SWITCH_TO_ENTERPRISE = "switchToEnterprise";
