import { FieldConfiguration } from "components/Crud/Form/Fields/FieldConfiguration.d";
import { arrayETL } from "components/Utils/ObjectUtils";
import { ObjectIdType } from "components/Utils/Object.d";
import { Theme } from "@material-ui/core";
import { priorityDictionary } from "components/Dictionaries/DictionariesCommon";
import { SAMPLEPOINT_API_URL } from "views/asset/SamplePoint/SamplePoint.const";
import { getAttributeByPath } from "components/Crud/EntityUtils";
import { CREATE_ACTION } from "components/Crud/Entity.const";
import { isFormSpecified, isSpecified } from "components/Utils/MiscUtils";
import { valueTypeDictionary, conditionTypeDictionary } from "./TriggerDictionaries";
import { API_LIB } from "components/Utils/API_LIB";
import { OPERATION_SUCCESS } from "components/Utils/CRUDUtils.d";
import { fetchDataByFullURL } from "components/Utils/CRUDUtils";
import { preUrl } from "views/constants";
import { EventLevel } from "components/Dictionaries/Dictionaries.d";
import { ValueType, ConditionType } from "./Trigger.d";

export const FAKE_UNITS_FIELD = "conditions[0].sampleField.units.abbreviation";
export const FAKE_UNITS_FIELD_PATH_TO_DELETE = "conditions[0].sampleField.units";

const sampleFieldFilterUrlFunction = (obj: Record<string, any>) => {
  const samplePointId = getAttributeByPath(obj, "conditions[0].samplePoint.id");

  return `/${samplePointId}`;
};
const unitsFilterUrlFunction = (obj: Record<string, any>) => {
  const sampleFieldId = getAttributeByPath(obj, "conditions[0].sampleField.id");

  return `?join=units::abbreviation&filter=id::eq::${sampleFieldId}`;
};

const sampleFieldFetchResultTransformer = (
  input: Record<string, any> | Array<Record<string, any>>
): Array<Record<string, any>> => {
  const data = getAttributeByPath(input, "sampleType.sampleFields");

  const result = arrayETL(data, [
    { e: "id" },
    {
      e: ["name", "units.abbreviation"],
      t: (name: string, units: string) => `${name} (${units})`,
      l: "name",
    },
  ]);

  return result;
};

const disableFetchingSampleFieldFunction = (obj: Record<string, any>) => {
  const samplePointId = getAttributeByPath(obj, "conditions[0].samplePoint.id");

  const result = !isFormSpecified(samplePointId);

  return result;
};
const disableFetchingUnitsFunction = (obj: Record<string, any>) => {
  const sampleFieldId = getAttributeByPath(obj, "conditions[0].sampleField.id");

  const result = !isFormSpecified(sampleFieldId);

  return result;
};

const hideIfValueTypeIsRate = (
  value: string,
  obj: Record<string, any>,
  editType: string,
  initialValues: Record<string, any>
) => {
  const valueTypeId = getAttributeByPath(obj, "conditions[0].valueType");

  const result = valueTypeId === ValueType.VALUE;

  return result;
};

const requiredIfValueTypeIsRate = (value: string | number, obj: Record<string, any>, editAction: string) => {
  const valueTypeId = getAttributeByPath(obj, "conditions[0].valueType");

  const result = valueTypeId === ValueType.RATE;

  return result;
};

const hideIfActionIsEdit = (
  value: string,
  obj: Record<string, any>,
  editType: string,
  initialValues: Record<string, any>
) => {
  const result = false; // editType === EDIT_ACTION;

  return result;
};

const hideIfActionIsEditOrValueTypeIsRate = (
  value: string,
  obj: Record<string, any>,
  editType: string,
  initialValues: Record<string, any>
) => {
  const result =
    hideIfValueTypeIsRate(value, obj, editType, initialValues) ||
    hideIfActionIsEdit(value, obj, editType, initialValues);

  return result;
};

const requiredIfActionCreate = (value: string | number, obj: Record<string, any>, editAction: string) => {
  const result = editAction === CREATE_ACTION;

  return result;
};

const requiredIfActionCreateOrValueTypeIsRate = (
  value: string | number,
  obj: Record<string, any>,
  editAction: string
) => {
  const result =
    requiredIfActionCreate(value, obj, editAction) || requiredIfValueTypeIsRate(value, obj, editAction);

  return result;
};

export const generateMessageBasedOnOtherFields = async (
  obj: Record<string, any>,
  editType: string,
  initialValues: Record<string, any>
) => {
  // console.log('generateMessageBasedOnOtherFields - obj', JSON.stringify(obj));
  let result: string;

  const samplePointId = getAttributeByPath(obj, "conditions[0].samplePoint.id");

  const sampleFieldId = getAttributeByPath(obj, "conditions[0].sampleField.id");

  const conditionType = getAttributeByPath(obj, "conditions[0].conditionType");
  let conditionTypeString: string;
  if (conditionType === ConditionType.LOWER ||conditionType === ConditionType.LOWER_EQUALS) {
    conditionTypeString = "below minimum";
  } else if (conditionType === ConditionType.GREATER || conditionType === ConditionType.GREATER_EQUALS) {
    conditionTypeString = "above maximum";
  } else if (conditionType === ConditionType.EQUALS) {
    conditionTypeString = "equals to";
  }

  const valueType = getAttributeByPath(obj, "conditions[0].valueType");
  let valueTypeString: string;
  if (valueType === ValueType.VALUE) {
    valueTypeString = " "; // Value doesn't need to be stated explicitly
  } else if (valueType === ValueType.RATE) {
    valueTypeString = " rate of fall excessive";
    conditionTypeString = "";
  }

  const value = getAttributeByPath(obj, "conditions[0].value");

  const units = getAttributeByPath(obj, FAKE_UNITS_FIELD);

  if (!isSpecified(samplePointId)) {
    result = "specify Sensor";
  } else if (!isSpecified(sampleFieldId)) {
    result = "specify Sample Field";
  } else if (!isSpecified(valueTypeString)) {
    result = "specify Value Type";
  } else if (!isSpecified(conditionTypeString)) {
    result = "specify Condition";
  } else if (!isSpecified(value)) {
    result = "specify Value";
  } else if (!isSpecified(units)) {
    result = "specify Units";
  } else {
    // TODO: potentially use memoization
    // TODO: and fix object promise
    let samplePointName: string = undefined;
    const samplePointData = await fetchDataByFullURL(
      `${preUrl}${SAMPLEPOINT_API_URL}/${samplePointId}`,
      "get sample point",
      "GET",
      undefined,
      true
    );

    if (samplePointData.status.type === OPERATION_SUCCESS) {
      samplePointName = getAttributeByPath(samplePointData, "data.sampleType.name");
    }

    result = `${samplePointName}${valueTypeString}${conditionTypeString}`;
  }

  return result;
};

export const triggerEditFormFieldsSpec = (assetId: ObjectIdType, theme: Theme): Array<FieldConfiguration> => [
  {
    fieldName: "assetId",
    label: "Asset Id",
    type: "number",
    defaultValue: assetId,
    hideIf: () => true,
    validation: { required: true },
  },
  // {
  //   fieldName: 'name',
  //   label: 'Name',
  //   type: 'text',
  //   validation: { required: true }
  // },
  {
    fieldName: "eventLevel",
    label: "Event Level",
    type: "select",
    defaultValue: EventLevel.WARNING,
    validation: { required: true },
    fieldParams: {
      options: priorityDictionary.map((item) => ({ code: item.code, label: item.label })),
    },
  },
  {
    fieldName: "isEnabled",
    label: "Is Enabled",
    type: "boolean",
    defaultValue: true,
    validation: { required: true },
    fieldParams: {
      color: theme.palette.primary.main,
    },
  },
  {
    fieldName: "timeBeforeReTrigger",
    label: "Time Before Re-Trigger (seconds)",
    type: "number",
    defaultValue: 604800,
    validation: { required: false },
  },

  {
    fieldName: "comments",
    label: "Comments",
    type: "text",
    validation: { required: false },
    fieldParams: {
      rows: 4,
    },
  },
  // Condition Form Fields
  {
    fieldName: "conditions[0].id",
    label: "id",
    type: "number",
    //    defaultValue: triggerId,
    hideIf: () => true,
    // validation: { required: false }
  },
  {
    fieldName: "conditions[0].triggerId",
    label: "TriggerId",
    type: "number",
    //    defaultValue: triggerId,
    hideIf: () => true,
    validation: { required: false },
  },
  {
    fieldName: "conditions[0].samplePoint.id",
    label: "Sensor",
    type: "select-async",
    validation: { required: true },
    fieldParams: {
      optionCodeField: "id",
      optionLabelField: "sampleType.name",
      optionDefinitionEndpoint: SAMPLEPOINT_API_URL,
      filterUrl: `?filter=assetId::eq::${assetId}`,
    },
    hideIf: hideIfActionIsEdit,
  },
  {
    fieldName: "conditions[0].sampleField.id",
    label: "Sample Field",
    type: "select-async",
    validation: { required: true },
    fieldParams: {
      optionCodeField: "id",
      optionLabelField: "name",
      optionDefinitionEndpoint: SAMPLEPOINT_API_URL,
      // filterUrl: `?filter=assetId::eq::${props.assetId}`
      filterUrl: sampleFieldFilterUrlFunction,
      fetchResultTransformer: sampleFieldFetchResultTransformer,
      disableFetching: disableFetchingSampleFieldFunction,
    },
    dependsOnField: ["conditions[0].samplePoint.id"],
    hideIf: hideIfActionIsEdit,
  },
  {
    fieldName: "conditions[0].valueType",
    label: "Value Type",
    type: "select",
    defaultValue: ValueType.VALUE,
    validation: { required: true },
    fieldParams: {
      options: valueTypeDictionary,
    },
    hideIf: hideIfActionIsEdit,
  },
  {
    fieldName: "conditions[0].params[thresholdSensitivity]",
    label: "Threshold Sensitivity",
    type: "number",
    defaultValue: 5,
    validation: { required: false },
    hideIf: hideIfActionIsEdit,
  },
  {
    fieldName: "conditions[0].params[window]",
    label: "Window (seconds)",
    type: "number",
    defaultValue: 3600,
    validation: {
      required: requiredIfActionCreateOrValueTypeIsRate,
    },
    hideIf: hideIfActionIsEditOrValueTypeIsRate,
  },
  {
    fieldName: "conditions[0].params[duration]",
    label: "Duration (seconds)",
    type: "number",
    defaultValue: 60,
    validation: { required: false },
    hideIf: hideIfActionIsEditOrValueTypeIsRate,
  },
  {
    fieldName: "conditions[0].conditionType",
    label: "Condition",
    type: "select",
    validation: { required: true },
    defaultValue: ConditionType.LOWER_EQUALS,
    fieldParams: {
      options: conditionTypeDictionary,
    },
    hideIf: hideIfActionIsEdit,
  },
  {
    fieldName: "conditions[0].value",
    label: "Value",
    type: "number",
    validation: { required: true },
    hideIf: hideIfActionIsEdit,
  },
  // -------
  {
    fieldName: FAKE_UNITS_FIELD,
    label: "Units",
    type: "select-async",
    validation: { required: true },
    disabled: true,
    fieldParams: {
      optionCodeField: "units.abbreviation",
      optionLabelField: "units.abbreviation",
      optionDefinitionEndpoint: API_LIB.SAMPLE_FIELD,
      // filterUrl: `?join=units::abbreviation`,
      // filterUrl: `?filter=assetId::eq::${props.assetId}`
      filterUrl: unitsFilterUrlFunction,
      // fetchResultTransformer: sampleFieldFetchResultTransformer,

      disableFetching: disableFetchingUnitsFunction,
    },
    dependsOnField: ["conditions[0].sampleField.id"],
  },
  // ----------
  {
    fieldName: "message",
    label: "Message",
    type: "text",
    defaultValue: generateMessageBasedOnOtherFields,
    validation: { required: true },
    disabled: true,
    dependsOnField: [
      "conditions[0].conditionType",
      "conditions[0].valueType",
      "conditions[0].value",
      "conditions[0].samplePoint.id",
      "conditions[0].sampleField.id",
      FAKE_UNITS_FIELD,
    ],
  },
  {
    fieldName: "conditions[0].useForecast",
    label: "Use Forecast",
    type: "boolean",
    defaultValue: true,
    fieldParams: {
      color: theme.palette.primary.main,
    },
    validation: { required: true },
    hideIf: hideIfActionIsEdit,
  },
];
