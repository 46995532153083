import React from "react";
import ConfirmButton from "./ConfirmButton";
import BasicButton from "./BasicButton";
import { ColorType } from "assets/ColorType";

interface ActionButtonProps {
  color?: ColorType;
  round?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  labelElement: JSX.Element;
  confirm: {
    message: string;
    dialogConfirmButtonText?: string;
  };
  action?: () => Promise<void> | void; // TODO: This is a hacko
  preActionCallback?: () => void;
  postActionCallback?: () => void;
}

function ActionButton(props: ActionButtonProps) {
  let button: JSX.Element;

  const action = async (event: React.MouseEvent<Element, MouseEvent>) => {
    // TODO: move inside BasicButton to make consistent with Confirm Button
    event.preventDefault();
    event.stopPropagation();

    if (props.preActionCallback) {
      props.preActionCallback();
    }

    await props.action();

    if (props.postActionCallback) {
      props.postActionCallback();
    }
  };

  if (props.confirm) {
    button = (
      <ConfirmButton
        msg={props.confirm.message}
        dialogConfirmButtonText={props.confirm.dialogConfirmButtonText}
        action={action}
        preActionCallback={props.preActionCallback}
        // Props which affect the style
        color={props.color}
        round={props.round}
        fullWidth={props.fullWidth}
        disabled={props.disabled}
      >
        {props.labelElement}
      </ConfirmButton>
    );
  } else {
    button = (
      <BasicButton
        onClick={action}
        // Props which affect the style
        color={props.color}
        round={props.round}
        fullWidth={props.fullWidth}
        disabled={props.disabled}
      >
        {props.labelElement}
      </BasicButton>
    );
  }

  return button;
}

export default ActionButton;
