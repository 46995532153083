import { StyleRulesCallback, Theme } from "@material-ui/core";
import { px } from "components/Utils/LayoutUtils";
import { CustomProps } from "assets/jss/muiTheme";

export const rowLayoutStyle: StyleRulesCallback<Theme, {}> = (theme: Theme & CustomProps) => {
  return {
    root: (props: Record<string, any>) => ({
      display: "flex",
      flexDirection: "column",
      "& > div:first-child:not(:only-child)": {
        boxSizing: "border-box",
        paddingBottom: px((theme.customProps as Record<string, number>)[props.spacingName] / 2),
      },
      "& > div:not(:first-child):not(:last-child)": {
        boxSizing: "border-box",
        paddingTop: px((theme.customProps as Record<string, number>)[props.spacingName] / 2),
        paddingBottom: px((theme.customProps as Record<string, number>)[props.spacingName] / 2),
      },
      "& > div:last-child:not(:only-child)": {
        boxSizing: "border-box",
        paddingTop: px((theme.customProps as Record<string, number>)[props.spacingName] / 2),
      },
      ...props,
    }),
    equalHeight: {
      "& > div": {
        flexGrow: 1,
        flexBasis: 0,
      },
    },
  };
};
