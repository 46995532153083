import { QueryEventAction } from "./DataQuery.d";
import { EventDataType } from "./DataProviderCommon.d";
import { UnifiedDataRequestType, OperationStatus } from "components/Utils/CRUDUtils.d";
import { CrudEventAction } from "./DataCrud.d";
import { ObjectIdType, ObjectWithIdFieldType } from "components/Utils/Object.d";

export enum DatasetManagerStatusType {
  STATIC = "STATIC",
  LOADING = "LOADING",
  MUTATING = "MUTATING",
}

export enum DatasetReducerEventActionType {
  POPULATE = "POPULATE",
  ADD = "ADD",
  UPDATE = "UPDATE",
  DELETE = "DELETE",
  MUTATING = "MUTATING",
  LOADING = "LOADING",
  SET_ERROR = "SET_ERROR",
}

export type DataRequestQueryType = EventDataType<QueryEventAction, UnifiedDataRequestType>;
export type DataRequestCrudType = EventDataType<CrudEventAction, Record<string, any>>; //TODO: with optional id to be pr
export type DataRequestType = DataRequestQueryType | DataRequestCrudType;

export interface DatasetReducerEventPayloadAsErrorType {
  operationType: DatasetReducerEventActionType;
  message: string;
  relatedId?: ObjectIdType;
}
export type DatasetReducerEventPayloadAsObjectWithIdType = ObjectWithIdFieldType;
// TODO: add support for adding objects with delayed save to database
// export type DatasetReducerEventPayloadAsObjectWithoutIdType = Record<string, any>;
export interface DatasetReducerEventPayloadAsDatasetType {
  data: Array<ObjectWithIdFieldType>;
  totalRows: number;
}

export type DatasetMutationExecutionPayloadType = DatasetReducerEventPayloadAsObjectWithIdType;
export type DatasetMutationSuccessPayloadType = DatasetReducerEventPayloadAsObjectWithIdType;
export type DatasetMutationErrorPayloadType = DatasetReducerEventPayloadAsErrorType;
export type DatasetLoadExecutionPayloadType = undefined;
export type DatasetLoadSuccessPayloadType = DatasetReducerEventPayloadAsDatasetType;
export type DatasetLoadErrorPayloadType = DatasetReducerEventPayloadAsErrorType;

export type DatasetReducerEventPayloadType =
  | DatasetMutationExecutionPayloadType
  | DatasetMutationSuccessPayloadType
  | DatasetMutationErrorPayloadType
  | DatasetLoadExecutionPayloadType
  | DatasetLoadSuccessPayloadType
  | DatasetLoadErrorPayloadType;

  // DatasetReducerEventPayloadAsErrorType |
  // (DatasetReducerEventPayloadAsObjectWithIdType & DatasetReducerEventPayloadAsSuccessType) |
  // // TODO: add support for adding objects with delayed save to database
  // // DatasetReducerEventPayloadAsObjectWithoutIdType |
  // (DatasetReducerEventPayloadAsDatasetType & DatasetReducerEventPayloadAsSuccessType) |
  // DatasetReducerEventPayloadAsSuccessType |
  // DatasetReducerEventPayloadAsSuccessType
export type DatasetReducerEventType = EventDataType<
  DatasetReducerEventActionType,
  DatasetReducerEventPayloadType
>;

export interface DatasetOperationResult {
  isSuccess: boolean;
  operationType: DatasetReducerEventActionType;
  message?: string;
  relatedId: ObjectIdType;
}

export interface DatasetManagerDatasetType {
  status: DatasetManagerStatusType;
  mutatingItemId: ObjectIdType;
  dataArray: Array<ObjectWithIdFieldType>;
  totalRows: number;
  lastOperationResult: DatasetOperationResult;
}

export interface OperationResultCallbackArgumentType {
  status: OperationStatus;
  message?: string;
  operation: DataRequestType;
  relatedId?: ObjectIdType;
}

export interface NotificationFlagTypes {
  notifyOnLoadSuccess?: boolean;
  notifyOnLoadFailure?: boolean;
  notifyOnMutationSuccess?: boolean;
  notifyOnMutationFailure?: boolean;
}
