import Button from "@material-ui/core/Button";
import buttonStyle from "components/Buttons/buttonStyle";
import clsx from "clsx";
import React, { useState, ReactNode, useRef, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import { ColorType } from "assets/ColorType";

interface Props {
  color?: ColorType;
  round?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  msg?: string;
  dialogConfirmButtonText?: string;
  action?: (event: React.MouseEvent<Element, MouseEvent>) => Promise<void>;
  open?: boolean;
  children: ReactNode;
  preActionCallback?: () => void;
}

const useStyles = makeStyles(buttonStyle, { name: "ConfirmButton" });

function ConfirmButton(props: Props) {
  const classes = useStyles({});

  const btnClassesCombo = clsx(classes.root, props.color && classes[props.color], {
    [classes.round]: props.round,
    [classes.fullWidth]: props.fullWidth,
    [classes.disabled]: props.disabled,
  });

  const [open, setDialogOpen] = useState(false);

  const isMountedRef = useRef(true);

  useEffect(() => {
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const handleClickOpen = () => {
    if (props.preActionCallback) {
      props.preActionCallback();
    }

    setDialogOpen(true);
  };

  const handleClickClose = () => {
    setDialogOpen(false);
  };

  const executeActionAndClose = async (event: React.MouseEvent<Element, MouseEvent>) => {
    // TODO: should we stop propagation here or let action component decide?
    // TODO: should we close the dialog before or after action?
    await props.action(event);

    if (isMountedRef.current === true) {
      setDialogOpen(false);
    }
  };

  return (
    <span>
      <Button size="small" className={btnClassesCombo} onClick={handleClickOpen}>
        {props.children}
      </Button>
      <Dialog open={open} onClose={handleClickClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Confirm</DialogTitle>
        <DialogContent>{props.msg}</DialogContent>
        <DialogActions>
          <Button onClick={handleClickClose} color="primary">
            Cancel
          </Button>
          <Button onClick={executeActionAndClose} color="primary">
            {props.dialogConfirmButtonText ? props.dialogConfirmButtonText : "Confirm"}
          </Button>
        </DialogActions>
      </Dialog>
    </span>
  );
}

export default ConfirmButton;
