import { StyleRules } from "@material-ui/core/styles";

const userLoginButtonStyle: StyleRules = {
  text: {
    textAnchor: "middle",
    fill: "white",
    fontSize: "1.6em",
    fontFamily: "Arial",
  },
  circle: {
    fill: "#aeaeae",
  },
  svg: {
    width: "3.8em",
    height: "3.8em",
  },
};

export default userLoginButtonStyle;
