import { StyleRulesCallback, Theme } from "@material-ui/core/styles";

export const moreActionsButtonFaceStyle: StyleRulesCallback<Theme, {}> = (theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "8px",
    paddingRight: "12px",
    boxSizing: "border-box",
    height: "30px",
    borderRadius: "4px",
    border: "1px solid #E0E0E0",
    whiteSpace: "nowrap",
  },
});
