import React from "react";
import { GlobalEventDispatcherContextType, EnterpriseDataType } from "GlobalContext.d";
import { UserParams } from "views/User/UserParams.d";
import { OpenPopupSignature } from "popups/PopupDialogsFrame.d";

// export const GlobalEventContext = React.createContext<GlobalEventDispatcherContextType>({dispatchEvent: undefined});
export const GlobalEventContext = React.createContext<GlobalEventDispatcherContextType>(null);

export const GlobalUserContext = React.createContext(undefined as UserParams);

export const GlobalEnterpriseContext = React.createContext(undefined as EnterpriseDataType);

export const GlobalPopupContext = React.createContext<OpenPopupSignature>({
  openPopup: undefined,
  updatePopupParams: undefined,
  closePopup: undefined,
});
