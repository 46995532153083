import React, { useEffect } from "react";
import { Route, withRouter } from "react-router-dom";
import { useAuth0 } from "components/Session/Auth0Provider";
import { ObjectWithKnownFieldsType } from "components/Utils/Object.d";

export interface PrivateRouteProps {
  render: any;
  path: string;
}

export function PrivateRoute(props: ObjectWithKnownFieldsType<PrivateRouteProps>) {
  const { render, path, ...rest } = props;

  const { loading, isAuthenticated, loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (loading || isAuthenticated) {
      return;
    }

    const fn = async () => {
      await loginWithRedirect({
        appState: { targetUrl: window.location.pathname },
      });
    };
    fn();
  }, [loading, isAuthenticated, loginWithRedirect]);

  const localRender = (inputProps: any) => {
    if (isAuthenticated) {
      return render(inputProps);
    } else {
      return null;
    }
  };

  return <Route path={path} render={localRender} {...rest} />;
}

// TODO: make sure it supports it
// @ts-ignore
export default withRouter(PrivateRoute);
