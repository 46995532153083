import React, { useState, useCallback } from "react";
import { PopupDialogComponents } from "./PopupDialogComponents";
import { ObjectIdType } from "components/Utils/Object.d";
import { isNonEmptyArray } from "components/Utils/MiscUtils";
import { DialogIdCallableType, DialogParamsType } from "./PopupDialogsFrame.d";
import { GlobalPopupContext } from "GlobalContext";

export interface PopupDialogsFrameProps {
  children: JSX.Element;
  // userData: UserParams;
  // currentEnterpriseId: EnterpriseIdType;
}

export function PopupDialogsFrame(props: PopupDialogsFrameProps) {
  const [dialogDescriptor, setDialogDescriptor] = useState({
    openDialogId: undefined,
    dialogParams: undefined,
  });
  const [onCloseCallbacks, setOnCloseCallbacks] = useState(undefined as Array<() => void>);

  const handleOpenDialogRequest = useCallback(
    (
      dialogId: DialogIdCallableType,
      id: ObjectIdType,
      actionCode: string,
      onCloseCallbacks: Array<() => void>,
      extraPropsObject?: Record<string, any>,
      dataObject?: Record<string, any>
    ) => {
      setDialogDescriptor({
        openDialogId: dialogId,
        dialogParams: {
          id: id,
          action: actionCode,
          extraPropsObject: extraPropsObject,
          dataObject: dataObject,
        },
      });
      setOnCloseCallbacks(onCloseCallbacks);
    },
    []
  );

  // TODO: perhaps not the ideal solution
  const handleUpdateDialogParams = useCallback((paramsToUpdate: DialogParamsType) => {
    setDialogDescriptor((prevState) => {
      if (prevState.openDialogId) {
        return {
          openDialogId: prevState.openDialogId,
          dialogParams: {
            id: paramsToUpdate.hasOwnProperty("id") ? paramsToUpdate.id : prevState.dialogParams.id,
            action: paramsToUpdate.hasOwnProperty("action")
              ? paramsToUpdate.action
              : prevState.dialogParams.action,
            extraPropsObject: paramsToUpdate.hasOwnProperty("extraPropsObject")
              ? paramsToUpdate.extraPropsObject
              : prevState.dialogParams.extraPropsObject,
            dataObject: paramsToUpdate.hasOwnProperty("dataObject")
              ? paramsToUpdate.dataObject
              : prevState.dialogParams.dataObject,
          },
        };
      } else {
        return prevState;
      }
    });
  }, []);

  const handleCloseDialogWindow = useCallback(
    (wasDataChanged?: boolean) => {
      // TODO: use of this function is a bit automagical and unclear
      // TODO: should we check for the state of the dialog descriptor

      if (wasDataChanged && isNonEmptyArray(onCloseCallbacks)) {
        onCloseCallbacks.forEach((subscriberCallbackItem) => {
          subscriberCallbackItem();
        });
      }

      // TODO: Not sure why. currently the function is repeated 3 times. Maybe use some dispatching, notification flag
      if (onCloseCallbacks) {
        setOnCloseCallbacks(undefined);
      }
      setDialogDescriptor({
        openDialogId: null,
        dialogParams: undefined,
      });
    },
    [onCloseCallbacks]
  );

  return (
    <GlobalPopupContext.Provider
      value={{
        openPopup: handleOpenDialogRequest,
        updatePopupParams: handleUpdateDialogParams,
        closePopup: handleCloseDialogWindow,
      }}
    >
      <PopupDialogComponents
        // userData={props.userData}
        // currentEnterpriseId={props.currentEnterpriseId}
        dialogParams={dialogDescriptor.dialogParams}
        openDialogId={dialogDescriptor.openDialogId}
        closeDialogWindow={handleCloseDialogWindow}
        // entityName={props.entityName}
      />
      {props.children}
    </GlobalPopupContext.Provider>
  );
}
