import React from "react";
import { IconComponentProps } from "./ProfessionalSet.d";

export function RainFallComponent(props: IconComponentProps) {
  const localHtmlColor = props.htmlColor || "#414959";

  return (
    <svg x="0px" y="0px" height="24" width="24">
      <path
        style={{ fill: "none", stroke: localHtmlColor, strokeWidth: 1.5, strokeMiterlimit: 10 }}
        d="M8.492,6.61c1.158-4.894,9.207-2.919,8.049,1.975
        c3.16-0.451,3.839,4.487,0.679,4.937H7.91C3.29,13.119,3.871,6.207,8.492,6.61c2.04,0.207,3.083,1.311,2.97,3.454 M9.452,13.613
        l-0.576,1.915 M8.6,16.446l-0.576,1.915 M12.207,15.433l0.576-1.915 M11.931,16.35l-0.576,1.915 M15.976,13.926L15.4,15.841
        M15.124,16.759l-0.576,1.915 M11.079,19.183l-0.576,1.915"
      />
    </svg>
  );
}
