import React from "react";
import { TableCellRendererProps } from "../TableCellRenderer.d";
import { getAttributeByPath } from "components/Crud/EntityUtils";
import { AlertLevelMessageBlock } from "views/Alert/AlertLevelMessageBlock";

export function DimmedAlertRenderer(props: TableCellRendererProps) {
  const eventLevelId = getAttributeByPath(props.obj, "level");
  let message = getAttributeByPath(props.obj, "message");
  const alertDate = getAttributeByPath(props.obj, "date");

  const triggerValue = getAttributeByPath(props.obj, "triggerValue");

  if (triggerValue > 0) {
    message = `${message}. Limit: ${triggerValue}cm`;
  }

  return (
    <div
      style={{
        height: "66px",
        // boxShadow: 'inset 0 -1px 0 0 #EEEEEE',
        paddingLeft: "8px",
        paddingRight: "8px",
        display: "flex",
        alignItems: "center",
      }}
    >
      <AlertLevelMessageBlock
        message={message}
        eventLevelId={eventLevelId}
        eventDate={alertDate}
        variant="small"
      />
    </div>
  );
}
