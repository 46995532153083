import React, { useState } from "react";
import { ObjectIdType, BasicEntityDataType } from "components/Utils/Object.d";
import { SamplePointListAssetSummary } from "../SamplePoint/SamplePointListAssetSummary";
import { useActiveUser, useActiveEnterprise } from "components/Utils/CustomHooks";
import { SamplePointSensorPairingList } from "../SamplePoint/SamplePointSensorPairing/SamplePointSensorPairingList";
import { isSpecified } from "components/Utils/MiscUtils";
import { ConditionalRender } from "layouts/ConditionalRender";

export interface AssetSamplePointConfigurationProps {
  assetId: ObjectIdType;
  siteData: BasicEntityDataType;
}

export function AssetSamplePointConfiguration(props: AssetSamplePointConfigurationProps) {
  const [activeSamplePoint, setActiveSamplePoint] = useState({ id: undefined, sampleTypeId: undefined });

  const currentUser = useActiveUser();
  const currentEnterprise = useActiveEnterprise();

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "row",
        paddingBottom: "20px",
        flexBasis: 0,
        flexGrow: 0.5,
      }}
    >
      <div style={{ flexBasis: "500px", flexShrink: 0 }}>
        <SamplePointListAssetSummary
          assetId={props.assetId}
          userData={currentUser}
          currentEnterpriseId={currentEnterprise.id}
          onRowClickFunction={(id: ObjectIdType, object: Record<string, any>) => {
            setActiveSamplePoint({
              id: id,
              sampleTypeId: object.sampleType.id,
            });
          }}
          activeRowPrimaryKeys={isSpecified(activeSamplePoint.id) ? [activeSamplePoint.id] : undefined}
          autoFocusOnFirstRow={true}
          contentToDisplayWhenNoData={<div>{"Asset does not have sensors"}</div>}
        />
      </div>
      <ConditionalRender condition={currentUser.isSuperAdmin}>
        <div style={{ flexGrow: 1, paddingLeft: "20px" }}>
          <SamplePointSensorPairingList
            samplePointId={isSpecified(activeSamplePoint.id) ? activeSamplePoint.id : -1}
            sampleTypeId={activeSamplePoint.sampleTypeId}
            siteId={props.siteData && props.siteData.id}
            userData={currentUser}
            currentEnterpriseId={currentEnterprise.id}
            contentToDisplayWhenNoData={<div>{"Choose sensor to display data"}</div>}
          />
        </div>
      </ConditionalRender>
    </div>
  );
}
