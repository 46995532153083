export const API_LIB = {
  ASSET_GROUP: "/asset-group",
  // TODO: arrange the following 3 items into one
  ASSET_GROUP_ASSET: "/asset",
  ASSET_GROUP_USER: "/asset-group-user",
  ASSET_INVENTORY_LIST: "/asset",
  ASSET: "/asset",
  ASSET_TYPE: "/asset-type",
  NOTIFICATIONS: "/notification",
  EVENT: "/event",
  CHANGE_PASSWORD: "/user/password",
  SAMPLE_POINT_SAMPLES_OVER_TIME: "/sample/samplepoint",
  SENSOR_SAMPLES_OVER_TIME: "/sample/sensor",
  ADDRESS_STATES: "/address/states",
  TRIGGER: "/trigger",
  TRIGGER_CONDITION: "/trigger-condition",
  TRIGGER_TEMPLATE: "/trigger-template",
  TRIGGER_CONDITION_TEMPLATE: "/trigger-condition-template",
  SAMPLE_FIELD: "/sample-field-type",
};
