import { StyleRulesCallback, Theme } from "@material-ui/core";

export const alertTriggerCardStyle: StyleRulesCallback<Theme, {}> = (theme) => ({
  root: {},
  checkBox: {
    color: theme.palette.primary.main,
  },
  boxToHighlightOnHover: {
    "&:hover": {
      boxShadow:
        "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
      borderRadius: "4px",
    },
  },
  containerWithHiddenForHooverElements: {
    "&:hover": {
      // boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
      "& .showOnHover": {
        display: "flex",
      },
    },
    "&:not(hover)": {
      "& .showOnHover": {
        display: "none",
      },
    },
  },
  cardCellSubHeadingText: {
    // Removed from - AssetMeasurementCardCellRenderer, LocationAndStatusRenderer
    fontSize: "12px",
    lineHeight: "18px",
    color: theme.palette.text.secondary,
  },
});
