import { StyleRulesCallback, Theme } from "@material-ui/core/styles";

export const headerEnterpriseNameStyle: StyleRulesCallback<Theme, {}> = (theme) => ({
  root: {
    minWidth: 0,
    flexGrow: 1,
  },
  textContent: {
    whiteSpace: "nowrap",
    overflowX: "hidden",
    textOverflow: "ellipsis",
  },
});
