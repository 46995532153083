export enum AlertAcknowledgementStatus {
  RAISED = 1,
  ACKNOWLEDGED = 2,
  SENT = 3,
  DELIVERY_FAILED = 400,
}

export enum NotificationType {
  UI = 1,
  EMAIL = 2,
  SMS = 3,
}

export interface AlertType {
  message: string;
}

export type VariantType = "small" | "large";
