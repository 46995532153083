import { StyleRulesCallback, Theme } from "@material-ui/core/styles";

export const mainScreenContentStyle: StyleRulesCallback<Theme, {}> = (theme) => ({
  root: {
    boxSizing: "border-box",
    width: "100%",
    overflowY: "auto",
  },
  largerThanMedium: {
    paddingTop: "48px",
    paddingLeft: "48px",
    paddingRight: "48px",
  },
  smallerThanMedium: {
    paddingTop: "4px",
    paddingLeft: "4px",
    paddingRight: "4px",
  },
});
