import React, { useContext } from "react";
import { FilterMatchTypes, SORT_ASC, UnifiedDataRequestType } from "components/Utils/CRUDUtils.d";
import { translateValue, filterBy } from "components/Utils/MiscUtils";
import { ALL_ENTERPRISES_CODE, ENTERPRISE_API_URL } from "views/Enterprise/Enterprise.const";
import { API_LIB } from "components/Utils/API_LIB";
import { APP_URL_PREFIX, refineRequest } from "components/Utils/RouteUtils";
import { ROUTE_LIB } from "components/Utils/RouteList";
import { BaseListProps, TableColumnParameters, TableColumnFilter } from "components/Crud/Table/EntityTable.d";
import { DataSourceParamsType } from "components/Crud/DataProvider/DataProviderCommon.d";
import { CrudQueryTableBinding } from "components/Crud/Table/CrudQueryTableBinding";
import { mergeRecords } from "components/Utils/ObjectUtils";
import { GlobalEventContext } from "GlobalContext";
import { GlobalEvent } from "GlobalContext.d";

export function AssetGroupList(props: BaseListProps) {
  const globalEventContext = useContext(GlobalEventContext);

  const tableColumns: TableColumnParameters[] = [
    {
      sourceName: "enterprise.name",
      label: "Enterprise",
      hide: props.currentEnterpriseId !== ALL_ENTERPRISES_CODE,
      hideForBreakpoints: { mdDown: true }, // TODO: show for super admin only
      width: 200,
    },
    {
      sourceName: "name",
      label: "Name",
      width: 200,
    },
  ];

  const uiFilters: Array<TableColumnFilter> = [
    {
      disable: props.currentEnterpriseId !== ALL_ENTERPRISES_CODE,
      filterType: "select-async",
      filterParams: {
        optionDefinitionEndpoint: ENTERPRISE_API_URL,
        optionCodeField: "id",
        optionLabelField: "name",
        renderEmptyValueAs: "ALL ENTERPRISES",
        autoComplete: true,
        placeholder: "ALL ENTERPRISES",
      },
      filterDataSourceField: "enterprise.id", // Because column uses name, but filter uses id
    },
    {
      filterType: "text",
      filterDataSourceField: "name",
      filterMatchType: FilterMatchTypes.CONTAINS,
      filterParams: {
        placeholder: "Name",
      },
    },
  ];

  const dataSource: DataSourceParamsType = {
    apiUrl: API_LIB.ASSET_GROUP,
  };

  let initialRequest: UnifiedDataRequestType = {
    fieldList: ["name", "enterprise.name"],
    filter: {
      baseFilters: [
        filterBy(
          "enterprise.id",
          "=",
          translateValue(props.currentEnterpriseId, [[ALL_ENTERPRISES_CODE, undefined]])
        ),
      ],
    },
    isPaginationEnabled: true,
    rowsPerPage: 10,
    pageNum: 0,
    sortByColumn: "name",
    sortDirection: SORT_ASC,
  };

  const navigateToAssetGroupData = (id: number) => {
    const path = `${APP_URL_PREFIX}${ROUTE_LIB.ASSET_GROUP_ASSET}`.replace(":groupId", `${id}`); // TODO: redo with function

    globalEventContext(
      /* .dispatchEvent */ {
        type: GlobalEvent.NAVIGATE,
        payload: path,
        originator: "AssetGroupList.navigateToAssetGroupData",
      }
    );
  };

  // to use URL defined query params
  const refinedRequestFromInput = refineRequest(props.inputRequest, uiFilters);
  initialRequest = mergeRecords(initialRequest, refinedRequestFromInput) as UnifiedDataRequestType;

  return (
    <CrudQueryTableBinding
      externalTriggerValues={[props.currentEnterpriseId]}
      dataSource={dataSource}
      title="Asset Groups"
      intialQuery={initialRequest}
      tableColumns={tableColumns}
      onRowClickFunction={(objId: number, object: Record<string, any>) => navigateToAssetGroupData(objId)}
      // actionsAllowed={allowedActions}
      highlightedRowsPrimaryKeys={props.activeRowPrimaryKeys}
      uiFilters={uiFilters}
      // to use URL defined query params
      allowUrlReplacementForFilters={props.allowUrlReplacementForFilters}
      allowReinitialization={props.allowReinitialization}
    />
  );
}
