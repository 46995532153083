import { isSpecified } from "components/Utils/MiscUtils";
import { drawText } from "components/Utils/CanvasUtils";

export function assetRoundedRectangleBanner(
  value?: string,
  units?: string,
  name?: string,
  valueColor?: string,
  discColor?: string
) {
  const canvasHeight = 60;
  const canvasWidth = 100;

  const markerCanvas = document.createElement("canvas");
  markerCanvas.width = canvasWidth;
  markerCanvas.height = canvasHeight;
  const ctx = markerCanvas.getContext("2d");

  ctx.strokeStyle = "black";
  ctx.lineWidth = 1;
  ctx.fillStyle = "white"; // '#307fbe';

  const r = 4;
  const x = 1;
  const y = 1;
  const w = 99;
  const h = 46;
  const tw = 8;
  const th = 10;

  ctx.beginPath();
  ctx.moveTo(x + r, y);
  ctx.lineTo(x + w - r, y);
  ctx.quadraticCurveTo(x + w, y, x + w, y + r);
  ctx.lineTo(x + w, y + h - r);
  ctx.quadraticCurveTo(x + w, y + h, x + w - r, y + h);

  ctx.lineTo(x + w / 2 + tw, y + h);
  ctx.lineTo(x + w / 2, y + h + th);
  ctx.lineTo(x + w / 2 - tw, y + h);

  ctx.lineTo(x + r, y + h);

  ctx.quadraticCurveTo(x, y + h, x, y + h - r);
  ctx.lineTo(x, y + r);
  ctx.quadraticCurveTo(x, y, x + r, y);
  ctx.closePath();

  // let p = new Path2D(`M 12.903292,37.842422 C 11.536477,37.587833 10.930501,37.400607 9.7310214,
  //   36.862278 6.302162,35.32343 3.6038615,32.214314 2.4779377,28.504909 1.6988561,
  //   25.938201 1.8701361,23.272025 3.0073252,20.264296 4.0825427,17.420485 6.8896181,
  //   12.589243 9.9743674,8.2733541 11.364412,6.3285213 14.246625,2.5920386 14.717771,
  //   2.1240053 c 0.128375,-0.127449 0.571003,-0.1730925 0.805851,-0.082908 0.138871,0.053361 1.563091,
  //   1.8374044 3.078872,3.8569198 1.926317,2.9303575 4.081617,5.7166379 5.919303,8.7326579 2.117549,
  //   3.533711 3.417721,6.587833 3.810829,8.951704 0.208901,1.256144 0.05629,3.155443 -0.375005,4.667257 -0.712102,
  //   2.496104 -1.67309,4.164032 -3.383328,5.87229 -1.271098,1.269595 -2.686732,2.225176 -4.159646,2.807803 -0.320872,
  //   0.127008 -0.714671,0.288855 -0.875109,0.359856 -0.320855,0.142002 -1.263382,0.37754 -2.224235,0.55588 -0.373659,
  //   0.06924 -1.184706,0.126567 -2.041922,0.144207 -1.271858,0.02624 -1.522184,0.01103 -2.370089,-0.147294 z`
  // );

  // TODO: user other files to make a drop without translation
  // ctx.fill(p);
  // ctx.stroke(p);
  ctx.fill();
  // ctx.stroke();

  if (isSpecified(discColor)) {
    ctx.save();
    ctx.beginPath();
    ctx.arc(15, 15, 9, 0, 2 * Math.PI);
    // ctx.closePath();
    ctx.fillStyle = "green";
    ctx.fill();
    ctx.restore();
  }

  if (isSpecified(value) || isSpecified(units)) {
    drawText(ctx, `${value} ${units}`, {
      containerWidth: canvasWidth,
      containerHeight: canvasHeight,
      y: 38,
      x: 30,
      color: valueColor || "black",
      outerStroke: { width: 0 },
      size: 12,
      // justify: 'center'
    });
  }

  if (isSpecified(name)) {
    drawText(ctx, name, {
      containerWidth: canvasWidth,
      containerHeight: canvasHeight,
      y: 20,
      x: 30,
      color: "black",
      outerStroke: { width: 0 },
      size: 12,
      padding: { right: 6 },
      // justify: 'center',
      // size: 20
    });
  }

  return markerCanvas;
}
