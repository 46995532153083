import React from "react";

import { TableCellRendererProps } from "components/Crud/Table/CellRenderers/TableCellRenderer.d";
import { hasAttributeByPath, getAttributeByPath } from "components/Crud/EntityUtils";

export function BootFlagRenderer(props: TableCellRendererProps) {
  let bootFlag = false;
  let value = "";

  if (hasAttributeByPath(props.obj, props.fieldName)) {
    bootFlag = getAttributeByPath(props.obj, props.fieldName);

    if (bootFlag) {
      value = String(bootFlag);
    }
  }

  return <div>{value}</div>;
}
