export const SAMPLE_TYPE_API = "/sample-type";

export const SAMPLEPOINT_API_URL = "/samplepoint";
export const SAMPLEPOINT_TYPE_API_URL = "/samplepoint-type";
export const SAMPLEPOINT_ROUTE = "/asset/:assetId/samplepoint";
export const SAMPLEPOINT_SAMPLE_API_URL = "/samplepoint/sample";
export const SAMPLEPOINT_SAMPLE_ROUTE = "/samplepoint/sample";
export const SAMPLEPOINT_ENTITY_NAME = "samplepoint";

export const SAMPLEPOINT_SENSOR_ACTION = "samplepoint_sensor";
