import React from "react";
import { ConditionalRender } from "../../layouts/ConditionalRender";
import { LinearProgress } from "@material-ui/core";

export interface LinearProgressOnConditionProps {
  condition: boolean;
}

export function LinearProgressOnCondition(props: LinearProgressOnConditionProps) {
  return (
    <ConditionalRender condition={props.condition}>
      <LinearProgress />
    </ConditionalRender>
  );
}
