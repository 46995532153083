import React from "react";

export interface ColorDiscProps {
  size: number;
  children?: string | JSX.Element | Array<JSX.Element>;
  htmlBackgroundColor: string;
}

export function ColorDisc(props: ColorDiscProps) {
  return (
    <div
      style={{
        height: props.size,
        width: props.size,
        borderRadius: "50%",
        backgroundColor: props.htmlBackgroundColor,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {props.children}
    </div>
  );
}
