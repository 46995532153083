import React from "react";
import { LinearProgressOnCondition } from "components/Elements/LinearProgressOnCondition";
import { Form } from "formik";
import { BlockOfFormikFormFields } from "./BlockOfFormikFormFields";
import { Grid, Button } from "@material-ui/core";
import { ConditionalRender } from "layouts/ConditionalRender";
import { getFormFieldsToShow } from "./EntityFormCommon";
import { SHOW_ACTION } from "../Entity.const";
import { BUTTON_VARIANT } from "components/Buttons/ButtonsCommon";
import {
  SetFieldValueDirectFunctionType,
  DispatchFieldsInProgressFunctionType,
  NotifyOnValidValuesChangeFunctionType,
  HandleSubmissionAttemptType,
  SubmitFormFunctionType,
  ConditionalFieldConfigurationFunctionType,
  HandleCloseFunctionType,
} from "./EntityForm.d";
import { ObjectIdType } from "components/Utils/Object.d";
import { EntityActionType } from "../Entity.d";
import { LayoutComponentType } from "./Layout/FormLayout.d";
import { FieldConfiguration } from "./Fields/FieldConfiguration.d";

export interface FormikRenderComponentProps {
  conditionalFieldConfiguration: ConditionalFieldConfigurationFunctionType;
  values: Record<string, any>;
  isSubmitting: boolean;
  initialValues: Record<string, any>;
  errors: Record<string, any>;
  handleChange: any; // TODO: typefy. Some of the fields components, do not support it uniformly
  action: EntityActionType;
  fieldConfigurations: Array<FieldConfiguration>;
  editModeOn: boolean;
  layoutComponent: LayoutComponentType;
  id: ObjectIdType;
  setFieldValue: SetFieldValueDirectFunctionType;
  notifyOnValidValuesChange: NotifyOnValidValuesChangeFunctionType;
  showCloseButton: boolean;
  handleClose: HandleCloseFunctionType;
  disableSubmitButton: boolean;
  handleSubmissionAttempt: HandleSubmissionAttemptType;
  submitForm: SubmitFormFunctionType;
  dispatchFieldsInProgress: DispatchFieldsInProgressFunctionType;
}

export function FormikRenderComponent(props: FormikRenderComponentProps) {
  if (props.conditionalFieldConfiguration) {
    props.conditionalFieldConfiguration(props.values);
  }

  return (
    <React.Fragment>
      <LinearProgressOnCondition condition={props.isSubmitting} />
      <Form>
        <BlockOfFormikFormFields
          initialValues={props.initialValues}
          values={props.values}
          errors={props.errors}
          handleChange={props.handleChange}
          fieldsToShow={getFormFieldsToShow(
            props.action,
            props.fieldConfigurations,
            props.values,
            props.initialValues
          )}
          fieldConfigurations={props.fieldConfigurations}
          editModeOn={props.editModeOn}
          action={props.action}
          layoutComponent={props.layoutComponent}
          id={props.id}
          setFieldValueDirect={props.setFieldValue}
          notifyOnValidValuesChange={props.notifyOnValidValuesChange}
          dispatchFieldsInProgress={props.dispatchFieldsInProgress}
        />
      </Form>
      <div>
        <Grid // TODO: change to normal div
          container
          direction="row"
          spacing={2}
          justify="flex-end"
          style={{ paddingTop: 8, paddingLeft: 8, paddingRight: 8 }}
        >
          <ConditionalRender condition={props.showCloseButton}>
            <Grid item>
              <Button /* variant={BUTTON_VARIANT} */ color="default" onClick={props.handleClose}>
                {props.action === SHOW_ACTION ? "Close" : "Cancel"}
              </Button>
            </Grid>
          </ConditionalRender>
          <Grid item>
            <ConditionalRender condition={props.action !== SHOW_ACTION && props.editModeOn}>
              <Button
                variant={BUTTON_VARIANT}
                color="primary"
                disabled={props.disableSubmitButton}
                onClick={() => props.handleSubmissionAttempt(props.submitForm)}
              >
                Save
              </Button>
            </ConditionalRender>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
}
