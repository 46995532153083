import React, { useState, useRef, useEffect } from "react";
import OutlinedFieldWrapper from "./OutlinedFieldWrapper";
import {
  TextField,
  MenuItem,
  MenuList,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  makeStyles,
  InputAdornment,
  useTheme,
} from "@material-ui/core";
import AsyncSelectOptionsProvider from "components/Crud/DataProvider/AsyncSelectOptionsProvider";
import {
  NONE_SELECT_CODE,
  NONE_SELECT_LABEL,
  MenuItemType,
  SelectAsyncConfiguration,
  FieldConfiguration,
  INPUT_VARIANT,
} from "./FieldConfiguration.d";
import { isSpecified } from "components/Utils/MiscUtils";
import { autoCompleteDropwDownStyle } from "./autoCompleteDropDownStyle";
// import KeyboardHideIcon from '@material-ui/icons/KeyboardHide';
import SearchIcon from "@material-ui/icons/Search";
import { useMediaQueryMatchDown } from "components/Utils/LayoutUtils";
import { CustomThemeType } from "assets/jss/muiTheme";

export interface AutoCompleteDropDownProps {
  fieldConfig: FieldConfiguration;
  required: boolean;
  onChange: (event: { target: { name: string; value: any } }) => void;
  helperText: string;
  errorFlag: boolean;
  disabled: boolean;
  value: string | number;
  customHeight?: string;
  valueWasModified?: boolean;
}

export const MAX_ITEMS_PER_DROP_DOWN = 7;

const useStyles = makeStyles(autoCompleteDropwDownStyle, { name: "AutoCompleteDropDown" });

export function AutoCompleteDropDown(props: AutoCompleteDropDownProps) {
  const classes = useStyles({});
  const anchorRef = React.useRef(null);
  const isSmallScreen = useMediaQueryMatchDown("sm");

  const [typedValue, setTypedValue] = useState("");
  const [selectedValue, setSelectedValue] = useState({
    code: "" as string | number,
    label: "",
  });

  const [optionsAreVisible, setOptionsAreVisible] = useState(false);

  const openOptions = () => {
    if (!optionsAreVisible) {
      setTypedValue("");
      setOptionsAreVisible(true);
    }
  };

  const handleType = (input: any) => {
    if (!optionsAreVisible) {
      setOptionsAreVisible(true);
    }

    setTypedValue(input.target.value);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setTypedValue(selectedValue.label);
    setOptionsAreVisible(false);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(optionsAreVisible);
  useEffect(() => {
    if (prevOpen.current === true && optionsAreVisible === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = optionsAreVisible;
  }, [optionsAreVisible]);

  const handleMenuItemClick = (event: any, code: string | number, label: string) => {
    setSelectedValue({ code: code, label: label });

    props.onChange({ target: { name: props.fieldConfig.fieldName, value: code } });

    setTypedValue(label);
    setOptionsAreVisible(false);
  };

  let filterUrl = (props.fieldConfig.fieldParams as SelectAsyncConfiguration).filterUrl || "";

  const joinSymbol = filterUrl === "" ? "?" : "&";

  if (isSpecified(typedValue) && typedValue !== "") {
    filterUrl = `${filterUrl}${joinSymbol}filter=${
      (props.fieldConfig.fieldParams as SelectAsyncConfiguration).optionLabelField
    }::$contL::${typedValue}`;
  } /* 
    TODO: I am not sure this code is relevant anymore, but struggle to recall the use case
    delete it if auto complete doesn't cause problems related to static values post July 19th

    else if (isSpecified(props.value) && props.value !== NONE_SELECT_CODE ) {
    let staticValues = (props.fieldConfig.fieldParams as SelectAsyncConfiguration).extraStaticValues;

    let valueInStaticValues: boolean;

    if (isNonEmptyArray(staticValues)) {
      valueInStaticValues = staticValues.find(staticItem => staticItem.code === props.value) ? true : false;
    }

    if (!valueInStaticValues) {
      filterUrl = `${filterUrl}${joinSymbol}filter=${(props.fieldConfig.fieldParams as SelectAsyncConfiguration).optionCodeField}::eq::${props.value}`;
    }

  }
 */
  const customizedInputProps = {
    style: {
      height: props.customHeight ? props.customHeight : (undefined as string | number),
    },
    endAdornment: (
      <InputAdornment position="end">
        <SearchIcon fontSize="small" htmlColor="#3C4858" />
      </InputAdornment>
    ),
  };

  const menuRef = useRef(null);

  const theme = useTheme() as CustomThemeType;

  return (
    <AsyncSelectOptionsProvider
      fieldName={props.fieldConfig.fieldName}
      fieldParams={props.fieldConfig.fieldParams as SelectAsyncConfiguration}
      value={selectedValue.code} // {props.fieldValue as string | number}
      filterUrl={filterUrl as string}
      extraStaticValues={(props.fieldConfig.fieldParams as SelectAsyncConfiguration).extraStaticValues}
    >
      {(optionsArray: Array<MenuItemType>, sanitizedValue: string | number) => {
        // TODO: Moved here to avoid running queries on rerenders. Move inside async select options provider.
        // let extraStaticValues = getAttributeByPath(props.fieldConfig, 'validation.required') === true ? undefined : [{code: NONE_SELECT_CODE, label: NONE_SELECT_LABEL}];

        let optionsWithEmptyValueIfNeeded: Array<MenuItemType>;

        if (props.required === true) {
          optionsWithEmptyValueIfNeeded = optionsArray;
        } else {
          const renderEmptyValue = (props.fieldConfig.fieldParams as SelectAsyncConfiguration)
            .renderEmptyValueAs
            ? (props.fieldConfig.fieldParams as SelectAsyncConfiguration).renderEmptyValueAs
            : NONE_SELECT_LABEL;

          optionsWithEmptyValueIfNeeded = [
            {
              code: NONE_SELECT_CODE,
              label: renderEmptyValue,
            },
            ...optionsArray,
          ];
        }

        const optionFromValue = optionsWithEmptyValueIfNeeded.find((item) => item.code === props.value);

        const valueFromOption = optionFromValue ? optionFromValue.label : "";

        const fieldValue = optionsAreVisible ? typedValue : valueFromOption;

        const menuContent = (
          <MenuList id="menu-list-grow">
            {optionsWithEmptyValueIfNeeded.length > 0 && (
              <MenuItem
                ref={menuRef}
                onClick={(event: any) =>
                  handleMenuItemClick(
                    event,
                    optionsWithEmptyValueIfNeeded[0].code,
                    optionsWithEmptyValueIfNeeded[0].label
                  )
                }
              >
                <span className={classes.ellipsisOnOverflow} style={{ maxWidth: 300 }}>
                  {optionsWithEmptyValueIfNeeded[0].label}
                </span>
              </MenuItem>
            )}
            {optionsWithEmptyValueIfNeeded.length > 1 &&
              optionsWithEmptyValueIfNeeded.slice(1, MAX_ITEMS_PER_DROP_DOWN).map((optionItem, index) => {
                return (
                  <MenuItem
                    key={index}
                    onClick={(event: any) => handleMenuItemClick(event, optionItem.code, optionItem.label)}
                  >
                    <span className={classes.ellipsisOnOverflow} style={{ maxWidth: 300 }}>
                      {optionItem.label}
                    </span>
                  </MenuItem>
                );
              })}
            {optionsWithEmptyValueIfNeeded.length > MAX_ITEMS_PER_DROP_DOWN ? (
              <MenuItem disabled>
                <i>more...</i>
              </MenuItem>
            ) : null}
            {optionsWithEmptyValueIfNeeded.length < 1 ? (
              <MenuItem disabled>
                <i>no data found</i>
              </MenuItem>
            ) : null}
          </MenuList>
        );

        return (
          <OutlinedFieldWrapper>
            <React.Fragment>
              <TextField
                style={{
                  borderRadius: "4px",
                  backgroundColor: props.valueWasModified ? theme.customColors.modifiedField : undefined,
                }}
                ref={anchorRef}
                id={props.fieldConfig.fieldName}
                variant={INPUT_VARIANT}
                onChange={handleType}
                onClick={openOptions}
                onKeyDown={(event: React.KeyboardEvent) => {
                  if (event.key === "ArrowDown" && menuRef && menuRef.current) {
                    menuRef.current.focus();
                  }
                }}
                value={fieldValue}
                size="small"
                fullWidth
                label={props.fieldConfig.label}
                error={props.errorFlag}
                required={props.required}
                helperText={props.helperText}
                autoComplete="off"
                InputProps={customizedInputProps}
                placeholder={(props.fieldConfig.fieldParams as SelectAsyncConfiguration).placeholder}
              />
              <Popper
                open={optionsAreVisible}
                className={classes.onTopOfPopup} // TODO: standardize indices for each level
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal={false}
                placement="bottom-start"
                modifiers={{
                  flip: {
                    enabled: isSmallScreen ? false : true,
                  },
                }}
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{ transformOrigin: placement === "bottom" ? "center top" : "center bottom" }}
                  >
                    <Paper elevation={8}>
                      <ClickAwayListener onClickAway={handleClose}>{menuContent}</ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </React.Fragment>
          </OutlinedFieldWrapper>
        );
      }}
    </AsyncSelectOptionsProvider>
  );
}
