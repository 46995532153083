import { useState, useEffect, useCallback, useContext, useRef } from "react";
import { fetchEntityById, deleteEntityById, createOrEditEntity } from "components/Utils/CRUDUtils";
import { OPERATION_SUCCESS, FetchResultType, OPERATION_ERROR } from "components/Utils/CRUDUtils.d";
import { CREATE_ACTION, EDIT_ACTION } from "../Entity.const";
import produce from "immer";
import {
  DataCrudProps,
  DataCrudDataType,
  DataCrudOperationStatusType,
  DataCrudOperationStatus,
  DataCrudEventType,
  CrudEventAction,
  DataCrudEventTypeInternal,
} from "./DataCrud.d";
import { isSpecified } from "components/Utils/MiscUtils";
import { MessageBannerDispatcherContext, MessageType } from "components/Dashboard/Message/MessageBannerFrame";
import { DataSourceParamsType } from "./DataProviderCommon.d";

export const CRUD_DISPATCHER = "CRUD";

export function DataCrud(props: DataCrudProps) {
  const messageBannerDispatcher = useContext(MessageBannerDispatcherContext);

  const emptyData: DataCrudDataType = {
    data: undefined,
    transactionId: 0,
  };

  const initialStatus: DataCrudOperationStatusType = {
    action: undefined,
    state: DataCrudOperationStatus.INITIAL,
    message: undefined,
    transactionId: 0,
    originatedInTransactionId: 0,
    data: emptyData,
  };

  // let [ transactionId, setTransactionId ] = useState(0);
  const transactionRef = useRef(0);

  // let [ data, setData ] = useState(emptyData);

  const [dataOperationStatus, setDataOperationStatus] = useState(initialStatus);

  const initialRequest: DataCrudEventTypeInternal = props.prefetchId
    ? {
        action: CrudEventAction.READ,
        payload: { id: props.prefetchId },
        originator: "DataCrud::initialRequest",
        submittedAtTransactionId: 0,
      }
    : undefined;

  const [activeRequest, setActiveRequest] = useState(initialRequest);

  const dispatchEvent = useCallback((event: DataCrudEventType) => {
    setActiveRequest(Object.assign({}, event, { submittedAtTransactionId: transactionRef.current }));
  }, []);

  // TODO: come up with a better architecture
  const {
    apiUrl,
    /* entityName,  */ useUrlAsIs,
    customUrlRequestFragment,
    additionalRequestParams,
  } = props.dataSource;
  const { method, body } = additionalRequestParams
    ? additionalRequestParams
    : { method: undefined, body: undefined };
  const externalTriggerValuesString = JSON.stringify(props.externalTriggerValues || []);
  useEffect(() => {
    let didCancel = false;

    const dataSource = {
      apiUrl,
      /* entityName,  */ useUrlAsIs,
      customUrlRequestFragment,
      additionalRequestParams: { method, body },
    };

    const executeRequest = async (
      dataSource: DataSourceParamsType,
      inputActiveRequest: DataCrudEventTypeInternal
    ) => {
      if (inputActiveRequest) {
        const transactionRefClosure = transactionRef;
        const newTransactionId = transactionRefClosure.current + 1; // transactionRef.current + 1;
        // setTransactionId(newTransactionId);
        transactionRefClosure.current = newTransactionId;

        setDataOperationStatus((prevStatus) => ({
          action: inputActiveRequest.action,
          state: DataCrudOperationStatus.EXECUTING,
          message: undefined,
          transactionId: newTransactionId,
          originatedInTransactionId: inputActiveRequest.submittedAtTransactionId,
          data: prevStatus.data,
        }));

        let result: FetchResultType;

        if (inputActiveRequest.action === CrudEventAction.CREATE) {
          result = await createOrEditEntity(
            CREATE_ACTION,
            undefined,
            inputActiveRequest.payload,
            dataSource.apiUrl,
            dataSource.customUrlRequestFragment
          );
        } else if (inputActiveRequest.action === CrudEventAction.UPDATE) {
          const payloadWithoutId = produce(inputActiveRequest.payload, (draftPayload) => {
            delete draftPayload.id;
          });

          result = await createOrEditEntity(
            EDIT_ACTION,
            inputActiveRequest.payload.id,
            payloadWithoutId,
            dataSource.apiUrl,
            dataSource.customUrlRequestFragment
          );
        } else if (inputActiveRequest.action === CrudEventAction.DELETE) {
          result = await deleteEntityById(inputActiveRequest.payload.id, dataSource.apiUrl);
        } else if (inputActiveRequest.action === CrudEventAction.READ) {
          result = await fetchEntityById(
            inputActiveRequest.payload.id,
            dataSource.apiUrl,
            dataSource.customUrlRequestFragment
          );
        }

        if (isSpecified(result)) {
          // TODO: improve using map
          let message: MessageType;

          if (inputActiveRequest.action === CrudEventAction.CREATE) {
            if (result.status.type === OPERATION_SUCCESS) {
              message = { messageType: OPERATION_SUCCESS, messageText: "Created Successfully" };
            } else {
              message = { messageType: OPERATION_ERROR, messageText: "Creation failed" };
            }
          } else if (inputActiveRequest.action === CrudEventAction.UPDATE) {
            if (result.status.type === OPERATION_SUCCESS) {
              message = { messageType: OPERATION_SUCCESS, messageText: "Updated Successfully" };
            } else {
              message = { messageType: OPERATION_ERROR, messageText: "Update failed" };
            }
          } else if (inputActiveRequest.action === CrudEventAction.DELETE) {
            if (result.status.type === OPERATION_SUCCESS) {
              message = { messageType: OPERATION_SUCCESS, messageText: "Deleted Successfully" };
            } else {
              message = { messageType: OPERATION_ERROR, messageText: "Deletion failed" };
            }
          }

          if (isSpecified(message)) {
            messageBannerDispatcher(/* .dispatchMessage */ message);
          }
        }

        if (isSpecified(result) && result.status.type === OPERATION_SUCCESS) {
          if (!didCancel) {
            setDataOperationStatus({
              action: inputActiveRequest.action,
              state: DataCrudOperationStatus.APPLIED_SUCCESSFULLY,
              message: undefined,
              transactionId: newTransactionId,
              originatedInTransactionId: inputActiveRequest.submittedAtTransactionId,
              data: {
                data: result.data,
                transactionId: newTransactionId,
              },
            });
            // setData({ data: result.data, transactionId: newTransactionId});
          }
        } else if (isSpecified(result)) {
          if (!didCancel) {
            setDataOperationStatus((prevStatus) => ({
              action: inputActiveRequest.action,
              state: DataCrudOperationStatus.ERROR,
              message: result.status.message,
              transactionId: newTransactionId,
              originatedInTransactionId: inputActiveRequest.submittedAtTransactionId,
              data: prevStatus.data,
            }));
          }
        }
      }
    };

    executeRequest(dataSource, activeRequest);

    return () => {
      didCancel = true;
    };
  }, [
    apiUrl,
    /* entityName,  */ useUrlAsIs,
    customUrlRequestFragment,
    method,
    body,
    activeRequest,
    externalTriggerValuesString,
    messageBannerDispatcher,
  ]);

  return props.render(
    dataOperationStatus.data,
    activeRequest,
    dataOperationStatus,
    dispatchEvent,
    // transactionId
    transactionRef.current
  );
}
