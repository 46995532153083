import React from "react";
import { UnifiedDataRequestType } from "components/Utils/CRUDUtils.d";
import { TableCellRendererProps } from "components/Crud/Table/CellRenderers/TableCellRenderer.d";
import { SimpleEnum } from "components/Crud/Table/CellRenderers/SimpleEnum";
import { UserParams } from "views/User/UserParams.d";
import { EnterpriseIdType } from "views/Enterprise/Enterprise.d";
import { ROUTE_LIB } from "components/Utils/RouteList";
import { TableColumnParameters, TableColumnFilter } from "components/Crud/Table/EntityTable.d";
import { ASSET_TYPES } from "components/Dictionaries/DictionariesCommon";
import { DataSourceParamsType } from "components/Crud/DataProvider/DataProviderCommon.d";
import { CrudQueryTableBinding } from "components/Crud/Table/CrudQueryTableBinding";
import { useVersion } from "components/Utils/CustomHooks";
import { FlexSpaceFiller } from "layouts/FlexSpaceFiller";
import RefreshIcon from "@material-ui/icons/Refresh";
import IconButton from "@material-ui/core/IconButton";

export interface AssetGroupResultsPreviewProps {
  userData: UserParams;
  currentEnterpriseId: EnterpriseIdType;
  trigger: boolean;
  filterConfiguration: Record<string, any>;
}

export const GROUP_CONFIGURATION_FILTER_RESULTS = "groupConfigurationFilterResults";
export const REFRESH_GROUP_FILTER_RESULTS_ACTION = "REFRESH";

function AssetGroupResultsPreview(props: AssetGroupResultsPreviewProps) {
  const [version, increaseVersion] = useVersion();

  const tableColumns: TableColumnParameters[] = [
    {
      sourceName: "name",
      label: "Name",
      width: "250px",
    },
    {
      sourceName: "assetTypeId",
      label: "Type",
      width: "100px",
      valueTransformationFunction: (rendererProps: TableCellRendererProps) => (
        <SimpleEnum options={ASSET_TYPES} {...rendererProps} />
      ),
    },
    {
      sourceName: "site.name",
      label: "Site",
      width: "250px",
    },
  ];

  const uiFilters: Array<TableColumnFilter> = [];

  const dataSource: DataSourceParamsType = {
    apiUrl: ROUTE_LIB.ASSET_GROUP_PREVIEW,
    useUrlAsIs: true,
    additionalRequestParams: { method: "POST", body: JSON.stringify(props.filterConfiguration) },
  };

  const initialRequest: UnifiedDataRequestType = {
    fieldList: [],
    filter: {
      baseFilters: [],
    },
    isPaginationEnabled: false,
  };

  /*   let headerActions = [{
    actionCode: REFRESH_GROUP_FILTER_RESULTS_ACTION,
    actionObject: GROUP_CONFIGURATION_FILTER_RESULTS,
    name: 'refresh',
    labelElement: <TableActionButtonFace><span>Refresh</span></TableActionButtonFace>,
    onClick: {
      type: 'action',
      fn: async () => {
        increaseVersion();
      }
    } as OnClickAction
  }];

  console.log(headerActions); */

  return (
    <div>
      <div style={{ height: "48px", display: "flex" }}>
        <FlexSpaceFiller />
        <IconButton onClick={() => increaseVersion()}>
          <RefreshIcon />
        </IconButton>
      </div>
      <CrudQueryTableBinding
        externalTriggerValues={[version, props.trigger /*, props.currentEnterpriseId, props.userData*/]}
        dataSource={dataSource}
        intialQuery={initialRequest}
        // headerActions={headerActions}
        tableColumns={tableColumns}
        actionsAllowed={[]}
        disablePaginationSection={true}
        uiFilters={uiFilters}
      />
    </div>
  );
}

export default AssetGroupResultsPreview;
