import React, { useState } from "react";
import { TableColumnFilter } from "../EntityTable.d";
import {
  mergePropertyFiltersIntoRequest,
  generateQueryStringByPropertyFilter,
  mergeFirstLevelPropertiesIntoRequest,
} from "components/Utils/CRUDUtils";
import { PropertyFilterCriteriaType, UnifiedDataRequestType } from "components/Utils/CRUDUtils.d";
import { CustomRadio, CustomRadioValueType, RadioOptionType } from "components/Radio/CustomRadio";
import { ScreenTitle } from "components/Elements/Typography/ScreenTitle";
import { isSpecified, isNonEmptyArray } from "components/Utils/MiscUtils";
import { FlexSpaceFiller } from "layouts/FlexSpaceFiller";
import { TableFiltersSection } from "./TableFiltersSection";
import { generateObjectTemplateFromKeysArray } from "components/Utils/ObjectUtils";
import { QueryEventAction, DataQueryEventType } from "components/Crud/DataProvider/DataQuery.d";
import { RenderIfSpecified } from "layouts/RenderIfSpecified";
import { MainScreenContentHeader } from "layouts/MainScreenContentHeader";
import { ConditionalRender } from "layouts/ConditionalRender";
import { RowLayout } from "layouts/RowLayout";
import { ActionButtonHandlerComponent } from "components/Buttons/ActionButtonHandlerComponent";
import { useHistory, useLocation } from "react-router-dom";
import { useMediaQueryMatchDown, px } from "components/Utils/LayoutUtils";
import { Paper, IconButton, Button } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { muiMobileHeaderHeight, muiRegularHeaderHeight } from "layouts/Dashboard/mainScreenStyle";
import SearchIcon from "@material-ui/icons/Search";
import { ActionButtonConfig } from "components/Buttons/Buttons.d";

export interface EntityTableHeaderProps {
  title?: string | JSX.Element;
  actionsAllowed: Array<string>; // TODO: Remove
  disableFilters?: boolean; // TODO: filterEnabled and disableFilters are duplicated parameters

  headerActions?: Array<ActionButtonConfig>;

  enableModeSwitch?: boolean;

  radioChangeFunction?: (newValue: CustomRadioValueType) => void;
  radioActiveValue?: CustomRadioValueType;
  radioOptions: Array<RadioOptionType>;

  uiFilters: Array<TableColumnFilter>;

  queryParams?: UnifiedDataRequestType;
  onRequestDataEventDispatcher?: (event: DataQueryEventType) => void;
  allowUrlReplacementForFilters?: boolean;
}

export function EntityTableHeader(props: EntityTableHeaderProps) {
  const history = useHistory();
  const location = useLocation();

  const handleFiltersUpdate = (event: PropertyFilterCriteriaType) => {
    let newDataRequest = mergeFirstLevelPropertiesIntoRequest(props.queryParams, {
      pageNum: 0,
    });
    newDataRequest = mergePropertyFiltersIntoRequest(newDataRequest, event);

    if (isSpecified(props.onRequestDataEventDispatcher)) {
      props.onRequestDataEventDispatcher({
        action: QueryEventAction.REQUEST_DATA,
        payload: newDataRequest,
        originator: "StatelessTable::handleFiltersUpdate",
      });
    }

    if (props.allowUrlReplacementForFilters) {
      // TODO: probably it should be done at the DataQueryBindingLevel
      generateQueryStringByPropertyFilter(newDataRequest, location, history);
    }
  };

  const filters = isNonEmptyArray(props.uiFilters) ? props.uiFilters.slice() : [];

  const filterSourceFields = filters.map((filterItem) => filterItem.filterDataSourceField);

  const filtersObject = generateObjectTemplateFromKeysArray(filterSourceFields);

  if (isSpecified(props.queryParams)) {
    Object.assign(filtersObject, props.queryParams.filter.propertyFilters);
  }

  const isSmallScreen = useMediaQueryMatchDown("sm");
  const [displayFiltersForMobile, setDisplayFiltersForMobile] = useState(false);

  return (
    <div>
      <ConditionalRender condition={isSpecified(props.title) || isNonEmptyArray(props.headerActions)}>
        <MainScreenContentHeader>
          <RenderIfSpecified value={props.title}>
            {(propsTitle) => (
              <div>
                <ConditionalRender
                  condition={typeof propsTitle === "string"}
                  onTrue={<ScreenTitle>{propsTitle}</ScreenTitle>}
                  onFalse={propsTitle}
                />
              </div>
            )}
          </RenderIfSpecified>
          <FlexSpaceFiller />
          <ConditionalRender
            condition={isSmallScreen && !props.disableFilters && isNonEmptyArray(props.uiFilters)}
          >
            <React.Fragment>
              <Button onClick={() => setDisplayFiltersForMobile((prevValue) => !prevValue)}>
                <span style={{ fontSize: "12px", padding: "0 4px" }}>Search</span>
                <SearchIcon fontSize="small" />
              </Button>
              <div
                style={{
                  display: displayFiltersForMobile !== true ? "none" : undefined,
                  width: "75vw",
                  height: `calc(100vh - ${px(
                    isSmallScreen ? muiMobileHeaderHeight : muiRegularHeaderHeight
                  )})`,
                  position: "absolute",
                  bottom: "0px",
                  right: "0px",
                  zIndex: 10000,
                }}
              >
                <Paper
                  elevation={4}
                  style={{
                    width: "100%",
                    height: "100%",
                    padding: "8px",
                    boxSizing: "border-box",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ fontWeight: "bold" }}>Search</div>
                    <div style={{ flexGrow: 1 }} />
                    <IconButton onClick={() => setDisplayFiltersForMobile(false)}>
                      <CloseIcon />
                    </IconButton>
                  </div>
                  <TableFiltersSection
                    direction={isSmallScreen === true ? "column" : "row"}
                    filters={filters}
                    activeFilterValues={filtersObject}
                    onFiltersChange={handleFiltersUpdate}
                  />
                </Paper>
              </div>
            </React.Fragment>
          </ConditionalRender>
          <ConditionalRender condition={!isSmallScreen}>
            <RowLayout spacingName="buttonSpacing">
              <RenderIfSpecified value={props.headerActions}>
                {(headerActions) =>
                  headerActions.map((actionDescriptorItem: ActionButtonConfig, index: number) => {
                    return (
                      <div key={index}>
                        <ActionButtonHandlerComponent
                          buttonConfig={actionDescriptorItem}
                          id={null}
                          object={null}
                          // eventDispatchers={props.actionProps.eventDispatchers}
                        />
                      </div>
                    );
                  })
                }
              </RenderIfSpecified>
            </RowLayout>
          </ConditionalRender>
        </MainScreenContentHeader>
      </ConditionalRender>
      <ConditionalRender
        condition={
          !isSmallScreen && ((!props.disableFilters && filters.length > 0) || props.enableModeSwitch === true)
        }
      >
        <div style={{ display: "flex", alignItems: "center", marginBottom: "12px" }}>
          <ConditionalRender condition={!props.disableFilters}>
            <TableFiltersSection
              filters={filters}
              activeFilterValues={filtersObject}
              onFiltersChange={handleFiltersUpdate}
            />
          </ConditionalRender>
          <FlexSpaceFiller />
          <ConditionalRender condition={props.enableModeSwitch}>
            <CustomRadio
              // itemWidth={36}
              value={props.radioActiveValue}
              options={props.radioOptions}
              onChange={props.radioChangeFunction}
            />
          </ConditionalRender>
        </div>
      </ConditionalRender>
      <ConditionalRender condition={props.enableModeSwitch && isSmallScreen}>
        <CustomRadio
          // itemWidth={36}
          value={props.radioActiveValue}
          options={props.radioOptions}
          onChange={props.radioChangeFunction}
        />
      </ConditionalRender>
    </div>
  );
}
