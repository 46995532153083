import { FieldConfiguration } from "./Fields/FieldConfiguration.d";
import { getAttributeByPath, hasAttributeByPath } from "../EntityUtils";
import { isSpecified } from "components/Utils/MiscUtils";
import { EntityActionType } from "../Entity.d";

export const safeFieldValue = (value: any, scenarioDefaultValue: any, lastResortDefaultValue: any) => {
  const safeValue = isSpecified(value) /*value !== undefined*/
    ? value
    : scenarioDefaultValue !== undefined
    ? scenarioDefaultValue
    : lastResortDefaultValue;

  return safeValue;
};

export const getFormFieldsToShow = (
  action: EntityActionType,
  fieldConfigurations: FieldConfiguration[],
  values: Record<string, any>,
  initialValues: Record<string, any>
) => {
  const fieldsToShow: Record<string, any> = {};

  for (const fieldConfig of fieldConfigurations) {
    fieldsToShow[fieldConfig.fieldName] = true;

    if (fieldConfig.hideIf) {
      const fieldValue = getAttributeByPath(values, fieldConfig.fieldName);

      fieldsToShow[fieldConfig.fieldName] = !fieldConfig.hideIf(fieldValue, values, action, initialValues);
    }
  }

  return fieldsToShow;
};

export const formFieldIsEmpty = (value: any) => {
  // TODO: it has a duplicate function isFormSpecified
  let result;

  if (!isSpecified(value)) {
    result = true;
  } else if (value === "") {
    result = true;
  } else {
    result = false;
  }

  return result;
};

export const getRequiredFlagValue = (
  fieldValue: string | number,
  fieldConfig: FieldConfiguration,
  values: Record<string, any>,
  editType: string
) => {
  const requiredPropertyDefinition = fieldConfig.validation && fieldConfig.validation.required;

  let fieldIsRequired: boolean;
  if (!isSpecified(requiredPropertyDefinition)) {
    fieldIsRequired = false;
  } else if (typeof requiredPropertyDefinition === "boolean") {
    fieldIsRequired = requiredPropertyDefinition;
  } else {
    fieldIsRequired = (requiredPropertyDefinition)(
      fieldValue,
      values,
      editType
    );
  }

  return fieldIsRequired;
};

export const getDisabledFlagValue = (
  fieldValue: string | number,
  fieldConfig: FieldConfiguration,
  values: Record<string, any>
) => {
  const disabledPropertyDefinition = fieldConfig.disabled;

  let fieldIsDisabled: boolean;
  if (!isSpecified(disabledPropertyDefinition)) {
    fieldIsDisabled = false;
  } else if (typeof disabledPropertyDefinition === "boolean") {
    fieldIsDisabled = disabledPropertyDefinition;
  } else {
    fieldIsDisabled = (disabledPropertyDefinition)(fieldValue, values);
  }

  return fieldIsDisabled;
};

export const formValidationErrors = (
  values: Record<string, any>,
  fieldsToShow: Record<string, boolean>,
  fieldConfigurations: Array<FieldConfiguration>,
  editType: string
) => {
  const errors: Record<string, any> = {};

  for (const fieldConfig of fieldConfigurations) {
    // If the field needs to be displayed and has validations, run the validations
    if (fieldConfig.validation && fieldsToShow[fieldConfig.fieldName]) {
      const validation = fieldConfig.validation;
      const fieldValue = getAttributeByPath(values, fieldConfig.fieldName);

      const isFieldPresent = hasAttributeByPath(values, fieldConfig.fieldName); // && fieldValue !== '';

      const fieldIsRequired: boolean = getRequiredFlagValue(fieldValue, fieldConfig, values, editType);

      if (
        fieldIsRequired &&
        isFieldPresent &&
        (!isSpecified(fieldValue) ||
          fieldValue === "" ||
          (typeof fieldValue === "string" && fieldValue.trim() === "") ||
          (Array.isArray(fieldValue) && fieldValue.length === 0))
      ) {
        errors[fieldConfig.fieldName] = `${fieldConfig.label} is required`;
      } else if (fieldIsRequired && !isFieldPresent) {
        errors[fieldConfig.fieldName] = `${fieldConfig.label} is required`;
      }

      if (isFieldPresent && isSpecified(fieldValue) && fieldValue !== "") {
        if (fieldConfig.type === "date-time" || fieldConfig.type === "date") {
          if (typeof fieldValue === "object" && fieldValue.toString() === "Invalid Date") {
            errors[fieldConfig.fieldName] = `${fieldValue}`;
          }
        }

        if (validation.minLength && fieldValue.length < validation.minLength) {
          errors[
            fieldConfig.fieldName
          ] = `${fieldConfig.label} needs to be at least ${validation.minLength} chars long`;
        }

        if (validation.maxLength && fieldValue.length > validation.maxLength) {
          errors[
            fieldConfig.fieldName
          ] = `${fieldConfig.label} cannot be longer than ${validation.maxLength} chars`;
        }

        if (validation.validationFunction && !validation.validationFunction(fieldValue, values)) {
          let errorMessage;

          if (validation.errorMessage) {
            errorMessage = validation.errorMessage;
          } else {
            errorMessage = "Validation function failed";
          }

          errors[fieldConfig.fieldName] = errorMessage;
        }

        if (validation.regExp && !validation.regExp.test(fieldValue)) {
          let errorMessage;

          if (validation.errorMessage) {
            errorMessage = validation.errorMessage;
          } else {
            errorMessage = `Value of ${fieldConfig.label} does not match the pattern`;
          }

          errors[fieldConfig.fieldName] = errorMessage;
        }
      }
    }
  }

  return errors;
};
