import { priorityDictionary } from "components/Dictionaries/DictionariesCommon";
import { FieldConfiguration } from "components/Crud/Form/Fields/FieldConfiguration.d";

export const triggerTemplateFormFields: Array<FieldConfiguration> = [
  {
    fieldName: "name",
    label: "Name",
    type: "text",
    validation: { required: true },
  },
  {
    fieldName: "message",
    label: "Message",
    type: "text",
    validation: { required: true },
  },
  {
    fieldName: "eventLevel",
    label: "Event Level",
    type: "select",
    validation: { required: true },
    fieldParams: {
      options: priorityDictionary.map((item) => ({ code: item.code, label: item.label })),
    },
  },
  {
    fieldName: "timeBeforeReTrigger",
    label: "Time Before Re-Triger (seconds)",
    type: "number",
    validation: { required: false },
  },
  {
    fieldName: "comments",
    label: "Comments",
    type: "text",
    validation: { required: false },
    fieldParams: {
      rows: 4,
    },
  },
];
