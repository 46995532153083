import React from "react";
import { MainPanelWithParamsProps } from "components/Dashboard/MainPanelWithParams.d";
import { EntityEditRouteParams } from "components/Dashboard/EntityEditRouteParams.d";
import { AssetGroupConfigurationNewExtendedView } from "./AssetGroupConfigurationNewExtendedView";

function AssetGroupConfigurationScreen(props: MainPanelWithParamsProps<EntityEditRouteParams>) {
  return (
    <AssetGroupConfigurationNewExtendedView
      id={props.match.params.id}
      action={props.match.params.action}
      currentEnterpriseId={props.currentEnterpriseId}
      userData={props.userData}
    />
  );
}

export default AssetGroupConfigurationScreen;
