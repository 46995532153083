import React from "react";
import { PortletCard } from "components/Cards/PortletCard";
import { RegularCardValue } from "components/Elements/Typography/RegularCardValue";
import { SubordinateCardValue } from "components/Elements/Typography/SubordinateCardValue";

export function LocalNoticesDemoPortlet() {
  return (
    <PortletCard title="Local Notices">
      {[
        <div key={1}>
          <div>
            <RegularCardValue>{"Drought Warning"}</RegularCardValue>
          </div>
          <div>
            <SubordinateCardValue>{"02/02/2010"}</SubordinateCardValue>
          </div>
        </div>,
        <div key={2}>
          <div>
            <RegularCardValue>{"Cold Front Predicted for the next week"}</RegularCardValue>
          </div>
          <div>
            <SubordinateCardValue>{"02/02/2010"}</SubordinateCardValue>
          </div>
        </div>,
        <div key={3}>
          <div>
            <RegularCardValue>{"Drought Warning"}</RegularCardValue>
          </div>
          <div>
            <SubordinateCardValue>{"02/02/2010"}</SubordinateCardValue>
          </div>
        </div>,
        <div key={4}>
          <div>
            <RegularCardValue>{"Cold Front Predicted for the next week"}</RegularCardValue>
          </div>
          <div>
            <SubordinateCardValue>{"02/02/2010"}</SubordinateCardValue>
          </div>
        </div>,
      ]}
    </PortletCard>
  );
}
