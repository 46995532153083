import React from "react";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import customRadioStyle from "./customRadioStyle";
import { isSpecified } from "components/Utils/MiscUtils";
import { px } from "components/Utils/LayoutUtils";

// TODO: similar with DressedDropdownOption
export interface RadioOptionType {
  code: number | boolean | string;
  label: string;
  labelElement: JSX.Element;
}

export type CustomRadioValueType = string | number | boolean;

export interface CustomRadioProps {
  value: CustomRadioValueType;
  options: Array<RadioOptionType>;
  onChange: (newValue: CustomRadioValueType) => void;
  itemWidth?: number;
}

const useStyles = makeStyles(customRadioStyle, { name: "CustomRadio" });

// TODO: current implementation is done for speed of development. because MUI is very bad at this atm. REDO with proper radio buttons

export function CustomRadio(props: CustomRadioProps) {
  const classes = useStyles({});

  const customWidthStyleProps = {} as Record<string, string>;

  if (isSpecified(props.itemWidth)) {
    customWidthStyleProps.width = px(props.itemWidth);
  }

  return (
    <div className={classes.root}>
      {props.options.map((item: RadioOptionType, index: number) => {
        return (
          <div
            key={index}
            className={clsx(props.value === item.code ? classes.checked : classes.unchecked)}
            onClick={() => props.onChange(item.code)}
            style={customWidthStyleProps}
          >
            {item.labelElement && <div className={classes.radioIconLabel}>{item.labelElement}</div>}
            {item.label && <div className={classes.radioTextLabel}>{item.label}</div>}
          </div>
        );
      })}
    </div>
  );
}
