import React from "react";
import { getAttributeByPath } from "components/Crud/EntityUtils";
import { useMediaQueryMatchDown } from "components/Utils/LayoutUtils";
import { AssetNameAndTypeCardCellRenderer } from "components/Crud/Table/CellRenderers/AssetCardCells/AssetNameAndTypeCardCellRenderer";
import { BasicActionSetRenderer } from "components/Crud/Table/CellRenderers/BasicActionSetRenderer";
import { TableCellRendererProps } from "components/Crud/Table/CellRenderers/TableCellRenderer.d";
import { SingleRowNotificationCard } from "./SingleRowNotificationCard";
import { ACTION_NAVIGATE_TO_ASSET } from "views/asset/Asset/Asset.const";

export function EnterpriseLevelAlertRowCard(props: TableCellRendererProps) {
  const isSmallScreen = useMediaQueryMatchDown("sm");

  const viewAssetActionProps = {
    actionSet: props.actionProps.actionSet.filter((item) => item.actionCode === ACTION_NAVIGATE_TO_ASSET),
    eventDispatchers: props.actionProps.eventDispatchers,
  };

  if (isSmallScreen) {
    return (
      <div style={{ padding: "16px 0" }}>
        <div>
          <AssetNameAndTypeCardCellRenderer
            assetName={getAttributeByPath(props.obj, "asset.name")}
            assetTypeId={getAttributeByPath(props.obj, "asset.assetTypeId")}
            locationName={getAttributeByPath(props.obj, "asset.site.name")}
            statusId={getAttributeByPath(props.obj, "id")}
            borderRight={false}
            borderLeft={false}
            borderBottom={true}
          />
        </div>

        <div>
          <SingleRowNotificationCard {...props} />
        </div>
        <div>
          <BasicActionSetRenderer
            obj={props.obj}
            fieldName={props.fieldName}
            actionProps={viewAssetActionProps}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div
        style={{
          display: "flex",
          flexGrow: 1,
          height: "120px",
          boxSizing: "border-box",
        }}
      >
        <div style={{ boxSizing: "border-box", width: "258px", paddingTop: "24px", paddingBottom: "24px" }}>
          <AssetNameAndTypeCardCellRenderer
            assetName={getAttributeByPath(props.obj, "asset.name")}
            assetTypeId={getAttributeByPath(props.obj, "asset.assetTypeId")}
            locationName={getAttributeByPath(props.obj, "asset.site.name")}
            statusId={getAttributeByPath(props.obj, "id")}
            borderRight={true}
            borderLeft={false}
          />
        </div>
        <div style={{ flexGrow: 1, height: "100%" }}>
          <SingleRowNotificationCard {...props} />
        </div>
        <BasicActionSetRenderer
          obj={props.obj}
          fieldName={props.fieldName}
          actionProps={viewAssetActionProps}
        />
      </div>
    );
  }
}
