import {
  SimpleAsyncDataStateType,
  SimpleAsyncDataEventType,
  SimpleAsyncEvent,
  DataType,
} from "./useSimpleAsyncData.d";
import { ReducerFunctionType } from "global.d";

export const simpleAsyncDataReducer: ReducerFunctionType<
  SimpleAsyncDataStateType,
  SimpleAsyncDataEventType
> = (prevState, event) => {
  switch (event.action) {
    case SimpleAsyncEvent.LOADING:
      return {
        isLoading: true,
        data: prevState.data,
        error: prevState.error,
      } as SimpleAsyncDataStateType;
    case SimpleAsyncEvent.LOAD_ERROR:
      return {
        isLoading: false,
        data: prevState.data,
        error: event.payload as string,
      } as SimpleAsyncDataStateType;
    case SimpleAsyncEvent.LOAD_SUCCESS:
      return {
        isLoading: false,
        data: event.payload as DataType,
        error: null as string,
      } as SimpleAsyncDataStateType;
    default:
      return prevState;
  }
};
