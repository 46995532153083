import React from "react";
import { makeStyles } from "@material-ui/core";
import { addButtonStyle } from "./addButtonStyle";

export interface AddButtonFaceProps {
  children: any;
}

const useAddButtonStyles = makeStyles(addButtonStyle, { name: "AddButtonFace" });

export function AddButtonFace(props: AddButtonFaceProps) {
  const classes = useAddButtonStyles({});

  return <div className={classes.root}>{props.children}</div>;
}
