import {
  DatasetManagerDatasetType,
  DatasetReducerEventType,
  DatasetReducerEventActionType,
  DatasetMutationErrorPayloadType,
  DatasetLoadErrorPayloadType,
  DatasetManagerStatusType,
  DatasetLoadSuccessPayloadType,
  DatasetMutationExecutionPayloadType,
  DatasetMutationSuccessPayloadType,
} from "./DatasetManager.d";
import { ObjectWithIdFieldType } from "components/Utils/Object.d";
import { ReducerFunctionType } from "global.d";

export const datasetReducer: ReducerFunctionType<DatasetManagerDatasetType, DatasetReducerEventType> = (
  prevState,
  event
) => {
  switch (event.action) {
    case DatasetReducerEventActionType.SET_ERROR:
      const errorEventPayload = event.payload as DatasetMutationErrorPayloadType | DatasetLoadErrorPayloadType;

      return {
        status: DatasetManagerStatusType.STATIC,
        mutatingItemId: null,
        dataArray: prevState.dataArray,
        totalRows: prevState.totalRows,
        lastOperationResult: {
          isSuccess: false,
          operationType: errorEventPayload.operationType,
          message: errorEventPayload.message,
          relatedId: errorEventPayload.relatedId,
        },
      };

    case DatasetReducerEventActionType.LOADING:
      // let loadingEventPayload = event.payload as DatasetLoadExecutionPayloadType

      return {
        status: DatasetManagerStatusType.LOADING,
        mutatingItemId: null,
        dataArray: [] as Array<ObjectWithIdFieldType>,
        totalRows: 0,
        lastOperationResult: null,
      };

    case DatasetReducerEventActionType.POPULATE:
      const populateEventPayload = event.payload as DatasetLoadSuccessPayloadType;

      return {
        status: DatasetManagerStatusType.STATIC,
        mutatingItemId: null,
        dataArray: populateEventPayload.data,
        totalRows: populateEventPayload.totalRows,
        lastOperationResult: {
          isSuccess: true,
          operationType: DatasetReducerEventActionType.POPULATE,
          relatedId: null,
        },
      };

    case DatasetReducerEventActionType.MUTATING:
      const mutationEventPayload = event.payload as DatasetMutationExecutionPayloadType;

      return {
        status: DatasetManagerStatusType.MUTATING,
        mutatingItemId: mutationEventPayload.id,
        dataArray: prevState.dataArray,
        totalRows: prevState.totalRows,
        lastOperationResult: null,
      };

    case DatasetReducerEventActionType.ADD:
      const addEventPayload = event.payload as DatasetMutationSuccessPayloadType;

      return {
        status: DatasetManagerStatusType.STATIC,
        mutatingItemId: null,
        dataArray: prevState.dataArray.concat(addEventPayload),
        totalRows: prevState.totalRows + 1,
        lastOperationResult: {
          isSuccess: true,
          operationType: DatasetReducerEventActionType.ADD,
          relatedId: addEventPayload.id,
        },
      };

    case DatasetReducerEventActionType.UPDATE:
      const updateEventPayload = event.payload as DatasetMutationSuccessPayloadType;

      return {
        status: DatasetManagerStatusType.STATIC,
        mutatingItemId: null,
        dataArray: prevState.dataArray.map((item) => {
          if (item.id !== updateEventPayload.id) {
            return item;
          } else {
            return updateEventPayload;
          }
        }),
        totalRows: prevState.totalRows,
        lastOperationResult: {
          isSuccess: true,
          operationType: DatasetReducerEventActionType.UPDATE,
          relatedId: updateEventPayload.id,
        },
      };

    case DatasetReducerEventActionType.DELETE:
      const deleteEventPayload = event.payload as DatasetMutationSuccessPayloadType;

      return {
        status: DatasetManagerStatusType.STATIC,
        mutatingItemId: null,
        dataArray: prevState.dataArray.filter((item) => item.id !== deleteEventPayload.id),
        totalRows: prevState.totalRows - 1,
        lastOperationResult: {
          isSuccess: true,
          operationType: DatasetReducerEventActionType.DELETE,
          relatedId: deleteEventPayload.id,
        },
      };
    default:
      return prevState;
  }
};
