import { useState, useEffect, useCallback, useContext, useRef } from "react";
import { OPERATION_SUCCESS, FetchResultType, OPERATION_ERROR } from "components/Utils/CRUDUtils.d";
import { fetchEntityListByQuery, fetchDataByFullURL } from "components/Utils/CRUDUtils";
import {
  DataQueryProps,
  DataQueryDataType,
  DataQueryOperationStatusType,
  DataQueryOperationStatus,
  DataQueryEventType,
  QueryEventAction,
  DataQueryEventTypeInternal,
} from "./DataQuery.d";
import { isSpecified } from "components/Utils/MiscUtils";
import { MessageBannerDispatcherContext, MessageType } from "components/Dashboard/Message/MessageBannerFrame";
import { preUrl } from "views/constants";
import { compareObjects } from "components/Utils/ObjectUtils";
import { DataSourceParamsType } from "./DataProviderCommon.d";

export const QUERY_DISPATCHER = "QUERY";

export function DataQuery(props: DataQueryProps) {
  const messageBannerDispatcher = useContext(MessageBannerDispatcherContext);

  const emptyData: DataQueryDataType = {
    data: undefined,
    totalRows: undefined,
    transactionId: 0,
  };

  const intialStatus: DataQueryOperationStatusType = {
    action: undefined,
    state: DataQueryOperationStatus.INITIAL,
    message: undefined,
    transactionId: 0,
    originatedInTransactionId: 0,
    data: emptyData,
  };

  const intialQueryRequest = props.initialQuery
    ? {
        action: QueryEventAction.REQUEST_DATA,
        payload: props.initialQuery,
        originator: "DataQuery::props.initialQuery",
        submittedAtTransactionId: 0,
      }
    : undefined;

  // let [ transactionId, setTransactionId ] = useState(0);

  const transactionRef = useRef(0);

  // let [ data, setData ] = useState(emptyData);
  const [dataOperationStatus, setDataOperationStatus] = useState(intialStatus);
  const [activeQuery, setActiveQuery] = useState(intialQueryRequest);

  const dispatchEvent = useCallback((event: DataQueryEventType) => {
    setActiveQuery(Object.assign({}, event, { submittedAtTransactionId: transactionRef.current }));
  }, []);

  const queryComparisonResult = compareObjects(props.initialQuery, activeQuery.payload);

  useEffect(() => {
    if (props.allowReinitialization) {
      if (!queryComparisonResult) {
        dispatchEvent({
          action: QueryEventAction.REQUEST_DATA,
          payload: props.initialQuery,
          originator: "DataQuery::props.initialQuery - reinitialized",
        });
      }
    }
  }, [props.allowReinitialization, props.initialQuery, dispatchEvent, queryComparisonResult]);

  // TODO: come up with a better architecture
  const {
    apiUrl,
    /* entityName,  */ useUrlAsIs,
    customUrlRequestFragment,
    additionalRequestParams,
  } = props.dataSource;
  const { method, body } = additionalRequestParams
    ? additionalRequestParams
    : { method: undefined, body: undefined };
  const externalTriggerValuesString = JSON.stringify(props.externalTriggerValues || []);
  useEffect(() => {
    let didCancel = false;

    const dataSource = {
      apiUrl,
      /* entityName, */ useUrlAsIs,
      customUrlRequestFragment,
      additionalRequestParams: { method, body },
    };

    const executeQuery = async (
      dataSource: DataSourceParamsType,
      inputActiveQuery: DataQueryEventTypeInternal
    ) => {
      if (inputActiveQuery) {
        const transactionRefClosure = transactionRef;
        const newTransactionId = transactionRefClosure.current + 1; // transactionRef.current + 1;
        // setTransactionId(newTransactionId);
        transactionRefClosure.current = newTransactionId;

        setDataOperationStatus((prevStatus) => ({
          action: QueryEventAction.REQUEST_DATA,
          state: DataQueryOperationStatus.LOADING,
          message: undefined,
          transactionId: newTransactionId,
          originatedInTransactionId: undefined,
          data: prevStatus.data,
        }));

        let result: FetchResultType;
        if (inputActiveQuery.action === QueryEventAction.REQUEST_DATA) {
          if (dataSource.useUrlAsIs) {
            const additionalRequestParams =
              dataSource.additionalRequestParams || ({} as { method?: any; body?: any });

            result = await fetchDataByFullURL(
              `${preUrl}${dataSource.apiUrl}`,
              "fetch as is",
              additionalRequestParams.method,
              additionalRequestParams.body
            );
          } else {
            result = await fetchEntityListByQuery(
              inputActiveQuery.payload,
              dataSource.apiUrl,
              dataSource.customUrlRequestFragment
            );
          }
        }

        if (newTransactionId === transactionRef.current) {
          // TODO: consider moving transaction to ref
          if (isSpecified(result)) {
            let message: MessageType;

            if (result.status.type === OPERATION_ERROR) {
              message = { messageType: OPERATION_ERROR, messageText: "Could not retrieve data" };
            }

            if (isSpecified(message)) {
              messageBannerDispatcher(/* .dispatchMessage */ message);
            }
          }

          if (isSpecified(result) && result.status.type === OPERATION_SUCCESS) {
            if (!didCancel) {
              setDataOperationStatus({
                action: inputActiveQuery.action,
                state: DataQueryOperationStatus.DATA_AVAILABLE,
                message: undefined,
                transactionId: newTransactionId,
                originatedInTransactionId: inputActiveQuery.submittedAtTransactionId,
                data: {
                  data: result.data,
                  totalRows: result.totalRows,
                  transactionId: newTransactionId,
                },
              });
              // setData(prevData => ({ data: result.data, totalRows: result.totalRows, transactionId: newTransactionId}));
            }
          } else if (isSpecified(result)) {
            if (!didCancel) {
              setDataOperationStatus((prevData) => ({
                action: inputActiveQuery.action,
                state: DataQueryOperationStatus.ERROR,
                message: result.status.message,
                transactionId: newTransactionId,
                originatedInTransactionId: inputActiveQuery.submittedAtTransactionId,
                data: prevData.data,
              }));
            }
          }
        }
      }
    };

    if (
      !isSpecified(dataSource.additionalRequestParams) ||
      !(
        dataSource.additionalRequestParams.method === "POST" &&
        dataSource.additionalRequestParams.body === undefined
      )
    ) {
      executeQuery(dataSource, activeQuery);
    }

    return () => {
      didCancel = true;
    };
  }, [
    apiUrl,
    /* entityName, */ useUrlAsIs,
    customUrlRequestFragment,
    method,
    body,
    activeQuery,
    externalTriggerValuesString,
    messageBannerDispatcher,
  ]);

  return props.render(
    dataOperationStatus.data,
    activeQuery.payload,
    dataOperationStatus,
    dispatchEvent,
    // transactionId
    transactionRef.current
  );
}
