export type OperationStatus = "success" | "error";
export const OPERATION_SUCCESS: OperationStatus = "success";
export const OPERATION_ERROR: OperationStatus = "error";

export type Order = "asc" | "desc";
export const SORT_ASC: Order = "asc";
export const SORT_DESC: Order = "desc";

export interface UnifiedDataRequestType {
  fieldList?: Array<string>;
  filter: FilterParameters;
  isPaginationEnabled?: boolean; // TODO: copied as is to minimize amount of changes. Refactor
  rowsPerPage?: number;
  pageNum?: number;
  sortByColumn?: string;
  sortDirection?: Order;
}

export type PropertyFilterCriteriaType = Record<string, PropertyFilterCriteria>;

export interface FilterParameters {
  baseFilters?: FilterCriteria[];
  propertyFilters?: PropertyFilterCriteriaType;
}

export interface ColumnRequestObject {
  fields?: string;
  join?: Array<string>;
}
export interface RequestNode {
  NODE_NAME: string | null;
  NODES?: Array<RequestNode>;
  LEAVES?: Array<string>;
}

export enum FilterMatchTypes {
  EQUAL,
  NOT_EQUAL,
  CONTAINS,
  GREATER_THAN,
  LOWER_THAN,
  IS_NOT_EMPTY,
  IS_EMPTY,
  DEFAULT, // default criteria for filter type
}

// TODO: refactor to avoid duplication with FilterCriteria
export interface PropertyFilterCriteria {
  attributeValue?: string | number;
  criteriaType: FilterMatchTypes;
}

export interface FilterCriteria {
  attributeName: string;
  attributeValue?: string | number;
  criteriaType: FilterMatchTypes;
  individualKey?: boolean;
}

export interface PaginationState {
  rowsPerPage?: number;
  pageNum?: number;
  sortByColumn?: string;
  sortDirection?: Order;
}

export interface FetchResultStatusType {
  type: OperationStatus;
  code?: number;
  message: string;
}

export interface FetchResultType {
  status: FetchResultStatusType;
  data?: any;
  totalRows?: number; // Apparently it is used somewhere
}

export interface DataOwnerParameters {
  endpointUrl: string;
  fields?: string[];
  titlePrefixTemplate: string;
}
