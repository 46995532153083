import React, { useCallback } from "react";
import GraphComponent from "components/Graph/GraphComponent";
import { SENSOR_API_URL } from "./Sensor.const";
import { GraphDataProvider } from "components/Crud/DataProvider/GraphDataProvider";
import { graphDataSetRetrievalUrl } from "components/Graph/GraphCommon";
import { getAttributeByPath } from "components/Crud/EntityUtils";
import { API_LIB } from "components/Utils/API_LIB";
import { SampleTypeEnum } from "components/Dictionaries/Dictionaries.d";
import {
  SampleEndpointResponseSegment,
  RequestedDataRangeType,
  GraphDataProviderDataType,
} from "components/Graph/Graph.d";
import { FetchResultType, OPERATION_SUCCESS } from "components/Utils/CRUDUtils.d";
import { fetchEntityById } from "components/Utils/CRUDUtils";

interface SensorGraphDataViewProps {
  sensorId: number;
  title?: string;
}

export function SensorGraphDataView(props: SensorGraphDataViewProps) {
  // TODO: come up with better architecture
  const getListOfDataProviders = useCallback(async () => {
    let result: FetchResultType;

    if (props.sensorId) {
      const parsedResponse = await fetchEntityById(
        props.sensorId,
        SENSOR_API_URL,
        "join=sensorLib.sampleType&join=sampleFields.units"
      );

      result = {
        status: parsedResponse.status,
      };

      if (parsedResponse.status.type === OPERATION_SUCCESS) {
        result.data = [
          {
            id: parsedResponse.data.id,
            name:
              parsedResponse.data.name !== ""
                ? parsedResponse.data.name
                : getAttributeByPath(parsedResponse, "data.sensorLib.sampleType.name"),
            sampleType: getAttributeByPath(parsedResponse, "data.sensorLib.sampleType"),
            sampleTypeId: getAttributeByPath(parsedResponse, "data.sensorLib.sampleTypeId"),
          },
        ];
      }
    } else {
      result = {
        status: {
          type: OPERATION_SUCCESS,
          message: "",
        },
        data: [],
      };
    }

    return result;
  }, [props.sensorId]);

  // TODO: come up with better architecture
  const generateDatasetRetrievalUrl = useCallback(
    (sampleTypeId: SampleTypeEnum, id: number, startDate: Date, endDate: Date) => {
      const resultUrl = graphDataSetRetrievalUrl(
        API_LIB.SENSOR_SAMPLES_OVER_TIME,
        sampleTypeId,
        id,
        startDate,
        endDate
      );

      return resultUrl;
    },
    []
  );

  return (
    <GraphDataProvider
      getListOfDataProviders={getListOfDataProviders}
      generateDatasetRetrievalUrl={[generateDatasetRetrievalUrl]}
    >
      {(
        dataArray: Array<SampleEndpointResponseSegment>,
        isDataLoading: boolean,
        dispatchDataRequest: (graphDataRequest: RequestedDataRangeType) => void,
        dataProviders: Array<GraphDataProviderDataType>
      ) => {
        return (
          <GraphComponent
            title={props.title}
            dataArray={dataArray}
            isDataLoading={isDataLoading}
            requestData={dispatchDataRequest}
            dataProviders={dataProviders}
          />
        );
      }}
    </GraphDataProvider>
  );
}
