import React, { ReactElement } from "react";
import {
  USER_API_URL,
  USER_MEMBERSHIP_LIST_ACTION,
  USER_ENTITY_NAME,
  CHANGE_USER_PASSWORD_EVENT_ENTITY,
} from "./User.const";
import Business from "@material-ui/icons/Business";
import { USER_MEMBERSHIP_ROUTE } from "views/User/Membership/UserMembership.const";
import { getEntityListRoute, refineRequest } from "components/Utils/RouteUtils";
import {
  FilterMatchTypes,
  SORT_ASC,
  UnifiedDataRequestType,
} from "components/Utils/CRUDUtils.d";
import { translateValue, filterBy } from "components/Utils/MiscUtils";
import {
  ALL_ENTERPRISES_CODE,
  ENTERPRISE_API_URL,
} from "views/Enterprise/Enterprise.const";
import {
  BaseListProps,
  TableColumnParameters,
  TableColumnFilter,
  TableBodyLayoutType,
} from "components/Crud/Table/EntityTable.d";
import {
  generateEditButtonConfig,
  generateShowButtonConfig,
  generateCreateButtonConfig,
  generateDeleteButtonConfig,
} from "components/Buttons/ButtonsCommon";
import { DataSourceParamsType } from "components/Crud/DataProvider/DataProviderCommon.d";
import { CrudQueryTableBinding } from "components/Crud/Table/CrudQueryTableBinding";
import { useVersion } from "components/Utils/CustomHooks";
import { TableActionButtonFace } from "components/Buttons/TableActionButtonFace";
import { UserEditForm } from "./UserEditForm";
import { ChangeUserPasswordForm } from "./ChangeUserPasswordForm";
import { EDIT_ACTION } from "components/Crud/Entity.const";
import { mergeRecords } from "components/Utils/ObjectUtils";
import { TableCellRendererProps } from "components/Crud/Table/CellRenderers/TableCellRenderer.d";
import { UserRowCard } from "./UserRowCard";

export function UserList(props: BaseListProps): ReactElement {
  const [version, increaseVersion] = useVersion();

  const valueTransformationFunction = (
    rendererProps: TableCellRendererProps
  ) => <UserRowCard {...rendererProps} />;

  const columns: TableColumnParameters[] = [
    {
      width: "100%",
      valueTransformationFunction,
      actionSet: [
        {
          labelElement: (
            <TableActionButtonFace>
              <Business />
              &nbsp;<span>Memberships</span>
            </TableActionButtonFace>
          ),
          name: "memberships",
          onClick: {
            type: "navigate",
            url: getEntityListRoute(USER_MEMBERSHIP_ROUTE),
            paramName: "userId",
          },
          actionCode: USER_MEMBERSHIP_LIST_ACTION,
          actionObject: USER_ENTITY_NAME,
        },
        {
          actionCode: EDIT_ACTION, // to avoid loading data
          actionObject: CHANGE_USER_PASSWORD_EVENT_ENTITY,
          name: "changeUserPassword",
          labelElement: (
            <TableActionButtonFace>
              <Business />
              &nbsp;<span>Change User Password</span>
            </TableActionButtonFace>
          ),
          onClick: {
            type: "dialog",
            dialogId: ChangeUserPasswordForm,
            onCloseCallbacks: [
              () =>
                console.log(
                  "UserList::columns.onClick.onDataChange is not supported"
                ),
            ],
            extraPropsObject: { title: "Change User Password" },
          },
        },
        generateEditButtonConfig(
          USER_ENTITY_NAME,
          UserEditForm,
          [increaseVersion],
          {
            title: "Edit User Details",
          }
        ),
        generateShowButtonConfig(USER_ENTITY_NAME, UserEditForm, {
          title: "Show User Details",
        }),
        generateDeleteButtonConfig(USER_ENTITY_NAME),
      ],
    },
  ];

  const uiFilters: Array<TableColumnFilter> = [
    {
      filterType: "text",
      filterDataSourceField: "username",
      filterMatchType: FilterMatchTypes.CONTAINS,
      filterParams: {
        placeholder: "Username",
      },
    },
    {
      filterType: "text",
      filterDataSourceField: "lastName",
      filterMatchType: FilterMatchTypes.CONTAINS,
      filterParams: {
        placeholder: "Last Name",
      },
    },
    {
      filterType: "text",
      filterDataSourceField: "email",
      filterMatchType: FilterMatchTypes.CONTAINS,
      filterParams: {
        placeholder: "Email",
      },
    },
  ];

  if (props.currentEnterpriseId === ALL_ENTERPRISES_CODE) {
    uiFilters.unshift({
      disable: props.currentEnterpriseId !== ALL_ENTERPRISES_CODE,
      filterType: "select-async",
      filterParams: {
        optionDefinitionEndpoint: ENTERPRISE_API_URL,
        optionCodeField: "id",
        optionLabelField: "name",
        renderEmptyValueAs: "ALL ENTERPRISES",
        autoComplete: true,
        placeholder: "ALL ENTERPRISES",
      },
      filterDataSourceField: "memberships.enterpriseId",
    });
  }

  const dataSource: DataSourceParamsType = {
    apiUrl: USER_API_URL,
  };

  let initialRequest: UnifiedDataRequestType = {
    sortByColumn: "lastName",
    sortDirection: SORT_ASC,
    fieldList: [
      "lastName",
      "firstName",
      "username",
      "email",
      "phoneNumber",
      "isSuperAdmin",
      "memberships.id",
      "memberships.role",
      "memberships.enterpriseId",
      "memberships.enterprise.name",
    ],
    filter: {
      baseFilters: [
        filterBy(
          "memberships.enterpriseId",
          "=",
          translateValue(props.currentEnterpriseId, [
            [ALL_ENTERPRISES_CODE, undefined],
          ])
        ),
      ],
    },
    isPaginationEnabled: true,
    rowsPerPage: 10,
    pageNum: 0,
  };

  const refinedRequestFromInput = refineRequest(props.inputRequest, uiFilters);
  initialRequest = mergeRecords(
    initialRequest,
    refinedRequestFromInput
  ) as UnifiedDataRequestType;

  const headerActions = [
    generateCreateButtonConfig(
      USER_ENTITY_NAME,
      "ADD NEW USER",
      UserEditForm,
      [increaseVersion],
      {
        title: "ADD NEW USER",
      }
    ),
  ];

  return (
    <CrudQueryTableBinding
      externalTriggerValues={[
        props.currentEnterpriseId,
        props.userData,
        version,
      ]}
      dataSource={dataSource}
      intialQuery={initialRequest}
      title="Users"
      tableColumns={columns}
      onRowClickFunction={props.onRowClickFunction}
      // actionsAllowed={allowedActions}
      highlightedRowsPrimaryKeys={props.activeRowPrimaryKeys}
      uiFilters={uiFilters}
      headerActions={headerActions}
      allowUrlReplacementForFilters={props.allowUrlReplacementForFilters}
      allowReinitialization={props.allowReinitialization}
      tableVariant={TableBodyLayoutType.FULL_WIDTH_CARD_SINGLE}
    />
  );
}
