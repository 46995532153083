export enum ConditionType {
  GREATER = 1,
  GREATER_EQUALS = 2,
  LOWER = 3,
  LOWER_EQUALS = 4,
  EQUALS = 5,
}

export enum ValueType {
  VALUE = 1,
  RATE = 2,
}
