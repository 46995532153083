import { StyleRulesCallback, Theme } from "@material-ui/core";

export const outlinedBadgeStyle: StyleRulesCallback<Theme, {}> = (theme) => ({
  root: {
    borderRadius: "2px",
    borderWidth: "1px",
    borderStyle: "solid",
    fontSize: "10px",
    lineHeight: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: "8px",
    paddingRight: "8px",
    fontWeight: "bold",
  },
});
