import React from "react";

import { MainPanelWithParamsProps } from "components/Dashboard/MainPanelWithParams.d";
import { EntityEditRouteParams } from "components/Dashboard/EntityEditRouteParams.d";
import { UserEditForm } from "./UserEditForm";
import { ScreenTitle } from "components/Elements/Typography/ScreenTitle";

export function UserEditScreen(props: MainPanelWithParamsProps<EntityEditRouteParams>) {
  return (
    <div>
      <ScreenTitle>User Settings</ScreenTitle>
      <div style={{ paddingTop: "24px" }}>
        <UserEditForm
          id={props.match.params.id}
          action={props.match.params.action}
          showCloseButton={false}
          currentEnterpriseId={props.currentEnterpriseId}
          userData={props.userData}
        />
      </div>
    </div>
  );
}
