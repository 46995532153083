import { StyleRulesCallback, Theme } from "@material-ui/core";
import { blue } from "@material-ui/core/colors";

export const monitorSummaryStyles: StyleRulesCallback<Theme, {}> = (theme) => ({
  root: {
    boxSizing: "border-box",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
  },
  contentRoot: {
    flexGrow: 1,
    // boxSizing: 'border-box',
    // height: '100%'
  },
  tabbedPanel: {
    boxSizing: "border-box",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    paddingTop: "24px",
  },
  sensorSubList: {
    boxSizing: "border-box",
    minWidth: "320px",
    display: "flex",
    flexDirection: "column",
  },
  sensorListHeader: {
    height: "56px",
    boxShadow: "inset -1px -1px 0 0 #EEEEEE",
    display: "flex",
    alignItems: "center",
    paddingLeft: "16px",
  },
  sensorListItem: {
    height: "56px",
    boxShadow: "inset -1px -1px 0 0 #EEEEEE",
    display: "flex",
    alignItems: "center",
    paddingLeft: "16px",
    "&:hover": {
      backgroundColor: "#F4F6F8",
    },
  },
  selectedItem: {
    backgroundColor: blue[50],
  },
});
