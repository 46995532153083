import React from "react";
import { MainPanelWithParamsProps } from "components/Dashboard/MainPanelWithParams.d";
import { SamplePointSensorPairingList } from "./SamplePointSensorPairingList";
import { getAttributeByPath } from "components/Crud/EntityUtils";
import { SAMPLEPOINT_API_URL } from "../SamplePoint.const";
import { ScreenTitle } from "components/Elements/Typography/ScreenTitle";
import { DataCrud } from "components/Crud/DataProvider/DataCrud";
import {
  DataCrudDataType,
  DataCrudEventType,
  DataCrudOperationStatusType,
} from "components/Crud/DataProvider/DataCrud.d";
import { isSpecified } from "components/Utils/MiscUtils";

export interface SamplePointSensorPairingScreenProps {
  samplePointId: number;
}

export function SamplePointSensorPairingScreen(
  props: MainPanelWithParamsProps<SamplePointSensorPairingScreenProps>
) {
  const searchParams = new URLSearchParams(props.location.search);
  const siteId = isSpecified(searchParams.get("siteId")) ? parseInt(searchParams.get("siteId"), 10) : undefined;

  const samplePointId = getAttributeByPath(props, "match.params.samplePointId");

  const dataSource = {
    apiUrl: SAMPLEPOINT_API_URL,
  };

  return (
    <DataCrud
      prefetchId={samplePointId}
      dataSource={dataSource}
      externalTriggerValues={[props.currentEnterpriseId, samplePointId]}
      render={(
        crudData: DataCrudDataType,
        crudRequest: DataCrudEventType,
        crudStatus: DataCrudOperationStatusType,
        dispatchCrudEvent: (event: DataCrudEventType) => void
      ) => {
        const samplePointName = isSpecified(crudData.data) ? crudData.data.name : "Loading...";
        const sampleTypeId = isSpecified(crudData.data) ? crudData.data.sampleTypeId : undefined;

        return (
          <div>
            <ScreenTitle>{samplePointName}</ScreenTitle>
            <SamplePointSensorPairingList
              samplePointId={samplePointId}
              sampleTypeId={sampleTypeId}
              siteId={siteId}
              userData={props.userData}
              currentEnterpriseId={props.currentEnterpriseId}
            />
          </div>
        );
      }}
    />
  );
}
