import React from "react";
import { makeStyles } from "@material-ui/core";
import { screenSectionTitleStyle } from "./screenSectionTitleStyle";

export interface ScreenSectionTitleProps {
  children: string;
}

const useStyles = makeStyles(screenSectionTitleStyle, { name: "ScreenSectionTitle" });

export function ScreenSectionTitle(props: ScreenSectionTitleProps) {
  const classes = useStyles({});

  return <span className={classes.root}>{props.children}</span>;
}
