import React, { useState } from "react";
import {
  Typography,
  Paper,
  Button,
  Grid,
  FormControl,
  Input,
  InputAdornment,
  IconButton,
  FormControlLabel,
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import { EnterpriseIdType } from "views/Enterprise/Enterprise.d";
import { BUTTON_VARIANT } from "components/Buttons/ButtonsCommon";
export const SEARCH_SCREEN_ROUTE = "/app/search";

export interface SearchScreenProps {
  currentEnterpriseId?: EnterpriseIdType;
}

function SearchScreen(props: SearchScreenProps) {
  const [searchValue, setSearchValue] = useState("");

  const [searchResults, setSearchResults] = useState([]);

  const executeSearch = () => {
    setSearchResults([
      [`Fist Row ${searchValue}`],
      [`Second Row ${searchValue}`],
      [`Third Row ${searchValue}`],
    ]);
  };

  return (
    <div style={{ margin: "16px", width: "100%" }}>
      <Paper>
        <Grid container direction="column">
          <Grid item style={{ margin: "8px" }}>
            <Typography variant="h5">Search Page</Typography>
            <Typography variant="subtitle2">
              WARNING This Page is a STUB. Styling and functionality will be better
            </Typography>
          </Grid>
          <Grid item style={{ margin: "8px" }}>
            <Grid container>
              <Grid item>
                <FormControl>
                  <FormControlLabel
                    style={{ margin: "8px" }}
                    label="Some Entity"
                    labelPlacement="start"
                    control={
                      <Input
                        style={{ marginLeft: "8px" }}
                        value={searchValue}
                        placeholder={`Enter value`}
                        onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                          setSearchValue(event.target.value)
                        }
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              disabled={!(searchValue && searchValue.length > 0)}
                              onClick={() => setSearchValue("")}
                            >
                              <ClearIcon />
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item style={{ margin: "8px" }}>
                <Button size="small" color="primary" variant={BUTTON_VARIANT} onClick={executeSearch}>
                  Search
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item style={{ margin: "16px" }}>
            <Typography>{`This area will display search results for the value you have entered ${searchValue}`}</Typography>
            {searchResults.map((value, index) => (
              <Typography key={index}>{value}</Typography>
            ))}
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}

export default SearchScreen;
