import { StyleRulesCallback, Theme } from "@material-ui/core";

export const geoMapPopupStyle: StyleRulesCallback<Theme, {}> = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  rootLargeScreen: {
    height: "75vh",
    width: "75vw",
  },
  rootSmallScreen: {
    height: "75vh",
    width: "100vw",
  },
  topBar: {
    boxShadow: "inset 0 -1px 0 0 #EEEEEE",
    display: "flex",
    alignItems: "center",
    paddingLeft: "24px",
    paddingRight: "16px",
  },
  mapContent: {
    flexGrow: 1,
    paddingTop: "16px",
    paddingLeft: "24px",
    paddingRight: "24px",
    paddingBottom: "24px",
    display: "flex",
    flexDirection: "column",
  },
  ellipsisOnOverflow: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
});
