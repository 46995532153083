export interface TemplateParams {
  [key: string]: string | number;
}

export function populateStringTemplate(templateString: string, templateParams: TemplateParams) {
  let retVal = templateString;

  if (templateParams) {
    for (const paramName in templateParams) {
      if (templateParams[paramName]) {
        const regExp = new RegExp(`:${paramName}`, "g");

        retVal = retVal.replace(regExp, String(templateParams[paramName]));
      }
    }
  }

  return retVal;
}

export function isValidEmail(emailValue: string) {
  // TODO: previous value /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  // remove TODO if no errors appear post 01-07-2020
  const emailRegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

  return emailRegExp.test(emailValue);
}

export function isValidPhoneNumber(phoneNumberValue: string) {
  // TODO: previous value /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g; complained at useless escape starting here - \./0-9]*$/g;
  // remove TODO if no errors appear in 01-07-2020
  const phoneNumberRegExp = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/g;

  return phoneNumberRegExp.test(phoneNumberValue);
}
