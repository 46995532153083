import React, { useState, useEffect, useCallback, useRef } from "react";
import { useAuth0 } from "components/Session/Auth0Provider";
import { Router, Switch } from "react-router-dom";
import { hist } from "index";
import { ThemeProvider } from "@material-ui/core";
import { globalMuiTheme } from "assets/jss/muiTheme";
import MessageBannerFrame from "components/Dashboard/Message/MessageBannerFrame";
import { PrivateRoute } from "PrivateRoute";
import MainScreen from "layouts/Dashboard/MainScreen";
import { UserParams } from "views/User/UserParams.d";
import {
  ALL_ENTERPRISES_CODE,
  ALL_ENTERPRISES_LABEL,
  ENTERPRISE_API_URL,
  LOADING_ENTERPRISE_DATA,
  ENTERPRISE_NOT_SELECTED,
} from "views/Enterprise/Enterprise.const";
import { OPERATION_SUCCESS } from "components/Utils/CRUDUtils.d";
import { fetchDataByFullURL } from "components/Utils/CRUDUtils";
import { preUrl } from "views/constants";
import { StorageUtils } from "components/Session/StorageUtils";
import { isSpecified } from "components/Utils/MiscUtils";
import { RotatingLoadingArrowCircle } from "assets/icons/RotatingLoadingArrowCircle";
import { GOOGLE_MAP_API_KEY, scriptId } from "components/GeoMap/GeoMap";
import { GlobalEventMessageType, GlobalEvent } from "GlobalContext.d";
import { GlobalUserContext, GlobalEventContext, GlobalEnterpriseContext } from "GlobalContext";

export interface AppProps {}

export function App(props: AppProps) {
  const auth0Data = useAuth0();

  const [stateEnterprise, setStateEnterprise] = useState({
    currentEnterpriseId: "" as string | number,
    currentEnterpriseName: ENTERPRISE_NOT_SELECTED,
  });

  const [stateUserData, setStateUserData] = useState(null as UserParams);
  const [stateSidebarVersion, setStateSidebarVersion] = useState(0);
  const [stateSidebarIsOpen, setStateSidebarIsOpen] = useState(true);

  // Methods:
  const increaseSidebarVersion = () => setStateSidebarVersion((prevState) => prevState + 1);

  const globalEventHandler = useCallback(
    (globalEvent: GlobalEventMessageType) => {
      console.log("App::globalEventHandler - globalEvent", globalEvent);

      if (globalEvent.type === GlobalEvent.CHANGE_CURRENT_ENTERPRISE) {
        setStateEnterprise((prevStateEnterprise) => {
          if (prevStateEnterprise.currentEnterpriseId !== globalEvent.payload.enterpriseId) {
            if (globalEvent.payload.enterpriseName) {
              return {
                currentEnterpriseId: globalEvent.payload.enterpriseId,
                currentEnterpriseName: globalEvent.payload.enterpriseName,
              };
            } else {
              return {
                currentEnterpriseId: globalEvent.payload.enterpriseId,
                currentEnterpriseName: LOADING_ENTERPRISE_DATA,
              };
            }
          } else {
            return {
              currentEnterpriseId: prevStateEnterprise.currentEnterpriseId,
              currentEnterpriseName: prevStateEnterprise.currentEnterpriseName,
            };
          }
        });
      } else if (globalEvent.type === GlobalEvent.REFRESH_SIDEBAR) {
        increaseSidebarVersion();
      } else if (globalEvent.type === GlobalEvent.NAVIGATE) {
        hist.push(globalEvent.payload);
      } else if (globalEvent.type === GlobalEvent.LOGOUT) {
        // this.handleUserLoggedOut();
        console.log("globalEvent.type === GlobalEvent.LOGOUT");
        auth0Data.logout();
        StorageUtils.clear();
      } else if (globalEvent.type === GlobalEvent.LOGIN) {
        // this.handleUserLoggedIn(globalEvent.payload.userData, globalEvent.payload.targetRoute);
        console.error("globalEvent.type === GlobalEvent.LOGIN");
      } else if (globalEvent.type === GlobalEvent.SET_SIDEBAR_OPEN) {
        setStateSidebarIsOpen(globalEvent.payload.isOpen);
      } else if (globalEvent.type === GlobalEvent.TOGGLE_SIDEBAR_IS_OPEN) {
        setStateSidebarIsOpen((prevState) => !prevState);
      } else if (globalEvent.type === GlobalEvent.SET_ACTIVE_USER) {
        setStateUserData(globalEvent.payload);
      }
    },
    [auth0Data]
  );

  const refPrevEnterpriseId = useRef("" as number | string);

  useEffect(() => {
    const scriptMapReference = document.getElementById(scriptId);

    if (!scriptMapReference) {
      const googleMapApiScriptElement = document.createElement("script");
      googleMapApiScriptElement.id = scriptId;

      googleMapApiScriptElement.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}`; // ?libraries=places`; // &libraries=places`;
      window.document.body.appendChild(googleMapApiScriptElement);
    }
  }, []);

  useEffect(() => {
    const setEnterpriseValues = async () => {
      if (stateEnterprise.currentEnterpriseId === ALL_ENTERPRISES_CODE) {
        setStateEnterprise({
          currentEnterpriseId: ALL_ENTERPRISES_CODE,
          currentEnterpriseName: ALL_ENTERPRISES_LABEL,
        });
        setImmediate(() =>
          StorageUtils.updateUserActivityDetails({
            enterpriseId: stateEnterprise.currentEnterpriseId,
            enterpriseName: ALL_ENTERPRISES_LABEL,
          })
        );
      } else if (
        stateEnterprise.currentEnterpriseId !== "" &&
        isSpecified(stateEnterprise.currentEnterpriseId)
      ) {
        setStateEnterprise((prevState) => ({
          currentEnterpriseId: prevState.currentEnterpriseId,
          currentEnterpriseName: LOADING_ENTERPRISE_DATA,
        }));
        // TODO: it is not possible to dispatch an error message from here.
        // TODO: Possible update bug if you switch enterprise again

        const result = await fetchDataByFullURL(
          `${preUrl}${ENTERPRISE_API_URL}/${stateEnterprise.currentEnterpriseId}`,
          "GET"
        );

        let enterpriseName: string;
        if (result.status.type === OPERATION_SUCCESS) {
          enterpriseName = result.data.name;
        } else {
          enterpriseName = `ERROR ENTERPRISE ID ${stateEnterprise.currentEnterpriseId}`;
        }

        setStateEnterprise((prevState) => ({
          currentEnterpriseId: prevState.currentEnterpriseId,
          currentEnterpriseName: enterpriseName,
        }));

        setImmediate(() =>
          StorageUtils.updateUserActivityDetails({
            enterpriseId: stateEnterprise.currentEnterpriseId,
            enterpriseName: enterpriseName,
          })
        );
      }

      if (refPrevEnterpriseId.current !== "") {
        setImmediate(() => {
          globalEventHandler({
            type: GlobalEvent.NAVIGATE,
            payload: "/",
            originator: "Post Enterprise Switching",
          });
        });
      }

      refPrevEnterpriseId.current = stateEnterprise.currentEnterpriseId;
    };

    setEnterpriseValues();
  }, [stateEnterprise.currentEnterpriseId, globalEventHandler]);

  if (auth0Data.loading) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          width: "100vw",
        }}
      >
        <RotatingLoadingArrowCircle isRotating={false} />
      </div>
    );
  } else {
    return (
      <Router history={hist}>
        <GlobalEventContext.Provider value={globalEventHandler /* {dispatchEvent: globalEventHandler} */}>
          <GlobalUserContext.Provider value={stateUserData}>
            <GlobalEnterpriseContext.Provider
              value={{ id: stateEnterprise.currentEnterpriseId, name: stateEnterprise.currentEnterpriseName }}
            >
              <ThemeProvider theme={globalMuiTheme}>
                <MessageBannerFrame>
                  <Switch>
                    <PrivateRoute
                      path="/" // {APP_URL_PREFIX}
                      render={(inputProps: any) => {
                        return (
                          <MainScreen /*history={hist} - passed in ...props*/
                            currentEnterpriseId={stateEnterprise.currentEnterpriseId}
                            currentEnterpriseName={stateEnterprise.currentEnterpriseName}
                            userData={stateUserData}
                            sidebarVersion={stateSidebarVersion}
                            sidebarIsOpen={stateSidebarIsOpen}
                            {...inputProps}
                          />
                        );
                      }}
                    />
                  </Switch>
                </MessageBannerFrame>
              </ThemeProvider>
            </GlobalEnterpriseContext.Provider>
          </GlobalUserContext.Provider>
        </GlobalEventContext.Provider>
      </Router>
    );
  }
}
