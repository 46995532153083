import { StyleRulesCallback, Theme } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { px } from "components/Utils/LayoutUtils";

const cardCellHeaderHeight = 20;

export const assetCardCellStyle: StyleRulesCallback<Theme, {}> = (theme) => ({
  root: {
    fontFamily: '"Roboto", "Helvetica", sans-serif',
    boxSizing: "border-box",
  },
  assetIcon: {
    paddingRight: "8px",
  },
  assetName: {
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "20px",
    color: theme.palette.text.primary,
    overflowWrap: "anywhere",
    // position: 'relative', // TODO: we need this for the absolute position to work
    // overflow: 'hidden',
    maxHeight: "60px", // triple the line height
  },
  // cardCellCardFormat: {
  //   height: '75px',
  //   paddingTop: '8px',
  //   paddingBottom: '8px'
  // },
  cellBorderRight: {
    borderRightColor: grey[300],
    borderRightWidth: "0.25px",
    borderRightStyle: "solid",
  },
  cellBorderBottom: {
    borderBottomColor: grey[300],
    borderBottomWidth: "0.25px",
    borderBottomStyle: "solid",
  },
  cellBorderLeft: {
    borderLeftColor: grey[300],
    borderLeftWidth: "0.25px",
    borderLeftStyle: "solid",
  },
  cardCellInnerBoxRowFormat: {
    minHeight: `${120 - 24 * 2}px`,
    paddingLeft: "24px",
    paddingRight: "24px",
    display: "flex",
    flexDirection: "column",
  },
  cardCellInnerBoxCardFormat: {
    height: `${75 - 8 * 2}px`,
    paddingLeft: "24px",
    paddingRight: "24px",
  },
  cardCellHeader: {
    height: px(cardCellHeaderHeight),
    display: "flex",
    alignItems: "center",
    // whiteSpace: 'nowrap'
  },
  cardCellContent: {
    // height: px(120 - 24 * 2 - cardCellHeaderHeight),
    flexBasis: px(120 - 24 * 2 - cardCellHeaderHeight),
    flexGrow: 1,
  },
  cardCellContentTop: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  cardCellContentMiddle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  cardCellContentBottom: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
  },
  cardCellTitleText: {
    color: theme.palette.text.secondary,
    fontSize: "11px",
    lineHeight: "13px",
    fontWeight: 500,
    letterSpacing: "0.33px", // TODO: is it important?
  },
  cardCellTitleNone: {
    display: "none",
  },
  cardCellContentSubTitle: {
    whiteSpace: "nowrap",
  },
  cardCellSubHeadingText: {
    // Removed from - AssetMeasurementCardCellRenderer, LocationAndStatusRenderer
    fontSize: "12px",
    lineHeight: "18px",
    color: theme.palette.text.secondary,
  },
  cardCellContentText: {
    // Removed from - AssetMeasurementCardCellRenderer, LocationAndStatusRenderer
    fontSize: "14px",
    color: theme.palette.text.primary,
  },
  cardCellContentTextShort: {
    // Removed from - AssetMeasurementCardCellRenderer, LocationAndStatusRenderer
    lineHeight: "24px",
  },
  cardCellRegularContentLine: {
    minHeight: "24px",
  },
  cardCellContentTextLong: {
    lineHeight: "20px",
    fontSize: "12px",
  },
  smallIconWrapper: {
    height: "20px",
    width: "20px",
  },
  statusSegment: {
    display: "flex",
    alignItems: "center",
  },
  ellipsisOnOverflow: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
});
