import { StyleRulesCallback, Theme } from "@material-ui/core/styles";

const formStyle: StyleRulesCallback<Theme, {}> = (theme) => ({
  paperContainer: {
    boxShadow: "inset 0 -1px 0 0 #EEEEEE",
    display: "flex",
    alignItems: "center",
  },
  grid: {
    padding: "15px",
    marginTop: "10px",
    marginBottom: "10px",
  },
  label: {
    color: "#000000",
    padding: "15px",
    textAlign: "left",
    fontSize: "1rem",
  },
  backBtn: {
    textTransform: "none",
    marginRight: "2px",
  },
  hidden: {
    display: "none",
  },
  textField: {
    marginTop: "0.25em",
    marginBottom: "0.25em",
  },
  selectField: {
    marginTop: "0.25em",
    marginBottom: "0.25em",
  },
});

export default formStyle;
