import React from "react";
import { FormLayoutProps } from "components/Crud/Form/Layout/FormLayout.d";
import { FormLayoutElement } from "components/Crud/Form/Layout/FormLayoutElement";
import { ConditionalRender } from "layouts/ConditionalRender";
import { CardSubTitle } from "components/Elements/Typography/CardSubTitle";

export function EnterpriseEditFormLayout(props: FormLayoutProps) {
  return (
    <div>
      <div style={{ height: "40px", display: "flex", alignItems: "center" }}>
        <CardSubTitle>{"PRIMARY INFO"}</CardSubTitle>
      </div>
      <FormLayoutElement fieldId="name" {...props} />
      <FormLayoutElement fieldId="businessType" {...props} />
      <FormLayoutElement fieldId="enterpriseType" {...props} />
      <FormLayoutElement fieldId="enterpriseStatus" {...props} />
      <div>
        <div style={{ height: "40px", display: "flex", alignItems: "center" }}>
          <CardSubTitle>{"BILLING ADDRESS"}</CardSubTitle>
        </div>
        <FormLayoutElement fieldId="billingAddress.name" {...props} />
        <FormLayoutElement fieldId="billingAddress.line1" {...props} />
        <FormLayoutElement fieldId="billingAddress.line2" {...props} />
        <div style={{ display: "flex" }}>
          <FormLayoutElement fieldId="billingAddress.city" {...props} />
          <FormLayoutElement fieldId="billingAddress.postCode" {...props} />
          <FormLayoutElement fieldId="billingAddress.stateId" {...props} />
        </div>
      </div>
      <div>
        <div style={{ height: "40px", display: "flex", alignItems: "center" }}>
          <CardSubTitle>{"SHIPPING ADDRESS"}</CardSubTitle>
        </div>
        <FormLayoutElement fieldId="useBillingAddressForShipping" {...props} />
        <ConditionalRender condition={props.values && props.values.useBillingAddressForShipping === false}>
          <React.Fragment>
            <FormLayoutElement fieldId="shippingAddress.name" {...props} />
            <FormLayoutElement fieldId="shippingAddress.line1" {...props} />
            <FormLayoutElement fieldId="shippingAddress.line2" {...props} />
            <div style={{ display: "flex" }}>
              <FormLayoutElement fieldId="shippingAddress.city" {...props} />
              <FormLayoutElement fieldId="shippingAddress.postCode" {...props} />
              <FormLayoutElement fieldId="shippingAddress.stateId" {...props} />
            </div>
          </React.Fragment>
        </ConditionalRender>
      </div>
    </div>
  );
}
