import React, { useContext } from "react";
import { SORT_DESC, UnifiedDataRequestType } from "components/Utils/CRUDUtils.d";
import { PACKET_LOG_STATUSES, PACKET_LOG_PACKET_TYPES } from "./PacketLog.dict";
import { handleRequestPacketLogReprocessing } from "./PacketLogCommon";
import { PACKET_LOG_API_URL, PACKET_LOG_ENTITY_NAME, REQUEST_PACKET_REPROCESSING } from "./PacketLog.const";
import { DateCellRenderer } from "components/Crud/Table/CellRenderers/DateCellRenderer";
import { SimpleEnum } from "components/Crud/Table/CellRenderers/SimpleEnum";
import { BootFlagRenderer } from "./BootFlagRenderer";
import { TableCellRendererProps } from "components/Crud/Table/CellRenderers/TableCellRenderer.d";
import { BaseListProps, TableColumnParameters, TableColumnFilter } from "components/Crud/Table/EntityTable.d";
import { generateShowButtonConfig, generateCreateButtonConfig } from "components/Buttons/ButtonsCommon";
import { DataSourceParamsType } from "components/Crud/DataProvider/DataProviderCommon.d";
import { filterBy } from "components/Utils/MiscUtils";
import { CrudQueryTableBinding } from "components/Crud/Table/CrudQueryTableBinding";
import { useVersion } from "components/Utils/CustomHooks";
import { PacketLogEditForm } from "./PacketLogEditForm";
import { MessageBannerDispatcherContext } from "components/Dashboard/Message/MessageBannerFrame";
import { ObjectIdType } from "components/Utils/Object.d";
import { TableActionButtonFace } from "components/Buttons/TableActionButtonFace";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import { OnClickAction } from "components/Buttons/Buttons.d";

export interface PacketLogListProps {
  monitorId: ObjectIdType;
  title?: string;
}

export function PacketLogList(props: BaseListProps & PacketLogListProps) {
  const [version, increaseVersion] = useVersion();
  const messageBannerDispatcher = useContext(MessageBannerDispatcherContext);

  const columns: TableColumnParameters[] = [
    { sourceName: "id", label: "Id" },
    { sourceName: "decodedData[sequenceNumber]", label: "Sequence No" },
    {
      sourceName: "decodedData[bootFlag]",
      label: "Boot Flag",
      valueTransformationFunction: BootFlagRenderer,
    },
    {
      sourceName: "decodedData[packetType]",
      label: "Packet Type",
      valueTransformationFunction: (rendererProps: TableCellRendererProps) => (
        <SimpleEnum options={PACKET_LOG_PACKET_TYPES} {...rendererProps} />
      ),
    },
    {
      sourceName: "packetStatus",
      label: "Packet Status",
      valueTransformationFunction: (rendererProps: TableCellRendererProps) => (
        <SimpleEnum options={PACKET_LOG_STATUSES} {...rendererProps} />
      ),
    },
    {
      sourceName: "dateReceived",
      label: "Received",
      valueTransformationFunction: (rendererProps: TableCellRendererProps) => (
        <DateCellRenderer userFriendlyFormat={false} {...rendererProps} />
      ),
    },
    {
      sourceName: "dateProcessed",
      label: "Processed",
      valueTransformationFunction: (rendererProps: TableCellRendererProps) => (
        <DateCellRenderer userFriendlyFormat={false} {...rendererProps} />
      ),
    },
    {
      disableSorting: true,
      actionSet: [
        generateShowButtonConfig(PACKET_LOG_ENTITY_NAME, PacketLogEditForm, {}, true),
        {
          labelElement: (
            <TableActionButtonFace>
              <AutorenewIcon fontSize="small" />
              &nbsp;<span>REPROCESS</span>
            </TableActionButtonFace>
          ),
          name: "reprocessPacket",
          onClick: {
            type: "action",
            fn: (id: ObjectIdType, object: Record<string, any>) => {
              handleRequestPacketLogReprocessing(id, messageBannerDispatcher);
              increaseVersion();
            },
          } as OnClickAction,
          actionCode: REQUEST_PACKET_REPROCESSING,
          actionObject: PACKET_LOG_ENTITY_NAME,
          doNotGroup: true,
          confirm: {
            message: `Are you sure you want to Reprocess the packet?`,
            dialogButton: "Yes",
          },
        },
      ],
    },
  ];

  const uiFilters: Array<TableColumnFilter> = [
    {
      filterType: "select",
      filterParams: {
        options: PACKET_LOG_STATUSES,
        renderEmptyValueAs: "ALL STATUSES",
      },
      filterDataSourceField: "packetStatus",
    },
  ];

  const getMonitorId = () => {
    if (!props.monitorId) {
      throw new Error("Monitor Id is not set");
    }

    return props.monitorId;
  };

  const dataSource: DataSourceParamsType = {
    apiUrl: PACKET_LOG_API_URL,
  };

  const initialRequest: UnifiedDataRequestType = {
    sortByColumn: "dateReceived",
    sortDirection: SORT_DESC,
    fieldList: [
      "decodedData[sequenceNumber]",
      "decodedData[bootFlag]",
      "decodedData[packetType]",
      "packetStatus",
      "dateReceived",
      "dateProcessed",
    ],
    filter: {
      baseFilters: [filterBy("monitorId", "=", getMonitorId())],
    },
    isPaginationEnabled: true,
    rowsPerPage: 50,
    pageNum: 0,
  };

  const headerActions = [
    generateCreateButtonConfig(PACKET_LOG_ENTITY_NAME, "ADD PACKET LOG", PacketLogEditForm, [
      increaseVersion,
    ]),
  ];

  return (
    <CrudQueryTableBinding
      externalTriggerValues={[props.currentEnterpriseId, props.userData, version]}
      dataSource={dataSource}
      intialQuery={initialRequest}
      title={props.title}
      tableColumns={columns}
      onRowClickFunction={props.onRowClickFunction}
      // actionsAllowed={allowedActions}
      highlightedRowsPrimaryKeys={props.activeRowPrimaryKeys}
      uiFilters={uiFilters}
      headerActions={headerActions}
    />
  );
}
