import { ObjectIdType } from "components/Utils/Object.d";
import { EntityActionType } from "../Entity.d";
import { EnterpriseIdType } from "views/Enterprise/Enterprise.d";
import { UserParams } from "views/User/UserParams.d";

export type OnCloseFormCallbackType = (changed: boolean) => void;

export interface EntityFormParams {
  // TODO: better name to indicate intended behavior
  id?: ObjectIdType;
  title?: string;
  action: EntityActionType;
  onCloseAdditionalActions?: OnCloseFormCallbackType;
  showCloseButton: boolean;
  currentEnterpriseId?: EnterpriseIdType;
  userData: UserParams;
  enableEditSafetySwitch?: boolean;
  dataObject?: Record<string, any>;
  warning?: string;
}

export enum FieldsInProgressActionTypeEnum {
  ADD = "add",
  REMOVE = "remove",
}

export type FieldsInProgressStateType = Record<string, number>;
export interface FieldsInProgressActionType {
  type: FieldsInProgressActionTypeEnum;
  payload: {
    field: string;
    timeStamp: number;
  };
}

export type SetFieldValueDirectFunctionType = {
  (field: never, value: any, shouldValidate?: boolean): void;
  (field: string, value: any): void;
};

export type DispatchFieldsInProgressFunctionType = (event: FieldsInProgressActionType) => void;
export type NotifyOnValidValuesChangeFunctionType = (values: Record<string, any>) => void;
export type SubmitFormFunctionType = () => void;
export type HandleSubmissionAttemptType = (submitFunction: SubmitFormFunctionType) => void;
export type HandleCloseFunctionType = () => void;

// TODO: very narrow set of Use Cases. Find a way to do it differently
export type ConditionalFieldConfigurationFunctionType = (request: Record<string, any>) => void;
