import { StyleRulesCallback, Theme } from "@material-ui/core/styles";
import { px } from "components/Utils/LayoutUtils";

// Note: mobile is 56, mobile landscape 48
// In our case mobile requires different treatment of sideBar and Header
export const muiRegularHeaderHeight = 64;
export const muiMobileHeaderHeight = 56;

export const mainScreenStyle: StyleRulesCallback<Theme, {}> = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100vh", // Note: this allows direct vertical sizing of the sideBarAndContentWrapper. not same as 100%
  },
  sideBarAndContentWrapper: {
    display: "flex",
    flexDirection: "row",
    // height: '100%'
    flexGrow: 1,
  },
  smallScreenSideBarContainer: {
    top: px(muiMobileHeaderHeight),
    bottom: 0,
    position: "fixed",
    overflowY: "auto",
    zIndex: 1000,
  },
  closedSmallScreenSideBar: {
    width: 0,
  },
});
