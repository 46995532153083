export const MONITOR_API_URL = "/monitor";
export const MONITOR_ROUTE = "/monitor";
export const MONITOR_ENTITY_NAME = "monitor";
export const MONITOR_CONFIGURATION_API_URL = "/monitor-configuration";
export const MONITOR_CONFIGURATION_ROUTE = "/monitor/:monitorId/configuration";
export const MONITOR_SUMMARY_ROUTE = "/monitor-summary/:monitorId";
export const MONITOR_CONFIGURATION_SENSOR_ROUTE_URL = "/sensor";
export const MONITOR_CONFIGURATION_ENTITY_NAME = "monitorCfg";
export const GRAPH_TITLE = "Water Level";

export const MONITOR_SENSOR_LIST_ACTION = "monitor_sensor_list";
export const MONITOR_CONFIGURATION_LIST_ACTION = "monitor_configuration_list";
export const MONITOR_PACKET_LOG_LIST_ACTION = "monitor_packet_log_list";

export const MONITOR_SUMMARY_ACTION = "monitor_summary";
