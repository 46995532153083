import React from "react";
import { UnifiedDataRequestType, SORT_ASC } from "components/Utils/CRUDUtils.d";
import {
  SAMPLEPOINT_SENSOR_PAIRING_ENTITY,
  SAMPLEPOINT_SENSOR_PAIRING_API,
} from "./SamplePointSensorPairing.const";
import { BaseListProps, TableColumnParameters, TableColumnFilter } from "components/Crud/Table/EntityTable.d";
import { DataSourceParamsType } from "components/Crud/DataProvider/DataProviderCommon.d";
import { CrudQueryTableBinding } from "components/Crud/Table/CrudQueryTableBinding";
import { useVersion } from "components/Utils/CustomHooks";
import {
  generateCreateButtonConfig,
  generateEditButtonConfig,
  generateShowButtonConfig,
  generateDeleteButtonConfig,
} from "components/Buttons/ButtonsCommon";
import { filterBy } from "components/Utils/MiscUtils";
import { TableCellRendererProps } from "components/Crud/Table/CellRenderers/TableCellRenderer.d";
import { DateCellRenderer } from "components/Crud/Table/CellRenderers/DateCellRenderer";
import { SimpleEnum } from "components/Crud/Table/CellRenderers/SimpleEnum";
import { SamplePointSensorPairingEditForm } from "./SamplePointSensorPairingEditForm";
import { ObjectIdType } from "components/Utils/Object.d";
import { ScreenSectionTitle } from "components/Elements/Typography/ScreenSectionTitle";
import { PAIRING_OPTIONS } from "./SamplePointSensorPairing.dict";

export interface SamplePointSensorPairingListProps {
  samplePointId: number;
  sampleTypeId: number;
  siteId?: ObjectIdType;
  contentToDisplayWhenNoData?: string | JSX.Element;
}

export function SamplePointSensorPairingList(props: BaseListProps & SamplePointSensorPairingListProps) {
  const [version, increaseVersion] = useVersion();

  const tableColumns: TableColumnParameters[] = [
    {
      sourceName: "id",
      label: "id",
    },
    // {
    //   sourceName: 'samplePoint.name',
    //   label: 'Sample Point Name'
    // },
    {
      sourceName: "sensor.label",
      label: "Label",
    },
    {
      sourceName: "sourceType",
      label: "Source Type",
      valueTransformationFunction: (rendererProps: TableCellRendererProps) => (
        <SimpleEnum options={PAIRING_OPTIONS} {...rendererProps} />
      ),
    },
    {
      sourceName: "startDate",
      label: "Start Date",
      valueTransformationFunction: (rendererProps: TableCellRendererProps) => (
        <DateCellRenderer userFriendlyFormat={false} {...rendererProps} />
      ),
    },
    {
      sourceName: "endDate",
      label: "End Date",
      valueTransformationFunction: (rendererProps: TableCellRendererProps) => (
        <DateCellRenderer userFriendlyFormat={false} {...rendererProps} />
      ),
    },
    {
      disableSorting: true,
      actionSet: [
        generateEditButtonConfig(
          SAMPLEPOINT_SENSOR_PAIRING_ENTITY,
          SamplePointSensorPairingEditForm,
          [increaseVersion],
          {
            samplePointId: props.samplePointId, // TODO: investigate why there is an error without it populated
            sampleTypeId: props.sampleTypeId,
            siteId: props.siteId,
            title: "Edit Sensor Pairing",
          }
        ),
        generateShowButtonConfig(SAMPLEPOINT_SENSOR_PAIRING_ENTITY, SamplePointSensorPairingEditForm, {
          samplePointId: props.samplePointId, // TODO: investigate why there is an error without it populated
          sampleTypeId: props.sampleTypeId,
          siteId: props.siteId,
          title: "Show Sensor Pairing",
        }),
        generateDeleteButtonConfig(SAMPLEPOINT_SENSOR_PAIRING_ENTITY),
      ],
    },
  ];

  const uiFilters: Array<TableColumnFilter> = [];

  const dataSource: DataSourceParamsType = {
    apiUrl: SAMPLEPOINT_SENSOR_PAIRING_API,
  };

  const initialRequest: UnifiedDataRequestType = {
    sortByColumn: "id",
    sortDirection: SORT_ASC,
    fieldList: ["samplePoint.name", "sensor.label", "samplePoint.sampleTypeId"],
    filter: {
      baseFilters: [filterBy("samplePointId", "=", props.samplePointId)],
    },
    isPaginationEnabled: true,
    rowsPerPage: 10,
    pageNum: 0,
  };

  const headerActions = [
    generateCreateButtonConfig(
      SAMPLEPOINT_SENSOR_PAIRING_ENTITY,
      "ADD SENSOR PAIRING",
      SamplePointSensorPairingEditForm,
      [increaseVersion],
      {
        samplePointId: props.samplePointId,
        sampleTypeId: props.sampleTypeId,
        siteId: props.siteId,
        title: "Add Sensor Pairing",
      }
    ),
  ];

  return (
    <CrudQueryTableBinding
      externalTriggerValues={[props.samplePointId, props.currentEnterpriseId, props.userData, version]}
      dataSource={dataSource}
      intialQuery={initialRequest}
      tableColumns={tableColumns}
      title={<ScreenSectionTitle>{"Sensor Pairing"}</ScreenSectionTitle>}
      onRowClickFunction={props.onRowClickFunction}
      // actionsAllowed={allowedActions}
      highlightedRowsPrimaryKeys={props.activeRowPrimaryKeys}
      uiFilters={uiFilters}
      headerActions={headerActions}
      allowReinitialization={true}
      contentToDisplayWhenNoData={props.contentToDisplayWhenNoData}
    />
  );
}
