import React from "react";
import { makeStyles, SvgIconProps } from "@material-ui/core";
import { discIconStyle } from "./discIconStyle";
import { ColorDisc } from "assets/icons/ColorDisc";
import clsx from "clsx";

export interface DiscIconProps {
  htmlBackgroundColor: string;
  icon: (props: SvgIconProps) => JSX.Element;
  iconColor?: string;
  discSize: number;
  iconSize: "small" | "large" | "default";
}

const useStyles = makeStyles(discIconStyle, { name: "DiscIcon" });

export function DiscIcon(props: DiscIconProps) {
  // TODO: Support other icon sizes

  const classes = useStyles({});

  const iconWrapperClassName = clsx(
    props.iconSize === "small" && classes.smallIconWrapper,
    props.iconSize === "default" && classes.defaultIconWrapper
  );

  return (
    <ColorDisc size={props.discSize} htmlBackgroundColor={props.htmlBackgroundColor}>
      <div className={iconWrapperClassName}>
        <props.icon fontSize={props.iconSize} htmlColor={props.iconColor || "white"} />
      </div>
    </ColorDisc>
  );
}
