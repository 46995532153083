import { headerStyle } from "components/Dashboard/Header/headerStyle";
import React, { useContext } from "react";
import clsx from "clsx";
import UserMenu from "./UserMenu";
import { RouteProps } from "react-router";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles } from "@material-ui/core/styles";
import { HeaderEnterpriseName } from "./HeaderEnterpriseName";
import { Button, IconButton } from "@material-ui/core";
import logo from "assets/img/white_logo.png";
import logo_drop from "assets/img/white_logo_drop.png";
import { UserParams } from "views/User/UserParams.d";
import { EnterpriseIdType } from "views/Enterprise/Enterprise.d";
import MenuIcon from "@material-ui/icons/Menu";
import { useMediaQueryMatchDown } from "components/Utils/LayoutUtils";
import { ConditionalRender } from "layouts/ConditionalRender";
import { ObjectWithKnownFieldsType } from "components/Utils/Object.d";
import { GlobalEventContext } from "GlobalContext";
import { GlobalEvent } from "GlobalContext.d";

interface HeaderProps {
  userData: UserParams;
  currentEnterpriseId: EnterpriseIdType;
  currentEnterpriseName: string;
}

const useStyles = makeStyles(headerStyle, { name: "Header" });

function Header(props: HeaderProps & RouteProps) {
  const classes = useStyles({});

  const globalEventContext = useContext(GlobalEventContext);

  const appBarClasses = clsx(classes.appBar);

  const isSmallScreen = useMediaQueryMatchDown("sm");

  const logoClasses: ObjectWithKnownFieldsType<{ root?: string; text?: string }> = {};

  if (isSmallScreen) {
    logoClasses.root = classes.smallLogoButtonRoot;
    logoClasses.text = classes.smallLogoButtonText;
  }

  const toggleSidebarIsOpen = () => {
    globalEventContext(
      /* .dispatchEvent */ {
        type: GlobalEvent.TOGGLE_SIDEBAR_IS_OPEN,
        payload: undefined,
        originator: "Header.toggleSidebarIsOpen",
      }
    );
  };

  return (
    <AppBar position="static" className={clsx(appBarClasses, !isSmallScreen)}>
      <Toolbar disableGutters={true} className={classes.container}>
        <div className={isSmallScreen ? classes.sideBarWideSectionSmallScreen : classes.sideBarWideSection}>
          <div
            className={isSmallScreen ? classes.sideBarClosedSectionSmallScreen : classes.sideBarClosedSection}
          >
            <IconButton onClick={toggleSidebarIsOpen}>
              <MenuIcon htmlColor="white" />
            </IconButton>
          </div>
          <div>
            <Button classes={logoClasses}>
              <ConditionalRender
                condition={isSmallScreen}
                onTrue={<img src={logo_drop} alt="logo_drop" />}
                onFalse={<img src={logo} alt="logo" />}
              />
            </Button>
          </div>
        </div>
        {
          <HeaderEnterpriseName
            currentEnterpriseId={props.currentEnterpriseId}
            currentEnterpriseName={props.currentEnterpriseName}
            userData={props.userData}
          />
        }
        {/*<IconButton onClick={() => TODO change - props.history.push(SEARCH_SCREEN_ROUTE)}>
            <Search/>
          </IconButton>
          <IconButton onClick={() => TODO change - props.history.push(getEntityListRoute(ALERT_ROUTE))}>
            <Badge badgeContent={''} color="default">
              <Notifications/>                    
            </Badge>               
          </IconButton>*/}
        <UserMenu userData={props.userData} currentEnterpriseId={props.currentEnterpriseId} />
      </Toolbar>
    </AppBar>
  );
}

export default Header;
