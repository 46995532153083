import React, { useRef, useState, useEffect } from "react";
import { TableCellRendererProps } from "components/Crud/Table/CellRenderers/TableCellRenderer.d";
import { getAttributeByPath } from "components/Crud/EntityUtils";
import { roleOptions } from "./Membership/UserMembership.dict";
import { useActiveEnterprise, useActiveUser } from "components/Utils/CustomHooks";
import { ALL_ENTERPRISES_CODE } from "views/Enterprise/Enterprise.const";
import { makeStyles } from "@material-ui/core";
import { assetCardCellStyle } from "components/Crud/Table/CellRenderers/AssetCardCells/assetCardCellStyle";
import { BasicActionSetRenderer } from "components/Crud/Table/CellRenderers/BasicActionSetRenderer";
import clsx from "clsx";
import { isNonEmptyArray } from "components/Utils/MiscUtils";
import { getDictionaryLabelByCode } from "components/Dictionaries/DictionariesUtils";

const useStyles = makeStyles(assetCardCellStyle, { name: "UserRowCard" });

export function UserRowCard(props: TableCellRendererProps) {
  const classes = useStyles({});

  const username = getAttributeByPath(props.obj, "username");
  const fullName = `${getAttributeByPath(props.obj, "firstName")} ${getAttributeByPath(props.obj, "lastName")}`;
  const email = getAttributeByPath(props.obj, "email");
  const phone = getAttributeByPath(props.obj, "phoneNumber");

  const currentEnterprise = useActiveEnterprise();
  const currentUser = useActiveUser();

  const nameRef = useRef(null);
  const [displayEllipsis, setDisplayEllipsis] = useState(false);

  useEffect(() => {
    if (nameRef.current.scrollHeight > nameRef.current.clientHeight) {
      setDisplayEllipsis(true);
    }
  }, [fullName]);

  return (
    <div
      style={{
        display: "flex",
        flexGrow: 1,
        height: "120px",
        paddingTop: "24px",
        paddingBottom: "24px",
        boxSizing: "border-box",
      }}
    >
      <div style={{ padding: "0 24px", width: "150px" }}>
        <div className={classes.ellipsisOnOverflow}>{username}</div>
        <div>
          <div ref={nameRef} style={{ overflow: "hidden", overflowWrap: "anywhere", maxHeight: "36px" }}>
            {fullName}
          </div>
          {displayEllipsis && <div style={{ lineHeight: "12px" }}>...</div>}
        </div>
      </div>
      <div style={{ padding: "0 24px", width: "250px" }}>
        <div className={clsx(classes.cardCellHeader, classes.cardCellTitleText)}>Email:</div>
        <div className={classes.ellipsisOnOverflow}>{email}</div>
        <div className={clsx(classes.cardCellHeader, classes.cardCellTitleText)}>Phone: </div>
        <div>{phone}</div>
      </div>
      <div style={{ flexGrow: 1 }}>
        <div className={clsx(classes.cardCellHeader, classes.cardCellTitleText)}>ROLE</div>
        {currentUser.isSuperAdmin === true && getAttributeByPath(props.obj, "isSuperAdmin") === true ? (
          <div>
            <b>Superadmin</b>
          </div>
        ) : null}
        {isNonEmptyArray(props.obj.memberships) &&
          props.obj.memberships.map((membershipItem: Record<string, any>, index: number) => {
            const roleName = getDictionaryLabelByCode(roleOptions, membershipItem.role);

            const enterpriseName = getAttributeByPath(membershipItem, "enterprise.name");

            return (
              <div key={index} style={{ display: "flex" }}>
                <div>
                  {currentEnterprise.id === ALL_ENTERPRISES_CODE ? <span>{enterpriseName}: </span> : null}
                  {roleName}
                </div>
              </div>
            );
          })}
      </div>
      <div style={{ padding: "0 24px", width: "170px" }}>
        <BasicActionSetRenderer obj={props.obj} fieldName={props.fieldName} actionProps={props.actionProps} />
      </div>
    </div>
  );
}
