import { isNonEmptyArray } from "components/Utils/MiscUtils";
import { CustomJoinSegmentsPluginSegmentType } from "./CustomJoinSegmentsPlugin.d";

export const joinSegmentsPlugin = {
  // TODO: add gradient fill
  // TODO: better prefix according to the rules https://www.chartjs.org/docs/latest/developers/plugins.html
  id: "joinSegmentsPlugin",

  calculateX: function (chart: any, value: any, inputScaleName: string) {
    let result;
    const scale = chart.scales[inputScaleName];

    const minValueX = scale.min;
    const maxValueX = scale.max;
    const minScaleX = scale.left;
    const maxScaleX = scale.right;

    result = ((value - minValueX) / (maxValueX - minValueX)) * (maxScaleX - minScaleX) + minScaleX;

    return result;
  },

  calculateY: function (chart: any, value: any, inputScaleName: string) {
    let result;
    const scale = chart.scales[inputScaleName];

    const minValueY = scale.min;
    const maxValueY = scale.max;
    const bottomScaleY = scale.bottom;
    const topScaleY = scale.top;

    result = bottomScaleY - ((value - minValueY) / (maxValueY - minValueY)) * (bottomScaleY - topScaleY);

    return result;
  },

  joinTwoDots: function (chart: any, context: any, segmentConfig: CustomJoinSegmentsPluginSegmentType) {
    const startX = this.calculateX(chart, segmentConfig.start.x, segmentConfig.scales.scaleX);
    const startY = this.calculateY(chart, segmentConfig.start.y, segmentConfig.scales.scaleY);

    const finishX = this.calculateX(chart, segmentConfig.finish.x, segmentConfig.scales.scaleX);
    const finishY = this.calculateY(chart, segmentConfig.finish.y, segmentConfig.scales.scaleY);

    context.beginPath();
    context.strokeStyle = segmentConfig.stroke.style;
    context.lineWidth = segmentConfig.stroke.lineWidth;
    context.moveTo(startX, startY);
    context.lineTo(finishX, finishY);
    context.stroke();
  },

  afterDatasetsDraw: function (chart: any, easing: any) {
    const context = chart.chart.ctx;
    const pluginConfig = chart.config.joinSegmentDots;

    if (pluginConfig && isNonEmptyArray(pluginConfig)) {
      pluginConfig.forEach((configItem: CustomJoinSegmentsPluginSegmentType) => {
        if (chart.scales[configItem.scales.scaleX] && chart.scales[configItem.scales.scaleY]) {
          this.joinTwoDots(chart, context, configItem);
        }
      });
    }
  },
};
