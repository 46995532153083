import { useState, useContext, useEffect, useCallback } from "react";
import { OPERATION_SUCCESS, OPERATION_ERROR } from "components/Utils/CRUDUtils.d";
import { fetchDatasetForDataProvider } from "components/Utils/CRUDUtils";
import { ObjectIdType } from "components/Utils/Object.d";
import { MessageBannerDispatcherContext } from "components/Dashboard/Message/MessageBannerFrame";
import { isNonEmptyArray, isSpecified } from "components/Utils/MiscUtils";
import { SampleTypeEnum } from "components/Dictionaries/Dictionaries.d";
import {
  SampleEndpointResponseSegment,
  RequestedDataRangeType,
  GraphDataProviderDataType,
} from "components/Graph/Graph.d";
import { GraphDataProviderProps } from "./GraphDataProvider.d";

// TODO: handle navigate away

export function GraphDataProvider(props: GraphDataProviderProps) {
  const messageBannerContext = useContext(MessageBannerDispatcherContext);

  const [dataProviders, setDataProviders] = useState({
    isDataLoading: false,
    dataProviders: [],
  });
  const [dataArray, setDataArray] = useState({
    isDataLoading: false,
    dataArray: [],
  });
  // const [ isDataLoading, setIsDataLoading ] = useState(false);

  const [requestedDataRange, setRequestedDataRange] = useState(undefined as RequestedDataRangeType);

  const getDatasetForUrl = useCallback(
    async (id: ObjectIdType, url: string) => {
      const response = await fetchDatasetForDataProvider(url);

      let result: SampleEndpointResponseSegment;

      if (response.status.type === OPERATION_SUCCESS && response.data) {
        result = response.data;
      } else if (response.status.type === OPERATION_ERROR) {
        console.error(`Error ${response.status.message} while data for data provider with id = ${id}`);
        result = {
          fields: [],
          samples: [],
          analysisType: undefined,
        };
        messageBannerContext(
          /* .dispatchMessage */ {
            messageType: OPERATION_ERROR,
            messageText: `Error while fetching data for data provider with id = ${id}`,
          }
        );
      }

      return result;
    },
    [messageBannerContext]
  );

  const getDatasetForDataProvider = useCallback(
    async (
      sampleTypeId: SampleTypeEnum,
      id: ObjectIdType,
      rangeStartDate: Date,
      rangeEndDate: Date
    ): Promise<SampleEndpointResponseSegment> => {
      // TODO: MOVE url generation to query engine

      const fetchedResult: any = await Promise.all(
        props.generateDatasetRetrievalUrl.map((urlGeneratingFunction) => {
          const generateDatasetRetrievalUrl = urlGeneratingFunction(
            sampleTypeId,
            id,
            rangeStartDate,
            rangeEndDate
          );

          const returnedDataSet = getDatasetForUrl(id, generateDatasetRetrievalUrl);

          return returnedDataSet;
        })
      );

      // TODO: glue returned results
      // TODO: need to get not the 0, but the last one

      let parsedResult, result;
      if (
        isNonEmptyArray(fetchedResult) &&
        isNonEmptyArray(props.datasetTransformationFunctions) &&
        isSpecified(props.datasetTransformationFunctions[1]) &&
        isSpecified(fetchedResult[1])
      ) {
        parsedResult = props.datasetTransformationFunctions[1](fetchedResult[1], {
          sampleTypeId,
          id,
          rangeStartDate,
          rangeEndDate,
        });

        if (isNonEmptyArray(parsedResult)) {
          result = { data: [].concat(fetchedResult[0].data, parsedResult[0].data) };
        } else {
          result = { data: [].concat(fetchedResult[0].data) };
        }
      } else if (isNonEmptyArray(fetchedResult)) {
        result = fetchedResult[0];
      }

      // return result[0];
      return result;
    },
    [props.generateDatasetRetrievalUrl, props.datasetTransformationFunctions, getDatasetForUrl]
  );

  // TODO: come up with a better architecture
  const fetchDataForGraph = useCallback(async () => {
    if (requestedDataRange && isNonEmptyArray(dataProviders.dataProviders)) {
      setDataArray({
        isDataLoading: true,
        dataArray: [],
      });

      // TODO: enable multiple data sample points on the same Axis and Different Axis

      const dataSet: SampleEndpointResponseSegment[] = await Promise.all(
        dataProviders.dataProviders.map((sp: GraphDataProviderDataType) =>
          getDatasetForDataProvider(
            sp.sampleTypeId,
            sp.id,
            requestedDataRange.requestedRangeStartDate,
            requestedDataRange.requestedRangeEndDate
          )
        )
      );

      setDataArray({
        isDataLoading: false,
        dataArray: dataSet,
      });
    }
  }, [requestedDataRange, dataProviders, getDatasetForDataProvider]);

  const getListOfDataProviders = props.getListOfDataProviders;

  useEffect(() => {
    let didCancel = false;

    const fetchDataProvidersInformation = async () => {
      setDataProviders({
        isDataLoading: true,
        dataProviders: [],
      });

      const dataProvidersResponse = await getListOfDataProviders();

      if (!didCancel) {
        if (dataProvidersResponse.status.type === OPERATION_SUCCESS) {
          setDataProviders({
            isDataLoading: false,
            dataProviders: dataProvidersResponse.data,
          });
        } else {
          console.error(`Error ${dataProvidersResponse.status.message} while fetching graph data providers`);
          messageBannerContext(
            /* .dispatchMessage */ {
              messageType: OPERATION_ERROR,
              messageText: "Error while fetching graph data providers",
            }
          );

          setDataProviders({
            isDataLoading: false,
            dataProviders: [],
          });
        }
      }
    };

    fetchDataProvidersInformation();

    return () => {
      didCancel = true;
    };
  }, [getListOfDataProviders, messageBannerContext]);

  useEffect(() => {
    fetchDataForGraph();
  }, [fetchDataForGraph, requestedDataRange, dataProviders]);

  return props.children(
    dataArray.dataArray,
    dataArray.isDataLoading || dataProviders.isDataLoading,
    setRequestedDataRange, // TODO: it is not safe to call this function directly
    dataProviders.dataProviders
  );
}
