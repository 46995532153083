import { StyleRulesCallback, Theme } from "@material-ui/core";

export const assetSummaryStyles: StyleRulesCallback<Theme, {}> = (theme) => ({
  root: {
    boxSizing: "border-box",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
  },
  contentRoot: {
    flexGrow: 1,
    // boxSizing: 'border-box',
    // height: '100%'
  },
  tabbedPanel: {
    boxSizing: "border-box",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    paddingTop: "24px",
  },
  additionalContent: {
    boxSizing: "border-box",
    minWidth: "320px",
    display: "flex",
    flexDirection: "column",
  },
  additionalContentSmallScreen: {
    marginTop: "20px",
  },
  additionalContentBigScreen: {
    marginLeft: "20px",
  },
});
