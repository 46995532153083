import React, { useCallback } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import messageBannerStyle from "components/Dashboard/Message/messageBannerStyle";
import { useState, useEffect } from "react";
import { OperationStatus } from "components/Utils/CRUDUtils.d";

// TODO: clean up and remove commented /* .dispatchMessage */ throughout the code
// export interface MessageBannerDispatcherContextType {
//   dispatchMessage: (message: MessageType) => void;
// }
export type MessageBannerDispatcherContextType = (message: MessageType) => void;

// TODO: clean up and remove commented /* .dispatchMessage */ throughout the code
// Note: Typescript does not allow for empty intialization.
// export const MessageBannerDispatcherContext = React.createContext<MessageBannerDispatcherContextType>({dispatchMessage: undefined});
export const MessageBannerDispatcherContext = React.createContext<MessageBannerDispatcherContextType>(null);

interface MessageBannerFrameProps {
  children?: React.ReactNode;
}

export interface MessageType {
  messageType: OperationStatus;
  messageText: string;
}

const useStyles = makeStyles(messageBannerStyle, { name: "MessageBannerFrame" });

export function MessageBannerFrame(props: MessageBannerFrameProps) {
  // TODO: add parameter to manipulate message expiration
  const classes = useStyles({});

  const [open, setOpen] = useState(false);
  const [messageQueue, setMessageQueue] = useState<Array<MessageType>>([]);

  const [activeMessage, setActiveMessage] = useState<MessageType>();

  useEffect(() => {
    setActiveMessage(messageQueue[0]);
  }, [messageQueue]);

  useEffect(() => {
    setOpen(activeMessage && true);
  }, [activeMessage]);

  const handleClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    // Keep message open on ClickAway
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
    removeFirstMessageFromTheQueue();
  };

  const addMessageToTheQueue = useCallback((message: MessageType) => {
    setMessageQueue((prevQueue) => {
      return [...prevQueue, message];
    });
  }, []);

  const removeFirstMessageFromTheQueue = () => {
    setMessageQueue((prevQueue) => prevQueue.slice(1));
  };

  return (
    <MessageBannerDispatcherContext.Provider value={addMessageToTheQueue}>
      <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
        <SnackbarContent
          className={clsx(
            classes.content,
            activeMessage && activeMessage.messageType && classes[activeMessage.messageType]
          )}
          message={<span>{activeMessage && activeMessage.messageText}</span>}
          action={
            <IconButton
              key="close"
              aria-label="close"
              color="inherit"
              className={classes.closeDialogIcon}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          }
        />
      </Snackbar>
      {props.children}
    </MessageBannerDispatcherContext.Provider>
  );
}

export default MessageBannerFrame;
