import React, { useCallback } from "react";
import { BaseListProps } from "components/Crud/Table/EntityTable.d";
import { ScreenTitle } from "components/Elements/Typography/ScreenTitle";
import { PrimaryCardValue } from "components/Elements/Typography/PrimaryCardValue";
import { OverviewPlainCard } from "components/Cards/OverviewPlainCard";
import { SecondaryCardValue } from "components/Elements/Typography/SecondaryCardValue";
import { ColumnLayout } from "layouts/ColumnLayout";
import { RowLayout } from "layouts/RowLayout";
import { AlertsTodayPortlet } from "./Portlets/AlertsTodayPortlet";
import { LocalNoticesDemoPortlet } from "ui-mockups/LocalNoticesDemoPortlet";
import { LocalWeatherForecastDemoPortlet } from "ui-mockups/LocalWeatherForecastDemoPortlet";
import { ColumnWrapper } from "layouts/ColumnWrapper";
import { RowWrapper } from "layouts/RowWrapper";
import { MainScreenContentHeader } from "layouts/MainScreenContentHeader";
import { useMediaQueryMatchDown } from "components/Utils/LayoutUtils";
import { ConditionalRender } from "layouts/ConditionalRender";
import { LatestAlertsPortlet } from "./Portlets/LatestAlertsPortlet";
import { isNonEmptyArray, isSpecified } from "components/Utils/MiscUtils";

export interface OverviewSummaryScreenProps {
  latestAlerts: Array<any>;
}

export function OverviewSummaryScreen(props: BaseListProps & OverviewSummaryScreenProps) {
  const isSmallScreen = useMediaQueryMatchDown("sm");

  let alertsNotAcknowledged: JSX.Element;
  let alertsBelowMinimumLevel: JSX.Element;
  let pumpsActive: JSX.Element;
  let weatherPortlet: JSX.Element;
  let localNoticesPortlet: JSX.Element;
  let latestAlertsPortlet: JSX.Element;

  let noUserDataContent: JSX.Element;

  const displayInfo = useCallback(() => {
    let result: boolean;

    result =
      props.currentEnterpriseId !== "" &&
      (props.userData.isSuperAdmin || isNonEmptyArray(props.userData.memberships));

    return result;
  }, [props.currentEnterpriseId, props.userData]);

  if (displayInfo()) {
    alertsNotAcknowledged = (
      <AlertsTodayPortlet userData={props.userData} currentEnterpriseId={props.currentEnterpriseId} />
    );

    alertsBelowMinimumLevel = (
      <OverviewPlainCard title="ALERTS BELOW MINIMUM LEVEL">
        <PrimaryCardValue>2</PrimaryCardValue>
      </OverviewPlainCard>
    );

    pumpsActive = (
      <OverviewPlainCard title="PUMPS ACTIVE">
        <PrimaryCardValue>12</PrimaryCardValue>
        <SecondaryCardValue> / 12</SecondaryCardValue>
      </OverviewPlainCard>
    );

    weatherPortlet = <LocalWeatherForecastDemoPortlet />;

    localNoticesPortlet = <LocalNoticesDemoPortlet />;

    latestAlertsPortlet = (
      <LatestAlertsPortlet userData={props.userData} currentEnterpriseId={props.currentEnterpriseId} />
    );
  } else {
    if (!isSpecified(props.userData)) {
      noUserDataContent = (
        <div
          style={{
            flexGrow: 1,
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div>
            <p>
              <b>WARNING</b>
            </p>
            <div>User Data is not available.</div>
            <div>Contact the Administrator</div>
          </div>
        </div>
      );
    } else if (!(props.userData.isSuperAdmin || isNonEmptyArray(props.userData.memberships))) {
      noUserDataContent = <div>User is not assigned to any Enterprise</div>;
    } else if (props.currentEnterpriseId === "") {
      noUserDataContent = <div>Enterprise Is not selected</div>;
    } else {
      noUserDataContent = <div>Unknown Problem. Contact the Administrator.</div>;
    }
  }

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <MainScreenContentHeader>
        <div>
          <ScreenTitle>Your Summary</ScreenTitle>
        </div>
      </MainScreenContentHeader>
      {displayInfo() ? null : noUserDataContent}
      {displayInfo() ? (
        <RowLayout spacingName="regularSpacing">
          <RowWrapper>
            <ConditionalRender
              condition={isSmallScreen}
              onTrue={
                <RowLayout spacingName="cardSpacing" equalHeight>
                  <RowWrapper>{alertsNotAcknowledged}</RowWrapper>
                  <RowWrapper>{alertsBelowMinimumLevel}</RowWrapper>
                  <RowWrapper>{pumpsActive}</RowWrapper>
                </RowLayout>
              }
              onFalse={
                <ColumnLayout spacingName="cardSpacing" equalWidth={true}>
                  <ColumnWrapper>{alertsNotAcknowledged}</ColumnWrapper>
                  <ColumnWrapper>{alertsBelowMinimumLevel}</ColumnWrapper>
                  <ColumnWrapper>{pumpsActive}</ColumnWrapper>
                </ColumnLayout>
              }
            />
          </RowWrapper>
          <RowWrapper>
            <ConditionalRender
              condition={isSmallScreen}
              onTrue={
                <RowLayout spacingName="cardSpacing">
                  <RowWrapper>{latestAlertsPortlet}</RowWrapper>
                  <RowWrapper>{weatherPortlet}</RowWrapper>
                  <RowWrapper>{localNoticesPortlet}</RowWrapper>
                </RowLayout>
              }
              onFalse={
                <ColumnLayout spacingName="cardSpacing">
                  <ColumnWrapper width="75%" alignSelf="stretch">
                    {latestAlertsPortlet}
                  </ColumnWrapper>
                  <ColumnWrapper flexGrow={1}>
                    <RowLayout spacingName="cardSpacing">
                      <RowWrapper>{weatherPortlet}</RowWrapper>
                      <RowWrapper>{localNoticesPortlet}</RowWrapper>
                    </RowLayout>
                  </ColumnWrapper>
                </ColumnLayout>
              }
            />
          </RowWrapper>
        </RowLayout>
      ) : null}
    </div>
  );
}
