import "assets/css/material-dashboard-react.css?v=1.2.0";
import React from "react";
import * as ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router } from "react-router-dom";
import { auth0Domain, auth0ClientId, auth0Audience } from "views/constants";
import { Auth0Provider } from "components/Session/Auth0Provider";
import { App } from "App";

export const hist = createBrowserHistory();

// TODO: uncomment for debugging
// DONT FORGET TO add - import { RouterProps } from 'react-router';
// class DebugRouter extends Router {
//   constructor(props: RouterProps) {
//     super(props);
//     console.log(/*KEEP*/'initial history is: ', JSON.stringify(this.props.history, null, 2));
//     this.props.history.listen((location, action) => {
//       console.log(/*KEEP*/
//         `The current URL is ${location.pathname}${location.search}${location.hash}`
//       );
//       console.log(/*KEEP*/`The last navigation action was ${action}`, JSON.stringify(this.props.history, null, 2));
//     });
//   }
// }

const onRedirectCallback = (appState: any) => {
  hist.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
};

ReactDOM.render(
  <Auth0Provider
    onRedirectCallback={onRedirectCallback}
    initOptions={{
      domain: auth0Domain,
      client_id: auth0ClientId,
      audience: auth0Audience,
      redirect_uri: window.location.origin,
    }}
  >
    <Router history={hist}>
      <App />
    </Router>
  </Auth0Provider>,
  document.getElementById("root")
);
