import { useState, useCallback, useContext } from "react";
import { GlobalEnterpriseContext, GlobalUserContext } from "GlobalContext";

export function useClientRect() {
  const [rect, setRect] = useState(null);
  const ref = useCallback((node) => {
    if (node !== null) {
      setRect(node.getBoundingClientRect());
    }
  }, []);
  return [rect, ref];
}

export function useVersion(): [number, () => void] {
  const [version, setVersion] = useState(0);

  const increaseVersion: () => void = useCallback(() => {
    setVersion((prevVersion) => prevVersion + 1);
    return;
  }, []);

  return [version, increaseVersion];
}

export function useActiveEnterprise() {
  const activeEnterprise = useContext(GlobalEnterpriseContext);

  return activeEnterprise;
}

export function useActiveUser() {
  const activeUser = useContext(GlobalUserContext);

  return activeUser;
}
