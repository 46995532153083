import React from "react";
import { TableCellRendererProps } from "../TableCellRenderer.d";
import { getAttributeByPath } from "components/Crud/EntityUtils";
import { AssetNameAndTypeCardCellRenderer } from "./AssetNameAndTypeCardCellRenderer";
import { AssetMeasurementCardCellRenderer } from "./AssetMeasurementCardCellRenderer";
import { LatestAlertCardCellRenderer } from "./LatestAlertCardCellRenderer";
import { BasicActionSetRenderer } from "../BasicActionSetRenderer";
import { ConditionalRender } from "layouts/ConditionalRender";

export function BigAssetCard(props: TableCellRendererProps) {
  const pendingNotificationsCount = getAttributeByPath(props.obj, "pendingNotificationCount");

  const latestPendingNotification = getAttributeByPath(props.obj, "latestPendingNotification");

  return (
    <div
      style={{
        flexGrow: 1,
        paddingTop: "24px",
        paddingBottom: "24px",
        boxSizing: "border-box",
      }}
    >
      <div style={{ width: "100%" }}>
        <div style={{ boxSizing: "border-box", width: "100%", display: "flex", alignItems: "start" }}>
          <div style={{ flexGrow: 1 }}>
            <AssetNameAndTypeCardCellRenderer
              assetName={getAttributeByPath(props.obj, "name")}
              assetTypeId={getAttributeByPath(props.obj, "assetTypeId")}
              locationName={getAttributeByPath(props.obj, "site.name")}
              statusId={getAttributeByPath(props.obj, "id")}
              borderRight={false}
              borderLeft={false}
            />
          </div>
          <div style={{ paddingRight: "16px" }}>
            <BasicActionSetRenderer
              obj={props.obj}
              fieldName={props.fieldName}
              actionProps={props.actionProps}
            />
          </div>
        </div>
        <div style={{ display: "flex" }}>
          {/*           <div style={{boxSizing: 'border-box', width: '186px'}}>
            <LocationAndStatusCardCellRenderer 
              locationName={getAttributeByPath(props.obj, 'site.name')}
              statusId={getAttributeByPath(props.obj, 'id')}
              borderRight={true}
              borderLeft={false}        
            />
          </div> */}
          <AssetMeasurementCardCellRenderer
            samplePoints={getAttributeByPath(props.obj, "samplePoints")}
            borderRight={false}
            borderLeft={false}
          />
        </div>
        <div style={{ display: "flex" }}>
          <div style={{ flexGrow: 1 }}>
            <ConditionalRender condition={pendingNotificationsCount > 0}>
              <LatestAlertCardCellRenderer
                latestPendingNotification={latestPendingNotification}
                pendingNotificationsCount={pendingNotificationsCount}
                borderRight={false}
                borderLeft={false}
              />
            </ConditionalRender>
          </div>
        </div>
      </div>
    </div>
  );
}
