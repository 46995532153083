import React, { useState, useMemo } from "react";
import { RowLayout } from "layouts/RowLayout";
import { RowWrapper } from "layouts/RowWrapper";
import { GraphComponentHeaderContainer } from "./GraphComponentHeaderContainer";
import { RenderIfSpecified } from "layouts/RenderIfSpecified";
import { ConditionalRender } from "layouts/ConditionalRender";
import { ScreenSectionTitle } from "components/Elements/Typography/ScreenSectionTitle";
import { FlexSpaceFiller } from "layouts/FlexSpaceFiller";
import { CardSubTitle } from "components/Elements/Typography/CardSubTitle";
import { CustomRadio } from "components/Radio/CustomRadio";
import { LinearProgress } from "@material-ui/core";
import GraphWidget from "./GraphWidget";
import {
  convertEndpointDataToXY,
  convertEndpointDataToTableData,
  findDotsToConnect,
  convertSamplePointDataToGraphDataWithConfig,
} from "./GraphDataUtils";
import { GraphTableWidget } from "./GraphTableWidget";
import { isNonEmptyArray } from "components/Utils/MiscUtils";
import { distinctCount, sortObjectArrayBy } from "components/Utils/ObjectUtils";
import moment from "moment-timezone";
import { useMediaQueryMatchDown } from "components/Utils/LayoutUtils";
import {
  ComponentModeType,
  GraphDataRequestParametersType,
  SampleEndpointResponse,
  DataSourceDescriptorType,
  ChartConfigurationOptions,
} from "./Graph.d";
import { dataDisplayOptions } from "./GraphCommon";

export interface GraphComponentDataProps {
  title: string | JSX.Element;
  isDataLoading: boolean;
  requestedGraphData: GraphDataRequestParametersType;
  dataArray: Array<SampleEndpointResponse>;
  dataSourceDescriptors: Array<DataSourceDescriptorType>;
  timeZone: string;
  dataAvailable: boolean;
  toggleControls?: () => void;
}

export function GraphComponentData(props: GraphComponentDataProps) {
  const chartType = "line"; // const [ chartType, setChartType ] = useState('line' as ChartType);
  const timeUnit = "day"; // const [ timeUnit, setTimeUnit ] = useState('day' as TimeUnit);

  const [componentMode, setComponentMode] = useState(ComponentModeType.GRAPH);

  const chartConfigByDataDescriptors = (
    dataDescriptors: Array<DataSourceDescriptorType>,
    requestedGraphData: GraphDataRequestParametersType
  ): ChartConfigurationOptions => {
    if (!requestedGraphData.requestedRange || !dataDescriptors) {
      return undefined;
    }

    const baseConfig: Partial<ChartConfigurationOptions> = {
      xAxisUnits: timeUnit,
      // Example: title: 'My graph title',
      legendPosition: "none",
      disableAnimation: true,
      disableCurvedLine: true,
      // Example: xAxisLabel: 'Date',
      timeZone: props.timeZone ? props.timeZone : moment.tz.guess(),
      minX: requestedGraphData.requestedRange.displayedRangeStartDate,
      maxX: requestedGraphData.requestedRange.displayedRangeEndDate,
      // Example: useRelativeScale: true,
      // Example: minLeftY: -25,
      // Example: maxLeftY: 150,
      // Example: stepLeftY: 25,
      minRightY: 0,
      // Example: maxRightY: 100,
      // Example: stepRightY: 15
    };

    if (isNonEmptyArray(props.dataSourceDescriptors)) {
      const distinctKeys = sortObjectArrayBy(distinctCount(dataDescriptors, "units"), "count", "desc");

      // TODO: support more than 2 distinct keys
      const leftKey = distinctKeys[0].key;
      let sampleTypeNamesByFrequence = sortObjectArrayBy(
        distinctCount(
          dataDescriptors.filter((item) => item.units === leftKey),
          "sampleTypeName"
        ),
        "count",
        "desc"
      );
      baseConfig.yAxisLabel = `${sampleTypeNamesByFrequence.map((item) => item.key).join(",")} (${leftKey})`;
      baseConfig.leftHandAxisId = leftKey;

      if (distinctKeys.length > 1) {
        const rightKey = distinctKeys[1].key;
        sampleTypeNamesByFrequence = sortObjectArrayBy(
          distinctCount(
            dataDescriptors.filter((item) => item.units === rightKey),
            "sampleTypeName"
          ),
          "count",
          "desc"
        );
        baseConfig.rightHandAxisLabel = `${sampleTypeNamesByFrequence
          .map((item) => item.key)
          .join(",")} (${rightKey})`;
        baseConfig.rightHandAxisId = rightKey;
      }
    }

    return baseConfig as ChartConfigurationOptions;
  };

  const chartConfig: ChartConfigurationOptions = chartConfigByDataDescriptors(
    props.dataSourceDescriptors,
    props.requestedGraphData
  );

  const chartDataWithConfig: Array<any> = useMemo(() => {
    const result = convertSamplePointDataToGraphDataWithConfig(props.dataArray, props.dataSourceDescriptors);

    return result;
  }, [props.dataArray, props.dataSourceDescriptors]);

  let pureDataToDraw, joinSegmentsConfig;

  if (componentMode === "graph") {
    pureDataToDraw = chartDataWithConfig.map((data) =>
      convertEndpointDataToXY(data, data.samplePoint.dateType)
    );

    joinSegmentsConfig = findDotsToConnect(chartDataWithConfig);
  } else if (componentMode === "table") {
    pureDataToDraw = chartDataWithConfig.map((data: any, index: number) =>
      convertEndpointDataToTableData(data, data.samplePoint.dateType, index)
    );
  }

  const GraphDataIsNotAvailable = (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      Data is not available
    </div>
  );

  const isSmallScreen = useMediaQueryMatchDown("sm");

  let warningIfMoreThanTwoAxis: string = null;
  if (componentMode === ComponentModeType.GRAPH && isNonEmptyArray(props.dataSourceDescriptors)) {
    const distinctValues = distinctCount(props.dataSourceDescriptors, "units");

    if (distinctValues.length > 2) {
      warningIfMoreThanTwoAxis =
        "Note: Selected datasources use 3 different units and would require 3 vertical axis. Only first 2 are shown.";
    }
  }

  return (
    <div style={{ height: "100%", flexGrow: 1 }}>
      <RowLayout height="100%">
        <RowWrapper>
          <GraphComponentHeaderContainer>
            <ConditionalRender
              condition={isSmallScreen}
              onTrue={
                <React.Fragment>
                  <CustomRadio
                    value={componentMode}
                    options={dataDisplayOptions}
                    onChange={(newValue: ComponentModeType) => setComponentMode(newValue)}
                  />
                  <FlexSpaceFiller />
                </React.Fragment>
              }
              onFalse={
                <React.Fragment>
                  <RenderIfSpecified value={props.title}>
                    {(propsTitle) => (
                      <div>
                        <ConditionalRender
                          condition={typeof propsTitle === "string"}
                          onTrue={<ScreenSectionTitle>{propsTitle}</ScreenSectionTitle>}
                          onFalse={propsTitle}
                        />
                      </div>
                    )}
                  </RenderIfSpecified>
                  <FlexSpaceFiller />
                  <div style={{ paddingRight: "16px" }}>
                    <CardSubTitle>{"DISPLAY"}</CardSubTitle>
                  </div>
                  <CustomRadio
                    value={componentMode}
                    options={dataDisplayOptions}
                    onChange={(newValue: ComponentModeType) => setComponentMode(newValue)}
                  />
                </React.Fragment>
              }
            />
          </GraphComponentHeaderContainer>
        </RowWrapper>
        <div style={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
          <div
            /* Content */ style={{
              boxSizing: "border-box",
              padding: "24px",
              flexGrow: 1,
              boxShadow: "inset -1px -1px 0 0 #EEEEEE",
              height: "100%",
            }}
          >
            <ConditionalRender condition={props.isDataLoading}>
              <LinearProgress style={{ width: "100%" }} />
            </ConditionalRender>
            <ConditionalRender condition={!props.dataAvailable}>{GraphDataIsNotAvailable}</ConditionalRender>
            {componentMode === "graph" &&
              props.dataAvailable &&
              props.requestedGraphData &&
              props.requestedGraphData.requestedRange && (
                <div>
                  {warningIfMoreThanTwoAxis && (
                    <div style={{ paddingBottom: "12px", fontStyle: "italic", fontSize: "12px" }}>
                      {warningIfMoreThanTwoAxis}
                    </div>
                  )}
                  <GraphWidget
                    chartType={chartType}
                    data={pureDataToDraw}
                    dataConfig={chartDataWithConfig.map((data) => data.chartConfig)}
                    chartConfig={chartConfig}
                    joinSegmentsConfig={joinSegmentsConfig}
                  />
                </div>
              )}
            {componentMode === "table" &&
              props.dataAvailable &&
              props.requestedGraphData &&
              props.requestedGraphData.requestedRange && (
                <GraphTableWidget
                  data={pureDataToDraw}
                  dataConfig={chartDataWithConfig.map((data) => data.chartConfig)}
                  timeZone={props.timeZone}
                  minDate={props.requestedGraphData.requestedRange.displayedRangeStartDate}
                  maxDate={props.requestedGraphData.requestedRange.displayedRangeEndDate}
                />
              )}
          </div>
        </div>
      </RowLayout>
    </div>
  );
}
