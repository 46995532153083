import { TableCellRendererProps } from "./TableCellRenderer.d";
import React from "react";
import { hasAttributeByPath, getAttributeByPath } from "components/Crud/EntityUtils";
import { px } from "components/Utils/LayoutUtils";

export class DropdownOption {
  // TODO: we have a similar interface in selects, need to combine
  code: number;
  label: string;
}

export function BasicCellRenderer(props: TableCellRendererProps) {
  let value = "";

  if (hasAttributeByPath(props.obj, props.fieldName)) {
    value = getAttributeByPath(props.obj, props.fieldName);

    if (value !== null && typeof value !== "string") {
      value = String(value);
    }
  }

  let maxWidth: string;

  if (typeof props.maxWidth === "string") {
    maxWidth = props.maxWidth;
  } else if (typeof props.maxWidth === "number") {
    maxWidth = px(props.maxWidth);
  }

  return <div style={{ maxWidth: maxWidth, overflow: "hidden", textOverflow: "ellipsis" }}>{value}</div>;
}
