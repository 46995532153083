import React from "react";
import { makeStyles } from "@material-ui/core";
import { primaryCardValueStyle } from "./primaryCardValueStyle";

export interface PrimaryCardValueProps {
  children: string;
}

const useStyles = makeStyles(primaryCardValueStyle, { name: "PrimaryCardValue" });

export function PrimaryCardValue(props: PrimaryCardValueProps) {
  const classes = useStyles({});

  return <span className={classes.root}>{props.children}</span>;
}
