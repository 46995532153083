import { isSpecified } from "components/Utils/MiscUtils";

function convertBracketPropertyPathToDotted(inputKey: string) {
  let outputKey = inputKey;

  outputKey = outputKey.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
  outputKey = outputKey.replace(/^\./, ""); // strip a leading dot

  return outputKey;
}

export const isFieldPath = (fieldName: string) => {
  if (fieldName.indexOf(".") >= 0 || fieldName.indexOf("[") >= 0) {
    return true;
  } else {
    return false;
  }
};

/**
 * Extracts an attribute from an object.
 * Path example: 'part3[0].name'
 * @param obj
 * @param key
 */
export function getAttributeByPath(obj: Readonly<any>, key: Readonly<string>) {
  if (!isSpecified(obj)) {
    return undefined;
  }

  if (!isFieldPath(key)) {
    return obj[key];
  }

  const internalKey = convertBracketPropertyPathToDotted(key);
  let internalObj = JSON.parse(JSON.stringify(obj)); // TODO: probably use immer

  const a = internalKey.split(".");
  for (let i = 0, n = a.length; i < n; ++i) {
    const k = a[i];
    if (isSpecified(internalObj) && k in internalObj) {
      internalObj = internalObj[k];
    } else {
      return;
    }
  }

  return internalObj;
}

/**
 * Sets an attribute by path. Creates intermediate objects if necessary.
 * It modifies the object input
 * Path example: 'part3.name'
 * @param obj Object to be modified
 * @param path
 * @param value
 */
export function setAttributeByPath(
  obj: any,
  path: Readonly<string>,
  value: Readonly<string | number | Date> | Array<any>
) {
  if (!isFieldPath(path)) {
    obj[path] = value;

    return obj;
  }

  let schema = obj; // a moving reference to internal objects within obj

  const internalKey = convertBracketPropertyPathToDotted(path);

  const pList = internalKey.split(".");
  const len = pList.length;

  for (let i = 0; i < len - 1; i++) {
    const elem = pList[i];

    if (!schema[elem]) {
      // TODO: update unit tests
      if (pList[i + 1] && !isNaN((pList[i + 1] as unknown) as number)) {
        schema[elem] = [];
      } else {
        schema[elem] = {};
      }
    }

    schema = schema[elem];
  }

  schema[pList[len - 1]] = value;
}

/**
 * Delete an attribute by path. Creates intermediate objects if necessary.
 * It modifies the object input
 * Path example: 'part3.name'
 * @param obj Object to be modified
 * @param path
 */
export function deleteAttributeByPath(obj: any, path: Readonly<string>) {
  if (!isFieldPath(path)) {
    delete obj[path];
  }

  const internalKey = convertBracketPropertyPathToDotted(path);

  const a = internalKey.split(".");
  let movingObject = obj;

  for (let i = 0; i < a.length - 1; i++) {
    const k = a[i];
    movingObject = movingObject[k];
  }

  const lastKey = a[a.length - 1];
  delete movingObject[lastKey];
}

export function hasAttributeByPath(obj: Readonly<any>, path: Readonly<string>) {
  if (!isSpecified(obj)) {
    return undefined;
  }

  if (!isFieldPath(path)) {
    return obj.hasOwnProperty(path);
  }

  let schema = obj; // a moving reference to internal objects within obj

  const internalKey = convertBracketPropertyPathToDotted(path);

  const pList = internalKey.split(".");
  const len = pList.length;

  for (let i = 0; i < len - 1; i++) {
    const elem = pList[i];

    if (schema && !schema.hasOwnProperty(elem)) {
      return false;
    }

    if (schema) {
      schema = schema[elem];
    }
  }

  if (!schema) {
    return false;
  }

  const result = schema.hasOwnProperty(pList[pList.length - 1]);

  return result;
}
