import { useContext, useState, useEffect } from "react";
import {
  DatasetReducerEventActionType,
  DatasetOperationResult,
} from "components/Crud/DataProvider/DatasetManager.d";
import { isSpecified } from "components/Utils/MiscUtils";
import { ObjectIdType } from "components/Utils/Object.d";
import { GlobalPopupContext } from "GlobalContext";
import { OperationsFlagType } from "./PopupDialogsFrame.d";

export function usePopupOperationController(lastOperationResult: DatasetOperationResult) {
  const { closePopup } = useContext(GlobalPopupContext);

  const [watchOperationResult, setWatchOperationResult] = useState(false);
  useEffect(() => {
    if (lastOperationResult === null) {
      setWatchOperationResult(true);
    } else if (watchOperationResult && isSpecified(lastOperationResult) && lastOperationResult.isSuccess) {
      if (
        lastOperationResult.operationType === DatasetReducerEventActionType.ADD ||
        lastOperationResult.operationType === DatasetReducerEventActionType.UPDATE
      ) {
        closePopup(true);
      }

      setWatchOperationResult(false);
    }
  }, [closePopup, watchOperationResult, lastOperationResult]);
}

export function useOperationController(
  lastOperationResult: DatasetOperationResult,
  immutableCallback: (isSuccess?: boolean, relatedId?: ObjectIdType) => void,
  flags: OperationsFlagType
) {
  // TODO: Unify with usePopupOperationController
  // TODO: extend to accept parameters for the immutableCallback other than is success

  const [watchOperationResult, setWatchOperationResult] = useState(false);

  const {
    onAddSuccess = false,
    onAddFailure = false,
    onUpdateSuccess = false,
    onUpdateFailure = false,
    onDeleteSuccess = false,
    onDeleteFailure = false,
  } = flags;

  useEffect(() => {
    if (lastOperationResult === null) {
      setWatchOperationResult(true);
    } else if (watchOperationResult && isSpecified(lastOperationResult)) {
      if (
        lastOperationResult.isSuccess &&
        ((onAddSuccess && lastOperationResult.operationType === DatasetReducerEventActionType.ADD) ||
          (onUpdateSuccess && lastOperationResult.operationType === DatasetReducerEventActionType.UPDATE) ||
          (onDeleteSuccess && lastOperationResult.operationType === DatasetReducerEventActionType.DELETE))
      ) {
        immutableCallback(true, lastOperationResult.relatedId);
      } else if (
        !lastOperationResult.isSuccess &&
        ((onAddFailure && lastOperationResult.operationType === DatasetReducerEventActionType.ADD) ||
          (onUpdateFailure && lastOperationResult.operationType === DatasetReducerEventActionType.UPDATE) ||
          (onDeleteFailure && lastOperationResult.operationType === DatasetReducerEventActionType.DELETE))
      ) {
        immutableCallback(false, lastOperationResult.relatedId);
      }
      setWatchOperationResult(false);
    }
  }, [
    immutableCallback,
    watchOperationResult,
    lastOperationResult,
    onAddSuccess,
    onAddFailure,
    onUpdateSuccess,
    onUpdateFailure,
    onDeleteSuccess,
    onDeleteFailure,
  ]);
}
