import React from "react";
import { SimpleEnum } from "components/Crud/Table/CellRenderers/SimpleEnum";
import { ENTERPRISE_API_URL, ENTERPRISE_ENTITY_NAME, SWITCH_TO_ENTERPRISE } from "./Enterprise.const";
import { enterpriseTypeOptions, businessTypeOptions, enterpriseStatusOptions } from "./Enterprise.dict";
import { EnterpriseEditForm } from "./EnterpriseEditForm";
import { SORT_ASC, UnifiedDataRequestType, FilterMatchTypes } from "components/Utils/CRUDUtils.d";
import { TableCellRendererProps } from "components/Crud/Table/CellRenderers/TableCellRenderer.d";
import { BaseListProps, TableColumnParameters, TableColumnFilter } from "components/Crud/Table/EntityTable.d";
import {
  generateEditButtonConfig,
  generateShowButtonConfig,
  generateCreateButtonConfig,
  generateDeleteButtonConfig,
  GLOBAL_DISPATCHER,
} from "components/Buttons/ButtonsCommon";
import { DataSourceParamsType } from "components/Crud/DataProvider/DataProviderCommon.d";
import { CrudQueryTableBinding } from "components/Crud/Table/CrudQueryTableBinding";
import { useVersion } from "components/Utils/CustomHooks";
import { refineRequest, getEntityListRoute } from "components/Utils/RouteUtils";
import { mergeRecords } from "components/Utils/ObjectUtils";
import { SITE_ROUTE, SITE_ENTITY_NAME } from "views/Site/Site.const";
import Home from "@material-ui/icons/Home";
import { LIST_ACTION } from "components/Crud/Entity.const";
import People from "@material-ui/icons/People";
import { TableActionButtonFace } from "components/Buttons/TableActionButtonFace";
import { USER_ENTITY_NAME, USER_ROUTE } from "views/User/User.const";
import { GlobalEvent } from "GlobalContext.d";
import Business from "@material-ui/icons/Business";

const switchToEnterprisePayload = (object: Record<string, any>) => {
  const result = { enterpriseId: object.id };

  return result;
};

export function EnterpriseList(props: BaseListProps) {
  const [version, increaseVersion] = useVersion();

  const columns: TableColumnParameters[] = [
    // { name: 'id', label: 'Id', hide: {smDown: true}},
    {
      sourceName: "name",
      label: "Name",
      width: 300,
    },
    {
      sourceName: "businessType",
      label: "Business Type",
      hideForBreakpoints: { smDown: true },
      valueTransformationFunction: (rendererProps: TableCellRendererProps) => (
        <SimpleEnum options={businessTypeOptions} {...rendererProps} />
      ),
    },
    {
      sourceName: "enterpriseType",
      label: "Enterprise Type",
      hideForBreakpoints: { mdDown: true },
      valueTransformationFunction: (rendererProps: TableCellRendererProps) => (
        <SimpleEnum options={enterpriseTypeOptions} {...rendererProps} />
      ),
    },
    {
      sourceName: "enterpriseStatus",
      label: "Enterprise Status",
      hideForBreakpoints: { mdDown: true },
      valueTransformationFunction: (rendererProps: TableCellRendererProps) => (
        <SimpleEnum options={enterpriseStatusOptions} {...rendererProps} />
      ),
    },
    {
      disableSorting: true,
      actionSet: [
        {
          labelElement: (
            <TableActionButtonFace>
              <Home fontSize="small" />
              &nbsp;<span>Enterprise Sites</span>
            </TableActionButtonFace>
          ),
          name: "sites",
          onClick: {
            type: "navigate",
            url: getEntityListRoute(SITE_ROUTE),
            // paramName : 'samplePointId',
            urlSearch: { enterpriseId: { valueSource: "id" } },
          },
          actionCode: LIST_ACTION,
          actionObject: SITE_ENTITY_NAME,
        },
        {
          labelElement: (
            <TableActionButtonFace>
              <People fontSize="small" />
              &nbsp;<span>Enterprise Users</span>
            </TableActionButtonFace>
          ),
          name: "users",
          onClick: {
            type: "navigate",
            url: getEntityListRoute(USER_ROUTE),
            // paramName : 'samplePointId',
            urlSearch: { "memberships.enterpriseId": { valueSource: "id" } },
          },
          actionCode: LIST_ACTION,
          actionObject: USER_ENTITY_NAME,
        },
        {
          labelElement: (
            <TableActionButtonFace>
              <Business fontSize="small" />
              &nbsp;<span style={{ whiteSpace: "nowrap" }}>Switch To Enterprise</span>
            </TableActionButtonFace>
          ),
          name: "switchToEnterprise",
          onClick: {
            type: "dispatchEvent",
            dispatcherName: GLOBAL_DISPATCHER,
            eventFormingFunction: switchToEnterprisePayload,
            action: GlobalEvent.CHANGE_CURRENT_ENTERPRISE,
          },
          actionCode: SWITCH_TO_ENTERPRISE,
          actionObject: ENTERPRISE_ENTITY_NAME,
        },
        generateEditButtonConfig(ENTERPRISE_ENTITY_NAME, EnterpriseEditForm, [increaseVersion], {
          title: "Edit Enterprise Details",
        }),
        generateShowButtonConfig(ENTERPRISE_ENTITY_NAME, EnterpriseEditForm, { title: "Show Site Details" }),
        generateDeleteButtonConfig(ENTERPRISE_ENTITY_NAME),
      ],
    },
  ];

  const uiFilters: Array<TableColumnFilter> = [
    {
      filterType: "text",
      filterDataSourceField: "name",
      filterMatchType: FilterMatchTypes.CONTAINS,
      filterParams: {
        placeholder: "ALL ENTERPRISES",
      },
    },
  ];

  const dataSource: DataSourceParamsType = {
    apiUrl: ENTERPRISE_API_URL,
  };

  let initialRequest: UnifiedDataRequestType = {
    sortByColumn: "name",
    sortDirection: SORT_ASC,
    fieldList: ["name", "businessType", "enterpriseType", "enterpriseStatus"],
    filter: {
      baseFilters: [],
    },
    isPaginationEnabled: true,
    rowsPerPage: 10,
    pageNum: 0,
  };

  // to use URL defined query params
  const refinedRequestFromInput = refineRequest(props.inputRequest, uiFilters);
  initialRequest = mergeRecords(initialRequest, refinedRequestFromInput) as UnifiedDataRequestType;

  const headerActions = [
    generateCreateButtonConfig(
      ENTERPRISE_ENTITY_NAME,
      "ADD ENTERPRISE",
      EnterpriseEditForm,
      [increaseVersion],
      { title: "Add Enterprise" }
    ),
  ];

  return (
    <CrudQueryTableBinding
      externalTriggerValues={[props.currentEnterpriseId, props.userData, version]}
      dataSource={dataSource}
      intialQuery={initialRequest}
      title="Enterprises"
      tableColumns={columns}
      onRowClickFunction={props.onRowClickFunction}
      // actionsAllowed={allowedActions}
      highlightedRowsPrimaryKeys={props.activeRowPrimaryKeys}
      uiFilters={uiFilters}
      headerActions={headerActions}
      // to use URL defined query params
      allowUrlReplacementForFilters={props.allowUrlReplacementForFilters}
      allowReinitialization={props.allowReinitialization}
    />
  );
}
