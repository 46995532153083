import React from "react";
import { makeStyles, TextField, MenuItem, Checkbox, ListItemText, Chip, useTheme } from "@material-ui/core";
import OutlinedFieldWrapper from "./OutlinedFieldWrapper";
import chippedSelectFieldStyle from "./chippedSelectFieldStyle";
import { MenuItemType, INPUT_VARIANT, FieldConfiguration } from "./FieldConfiguration.d";
import { RenderIfSpecified } from "layouts/RenderIfSpecified";
import { safeFieldValue } from "../EntityFormCommon";
import clsx from "clsx";
import { CustomThemeType } from "assets/jss/muiTheme";

interface ChippedSelectFieldProps {
  // TODO: this is distinctly different from others where it is. Unify: Array<MenuItemType>;
  optionsArray: Array<MenuItemType>;
  onChange?: (event: React.ChangeEvent<{ value: unknown }>) => void;
  values: Array<any>;
  helperText?: string;
  defaultValue?: string | number;
  errorFlag: boolean;
  fieldConfig: FieldConfiguration;
  disabled?: boolean;
  required?: boolean;
  valueWasModified?: boolean;
}

const useStyles = makeStyles(chippedSelectFieldStyle, { name: "ChippedSelectField" });

function ChippedSelectField(props: ChippedSelectFieldProps) {
  const classes = useStyles({});

  const menuOptions = props.optionsArray || [];

  const safeMultipleValue = safeFieldValue(props.values, [props.defaultValue], []);

  const theme = useTheme() as CustomThemeType;

  return (
    <OutlinedFieldWrapper>
      <TextField
        style={{
          borderRadius: "4px",
          backgroundColor: props.valueWasModified ? theme.customColors.modifiedField : undefined,
        }}
        id={props.fieldConfig.fieldName}
        helperText={props.helperText}
        required={props.required}
        name={props.fieldConfig.fieldName}
        value={safeMultipleValue} // props.values}
        label={props.fieldConfig.label}
        onChange={props.onChange}
        disabled={props.disabled}
        variant={INPUT_VARIANT}
        size="small"
        margin="none"
        fullWidth
        select
        SelectProps={{
          id: props.fieldConfig.fieldName,
          multiple: true,
          renderValue: (selected: any) => {
            return (
              <div className={classes.chips}>
                {(selected as string[]).map((code) => {
                  const option = menuOptions.find((item) => item.code === code);

                  return (
                    <RenderIfSpecified
                      key={code}
                      value={option}
                      render={(optionValue) => <Chip label={optionValue.label} className={classes.chip} />}
                    />
                  );
                })}
              </div>
            );
          },
        }}
      >
        {menuOptions.map((optionItem: MenuItemType) => {
          return (
            <MenuItem key={optionItem.code} value={optionItem.code}>
              <Checkbox checked={props.values.indexOf(optionItem.code) > -1} />
              <ListItemText
                primary={optionItem.label}
                className={clsx(classes.dropdown, classes.ellipsisOnOverflow)}
              />
            </MenuItem>
          );
        })}
      </TextField>
    </OutlinedFieldWrapper>
  );
}

export default ChippedSelectField;
