import React from "react";
import { RouteProps } from "react-router-dom";
import Drawer from "@material-ui/core/Drawer";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { sidebarStyle } from "components/Dashboard/Sidebar/sidebarStyle";
import { DashboardRoute, SideBarCategory } from "components/Utils/RouteUtils.d";
import { UserParams } from "views/User/UserParams.d";
import SidebarItemStaticGroup from "./SidebarItemStaticGroup";
import SidebarItemDynamicGroup from "./SidebarItemDynamicGroup";
import { EnterpriseIdType } from "views/Enterprise/Enterprise.d";
import { ALL_ENTERPRISES_CODE } from "views/Enterprise/Enterprise.const";
import { useMediaQueryMatchDown } from "components/Utils/LayoutUtils";

interface SidebarProps {
  userData: UserParams;
  currentEnterpriseId: EnterpriseIdType;
  open: boolean;
  // TODO: Filtering of global routes within this component is a Mixture of Concepts and concerns
  routes: DashboardRoute[];
  sidebarVersion: number;
}

const useStyles = makeStyles(sidebarStyle, { name: "Sidebar" });

function Sidebar(props: SidebarProps & RouteProps) {
  const classes = useStyles({});

  // let smallerThanMedium = useMediaQuery(
  //   (theme: Theme) => theme.breakpoints.down('md'),
  //   { // Without it it will not be showing correctly, due to pre-rendering calculation
  //     defaultMatches: null,
  //     noSsr: true
  //   }
  // );

  const isSmallScreen = useMediaQueryMatchDown("sm");

  const drawerPaperClassName = clsx(
    classes.drawerPaper,
    props.open ? classes.drawerOpen : isSmallScreen ? classes.drawerCloseMobile : classes.drawerClosed
  );

  return (
    <Drawer
      variant="permanent"
      anchor="left"
      // TODO: this is not needed for the current state - open={open} but potentially needed for responsive design
      className={classes.root}
      classes={{
        // To override behavior of nested components
        paper: drawerPaperClassName,
      }}
      // NOTE: for responsive temprorary bar this can be still useful ModalProps={{ keepMounted: true }}
    >
      <div>
        {props.userData && (
          <SidebarItemStaticGroup
            groupTitle="OVERVIEW"
            routes={props.routes}
            sidebarItemCategory={SideBarCategory.OVERVIEW}
            userData={props.userData}
            currentEnterpriseId={props.currentEnterpriseId}
            allowedActions={[]}
            divider={false}
            open={props.open}
            ifContentIsEmptyDisplay={null}
          />
        )}
        {props.userData && props.currentEnterpriseId !== ALL_ENTERPRISES_CODE && (
          <SidebarItemDynamicGroup
            groupTitle="GROUPS"
            userData={props.userData}
            currentEnterpriseId={props.currentEnterpriseId}
            open={props.open}
            sidebarVersion={props.sidebarVersion}
            ifContentIsEmptyDisplay={"No Groups Configured"}
          />
        )}
        {props.userData && (
          <SidebarItemStaticGroup
            groupTitle="SYSTEM"
            routes={props.routes}
            sidebarItemCategory={SideBarCategory.SYSTEM}
            userData={props.userData}
            currentEnterpriseId={props.currentEnterpriseId}
            allowedActions={[]}
            divider={false}
            open={props.open}
            ifContentIsEmptyDisplay={null}
          />
        )}
        {props.userData && (
          <SidebarItemStaticGroup
            groupTitle="ADMINISTRATION"
            routes={props.routes}
            sidebarItemCategory={SideBarCategory.ADMIN_VIEWS}
            userData={props.userData}
            currentEnterpriseId={props.currentEnterpriseId}
            allowedActions={[]}
            divider={false}
            open={props.open}
            ifContentIsEmptyDisplay={null}
          />
        )}
      </div>
    </Drawer>
  );
}

export default Sidebar;
