import { StyleRulesCallback, Theme } from "@material-ui/core/styles";

export const addButtonStyle: StyleRulesCallback<Theme, {}> = (theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "4px",
    backgroundColor: theme.palette.primary.main,
    height: "36px",
    minWidth: "136px",
    padding: "6px 16px",
    boxSizing: "border-box",
    fontWeight: 500,
    letterSpacing: "0.3px",
    color: "white",
    fontSize: "14px",
  },
});
