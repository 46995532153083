import React from "react";
import outlinedFieldWrapperStyle from "./outlinedFieldWrapperStyle";
import { makeStyles } from "@material-ui/core";

export interface OutlinedFieldWrapperProps {
  children: JSX.Element;
}

const useStyles = makeStyles(outlinedFieldWrapperStyle, { name: "OutlinedFieldWrapper" });

// Note: this component is needed to prevent margin collapse of outlined MUI components
function OutlinedFieldWrapper(props: OutlinedFieldWrapperProps) {
  const classes = useStyles({});

  return <div className={classes.root}>{props.children}</div>;
}

export default OutlinedFieldWrapper;
