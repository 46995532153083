import React from "react";
import { activeRoute } from "components/Utils/RouteUtils";
import { DashboardRoute, SideBarCategory } from "components/Utils/RouteUtils.d";
import { UserParams } from "views/User/UserParams.d";
import SidebarItem from "./SidebarItem";
import { rolesListIncludeUserRole } from "access-control/RBACEngine";
import { EntityActionType } from "components/Crud/Entity.d";
import { EnterpriseIdType } from "views/Enterprise/Enterprise.d";
import { SidebarItemGroup } from "./SidebarItemGroup";
import { isNonEmptyArray } from "components/Utils/MiscUtils";
import { makeStyles } from "@material-ui/core";
import { sidebarItemStyle } from "./sidebarItemstyle";

interface SidebarItemStaticGroupProps {
  routes: Array<DashboardRoute>;
  sidebarItemCategory: SideBarCategory;
  userData: UserParams;
  currentEnterpriseId: EnterpriseIdType;
  allowedActions: Array<EntityActionType>;
  divider?: boolean;
  open: boolean;
  groupTitle?: string;
  ifContentIsEmptyDisplay: string | JSX.Element; // if null - the whole group will be hidden
}

const useStyles = makeStyles(sidebarItemStyle, { name: "SidebarItemStaticGroup" });

function SidebarItemStaticGroup(props: SidebarItemStaticGroupProps) {
  const classes = useStyles({});

  const groupLinks = props.routes
    .filter((prop) => prop.category === props.sidebarItemCategory)
    .filter((prop) => {
      // TODO: refactor for the better code quality
      const filterFlag = rolesListIncludeUserRole(props.userData, props.currentEnterpriseId, prop.show);

      return filterFlag;
    })
    .map((prop, key) => {
      if (prop.redirect) {
        // TODO: why is it within the map if it is invariant???
        return null;
      }

      // IMPORTANT: we configure by role (global) and individual item
      const showConfigButton = rolesListIncludeUserRole(
        props.userData,
        props.currentEnterpriseId,
        prop.showConfig
      );

      return (
        <SidebarItem
          key={key}
          active={activeRoute(prop.path)}
          path={prop.path}
          label={prop.sidebarName}
          icon={prop.icon}
          showConfigButton={showConfigButton}
          allowedActions={props.allowedActions}
          open={props.open}
        />
      );
    });

  let groupContent: JSX.Element;

  if (!isNonEmptyArray(groupLinks) && props.ifContentIsEmptyDisplay === null) {
    groupContent = null;
  } else if (!isNonEmptyArray(groupLinks) && props.ifContentIsEmptyDisplay !== null) {
    let groupChild: JSX.Element;

    if (typeof props.ifContentIsEmptyDisplay === "string") {
      groupChild = <div className={classes.itemText}>{props.ifContentIsEmptyDisplay}</div>;
    } else {
      groupChild = props.ifContentIsEmptyDisplay;
    }

    groupContent = (
      <SidebarItemGroup groupTitle={props.groupTitle} open={props.open}>
        <React.Fragment>{groupChild}</React.Fragment>
      </SidebarItemGroup>
    );
  } else {
    groupContent = (
      <SidebarItemGroup groupTitle={props.groupTitle} open={props.open}>
        {groupLinks}
      </SidebarItemGroup>
    );
  }

  return groupContent;
}

export default SidebarItemStaticGroup;
