import { DataSourceParamsType } from "./DataProviderCommon.d";
import {
  EventDataType,
  DataProviderOperationStatusType,
  EventDataTypeInternal,
} from "./DataProviderCommon.d";
import { UnifiedDataRequestType } from "components/Utils/CRUDUtils.d";

export interface DataQueryDataType {
  data: Array<any>;
  totalRows: number;
  transactionId: number;
}

export enum QueryEventAction {
  REQUEST_DATA = "REQUEST_DATA",
}

export type QueryEventPayload = UnifiedDataRequestType;

export type DataQueryEventType = EventDataType<QueryEventAction, QueryEventPayload>;
export type DataQueryEventTypeInternal = EventDataTypeInternal<QueryEventAction, QueryEventPayload>;

export enum DataQueryOperationStatus {
  INITIAL = "INITIAL",
  LOADING = "LOADING",
  DATA_AVAILABLE = "DATA_AVAILABLE",
  ERROR = "ERROR",
}

export type DataQueryOperationStatusType = DataProviderOperationStatusType<
  QueryEventAction,
  DataQueryOperationStatus,
  DataQueryDataType
>;

export interface DataQueryProps {
  dataSource: DataSourceParamsType;
  initialQuery?: UnifiedDataRequestType;
  externalTriggerValues?: Array<any>;
  allowReinitialization?: boolean;
  render: (
    queryData: DataQueryDataType,
    queryParams: UnifiedDataRequestType,
    querysStatus: Record<string, any>,
    dispatchQueryEvent: (event: DataQueryEventType) => void,
    activeTransactionId?: number
  ) => JSX.Element;
}
