import { StyleRulesCallback, Theme } from "@material-ui/core";

export const discIconStyle: StyleRulesCallback<Theme, {}> = (theme) => ({
  smallIconWrapper: {
    height: "20px",
    width: "20px",
  },
  defaultIconWrapper: {
    height: "24px",
    width: "24px",
  },
});
