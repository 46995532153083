import React from "react";
import entityTableRowsetStyle from "../Sections/entityTableRowsetStyle";
import { makeStyles, Paper } from "@material-ui/core";
import { RowRendererProps } from "./EntityTableUnifiedTableRowRenderer";
import clsx from "clsx";
import { EntityTableCell } from "../Sections/EntityTableCell";

const useStyles = makeStyles(entityTableRowsetStyle, { name: "TableRowCardRow" });

export function TableRowCardRow(props: RowRendererProps) {
  const classes = useStyles({});

  const onClickHandler = props.onRowClickFunction
    ? () => props.onRowClickFunction(props.rowDataObject.id, props.rowDataObject)
    : undefined;

  return (
    <Paper
      classes={{
        root: clsx(
          classes.cardRowWithGutter,
          classes.root,
          classes.hover,
          props.isSelected && classes.selected
        ),
      }}
      onClick={onClickHandler}
    >
      <div className={classes.divRow}>
        {props.tableColumns
          .filter((columnItem) => !columnItem.hide)
          .map((column: any, columnIndex: number) => (
            <EntityTableCell
              key={columnIndex}
              rowDataObject={props.rowDataObject}
              column={column}
              zeroPadding={props.zeroPadding}
              cellContainerElement="div"
              eventDispatchers={props.eventDispatchers}
              restrictActions={props.restrictActions}
            />
          ))}
      </div>
    </Paper>
  );
}
