import React from "react";
import { MainPanelWithParamsProps } from "components/Dashboard/MainPanelWithParams.d";
import { EntityEditRouteParams } from "components/Dashboard/EntityEditRouteParams.d";
import { EnterpriseEditForm } from "./EnterpriseEditForm";

export function EnterpriseEditScreen(props: MainPanelWithParamsProps<EntityEditRouteParams>) {
  return (
    <EnterpriseEditForm
      id={props.match.params.id}
      action={props.match.params.action}
      showCloseButton={false}
      currentEnterpriseId={props.currentEnterpriseId}
      userData={props.userData}
    />
  );
}
