import React from "react";
import { FormLayoutElement } from "components/Crud/Form/Layout/FormLayoutElement";
import { FieldConfiguration } from "../Fields/FieldConfiguration.d";
import { makeStyles } from "@material-ui/core";
import { defaultFormLayoutStyle } from "./defaultFormLayoutStyle";
import { FormLayoutProps } from "./FormLayout.d";

const useStyles = makeStyles(defaultFormLayoutStyle, { name: "DefaultFormLayout" });

export function DefaultFormLayout(props: FormLayoutProps) {
  const classes = useStyles({});

  return (
    <div className={classes.root}>
      {props.fieldConfigurations.map((fieldConfig: FieldConfiguration) => {
        return <FormLayoutElement key={fieldConfig.fieldName} fieldId={fieldConfig.fieldName} {...props} />;
      })}
    </div>
  );
}
