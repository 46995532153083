import React, { useState, useEffect } from "react";
import { USER_MEMBERSHIP_API_URL, USER_MEMBERSHIP_ENTITY_NAME } from "./UserMembership.const";
import { SimpleEnum } from "components/Crud/Table/CellRenderers/SimpleEnum";
import { DataOwnerParameters, UnifiedDataRequestType, SORT_ASC } from "components/Utils/CRUDUtils.d";
import { generateEntityPathString } from "components/Utils/CRUDUtils";
import { UserMembershipEditForm } from "./UserMembershipEditForm";
import { TableCellRendererProps } from "components/Crud/Table/CellRenderers/TableCellRenderer.d";
import { BaseListProps, TableColumnParameters, TableColumnFilter } from "components/Crud/Table/EntityTable.d";
import { DataSourceParamsType } from "components/Crud/DataProvider/DataProviderCommon.d";
import { filterBy } from "components/Utils/MiscUtils";
import { CrudQueryTableBinding } from "components/Crud/Table/CrudQueryTableBinding";
import { useVersion } from "components/Utils/CustomHooks";
import {
  generateCreateButtonConfig,
  generateDeleteButtonConfig,
  generateEditButtonConfig,
  generateShowButtonConfig,
} from "components/Buttons/ButtonsCommon";
import { refineRequest } from "components/Utils/RouteUtils";
import { mergeRecords } from "components/Utils/ObjectUtils";
import { roleOptions } from "./UserMembership.dict";

export interface UserMembershipListProps {
  userId: number;
}

export function UserMembershipList(props: BaseListProps & UserMembershipListProps) {
  const [version, increaseVersion] = useVersion();

  const TITLE_SUFFIX = "Enterprise Memberships";

  const [title, setTitle] = useState(TITLE_SUFFIX);

  useEffect(() => {
    const dataOwner: DataOwnerParameters[] = [
      {
        endpointUrl: `/user/${props.userId}`,
        fields: ["username"],
        titlePrefixTemplate: "User :username > ",
      },
    ];

    generateEntityPathString(dataOwner, (value: string) => setTitle(`${value}${TITLE_SUFFIX}`));
  }, [props.userId]);

  const columns: TableColumnParameters[] = [
    // { name: 'id', label: 'Id', hide: {smDown: true}},
    { sourceName: "enterprise.name", label: "Enterprise Name" },
    {
      sourceName: "role",
      label: "Role",
      hideForBreakpoints: { mdDown: true },
      valueTransformationFunction: (rendererProps: TableCellRendererProps) => (
        <SimpleEnum options={roleOptions} {...rendererProps} />
      ),
    },
    {
      disableSorting: true,
      actionSet: [
        generateEditButtonConfig(USER_MEMBERSHIP_ENTITY_NAME, UserMembershipEditForm, [increaseVersion], {
          title: "Edit User Membership",
        }),
        generateShowButtonConfig(USER_MEMBERSHIP_ENTITY_NAME, UserMembershipEditForm),
        generateDeleteButtonConfig(USER_MEMBERSHIP_ENTITY_NAME),
      ],
    },
  ];

  const uiFilters: Array<TableColumnFilter> = [];

  const getUserId = () => {
    if (!props.userId) {
      throw new Error("User Id is not set");
    }

    return props.userId;
  };

  const dataSource: DataSourceParamsType = {
    apiUrl: USER_MEMBERSHIP_API_URL,
  };

  let initialRequest: UnifiedDataRequestType = {
    sortByColumn: "id",
    sortDirection: SORT_ASC,
    fieldList: ["enterprise.name", "role"],
    filter: {
      baseFilters: [filterBy("userId", "=", getUserId())],
    },
    isPaginationEnabled: true,
    rowsPerPage: 10,
    pageNum: 0,
  };

  // to use URL defined query params
  const refinedRequestFromInput = refineRequest(props.inputRequest, uiFilters);
  initialRequest = mergeRecords(initialRequest, refinedRequestFromInput) as UnifiedDataRequestType;

  const headerActions = [
    generateCreateButtonConfig(
      USER_MEMBERSHIP_ENTITY_NAME,
      "ADD USER MEMBERSHIP",
      UserMembershipEditForm,
      [increaseVersion],
      {
        userId: props.userId,
        title: "Add User Membership",
      }
    ),
  ];

  return (
    <CrudQueryTableBinding
      externalTriggerValues={[props.currentEnterpriseId, props.userData, version]}
      dataSource={dataSource}
      intialQuery={initialRequest}
      title={title}
      tableColumns={columns}
      onRowClickFunction={props.onRowClickFunction}
      // actionsAllowed={allowedActions}
      highlightedRowsPrimaryKeys={props.activeRowPrimaryKeys}
      uiFilters={uiFilters}
      headerActions={headerActions}
      // to use URL defined query params
      allowUrlReplacementForFilters={props.allowUrlReplacementForFilters}
      allowReinitialization={props.allowReinitialization}
    />
  );
}
