import React, { useMemo } from "react";
import { TableColumnParameters } from "components/Crud/Table/EntityTable.d";
import { Accordion, AccordionSummary, AccordionDetails, makeStyles } from "@material-ui/core";
import { startOfDayDate, MLSECS_IN_DAY } from "components/Utils/DateUtils";
import { isNonEmptyArray, isSpecified, pcntOf } from "components/Utils/MiscUtils";
import { px } from "components/Utils/LayoutUtils";
import { sortObjectArrayBy } from "components/Utils/ObjectUtils";
import { useClientRect } from "components/Utils/CustomHooks";
import moment from "moment";
import { SORT_DESC } from "components/Utils/CRUDUtils.d";
import { graphTableWidgetStyle } from "./graphTableWidgetStyle";
import { SAMPLE_POINT_TYPES } from "components/Dictionaries/DictionariesCommon";
import HelpIcon from "@material-ui/icons/Help";
import { hasAttributeByPath } from "components/Crud/EntityUtils";
import { TableDataType, SupportedChartTypes } from "./Graph.d";
import { getDictionaryItemByCode } from "components/Dictionaries/DictionariesUtils";

export interface GraphTableWidgetProps {
  data: Array<any>;
  dataConfig?: any[];
  timeZone: string;
  minDate: Date;
  maxDate: Date;
}

const useStyles = makeStyles(graphTableWidgetStyle, { name: "GraphTableWidget" });

const DAY_AGGREGATION_STRING = "DAY AGG";

export function GraphTableWidget(props: GraphTableWidgetProps) {
  const classes = useStyles({});

  const { joinedArray, extractedDayMarkers } = useMemo(() => {
    // TODO: make more straightforward
    const result: TableDataType = {
      joinedArray: [],
      extractedDayMarkers: [],
    };

    result.joinedArray = (() => {
      let result = [].concat(...props.data);

      result = result.filter(
        (arrayItem) =>
          arrayItem && props.minDate.getTime() <= arrayItem.date && arrayItem.date <= props.maxDate.getTime()
      );

      return result;
    })();

    if (isNonEmptyArray(result.joinedArray)) {
      result.joinedArray = sortObjectArrayBy(result.joinedArray, "date");

      result.extractedDayMarkers.push(
        startOfDayDate(new Date(result.joinedArray[0].date), props.timeZone).getTime()
      );

      result.joinedArray.forEach((arrayItem) => {
        // we assume that array is already sorted sequentially
        const lastDateMs = result.extractedDayMarkers[result.extractedDayMarkers.length - 1];

        if (arrayItem.date - lastDateMs >= MLSECS_IN_DAY) {
          result.extractedDayMarkers.push(startOfDayDate(new Date(arrayItem.date), props.timeZone).getTime());
        }
      });
    }

    return result;
  }, [props.data, props.minDate, props.maxDate, props.timeZone]);

  const tableColumns: TableColumnParameters[] = [
    {
      sourceName: "sourceId",
      label: "Source",
      width: "20%",
    },
    {
      sourceName: "date",
      label: "Time",
      width: "20%",
      // valueTransformationFunction: (rendererProps: TableCellRendererProps) =>
      //   <DateCellRenderer userFriendlyFormat={false} {...rendererProps}/>
    },
    {
      sourceName: "value",
      width: "40%",
      label: "Value",
    },
  ];

  const [rect, ref] = useClientRect(); // Simple ref is not notified on changes

  const calculateWidth = (percentValue: string | number, inputParentWidth: number) => {
    return isSpecified(inputParentWidth)
      ? px(Math.round(pcntOf(percentValue, inputParentWidth)))
      : percentValue;
  };

  const sortedExtractedDayMarkers = extractedDayMarkers.slice().sort((a, b) => b - a);

  const parentWidth = isSpecified(rect) ? rect.width : undefined;

  return (
    <div className={classes.root}>
      <div className={classes.tableHead}>
        {tableColumns.map((columnItem: TableColumnParameters, index: number) => {
          return (
            <div
              key={index}
              className={classes.tableHeadCell}
              style={{
                // Otherwise width is different from children's width due to the scroll bar
                width: calculateWidth(tableColumns[index].width, parentWidth),
              }}
            >
              {columnItem.label}
            </div>
          );
        })}
      </div>
      <div className={classes.tableContent}>
        {sortedExtractedDayMarkers.map((dateMarker: number, index: number) => {
          const dayOfWeekName = isSpecified(props.timeZone)
            ? moment(dateMarker).tz(props.timeZone)
            : moment(dateMarker);

          let accordeonContent = joinedArray.filter((arrayItem) => {
            return arrayItem.date >= dateMarker && arrayItem.date < dateMarker + MLSECS_IN_DAY;
          });

          accordeonContent = sortObjectArrayBy(accordeonContent, "date", SORT_DESC);

          return (
            <Accordion key={index} TransitionProps={{ unmountOnExit: true }} defaultExpanded={true}>
              <AccordionSummary
                classes={{
                  root: classes.expansionPanelRoot,
                  content: classes.content,
                  expanded: classes.expanded,
                }}
              >
                {dayOfWeekName.format("dddd - DD/MM/YYYY z")}
              </AccordionSummary>
              <AccordionDetails classes={{ root: classes.detailsRoot }}>
                <div className={classes.expandedTableWrapper}>
                  {accordeonContent.map((arrayItem: any, itemIndex: number) => {
                    const dateValue = isSpecified(props.timeZone)
                      ? moment(arrayItem.date).tz(props.timeZone)
                      : moment(arrayItem.date);

                    const sampleTypeRecord = getDictionaryItemByCode(
                      SAMPLE_POINT_TYPES,
                      arrayItem.sampleTypeId
                    ) as Record<string, any>;
                    let SampleTypeIconFunction;

                    if (!hasAttributeByPath(sampleTypeRecord, "iconFunction")) {
                      SampleTypeIconFunction = (htmlColor: string) => <HelpIcon htmlColor={htmlColor} />;
                    } else {
                      SampleTypeIconFunction = sampleTypeRecord.iconFunction;
                    }

                    const chartType = props.dataConfig[arrayItem.sourceId].type;
                    const dateTimeValue =
                      chartType === SupportedChartTypes.BAR
                        ? DAY_AGGREGATION_STRING
                        : dateValue.format("HH:mm");

                    const SampleTypeIcon = SampleTypeIconFunction(
                      props.dataConfig[arrayItem.sourceId].backgroundColor
                    );

                    return (
                      <div key={itemIndex} className={classes.tableRow}>
                        <div className={classes.tableCell} style={{ width: tableColumns[0].width }}>
                          {SampleTypeIcon}
                        </div>
                        <div className={classes.tableCell} style={{ width: tableColumns[1].width }}>
                          {dateTimeValue}
                        </div>
                        <div className={classes.tableCell} style={{ width: tableColumns[2].width }}>
                          {arrayItem.value}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </AccordionDetails>
            </Accordion>
          );
        })}
        <div ref={ref} style={{ visibility: "hidden" }} />
      </div>
    </div>
  );
}
