import React from "react";
import { SvgIcon, useTheme } from "@material-ui/core";
import { IconsPathDescriptorType } from "../IconsCommon";

export const TrendArrowFlatSvgPathObject = (color: string): IconsPathDescriptorType => {
  return {
    path: "m 4.9107143,10.4 v 3.231 H 15.356837 v 2.297 L 18.910715,12 15.361929,8.0891934 v 2.3554496 z",
    strokeWidth: "1px",
    strokeColor: color,
    fillColor: color,
  };
};

export function TrendArrowFlat(props: any) {
  const theme = useTheme();
  let color = theme.palette.text.secondary;

  // TODO: this is a hack, color can be inherited from htmlColor properly
  if (props.htmlColor) {
    color = props.htmlColor;
  }

  const svgPathObject = TrendArrowFlatSvgPathObject(color);

  return (
    <SvgIcon {...props}>
      <path
        d={svgPathObject.path}
        fill={svgPathObject.fillColor}
        strokeWidth={svgPathObject.strokeWidth}
        stroke={svgPathObject.strokeColor}
      />
    </SvgIcon>
  );
}
