import React from "react";
import { Paper } from "@material-ui/core";
import { CardSubTitle } from "components/Elements/Typography/CardSubTitle";

export interface OverviewPlainCardProps {
  title: string;
  children: JSX.Element | Array<JSX.Element>;
}

export function OverviewPlainCard(props: OverviewPlainCardProps) {
  return (
    <Paper>
      <div style={{ padding: "24px" }}>
        <CardSubTitle>{props.title}</CardSubTitle>
        <div style={{ height: "24px" }} />
        {props.children}
      </div>
    </Paper>
  );
}
