import userMenuStyle from "components/Dashboard/Header/userMenuStyle";
import React, { MouseEventHandler, useContext } from "react";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import SettingsIcon from "@material-ui/icons/Settings";
import LogoutIcon from "@material-ui/icons/ExitToApp";
import Person from "@material-ui/icons/Person";
import { Button, makeStyles } from "@material-ui/core";
import { getRoleDescription, UserUtils } from "views/User/UserCommon";
import { getEntityEditRoute, getAggregatedPageRoute } from "components/Utils/RouteUtils";
import UserLoginButton from "./UserLoginButton";
import { UserRole } from "components/Crud/EntityGuard.d";
import PopupMenu from "components/PopupMenu/PopupMenu";
import { EnterpriseIdType } from "views/Enterprise/Enterprise.d";
import { ROUTE_LIB } from "components/Utils/RouteList";
import { useActiveEnterprise } from "components/Utils/CustomHooks";
import { isNonEmptyArray, isSpecified } from "components/Utils/MiscUtils";
import { UserParams } from "views/User/UserParams.d";
import { GlobalEventContext } from "GlobalContext";
import { GlobalEvent } from "GlobalContext.d";
import { USER_ROUTE } from "views/User/User.const";

interface UserMenuProps {
  userData: UserParams;
  currentEnterpriseId: EnterpriseIdType;
}

const useStyles = makeStyles(userMenuStyle, { name: "UserMenu" });

function UserMenu(props: UserMenuProps) {
  const classes = useStyles({});

  const globalEventContext = useContext(GlobalEventContext);

  const activeEnterprise = useActiveEnterprise();

  const handleClickSwitchEnterprise = (popupCloseActions?: Function) => {
    globalEventContext(
      /* .dispatchEvent */ {
        type: GlobalEvent.NAVIGATE,
        payload: getAggregatedPageRoute(ROUTE_LIB.SWITCH_ENTERPRISE_ROUTE),
        originator: "UserMenu.handleClickSwitchEnterprise",
      }
    );

    // closeMenu();
    if (popupCloseActions) {
      // TODO: find more gracefull way (e.g. by moving Menu items to Popupmenu as these are repeatable actions)
      popupCloseActions();
    }
  };

  const logout = (popupCloseActions?: Function) => {
    // closeMenu();
    if (popupCloseActions) {
      // TODO: find more gracefull way (e.g. by moving Menu items to Popupmenu as these are repeatable actions)
      popupCloseActions();
    }
    // props.onLogoutCallback();
    globalEventContext(
      /* .dispatchEvent */ {
        type: GlobalEvent.LOGOUT,
        originator: "UserMenu.logout",
      }
    );
  };

  const userEdit = (popupCloseActions?: Function) => {
    // TODO: not popper type stuff

    globalEventContext(
      /* .dispatchEvent */ {
        type: GlobalEvent.NAVIGATE,
        payload: getEntityEditRoute(USER_ROUTE, props.userData.userId),
        originator: "UserMenu.userEdit",
      }
    );

    // closeMenu();
    if (popupCloseActions) {
      // TODO: find more gracefull way (e.g. by moving Menu items to Popupmenu as these are repeatable actions)
      popupCloseActions();
    }
  };

  // TODO: too much code
  const calculateRole = (inputUserData: any, currentEnterpriseId: any) => {
    if (inputUserData && inputUserData.isSuperAdmin) {
      return UserRole.SUPER_ADMIN;
    } else if (isSpecified(currentEnterpriseId) && currentEnterpriseId !== "") {
      let roleId: any;

      if (isNonEmptyArray(inputUserData.memberships)) {
        const activeMembership = inputUserData.memberships.find((membershipValue: any) => {
          return membershipValue.enterpriseId === currentEnterpriseId;
        });

        if (isSpecified(activeMembership)) {
          roleId = activeMembership.role;
        }
      }
      return roleId;
    }

    return undefined;
  };

  // TODO: // 1: When Admin choses and Enterprise it's name it snot shown because membership is not known
  const currentEnterpriseName: string = activeEnterprise.name;
  const userData: UserParams = props.userData;
  const userRole: string = getRoleDescription(calculateRole(userData, activeEnterprise.id));

  return (
    <div className={classes.buttonContainer}>
      <PopupMenu
        // TODO: modify prop so as no to pass function exlicitly
        clickableVisibleItem={(handleClick: MouseEventHandler) => {
          if (userData) {
            return (
              <Button onClick={handleClick} className={classes.buttonLink}>
                <UserLoginButton userData={userData} />
              </Button>
            );
          } else {
            return null;
          }
        }}
      >
        {(menuCloseActions: Function) => (
          <Paper className={classes.dropdown}>
            <div className={classes.accountPanel}>
              <Person />
              <div>{UserUtils.getFullName(userData)}</div>
              <div>{currentEnterpriseName}</div>
              {userRole && userRole}
            </div>
            <Divider />
            <MenuList role="menu" style={{ padding: "0 0 0 0" }}>
              {userData && (userData.isSuperAdmin || isNonEmptyArray(userData.memberships, 2)) && (
                <div>
                  <MenuItem
                    onClick={() => handleClickSwitchEnterprise(menuCloseActions)}
                    className={classes.userAction}
                  >
                    Switch Enterprise
                  </MenuItem>
                  <Divider />
                </div>
              )}
              <MenuItem onClick={() => userEdit(menuCloseActions)} className={classes.userAction}>
                User Settings
                <SettingsIcon />
              </MenuItem>
              <Divider />
              <MenuItem onClick={() => logout(menuCloseActions)} className={classes.userAction}>
                Logout
                <LogoutIcon />
              </MenuItem>
            </MenuList>
          </Paper>
        )}
      </PopupMenu>
    </div>
  );
}

export default UserMenu;
