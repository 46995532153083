import React from "react";
import { makeStyles } from "@material-ui/core";
import { multiLineCardValueStyle } from "./multiLineCardValueStyle";

export interface MultiLineCardValueProps {
  children: string | JSX.Element;
}

const useStyles = makeStyles(multiLineCardValueStyle, { name: "MultiLineCardValue" });

export function MultiLineCardValue(props: MultiLineCardValueProps) {
  const classes = useStyles({});

  return <span className={classes.root}>{props.children}</span>;
}
