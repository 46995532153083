import React from "react";
import { ObjectIdType } from "components/Utils/Object.d";
import { UnifiedDataRequestType, SORT_ASC } from "components/Utils/CRUDUtils.d";
import { PortletCard } from "components/Cards/PortletCard";
import { extractSamplePointsCurrentValues } from "../SamplePoint/SamplePointCommon";
import { DataSourceParamsType } from "components/Crud/DataProvider/DataProviderCommon.d";
import { filterBy } from "components/Utils/MiscUtils";
import {
  DataQueryDataType,
  DataQueryEventType,
  DataQueryOperationStatusType,
} from "components/Crud/DataProvider/DataQuery.d";
import { DataQuery } from "components/Crud/DataProvider/DataQuery";
import { CurrentValueCardBlock } from "components/Crud/Table/CellRenderers/AssetCardCells/CurrentValueCardBlock";
import { SamplePointCurrentValueType } from "../SamplePoint/SamplePoint.d";
import { SAMPLEPOINT_API_URL } from "../SamplePoint/SamplePoint.const";

export interface AssetCurrentValuesPortletProps {
  assetId: ObjectIdType;
}

export function AssetCurrentValuesPortlet(props: AssetCurrentValuesPortletProps) {
  const dataSource: DataSourceParamsType = {
    apiUrl: `${SAMPLEPOINT_API_URL}`,
  };

  const initialLoadRequest: UnifiedDataRequestType = {
    fieldList: ["name", "currentStatus"],
    filter: {
      baseFilters: [filterBy("assetId", "=", props.assetId)],
    },
    sortByColumn: "id",
    sortDirection: SORT_ASC,
  };

  return (
    <DataQuery
      dataSource={dataSource}
      initialQuery={initialLoadRequest}
      externalTriggerValues={[props.assetId]}
      render={(
        queryData: DataQueryDataType,
        queryParams: UnifiedDataRequestType,
        queryStatus: DataQueryOperationStatusType,
        dispatchQueryEvent: (event: DataQueryEventType) => void
      ) => {
        const currentValues = extractSamplePointsCurrentValues(queryData.data);

        return (
          <PortletCard title="Current Values">
            {currentValues.map((currentValueItem: SamplePointCurrentValueType, index: number) => {
              return (
                <CurrentValueCardBlock
                  key={index}
                  name={currentValueItem.name}
                  valueUnits={currentValueItem.valueUnits}
                  trend={currentValueItem.trend}
                />
              );
            })}
          </PortletCard>
        );
      }}
    />
  );
}
