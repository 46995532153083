import React from "react";
import { TrendBadge } from "components/Elements/Badge/TrendBadge";
import { RegularCardValue } from "components/Elements/Typography/RegularCardValue";
import { SubordinateCardValue } from "components/Elements/Typography/SubordinateCardValue";
import { isSpecified, isNonEmptyArray } from "components/Utils/MiscUtils";
import { SamplePointCurrentValueValueType } from "views/asset/SamplePoint/SamplePoint.d";

export interface CurrentValueCardBlockProps {
  name: string;
  valueUnits: Array<SamplePointCurrentValueValueType>;
  trend?: number;
}

export function CurrentValueCardBlock(props: CurrentValueCardBlockProps) {
  const valueUnitArray = isNonEmptyArray(props.valueUnits)
    ? props.valueUnits.map((valueUnitItem) => {
        return `${valueUnitItem.value} ${valueUnitItem.units}`;
      })
    : undefined;

  const valueUnitContent = isNonEmptyArray(valueUnitArray) ? valueUnitArray.join(" / ") : "-";

  // TODO: ColumnLayout is not needed here
  return (
    <div className="CurrentValueCardBlock">
      <div style={{ display: "flex", alignItems: "center" }}>
        {isSpecified(props.trend) ? <TrendBadge trend={props.trend} /> : null}
        <RegularCardValue trend={props.trend}>{`${valueUnitContent}`}</RegularCardValue>
      </div>
      <div>
        <SubordinateCardValue>{props.name}</SubordinateCardValue>
      </div>
    </div>
  );
}
