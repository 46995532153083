import React from "react";
import { MainPanelWithParamsProps } from "components/Dashboard/MainPanelWithParams.d";
import { useActiveUser } from "components/Utils/CustomHooks";
import { AssetSummaryScreenContent } from "./AssetSummaryContent";
import { DataCrud } from "components/Crud/DataProvider/DataCrud";
import {
  DataCrudDataType,
  DataCrudEventType,
  DataCrudOperationStatusType,
} from "components/Crud/DataProvider/DataCrud.d";
import { API_LIB } from "components/Utils/API_LIB";
import { DataSourceParamsType } from "components/Crud/DataProvider/DataProviderCommon.d";
import { isSpecified } from "components/Utils/MiscUtils";
import { hasAttributeByPath } from "components/Crud/EntityUtils";

export interface AssetSummaryScreenProps {
  entityId: number; // TODO: this parameter is available not only as props.match.params.entityId but directly
}

export function AssetSummaryScreen(props: MainPanelWithParamsProps<AssetSummaryScreenProps>) {
  const assetId = props.match.params.entityId;

  const searchParams = new URLSearchParams(props.location.search);
  const tabId = isSpecified(searchParams.get("initialTabId"))
    ? parseInt(searchParams.get("initialTabId"), 10)
    : undefined;

  const userData = useActiveUser();

  const dataSource: DataSourceParamsType = {
    apiUrl: API_LIB.ASSET,
    customUrlRequestFragment: `join=site`,
  };

  return (
    <DataCrud
      prefetchId={assetId}
      dataSource={dataSource}
      externalTriggerValues={[assetId]}
      render={(
        crudData: DataCrudDataType,
        crudRequest: DataCrudEventType,
        crudStatus: DataCrudOperationStatusType,
        dispatchCrudEvent: (event: DataCrudEventType) => void
      ) => {
        let siteData;
        const assetDataFromNetwork = crudData;

        if (isSpecified(assetDataFromNetwork.data) && hasAttributeByPath(assetDataFromNetwork.data, "site")) {
          siteData = {
            id: assetDataFromNetwork.data.site.id,
            name: assetDataFromNetwork.data.site.name,
          };
        }

        return (
          <AssetSummaryScreenContent
            assetId={assetId}
            userData={userData}
            siteData={siteData}
            initialTabId={tabId}
            currentEnterpriseId={props.currentEnterpriseId}
          />
        );
      }}
    />
  );
}
