import React from "react";
import { ObjectIdType } from "components/Utils/Object.d";
import { EventDispatchersType } from "components/Crud/Table/StatelessTable.d";
import { DataRequestType } from "components/Crud/DataProvider/DatasetManager.d";
import { Paper, makeStyles, useTheme } from "@material-ui/core";
import { conditionTypeDictionary, valueTypeDictionary } from "./TriggerDictionaries";
import { FlexSpaceFiller } from "layouts/FlexSpaceFiller";
import { ActionButtonHandlerComponent } from "components/Buttons/ActionButtonHandlerComponent";
import { OutlinedBadge } from "components/Elements/Badge/OutlinedBadge";
import { getAttributeByPath } from "components/Crud/EntityUtils";
import { isSpecified } from "components/Utils/MiscUtils";
import { priorityDictionary } from "components/Dictionaries/DictionariesCommon";
import { alertTriggerCardStyle } from "./alertTriggerCardStyle";
import clsx from "clsx";
import { CardSubTitle } from "components/Elements/Typography/CardSubTitle";
import { RegularCardValue } from "components/Elements/Typography/RegularCardValue";
import { MultiLineCardValue } from "components/Elements/Typography/MultiLineCardValue";
import { ConditionalRender } from "layouts/ConditionalRender";
import { ActionButtonConfig } from "components/Buttons/Buttons.d";
import { EventLevel } from "components/Dictionaries/Dictionaries.d";
import {
  getDictionaryLabelElementByCode,
  getDictionaryLabelByCode,
} from "components/Dictionaries/DictionariesUtils";

export interface TriggerCardProps {
  assetId: ObjectIdType; // TODO: we don't need assetId we can take it from the dataObject
  id: ObjectIdType;
  name: string;
  dataObject: Record<string, any>;
  actionsSet: Array<ActionButtonConfig>;
  errorMessage?: string;
  eventDispatchers?: EventDispatchersType;
  triggerEventDispatcher: (value: DataRequestType) => void;
}

/* let dataSource: DataSourceParamsType = {
  apiUrl: API_LIB.TRIGGER_CONDITION,
  // customUrlRequestFragment: `filter=triggerId::eq::${props.id}`
}; */

const useStyles = makeStyles(alertTriggerCardStyle, { name: "TriggerCard" });

export function TriggerCard(props: TriggerCardProps) {  
  const classes = useStyles({});
  const theme = useTheme();

  let triggerMessage = "";
  const unit = getAttributeByPath(props.dataObject.conditions[0], "sampleField.units.abbreviation");
  const value = getAttributeByPath(props.dataObject.conditions[0], "value");
  if (isSpecified(props.dataObject.message) && props.dataObject.message !== "") {
    triggerMessage = props.dataObject.message;
  } else if (isSpecified(props.dataObject.templateId)) {
    const templateMessage = getAttributeByPath(props.dataObject, "template.message");

    if (isSpecified(templateMessage) && templateMessage !== "") {
      triggerMessage = templateMessage;
    }
  }

  triggerMessage = `${triggerMessage}. Limit: ${value}${unit}`
  
  let eventLevelId = undefined as EventLevel;

  if (isSpecified(props.dataObject.eventLevel)) {
    eventLevelId = props.dataObject.eventLevel;
  } else {
    const templateEventLevelId = getAttributeByPath(props.dataObject, "template.eventLevel");

    if (isSpecified(templateEventLevelId)) {
      eventLevelId = templateEventLevelId;
    }
  }

  /*   let templateBasedValueElement = null;
  if (isSpecified(props.dataObject.templateId) && isSpecified(props.dataObject.template) && isNonEmptyArray(props.dataObject.template.conditions)) {
    templateBasedValueElement = (
      <div>
        <span className={classes.cardCellSubHeadingText}>Value: </span><RegularCardValue>{`${props.dataObject.template.conditions[0].value}`}</RegularCardValue>
      </div>
    )
  } */

  const triggerConditions = props.dataObject.conditions || [];

  const isTriggerEnabled = getAttributeByPath(props.dataObject, "isEnabled");

  return (
    <div style={{ padding: "8px 0" /* , width: '50vw' */ }}>
      <Paper className={clsx(classes.boxToHighlightOnHover)}>
        <div style={{ display: "flex", padding: "16px 0" }}>
          <div
            style={{
              flexBasis: 0,
              flexGrow: 1,
              paddingLeft: "24px",
              paddingRight: "24px",
            }}
          >
            <div style={{ display: "flex", alignItems: "center", height: "28px" }}>
              <div style={{ width: "100px" }}>
                {getDictionaryLabelElementByCode(priorityDictionary, eventLevelId)}
              </div>
              <ConditionalRender condition={!isTriggerEnabled}>
                <div style={{ boxSizing: "border-box", padding: "8px" }}>
                  <OutlinedBadge htmlColor={theme.palette.text.secondary}>DISABLED</OutlinedBadge>
                </div>
              </ConditionalRender>
              <FlexSpaceFiller />
              <div
                /* className="showOnHover"  */ style={{
                  display: "flex",
                  alignContent: "center",
                  color: "navy",
                }}
              >
                {props.actionsSet.map((actionConfigItem, index) => {
                  return (
                    <ActionButtonHandlerComponent
                      key={index}
                      buttonConfig={actionConfigItem}
                      id={props.id}
                      object={props.dataObject}
                      eventDispatchers={props.eventDispatchers}
                    />
                  );
                })}
              </div>
            </div>
            <div style={{ color: "red" }}>{props.errorMessage}</div>
            <div style={{ display: "flex", padding: "8px 0" }}>
              <div style={{ flexGrow: 1 }}>
                <MultiLineCardValue>{triggerMessage}</MultiLineCardValue>
              </div>
            </div>
          </div>
          <ConditionalRender condition={false /*TODO: may need to bring back for super admins*/}>
            <div
              style={{
                flexBasis: 0,
                flexGrow: 1,
                paddingLeft: "24px",
                borderLeftColor: "#e0e0e0",
                borderLeftStyle: "solid",
                borderLeftWidth: "0.25px",
              }}
            >
              <div style={{ display: "flex", alignItems: "center", height: "28px" }}>
                <CardSubTitle>CONFIGURATION DETAILS</CardSubTitle>
                <FlexSpaceFiller />
              </div>
              {triggerConditions.map((dataArrayItem: Record<string, any>, index: number) => {
                const sampleTypeName = getAttributeByPath(dataArrayItem, "samplePoint.sampleType.name");
                const sampleFieldName = getAttributeByPath(dataArrayItem, "sampleField.name");

                let sampleTypeString = undefined as string;
                if (sampleTypeName === sampleFieldName) {
                  sampleTypeString = sampleTypeName;
                } else {
                  sampleTypeString = `${sampleTypeName} [${sampleFieldName}]`;
                }

                return (
                  <div
                    key={index}
                    className={clsx(
                      classes.containerWithHiddenForHooverElements,
                      classes.boxToHighlightOnHover
                    )}
                    style={{
                      height: "36px",
                      boxSizing: "border-box",
                      display: "flex",
                      padding: "4px 0",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ padding: "0 4px" }}>
                      <RegularCardValue>{sampleTypeString}</RegularCardValue>
                    </div>
                    <div style={{ padding: "0 4px" }}>
                      <RegularCardValue>
                        {getDictionaryLabelByCode(valueTypeDictionary, dataArrayItem.valueType as number)}
                      </RegularCardValue>
                    </div>
                    <div style={{ padding: "0 4px" }}>
                      <RegularCardValue>
                        {getDictionaryLabelByCode(
                          conditionTypeDictionary,
                          dataArrayItem.conditionType as number
                        )}
                      </RegularCardValue>
                    </div>
                    <div style={{ padding: "0 4px" }}>
                      <RegularCardValue>{dataArrayItem.value as string | number}</RegularCardValue>
                    </div>
                    <div style={{ padding: "0 4px" }}>
                      <div>
                        {dataArrayItem.useForecast ? (
                          <OutlinedBadge htmlColor={"green"}>USE FORECAST</OutlinedBadge>
                        ) : (
                          <OutlinedBadge htmlColor={"grey"}>DO NOT USE FORECAST</OutlinedBadge>
                        )}
                      </div>
                    </div>
                    <div style={{ flexGrow: 1 }} />
                  </div>
                );
              })}
            </div>
          </ConditionalRender>
        </div>
      </Paper>
    </div>
  );
}

// Usefull code to illustrated
/*   const [triggerConditions, dispatchTriggerConditionsRequest] = useDatasetManager(dataSource); */

// const theme = useTheme();

/*   let initialRequest: UnifiedDataRequestType = useMemo(() => ({
    fieldList: [
      'id', 'conditionType', 'sampleFieldId', 'sampleField.name', 'samplePointId', 'samplePoint.sampleType.name', 
      'triggerConditionTemplateId', 'triggerId', 'useForecast', 'value', 'valueType', 'params'
    ],
    filter: {
      baseFilters: [
        filterBy('triggerId', '=', props.id)
      ]
    },
    isPaginationEnabled: true,
    rowsPerPage: 10,
    pageNum: 0,
    sortByColumn: 'id',
    sortDirection: SORT_ASC       
  }), [props.id]); */

/*   let triggerConditionEditFormFields = triggerConditionEditFormFieldsSpec(props.id, props.assetId, theme); */

// Bits copied from DataCrudEntityFormBinding
// let eventDispatchersForForm: EventDispatchersType = {};
// eventDispatchersForForm[CRUD_DISPATCHER] = dispatchTriggerConditionsRequest;

/*   const TriggerConditionEditForm = ((props: any) => (
    <FetchlessFormikEntityForm
      // tempOperationResult={operationResult}
      // triggerCloseOnSuccesOf={[CrudEventAction.CREATE, CrudEventAction.UPDATE, CrudEventAction.DELETE]}
      formData={props.dataObject}
      id={props.id}
      action={props.action}
      title={props.title}
      isDataLoading={false}//{crudStatus.state === DataCrudOperationStatus.EXECUTING}
      fieldConfigurations={triggerConditionEditFormFields}
      eventDispatchers={eventDispatchersForForm}
      onCloseAdditionalActions={props.onCloseAdditionalActions}
      showCloseButton={props.showCloseButton}
      userData={props.userData}
      currentEnterpriseId={props.currentEnterpriseId}
      layoutComponent={TriggerConditionFormLayout}
      conditionalFieldConfiguration={props.conditionalFieldConfiguration}
      formDataPreSubmitProcessor={props.formDataPreSubmitProcessor}
      doNotPreFetchData={true}//{props.doNotPreFetchData}
      warning={props.warning}
    />
  )); */

// let createTriggerConditionButtonConfig = generateCreateButtonConfig(
//   ENTITY_LIB.TRIGGER_CONDITION,
//   'Add',
//   TriggerConditionEditForm,
//   [],
//   {
//     title: 'Add Trigger Condition',
//     // warning: warningValue
//   }
// );

/*   let createTriggerConditionButtonConfig = {
    actionCode: CREATE_ACTION,
    actionObject: ENTITY_LIB.TRIGGER_CONDITION,
    name: 'add',
    labelElement: <AddIcon fontSize="small"/>,
    onClick: {
      type: 'dialog',
      dialogId: TriggerConditionEditForm,
      onCloseCallbacks: [] as Array<() => void>,
      extraPropsObject: {
        title: 'Add Trigger Condition',
        // warning: warningValue
      },      
    } as OnClickDialog
  };
   */
/*   let editTriggerConditionButtonConfig = {
    actionCode: EDIT_ACTION,
    actionObject: ENTITY_LIB.TRIGGER_CONDITION,
    name: 'edit',
    labelElement: <SettingsIcon fontSize="small"/>,
    onClick: {
      type: 'dialog',
      dialogId: TriggerConditionEditForm,
      onCloseCallbacks: [] as Array<() => void>,
      extraPropsObject: {
        title: 'Trigger Condition Edit',
        // warning: warningValue,
      }      
    } as OnClickDialog,
    doNotGroup: true
  };   */

/*   let deleteTriggerConditionButtonConfig = {
    actionObject: ENTITY_LIB.TRIGGER_CONDITION,
    actionCode: DELETE_ACTION,
    name: 'delete',
    labelElement: <ActionDelete fontSize="small"/>,
    onClick: {
      type: 'dispatchEvent',
      dispatcherName: CRUD_DISPATCHER,
      action: CrudEventAction.DELETE
  
    } as OnClickDispatchEvent,
    confirm: { message: 'Are you sure you want to delete this?', dialogButton: 'Delete'},
  }; */

/*   let operationResult = triggerConditions.lastOperationResult;
  usePopupOperationController(operationResult);

  // INITIAL LOAD ON MOUNT
  useEffect(() => {
    dispatchTriggerConditionsRequest({
      action: QueryEventAction.REQUEST_DATA, 
      payload: initialRequest,
      originator: 'Initial Load on Mount'
    })
  }, [dispatchTriggerConditionsRequest, initialRequest]); */

/*   let templateNameElement = null;
  if (props.dataObject.templateId) {
    templateNameElement = (
      <div>
        <span className={classes.cardCellSubHeadingText}>Based on Template:</span><RegularCardValue>{` ${props.dataObject.template.name}`}</RegularCardValue>
      </div>
    );
  } */

// Rendering:

/*           <Button
            onClick={() => {
              props.triggerEventDispatcher({
                action: CrudEventAction.UPDATE,
                payload: {
                  ...props.dataObject,
                  isEnabled: !props.dataObject.isEnabled,
                },
                originator: 'TriggerCard - is enabled check box'
              });
            }}
          >
            <div className={classes.checkBox}>{props.dataObject.isEnabled ? <CheckBoxIcon/> : <CheckBoxOutlineBlankIcon/>}</div>
          </Button>  */

/* <div style={{display: 'none'}}>
      <IconButton 
        onClick={() => dispatchTriggerConditionsRequest({
          action: QueryEventAction.REQUEST_DATA, 
          payload: initialRequest,
          originator: 'Refresh Trigger Conditions'
        })}  
      >
        <RefreshIcon/>
      </IconButton>  
    </div>       
    <div style={{display: 'none'}}>
      <ActionButtonHandlerComponent
        buttonConfig={createTriggerConditionButtonConfig}
        id={null}
        object={null}
        eventDispatchers={props.eventDispatchers}
      />  
    </div> */

// <div /* className="showOnHover" */ style={{display: 'none', padding: '0 4px'}}>
// {/*                       <ActionButtonHandlerComponent
//     buttonConfig={editTriggerConditionButtonConfig}
//     id={dataArrayItem.id}
//     object={dataArrayItem}
//     // eventDispatchers={eventDispatchersForForm}
//   />
//   <ActionButtonHandlerComponent
//     buttonConfig={deleteTriggerConditionButtonConfig}
//     id={dataArrayItem.id} // TODO: this id is ignored for some reason
//     object={dataArrayItem}//{dataArrayItem.dataObject}
//     eventDispatchers={eventDispatchersForForm}
//   />  */}
// </div>
