import React from "react";
import { getAttributeByPath } from "components/Crud/EntityUtils";
import { isFormSpecified, isNonEmptyArray, isSpecified } from "components/Utils/MiscUtils";
import { ACKNOWLEDGE_ALERT_ACTION, ACTION_ALERT_ACTION } from "./Alert.const";
import { BasicActionSetRendererProps } from "components/Crud/Table/CellRenderers/BasicActionSetRenderer";
import { ConditionalRender } from "layouts/ConditionalRender";
import { TableActionButtonFace } from "components/Buttons/TableActionButtonFace";
import { ActionButtonHandlerComponent } from "components/Buttons/ActionButtonHandlerComponent";
import { useActiveUser } from "components/Utils/CustomHooks";
import { getDictionaryLabelByCode } from "components/Dictionaries/DictionariesUtils";
import { AlertAcknowledgementStatus } from "./Alert.d";
import { alertNotificationDictionary } from "./Alert.dict";

export interface AlertActionsBlockProps {
  obj: Record<string, any>;
  fieldName?: string;
  actionProps?: BasicActionSetRendererProps;
}

export function AlertActionsBlock(props: AlertActionsBlockProps) {
  const acknowledgementStatusId = getAttributeByPath(props.obj, "notifications[0].notificationStatus");
  let acknowledgementText: string;

  if (isFormSpecified(acknowledgementStatusId)) {
    acknowledgementText = getDictionaryLabelByCode(
      alertNotificationDictionary,
      acknowledgementStatusId
    ).toUpperCase();
  }
  const acknowledgeActionProps = props.actionProps.actionSet.filter(
    (item) => item.actionCode === ACKNOWLEDGE_ALERT_ACTION
  );

  const actionedData = getAttributeByPath(props.obj, "eventActions");
  const user = useActiveUser();
  const alreadyActionByUser =
    (actionedData || []).findIndex((actionItem: Record<string, any>) => actionItem.userId === user.userId) >=
    0;

  const actionActionProps = props.actionProps.actionSet.filter(
    (item) => item.actionCode === ACTION_ALERT_ACTION
  );

  return (
    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
      <div>
        <ConditionalRender
          condition={alreadyActionByUser === true}
          onTrue={
            <div style={{ padding: "4px 5px" }}>
              <div style={{ width: "140px", backgroundColor: "#EEEEEE" }}>
                <TableActionButtonFace>
                  <span
                    style={{
                      color: "#546E7A",
                      fontWeight: 500,
                      minWidth: "100px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {"Actioned"}
                  </span>
                </TableActionButtonFace>
              </div>
            </div>
          }
          onFalse={
            isNonEmptyArray(actionActionProps) && (
              <ActionButtonHandlerComponent
                buttonConfig={actionActionProps[0]}
                id={props.obj.id}
                object={props.obj}
                eventDispatchers={props.actionProps.eventDispatchers}
              />
            )
          }
        />
      </div>
      <div>
        <ConditionalRender
          condition={
            isSpecified(acknowledgementStatusId) &&
            acknowledgementStatusId === AlertAcknowledgementStatus.ACKNOWLEDGED
          }
          onTrue={
            <div style={{ padding: "4px 5px" }}>
              <div style={{ width: "140px", backgroundColor: "#EEEEEE" }}>
                <TableActionButtonFace>
                  <span
                    style={{
                      color: "#546E7A",
                      fontWeight: 500,
                      minWidth: "100px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {acknowledgementText}
                  </span>
                </TableActionButtonFace>
              </div>
            </div>
          }
          onFalse={
            isSpecified(acknowledgementStatusId) &&
            isNonEmptyArray(acknowledgeActionProps) && (
              <ActionButtonHandlerComponent
                buttonConfig={acknowledgeActionProps[0]}
                id={props.obj.id}
                object={props.obj}
                eventDispatchers={props.actionProps.eventDispatchers}
              />
            )
          }
        />
      </div>
    </div>
  );
}
