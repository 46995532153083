import React, { ChangeEvent } from "react";
import { FormControlLabel, useTheme } from "@material-ui/core";
import { ColouredCheckBox } from "./ColouredCheckBox";
import { CheckboxConfiguration, FieldConfiguration } from "./FieldConfiguration.d";
import { CustomThemeType } from "assets/jss/muiTheme";

export interface FormCheckBoxProps {
  value: boolean;
  fieldConfig: FieldConfiguration;
  valueWasModified?: boolean;
  disabled?: boolean; // TODO: duplicated within FieldConfiguration
  onChange: (event: any) => void;
}

export function FormCheckBox(props: FormCheckBoxProps) {
  const theme = useTheme() as CustomThemeType;

  return (
    <div
      style={{
        paddingLeft: "14px",
        borderRadius: "4px",
        backgroundColor: props.valueWasModified ? theme.customColors.modifiedField : undefined,
      }}
    >
      <FormControlLabel
        control={
          <ColouredCheckBox
            customcolor={(props.fieldConfig.fieldParams as CheckboxConfiguration).color}
            checked={props.value}
            onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
              props.onChange({ target: { name: props.fieldConfig.fieldName, value: checked } });

              // target: {name: props.id, value: dateToSend.toDate()/* .getTime()*/}
            }}
            value={props.fieldConfig.fieldName}
            disabled={props.disabled}
          />
        }
        label={props.fieldConfig.label}
      />
    </div>
  );
}
