import { StyleRulesCallback, Theme } from "@material-ui/core";

export const subordinateCardValueStyle: StyleRulesCallback<Theme, {}> = (theme) => ({
  root: {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "18px",
    color: theme.palette.text.secondary,
    whiteSpace: "nowrap",
  },
});
