import React from "react";
import { DataSourceParamsType } from "components/Crud/DataProvider/DataProviderCommon.d";
import { API_LIB } from "components/Utils/API_LIB";
import { AssetGeoMap } from "./AssetGeoMap";
import { LinearProgressOnCondition } from "components/Elements/LinearProgressOnCondition";
import { IconButton, makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { FlexSpaceFiller } from "layouts/FlexSpaceFiller";
import { ScreenSectionTitle } from "components/Elements/Typography/ScreenSectionTitle";
import { geoMapPopupStyle } from "./geoMapPopupStyle";
import { UnifiedDataRequestType, SORT_ASC } from "components/Utils/CRUDUtils.d";
import { filterBy, translateValue } from "components/Utils/MiscUtils";
import { DataQuery } from "components/Crud/DataProvider/DataQuery";
import {
  DataQueryDataType,
  DataQueryOperationStatusType,
  DataQueryEventType,
} from "components/Crud/DataProvider/DataQuery.d";
import clsx from "clsx";
import { useMediaQueryMatchDown } from "components/Utils/LayoutUtils";
import { ALL_ENTERPRISES_CODE } from "views/Enterprise/Enterprise.const";

const useStyles = makeStyles(geoMapPopupStyle, { name: "GeoMapPopup" });

export function GeoMapPopup(props: any) {
  const classes = useStyles({});
  const isSmallScreen = useMediaQueryMatchDown("sm");

  const queryDataSource: DataSourceParamsType = {
    apiUrl: API_LIB.ASSET_INVENTORY_LIST,
    // customUrlRequestFragment: `groupId=${props.assetGroupId}`
  };

  // TODO: remove unnecessary fields
  const initialLoadRequest: UnifiedDataRequestType = {
    fieldList: [
      "name",
      "assetTypeId",
      "site.name",
      "site.enterprise.name",
      "samplePoints",
      "samplePoints.currentStatus",
      "samplePoints.name",
      "location",
    ],
    filter: {
      baseFilters: [
        filterBy("id", "=", props.id),
        filterBy(
          "site.enterpriseId",
          "=",
          translateValue(props.currentEnterpriseId, [[ALL_ENTERPRISES_CODE, undefined]])
        ),
      ],
    },
    isPaginationEnabled: true,
    rowsPerPage: 10,
    pageNum: 0,
    sortByColumn: "id",
    sortDirection: SORT_ASC,
  };

  const handleClose = () => {
    if (props.onCloseAdditionalActions) {
      props.onCloseAdditionalActions(false);
    }
  };

  return (
    <DataQuery
      dataSource={queryDataSource}
      initialQuery={initialLoadRequest}
      externalTriggerValues={[props.assetId]}
      render={(
        queryData: DataQueryDataType,
        queryParams: UnifiedDataRequestType,
        queryStatus: DataQueryOperationStatusType,
        dispatchQueryEvent: (event: DataQueryEventType) => void
      ) => {
        return (
          <div
            className={clsx(classes.root, isSmallScreen ? classes.rootSmallScreen : classes.rootLargeScreen)}
          >
            <div className={classes.topBar}>
              <div className={classes.ellipsisOnOverflow}>
                <ScreenSectionTitle>
                  {queryData.data ? queryData.data[0].name : "Loading..."}
                </ScreenSectionTitle>
              </div>
              <FlexSpaceFiller />
              <div>
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </div>
            </div>
            <div className={classes.mapContent}>
              <LinearProgressOnCondition condition={queryData.data === undefined} />
              {queryData.data !== undefined ? (
                <AssetGeoMap inputData={queryData.data} activeEntityIds={[parseInt(props.id, 10)]} />
              ) : null}
            </div>
          </div>
        );
      }}
    />
  );
}

/*
  // TODO: delete post demo - 04/05/2020
  const dataSource: DataSourceParamsType = {
    apiUrl: API_LIB.ASSET,
    entityName: ENTITY_LIB.ASSET_INVENTORY_ITEM,
    customUrlRequestFragment: 'join=samplePoints::currentStatus,name'
  };

  return (
    <DataCrud
      prefetchId={props.id}
      dataSource={dataSource}
      render={
        ( crudData: DataCrudDataType,
          crudRequest: DataCrudEventType,
          crudStatus: DataCrudOperationStatusType,
          dispatchCrudEvent: (event: DataCrudEventType) => void,
          activeTransactionId: number
        ) => {    

        return (
          <div className={classes.root}>
            <div className={classes.topBar}>
              <div><ScreenSectionTitle>{crudData.data ? crudData.data.name : 'Loading...'}</ScreenSectionTitle></div>
              <FlexSpaceFiller/>
              <div><IconButton onClick={handleClose}><CloseIcon/></IconButton></div>
            </div>            
            <div className={classes.mapContent}>
              <LinearProgressOnCondition condition={crudData.data === undefined}/>            
              {crudData.data !== undefined ? <AssetGeoMap inputData={[crudData.data]} activeEntityIds={[parseInt(props.id, 10)]}/> : null}
            </div>
          </div>
        );
      }}
    />
  );

*/
