// tslint:disable:max-line-length

import { StyleRulesCallback, Theme } from "@material-ui/core/styles";
import { greenColor, roseColor } from "assets/jss/material-dashboard-react";

const buttonStyle: StyleRulesCallback<Theme, {}> = (theme) => ({
  root: {
    color: theme.palette.primary.main,
    variant: "inline",
    textTransform: "none",
    marginRight: "2px",
    marginLeft: "2px",
    // padding: 0,
    cursor: "pointer",
    // '&:hover': {
    //   backgroundColor: greyColor,
    //   boxShadow:
    //     '0 14px 26px -12px rgba(153, 153, 153, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(153, 153, 153, 0.2)',
    // },
  },
  rootMobile: {
    padding: "6px 10px",
  },
  blue: {
    color: "#FFFFFF",
    backgroundColor: theme.palette.primary.main,
    boxShadow:
      "0 2px 2px 0 rgba(156, 39, 176, 0.14), 0 3px 1px -2px rgba(156, 39, 176, 0.2), 0 1px 5px 0 rgba(156, 39, 176, 0.12)",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      boxShadow:
        "0 14px 26px -12px rgba(156, 39, 176, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(156, 39, 176, 0.2)",
    },
  },
  green: {
    backgroundColor: greenColor,
    boxShadow:
      "0 2px 2px 0 rgba(156, 39, 176, 0.14), 0 3px 1px -2px rgba(156, 39, 176, 0.2), 0 1px 5px 0 rgba(156, 39, 176, 0.12)",
    "&:hover": {
      backgroundColor: greenColor,
      boxShadow:
        "0 14px 26px -12px rgba(156, 39, 176, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(156, 39, 176, 0.2)",
    },
  },
  rose: {
    backgroundColor: roseColor,
    boxShadow:
      "0 2px 2px 0 rgba(233, 30, 99, 0.14), 0 3px 1px -2px rgba(233, 30, 99, 0.2), 0 1px 5px 0 rgba(233, 30, 99, 0.12)",
    "&:hover": {
      backgroundColor: roseColor,
      boxShadow:
        "0 14px 26px -12px rgba(233, 30, 99, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(233, 30, 99, 0.2)",
    },
  },
  round: {
    borderRadius: "30px",
  },
  disabled: {
    opacity: 0.65,
    pointerEvents: "none",
  },
});

export default buttonStyle;
