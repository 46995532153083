import { StyleRulesCallback, Theme } from "@material-ui/core/styles";

export const trendBadgeStyle: StyleRulesCallback<Theme, {}> = (theme) => ({
  root: {
    // boxSizing: 'border-box',
    // height: '35px',
    // width: '35px',
    // border: '1px solid #E0E0E0',
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});
