import React from "react";
import { makeStyles } from "@material-ui/core";
import { flexSpaceFillerStyle } from "./flexSpaceFillerStyle";

const useStyle = makeStyles(flexSpaceFillerStyle, { name: "FlexSpaceFiller" });

export function FlexSpaceFiller() {
  const classes = useStyle({});

  return <div className={classes.root} />;
}
