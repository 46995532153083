import { useMediaQuery, Theme } from "@material-ui/core";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";

export const useMediaQueryMatchDown = (breakPointValue: Breakpoint) => {
  //TODO: use context as we can get it once and for all

  const matchResult = useMediaQuery((theme: Theme) => theme.breakpoints.down(breakPointValue), {
    // Without it it will not be showing correctly, due to pre-rendering calculation
    defaultMatches: null,
    noSsr: true,
  });

  return matchResult;
};

export const px = (inputValue: number) => {
  if (inputValue || inputValue === 0) {
    return `${inputValue}px`;
  } else {
    return "";
  }
};
