import React, { useState, useEffect } from "react";
import { Dialog, IconButton, Button } from "@material-ui/core";
import { ColumnLayout } from "layouts/ColumnLayout";
import { FlexSpaceFiller } from "layouts/FlexSpaceFiller";
import CloseIcon from "@material-ui/icons/Close";
import { MaterialUIPickers } from "components/Crud/Form/Fields/MaterialUIPickers";
import { ScreenSectionTitle } from "components/Elements/Typography/ScreenSectionTitle";

import { INPUT_VARIANT } from "components/Crud/Form/Fields/FieldConfiguration.d";
import produce from "immer";
import { datePlusInterval, dateMinusInterval, endOfDayDate } from "components/Utils/DateUtils";
import { EventDataType } from "components/Crud/DataProvider/DataProviderCommon.d";
import { useMediaQueryMatchDown } from "components/Utils/LayoutUtils";

import { isSpecified } from "components/Utils/MiscUtils";
import { GraphDataRequestParametersType, IntervalOptions } from "./Graph.d";

export enum GraphDateRangePickerEventAction {
  CANCEL = "CANCEL",
  APPLY_INTERVAL = "APPLY_INTERVAL",
}

export type GraphDateRangePickerEventType = EventDataType<
  GraphDateRangePickerEventAction,
  GraphDataRequestParametersType
>;

export interface GraphDateRangePickerDialogProps {
  open: boolean;
  values: GraphDataRequestParametersType;
  eventDispatcher: (event: GraphDateRangePickerEventType) => void;
  timeZone: string;
}

export function GraphDateRangePickerDialog(props: GraphDateRangePickerDialogProps) {
  const [localValues, setLocalValues] = useState(props.values);

  useEffect(() => {
    if (props.open) {
      setLocalValues(props.values);
    }
  }, [props.open, props.values]);

  const handleCancel = () => {
    props.eventDispatcher({
      action: GraphDateRangePickerEventAction.CANCEL,
      payload: undefined,
      originator: "GraphDateRangePickerDialog::handleCancel",
    });
  };

  const handleSubmit = () => {
    props.eventDispatcher({
      action: GraphDateRangePickerEventAction.APPLY_INTERVAL,
      payload: localValues,
      originator: "GraphDateRangePickerDialog::handleSubmit",
    });
    props.eventDispatcher({
      action: GraphDateRangePickerEventAction.CANCEL,
      payload: undefined,
      originator: "GraphDateRangePickerDialog::handleSubmit",
    });
  };

  // TODO: typefy signature of the handler function
  const handleChangeStartDate = (newValue: { target: { name: string; value: Date } } | null) => {
    const parameterValue = newValue.target.value;

    if (!rangeValidationError(parameterValue, localValues.requestedRange.displayedRangeEndDate)) {
      const newIntervalConfig = produce(localValues, (draftValues) => {
        const displayedStartDate = parameterValue;
        const displayedEndDate = draftValues.requestedRange.displayedRangeEndDate;

        draftValues.requestedRange = {
          displayedRangeStartDate: displayedStartDate,
          displayedRangeEndDate: displayedEndDate,
          requestedRangeStartDate: dateMinusInterval(
            displayedStartDate,
            IntervalOptions.ONE_DAY,
            props.timeZone
          ),
          requestedRangeEndDate: datePlusInterval(displayedEndDate, IntervalOptions.ONE_DAY, props.timeZone),
        };
      });

      setLocalValues(newIntervalConfig);
    }
  };

  // TODO: typefy signature of the handler function
  const handleChangeEndDate = (newValue: { target: { name: string; value: Date } } | null) => {
    const parameterValue = newValue.target.value;

    const endOfDay = endOfDayDate(parameterValue, props.timeZone);

    if (!rangeValidationError(localValues.requestedRange.displayedRangeStartDate, parameterValue)) {
      const newIntervalConfig = produce(localValues, (draftValues) => {
        const displayedStartDate = draftValues.requestedRange.displayedRangeStartDate;
        const displayedEndDate = endOfDay;

        draftValues.requestedRange = {
          displayedRangeStartDate: displayedStartDate,
          displayedRangeEndDate: displayedEndDate,
          requestedRangeStartDate: dateMinusInterval(
            displayedStartDate,
            IntervalOptions.ONE_DAY,
            props.timeZone
          ),
          requestedRangeEndDate: datePlusInterval(displayedEndDate, IntervalOptions.ONE_DAY, props.timeZone),
        };
      });

      setLocalValues(newIntervalConfig);
    }
  };

  const rangeValidationError = (startDate: Date, endDate: Date) => {
    let result = null;

    if (startDate > endDate) {
      result = "End date should be less than start date";
    }

    return result;
  };

  // // TODO: find a way to pass default options to DatePicker
  // let defaultDatePickerOptions: Partial<DatePickerProps> = {
  //   errorText: dateRangeValidationError,
  //   disabled: disableCustomIntervalPicker,
  //   pickerType: 'date',
  //   behavior: 'inline',
  //   variant: INPUT_VARIANT,
  //   format: 'yyyy/MM/dd',
  //   disableFuture: true
  // };

  const dateRangeValidationError = isSpecified(localValues.requestedRange)
    ? rangeValidationError(
        localValues.requestedRange.displayedRangeStartDate,
        localValues.requestedRange.displayedRangeEndDate
      )
    : undefined;

  const rangeToHighlight = {
    start: isSpecified(localValues.requestedRange)
      ? localValues.requestedRange.displayedRangeStartDate
      : undefined,
    end: isSpecified(localValues.requestedRange)
      ? localValues.requestedRange.displayedRangeEndDate
      : undefined,
  };

  const isSmallScreen = useMediaQueryMatchDown("sm");

  return (
    <Dialog open={props.open} maxWidth="lg">
      <div>
        <div style={{ boxShadow: "inset 0 -1px 0 0 #EEEEEE", display: "flex", alignItems: "center" }}>
          <div style={{ paddingLeft: "16px" }}>
            <ScreenSectionTitle>Custom Graph Range</ScreenSectionTitle>
          </div>
          <FlexSpaceFiller />
          <IconButton onClick={handleCancel}>
            <CloseIcon />
          </IconButton>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: isSmallScreen ? "column" : "row",
            padding: isSmallScreen ? "0 16px" : undefined,
          }}
        >
          <div>
            <MaterialUIPickers
              helperText={dateRangeValidationError}
              disabled={false}
              id="start_date"
              label="Start Date"
              pickerType="date"
              behavior="inline"
              variant={INPUT_VARIANT}
              disableFuture={true}
              format="YYYY/MM/DD"
              value={
                isSpecified(localValues.requestedRange)
                  ? localValues.requestedRange.displayedRangeStartDate
                  : null
              }
              onChange={handleChangeStartDate}
              dateRangeToHighlight={rangeToHighlight}
              datePickerVariant={isSmallScreen ? "inline" : "static"}
              timeZone={props.timeZone}
            />
          </div>
          <div>
            <MaterialUIPickers
              helperText={dateRangeValidationError}
              disabled={false}
              id="end_date"
              label="End Date"
              pickerType="date"
              behavior="inline"
              variant={INPUT_VARIANT}
              format="YYYY/MM/DD"
              disableFuture={true}
              value={
                isSpecified(localValues.requestedRange)
                  ? localValues.requestedRange.displayedRangeEndDate
                  : null
              }
              onChange={handleChangeEndDate}
              dateRangeToHighlight={rangeToHighlight}
              datePickerVariant={isSmallScreen ? "inline" : "static"}
              timeZone={props.timeZone}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            boxShadow: "inset 0 1px 0 0 #EEEEEE",
            paddingTop: "16px",
            paddingBottom: "16px",
            paddingRight: "16px",
            paddingLeft: "16px",
          }}
        >
          <ColumnLayout spacingName="buttonSpacing">
            <div>
              <Button onClick={handleCancel}>CANCEL</Button>
            </div>
            <div>
              <Button variant="contained" color="primary" onClick={handleSubmit}>
                Apply Interval
              </Button>
            </div>
          </ColumnLayout>
        </div>
      </div>
    </Dialog>
  );
}
