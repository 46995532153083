import React from "react";
import { useMediaQueryMatchDown } from "components/Utils/LayoutUtils";
import { TableCellRendererProps } from "components/Crud/Table/CellRenderers/TableCellRenderer.d";
import { getAttributeByPath } from "components/Crud/EntityUtils";
import { priorityDictionary } from "components/Dictionaries/DictionariesCommon";
import { ColorDisc } from "assets/icons/ColorDisc";
import { ConditionalRender } from "layouts/ConditionalRender";
import { FlexSpaceFiller } from "layouts/FlexSpaceFiller";
import { AlertLevelMessageBlock } from "./AlertLevelMessageBlock";
import { AlertActionsBlock } from "./AlertActionsBlock";
import { useActiveUser } from "components/Utils/CustomHooks";
import { getDictionaryItemByCode } from "components/Dictionaries/DictionariesUtils";

export function SingleRowNotificationCard(props: TableCellRendererProps) {  
  const isSmallScreen = useMediaQueryMatchDown("sm");

  const eventLevelId = getAttributeByPath(props.obj, "level");
  const eventLevelItem = getDictionaryItemByCode(priorityDictionary, eventLevelId) as Record<string, any>;
  let message = getAttributeByPath(props.obj, "message");
  const triggerValue = getAttributeByPath(props.obj, "triggerValue");

  if(triggerValue > 0) {
    message = `${message}. Limit: ${triggerValue}cm`
  }

  const alertDate = getAttributeByPath(props.obj, "date");

  const user = useActiveUser();
  const eventActions = getAttributeByPath(props.obj, "eventActions") || [];
  const otherUsersWhoActioned = eventActions.filter((item: Record<string, any>) => item.userId !== user.userId);

  const namesOfOtherUsersWhoActioned = otherUsersWhoActioned.map(
    (item: Record<string, any>) => `${item.user.firstName} ${(item.user.lastName || "").slice(0, 1)}`
  );

  return (
    <div style={{ display: "flex", height: "100%" }}>
      <div
        style={{
          paddingLeft: isSmallScreen === true ? "16px" : "24px",
          paddingTop: isSmallScreen === true ? "16px" : "24px",
        }}
      >
        <div style={{ height: "24px", display: "flex", alignItems: "center" }}>
          <ColorDisc size={isSmallScreen === true ? 12 : 16} htmlBackgroundColor={eventLevelItem.colorCode} />
        </div>
      </div>
      <div
        style={{
          boxSizing: "border-box",
          width: "100%",
          display: "flex",
          flexDirection: isSmallScreen === true ? "column" : "row",
          padding: "16px",
        }}
      >
        <AlertLevelMessageBlock
          message={message}
          eventLevelId={eventLevelId}
          eventDate={alertDate}
          actionedBy={namesOfOtherUsersWhoActioned}
        />
        <ConditionalRender condition={!isSmallScreen}>
          <FlexSpaceFiller />
        </ConditionalRender>
        <AlertActionsBlock obj={props.obj} fieldName={props.fieldName} actionProps={props.actionProps} />
      </div>
    </div>
  );
}
