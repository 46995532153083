import React from "react";
import { TableCellRendererProps } from "./TableCellRenderer.d";
import { ActionButtonHandlerComponent } from "components/Buttons/ActionButtonHandlerComponent";
import { EventDispatchersType } from "../StatelessTable.d";
import PopupMenu from "components/PopupMenu/PopupMenu";
import { Button, makeStyles } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import { isNonEmptyArray } from "components/Utils/MiscUtils";
import { MoreActionsButtonFace } from "components/Buttons/MoreActionsButtonFace";
import { basicActionSetRendererStyle } from "./basicActionSetRendererStyle";
import { useActiveUser, useActiveEnterprise } from "components/Utils/CustomHooks";
import { isActionAllowed } from "access-control/RBACEngine";
import { ActionButtonConfig } from "components/Buttons/Buttons.d";

export interface BasicActionSetRendererProps {
  actionSet: Array<ActionButtonConfig>;
  eventDispatchers?: EventDispatchersType;
}

const useStyles = makeStyles(basicActionSetRendererStyle, { name: "BasicActionSetRenderer" });

export function BasicActionSetRenderer(props: TableCellRendererProps /*  & BasicActionSetRendererProps */) {
  const classes = useStyles({});

  const activeUser = useActiveUser();
  const activeEnterprise = useActiveEnterprise();

  const buttonsToDisplay = props.actionProps.actionSet.filter((actionDescriptor: ActionButtonConfig) => {
    let displayFlag: boolean;

    if (actionDescriptor.displayCondition) {
      displayFlag = actionDescriptor.displayCondition(props.obj);
    } else {
      displayFlag = true;
    }

    // Note, unfortunately, for group buttons we cannot reuse the fact that BasicActionSet renderer will not render itself if the action is not allowed.
    // This is because we cannot render the component before disableMenuInput is known.
    if (displayFlag) {
      displayFlag = isActionAllowed(
        actionDescriptor.actionCode,
        activeEnterprise.id,
        activeUser,
        [],
        actionDescriptor.actionObject
      );
    }

    return displayFlag;
  });

  let buttonGroupActionConfigs: Array<ActionButtonConfig> = buttonsToDisplay.filter(
    (buttonConfig: ActionButtonConfig) => !buttonConfig.doNotGroup
  );
  let standaloneButtonsActionConfigs: Array<ActionButtonConfig>;

  // Single Button is never grouped

  if (buttonGroupActionConfigs.length > 1) {
    standaloneButtonsActionConfigs = buttonsToDisplay.filter(
      (buttonConfig: ActionButtonConfig) => buttonConfig.doNotGroup
    );
  } else {
    standaloneButtonsActionConfigs = buttonsToDisplay;
    buttonGroupActionConfigs = null;
  }

  let buttonGroupContent: JSX.Element;
  let standaloneButtonsContent: JSX.Element;

  if (isNonEmptyArray(buttonGroupActionConfigs)) {
    buttonGroupContent = (
      <PopupMenu
        clickableVisibleItem={(handleClick: (event: React.MouseEvent<Element, MouseEvent>) => void) => (
          <Button onClick={handleClick}>
            <MoreActionsButtonFace />
          </Button>
        )}
      >
        {(menuCloseActions: () => void, disableMenuInput: () => void) => {
          return (
            <Paper>
              {buttonGroupActionConfigs.map((actionDescriptor: ActionButtonConfig, index: number) => {
                return (
                  <div key={actionDescriptor.name} className={classes.grouppedItem}>
                    <ActionButtonHandlerComponent
                      buttonConfig={actionDescriptor}
                      id={props.obj.id}
                      object={props.obj}
                      eventDispatchers={props.actionProps.eventDispatchers}
                      preActionCallback={disableMenuInput}
                      postActionCallback={menuCloseActions}
                    />
                  </div>
                );
              })}
            </Paper>
          );
        }}
      </PopupMenu>
    );
  }

  if (isNonEmptyArray(standaloneButtonsActionConfigs)) {
    standaloneButtonsContent = (
      <React.Fragment>
        {standaloneButtonsActionConfigs.map((actionDescriptor: ActionButtonConfig, index: number) => {
          return (
            <ActionButtonHandlerComponent
              key={actionDescriptor.name}
              buttonConfig={actionDescriptor}
              id={props.obj.id}
              object={props.obj}
              eventDispatchers={props.actionProps.eventDispatchers}
            />
          );
        })}
      </React.Fragment>
    );
  }

  return (
    <div style={{ display: "flex", alignItems: "center", height: "100%", justifyContent: "flex-end" }}>
      {buttonGroupContent}
      {standaloneButtonsContent}
    </div>
  );
}
