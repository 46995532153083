import React from "react";
import { FieldConfiguration } from "components/Crud/Form/Fields/FieldConfiguration.d";
import { SAMPLEPOINT_SENSOR_PAIRING_API } from "./SamplePointSensorPairing.const";
import { SENSOR_API_URL } from "views/hardware/Sensor/Sensor.const";
import { SAMPLEPOINT_API_URL } from "../SamplePoint.const";
import { DataCrudEntityFormBinding } from "components/Crud/Form/DataCrudEntityFormBinding";
import { PairingType } from "./SamplePointSensorPairing.d";
import { PAIRING_OPTIONS } from "./SamplePointSensorPairing.dict";
import { EntityFormParams } from "components/Crud/Form/EntityForm.d";

export interface SamplePointSensorPairingEditFormProps {
  samplePointId: number;
  sampleTypeId: number;
  siteId?: number;
}

export function SamplePointSensorPairingEditForm(
  props: EntityFormParams & SamplePointSensorPairingEditFormProps
) {
  const formFieldsConfiguration: FieldConfiguration[] = [
    {
      fieldName: "samplePointId",
      label: "Sensor",
      defaultValue: props.samplePointId,
      disabled: true,
      validation: { required: true },
      type: "select-async",
      fieldParams: {
        optionDefinitionEndpoint: `${SAMPLEPOINT_API_URL}/?filter=id::eq::${props.samplePointId}&fields=name`,
        optionCodeField: "id",
        optionLabelField: "name",
      },
    },
    {
      fieldName: "sensorId",
      label: "Sensor Point",
      disabled: false,
      type: "select-async",
      validation: { required: true },
      fieldParams: {
        // optionDefinitionEndpoint: `${SENSOR_API_URL}/?join=sensorLib&join=device&join=device.site&join=device.site.enterprise&filter=site.enterpriseId::eq::${props.currentEnterpriseId}&filter=sensorLib.sampleTypeId::eq::${props.sampleTypeId}`,
        optionDefinitionEndpoint: `${SENSOR_API_URL}/?join=sensorLib&join=device&siteId=${props.siteId}&filter=sensorLib.sampleTypeId::eq::${props.sampleTypeId}`,
        optionCodeField: "id",
        optionLabelField: "label",
      },
    },
    {
      fieldName: "sourceType",
      label: "Sample Source",
      type: "select",
      validation: { required: true },
      defaultValue: PairingType.SENSOR,
      fieldParams: { options: PAIRING_OPTIONS },
    },
    {
      fieldName: "startDate",
      label: "Start Date",
      validation: { required: true },
      type: "date-time",
      dateTimeFormat: "YYYY/MM/DD HH:MM:SS",
    },
    {
      fieldName: "endDate",
      label: "End Date",
      validation: { required: false },
      type: "date-time",
      dateTimeFormat: "YYYY/MM/DD HH:MM:SS",
    },
  ];

  const dataSource = {
    apiUrl: SAMPLEPOINT_SENSOR_PAIRING_API,
    // customUrlRequestFragment: `groupId=${props.assetGroupId}`
  };

  return (
    <DataCrudEntityFormBinding
      id={props.id}
      dataSource={dataSource}
      action={props.action}
      title={props.title}
      fieldConfigurations={formFieldsConfiguration}
      onCloseAdditionalActions={props.onCloseAdditionalActions}
      showCloseButton={props.showCloseButton}
      userData={props.userData}
      currentEnterpriseId={props.currentEnterpriseId}
    />
  );
}
