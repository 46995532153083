import React, { ChangeEvent } from "react";
import { TextField, useTheme } from "@material-ui/core";
import { FieldConfiguration, FieldParamsType, INPUT_VARIANT } from "./FieldConfiguration.d";
import OutlinedFieldWrapper from "./OutlinedFieldWrapper";
import { safeFieldValue } from "../EntityFormCommon";
import { CustomThemeType } from "assets/jss/muiTheme";

export interface ControlledComponentProps {
  // TODO: duplication code in SelectField
  value: string | number | Date;
  onChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  // error?: boolean;
  helperText?: string;
  // name: string;
}

interface CustomTextFieldProps {
  value: any;
  defaultValue?: any;
  helperText?: string;
  fieldConfig: FieldConfiguration;
  disabled?: boolean; // TODO: duplicated within FieldConfiguration
  errorFlag: boolean;
  customHeight?: string;
  onChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  required?: boolean;
  valueWasModified?: boolean;
}

function CustomTextField(props: CustomTextFieldProps) {
  const safeValue = safeFieldValue(props.value, props.defaultValue, "");

  const generateMultilineConfig = (fieldParams: FieldParamsType) => {
    // TODO: do proper typecasting

    const result = { rows: 1, multiline: false };

    if (fieldParams && "rows" in fieldParams) {
      result.rows = fieldParams.rows;
      result.multiline = true;
    }

    return result;
  };

  const multiRowsConfig = generateMultilineConfig(props.fieldConfig.fieldParams);

  let customizedInputProps: {
    style: { height: string };
  };

  if (props.customHeight) {
    customizedInputProps = {
      style: { height: props.customHeight },
    };
  }

  const theme = useTheme() as CustomThemeType;

  return (
    <OutlinedFieldWrapper>
      <TextField
        style={{
          borderRadius: "4px",
          backgroundColor: props.valueWasModified ? theme.customColors.modifiedField : undefined,
        }}
        // Params similar to SelectField
        id={props.fieldConfig.fieldName}
        helperText={props.helperText}
        onChange={props.onChange}
        name={props.fieldConfig.fieldName}
        value={safeValue} // props.value}
        variant={INPUT_VARIANT}
        disabled={props.disabled}
        error={props.errorFlag}
        label={props.fieldConfig.label}
        fullWidth
        // required={props.fieldConfig.validation ? props.fieldConfig.validation.required : undefined}
        required={props.required}
        size="small"
        InputProps={customizedInputProps}
        placeholder={props.fieldConfig.placeholder}
        // Params unique to CustomTextField
        // Text field comes in different flavors e-mail, password etc. Not really applicable for select.
        type={props.fieldConfig.type}
        {...multiRowsConfig}
        autoComplete="off"
      />
    </OutlinedFieldWrapper>
  );
}

export default CustomTextField;
