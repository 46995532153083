import { StyleRulesCallback, Theme } from "@material-ui/core";

export const screenSectionTitleStyle: StyleRulesCallback<Theme, {}> = (theme) => ({
  root: {
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "20px",
    color: theme.palette.text.primary,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
});
