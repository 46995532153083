import React from "react";
import {
  FieldConfiguration,
  SelectConfiguration,
  MenuItemType,
  SelectAsyncConfiguration,
  INPUT_VARIANT,
  NONE_SELECT_CODE,
  NONE_SELECT_LABEL,
} from "./Fields/FieldConfiguration.d";
import CustomTextField from "./Fields/CustomTextField";
import { FormattedJSONField } from "./Fields/FormattedJSONField";
import ChippedSelectField from "./Fields/ChippedSelectField";
import OutlinedSelectField from "./Fields/OutlinedSelectField";
import AsyncSelectOptionsProvider from "../DataProvider/AsyncSelectOptionsProvider";
import { MaterialUIPickers } from "./Fields/MaterialUIPickers";
import { AutoCompleteDropDown } from "./Fields/AutoCompleteDropDown";
import { twoArraysAreIdentical } from "components/Utils/ObjectUtils";
import { FormCheckBox } from "./Fields/FormCheckBox";
import { FieldsInProgressActionType } from "./EntityForm.d";

export interface FormFieldRendererProps {
  fieldConfig: FieldConfiguration;
  fieldValue: Date | string | number | Array<any> | boolean;
  fieldDefaultValue?: Date | string | number | Array<any> | boolean;
  initialFieldValue: Date | string | number | Array<any> | boolean;
  helperText: string;
  errorFlag: boolean;
  disabled: boolean;
  required: boolean;
  // TODO: typefy properly
  handleChange: (
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | React.ChangeEvent<{ value: unknown }>
      | { target: { name: string; value: any } }
  ) => void;
  setFieldValueDirect?: {
    (field: never, value: any, shouldValidate?: boolean): void;
    (field: string, value: any): void;
  };
  dispatchFieldsInProgress?: (event: FieldsInProgressActionType) => void;
}

export function FormFieldRenderer(props: FormFieldRendererProps) {
  let valueWasModified: boolean;
  if (props.fieldConfig.type === "select-multiple") {
    valueWasModified = !twoArraysAreIdentical(
      props.fieldValue as Array<any>,
      props.initialFieldValue as Array<any>
    );
  } else {
    valueWasModified = props.fieldValue !== props.initialFieldValue;
  }

  switch (props.fieldConfig.type) {
    case "email":
    case "number":
    case "password":
    case "text":
      return (
        <div>
          <CustomTextField
            onChange={props.handleChange}
            helperText={props.helperText}
            value={props.fieldValue}
            defaultValue={props.fieldDefaultValue}
            errorFlag={props.errorFlag}
            fieldConfig={props.fieldConfig}
            disabled={props.disabled}
            required={props.required}
            valueWasModified={valueWasModified}
          />
        </div>
      );
    case "json":
      return (
        <div>
          <FormattedJSONField
            disabled={props.disabled}
            value={props.fieldValue}
            label={props.fieldConfig.label}
          />
        </div>
      );
    case "boolean":
      return (
        <div>
          <FormCheckBox
            fieldConfig={props.fieldConfig}
            value={props.fieldValue as boolean}
            disabled={props.disabled}
            onChange={props.handleChange}
            valueWasModified={valueWasModified}
          />
        </div>
      );
    case "date-time":
      return (
        <div>
          <MaterialUIPickers
            id={props.fieldConfig.fieldName}
            errorFlag={props.errorFlag}
            required={props.required}
            value={props.fieldValue as string | number | Date}
            defaultValue={props.fieldDefaultValue as string | number | Date}
            onChange={props.handleChange}
            disabled={props.disabled}
            pickerType={props.fieldConfig.type}
            behavior="inline"
            variant={INPUT_VARIANT}
            disableFuture={false}
            format={props.fieldConfig.dateTimeFormat || "YYYY/MM/DD"}
            label={props.fieldConfig.label}
            helperText={props.helperText}
            // onChangeCallback={this.handleChangeStartDate}
            // dateRangeToHighlight={{start: this.state.rangeStartDate, end: this.state.rangeEndDate}}
            valueWasModified={valueWasModified}
          />
        </div>
      );
    case "select-async":
      // TODO: refactor to a better code structure.
      let content: JSX.Element;

      if ((props.fieldConfig.fieldParams as SelectAsyncConfiguration).autoComplete) {
        content = (
          <div>
            <AutoCompleteDropDown
              fieldConfig={props.fieldConfig}
              required={props.required}
              onChange={props.handleChange}
              helperText={props.helperText}
              errorFlag={props.errorFlag}
              disabled={props.disabled}
              value={props.fieldValue as string | number}
              valueWasModified={valueWasModified}
            />
          </div>
        );
      } else {
        content = (
          <div>
            <AsyncSelectOptionsProvider
              fieldName={props.fieldConfig.fieldName}
              fieldParams={props.fieldConfig.fieldParams as SelectAsyncConfiguration}
              value={props.fieldValue as string | number}
              filterUrl={(props.fieldConfig.fieldParams as SelectAsyncConfiguration).filterUrl as string}
              extraStaticValues={
                (props.fieldConfig.fieldParams as SelectAsyncConfiguration).extraStaticValues
              }
              dispatchFieldsInProgress={props.dispatchFieldsInProgress}
            >
              {(
                optionsArray: Array<MenuItemType>,
                sanitizedValue: string | number,
                valueOptionsAreAvailable: boolean
              ) => {
                let optionsWithEmptyValueIfNeeded: Array<MenuItemType>;

                if (props.required === true) {
                  optionsWithEmptyValueIfNeeded = optionsArray;
                } else {
                  optionsWithEmptyValueIfNeeded = [
                    {
                      code: NONE_SELECT_CODE,
                      label: NONE_SELECT_LABEL,
                    },
                    ...optionsArray,
                  ];
                }

                return (
                  <OutlinedSelectField
                    required={props.required}
                    fieldConfig={props.fieldConfig}
                    onChange={props.handleChange}
                    value={sanitizedValue}
                    defaultValue={props.fieldDefaultValue}
                    errorFlag={props.errorFlag}
                    helperText={props.helperText}
                    disabled={!valueOptionsAreAvailable || props.disabled}
                    optionsArray={optionsWithEmptyValueIfNeeded} // optionsArray}
                    setFieldValueDirect={props.setFieldValueDirect}
                    valueWasModified={valueWasModified}
                  />
                );
              }}
            </AsyncSelectOptionsProvider>
          </div>
        );
      }
      return content;
    case "select":
      // let safeFieldValue = fieldValue || fieldConfig.defaultValue || '';
      let staticOptionsWithEmptyValueIfNeeded: Array<MenuItemType>;
      const staticOptionsArray = (props.fieldConfig.fieldParams as SelectConfiguration).options;

      if (props.required === true) {
        staticOptionsWithEmptyValueIfNeeded = staticOptionsArray;
      } else {
        staticOptionsWithEmptyValueIfNeeded = [
          { code: NONE_SELECT_CODE, label: NONE_SELECT_LABEL },
          ...staticOptionsArray,
        ];
      }

      return (
        <div>
          <OutlinedSelectField
            fieldConfig={props.fieldConfig}
            required={props.required}
            onChange={props.handleChange}
            value={props.fieldValue} // safeFieldValue}
            defaultValue={props.fieldDefaultValue as string | number}
            errorFlag={props.errorFlag}
            helperText={props.helperText}
            disabled={props.disabled}
            // optionsArray={(props.fieldConfig.fieldParams as SelectConfiguration).options}
            optionsArray={staticOptionsWithEmptyValueIfNeeded}
            setFieldValueDirect={props.setFieldValueDirect}
            valueWasModified={valueWasModified}
          />
        </div>
      );
    case "select-multiple":
      // let safeMultipleValue = fieldValue || fieldConfig.defaultValue || [];

      // console.log('safeMultipleValue', safeMultipleValue);
      // console.log('(fieldConfig.fieldParams as SelectConfiguration).options', (fieldConfig.fieldParams as SelectConfiguration).options);

      return (
        <div>
          <ChippedSelectField
            fieldConfig={props.fieldConfig}
            required={props.required}
            onChange={props.handleChange}
            values={(props.fieldValue as Array<any>) || []} // safeMultipleValue}
            defaultValue={props.fieldDefaultValue as string | number}
            errorFlag={props.errorFlag}
            helperText={props.helperText}
            disabled={props.disabled}
            optionsArray={(props.fieldConfig.fieldParams as SelectConfiguration).options}
            valueWasModified={valueWasModified}
          />
        </div>
      );
    case "hidden":
      // Show nothing
      return null;
    default:
      console.error("EntityEdit:renderFormFields - UNKNOWN FIELD TYPE");
      return null;
  }
}
