import React from "react";
import { FormControlLabel, FormGroup, makeStyles } from "@material-ui/core";
import { ColouredCheckBox } from "components/Crud/Form/Fields/ColouredCheckBox";
import { useMediaQueryMatchDown } from "components/Utils/LayoutUtils";
import { elementLabelStyle } from "./elementLabel.style";

export interface CheckBoxGroupDefinitionType {
  boxColor: string;
  label: string;
}

export interface CheckBoxGroupProps {
  definition: Array<CheckBoxGroupDefinitionType>;
  values: Array<boolean>;
  onChange: (values: Array<boolean>) => void;
}

const useLabelStyles = makeStyles(elementLabelStyle, { name: "CheckBoxGroup" });

export function CheckBoxGroup(props: CheckBoxGroupProps) {
  const labelClasses = useLabelStyles({});

  const toggleDatasetCheckBox = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const newValues = props.values.map((currentValue: boolean, index: number) =>
      index.toString() === event.target.value ? checked : currentValue
    );

    props.onChange(newValues);
  };

  const isSmallScreen = useMediaQueryMatchDown("sm");

  return (
    <FormGroup>
      <div
        style={{
          display: "flex",
          flexDirection: isSmallScreen ? "row" : "column",
          flexWrap: isSmallScreen ? "wrap" : "nowrap",
        }}
      >
        {props.definition.map((definitionItem: any, index: number) => {
          return (
            <div key={index} style={{ whiteSpace: "nowrap", flexBasis: 0, flexGrow: 0.5 }}>
              <FormControlLabel
                key={index}
                classes={{
                  label: isSmallScreen ? labelClasses.labelMobile : labelClasses.labelDesktop,
                }}
                label={definitionItem.label}
                control={
                  <ColouredCheckBox
                    customcolor={definitionItem.boxColor}
                    checked={props.values[index] || false}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
                      toggleDatasetCheckBox(event, checked)
                    }
                    value={index}
                  />
                }
              />
            </div>
          );
        })}
      </div>
    </FormGroup>
  );
}
