import React, { useContext, useState, useEffect } from "react";
import { EnterpriseIdType } from "views/Enterprise/Enterprise.d";
import { OverviewPlainCard } from "components/Cards/OverviewPlainCard";
import { PrimaryCardValue } from "components/Elements/Typography/PrimaryCardValue";
import { UserParams } from "views/User/UserParams.d";
import { fetchDataByFullURL, getFilterAction } from "components/Utils/CRUDUtils";
import { MessageBannerDispatcherContext } from "components/Dashboard/Message/MessageBannerFrame";
import { AlertAcknowledgementStatus } from "views/Alert/Alert.d";
import { API_LIB } from "components/Utils/API_LIB";
import { preUrl } from "views/constants";
import { FilterMatchTypes, OPERATION_ERROR } from "components/Utils/CRUDUtils.d";

export interface AlertsTodayPortletProps {
  currentEnterpriseId: EnterpriseIdType;
  userData: UserParams;
}

export function AlertsTodayPortlet(props: AlertsTodayPortletProps) {
  const messageBannerDispatcher = useContext(MessageBannerDispatcherContext);

  const [alertList, setAlertList] = useState([] as any);

  useEffect(() => {
    const asyncFunctionWrapper = async () => {
      // TODO: redo using request object
      const notificationStatusFilter = `filter=notificationStatus::eq::${AlertAcknowledgementStatus.RAISED}`;
      const userIdFilter = `filter=userId::${getFilterAction(FilterMatchTypes.EQUAL)}::${
        props.userData.userId
      }`;

      // TODO: Alerts filtering by date is temporarily disabled as it's not supported
      const dateFilter = ""; // `filter=event.date::${getFilterAction(FilterMatchTypes.GREATER_THAN)}::${startOfToday().getTime()}`;

      const data = await fetchDataByFullURL(
        `${preUrl}${API_LIB.NOTIFICATIONS}?${notificationStatusFilter}&${userIdFilter}&${dateFilter}`,
        "Fetch Alert Count"
      );

      if (data.status.type === "success") {
        setAlertList(data.data);
      } else {
        messageBannerDispatcher({
          messageType: OPERATION_ERROR,
          messageText: `Failed to retrieve Alert Count`,
        });
      }
    };

    asyncFunctionWrapper();
  }, [props.userData.userId, messageBannerDispatcher]);

  return (
    <OverviewPlainCard title="ALERTS NOT ACKNOWLEDGED">
      <PrimaryCardValue>{alertList.length}</PrimaryCardValue>
    </OverviewPlainCard>
  );
}
