export enum UserRole {
  ALL_USERS = -1,
  SUPER_ADMIN = 0,
  USER = 1,
  SUPERVISOR = 2,
  ADMIN = 3,
}

export interface GuardParams {
  action: string;
  currentMembershipRole?: UserRole;
  isSuperAdmin: boolean;
  id?: number;
}

/**
 * Base interface for Entity Actions
 * It tells the UI whether the user has permissions to perform an action
 * on a type of entity, given its current role.
 */
export interface EntityGuard {
  hasPermission(params: GuardParams): boolean;
}
