import React from "react";
import { GraphComponentHeaderContainer } from "./GraphComponentHeaderContainer";
import { GraphComponentRangeNavigation } from "./GraphComponentRangeNavigation";
import { ScreenSectionTitle } from "components/Elements/Typography/ScreenSectionTitle";
import { CheckBoxGroup, CheckBoxGroupDefinitionType } from "components/Elements/CheckBoxGroup";
import { makeStyles } from "@material-ui/core";
import { graphComponentStyle } from "./graphComponentStyle";
import { CardSubTitle } from "components/Elements/Typography/CardSubTitle";
import { useMediaQueryMatchDown } from "components/Utils/LayoutUtils";
import { ConditionalRender } from "layouts/ConditionalRender";
import { GraphDataRequestParametersType } from "./Graph.d";

export interface GraphComponentControlsProps {
  requestedGraphData: GraphDataRequestParametersType;
  displayedDatasets: Array<boolean>;
  dataSourceCheckBoxOptions: Array<CheckBoxGroupDefinitionType>;
  handleGraphDataRequestChange: (newValues: GraphDataRequestParametersType) => void;
  onDisplayedDatasetChange: (values: Array<boolean>) => void;
  timeZone: string;
}

const useStyles = makeStyles(graphComponentStyle, { name: "GraphComponentControls" });

export function GraphComponentControls(props: GraphComponentControlsProps) {
  const classes = useStyles({});

  const isSmallScreen = useMediaQueryMatchDown("sm");

  return (
    <div>
      <ConditionalRender condition={!isSmallScreen}>
        <GraphComponentHeaderContainer>
          <ScreenSectionTitle>Controls</ScreenSectionTitle>
        </GraphComponentHeaderContainer>
      </ConditionalRender>
      <div
        /* Content start */ style={{
          boxSizing: "border-box",
          padding: isSmallScreen ? "16px" : "24px",
          height: "100%",
        }}
      >
        <GraphComponentRangeNavigation
          values={props.requestedGraphData}
          onChange={props.handleGraphDataRequestChange}
          timeZone={props.timeZone}
        />
        <div className={classes.graphSubGroupStyle}>
          <div className={isSmallScreen ? classes.invisible : classes.groupTitleItem}>
            <CardSubTitle>{"DATA SOURCES"}</CardSubTitle>
          </div>
          <CheckBoxGroup
            definition={props.dataSourceCheckBoxOptions}
            values={props.displayedDatasets}
            onChange={props.onDisplayedDatasetChange}
          />
        </div>
      </div>
    </div>
  );
}
