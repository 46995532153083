import { mainScreenStyle } from "layouts/Dashboard/mainScreenStyle";
import React, { useEffect, useContext, useState } from "react";
import { RouteProps } from "react-router-dom";
import dashboardRoutes from "routes/DashboardRoutes";
import { makeStyles } from "@material-ui/core/styles";
import { UserParams } from "views/User/UserParams.d";
import { USER_API_URL } from "views/User/User.const";
import Header from "components/Dashboard/Header/Header";
import Sidebar from "components/Dashboard/Sidebar/Sidebar";
import { ALL_ENTERPRISES_CODE, ALL_ENTERPRISES_LABEL } from "views/Enterprise/Enterprise.const";
import { RowWrapper } from "layouts/RowWrapper";
import { MainScreenContent } from "./MainScreenContent";
import { useMediaQueryMatchDown } from "components/Utils/LayoutUtils";
import clsx from "clsx";
import { PopupDialogsFrame } from "popups/PopupDialogsFrame";
import { isSpecified, isNonEmptyArray } from "components/Utils/MiscUtils";
import { useAuth0 } from "components/Session/Auth0Provider";
import { RotatingLoadingArrowCircle } from "assets/icons/RotatingLoadingArrowCircle";
import { OPERATION_SUCCESS } from "components/Utils/CRUDUtils.d";
import { fetchEntityById } from "components/Utils/CRUDUtils";
import { DashboardRoute } from "components/Utils/RouteUtils.d";
import { ObjectIdType } from "components/Utils/Object.d";
import { EnterpriseIdType } from "views/Enterprise/Enterprise.d";
import { GlobalEventContext } from "GlobalContext";
import { GlobalEvent } from "GlobalContext.d";

interface MainScreenProps {
  currentEnterpriseId: EnterpriseIdType;
  currentEnterpriseName: string;
  userData: UserParams;
  match: any; // TODO: proper router typing
  sidebarVersion: number;
  sidebarIsOpen: boolean;
}

const useStyles = makeStyles(mainScreenStyle, { name: "MainScreen" });

export const getRoutesForEnterprise = (currentEnterpriseId: ObjectIdType) => {
  let result: DashboardRoute[];

  if (currentEnterpriseId === "") {
    result = dashboardRoutes.filter((routeItem) => routeItem.availableForNoEnterprise === true);
  } else if (currentEnterpriseId !== ALL_ENTERPRISES_CODE) {
    // Single Enterprise
    result = dashboardRoutes.filter((routeItem) => !(routeItem.disableIfEnterpriseIsSelected === true));
  } else {
    result = dashboardRoutes;
  }

  // result = (
  //   currentEnterpriseId !== ALL_ENTERPRISES_CODE ?
  //   dashboardRoutes :
  //   dashboardRoutes.filter(routeItem => routeItem.availableForNoEnterprise)
  // );

  return result;
};

function MainScreen(props: MainScreenProps & RouteProps) {
  const classes = useStyles({});

  const globalEventContext = useContext(GlobalEventContext);

  const isSmallScreen = useMediaQueryMatchDown("sm");
  // const [ version, increaseVersion ] = useVersion();

  const [initializationStatus, setInitializationStatus] = useState({
    isInitializing: true,
    statusMessage: undefined,
  });

  const [availableRoutes, setAvailableRoutes] = useState([]);

  useEffect(() => {
    setAvailableRoutes(getRoutesForEnterprise(props.currentEnterpriseId));
  }, [props.currentEnterpriseId]);

  useEffect(() => {
    if (/* props.sidebarIsOpen &&  */ isSmallScreen) {
      globalEventContext(
        /* .dispatchEvent */ {
          type: GlobalEvent.SET_SIDEBAR_OPEN,
          payload: { isOpen: false },
          originator: "MainScreen.useEffect[]",
        }
      );
    }
  }, [globalEventContext, isSmallScreen]);

  const { isAuthenticated, user } = useAuth0();

  const userId = user.sub.replace("auth0|", "");

  useEffect(() => {
    // TODO: this effect must be taken above the main screen to the authorized Realm or something

    const initUser = async () => {
      setInitializationStatus({ isInitializing: true, statusMessage: undefined });

      const localResult = await fetchEntityById(
        userId,
        USER_API_URL,
        "join=memberships&join=memberships.enterprise"
      );

      if (localResult.status.type === OPERATION_SUCCESS) {
        const data = localResult.data;

        // ----- settting user Params ---------
        const userParams: UserParams = {
          isSuperAdmin: data.isSuperAdmin,
          userId: data.id,
          firstName: data.firstName,
          lastName: data.lastName,
          username: data.username,
          memberships: data.memberships,
        };

        globalEventContext(
          /* .dispatchEvent */ {
            type: GlobalEvent.SET_ACTIVE_USER,
            payload: userParams,
            originator: "MainScreen.useEffect[isAuthenticated, user]",
          }
        );

        // ----- setting Enterprise Params
        if (userParams.isSuperAdmin) {
          globalEventContext(
            /* .dispatchEvent */ {
              type: GlobalEvent.CHANGE_CURRENT_ENTERPRISE,
              payload: {
                enterpriseId: ALL_ENTERPRISES_CODE,
                enterpriseName: ALL_ENTERPRISES_LABEL,
              },
              originator: "MainScreen.useEffect[isAuthenticated, user]",
            }
          );

          setInitializationStatus({ isInitializing: false, statusMessage: undefined });
        } else if (isNonEmptyArray(userParams.memberships)) {
          const userEnterpriseId = userParams.memberships[0].enterpriseId;

          globalEventContext(
            /* .dispatchEvent */ {
              type: GlobalEvent.CHANGE_CURRENT_ENTERPRISE,
              payload: {
                enterpriseId: userEnterpriseId,
                // enterpriseName: ALL_ENTERPRISES_LABEL
              },
              originator: "MainScreen.useEffect[isAuthenticated, user]",
            }
          );

          setInitializationStatus({ isInitializing: false, statusMessage: undefined });
        } else {
          setInitializationStatus({
            isInitializing: false,
            statusMessage: "User is not assigned to any enterprise",
          });
        }
      } else {
        setInitializationStatus({ isInitializing: false, statusMessage: "Authorization Error" });
      }
    };

    if (isAuthenticated) {
      initUser();
    }
  }, [isAuthenticated, userId, globalEventContext]);

  let mainContentToDisplay;

  if (initializationStatus.isInitializing) {
    // TODO: keep for potential debugging let userMessage = isSpecified(props.userData) ? JSON.stringify(props.userData) : 'User Data is not available';
    // TODO: keep for potential debugging let enterpriseData = `enterpriseId = ${props.currentEnterpriseId}, enterpriseName = ${props.currentEnterpriseName}`;

    mainContentToDisplay = (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          width: "100%",
        }}
      >
        <RotatingLoadingArrowCircle />
      </div>
    );
  } else if (isSpecified(initializationStatus.statusMessage)) {
    /*     mainContentToDisplay = (
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>
        <p>{initializationStatus.statusMessage}</p>
      </div>
    ); */
    mainContentToDisplay = (
      <MainScreenContent
        routes={availableRoutes}
        userData={props.userData}
        currentEnterpriseId={props.currentEnterpriseId}
      />
    );
  } else {
    mainContentToDisplay = (
      <MainScreenContent
        routes={availableRoutes}
        userData={props.userData}
        currentEnterpriseId={props.currentEnterpriseId}
      />
    );
  }

  return (
    <PopupDialogsFrame>
      <div className={classes.root}>
        <RowWrapper id="Header">
          <Header
            userData={props.userData}
            currentEnterpriseId={props.currentEnterpriseId}
            currentEnterpriseName={props.currentEnterpriseName}
          />
        </RowWrapper>
        <RowWrapper flexGrow={1} style={{ overflowY: "hidden", display: "flex" }}>
          <div className={classes.sideBarAndContentWrapper}>
            <div
              id="Sidebar-content"
              className={clsx(
                isSmallScreen ? classes.smallScreenSideBarContainer : undefined,
                isSmallScreen && !props.sidebarIsOpen ? classes.closedSmallScreenSideBar : undefined
              )}
            >
              <Sidebar
                routes={availableRoutes}
                open={props.sidebarIsOpen}
                userData={props.userData}
                currentEnterpriseId={props.currentEnterpriseId}
                sidebarVersion={props.sidebarVersion}
                {...props}
              />
            </div>
            {mainContentToDisplay}
          </div>
        </RowWrapper>
      </div>
    </PopupDialogsFrame>
  );
}

export default MainScreen;
