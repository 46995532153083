import React, { useContext, ReactElement } from "react";
import { FormLayoutProps } from "components/Crud/Form/Layout/FormLayout.d";
import { FormLayoutElement } from "components/Crud/Form/Layout/FormLayoutElement";
import { ConditionalRender } from "layouts/ConditionalRender";
import {
  PASSWORD_KEY,
  CONFIRM_PASSWORD_KEY,
  IS_SUPER_ADMIN_KEY,
  FIRST_MEMBERSHIP_ROLE,
  FIRST_MEMBERSHIP_ENTERPRISE,
  FIELD_DO_VERIFY_EMAIL,
  REREQUEST_EMAIL_VERIFICATION,
  REQUEST_RESET_PASSWORD,
  FIRST_MEMBERSHIP_ID,
} from "./UserEditForm";
import { CREATE_ACTION, EDIT_ACTION } from "components/Crud/Entity.const";
import { OutlinedBadge } from "components/Elements/Badge/OutlinedBadge";
import { useTheme, makeStyles } from "@material-ui/core";
import { ActionButtonHandlerComponent } from "components/Buttons/ActionButtonHandlerComponent";
import { MessageBannerDispatcherContext } from "components/Dashboard/Message/MessageBannerFrame";
import {
  handleClickRerequestEmailVerificataion,
  handleClickRequestResetPassowrd,
} from "./UserCommon";
import { USER_ENTITY_NAME } from "./User.const";
import { isSpecified } from "components/Utils/MiscUtils";
import { userEditFormLayoutStyle } from "./userEditFormLayoutStyle";
import { FlexSpaceFiller } from "layouts/FlexSpaceFiller";
import { CardSubTitle } from "components/Elements/Typography/CardSubTitle";
import { OnClickAction } from "components/Buttons/Buttons.d";

const useStyles = makeStyles(userEditFormLayoutStyle, {
  name: "UserEditFormLayout",
});

export function UserEditFormLayout(props: FormLayoutProps): ReactElement {
  const classes = useStyles({});
  const theme = useTheme();
  const messageBannerDispatcher = useContext(MessageBannerDispatcherContext);

  // TODO: Refactor. May be form action should be a part of the form, not a part of layout
  const resendEmailButton = isSpecified(props.id)
    ? {
        labelElement: <div>Resend</div>,
        name: "rerequestEmailVerification",
        onClick: {
          type: "action",
          fn: () =>
            handleClickRerequestEmailVerificataion(
              props.id,
              messageBannerDispatcher
            ),
        } as OnClickAction,
        actionCode: REREQUEST_EMAIL_VERIFICATION,
        actionObject: USER_ENTITY_NAME,
        confirm: {
          message: `Are you sure you want to resend Email Verification Request`,
          dialogButton: "Yes",
        },
      }
    : null;

  // TODO: Refactor. May be form action should be a part of the form, not a part of layout
  const resetPasswordButton = isSpecified(props.id)
    ? {
        labelElement: <div>Reset passowrd</div>,
        name: "requestResetPassword",
        onClick: {
          type: "action",
          fn: () =>
            handleClickRequestResetPassowrd(props.id, messageBannerDispatcher),
        } as OnClickAction,
        actionCode: REQUEST_RESET_PASSWORD,
        actionObject: USER_ENTITY_NAME,
        confirm: {
          message: `Are you sure you want to reset your password`,
          dialogButton: "Yes",
        },
      }
    : null;

  const isDoVerifyFieldPresent = props.fieldConfigurations.find(
    (configItem) => configItem.fieldName === FIELD_DO_VERIFY_EMAIL
  )
    ? true
    : false;

  return (
    <div>
      <div style={{ height: "40px", display: "flex", alignItems: "center" }}>
        <CardSubTitle>{"PRIMARY INFO"}</CardSubTitle>
      </div>
      <div style={{ display: "flex" }}>
        <div>
          <FormLayoutElement fieldId="title" {...props} />
        </div>
        <div style={{ flexBasis: 0, flexGrow: 1 }}>
          <FormLayoutElement fieldId="firstName" {...props} />
        </div>
        <div style={{ flexBasis: 0, flexGrow: 1 }}>
          <FormLayoutElement fieldId="lastName" {...props} />
        </div>
      </div>
      <FormLayoutElement fieldId="username" {...props} />
      <div style={{ height: "40px", display: "flex", alignItems: "center" }}>
        <CardSubTitle>{"CONTACT INFO"}</CardSubTitle>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ flexGrow: 1 }}>
          <FormLayoutElement fieldId="phoneNumber" {...props} />
        </div>
        <div
          style={{ width: "230px", padding: "0 8px", boxSizing: "border-box" }}
        >
          <FormLayoutElement fieldId="enableSmsNotifications" {...props} />
        </div>
      </div>
      <div className={classes.emailInfoContainer}>
        <div className={classes.emailField} style={{ flexGrow: 1 }}>
          <FormLayoutElement fieldId="email" {...props} />
        </div>
        <div style={{ display: "flex", width: "230px", alignItems: "center" }}>
          <div style={{ padding: "0 8px" }}>
            <FormLayoutElement fieldId="enableEmailNotifications" {...props} />
          </div>
          <ConditionalRender condition={props.action === EDIT_ACTION}>
            <div className={classes.isEmailVerifiedBlock}>
              {props.values && props.values.isEmailVerified === true ? (
                <OutlinedBadge htmlColor={theme.palette.success.main}>
                  <div>
                    <div className={classes.notWord}>{"EMAIL"}</div>
                    <div>{"VERIFIED"}</div>
                  </div>
                </OutlinedBadge>
              ) : (
                <OutlinedBadge htmlColor={theme.palette.warning.main}>
                  <div>
                    <div className={classes.notWord}>{"EMAIL NOT"}</div>
                    <div>{"VERIFIED"}</div>
                  </div>
                </OutlinedBadge>
              )}
            </div>
          </ConditionalRender>
        </div>
      </div>
      <div className={classes.sendVerificationBlock}>
        <FormLayoutElement fieldId={FIELD_DO_VERIFY_EMAIL} {...props} />
        <FlexSpaceFiller />
        {resendEmailButton && !isDoVerifyFieldPresent && (
          <ActionButtonHandlerComponent
            buttonConfig={resendEmailButton}
            id={props.id}
            object={null}
            // eventDispatchers={props.actionProps.eventDispatchers}
          />
        )}
      </div>
      <div className={classes.sendVerificationBlock}>
        <FlexSpaceFiller />
        {resetPasswordButton && props.id === props.userData.userId && (
          <ActionButtonHandlerComponent
            buttonConfig={resetPasswordButton}
            id={props.id}
            object={null}
          />
        )}
      </div>
      <ConditionalRender condition={props.action === CREATE_ACTION}>
        <div style={{ height: "40px", display: "flex", alignItems: "center" }}>
          <CardSubTitle>{"PASSWORD"}</CardSubTitle>
        </div>
      </ConditionalRender>
      <FormLayoutElement fieldId={PASSWORD_KEY} {...props} />
      <FormLayoutElement fieldId={CONFIRM_PASSWORD_KEY} {...props} />
      <ConditionalRender
        condition={
          props.action ===
            CREATE_ACTION /* || props.action === EDIT_ACTION  */ ||
          props.userData.isSuperAdmin
        }
      >
        <React.Fragment>
          <div
            style={{ height: "40px", display: "flex", alignItems: "center" }}
          >
            <CardSubTitle>{"MEMBERSHIP"}</CardSubTitle>
          </div>
          <FormLayoutElement fieldId={IS_SUPER_ADMIN_KEY} {...props} />
          <FormLayoutElement fieldId={FIRST_MEMBERSHIP_ID} {...props} />
          <FormLayoutElement fieldId={FIRST_MEMBERSHIP_ROLE} {...props} />
          <FormLayoutElement fieldId={FIRST_MEMBERSHIP_ENTERPRISE} {...props} />
        </React.Fragment>
      </ConditionalRender>
    </div>
  );
}
