import moment from "moment-timezone";
import { intervalMap } from "components/Graph/GraphCommon";
import { IntervalOptions } from "components/Graph/Graph.d";

const ONE_MINUTE_TIME = 60 * 1000;
const TWO_MINUTES_TIME = 2 * ONE_MINUTE_TIME;
const ONE_HOUR_TIME = 60 * ONE_MINUTE_TIME;
const TWO_HOURS_TIME = 2 * ONE_HOUR_TIME;
const ONE_DAY_TIME = 24 * ONE_HOUR_TIME;
const TWO_DAYS_TIME = 2 * ONE_DAY_TIME;
const FIFTEEN_DAYS_TIME = 15 * ONE_DAY_TIME;

export const MLSECS_IN_DAY = 24 * 60 * 60 * 1000; // TODO: same as ONE_DAY_TIME
export const EOD_MLSECS_PRECISION = 1;

export const endOfDayDate = (inputDate: Date | number, timeZone: string): Date => {
  // TODO: not truncated but rounded

  // const inputDateAsDate = (typeof inputDate === 'number') ? new Date(inputDate) : inputDate;

  // const result = new Date(inputDateAsDate.getFullYear(), inputDateAsDate.getMonth(), inputDateAsDate.getDate(), 23, 59, 59, 1000 - EOD_MLSECS_PRECISION);
  const result = timeZone ? moment(inputDate).tz(timeZone) : moment(inputDate); // (typeof inputDate === 'number') ? new Date(inputDate) : inputDate;

  result.endOf("day");

  return result.toDate();
};
export const startOfDayDate = (inputDate: Date | number, timeZone: string): Date => {
  const result = timeZone ? moment(inputDate).tz(timeZone) : moment(inputDate); // (typeof inputDate === 'number') ? new Date(inputDate) : inputDate;

  // const result = new Date(inputDateAsDate.getFullYear(), inputDateAsDate.getMonth(), inputDateAsDate.getDate(), 0, 0, 0, 0);
  result.startOf("day");

  return result.toDate();
};

export const endOfToday = (timeZone?: string): Date => {
  const result = timeZone ? moment().tz(timeZone) : moment();

  result.endOf("day");

  return result.toDate();
};

export const startOfToday = (timeZone?: string): Date => {
  const result = timeZone ? moment().tz(timeZone) : moment();

  result.startOf("day");

  return result.toDate();
};

export const dateMinusInterval = (date: Date, interval: IntervalOptions, timeZone: string) => {
  const newDateMoment = timeZone ? moment(date).tz(timeZone) : moment(date);

  newDateMoment.subtract(intervalMap.get(interval));

  return newDateMoment.toDate();
};

export const datePlusInterval = (date: Date, interval: IntervalOptions, timeZone: string) => {
  const newDateMoment = timeZone ? moment(date).tz(timeZone) : moment(date);

  newDateMoment.add(intervalMap.get(interval));

  return newDateMoment.toDate();
};

export const weekDayString = (inputDate: number | Date) => {
  const weekDay = typeof inputDate === "number" ? new Date(inputDate).getDay() : inputDate.getDay();

  switch (weekDay) {
    case 0:
      return "Sunday";
    case 1:
      return "Monday";
    case 2:
      return "Tuesday";
    case 3:
      return "Wednesday";
    case 4:
      return "Thursday";
    case 5:
      return "Friday";
    case 6:
      return "Saturday";
    default:
      return "unknown";
  }
};

export const dateToString = (value: number, userFriendlyFormat?: boolean, dateOnly?: boolean) => {
  const gap = new Date().getTime() - value;
  let retStr = "";

  if (!value) {
    retStr = "";
  } else if (gap > FIFTEEN_DAYS_TIME || !userFriendlyFormat) {
    // "HH:mm DD-MM-YYYY"
    retStr = new Intl.DateTimeFormat("en-GB", {
      hour: "2-digit",
      minute: "2-digit",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }).format(value);

    const arr = retStr.split(",");

    if (dateOnly) {
      retStr = arr[0];
    } else {
      const space = " ";
      retStr = arr[1] + space + arr[0];
    }
  } else if (gap < ONE_MINUTE_TIME) {
    retStr = "Just now";
  } else if (gap < TWO_MINUTES_TIME) {
    retStr = "1 minute ago";
  } else if (gap < ONE_HOUR_TIME) {
    const minutes = Math.round(gap / (60 * 1000));
    retStr = `${minutes} minutes ago`;
  } else if (gap < TWO_HOURS_TIME) {
    retStr = "1 hour ago";
  } else if (gap < ONE_DAY_TIME) {
    const hours = Math.round(gap / (60 * 60 * 1000));
    retStr = `${hours} hours ago`;
  } else if (gap < TWO_DAYS_TIME) {
    retStr = `yesterday`;
  } else if (gap < FIFTEEN_DAYS_TIME) {
    const days = Math.round(gap / (24 * 60 * 60 * 1000));
    retStr = `${days} days ago`;
  }

  return retStr;
};
