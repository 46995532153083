import React, { ReactText } from "react";
import { isSpecified } from "components/Utils/MiscUtils";

export interface ConditionalRenderPropsChildren {
  condition: boolean;
  children: JSX.Element | ReactText;
}
export interface ConditionalRenderPropsTrueFalse {
  condition: boolean;
  onTrue: JSX.Element | ReactText;
  onFalse?: JSX.Element | ReactText;
}

export function ConditionalRender(props: ConditionalRenderPropsChildren | ConditionalRenderPropsTrueFalse) {
  let result: JSX.Element | ReactText = null;

  if (props.condition) {
    if (isSpecified((props as ConditionalRenderPropsTrueFalse).onTrue)) {
      result = (props as ConditionalRenderPropsTrueFalse).onTrue;
    } else if (isSpecified((props as ConditionalRenderPropsChildren).children)) {
      result = (props as ConditionalRenderPropsChildren).children;
    }
  } else {
    if (isSpecified((props as ConditionalRenderPropsTrueFalse).onFalse)) {
      result = (props as ConditionalRenderPropsTrueFalse).onFalse;
    }
  }

  if (typeof result === "string" || typeof result === "number") {
    return <React.Fragment>{result}</React.Fragment>;
  } else {
    return result;
  }
}
