import React from "react";
import { TableColumnParameters } from "../EntityTable.d";
import { TableCellRendererProps } from "../CellRenderers/TableCellRenderer.d";
import { BasicCellRenderer } from "../CellRenderers/BasicCellRenderer";
import { BasicActionSetRenderer, BasicActionSetRendererProps } from "../CellRenderers/BasicActionSetRenderer";
import { EntityTableUnifiedTableCell, CellContainerType } from "../Content/EntityTableUnifiedTableCell";
import { ObjectWithIdFieldType } from "components/Utils/Object.d";
import { EventDispatchersType } from "../StatelessTable.d";

export interface EntityTableCellProps {
  rowDataObject: ObjectWithIdFieldType;
  column: TableColumnParameters;
  zeroPadding?: boolean;
  eventDispatchers?: EventDispatchersType;
  className?: string;
  cellContainerElement: CellContainerType;
  restrictActions?: Array<string>;
}

export function EntityTableCell(props: EntityTableCellProps) {
  let CellRender: (props: TableCellRendererProps & any) => JSX.Element;

  let actionProps: BasicActionSetRendererProps;

  if (props.column.valueTransformationFunction) {
    CellRender = props.column.valueTransformationFunction;
  } else if (props.column.actionSet) {
    CellRender = BasicActionSetRenderer;
  } else {
    CellRender = BasicCellRenderer;
  }

  // Split from defining cell rendered to allow pass action set to custom renderer
  if (props.column.actionSet) {
    const restrictActions = props.restrictActions || [];

    const filteredActionSet = props.column.actionSet.filter(
      (item) => !restrictActions.includes(item.actionCode)
    );

    actionProps = {
      actionSet: filteredActionSet,
      eventDispatchers: props.eventDispatchers,
    };
  }

  return (
    <EntityTableUnifiedTableCell
      width={props.column.width}
      hide={props.column.hideForBreakpoints}
      align={props.column.align}
      className={props.className}
      noWrapColumnContent={props.column.noWrapColumnContent}
      zeroPadding={props.zeroPadding}
      cellContainerElement={props.cellContainerElement}
    >
      <CellRender
        obj={props.rowDataObject}
        fieldName={props.column.sourceName}
        actionProps={actionProps}
        maxWidth={props.column.width}
      />
    </EntityTableUnifiedTableCell>
  );
}
