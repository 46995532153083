import { ObjectIdType } from "components/Utils/Object.d";
import { FilterMatchTypes, UnifiedDataRequestType } from "components/Utils/CRUDUtils.d";
import { HiddenProps } from "@material-ui/core";
import { TableCellRendererProps } from "./CellRenderers/TableCellRenderer.d";
import { SelectAsyncConfiguration, SelectConfiguration } from "../Form/Fields/FieldConfiguration.d";
import { ActionButtonConfig } from "components/Buttons/Buttons.d";
import { BaseEntityProps } from "../Entity.d";

export type OnRowClickFunctionType = (id: ObjectIdType, object: Record<string, any>) => void;

export interface BaseListProps extends BaseEntityProps {
  onRowClickFunction?: OnRowClickFunctionType;
  activeRowPrimaryKeys?: Array<ObjectIdType>;
  inputRequest?: Partial<UnifiedDataRequestType>;
  allowUrlReplacementForFilters?: boolean;
  allowReinitialization?: boolean;
}

export enum TableBodyLayoutType {
  FULL_WIDTH_HTML = "full-width-html",
  FULL_WIDTH_CARD_SINGLE = "full-width-card-single",
  FULL_WIDTH_CARD_MULTI = "full-width-card-multi",
  HALF_WIDTH_INJECTED_CONTENT = "half-width-card-injected-content",
  FULL_WIDTH_INJECTED_CONTENT = "full-width-injected-content",
}

export interface TextFilterParams {
  placeholder: string;
}

export interface TableColumnFilter {
  filterType: "text" | "select" | "select-async"; // TODO: add support for multiple filters
  filterParams: TextFilterParams | SelectConfiguration | SelectAsyncConfiguration;
  filterDataSourceField: string;
  filterMatchType?: FilterMatchTypes;
  disable?: boolean;
}

export interface TableColumnParameters {
  sourceName?: string | Array<string>; // TODO: double check all places where it is used to check for Array or string
  label?: string;
  labelUnit?: true;
  hideForBreakpoints?: HiddenProps; // TODO: this hide attribute is MUI hide attribute used for RESPONSIVE hiding
  hide?: boolean; // TODO: unify behaviour with previous attribute
  width?: number | string;
  noWrapColumnContent?: boolean;
  noWrapColumnHeader?: boolean;
  align?: "inherit" | "left" | "center" | "right" | "justify";
  valueTransformationFunction?: (props: TableCellRendererProps) => JSX.Element;
  actionSet?: Array<ActionButtonConfig>;
  disableSorting?: boolean;
}
