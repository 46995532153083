import React, { useState, useEffect } from "react";
import { CREATE_ACTION, EDIT_ACTION, DELETE_ACTION } from "components/Crud/Entity.const";
import { fetchDataByFullURL } from "components/Utils/CRUDUtils";
import { API_LIB } from "components/Utils/API_LIB";
import { preUrl } from "views/constants";
import { calculateAllowedActions } from "access-control/RBACEngine";
import { ENTITY_LIB } from "components/Utils/CRUDEntityList";
import SidebarItem from "./SidebarItem";
import { activeRoute, APP_URL_PREFIX, generateEntityActionRoute } from "components/Utils/RouteUtils";
import { ROUTE_LIB } from "components/Utils/RouteList";
import Add from "@material-ui/icons/Add";
import { UserParams } from "views/User/UserParams.d";
import { EnterpriseIdType } from "views/Enterprise/Enterprise.d";
import { ALL_ENTERPRISES_CODE } from "views/Enterprise/Enterprise.const";
import { SidebarItemGroup } from "./SidebarItemGroup";
import { isNonEmptyArray } from "components/Utils/MiscUtils";
import { sidebarItemStyle } from "./sidebarItemstyle";
import { makeStyles } from "@material-ui/core";
import { ReactComponent as AssetGroupIcon } from "assets/icons/ProfessionalSet/AssestGroup.svg";

interface SidebarItemDynamicGroupProps {
  userData: UserParams;
  currentEnterpriseId: EnterpriseIdType;
  open: boolean;
  groupTitle?: string;
  sidebarVersion: number;
  ifContentIsEmptyDisplay: string | JSX.Element; // if null - the whole group will be hidden
}

const useStyles = makeStyles(sidebarItemStyle, { name: "SidebarItemDynamicGroup" });

function SidebarItemDynamicGroup(props: SidebarItemDynamicGroupProps) {
  const classes = useStyles({});

  const [linksToGroups, setLinksToGroups] = useState([]);

  useEffect(() => {
    const getAsyncData = async (currentEnterpriseId: EnterpriseIdType) => {
      // TODO: redo using the request object
      if (currentEnterpriseId && currentEnterpriseId !== -1) {
        const result = await fetchDataByFullURL(
          `${preUrl}${API_LIB.ASSET_GROUP}/` +
            `?fields=name,enterpriseId` +
            `&filter=enterpriseId::eq::${currentEnterpriseId}` +
            `&sort=name,ASC`,
          "get asset groups"
        );

        if (result.status.type === "success") {
          setLinksToGroups(
            result.data.map((dataItem: any) => {
              return {
                id: dataItem.id,
                name: dataItem.name,
              };
            })
          );
        }
      }
    };

    getAsyncData(props.currentEnterpriseId);
  }, [props.currentEnterpriseId, props.sidebarVersion]);

  const groupConfigurationAllowedActionsByRole = calculateAllowedActions(
    props.currentEnterpriseId,
    props.userData,
    [],
    ENTITY_LIB.ASSET_GROUP
  );

  let groupLinks: JSX.Element[];
  let showCreateGroupConfigurationButton;

  if (props.currentEnterpriseId !== ALL_ENTERPRISES_CODE) {
    groupLinks = linksToGroups.map((linkParams, key) => {
      // IMPORTANT: we configure by role (global) and individual item

      const path = `${APP_URL_PREFIX}${ROUTE_LIB.ASSET_GROUP_ASSET}`.replace(":groupId", linkParams.id); // TODO: redo with function

      const sidebarPopupConfigAllowedActions = groupConfigurationAllowedActionsByRole.filter(
        (allowedActionItem) => allowedActionItem === EDIT_ACTION || allowedActionItem === DELETE_ACTION
      );

      const showConfigButton = isNonEmptyArray(sidebarPopupConfigAllowedActions);

      return (
        <SidebarItem
          key={key}
          entityId={linkParams.id}
          active={activeRoute(path)}
          path={path}
          label={linkParams.name}
          icon={<AssetGroupIcon />}
          showConfigButton={showConfigButton}
          allowedActions={sidebarPopupConfigAllowedActions}
          open={props.open}
        />
      );
    });

    showCreateGroupConfigurationButton = groupConfigurationAllowedActionsByRole.indexOf(CREATE_ACTION) >= 0;

    if (showCreateGroupConfigurationButton) {
      const addGroupPath = generateEntityActionRoute(ROUTE_LIB.DYNAMIC_GROUP_CONFIGURATION, CREATE_ACTION);

      groupLinks.push(
        <SidebarItem
          key={"add_group_button"}
          // entityId={linkParams.id}
          active={activeRoute(addGroupPath)}
          path={addGroupPath}
          label={"Add New Group"}
          icon={<Add fontSize="small" />}
          showConfigButton={false}
          allowedActions={[]}
          open={props.open}
        />

        // TODO: save for the reference
        // <div key="add_group_button" className={classes.addNewGroupSection}>
        //   <Button onClick={handleClickAddNewGroup} variant={BUTTON_VARIANT} className={classes.addGroupButton}>
        //     <Add fontSize="small"/>{props.open && <Typography className={classes.addNewGroupText}>Add New Group</Typography>}
        //   </Button>
        // </div>
      );
    }
  }

  let groupContent: JSX.Element;

  if (!isNonEmptyArray(groupLinks) && props.ifContentIsEmptyDisplay === null) {
    groupContent = null;
  } else if (!isNonEmptyArray(groupLinks) && props.ifContentIsEmptyDisplay !== null) {
    let groupChild: JSX.Element;

    if (typeof props.ifContentIsEmptyDisplay === "string") {
      groupChild = <div className={classes.itemText}>{props.ifContentIsEmptyDisplay}</div>;
    } else {
      groupChild = props.ifContentIsEmptyDisplay;
    }

    groupContent = (
      <SidebarItemGroup groupTitle={props.groupTitle} open={props.open}>
        <React.Fragment>{groupChild}</React.Fragment>
      </SidebarItemGroup>
    );
  } else {
    groupContent = (
      <SidebarItemGroup groupTitle={props.groupTitle} open={props.open}>
        {groupLinks}
      </SidebarItemGroup>
    );
  }

  return groupContent;
}

export default SidebarItemDynamicGroup;
