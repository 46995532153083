import * as React from "react";
import { FieldConfiguration } from "components/Crud/Form/Fields/FieldConfiguration.d";
import { PACKET_LOG_API_URL } from "./PacketLog.const";
import { DataCrudEntityFormBinding } from "components/Crud/Form/DataCrudEntityFormBinding";
import { EntityFormParams } from "components/Crud/Form/EntityForm.d";

export function PacketLogEditForm(props: EntityFormParams) {
  const getFieldConfigs = (): FieldConfiguration[] => {
    return [
      {
        fieldName: "monitor.commsAddress",
        label: "Comms Address",
        type: "text",
      },
      {
        fieldName: "dateReceived",
        label: "Date",
        type: "date-time",
        dateTimeFormat: "YYYY/MM/DD hh:mm:ss",
      },
      {
        fieldName: "decodedData[sequenceNumber]",
        label: "Seq No",
        type: "text",
      },
      {
        fieldName: "rawData",
        label: "Raw Data",
        type: "text",
      },
      {
        fieldName: "decodedData",
        label: "Decoded Data",
        type: "json",
      },
      // {
      //   key: 'decodedData',
      //   label: 'Decoded Data',
      //   type: 'text',
      //   fieldParams: { rows : 6}
      // },
    ];
  };

  const dataSource = {
    apiUrl: PACKET_LOG_API_URL,
    customUrlRequestFragment: `join=monitor`,
  };

  return (
    <DataCrudEntityFormBinding
      id={props.id} // TODO: do we need this???
      dataSource={dataSource}
      action={props.action}
      title={props.title}
      fieldConfigurations={getFieldConfigs()}
      onCloseAdditionalActions={props.onCloseAdditionalActions}
      showCloseButton={props.showCloseButton}
      userData={props.userData}
      currentEnterpriseId={props.currentEnterpriseId}
    />
  );
}
