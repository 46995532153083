import React from "react";
import { SvgIcon, useTheme } from "@material-ui/core";
import { IconsPathDescriptorType } from "../IconsCommon";

export const TrendArrowDownSvgPathObject = (color: string): IconsPathDescriptorType => {
  return {
    path:
      "m 8.1907833,6.3570361 -2.284662,2.284662 7.3865247,7.3865239 -1.624224,1.624224 5.290486,-0.264544 0.255988,-5.274729 -1.665555,1.665554 z",
    strokeWidth: "1px",
    strokeColor: color,
    fillColor: color,
  };
};

export function TrendArrowDown(props: any) {
  const theme = useTheme();
  let color = theme.palette.warning.main;

  // TODO: this is a hack, color can be inherited from htmlColor properly
  if (props.htmlColor) {
    color = props.htmlColor;
  }

  const svgPathObject = TrendArrowDownSvgPathObject(color);

  return (
    <SvgIcon {...props}>
      <path
        d={svgPathObject.path}
        fill={svgPathObject.fillColor}
        strokeWidth={svgPathObject.strokeWidth}
        stroke={svgPathObject.strokeColor}
      />
    </SvgIcon>
  );
}
