import React from "react";
import { makeStyles } from "@material-ui/core";
import { tableActionButtonFaceStyle } from "./tableActionButtonFaceStyle";
import clsx from "clsx";

export interface TableActionButtonFaceProps {
  children: any;
  variant?: "default" | "important" | "unobtrusive";
  htmlColor?: string;
}

const useStyles = makeStyles(tableActionButtonFaceStyle, { name: "TableActionButtonFace" });

export function TableActionButtonFace(props: TableActionButtonFaceProps) {
  const classes = useStyles({});

  const variant = props.variant || "default";

  const className = clsx(classes.root, classes[variant]);

  return (
    <div className={className} style={{ backgroundColor: props.htmlColor }}>
      <React.Fragment>{props.children}</React.Fragment>
    </div>
  );
}
