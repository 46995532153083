import { StyleRulesCallback, Theme } from "@material-ui/core";

export const primaryCardValueStyle: StyleRulesCallback<Theme, {}> = (theme) => ({
  root: {
    fontSize: "50px",
    lineHeight: "40px",
    color: theme.palette.text.primary,
    fontWeight: 400,
  },
});
