import React, { useState, useContext, useCallback } from "react";
import { ObjectIdType } from "components/Utils/Object.d";
import { Paper, makeStyles } from "@material-ui/core";
import { OPERATION_ERROR, UnifiedDataRequestType, SORT_DESC } from "components/Utils/CRUDUtils.d";
import { createOrEditEntity } from "components/Utils/CRUDUtils";
import { UserParams } from "views/User/UserParams.d";
import { API_LIB } from "components/Utils/API_LIB";
import { getAttributeByPath } from "components/Crud/EntityUtils";
import { EDIT_ACTION, CREATE_ACTION } from "components/Crud/Entity.const";
import { ACKNOWLEDGE_ALERT_ACTION, ALERT_ENTITY_NAME, ACTION_ALERT_ACTION } from "views/Alert/Alert.const";
import { MessageBannerDispatcherContext } from "components/Dashboard/Message/MessageBannerFrame";
import { DataSourceParamsType } from "components/Crud/DataProvider/DataProviderCommon.d";
import { isNonEmptyArray, filterBy, isSpecified } from "components/Utils/MiscUtils";
import {
  DataQueryDataType,
  DataQueryOperationStatusType,
  DataQueryEventType,
} from "components/Crud/DataProvider/DataQuery.d";
import { DataQuery } from "components/Crud/DataProvider/DataQuery";
import { activeAlertCardStyle } from "./activeAlertCardStyle";
import { useMediaQueryMatchDown } from "components/Utils/LayoutUtils";
import { FlexSpaceFiller } from "layouts/FlexSpaceFiller";
import { ScreenSectionTitle } from "components/Elements/Typography/ScreenSectionTitle";
import { ActionButtonHandlerComponent } from "components/Buttons/ActionButtonHandlerComponent";
import { SingleRowNotificationCard } from "views/Alert/SingleRowNotificationCard";
import { TableActionButtonFace } from "components/Buttons/TableActionButtonFace";
import { priorityDictionary } from "components/Dictionaries/DictionariesCommon";
import { ActionButtonConfig } from "components/Buttons/Buttons.d";
import { getDictionaryItemByCode } from "components/Dictionaries/DictionariesUtils";
import { AlertAcknowledgementStatus } from "views/Alert/Alert.d";

export interface ActiveAlertCardProps {
  assetId: ObjectIdType;
  userData: UserParams;
  portletAction: ActionButtonConfig;
}

const useStyles = makeStyles(activeAlertCardStyle, { name: "ActiveAlertCard" });

// TODO: lift state up
export function ActiveAlertCard(props: ActiveAlertCardProps) {
  const messageBannerDispatcher = useContext(MessageBannerDispatcherContext);
  const isSmallScreen = useMediaQueryMatchDown("sm");

  const classes = useStyles({});

  // TODO: this is an alternative to didInvalidateData, need to decide wich approach is better
  const [dataSnapshotVersionNum, setDataSnapshotVersionNum] = useState(0);

  const increaseVersion = useCallback(() => {
    setDataSnapshotVersionNum((previousVersion) => previousVersion + 1);
  }, []);

  const dataSource: DataSourceParamsType = {
    apiUrl: `${API_LIB.NOTIFICATIONS}`,
  };

  const initialLoadRequest: UnifiedDataRequestType = {
    fieldList: [
      "notificationStatus",
      "event.message",
      "event.date",
      "event.level",
      "event.eventActions.id",
      "event.eventActions.userId",
      "event.eventActions.user.id",
      "event.eventActions.user.firstName",
      "event.eventActions.user.lastName",
    ],
    filter: {
      baseFilters: [
        filterBy("event.assetId", "=", props.assetId),
        filterBy("notificationStatus", "=", AlertAcknowledgementStatus.RAISED, true),
        filterBy("userId", "=", props.userData.userId),
      ],
    },
    isPaginationEnabled: true,
    pageNum: 0,
    rowsPerPage: 1,
    sortByColumn: "event.date", // 'event.date',
    sortDirection: SORT_DESC,
  };

  const asyncAlertAcknowledgement = useCallback(
    async (id: ObjectIdType, object: Record<string, any>) => {
      const notificationId = getAttributeByPath(object, "notifications[0].id");

      const result = await createOrEditEntity(
        EDIT_ACTION,
        notificationId,
        { notificationStatus: AlertAcknowledgementStatus.ACKNOWLEDGED },
        API_LIB.NOTIFICATIONS
      );

      if (result.status.type === OPERATION_ERROR) {
        messageBannerDispatcher({
          messageType: OPERATION_ERROR,
          messageText: `Alert Acknowledgement failed`,
        });

        console.error(
          `Error ${result.status.message} while acknowledging notification with id =  ${notificationId}`
        );
      } else {
        increaseVersion();
      }
    },
    [messageBannerDispatcher, increaseVersion]
  );

  const userId = props.userData.userId;

  const asyncAlertAction = useCallback(
    async (id: ObjectIdType, object: Record<string, any>) => {
      const eventId = getAttributeByPath(object, "id");

      const result = await createOrEditEntity(
        CREATE_ACTION,
        eventId,
        {
          userId: userId,
        },
        `/event/action/${eventId}`
      );

      if (result.status.type === OPERATION_ERROR) {
        messageBannerDispatcher({
          messageType: OPERATION_ERROR,
          messageText: `Alert Actioning failed`,
        });

        console.error(
          `Error ${result.status.message} while acknowledging notification with id =  ${eventId}`
        );
      } else {
        increaseVersion();
      }
    },
    [messageBannerDispatcher, increaseVersion, userId]
  );

  const actionSet: Array<ActionButtonConfig> = [
    {
      labelElement: (obj: Record<string, any>) => {
        const eventLevelItem = getDictionaryItemByCode(priorityDictionary, obj.level) as Record<string, any>;

        return (
          <TableActionButtonFace variant="important" htmlColor={eventLevelItem.colorCode}>
            <span style={{ minWidth: "100px", color: eventLevelItem.textColor }}>Acknowledge</span>
          </TableActionButtonFace>
        );
      },
      name: "acknowledgeAlert",
      onClick: {
        type: "action",
        fn: asyncAlertAcknowledgement,
      },
      displayCondition: (obj: Record<string, any>) => {
        const notificationStatus = getAttributeByPath(obj, "notifications[0].notificationStatus");

        const displayResult =
          isSpecified(notificationStatus) && notificationStatus !== AlertAcknowledgementStatus.ACKNOWLEDGED;

        return displayResult;
      },
      doNotGroup: true,
      actionCode: ACKNOWLEDGE_ALERT_ACTION,
      actionObject: ALERT_ENTITY_NAME,
    },
    {
      labelElement: (
        <TableActionButtonFace variant="unobtrusive">
          <span style={{ minWidth: "100px", fontWeight: 500 }}>Action</span>
        </TableActionButtonFace>
      ),
      name: "acknowledgeAlert",
      onClick: {
        type: "action",
        fn: asyncAlertAction,
      },
      displayCondition: (obj: Record<string, any>) => {
        const notificationStatus = getAttributeByPath(obj, "notifications[0].notificationStatus");

        const displayResult =
          isSpecified(notificationStatus) && notificationStatus !== AlertAcknowledgementStatus.ACKNOWLEDGED;

        return displayResult;
      },
      doNotGroup: true,
      actionCode: ACTION_ALERT_ACTION,
      actionObject: ALERT_ENTITY_NAME,
    },
  ];

  return (
    <DataQuery
      dataSource={dataSource}
      initialQuery={initialLoadRequest}
      externalTriggerValues={[props.assetId, dataSnapshotVersionNum]}
      render={(
        queryData: DataQueryDataType,
        queryParams: UnifiedDataRequestType,
        queryStatus: DataQueryOperationStatusType,
        dispatchQueryEvent: (event: DataQueryEventType) => void
      ) => {
        if (isNonEmptyArray(queryData.data)) {
          const alertData = queryData.data[0];

          // let eventLevelItem = getDictionaryItemByCode(priorityDictionary, alertData.event.level) as Record<string, any>;

          const totalNotifications = queryData.totalRows;
          // let moreNotificationsString: string = undefined;
          // if (totalNotifications > 1) {
          //  moreNotificationsString = `+ ${totalNotifications - 1} more`;
          // }

          console.log("alertData", alertData);

          const eventData = {
            notifications: [
              {
                id: alertData.id,
                notificationStatus: alertData.notificationStatus,
              },
            ],
            ...alertData.event,
          };

          console.log("eventData", eventData);

          return (
            <div className={isSmallScreen ? classes.rootMobile : classes.rootDesktop}>
              <Paper className={classes.paper}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    height: "56px",
                    boxShadow: "inset -1px -1px 0 0 #EEEEEE",
                    padding: isSmallScreen ? "0 16px" : "0 24px",
                  }}
                >
                  <ScreenSectionTitle>{`New alerts (${totalNotifications})`}</ScreenSectionTitle>
                  <FlexSpaceFiller />
                  <ActionButtonHandlerComponent
                    buttonConfig={props.portletAction}
                    id={null}
                    object={null}
                    // eventDispatchers={props.actionProps.eventDispatchers}
                  />
                </div>
                <div>
                  <SingleRowNotificationCard
                    obj={eventData}
                    fieldName="id"
                    actionProps={{ actionSet: actionSet }}
                  />
                </div>
              </Paper>
            </div>
          );
        } else {
          return <React.Fragment />;
        }
      }}
    />
  );
}
