import { StyleRulesCallback, Theme } from "@material-ui/core/styles";
import { defaultFont } from "assets/jss/material-dashboard-react";
import { grey } from "@material-ui/core/colors";

const PAPER_BORDER_RADIUS = "24px";

const tableStyle: StyleRulesCallback<Theme, {}> = (theme) => ({
  table: {
    marginBottom: "0",
    marginTop: "0",
    width: "100%",
    maxWidth: "100%",
    // backgroundColor: 'transparent',
    sortDirection: "asc",
    sortable: "true",
  },
  // Note: table2ColumnCardProtector and tableResponsive are mutually exclusive
  table2ColumnCardProtector: {
    // TODO: find out what is causing overflow
    overflowX: "hidden",
    width: "100%",
    marginTop: theme.spacing.length * 3,
  },
  tableResponsive: {
    width: "100%",
    marginTop: theme.spacing.length * 3,
    overflowX: "auto",
  },
  tableWithGutters: {
    // TODO: potentially apply negative margins for the table to avoid extra margin due to borderSpacing cell
    borderCollapse: "separate",
    borderSpacing: "0 20px",
    "& > tbody > tr": {
      "& > td": {
        position: "relative",
      },
      "& > td::after": {
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        content: `""`,
        zIndex: -1,
        transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        boxShadow:
          "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
      },
      "& > td:first-child::after": {
        borderTopLeftRadius: PAPER_BORDER_RADIUS,
        borderBottomLeftRadius: PAPER_BORDER_RADIUS,
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        content: `""`,
        zIndex: -1,
        transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        boxShadow:
          "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
      },
      "& > td:first-child": {
        borderTopLeftRadius: PAPER_BORDER_RADIUS,
        borderBottomLeftRadius: PAPER_BORDER_RADIUS,
      },
      "& > td:last-child": {
        borderTopRightRadius: PAPER_BORDER_RADIUS,
        borderBottomRightRadius: PAPER_BORDER_RADIUS,
      },
      "& > td:last-child::after": {
        borderTopRightRadius: PAPER_BORDER_RADIUS,
        borderBottomRightRadius: PAPER_BORDER_RADIUS,
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        content: `""`,
        zIndex: -1,
        transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        boxShadow:
          "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
      },
    },
  },
  tableHeaderRow: {
    backgroundColor: grey[50],
    height: "56px",
  },
  tableHeaderRoundedChildCells: {
    // TODO: find a better way to style it. table styling using CSS has no common sense
    "& th:first-child": {
      borderTopLeftRadius: "4px",
    },
    "& th:last-child": {
      borderTopRightRadius: "4px",
    },
  },
  tableHeadCell: {
    ...defaultFont,
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "24px",
    color: theme.palette.text.primary,
    // width: '30em',
    // textAlign: 'left',
  },
  tableCell: {
    ...defaultFont,
    lineHeight: "1.42857143",
    padding: "12px 8px",
    verticalAlign: "middle",
    // textAlign: 'left',
    sortDirection: "asc",
    sortable: "true",
    fontWeight: "bolder",
    // whiteSpace: 'nowrap',
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "25px",
  },
  marginTop: {
    // marginTop: '1px',
  },
  alignCenter: {
    textAlign: "center",
  },
  noWrapColumnHeader: {
    whiteSpace: "nowrap",
  },
  mdSpecCellPaddingForMuiPaddingNone: {
    paddingTop: "6px",
    paddingRight: "16px",
    paddingLeft: "16px",
    paddingBottom: "6px",
  },
  tableWrapperScrollWithinPage: {
    height: "100%",
  },
  tableWrapperScrollFullPage: {
    // Intentionally null
  },
  tableContentWrapperScrollWithinPage: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  tableContentWrapperScrollFullPage: {
    // Intentionally null
  },
  injectedContent: {
    flexBasis: 0,
    flexGrow: 1,
  },
  injectedContentHalf: {
    paddingLeft: "24px",
  },
});

export default tableStyle;
