import { UserRole } from "components/Crud/EntityGuard.d";

export enum SideBarCategory {
  GROUPS = 0,
  SYSTEM,
  ADMIN_VIEWS,
  OVERVIEW,
}

export interface DashboardRoute {
  path?: string;
  sidebarName?: string;
  icon?: JSX.Element;
  redirect?: boolean;
  to?: string;
  component?: any;
  show: boolean | Array<UserRole>;
  componentProps?: Array<string>;
  showConfig?: boolean | Array<UserRole>;
  category?: SideBarCategory;
  availableForNoEnterprise?: boolean;
  disableIfEnterpriseIsSelected?: boolean;
}

export enum RouteSearchKeys {
  PAGE_NUM = "page_num",
  ROWS_PER_PAGE = "rows_per_page",
  SORT_BY = "sort_by",
  SORT_DIRECTION = "sort_direction",
  TAB_ID = "tab_id",
}
