import { StyleRulesCallback, Theme } from "@material-ui/core";
import { px } from "components/Utils/LayoutUtils";
import { CustomProps } from "assets/jss/muiTheme";
import { isSpecified } from "components/Utils/MiscUtils";

export const columnLayoutStyle: StyleRulesCallback<Theme, {}> = (theme: Theme & CustomProps) => {
  // TODO: if spacing is no Spacing there is no need to add padding rules

  // TODO: for some reason when using ReactDOMserver.renderToStaticMarkup() theme.customProps where not passed
  return {
    root: (props: Record<string, any>) => ({
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      // flexWrap: 'wrap',
      "& > div:first-child:not(:only-child)": {
        boxSizing: "border-box",
        paddingRight: isSpecified(theme.customProps)
          ? px((theme.customProps as Record<string, number>)[props.spacingName] / 2)
          : 0,
      },
      "& > div:not(:first-child):not(:last-child)": {
        boxSizing: "border-box",
        paddingLeft: isSpecified(theme.customProps)
          ? px((theme.customProps as Record<string, number>)[props.spacingName] / 2)
          : 0,
        paddingRight: isSpecified(theme.customProps)
          ? px((theme.customProps as Record<string, number>)[props.spacingName] / 2)
          : 0,
      },
      "& > div:last-child:not(:only-child)": {
        boxSizing: "border-box",
        paddingLeft: isSpecified(theme.customProps)
          ? px((theme.customProps as Record<string, number>)[props.spacingName] / 2)
          : 0,
      },
      ...props,
    }),
    equalWidth: {
      "& > div": {
        flexGrow: 1,
        flexBasis: 0,
      },
    },
  };
};
