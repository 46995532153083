export enum SimpleAsyncEvent {
  LOADING = "LOADING",
  LOAD_ERROR = "LOAD_ERROR",
  LOAD_SUCCESS = "LOAD_SUCCESS",
}

export type DataType = Array<Record<string, any>> | Record<string, any>;

export interface SimpleAsyncDataStateType {
  isLoading: boolean;
  data: DataType;
  error: string;
}

export interface SimpleAsyncDataEventType {
  action: SimpleAsyncEvent;
  payload?: DataType | string;
}

export interface NotificationFlagsType {
  notifyOnSuccess?: boolean;
  notifyOnFailure?: boolean;
}
