import React from "react";
import { Paper, makeStyles } from "@material-ui/core";
import { ScreenSectionTitle } from "components/Elements/Typography/ScreenSectionTitle";
import { portletCardStyle } from "./portletCardStyle";
import { isNonEmptyArray } from "components/Utils/MiscUtils";
import { ActionButtonHandlerComponent } from "components/Buttons/ActionButtonHandlerComponent";
import { ActionButtonConfig } from "components/Buttons/Buttons.d";

export interface PortletCardProps {
  title: string;
  children: JSX.Element | Array<JSX.Element>;
  portletAction?: Array<ActionButtonConfig>;
}

const useStyles = makeStyles(portletCardStyle, { name: "PortletCard" });

export function PortletCard(props: PortletCardProps) {
  const classes = useStyles({});

  let portletContent: JSX.Element | Array<JSX.Element>;

  if (Array.isArray(props.children)) {
    portletContent = props.children.map((contentItem: JSX.Element, index: number) => {
      return (
        <div key={index} className={classes.portletContentItem}>
          {contentItem}
        </div>
      );
    });
  } else {
    portletContent = props.children;
  }

  return (
    <div className={classes.root}>
      <Paper classes={{ root: classes.paperRoot }}>
        <div className={classes.header}>
          <ScreenSectionTitle>{props.title}</ScreenSectionTitle>
        </div>
        <div className={classes.content}>{portletContent}</div>
        {isNonEmptyArray(props.portletAction) ? (
          <div className={classes.footer}>
            {props.portletAction.map((actionDescriptor: ActionButtonConfig, index: number) => {
              return (
                <ActionButtonHandlerComponent
                  key={actionDescriptor.name}
                  buttonConfig={actionDescriptor}
                  id={null}
                  object={null}
                  // eventDispatchers={props.actionProps.eventDispatchers}
                />
              );
            })}
          </div>
        ) : null}
      </Paper>
    </div>
  );
}
