import React from "react";
import { makeStyles } from "@material-ui/core";
import { mainScreenContentStyle } from "./mainScreenContentStyle";
import { Redirect, Route, Switch } from "react-router-dom";
import { UserParams } from "views/User/UserParams.d";
import { defaultRouteByUserAndEnterprise } from "components/Utils/RouteUtils";
import { DashboardRoute } from "components/Utils/RouteUtils.d";
import { EnterpriseIdType } from "views/Enterprise/Enterprise.d";
import clsx from "clsx";
import { useMediaQueryMatchDown } from "components/Utils/LayoutUtils";

export interface MainScreenContentProps {
  routes: DashboardRoute[];
  currentEnterpriseId: EnterpriseIdType;
  userData: UserParams;
}

const useStyles = makeStyles(mainScreenContentStyle, { name: "MainScreenContent" });

export function MainScreenContent(props: MainScreenContentProps) {
  const classes = useStyles({});

  const extractPropsFromMatchedRoute = (componentProps: Array<string>, matchParams: any) => {
    // TODO: add capability to translate prop names
    const result: Record<string, any> = {};

    if (componentProps) {
      componentProps.forEach((propName) => (result[propName] = matchParams[propName]));
    }

    return result;
  };

  const isSmallScreen = useMediaQueryMatchDown("sm");

  return (
    <div className={clsx(classes.root, isSmallScreen ? classes.smallerThanMedium : classes.largerThanMedium)}>
      <Switch>
        {props.routes.map((prop, key) => {
          const Component = prop.component;

          if (prop.redirect) {
            return <Redirect from={prop.path} to={prop.to} key={key} />;
          }

          return (
            // IMPORTANT NOTE: match.params are available only when Route.render is executed
            // TODO: ...props.match.params - redo in a more explicit way
            <Route
              path={prop.path}
              render={(
                routeRenderProps // TODO: Typefy
              ) => (
                <Component
                  {...routeRenderProps}
                  {...extractPropsFromMatchedRoute(prop.componentProps, routeRenderProps.match.params)}
                  currentEnterpriseId={props.currentEnterpriseId}
                  userData={props.userData}
                />
              )}
              key={key}
            />
          );
        })}
        <Route
          path="/"
          render={(inputProps: any) => {
            return (
              <Redirect
                to={{
                  pathname: defaultRouteByUserAndEnterprise(props.userData, props.currentEnterpriseId), // DEFAULT_ROUTE,
                  state: { from: inputProps.location },
                }}
              />
            );
          }}
        />
      </Switch>
    </div>
  );
}
