import { ConditionType, ValueType } from "./Trigger.d";

export const conditionTypeDictionary = [
  {
    code: ConditionType.GREATER,
    label: "greater than",
    hideForInput: true,
  },
  {
    code: ConditionType.GREATER_EQUALS,
    label: "greater or equals to",    
  },
  {
    code: ConditionType.LOWER,
    label: "less than",
    hideForInput: true,
  },
  {
    code: ConditionType.LOWER_EQUALS,
    label: "less than or equals to",    
  },
  {
    code: ConditionType.EQUALS,
    label: "equals to",
    hideForInput: true,
  },
];

export const valueTypeDictionary = [
  {
    code: ValueType.VALUE,
    label: "Value",
  },
  {
    code: ValueType.RATE,
    label: "Rate",
  },
];
