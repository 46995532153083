import React from "react";
import { IconComponentProps } from "./ProfessionalSet.d";

export function PressureComponent(props: IconComponentProps) {
  const localHtmlColor = props.htmlColor || "#414959";

  return (
    <svg x="0px" y="0px" height="24" width="24">
      <path
        style={{ fill: "none", stroke: localHtmlColor, strokeWidth: 1.5, strokeMiterlimit: 10 }}
        d="M5.58,9.17A6.42,6.42,0,1,1,12,15.59,6.42,6.42,0,0,1,5.58,
      9.17ZM16,9a3.95,3.95,0,0,0-7.9,0M3.62,18H20.49M3.62,20.5H20.49M12,18V15.59m.11-2.84a.44.44,0,
      1,1,.35-.52A.45.45,0,0,1,12.11,12.75Zm-.88-4.33.89,4.4"
      />
    </svg>
  );
}
