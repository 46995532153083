import React from "react";
import { Box } from "@material-ui/core";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  activeTabId: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, activeTabId, index, ...other } = props;
  // TODO: change Box to div
  return (
    <Box
      component="div"
      role="tabpanel"
      hidden={activeTabId !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      style={{ flexGrow: 1 }}
      {...other}
    >
      {/* activeTabId === index &&  */ <div style={{ height: "100%" }}>{children}</div>}
    </Box>
  );
}

export default TabPanel;
