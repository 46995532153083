import {
  AsyncValuesAction,
  AsyncValuesEventType,
  ValueOptionsStateType,
} from "./AsyncSelectOptionsProvider.d";
import { ReducerFunctionType } from "global.d";

export const AsyncValuesReducer: ReducerFunctionType<ValueOptionsStateType, AsyncValuesEventType> = (
  prevState,
  event
) => {
  switch (event.action) {
    case AsyncValuesAction.FLUSH_VALUES:
      const flushActionResult = {
        valueOptionsAreAvailable: false,
        valueOptions: prevState.valueOptions.slice(),
      };

      return flushActionResult;
    case AsyncValuesAction.UPDATE_VALUES:
      const updateActionResult = {
        valueOptionsAreAvailable: true,
        valueOptions: event.payload.slice(),
      };

      return updateActionResult;
    default:
      return prevState;
  }
};
