import { UserRole } from "components/Crud/EntityGuard.d";
import {
  CREATE_ACTION,
  DELETE_ACTION,
  EDIT_ACTION,
  SHOW_ACTION,
  LIST_ACTION,
} from "components/Crud/Entity.const";
import {
  MONITOR_CONFIGURATION_LIST_ACTION,
  MONITOR_PACKET_LOG_LIST_ACTION,
  MONITOR_SENSOR_LIST_ACTION,
  MONITOR_ENTITY_NAME,
  MONITOR_CONFIGURATION_ENTITY_NAME,
  MONITOR_SUMMARY_ACTION,
} from "views/hardware/Monitor/Monitor.const";
import {
  USER_MEMBERSHIP_LIST_ACTION,
  USER_ENTITY_NAME,
  CHANGE_USER_PASSWORD_EVENT_ENTITY,
} from "views/User/User.const";
import { USER_MEMBERSHIP_ENTITY_NAME } from "views/User/Membership/UserMembership.const";
import {
  ENTERPRISE_ENTITY_NAME,
  SWITCH_TO_ENTERPRISE,
} from "views/Enterprise/Enterprise.const";
import { SAMPLEPOINT_ENTITY_NAME } from "views/asset/SamplePoint/SamplePoint.const";
import {
  SENSOR_GRAPH_ACTION,
  SENSOR_ENTITY_NAME,
} from "views/hardware/Sensor/Sensor.const";
import {
  PACKET_LOG_ENTITY_NAME,
  REQUEST_PACKET_REPROCESSING,
} from "views/hardware/Monitor/PacketLog/PacketLog.const";
import {
  ALERT_ENTITY_NAME,
  ACTION_ALERT_ACTION,
} from "views/Alert/Alert.const";
import { SITE_ENTITY_NAME } from "views/Site/Site.const";
import { ENTITY_LIB } from "components/Utils/CRUDEntityList";
import {
  SAMPLEPOINT_SENSOR_PAIRING_ACTION,
  SAMPLEPOINT_SENSOR_PAIRING_ENTITY,
} from "views/asset/SamplePoint/SamplePointSensorPairing/SamplePointSensorPairing.const";
import { ACTION_NAVIGATE_TO_ASSET } from "views/asset/Asset/Asset.const";
import {
  GROUP_CONFIGURATION_FILTER_RESULTS,
  REFRESH_GROUP_FILTER_RESULTS_ACTION,
} from "views/AssetGroup/AssetGroupResultsPreview";
import { ACKNOWLEDGE_ALERT_ACTION } from "views/Alert/Alert.const";
import {
  VIEW_LOCATION_ACTION,
  NAVIGATE_TO_ALL_ASSET_ALERTS_ACTION,
} from "views/asset/Asset/Asset.const";
import { NAVIGATE_TO_ALL_ALERTS } from "views/Overview/Portlets/LatestAlertsPortlet";
import {
  REREQUEST_EMAIL_VERIFICATION,
  REQUEST_RESET_PASSWORD,
} from "views/User/UserEditForm";
import { RBACPermissionType } from "./RBACEngine.d";

// TODO: organize list into hierarychies
// TODO: add UI to see and manage permissions
export const RBAC_LIST: Array<RBACPermissionType> = [
  // -------- USER_ENTITY_NAME --------
  // CREATE
  {
    userRoleId: UserRole.SUPER_ADMIN,
    entityType: USER_ENTITY_NAME,
    entityAction: CREATE_ACTION,
    permissionValue: true,
  },
  {
    userRoleId: UserRole.ADMIN,
    entityType: USER_ENTITY_NAME,
    entityAction: CREATE_ACTION,
    permissionValue: true,
  },
  // EDIT
  {
    userRoleId: UserRole.SUPER_ADMIN,
    entityType: USER_ENTITY_NAME,
    entityAction: EDIT_ACTION,
    permissionValue: true,
  },
  {
    userRoleId: UserRole.ADMIN,
    entityType: USER_ENTITY_NAME,
    entityAction: EDIT_ACTION,
    permissionValue: true,
  },
  // DELETE
  {
    userRoleId: UserRole.SUPER_ADMIN,
    entityType: USER_ENTITY_NAME,
    entityAction: DELETE_ACTION,
    permissionValue: true,
  },
  // OTHER ACTIONS
  {
    userRoleId: UserRole.SUPER_ADMIN,
    entityType: USER_ENTITY_NAME,
    entityAction: USER_MEMBERSHIP_LIST_ACTION,
    permissionValue: true,
  },
  {
    userRoleId: UserRole.ALL_USERS,
    entityType: CHANGE_USER_PASSWORD_EVENT_ENTITY,
    entityAction: EDIT_ACTION,
    permissionValue: true,
  },
  {
    userRoleId: UserRole.SUPER_ADMIN,
    entityType: USER_ENTITY_NAME,
    entityAction: REREQUEST_EMAIL_VERIFICATION,
    permissionValue: true,
  },
  {
    userRoleId: UserRole.ALL_USERS,
    entityType: USER_ENTITY_NAME,
    entityAction: REQUEST_RESET_PASSWORD,
    permissionValue: true,
  },
  {
    userRoleId: UserRole.SUPER_ADMIN,
    entityType: USER_ENTITY_NAME,
    entityAction: LIST_ACTION,
    permissionValue: true,
  },
  {
    userRoleId: UserRole.ADMIN,
    entityType: USER_ENTITY_NAME,
    entityAction: LIST_ACTION,
    permissionValue: true,
  },

  // -------- ENTERPRISE_ENTITY_NAME --------
  // CREATE
  {
    userRoleId: UserRole.SUPER_ADMIN,
    entityType: ENTERPRISE_ENTITY_NAME,
    entityAction: CREATE_ACTION,
    permissionValue: true,
  },
  // SHOW
  {
    userRoleId: UserRole.ALL_USERS,
    entityType: ENTERPRISE_ENTITY_NAME,
    entityAction: SHOW_ACTION,
    permissionValue: true,
  },
  // EDIT
  {
    userRoleId: UserRole.SUPER_ADMIN,
    entityType: ENTERPRISE_ENTITY_NAME,
    entityAction: EDIT_ACTION,
    permissionValue: true,
  },
  // DELETE
  {
    userRoleId: UserRole.SUPER_ADMIN,
    entityType: ENTERPRISE_ENTITY_NAME,
    entityAction: DELETE_ACTION,
    permissionValue: true,
  },
  // OTHER ACTIONS:
  {
    userRoleId: UserRole.SUPER_ADMIN,
    entityType: ENTERPRISE_ENTITY_NAME,
    entityAction: SWITCH_TO_ENTERPRISE,
    permissionValue: true,
  },

  // -------- USER_MEMBERSHIP_ENTITY_NAME --------
  // CREATE
  {
    userRoleId: UserRole.SUPER_ADMIN,
    entityType: USER_MEMBERSHIP_ENTITY_NAME,
    entityAction: CREATE_ACTION,
    permissionValue: true,
  },
  // EDIT
  {
    userRoleId: UserRole.SUPER_ADMIN,
    entityType: USER_MEMBERSHIP_ENTITY_NAME,
    entityAction: EDIT_ACTION,
    permissionValue: true,
  },
  // DELETE
  {
    userRoleId: UserRole.SUPER_ADMIN,
    entityType: USER_MEMBERSHIP_ENTITY_NAME,
    entityAction: DELETE_ACTION,
    permissionValue: true,
  },

  // -------- SITE_ENTITY_NAME --------
  // CREATE
  {
    userRoleId: UserRole.SUPER_ADMIN,
    entityType: SITE_ENTITY_NAME,
    entityAction: CREATE_ACTION,
    permissionValue: true,
  },
  {
    userRoleId: UserRole.ADMIN,
    entityType: SITE_ENTITY_NAME,
    entityAction: CREATE_ACTION,
    permissionValue: true,
  },
  // EDIT
  {
    userRoleId: UserRole.SUPER_ADMIN,
    entityType: SITE_ENTITY_NAME,
    entityAction: EDIT_ACTION,
    permissionValue: true,
  },
  {
    userRoleId: UserRole.ADMIN,
    entityType: SITE_ENTITY_NAME,
    entityAction: EDIT_ACTION,
    permissionValue: true,
  },
  // DELETE
  {
    userRoleId: UserRole.SUPER_ADMIN,
    entityType: SITE_ENTITY_NAME,
    entityAction: DELETE_ACTION,
    permissionValue: true,
  },
  {
    userRoleId: UserRole.ADMIN,
    entityType: SITE_ENTITY_NAME,
    entityAction: DELETE_ACTION,
    permissionValue: true,
  },
  // MISC:
  {
    userRoleId: UserRole.SUPER_ADMIN,
    entityType: SITE_ENTITY_NAME,
    entityAction: LIST_ACTION,
    permissionValue: true,
  },
  {
    userRoleId: UserRole.ADMIN,
    entityType: SITE_ENTITY_NAME,
    entityAction: LIST_ACTION,
    permissionValue: true,
  },

  // -------- ENTITY_LIB.ASSET_GROUP --------
  // CREATE
  {
    userRoleId: UserRole.SUPER_ADMIN,
    entityType: ENTITY_LIB.ASSET_GROUP,
    entityAction: CREATE_ACTION,
    permissionValue: true,
  },
  {
    userRoleId: UserRole.ADMIN,
    entityType: ENTITY_LIB.ASSET_GROUP,
    entityAction: CREATE_ACTION,
    permissionValue: true,
  },
  // SHOW
  {
    userRoleId: UserRole.ALL_USERS,
    entityType: ENTITY_LIB.ASSET_GROUP,
    entityAction: SHOW_ACTION,
    permissionValue: true,
  },
  // EDIT
  {
    userRoleId: UserRole.SUPER_ADMIN,
    entityType: ENTITY_LIB.ASSET_GROUP,
    entityAction: EDIT_ACTION,
    permissionValue: true,
  },
  {
    userRoleId: UserRole.ADMIN,
    entityType: ENTITY_LIB.ASSET_GROUP,
    entityAction: EDIT_ACTION,
    permissionValue: true,
  },
  // DELETE
  {
    userRoleId: UserRole.SUPER_ADMIN,
    entityType: ENTITY_LIB.ASSET_GROUP,
    entityAction: DELETE_ACTION,
    permissionValue: true,
  },
  {
    userRoleId: UserRole.ADMIN,
    entityType: ENTITY_LIB.ASSET_GROUP,
    entityAction: DELETE_ACTION,
    permissionValue: true,
  },

  // -------- ENTITY_LIB.GROUP_CONFIGURATION_NOTIFY_USERS --------
  // CREATE
  {
    userRoleId: UserRole.ALL_USERS,
    entityType: ENTITY_LIB.GROUP_CONFIGURATION_NOTIFY_USERS,
    entityAction: CREATE_ACTION,
    permissionValue: true,
  },
  // SHOW
  {
    userRoleId: UserRole.ALL_USERS,
    entityType: ENTITY_LIB.GROUP_CONFIGURATION_NOTIFY_USERS,
    entityAction: SHOW_ACTION,
    permissionValue: true,
  },
  // EDIT
  {
    userRoleId: UserRole.ALL_USERS,
    entityType: ENTITY_LIB.GROUP_CONFIGURATION_NOTIFY_USERS,
    entityAction: EDIT_ACTION,
    permissionValue: true,
  },
  // DELETE
  {
    userRoleId: UserRole.SUPER_ADMIN,
    entityType: ENTITY_LIB.GROUP_CONFIGURATION_NOTIFY_USERS,
    entityAction: DELETE_ACTION,
    permissionValue: true,
  },

  // -------- GROUP_CONFIGURATION_FILTER_RESULTS --------
  // OTHER ACTIONS
  {
    userRoleId: UserRole.ALL_USERS,
    entityType: GROUP_CONFIGURATION_FILTER_RESULTS,
    entityAction: REFRESH_GROUP_FILTER_RESULTS_ACTION,
    permissionValue: true,
  },

  // -------- ENTITY_LIB.ASSET_INVENTORY_ITEM --------
  {
    userRoleId: UserRole.ALL_USERS,
    entityType: ENTITY_LIB.ASSET_INVENTORY_ITEM,
    entityAction: SHOW_ACTION,
    permissionValue: true,
  },
  // CREATE
  {
    userRoleId: UserRole.SUPER_ADMIN,
    entityType: ENTITY_LIB.ASSET_INVENTORY_ITEM,
    entityAction: CREATE_ACTION,
    permissionValue: true,
  },
  {
    userRoleId: UserRole.ADMIN,
    entityType: ENTITY_LIB.ASSET_INVENTORY_ITEM,
    entityAction: CREATE_ACTION,
    permissionValue: true,
  },
  // EDIT
  {
    userRoleId: UserRole.ADMIN,
    entityType: ENTITY_LIB.ASSET_INVENTORY_ITEM,
    entityAction: EDIT_ACTION,
    permissionValue: true,
  },
  {
    userRoleId: UserRole.SUPER_ADMIN,
    entityType: ENTITY_LIB.ASSET_INVENTORY_ITEM,
    entityAction: EDIT_ACTION,
    permissionValue: true,
  },

  // DELETE
  {
    userRoleId: UserRole.SUPER_ADMIN,
    entityType: ENTITY_LIB.ASSET_INVENTORY_ITEM,
    entityAction: DELETE_ACTION,
    permissionValue: true,
  },
  {
    userRoleId: UserRole.ADMIN,
    entityType: ENTITY_LIB.ASSET_INVENTORY_ITEM,
    entityAction: DELETE_ACTION,
    permissionValue: true,
  },
  // OTHER ACTIONS
  {
    userRoleId: UserRole.ALL_USERS,
    entityType: ENTITY_LIB.ASSET_INVENTORY_ITEM,
    entityAction: ACTION_NAVIGATE_TO_ASSET,
    permissionValue: true,
  },
  {
    userRoleId: UserRole.ALL_USERS,
    entityType: ENTITY_LIB.ASSET_INVENTORY_ITEM,
    entityAction: VIEW_LOCATION_ACTION,
    permissionValue: true,
  },
  {
    userRoleId: UserRole.ALL_USERS,
    entityType: ENTITY_LIB.ASSET_INVENTORY_ITEM,
    entityAction: NAVIGATE_TO_ALL_ASSET_ALERTS_ACTION,
    permissionValue: true,
  },

  // -------- ALERT_ENTITY_NAME --------
  // OTHER ACTIONS
  {
    userRoleId: UserRole.ALL_USERS,
    entityType: ALERT_ENTITY_NAME,
    entityAction: NAVIGATE_TO_ALL_ALERTS,
    permissionValue: true,
  },
  {
    userRoleId: UserRole.ALL_USERS,
    entityType: ALERT_ENTITY_NAME,
    entityAction: ACTION_NAVIGATE_TO_ASSET,
    permissionValue: true,
  },
  {
    userRoleId: UserRole.ALL_USERS,
    entityType: ALERT_ENTITY_NAME,
    entityAction: ACKNOWLEDGE_ALERT_ACTION,
    permissionValue: true,
  },
  {
    userRoleId: UserRole.ALL_USERS,
    entityType: ALERT_ENTITY_NAME,
    entityAction: ACTION_ALERT_ACTION,
    permissionValue: true,
  },

  // -------- SAMPLEPOINT_ENTITY_NAME --------
  // CREATE
  {
    userRoleId: UserRole.ADMIN,
    entityType: SAMPLEPOINT_ENTITY_NAME,
    entityAction: CREATE_ACTION,
    permissionValue: true,
  },
  {
    userRoleId: UserRole.SUPER_ADMIN,
    entityType: SAMPLEPOINT_ENTITY_NAME,
    entityAction: CREATE_ACTION,
    permissionValue: true,
  },
  // SHOW
  {
    userRoleId: UserRole.USER,
    entityType: SAMPLEPOINT_ENTITY_NAME,
    entityAction: SHOW_ACTION,
    permissionValue: true,
  },
  // EDIT
  {
    userRoleId: UserRole.ADMIN,
    entityType: SAMPLEPOINT_ENTITY_NAME,
    entityAction: EDIT_ACTION,
    permissionValue: true,
  },
  {
    userRoleId: UserRole.SUPER_ADMIN,
    entityType: SAMPLEPOINT_ENTITY_NAME,
    entityAction: EDIT_ACTION,
    permissionValue: true,
  },
  // DELETE
  {
    userRoleId: UserRole.SUPER_ADMIN,
    entityType: SAMPLEPOINT_ENTITY_NAME,
    entityAction: DELETE_ACTION,
    permissionValue: true,
  },
  // OTHER ACTIONS
  {
    userRoleId: UserRole.SUPER_ADMIN,
    entityType: SAMPLEPOINT_ENTITY_NAME,
    entityAction: SAMPLEPOINT_SENSOR_PAIRING_ACTION,
    permissionValue: true,
  },

  // -------- SAMPLEPOINT_SENSOR_PAIRING_ENTITY --------
  // CREATE
  {
    userRoleId: UserRole.SUPER_ADMIN,
    entityType: SAMPLEPOINT_SENSOR_PAIRING_ENTITY,
    entityAction: CREATE_ACTION,
    permissionValue: true,
  },
  // SHOW
  {
    userRoleId: UserRole.ADMIN,
    entityType: SAMPLEPOINT_SENSOR_PAIRING_ENTITY,
    entityAction: SHOW_ACTION,
    permissionValue: true,
  },
  // EDIT
  {
    userRoleId: UserRole.SUPER_ADMIN,
    entityType: SAMPLEPOINT_SENSOR_PAIRING_ENTITY,
    entityAction: EDIT_ACTION,
    permissionValue: true,
  },
  // DELETE
  {
    userRoleId: UserRole.SUPER_ADMIN,
    entityType: SAMPLEPOINT_SENSOR_PAIRING_ENTITY,
    entityAction: DELETE_ACTION,
    permissionValue: true,
  },

  // -------- MONITOR_ENTITY_NAME --------
  // SHOW
  {
    userRoleId: UserRole.ADMIN,
    entityType: MONITOR_ENTITY_NAME,
    entityAction: SHOW_ACTION,
    permissionValue: true,
  },
  // EDIT
  {
    userRoleId: UserRole.SUPER_ADMIN,
    entityType: MONITOR_ENTITY_NAME,
    entityAction: EDIT_ACTION,
    permissionValue: true,
  },
  // DELETE
  {
    userRoleId: UserRole.SUPER_ADMIN,
    entityType: MONITOR_ENTITY_NAME,
    entityAction: DELETE_ACTION,
    permissionValue: true,
  },
  // MONITOR_ENTITY_NAME - OTHER ACTIONS
  {
    userRoleId: UserRole.ALL_USERS,
    entityType: MONITOR_ENTITY_NAME,
    entityAction: MONITOR_SENSOR_LIST_ACTION,
    permissionValue: true,
  },
  {
    userRoleId: UserRole.ALL_USERS,
    entityType: MONITOR_ENTITY_NAME,
    entityAction: MONITOR_CONFIGURATION_LIST_ACTION,
    permissionValue: true,
  },
  {
    userRoleId: UserRole.ALL_USERS,
    entityType: MONITOR_ENTITY_NAME,
    entityAction: MONITOR_PACKET_LOG_LIST_ACTION,
    permissionValue: true,
  },
  {
    userRoleId: UserRole.ALL_USERS,
    entityType: MONITOR_ENTITY_NAME,
    entityAction: MONITOR_SUMMARY_ACTION,
    permissionValue: true,
  },

  // -------- MONITOR_CONFIGURATION_ENTITY_NAME --------
  // OTHER ACTIONS
  {
    userRoleId: UserRole.ALL_USERS,
    entityType: MONITOR_CONFIGURATION_ENTITY_NAME,
    entityAction: MONITOR_SENSOR_LIST_ACTION,
    permissionValue: true,
  },

  // -------- PACKET_LOG_ENTITY_NAME --------
  // SHOW
  {
    userRoleId: UserRole.ALL_USERS,
    entityType: PACKET_LOG_ENTITY_NAME,
    entityAction: SHOW_ACTION,
    permissionValue: true,
  },
  // OTHER ACTIONS
  {
    userRoleId: UserRole.SUPER_ADMIN,
    entityType: PACKET_LOG_ENTITY_NAME,
    entityAction: REQUEST_PACKET_REPROCESSING,
    permissionValue: true,
  },

  // -------- SENSOR_ENTITY_NAME --------
  // SHOW
  {
    userRoleId: UserRole.ADMIN,
    entityType: SENSOR_ENTITY_NAME,
    entityAction: SHOW_ACTION,
    permissionValue: true,
  },
  // EDIT
  {
    userRoleId: UserRole.SUPER_ADMIN,
    entityType: SENSOR_ENTITY_NAME,
    entityAction: EDIT_ACTION,
    permissionValue: true,
  },
  // DELETE
  {
    userRoleId: UserRole.SUPER_ADMIN,
    entityType: SENSOR_ENTITY_NAME,
    entityAction: DELETE_ACTION,
    permissionValue: true,
  },
  // OTHER
  {
    userRoleId: UserRole.ALL_USERS,
    entityType: SENSOR_ENTITY_NAME,
    entityAction: SENSOR_GRAPH_ACTION,
    permissionValue: true,
  },

  // -------- ENTITY_LIB.TRIGGER --------
  // CREATE
  {
    userRoleId: UserRole.SUPER_ADMIN,
    entityType: ENTITY_LIB.TRIGGER,
    entityAction: CREATE_ACTION,
    permissionValue: true,
  },
  {
    userRoleId: UserRole.ADMIN,
    entityType: ENTITY_LIB.TRIGGER,
    entityAction: CREATE_ACTION,
    permissionValue: true,
  },
  // SHOW
  {
    userRoleId: UserRole.SUPER_ADMIN,
    entityType: ENTITY_LIB.TRIGGER,
    entityAction: SHOW_ACTION,
    permissionValue: true,
  },
  {
    userRoleId: UserRole.ADMIN,
    entityType: ENTITY_LIB.TRIGGER,
    entityAction: SHOW_ACTION,
    permissionValue: true,
  },
  // EDIT
  {
    userRoleId: UserRole.SUPER_ADMIN,
    entityType: ENTITY_LIB.TRIGGER,
    entityAction: EDIT_ACTION,
    permissionValue: true,
  },
  {
    userRoleId: UserRole.ADMIN,
    entityType: ENTITY_LIB.TRIGGER,
    entityAction: EDIT_ACTION,
    permissionValue: true,
  },
  // DELETE
  {
    userRoleId: UserRole.SUPER_ADMIN,
    entityType: ENTITY_LIB.TRIGGER,
    entityAction: DELETE_ACTION,
    permissionValue: true,
  },
  {
    userRoleId: UserRole.ADMIN,
    entityType: ENTITY_LIB.TRIGGER,
    entityAction: DELETE_ACTION,
    permissionValue: true,
  },
  // OTHER
  {
    userRoleId: UserRole.SUPER_ADMIN,
    entityType: ENTITY_LIB.TRIGGER,
    entityAction: SENSOR_GRAPH_ACTION,
    permissionValue: true,
  },
  {
    userRoleId: UserRole.ADMIN,
    entityType: ENTITY_LIB.TRIGGER,
    entityAction: SENSOR_GRAPH_ACTION,
    permissionValue: true,
  },

  // -------- ENTITY_LIB.TRIGGER_CONDITION --------
  // CREATE
  {
    userRoleId: UserRole.SUPER_ADMIN,
    entityType: ENTITY_LIB.TRIGGER_CONDITION,
    entityAction: CREATE_ACTION,
    permissionValue: true,
  },
  {
    userRoleId: UserRole.ADMIN,
    entityType: ENTITY_LIB.TRIGGER_CONDITION,
    entityAction: CREATE_ACTION,
    permissionValue: true,
  },
  // SHOW
  {
    userRoleId: UserRole.SUPER_ADMIN,
    entityType: ENTITY_LIB.TRIGGER_CONDITION,
    entityAction: SHOW_ACTION,
    permissionValue: true,
  },
  {
    userRoleId: UserRole.ADMIN,
    entityType: ENTITY_LIB.TRIGGER_CONDITION,
    entityAction: SHOW_ACTION,
    permissionValue: true,
  },
  // EDIT
  {
    userRoleId: UserRole.SUPER_ADMIN,
    entityType: ENTITY_LIB.TRIGGER_CONDITION,
    entityAction: EDIT_ACTION,
    permissionValue: true,
  },
  {
    userRoleId: UserRole.ADMIN,
    entityType: ENTITY_LIB.TRIGGER_CONDITION,
    entityAction: EDIT_ACTION,
    permissionValue: true,
  },
  // DELETE
  {
    userRoleId: UserRole.SUPER_ADMIN,
    entityType: ENTITY_LIB.TRIGGER_CONDITION,
    entityAction: DELETE_ACTION,
    permissionValue: true,
  },
  {
    userRoleId: UserRole.ADMIN,
    entityType: ENTITY_LIB.TRIGGER_CONDITION,
    entityAction: DELETE_ACTION,
    permissionValue: true,
  },

  // -------- ENTITY_LIB.TRIGGER_TEMPLATE --------
  // CREATE
  {
    userRoleId: UserRole.SUPER_ADMIN,
    entityType: ENTITY_LIB.TRIGGER_TEMPLATE,
    entityAction: CREATE_ACTION,
    permissionValue: true,
  },
  // SHOW
  {
    userRoleId: UserRole.SUPER_ADMIN,
    entityType: ENTITY_LIB.TRIGGER_TEMPLATE,
    entityAction: SHOW_ACTION,
    permissionValue: true,
  },
  // EDIT
  {
    userRoleId: UserRole.SUPER_ADMIN,
    entityType: ENTITY_LIB.TRIGGER_TEMPLATE,
    entityAction: EDIT_ACTION,
    permissionValue: true,
  },
  // DELETE
  {
    userRoleId: UserRole.SUPER_ADMIN,
    entityType: ENTITY_LIB.TRIGGER_TEMPLATE,
    entityAction: DELETE_ACTION,
    permissionValue: true,
  },
  // OTHER
  {
    userRoleId: UserRole.SUPER_ADMIN,
    entityType: ENTITY_LIB.TRIGGER_TEMPLATE,
    entityAction: SENSOR_GRAPH_ACTION,
    permissionValue: true,
  },

  // -------- ENTITY_LIB.TRIGGER_CONDITION_TEMPLATE --------
  // CREATE
  {
    userRoleId: UserRole.SUPER_ADMIN,
    entityType: ENTITY_LIB.TRIGGER_CONDITION_TEMPLATE,
    entityAction: CREATE_ACTION,
    permissionValue: true,
  },
  // SHOW
  {
    userRoleId: UserRole.SUPER_ADMIN,
    entityType: ENTITY_LIB.TRIGGER_CONDITION_TEMPLATE,
    entityAction: SHOW_ACTION,
    permissionValue: true,
  },
  // EDIT
  {
    userRoleId: UserRole.SUPER_ADMIN,
    entityType: ENTITY_LIB.TRIGGER_CONDITION_TEMPLATE,
    entityAction: EDIT_ACTION,
    permissionValue: true,
  },
  // DELETE
  {
    userRoleId: UserRole.SUPER_ADMIN,
    entityType: ENTITY_LIB.TRIGGER_CONDITION_TEMPLATE,
    entityAction: DELETE_ACTION,
    permissionValue: true,
  },
];
