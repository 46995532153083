import React, { useEffect } from "react";
import { DataSourceParamsType } from "../../components/Crud/DataProvider/DataProviderCommon.d";
import { useDatasetManager } from "../../components/Crud/DataProvider/DatasetManager";
import { QueryEventAction } from "../../components/Crud/DataProvider/DataQuery.d";
import { isSpecified } from "components/Utils/MiscUtils";
import { SORT_ASC, UnifiedDataRequestType } from "components/Utils/CRUDUtils.d";
import { FetchlessFormikEntityForm } from "../../components/Crud/Form/FetchlessFormikEntityForm";
import { CRUD_DISPATCHER } from "../../components/Crud/DataProvider/DataCrud";
import { EventDispatchersType } from "../../components/Crud/Table/StatelessTable.d";
import { generateCreateButtonConfig } from "components/Buttons/ButtonsCommon";
import { ENTITY_LIB } from "components/Utils/CRUDEntityList";
import { ActionButtonHandlerComponent } from "components/Buttons/ActionButtonHandlerComponent";
import SettingsIcon from "@material-ui/icons/Settings";
import ActionDelete from "@material-ui/icons/Delete";
import { EDIT_ACTION, DELETE_ACTION } from "components/Crud/Entity.const";
import { CrudEventAction } from "components/Crud/DataProvider/DataCrud.d";
import { API_LIB } from "components/Utils/API_LIB";
import { TriggerTemplateCard } from "./TriggerTemplateCard";
import RefreshIcon from "@material-ui/icons/Refresh";
import IconButton from "@material-ui/core/IconButton";
import { ScreenTitle } from "components/Elements/Typography/ScreenTitle";
import { FlexSpaceFiller } from "layouts/FlexSpaceFiller";
import { triggerTemplateFormFields } from "./TriggerTemplate.formFields";
import { usePopupOperationController } from "popups/usePopupOperationController";
import { OnClickDialog, OnClickDispatchEvent } from "components/Buttons/Buttons.d";

// export interface TemporaryTriggerListProps {
//   assetId: ObjectIdType;
// }

const dataSource: DataSourceParamsType = {
  // apiUrl: '/asset',
  apiUrl: API_LIB.TRIGGER_TEMPLATE,
};

const initialRequest: UnifiedDataRequestType = {
  // fieldList: [
  //   'name',
  //   'assetTypeId',
  //   'siteId',
  // ],
  filter: {
    // baseFilters: [
    //   filterBy('siteId', '=', 2)
    // ]
  },
  isPaginationEnabled: true,
  rowsPerPage: 10,
  pageNum: 0,
  sortByColumn: "name",
  sortDirection: SORT_ASC,
};

export function TriggerTemplateList(/* props: TemporaryTriggerListProps */) {
  const [dataset, dispatchRequest] = useDatasetManager(dataSource);

  // const assetFormDataSource = { apiUrl: API_LIB.ASSET_INVENTORY_LIST };

  // Bits copied from DataCrudEntityFormBinding
  const eventDispatchersForForm: EventDispatchersType = {};
  eventDispatchersForForm[CRUD_DISPATCHER] = dispatchRequest;

  // //
  // let operationResult = {
  //   operationIdentifier: crudStatus.action,
  //   successFlag: (crudStatus.state === DataCrudOperationStatus.APPLIED_SUCCESSFULLY)
  // };

  const TriggerEditForm = (props: any) => (
    <FetchlessFormikEntityForm
      // tempOperationResult={operationResult}
      // triggerCloseOnSuccesOf={[CrudEventAction.CREATE, CrudEventAction.UPDATE, CrudEventAction.DELETE]}
      formData={props.dataObject}
      id={props.id}
      action={props.action}
      title={props.title}
      isDataLoading={false} //{crudStatus.state === DataCrudOperationStatus.EXECUTING}
      fieldConfigurations={triggerTemplateFormFields}
      eventDispatchers={eventDispatchersForForm}
      onCloseAdditionalActions={props.onCloseAdditionalActions}
      showCloseButton={props.showCloseButton}
      userData={props.userData}
      currentEnterpriseId={props.currentEnterpriseId}
      layoutComponent={props.layoutComponent}
      conditionalFieldConfiguration={props.conditionalFieldConfiguration}
      formDataPreSubmitProcessor={props.formDataPreSubmitProcessor}
      doNotPreFetchData={true} //{props.doNotPreFetchData}
      warning={props.warning}
    />
  );

  const createTriggeButtonConfig = generateCreateButtonConfig(
    ENTITY_LIB.TRIGGER_TEMPLATE,
    "Add Trigger Template",
    TriggerEditForm,
    [],
    {
      title: "Add Trigger Template",
      // warning: warningValue
    }
  );

  const editTriggerButtonConfig = {
    actionCode: EDIT_ACTION,
    actionObject: ENTITY_LIB.TRIGGER_TEMPLATE,
    name: "edit",
    labelElement: <SettingsIcon fontSize="small" />,
    onClick: {
      type: "dialog",
      dialogId: TriggerEditForm,
      onCloseCallbacks: [] as Array<() => void>,
      extraPropsObject: {
        title: "Trigger Template Edit",
        // warning: warningValue,
      },
    } as OnClickDialog,
    doNotGroup: true,
  };

  const deleteTriggerButtonConfig = {
    actionObject: ENTITY_LIB.TRIGGER_TEMPLATE,
    actionCode: DELETE_ACTION,
    name: "delete",
    labelElement: <ActionDelete fontSize="small" />,
    onClick: {
      type: "dispatchEvent",
      dispatcherName: CRUD_DISPATCHER,
      action: CrudEventAction.DELETE,
    } as OnClickDispatchEvent,
    confirm: { message: "Are you sure you want to delete this?", dialogButton: "Delete" },
  };

  const operationResult = dataset.lastOperationResult;
  usePopupOperationController(operationResult);

  useEffect(() => {
    dispatchRequest({
      action: QueryEventAction.REQUEST_DATA,
      payload: initialRequest,
      originator: "Initial load of Trigger Template Data",
    });
  }, [dispatchRequest]);

  return (
    <div>
      <div style={{ display: "flex" }}>
        <ScreenTitle>Trigger Templates</ScreenTitle>
        <FlexSpaceFiller />
        <IconButton
          onClick={() =>
            dispatchRequest({
              action: QueryEventAction.REQUEST_DATA,
              payload: initialRequest,
              originator: "TriggerTemplate - refresh content button",
            })
          }
        >
          <RefreshIcon />
        </IconButton>
        <ActionButtonHandlerComponent
          buttonConfig={createTriggeButtonConfig}
          id={null}
          object={null}
          eventDispatchers={eventDispatchersForForm}
        />
      </div>
      <p style={{ color: "red" }}>
        {dataset.lastOperationResult &&
        !dataset.lastOperationResult.isSuccess &&
        !isSpecified(dataset.lastOperationResult.relatedId)
          ? dataset.lastOperationResult.message
          : ""}
      </p>
      {dataset.dataArray.map((arrayItem: any, index: number) => {
        return (
          <TriggerTemplateCard
            key={index}
            actionsSet={[editTriggerButtonConfig, deleteTriggerButtonConfig]}
            id={arrayItem.id}
            name={arrayItem.name}
            dataObject={arrayItem}
            errorMessage={
              dataset.lastOperationResult &&
              dataset.lastOperationResult &&
              dataset.lastOperationResult.relatedId === arrayItem.id
                ? dataset.lastOperationResult.message
                : ""
            }
            eventDispatchers={eventDispatchersForForm}
          />
        );
      })}
    </div>
  );
}
