import React from "react";
import { DataCrud, CRUD_DISPATCHER } from "../DataProvider/DataCrud";
import {
  DataCrudDataType,
  DataCrudEventType,
  DataCrudOperationStatusType,
  DataCrudOperationStatus,
  CrudEventAction,
} from "../DataProvider/DataCrud.d";
import { isSpecified } from "components/Utils/MiscUtils";
import { DataSourceParamsType } from "../DataProvider/DataProviderCommon.d";
import { ObjectIdType } from "components/Utils/Object.d";
import { EntityActionType } from "../Entity.d";
import { FieldConfiguration } from "./Fields/FieldConfiguration.d";
import { EnterpriseIdType } from "views/Enterprise/Enterprise.d";
import { UserParams } from "views/User/UserParams.d";
import { FetchlessFormikEntityForm } from "./FetchlessFormikEntityForm";
import { FormLayoutProps } from "./Layout/FormLayout.d";
import { EventDispatchersType } from "../Table/StatelessTable.d";
import { OnCloseFormCallbackType, ConditionalFieldConfigurationFunctionType } from "./EntityForm.d";

export interface DataCrudEntityFormBindingProps {
  // Data Crud Props
  dataSource: DataSourceParamsType;
  id?: ObjectIdType;
  externalTriggerValues?: Array<any>;

  // Fetchless Entity Form Props
  title?: string;
  action: EntityActionType;
  fieldConfigurations: FieldConfiguration[];
  onCloseAdditionalActions?: OnCloseFormCallbackType; // Callback to be executed after closing the form
  showCloseButton: boolean;
  currentEnterpriseId: EnterpriseIdType;
  userData: UserParams;
  enableEditSafetySwitch?: boolean;

  layoutComponent?: (props: FormLayoutProps) => JSX.Element;

  conditionalFieldConfiguration?: ConditionalFieldConfigurationFunctionType;
  formDataPreSubmitProcessor?: (object: Record<string, any>) => Record<string, any>;
  doNotPreFetchData?: boolean;
}

export function DataCrudEntityFormBinding(props: DataCrudEntityFormBindingProps) {
  return (
    <DataCrud
      dataSource={props.dataSource}
      render={(
        crudData: DataCrudDataType,
        crudRequest: DataCrudEventType,
        crudStatus: DataCrudOperationStatusType,
        dispatchCrudEvent: (event: DataCrudEventType) => void,
        activeTransactionId: number
      ) => {
        // console.log('DataCrudEntityFormBinding::internal functions running -------------------------------------------');
        // console.log('DataCrudEntityFormBinding::internal functions running - activeTransactionId', activeTransactionId);
        // console.log('DataCrudEntityFormBinding::internal functions running - crudStatus', JSON.stringify(crudStatus));
        // console.log('DataCrudEntityFormBinding::internal functions running - crudRequest', JSON.stringify(crudRequest));
        // console.log('DataCrudEntityFormBinding::internal functions running - data.transactionId', JSON.stringify(crudData.transactionId));

        const eventDispatchers: EventDispatchersType = {};
        eventDispatchers[CRUD_DISPATCHER] = dispatchCrudEvent;
        const id = isSpecified(crudData.data) ? crudData.data.id : props.id;

        const operationResult = {
          operationIdentifier: crudStatus.action,
          successFlag: crudStatus.state === DataCrudOperationStatus.APPLIED_SUCCESSFULLY,
        };

        return (
          <FetchlessFormikEntityForm
            tempOperationResult={operationResult}
            triggerCloseOnSuccesOf={[CrudEventAction.CREATE, CrudEventAction.UPDATE, CrudEventAction.DELETE]}
            formData={crudData.data}
            id={id}
            action={props.action}
            title={props.title}
            isDataLoading={crudStatus.state === DataCrudOperationStatus.EXECUTING}
            fieldConfigurations={props.fieldConfigurations}
            eventDispatchers={eventDispatchers}
            onCloseAdditionalActions={props.onCloseAdditionalActions}
            showCloseButton={props.showCloseButton}
            userData={props.userData}
            currentEnterpriseId={props.currentEnterpriseId}
            layoutComponent={props.layoutComponent}
            conditionalFieldConfiguration={props.conditionalFieldConfiguration}
            formDataPreSubmitProcessor={props.formDataPreSubmitProcessor}
            doNotPreFetchData={props.doNotPreFetchData}
          />
        );
      }}
    />
  );
}
