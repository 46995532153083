import { StyleRulesCallback, Theme } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";

export const graphTableWidgetStyle: StyleRulesCallback<Theme, {}> = (theme) => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  expansionPanelRoot: {
    backgroundColor: grey[50],
    height: "40px",
    minHeight: "40px",
    "&$expanded": {
      minHeight: "40px",
    },
    padding: 0,
  },
  content: {
    height: "40px",
    margin: 0,
    minHeight: "40px",
    display: "flex",
    alignItems: "center",
    paddingLeft: "16px",
  },
  expanded: {
    height: "40px",
    minHeight: "40px",
    boxShadow: "0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2)",
    "& > *": {
      boxShadow: "none",
    },
  },
  detailsRoot: {
    padding: 0,
  },
  tableHead: {
    display: "flex",
    height: "40px",
  },
  tableHeadCell: {
    display: "flex",
    alignItems: "center",
    boxSizing: "border-box",
    paddingLeft: "16px",
    paddingRight: "16px",
  },
  tableContent: {
    height: "300px",
    overflowY: "auto",
    flexGrow: 1,
  },
  tableRow: {
    display: "flex",
    boxShadow: "inset 0 -1px 0 0 #EEEEEE",
    height: "40px",
    alignItems: "center",
  },
  tableCell: {
    boxSizing: "border-box",
    paddingLeft: "16px",
    paddingRight: "16px",
    whiteSpace: "nowrap",
  },
  expandedTableWrapper: {
    width: "100%",
  },
});
