import React from "react";
import { MainPanelWithParamsProps } from "components/Dashboard/MainPanelWithParams.d";
import { AssetInventory } from "./AssetInventory";
import { parseSearchStringIntoScreenRequest } from "components/Utils/RouteUtils";
import { useSimpleAsyncData } from "components/Crud/DataProvider/useSimpleAsyncData";
import { API_LIB } from "components/Utils/API_LIB";
import { getAttributeByPath } from "components/Crud/EntityUtils";

export interface AssetInventoryScreenProps {
  groupId?: number;
}

export function AssetInventoryScreen(
  props: MainPanelWithParamsProps<AssetInventoryScreenProps> & AssetInventoryScreenProps
) {
  const requestFromUrl = parseSearchStringIntoScreenRequest(props.location);

  const groupNameQuery = props.groupId ? `${API_LIB.ASSET_GROUP}/${props.groupId}` : null;
  const groupTitleRequestData = useSimpleAsyncData(groupNameQuery);

  let screenTitle: string;

  if (!props.groupId) {
    screenTitle = "All Assets";
  } else if (groupTitleRequestData.isLoading) {
    screenTitle = "Loading group name...";
  } else {
    screenTitle = getAttributeByPath(groupTitleRequestData, "data.name");
  }

  return (
    <AssetInventory
      title={screenTitle}
      assetGroupId={props.groupId}
      userData={props.userData}
      currentEnterpriseId={props.currentEnterpriseId}
      inputRequest={requestFromUrl.request}
      allowUrlReplacementForFilters={true}
      allowReinitialization={true}
    />
  );
}
