import React from "react";
import { SORT_ASC, UnifiedDataRequestType, FilterMatchTypes } from "components/Utils/CRUDUtils.d";
import { SITE_API_URL, SITE_ENTITY_NAME } from "./Site.const";
import { ALL_ENTERPRISES_CODE, ENTERPRISE_API_URL } from "views/Enterprise/Enterprise.const";
import { translateValue, filterBy } from "components/Utils/MiscUtils";
import { BaseListProps, TableColumnParameters, TableColumnFilter } from "components/Crud/Table/EntityTable.d";
import {
  generateEditButtonConfig,
  generateShowButtonConfig,
  generateCreateButtonConfig,
  generateDeleteButtonConfig,
} from "components/Buttons/ButtonsCommon";
import { DataSourceParamsType } from "components/Crud/DataProvider/DataProviderCommon.d";
import { CrudQueryTableBinding } from "components/Crud/Table/CrudQueryTableBinding";
import { useVersion } from "components/Utils/CustomHooks";
import { SiteEditForm } from "./SiteEditForm";
import { refineRequest, getEntityListRoute } from "components/Utils/RouteUtils";
import { mergeRecords } from "components/Utils/ObjectUtils";

import { ACTION_NAVIGATE_TO_ASSET } from "views/asset/Asset/Asset.const";
import { ENTITY_LIB } from "components/Utils/CRUDEntityList";
import { TableActionButtonFace } from "components/Buttons/TableActionButtonFace";
import { ROUTE_LIB } from "components/Utils/RouteList";
import { ReactComponent as AssetIcon } from "assets/icons/ProfessionalSet/Asset.svg";

export function SiteList(props: BaseListProps) {
  const [version, increaseVersion] = useVersion();

  const tableColumns: TableColumnParameters[] = [
    {
      sourceName: "enterprise.name",
      label: "Enterprise",
      hideForBreakpoints: { mdDown: true },
      hide: props.currentEnterpriseId !== ALL_ENTERPRISES_CODE,
    },
    {
      sourceName: "name",
      label: "Name",
      width: 300,
    },
    {
      disableSorting: true,
      actionSet: [
        {
          labelElement: (
            <TableActionButtonFace>
              <AssetIcon />
              &nbsp;<span>Site Assets</span>
            </TableActionButtonFace>
          ),
          name: "assets",
          onClick: {
            type: "navigate",
            url: getEntityListRoute(ROUTE_LIB.ASSET_INVENTORY),
            // paramName : 'samplePointId',
            urlSearch: { siteId: { valueSource: "id" } },
          },
          actionCode: ACTION_NAVIGATE_TO_ASSET,
          actionObject: ENTITY_LIB.ASSET_INVENTORY_ITEM,
        },
        generateEditButtonConfig(SITE_ENTITY_NAME, SiteEditForm, [increaseVersion], {
          title: "Edit Site Details",
        }),
        generateShowButtonConfig(SITE_ENTITY_NAME, SiteEditForm, { title: "Show Site Details" }),
        generateDeleteButtonConfig(SITE_ENTITY_NAME),
      ],
    },
  ];

  const uiFilters: Array<TableColumnFilter> = [
    {
      disable: props.currentEnterpriseId !== ALL_ENTERPRISES_CODE,
      filterType: "select-async",
      filterParams: {
        optionDefinitionEndpoint: ENTERPRISE_API_URL,
        optionCodeField: "id",
        optionLabelField: "name",
        renderEmptyValueAs: "ALL ENTERPRISES",
        autoComplete: true,
        placeholder: "ALL ENTERPRISES",
      },
      filterDataSourceField: "enterpriseId", // Because column uses name, but filter uses id
    },
    {
      filterType: "text",
      filterDataSourceField: "name",
      filterMatchType: FilterMatchTypes.CONTAINS,
      filterParams: {
        placeholder: "ALL SITES",
      },
    },
  ];

  const dataSource: DataSourceParamsType = {
    apiUrl: SITE_API_URL,
  };

  let initialRequest: UnifiedDataRequestType = {
    sortByColumn: "name",
    sortDirection: SORT_ASC,
    fieldList: ["name", "enterprise.name"],
    filter: {
      baseFilters: [
        filterBy(
          "enterpriseId",
          "=",
          translateValue(props.currentEnterpriseId, [[ALL_ENTERPRISES_CODE, undefined]])
        ),
      ],
    },
    isPaginationEnabled: true,
    rowsPerPage: 10,
    pageNum: 0,
  };

  // to use URL defined query params
  const refinedRequestFromInput = refineRequest(props.inputRequest, uiFilters);
  initialRequest = mergeRecords(initialRequest, refinedRequestFromInput) as UnifiedDataRequestType;

  const headerActions = [
    generateCreateButtonConfig(SITE_ENTITY_NAME, "ADD SITE", SiteEditForm, [increaseVersion], {
      title: "Add Site",
    }),
  ];

  return (
    <CrudQueryTableBinding
      externalTriggerValues={[props.currentEnterpriseId, props.userData, version]}
      dataSource={dataSource}
      intialQuery={initialRequest}
      title="Sites"
      tableColumns={tableColumns}
      onRowClickFunction={props.onRowClickFunction}
      // actionsAllowed={allowedActions}
      highlightedRowsPrimaryKeys={props.activeRowPrimaryKeys}
      uiFilters={uiFilters}
      headerActions={headerActions}
      // to use URL defined query params
      allowUrlReplacementForFilters={props.allowUrlReplacementForFilters}
      allowReinitialization={props.allowReinitialization}
    />
  );
}
