import React from "react";
import { PortletCard } from "components/Cards/PortletCard";
import { PrimaryCardValue } from "components/Elements/Typography/PrimaryCardValue";
import { CardSubTitle } from "components/Elements/Typography/CardSubTitle";
import CloudOutlinedIcon from "@material-ui/icons/CloudOutlined";
import CloudIcon from "@material-ui/icons/Cloud";
import { StyleRulesCallback, Theme, makeStyles } from "@material-ui/core";

const weatherValueStyle: StyleRulesCallback<Theme, {}> = (theme) => ({
  root: {
    color: theme.palette.text.secondary,
    fontFamily: "SF Pro Display, sans-serif",
    fontSize: "13px",
    fontWeight: 500,
    lineHeight: "15px",
    height: "26px",
    textAlign: "center",
    // textAlign: 'center',
  },
});

const useStyles = makeStyles(weatherValueStyle, { name: "WVal" });

function WVal(props: { children: string }) {
  const classes = useStyles({});

  return <div className={classes.root}>{props.children}</div>;
}

export function LocalWeatherForecastDemoPortlet() {
  return (
    <PortletCard title="Local Weather Forecast">
      <div style={{ padding: "31px" }}>
        <div>
          <div style={{ height: "32px" }}>
            <CardSubTitle>RIGHT NOW</CardSubTitle>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <PrimaryCardValue>{"29°C"}</PrimaryCardValue>
            <div style={{ paddingLeft: "40px" }}>
              <CloudIcon htmlColor="#BBD9F0" style={{ height: "50px", width: "50px" }} />
            </div>
          </div>
        </div>
        <hr
          style={{
            marginTop: "32px",
            marginBottom: "28px",
            boxSizing: "border-box",
            border: "1px solid #e0e0e0",
          }}
        />
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <WVal>Mon</WVal>
            <div>
              <CloudOutlinedIcon color="primary" />
            </div>
            <WVal>24</WVal>
          </div>
          <div>
            <WVal>Tue</WVal>
            <div>
              <CloudOutlinedIcon color="primary" />
            </div>
            <WVal>24</WVal>
          </div>
          <div>
            <WVal>Wed</WVal>
            <div>
              <CloudOutlinedIcon color="primary" />
            </div>
            <WVal>24</WVal>
          </div>
          <div>
            <WVal>Thu</WVal>
            <div>
              <CloudOutlinedIcon color="primary" />
            </div>
            <WVal>24</WVal>
          </div>
          <div>
            <WVal>Fri</WVal>
            <div>
              <CloudOutlinedIcon color="primary" />
            </div>
            <WVal>24</WVal>
          </div>
        </div>
      </div>
    </PortletCard>
  );
}
