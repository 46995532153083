import React from "react";
import { makeStyles } from "@material-ui/core";
import { regularCardValueStyle } from "./regularCardValueStyle";
import clsx from "clsx";

export interface RegularCardValueProps {
  children: string | number;
  trend?: number;
}

const useStyles = makeStyles(regularCardValueStyle, { name: "RegularCardValue" });

export function RegularCardValue(props: RegularCardValueProps) {
  const classes = useStyles({});

  const className = clsx(classes.root, {
    [classes.trendUpward]: props.trend > 0,
    [classes.trendDownward]: props.trend < 0,
  });

  return <span className={className}>{props.children}</span>;
}
