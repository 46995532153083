import { StyleRulesCallback, Theme } from "@material-ui/core/styles";

export const screenSecondaryActionButtonFaceStyle: StyleRulesCallback<Theme, {}> = (theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "16px",
    paddingRight: "16px",
    boxSizing: "border-box",
    height: "36px",
    borderRadius: "4px",
    border: "1px solid #E0E0E0",
    backgroundColor: "transparent",
    "& span": {
      color: theme.palette.text.primary,
      textTransform: "uppercase",
    },
    "& svg": {
      color: theme.palette.text.secondary,
    },
  },
});
