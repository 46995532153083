import React, { useState, useEffect } from "react";
import { CHART_COLOR_MAP, DEFAULT_CHART_COLOR_KEY } from "components/Graph/GraphCommon";
import { endOfToday, dateMinusInterval, datePlusInterval, startOfToday } from "components/Utils/DateUtils";
import { ConditionalRender } from "layouts/ConditionalRender";
import { RowLayout } from "layouts/RowLayout";
import { RowWrapper } from "layouts/RowWrapper";
import { GraphComponentControls } from "./GraphComponentControls";
import { GraphComponentData } from "./GraphComponentData";
import { useMediaQueryMatchDown } from "components/Utils/LayoutUtils";
import { arrayETL } from "components/Utils/ObjectUtils";
import { CheckBoxGroupDefinitionType } from "components/Elements/CheckBoxGroup";
import { isSpecified, isNonEmptyArray } from "components/Utils/MiscUtils";
import {
  RequestedDataRangeType,
  GraphDataProviderDataType,
  IntervalOptions,
  GraphDataRequestParametersType,
  DataSourceDescriptorType,
  SampleEndpointResponse,
} from "./Graph.d";

interface GraphComponentProps {
  title: string | JSX.Element;
  isDataLoading: boolean;
  dataArray: any[];
  requestData: (graphDataRequest: RequestedDataRangeType) => void;
  dataProviders: Array<GraphDataProviderDataType>;
  timeZone?: string;
}

function GraphComponent(props: GraphComponentProps) {
  const isSmallScreen = useMediaQueryMatchDown("sm");

  const [selectedInterval, setSelectedInterval] = useState(IntervalOptions.TWO_WEEKS as IntervalOptions);

  const generateInitialRequest = (timeZone: string): RequestedDataRangeType => {
    const soToday = startOfToday(timeZone);
    const eoToday = endOfToday(timeZone);
    const initialDisplayedStartDate = dateMinusInterval(
      datePlusInterval(soToday, IntervalOptions.ONE_DAY, timeZone),
      IntervalOptions.TWO_WEEKS,
      timeZone
    );
    const initialDisplayedEndDate = eoToday;

    return {
      displayedRangeStartDate: initialDisplayedStartDate,
      displayedRangeEndDate: initialDisplayedEndDate,
      requestedRangeStartDate: dateMinusInterval(
        initialDisplayedStartDate,
        IntervalOptions.ONE_DAY,
        timeZone
      ),
      requestedRangeEndDate: datePlusInterval(initialDisplayedEndDate, IntervalOptions.ONE_DAY, timeZone),
    };
  };

  const [requestedRange, setRequestedRange] = useState(undefined as RequestedDataRangeType);

  const [displayedDataSourcesFlags, setDisplayedDataSourcesFlags] = useState([] as Array<boolean>);

  const resetDisplayedDataSourcesFlags = (inputDataProviders: Array<GraphDataProviderDataType>) => {
    setDisplayedDataSourcesFlags(inputDataProviders.map((datasetConfigItem) => true));
  };

  const handleGraphDataRequestChange = (newValues: GraphDataRequestParametersType) => {
    setSelectedInterval(newValues.definedInterval);
    setRequestedRange(newValues.requestedRange);
  };

  const assignColorsToDataSourceDescriptors = (
    inputDataSourceDescriptors: Array<DataSourceDescriptorType>
  ) => {
    // TODO: change to immutable
    // dataSourceDescriptors = mergeScalarArrayToObjectArray(dataSourceDescriptors, 'color', CHART_COLOR_PREFERENCE_TABLE) as DataSourceDescriptorType[];
    for (let i = 0; i < inputDataSourceDescriptors.length; i++) {
      inputDataSourceDescriptors[i].color = CHART_COLOR_MAP.get(inputDataSourceDescriptors[i].sampleTypeId);

      if (!isSpecified(inputDataSourceDescriptors[i].color)) {
        inputDataSourceDescriptors[i].color = CHART_COLOR_MAP.get(DEFAULT_CHART_COLOR_KEY);
      }
    }
  };

  const sanitizedDataArray = props.dataArray.filter(
    (item: SampleEndpointResponse, index: number) => displayedDataSourcesFlags[index]
  );

  const dataSourceDescriptors: Array<DataSourceDescriptorType> = arrayETL<DataSourceDescriptorType>(
    props.dataProviders,
    [
      { e: "id" },
      { e: "name" },
      { e: "sampleType.name", l: "sampleTypeName" },
      { e: "sampleType.id", l: "sampleTypeId" },
      { e: "sampleType.dateType", l: "dateType" },
      { e: "sampleType.sampleFields[0].units.abbreviation", l: "units" },
    ]
  );

  assignColorsToDataSourceDescriptors(dataSourceDescriptors);

  const dataSourceCheckBoxOptions = arrayETL<CheckBoxGroupDefinitionType>(dataSourceDescriptors, [
    { e: ["name", "units"], t: (name: string, units: string) => `${name} (${units})`, l: "label" },
    { e: "color", l: "boxColor" },
  ]);

  const sanitizedDataSourceDescriptors = dataSourceDescriptors.filter(
    (item: DataSourceDescriptorType, index: number) => displayedDataSourcesFlags[index]
  );

  useEffect(() => {
    if (requestedRange === undefined && isNonEmptyArray(props.dataProviders)) {
      const initialRequest = generateInitialRequest(props.timeZone);

      setRequestedRange(initialRequest);
    }
  }, [props.timeZone, props.dataProviders, requestedRange]);

  useEffect(() => {
    resetDisplayedDataSourcesFlags(props.dataProviders);
  }, [props.dataProviders]);

  // TODO: comeup with a better architecture
  const requestDataCallack = props.requestData;
  useEffect(() => {
    if (requestedRange) {
      requestDataCallack(requestedRange);
    }
  }, [requestedRange, requestDataCallack]);

  const requestedGraphData: GraphDataRequestParametersType = {
    definedInterval: selectedInterval,
    requestedRange: requestedRange,
  };

  const RenderedGraphComponentData = (
    <GraphComponentData
      title={props.title}
      isDataLoading={props.isDataLoading}
      requestedGraphData={requestedGraphData}
      dataArray={sanitizedDataArray}
      dataSourceDescriptors={sanitizedDataSourceDescriptors}
      timeZone={props.timeZone}
      dataAvailable={isNonEmptyArray(dataSourceCheckBoxOptions, 1)}
    />
  );

  const RenderedGraphComponentControls = (
    <GraphComponentControls
      requestedGraphData={requestedGraphData}
      displayedDatasets={displayedDataSourcesFlags}
      dataSourceCheckBoxOptions={dataSourceCheckBoxOptions}
      handleGraphDataRequestChange={handleGraphDataRequestChange}
      onDisplayedDatasetChange={(displayFlags: Array<boolean>) => setDisplayedDataSourcesFlags(displayFlags)}
      timeZone={props.timeZone}
    />
  );

  return (
    <React.Fragment>
      <ConditionalRender
        condition={isSmallScreen}
        onTrue={
          <RowLayout>
            <RowWrapper>{RenderedGraphComponentData}</RowWrapper>
            <RowWrapper>{RenderedGraphComponentControls}</RowWrapper>
          </RowLayout>
        }
        onFalse={
          <div
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "stretch",
              alignContent: "stretch",
            }} /* style={{boxSizing: 'border-box', height: '100%'}} */
          >
            <div
              id="graph_data"
              style={{
                boxSizing: "border-box",
                /* height: '100%', */ flexGrow: 1,
                display: "flex",
                flexDirection: "column",
              }}
            >
              {RenderedGraphComponentData}
            </div>
            <div id="graph_controls" style={{ boxSizing: "border-box", height: "100%", width: "240px" }}>
              {RenderedGraphComponentControls}
            </div>
          </div>
        }
      />
    </React.Fragment>
  );
}

export default GraphComponent;
