import React from "react";
import { FormLayoutElementDefined } from "./FormLayoutElementDefined";
import { EntityActionType } from "components/Crud/Entity.d";
import { FieldConfiguration } from "../Fields/FieldConfiguration.d";
import { FieldsInProgressActionType } from "../EntityForm.d";

export interface FormLayoutElementProps {
  fieldId: string;
  values: Record<string, any>;
  initialValues: Record<string, any>;
  errors: Record<string, any>;
  handleChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;

  fieldConfigurations: FieldConfiguration[];
  editModeOn: boolean;
  action: EntityActionType;
  setFieldValueDirect: {
    (field: never, value: any, shouldValidate?: boolean): void;
    (field: string, value: any): void;
  };
  dispatchFieldsInProgress: (event: FieldsInProgressActionType) => void;
}

export function FormLayoutElement(props: FormLayoutElementProps) {
  const fieldConfig = props.fieldConfigurations.find(
    (configurationItem) => configurationItem.fieldName === props.fieldId
  );

  if (!fieldConfig) {
    return null;
  } else {
    return <FormLayoutElementDefined {...props} />;
  }
}
