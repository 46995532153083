import { isSpecified } from "components/Utils/MiscUtils";

export interface RenderIfSpecifiedProps {
  value: any;
  render?: (value: any, ...rest: Array<any>) => JSX.Element;
  children?: (value: any, ...rest: Array<any>) => JSX.Element;
}

export function RenderIfSpecified(props: RenderIfSpecifiedProps) {
  if (isSpecified(props.value)) {
    if (isSpecified(props.render)) {
      return props.render(props.value);
    } else if (isSpecified(props.children)) {
      return props.children(props.value);
    }
  }

  return null;
}
