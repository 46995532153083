import React from "react";
import { grey } from "@material-ui/core/colors";

export function TableDividerElement() {
  return (
    <div
      style={{
        height: "24px",
        paddingBottom: "24px",
        flexBasis: "24px",
        boxSizing: "border-box",
        borderTop: "1px solid #E0E0E0",
        color: grey[300],
      }}
    />
  );
}
