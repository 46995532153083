import Chart, { ChartOptions } from "chart.js";
import { Color } from "csstype";
import { isSpecified } from "components/Utils/MiscUtils";
import moment from "moment-timezone";
import {
  ChartConfigurationOptions,
  TicksConfigOptions,
  GraphPointValueType,
  TypeChartJsSteppedLine,
  SupportedChartTypes,
} from "./Graph.d";

// Top level configuration parameters
const generateDefaultParamsSubConfig = (config: ChartConfigurationOptions) => {
  return {
    responsive: true, // Not configurable yet
    spanGaps: true, // Not configurable yet - Do NOT break the line if data is missing
    showLines: true, // Not configurable yet - show only dots without connecting them
    maintainAspectRatio: true,
  };
};
const generateTitleSubConfig = (config: ChartConfigurationOptions) => {
  let titleSubConfig = undefined;
  if (config.hasOwnProperty("title")) {
    titleSubConfig = {
      title: {
        display: true,
        text: config.title,
      },
    };
  }
  return titleSubConfig;
};
const generateLegendSubConfig = (config: ChartConfigurationOptions) => {
  let legendSubConfig = undefined;
  if (config.hasOwnProperty("legendPosition")) {
    legendSubConfig = {
      legend: {
        display: config.legendPosition === "none" ? false : true,
        position: config.legendPosition !== "none" ? config.legendPosition : undefined,
      },
    };
  }
  return legendSubConfig;
};
const generateCurvedLineSubConfig = (config: ChartConfigurationOptions) => {
  let curvedLineSubConfig = undefined;
  if (config.hasOwnProperty("disableCurvedLine") && config.disableCurvedLine === true) {
    curvedLineSubConfig = {
      elements: {
        line: {
          tension: 0, // Disable bezier curve for lines
        },
      },
    };
  }
  return curvedLineSubConfig;
};
const generateAnimationSubConfig = (config: ChartConfigurationOptions) => {
  let animationSubConfig = undefined;
  if (config.hasOwnProperty("disableAnimation") && config.disableAnimation === true) {
    animationSubConfig = {
      animation: { duration: 0 },
      hover: { animationDuration: 0 },
      responsiveAnimationDuration: 0,
    };
  }
  return animationSubConfig;
};

// X Axis configuration parameters
const generateXAxisUnitsSubConfig = (config: ChartConfigurationOptions) => {
  // moment.tz.setDefault(config.timeZone);

  // Chart Type is mandatory
  let xAxisUnitsSubConfig = undefined;
  if (config.xAxisUnits === "day" || config.xAxisUnits === "hour") {
    xAxisUnitsSubConfig = {
      type: "time",
      time: {
        unit: config.xAxisUnits,
        // parser: function (date: Date) {
        //   let momentValue = moment(date);

        //   momentValue.tz(config.timeZone);

        // return momentValue;
        // }
        // OPT - parser: timeFormat,
        // OPT - tooltipFormat: '11 HH:mm'
      },
    };
  }
  return xAxisUnitsSubConfig;
};
const generateXAxisLabelSubConfig = (config: ChartConfigurationOptions) => {
  let xAxisLabelSubConfig = undefined;
  if (config.hasOwnProperty("xAxisLabel") && config.xAxisLabel.length > 0) {
    xAxisLabelSubConfig = {
      scaleLabel: {
        display: true,
        labelString: config.xAxisLabel,
      },
    };
  }
  return xAxisLabelSubConfig;
};
const generateXAxisTicksSubConfig = (config: ChartConfigurationOptions) => {
  let xAxisTicksSubConfig = undefined;
  if (config.hasOwnProperty("minX") || config.hasOwnProperty("maxX")) {
    xAxisTicksSubConfig = {
      ticks: {
        min: config.hasOwnProperty("minX") ? config.minX : undefined,
        max: config.hasOwnProperty("maxX") ? config.maxX : undefined,
      },
    };
  }
  return xAxisTicksSubConfig;
};

// Y Axis configuration parameters
const generateRightYAxisSubConfig = (config: ChartConfigurationOptions) => {
  const rightYAxisSubConfig: Array<any> = [];
  if (config.hasOwnProperty("rightHandAxisId") && isSpecified(config.rightHandAxisId)) {
    rightYAxisSubConfig[0] = {
      // Right Axis
      type: "linear",
      display: true,
      position: "right",
      id: config.rightHandAxisId,
      gridLines: {
        drawOnChartArea: false,
      },
      scaleLabel: {
        display: true,
        labelString: config.rightHandAxisLabel,
      },
    };

    if (
      config.hasOwnProperty("minRightY") ||
      config.hasOwnProperty("maxRightY") ||
      config.hasOwnProperty("stepRightY")
    ) {
      rightYAxisSubConfig[0].ticks = {
        min: config.hasOwnProperty("minRightY") ? config.minRightY : undefined,
        max: config.hasOwnProperty("maxRightY") ? config.maxRightY : undefined,
        stepSize: config.hasOwnProperty("stepRightY") ? config.stepRightY : undefined,
      };
    }
  }
  return rightYAxisSubConfig;
};

const generateYAxisLabelSubConfig = (config: ChartConfigurationOptions) => {
  let yAxisLabelSubConfig = undefined;
  if (config.hasOwnProperty("yAxisLabel") && config.yAxisLabel && config.yAxisLabel.length > 0) {
    yAxisLabelSubConfig = {
      scaleLabel: {
        display: true,
        labelString: config.yAxisLabel,
      },
    };
  }
  return yAxisLabelSubConfig;
};

const generateYLeftAxisTicksSubConfig = (config: ChartConfigurationOptions) => {
  let yLeftAxisTicksSubConfig: TicksConfigOptions = {
    suggestedMin: 0,
  };

  if (
    config.hasOwnProperty("minLeftY") ||
    config.hasOwnProperty("maxLeftY") ||
    config.hasOwnProperty("stepLeftY") ||
    config.hasOwnProperty("useRelativeScale")
  ) {
    yLeftAxisTicksSubConfig = {
      min: config.hasOwnProperty("minLeftY") ? config.minLeftY : undefined,
      suggestedMin: config.hasOwnProperty("useRelativeScale") ? undefined : 0,
      max: config.hasOwnProperty("maxLeftY") ? config.maxLeftY : undefined,
      stepSize: config.hasOwnProperty("stepLeftY") ? config.stepLeftY : undefined,
    };
  }

  return {
    ticks: yLeftAxisTicksSubConfig,
  };
};

export const generateChartOptions = (config: ChartConfigurationOptions) => {
  let result: ChartOptions;

  result = {
    // Not configurable yet - Tooltips configuration START
    // tooltips: {
    //  mode: 'index',
    //  intersect: true
    // },
    ...generateDefaultParamsSubConfig(config),
    ...generateTitleSubConfig(config),
    ...generateLegendSubConfig(config),
    ...generateCurvedLineSubConfig(config),
    ...generateAnimationSubConfig(config),
    hover: {
      mode: "nearest",
      intersect: false,
    },
    tooltips: {
      mode: "nearest",
      intersect: false,
      cornerRadius: 8,
      backgroundColor: "#FFFFFF",
      titleFontColor: "#66788A",
      bodyFontColor: "#66788A",
      borderColor: "#3f3f44", // rgba(63,63,68,0.05),
      borderWidth: 0.2,
      callbacks: {
        label: function (tooltipItem: Chart.ChartTooltipItem, data: Chart.ChartData) {
          // TODO: Hacko until a better way to get units
          return `${tooltipItem.value} ${data.datasets[tooltipItem.datasetIndex].yAxisID}`;
        },
        labelColor: function (tooltipItem: any, chart: any) {
          const color = chart.config.data.datasets[tooltipItem.datasetIndex].borderColor;

          return {
            borderColor: "#FFFFFF",
            backgroundColor: color,
          };
        },
        title: function (tooltipItem: Chart.ChartTooltipItem[], data: Chart.ChartData) {
          const datasetValue = data.datasets[tooltipItem[0].datasetIndex].data[
            tooltipItem[0].index
          ] as GraphPointValueType;

          const chartType = data.datasets[tooltipItem[0].datasetIndex].type;

          const formatString = chartType === SupportedChartTypes.BAR ? "DD-MM-YYYY z" : "hh:mm:ss DD-MM-YYYY z";

          const displayedValue = moment(datasetValue.x).tz(config.timeZone).format(formatString);

          return displayedValue;
        },
      },
    },
    scales: {
      xAxes: [
        {
          ...generateXAxisUnitsSubConfig(config),
          ...generateXAxisLabelSubConfig(config),
          ...generateXAxisTicksSubConfig(config),
        },
      ],
      yAxes: [
        {
          // Primary Y Axis
          id: config.leftHandAxisId,
          ...generateYAxisLabelSubConfig(config),
          ...generateYLeftAxisTicksSubConfig(config),
        },
        ...generateRightYAxisSubConfig(config),
      ],
    },
  };

  return result;
};

export const defaultLineConfig = (labelName: string, color: Color, gradientFill: boolean, axisId: string) => {
  // // TODO: gradient fill of the background. Crude example below
  // let gradient = myChartRefForGradient.createLinearGradient(0, 0, 0, 200);
  // gradient.addColorStop(0, 'blue');
  // gradient.addColorStop(.9999999, 'white');
  // gradient.addColorStop(1, 'transparent');

  // Note: see here: https://www.chartjs.org/docs/latest/configuration/elements.html
  return {
    type: "LineWithLine", // 'line',
    label: labelName,
    borderColor: color,
    backgroundColor: color,
    borderWidth: 2,
    fill: false,
    pointRadius: 0,
    // hitRadiua:
    hoverRadius: 5,
    // pointStyle: 'circle',
    gradientFill: gradientFill,
    yAxisID: axisId,
  };
};

export const defaultBarConfig = (labelName: string, color: Color, axisId: string) => {
  return {
    type: "bar",
    label: labelName,
    // hoverBackgroundColor: grey[700], // TODO: add function which will calculate it based on color
    backgroundColor: color,
    borderColor: color,
    borderWidth: 0,
    yAxisID: axisId,
  };
};

export const defaultStepLineConfig = (
  labelName: string,
  color: Color,
  gradientFill: boolean,
  axisId: string,
  stepType?: TypeChartJsSteppedLine
) => {
  return {
    type: "LineWithLine",
    label: labelName,
    borderColor: color,
    backgroundColor: color,
    borderWidth: 0,
    pointRadius: 0,
    // TODO: gradient fill - backgroundColor: color, // gradient,
    fill: false, // TODO: gradient fill - fill: true,
    gradientFill: gradientFill,
    steppedLine: stepType ? stepType : "before", //  true,
    yAxisID: axisId,
  };
};

export const chartConfigByType = (
  name: string,
  chartType: SupportedChartTypes,
  color: string,
  yAxisId: string,
  gradientFill?: boolean
) => {
  switch (chartType) {
    case SupportedChartTypes.BAR:
      return defaultBarConfig(name, color, yAxisId);
    case SupportedChartTypes.STEPPED_LINE:
      return defaultStepLineConfig(name, color, true, yAxisId);
    case SupportedChartTypes.LINE:
    default:
      return defaultLineConfig(name, color, true, yAxisId);
  }
};
