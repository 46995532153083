import React from "react";
import { makeStyles } from "@material-ui/core";
import { screenSecondaryActionButtonFaceStyle } from "./screenSecondaryActionButtonFaceStyle";

export interface TableActionButtonFaceProps {
  children: any;
}

const useStyles = makeStyles(screenSecondaryActionButtonFaceStyle, {
  name: "ScreenSecondaryActionButtonFace",
});

export function ScreenSecondaryActionButtonFace(props: TableActionButtonFaceProps) {
  const classes = useStyles({});

  return (
    <div className={classes.root}>
      <React.Fragment>{props.children}</React.Fragment>
    </div>
  );
}
