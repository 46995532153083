import React from "react";
import { AlertList } from "./AlertList";
import { parseSearchStringIntoScreenRequest } from "components/Utils/RouteUtils";
import { AlertAcknowledgementStatus } from "./Alert.d";
import { FilterMatchTypes } from "components/Utils/CRUDUtils.d";

export interface AlertsOverviewScreenProps {}

export function AlertsOverviewScreen(props: any) {
  const requestFromUrl = parseSearchStringIntoScreenRequest(props.location);

  if (Object.keys(requestFromUrl.request).length === 0) {
    requestFromUrl.request = {
      filter: {
        propertyFilters: {
          "notifications.notificationStatus": {
            attributeValue: AlertAcknowledgementStatus.RAISED,
            criteriaType: FilterMatchTypes.DEFAULT,
          },
        },
      },
    };
  }

  return (
    <AlertList
      userData={props.userData}
      currentEnterpriseId={props.currentEnterpriseId}
      title="All Alerts"
      inputRequest={requestFromUrl.request}
      allowUrlReplacementForFilters={true}
      allowReinitialization={true}
    />
  );
}
