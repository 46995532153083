import React from "react";
import { makeStyles } from "@material-ui/core";
import { rowLayoutStyle } from "./rowLayoutStyle";
import { SpacingNameType } from "assets/jss/muiTheme";
import clsx from "clsx";

export interface RowLayoutProps {
  children: JSX.Element | Array<JSX.Element>;
  spacingName?: SpacingNameType;
  equalHeight?: boolean;
  height?: string;
}

const useStyles = makeStyles(rowLayoutStyle, { name: "RowLayout" });

const defaultSpacing: SpacingNameType = "noSpacing";

export function RowLayout(props: RowLayoutProps) {
  const spacingName = props.spacingName || defaultSpacing;

  const classes = useStyles({ spacingName: spacingName, height: props.height });

  return <div className={clsx(classes.root, props.equalHeight && classes.equalHeight)}>{props.children}</div>;
}
