// TODO move to config file? NWP-99

// ====== DEVELOP:
// ---- constants ----
// const preUrl = 'http://localhost:3030';
// export const auth0Domain = 'fmbt.au.auth0.com';
// export const auth0ClientId = 'bldOdMdyy82gchFn6ao3yi7k7ePlurQB'; // DEVELOP
// export const auth0Audience = 'https://stage.api.farmbot.net.au';

// ====== DEVELOP FROM STAGE
// ---- constants ----
// const preUrl = 'https://wk9gu6l0ke.execute-api.ap-southeast-2.amazonaws.com/staging';
// export const auth0Domain = 'fmbt.au.auth0.com';
// export const auth0ClientId = 'Ddyw9V9Xa00eDYNYZJFiNSYDxP4u79XB'; // STAGE FROM DEV
// export const auth0Audience = 'https://stage.api.farmbot.net.au';

// ====== STAGE:
// ---- Commands ---
// yarn deploy-stage
// aws cloudfront create-invalidation --distribution-id=E1QN1TYF997K3S --paths /
// ---- constants ----
// const preUrl = "https://wk9gu6l0ke.execute-api.ap-southeast-2.amazonaws.com/staging";
// export const auth0Domain = "fmbt.au.auth0.com";
// export const auth0ClientId = "AGEWTSHa6pNhDFSWISzno0pmrOXGmP2A"; // STAGE !!!WARNING - put before deploy. yarn build -> yarn deploy-stage
// export const auth0Audience = "https://stage.api.farmbot.net.au";

// ====== STAGE Pro:
// ---- Commands ---
// yarn deploy-stage
// aws cloudfront create-invalidation --distribution-id=E1QN1TYF997K3S --paths /
// ---- constants ----
// const preUrl = "https://wk9gu6l0ke.execute-api.ap-southeast-2.amazonaws.com/staging";
// export const auth0Domain = "fmbt-pro.au.auth0.com";
// export const auth0ClientId = "P1EvSFJwaTWiq43S3kyeZ1n0kxObwVL1"; // STAGE !!!WARNING - put before deploy. yarn build -> yarn deploy-stage
// export const auth0Audience = "https://staging.be.farmbot.com.au";

// ====== HANCOK:
// ---- Commands:
// yarn deploy-stage-hppl
// aws cloudfront create-invalidation --distribution-id=E1SDMOBG5ZFYSY --paths /
// ---- constants ----
// const preUrl = 'https://gqedvh7qye.execute-api.ap-southeast-2.amazonaws.com/staging-hppl/';
// export const auth0Domain = 'fmbt-pro.au.auth0.com';//HANCOCK
// export const auth0ClientId = 'P1EvSFJwaTWiq43S3kyeZ1n0kxObwVL1'; //HANCOCK
// export const auth0Audience = 'https://hppl-stage-api.farmbot.com.au'; //HANCOCK

// ====== PRODUCTION
// ---- Commands:
// yarn deploy
// aws cloudfront create-invalidation --distribution-id=E314GM3F2ICWYU --paths /
// ---- constants ----
// const preUrl = 'https://v44sxa768b.execute-api.ap-southeast-2.amazonaws.com/production'; // PRODUCTION
// export const auth0Domain = 'fmbt.au.auth0.com';
// export const auth0ClientId = 'GiSteZfbPPCfcHu1lQgsHzJxCvY1Vse1'; // PRODUCTION
// export const auth0Audience = 'https://api.farmbot.net.au'; // PRODUCTION

// ====== PRODUCTION
// ---- Commands:
// yarn deploy
// aws cloudfront create-invalidation --distribution-id=E314GM3F2ICWYU --paths /
// ---- constants ----
const preUrl = 'https://be.farmbot.com.au'; // PRODUCTION
export const auth0Domain = 'fmbt-pro.au.auth0.com';
export const auth0ClientId = 'OjVqrjhKjuB27oDpt5Noaawr3PPZVFCN'; // PRODUCTION
export const auth0Audience = 'https://be.farmbot.com.au'; // PRODUCTION

export { preUrl };
