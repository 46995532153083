import React, { useState, useEffect, useCallback, useReducer } from "react";
import { Formik } from "formik";
import { Paper, FormControlLabel, Switch, IconButton, makeStyles } from "@material-ui/core";
import formStyle from "components/Crud/Form/formStyle";
import { CREATE_ACTION, EDIT_ACTION, SHOW_ACTION } from "../Entity.const";
import { convertUIValuesToDB, exactractConfiguredFields } from "../../Utils/CRUDUtils";
import { FieldConfiguration } from "./Fields/FieldConfiguration.d";
import { UserParams } from "views/User/UserParams.d";
import { EnterpriseIdType } from "views/Enterprise/Enterprise.d";
import CloseIcon from "@material-ui/icons/Close";
import { formValidationErrors, getFormFieldsToShow } from "./EntityFormCommon";
import { CrudEventAction, DataCrudEventType } from "../DataProvider/DataCrud.d";
import { EventDispatchersType } from "../Table/StatelessTable.d";
import { CRUD_DISPATCHER } from "../DataProvider/DataCrud";
import { ConditionalRender } from "layouts/ConditionalRender";
import { FormikRenderComponent } from "./FormikRenderComponent";
import { isNonEmptyArray, isSpecified } from "components/Utils/MiscUtils";
import { ScreenSectionTitle } from "components/Elements/Typography/ScreenSectionTitle";
import { FlexSpaceFiller } from "layouts/FlexSpaceFiller";
import { LinearProgressOnCondition } from "components/Elements/LinearProgressOnCondition";
import { ObjectIdType, ObjectWithKnownFieldsType } from "components/Utils/Object.d";
import { EntityActionType } from "../Entity.d";
import { OnCloseFormCallbackType, ConditionalFieldConfigurationFunctionType } from "./EntityForm.d";
import { fieldsInProgressReducer } from "./FetchlessFormikEntityForm.reducer";
import { LayoutComponentType } from "./Layout/FormLayout.d";

export interface EntityFormProps {
  id?: ObjectIdType;
  title?: string;
  action: EntityActionType;
  fieldConfigurations: FieldConfiguration[];
  formData: ObjectWithKnownFieldsType<{ id?: ObjectIdType }>;

  isDataLoading: boolean;
  eventDispatchers: EventDispatchersType;

  doNotWaitForSuccessToClose?: boolean;
  triggerCloseOnSuccesOf?: Array<any>;
  tempOperationResult?: { operationIdentifier: string | number; successFlag: boolean };

  // entityApiUrl: string;
  onCloseAdditionalActions?: OnCloseFormCallbackType; // Callback to be executed after closing the form
  showCloseButton: boolean;
  currentEnterpriseId: EnterpriseIdType;
  userData: UserParams;
  enableEditSafetySwitch?: boolean;

  layoutComponent?: LayoutComponentType;

  conditionalFieldConfiguration?: ConditionalFieldConfigurationFunctionType;
  formDataPreSubmitProcessor?: (object: Record<string, any>, editType: string) => Record<string, any>;
  doNotPreFetchData?: boolean;

  warning?: string;
  notifyOnValidValuesChange?: (values: Record<string, any>) => void;
}

const initialFieldsInProgresState: Record<string, number> = {};

// TODO: do proper support for the scenario when there are MORE ui fields than in DB.
// We should take care of it in both cases DB->UI and UI->DB

const useStyles = makeStyles(formStyle, { name: "FetchlessFormikEntityForm" });

export function FetchlessFormikEntityForm(props: EntityFormProps) {
  // Note: Supports 1 context only. For multiple contexts use render props. TODO: support multiple contexts

  const classes = useStyles({});

  const [submissionAttemptedAtLeastOnce, setSubmissionAttemptedAtLeastOnce] = useState(false);

  const [editModeOn, setEditModeOn] = useState(
    props.action === CREATE_ACTION || !props.enableEditSafetySwitch ? true : false
  );

  const [fieldsInProgress, dispatchFieldsInProgress] = useReducer(
    fieldsInProgressReducer,
    initialFieldsInProgresState
  );

  // TODO: come up with better architecture
  const onCloseAdditionalActionsCallback = props.onCloseAdditionalActions;

  const handleClose = useCallback(() => {
    if (onCloseAdditionalActionsCallback) {
      const wasDataChanged = props.action === SHOW_ACTION ? false : submissionAttemptedAtLeastOnce === true;

      onCloseAdditionalActionsCallback(wasDataChanged);
    }
  }, [props.action, onCloseAdditionalActionsCallback, submissionAttemptedAtLeastOnce]);

  const handleSubmissionAttempt = (submitFunction: Function) => {
    // Until Formik issue is fixed https://github.com/jaredpalmer/formik/issues/1251
    if (!submissionAttemptedAtLeastOnce) {
      setSubmissionAttemptedAtLeastOnce(true);
    }

    submitFunction();

    if (props.doNotWaitForSuccessToClose) {
      // TODO: not the most elegant solution, but needed for Asset Group
      setTimeout(() => handleClose(), 500);
    }
  };

  useEffect(() => {
    // TODO: temporary hack -> make better

    if (isNonEmptyArray(props.triggerCloseOnSuccesOf) && isSpecified(props.tempOperationResult)) {
      if (
        props.tempOperationResult.successFlag &&
        props.triggerCloseOnSuccesOf.indexOf(props.tempOperationResult.operationIdentifier) >= 0
      ) {
        setTimeout(() => handleClose(), 500);
      }
    }
  }, [props.triggerCloseOnSuccesOf, props.tempOperationResult, handleClose]);

  const someFieldsAreCalculated = Object.keys(fieldsInProgress).length;
  const isDataLoading = props.isDataLoading || someFieldsAreCalculated > 0;

  // TODO: I don't remember which use case required to be a state. Remove 2 weeks after 15/07/2020 if no use case is found
  // let [ disableSubmitButton, setDisableSubmitButton ] = useState(false);
  // useEffect(() => {
  //   if (props.isDataLoading || someFieldsAreCalculated > 0) {
  //     setDisableSubmitButton(true);
  //     console.log('DISABLE');
  //   } else {
  //     setDisableSubmitButton(false);
  //   }
  // }, [props.isDataLoading, someFieldsAreCalculated]);

  const toggleEditModeSwitch = () => {
    setEditModeOn((prevEditModeOn) => !prevEditModeOn);
  };

  const crudEventDispatcher = props.eventDispatchers[CRUD_DISPATCHER];
  useEffect(() => {
    if (props.id && !props.doNotPreFetchData) {
      if (isSpecified(crudEventDispatcher)) {
        crudEventDispatcher({
          action: CrudEventAction.READ,
          payload: { id: props.id },
          originator: "FetchlessFormikEntityForm::useEffect[props.id]",
        });
      } else {
        console.error(
          "FetchlessFormikEntityForm::useEffect[props.id] does not have props.eventDispatchers[CRUD_DISPATCHER] specified"
        );
      }
    }
  }, [props.id, props.doNotPreFetchData, crudEventDispatcher]);

  const submit = async (values: Record<string, any>, setSubmitting: (submiting: boolean) => void) => {
    setSubmitting(true);

    if (isSpecified(props.eventDispatchers[CRUD_DISPATCHER])) {
      let eventPayloadContent: Record<string, any> = convertUIValuesToDB(props.fieldConfigurations, values);

      if (props.action === EDIT_ACTION) {
        eventPayloadContent.id = props.id;
      }

      if (props.formDataPreSubmitProcessor) {
        eventPayloadContent = props.formDataPreSubmitProcessor(eventPayloadContent, props.action);
      }

      if (props.action === CREATE_ACTION) {
        const createEventContent: DataCrudEventType = {
          action: CrudEventAction.CREATE,
          payload: eventPayloadContent, // convertUIValuesToDB(props.fieldConfigurations, values),
          originator: "FetchlessFormikEntityForm::submit - create",
        };

        props.eventDispatchers[CRUD_DISPATCHER](createEventContent);
      } else if (props.action === EDIT_ACTION) {
        const updateEventContent = {
          action: CrudEventAction.UPDATE,
          payload: eventPayloadContent /* {
            id: props.id,
            ...convertUIValuesToDB(props.fieldConfigurations, values)
          }, */,
          originator: "FetchlessFormikEntityForm::submit - edit",
        };

        props.eventDispatchers[CRUD_DISPATCHER](updateEventContent);
      }
    } else {
      console.error(
        "FetchlessFormikEntityForm::componentDidUpdate does not have props.eventDispatchers[CRUD_DISPATCHER] specified"
      );
    }

    // TODO: it is called at the wrong time
    setSubmitting(false);
  };

  const validate = (values: Record<string, any>, initialValues: Record<string, any>, editType: string) => {
    const fieldsToShow = getFormFieldsToShow(props.action, props.fieldConfigurations, values, initialValues);

    const errors = formValidationErrors(values, fieldsToShow, props.fieldConfigurations, editType);

    return errors;
  };

  const formValues = exactractConfiguredFields(props.formData, props.fieldConfigurations, props.action);

  return (
    <div>
      <div /* Grid item */ /* TODO: NWP-96 as dialog or not as dialog - xs={12} sm={12} md={6}*/>
        <Paper>
          <div className={classes.paperContainer}>
            <div style={{ paddingLeft: "16px" }}>
              <ScreenSectionTitle>{props.title}</ScreenSectionTitle>
            </div>
            <FlexSpaceFiller />
            <ConditionalRender condition={props.enableEditSafetySwitch && props.action === EDIT_ACTION}>
              <div>
                <FormControlLabel
                  value="edit"
                  control={<Switch color="primary" checked={editModeOn} onChange={toggleEditModeSwitch} />}
                  label="Edit"
                  labelPlacement="start"
                />
              </div>
            </ConditionalRender>
            <ConditionalRender condition={props.showCloseButton}>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </ConditionalRender>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: 16,
            }} /*Grid container direction="column" style={{padding: 16}}*/
          >
            <LinearProgressOnCondition condition={isDataLoading} />
            {props.warning && props.warning !== "" ? <p style={{ color: "red" }}>{props.warning}</p> : null}
            <ConditionalRender condition={isSpecified(formValues)}>
              <Formik
                enableReinitialize
                initialValues={formValues}
                validate={(values) => {
                  return validate(values, formValues, props.action);
                }}
                validateOnBlur={false}
                validateOnChange={submissionAttemptedAtLeastOnce}
                onSubmit={(values, { setSubmitting }) => {
                  submit(values, setSubmitting);
                }}
                render={({
                  submitForm,
                  isSubmitting,
                  values,
                  setFieldValue,
                  errors,
                  handleChange,
                  initialValues,
                  ...rest
                }) => {
                  return (
                    <FormikRenderComponent
                      conditionalFieldConfiguration={props.conditionalFieldConfiguration}
                      values={values}
                      isSubmitting={isSubmitting}
                      initialValues={initialValues}
                      errors={errors}
                      handleChange={handleChange}
                      action={props.action}
                      fieldConfigurations={props.fieldConfigurations}
                      editModeOn={editModeOn}
                      layoutComponent={props.layoutComponent}
                      id={props.id}
                      setFieldValue={setFieldValue}
                      notifyOnValidValuesChange={props.notifyOnValidValuesChange}
                      showCloseButton={props.showCloseButton}
                      handleClose={handleClose}
                      disableSubmitButton={isDataLoading /* disableSubmitButton */}
                      handleSubmissionAttempt={handleSubmissionAttempt}
                      submitForm={submitForm}
                      dispatchFieldsInProgress={dispatchFieldsInProgress}
                    />
                  );
                }}
              />
            </ConditionalRender>
          </div>
        </Paper>
      </div>
    </div>
  );
}

// OLD CODE

// if (props.conditionalFieldConfiguration) {
//   props.conditionalFieldConfiguration(values);
// }

// console.log('FetchlessFormikEntityForm ------ RENDER inside FORMIK', values);

// return (
//   <React.Fragment>
//     <LinearProgressOnCondition condition={isSubmitting}/>
//     <Form>
//       <BlockOfFormikFormFields
//         initialValues={initialValues}
//         values={values}
//         errors={errors}
//         handleChange={handleChange}
//         fieldsToShow={getFormFieldsToShow(props.action, props.fieldConfigurations, values, initialValues)}
//         fieldConfigurations={props.fieldConfigurations}
//         editModeOn={editModeOn}
//         action={props.action}
//         layoutComponent={props.layoutComponent}
//         id={props.id}
//         setFieldValueDirect={setFieldValue}
//         notifyOnValidValuesChange={props.notifyOnValidValuesChange}
//       />
//     </Form>
//     <div>
//       <Grid // TODO: change to normal div
//         container
//         direction="row"
//         spacing={2}
//         justify="flex-end"
//         style={{paddingTop: 8, paddingLeft: 8, paddingRight: 8}}
//       >
//         <ConditionalRender condition={props.showCloseButton}>
//           <Grid item>
//             <Button /* variant={BUTTON_VARIANT} */ color="default" onClick={handleClose}>Cancel</Button>
//           </Grid>
//         </ConditionalRender>
//         <Grid item>
//         <ConditionalRender condition={(props.action !== SHOW_ACTION && editModeOn)}>
//           <Button
//             variant={BUTTON_VARIANT}
//             color="primary"
//             disabled={disableSubmitButton}
//             onClick={() => handleSubmissionAttempt(submitForm)}
//           >
//             Save
//           </Button>
//         </ConditionalRender>
//         </Grid>
//       </Grid>
//     </div>
//   </React.Fragment>
// );
// }}
