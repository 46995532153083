import React, { useState } from "react";
import { ENTERPRISE_API_URL, ALL_ENTERPRISES_CODE } from "views/Enterprise/Enterprise.const";
import { TableCellRendererProps } from "components/Crud/Table/CellRenderers/TableCellRenderer.d";
import { DressedEnum } from "components/Crud/Table/CellRenderers/DressedEnum";
import { ACTION_NAVIGATE_TO_ASSET } from "./Asset.const";
import {
  FilterMatchTypes,
  FilterCriteria,
  UnifiedDataRequestType,
  SORT_ASC,
} from "components/Utils/CRUDUtils.d";
import { refineRequest } from "components/Utils/RouteUtils";
import { ENTITY_LIB } from "components/Utils/CRUDEntityList";
import { API_LIB } from "components/Utils/API_LIB";
import {
  BaseListProps,
  TableColumnParameters,
  TableColumnFilter,
  TableBodyLayoutType,
} from "components/Crud/Table/EntityTable.d";
import { SITE_API_URL } from "views/Site/Site.const";
import {
  generateEditButtonConfig,
  generateShowButtonConfig,
  generateCreateButtonConfig,
  generateDeleteButtonConfig,
} from "components/Buttons/ButtonsCommon";
import { getAggregatedPageRoute, getEntityListRoute } from "components/Utils/RouteUtils";
import { ROUTE_LIB } from "components/Utils/RouteList";
import { assetTypeIconDictionaryWithTooltips } from "components/Dictionaries/DictionariesCommon";
import { translateValue, filterBy } from "components/Utils/MiscUtils";
import { CrudQueryTableBinding } from "components/Crud/Table/CrudQueryTableBinding";
import { DataSourceParamsType } from "components/Crud/DataProvider/DataProviderCommon.d";
import { SingleRowAssetCard } from "components/Crud/Table/CellRenderers/AssetCardCells/SingleRowAssetCard";
import { BigAssetCard } from "components/Crud/Table/CellRenderers/AssetCardCells/BigAssetCard";
import { AssetGeoMap } from "components/GeoMap/AssetGeoMap";
import { ObjectIdType } from "components/Utils/Object.d";
import { mergeRecords } from "components/Utils/ObjectUtils";
import { InjectedContentSignatureType } from "components/Crud/Table/StatelessTable.d";
import { useVersion } from "components/Utils/CustomHooks";
import SettingsInputComposite from "@material-ui/icons/SettingsInputComposite";
import { SAMPLEPOINT_ROUTE } from "../SamplePoint/SamplePoint.const";
import { TableActionButtonFace } from "components/Buttons/TableActionButtonFace";
import { AssetEditForm } from "./AssetEditForm";
import { DELETE_ACTION, CREATE_ACTION } from "components/Crud/Entity.const";
import { ConditionalRender } from "layouts/ConditionalRender";
import { ActionButtonConfig } from "components/Buttons/Buttons.d";

export interface AssetInventoryProps {
  assetGroupId?: number;
  title?: string;
}

export function AssetInventory(props: BaseListProps & AssetInventoryProps) {
  const [version, increaseVersion] = useVersion();
  const [displayMode, setDisplayMode] = useState(
    TableBodyLayoutType.FULL_WIDTH_CARD_SINGLE as TableBodyLayoutType
  );

  let tableColumns: TableColumnParameters[];
  let uiFilters: Array<TableColumnFilter>;

  if (displayMode !== TableBodyLayoutType.FULL_WIDTH_HTML) {
    tableColumns = [
      {
        sourceName: [
          "name",
          "assetTypeId",
          "site.name",
          "id",
          "samplePoints.currentStatus",
          "samplePoints.events",
        ],
        label: "",
        width: "100%",
        actionSet: [
          {
            labelElement: <div style={{ whiteSpace: "nowrap" }}>{"VIEW DETAIL"}</div>,
            name: "viewDetail",
            onClick: {
              type: "navigate",
              // TODO: it has nothing to do with Asset Group
              url: getAggregatedPageRoute(ROUTE_LIB.ASSET_SUMMARY),
              paramName: "entityId",
            },
            actionCode: ACTION_NAVIGATE_TO_ASSET,
            actionObject: ENTITY_LIB.ASSET_INVENTORY_ITEM,
          },
        ],
      },
    ];

    if (displayMode === TableBodyLayoutType.FULL_WIDTH_CARD_SINGLE) {
      tableColumns[0].valueTransformationFunction = (rendererProps: TableCellRendererProps) => (
        <SingleRowAssetCard {...rendererProps} />
      );
    } else if (
      displayMode === TableBodyLayoutType.FULL_WIDTH_CARD_MULTI ||
      displayMode === TableBodyLayoutType.HALF_WIDTH_INJECTED_CONTENT
    ) {
      tableColumns[0].valueTransformationFunction = (rendererProps: TableCellRendererProps) => (
        <BigAssetCard {...rendererProps} />
      );
    }

    uiFilters = [
      {
        filterType: "text",
        filterDataSourceField: "name",
        filterMatchType: FilterMatchTypes.CONTAINS,
        filterParams: {
          placeholder: "Asset Name",
        },
      },
      {
        filterType: "select-async",
        filterParams: {
          optionDefinitionEndpoint: SITE_API_URL,
          optionCodeField: "id",
          optionLabelField: "name",
          renderEmptyValueAs: "ALL SITES",
          autoComplete: true,
          placeholder: "ALL SITES",
          filterUrl:
            props.currentEnterpriseId !== ALL_ENTERPRISES_CODE
              ? `?filter=enterpriseId::eq::${props.currentEnterpriseId}`
              : undefined,
        },
        filterDataSourceField: "siteId", // Because column uses name, but filter uses id
      },
    ];
  } else {
    tableColumns = [
      {
        sourceName: "site.enterprise.name", // TODO: actualy this is not a name, but a data source address
        label: "Enterprise",
        hide: props.currentEnterpriseId !== ALL_ENTERPRISES_CODE,
      },
      {
        sourceName: "site.name", // TODO: actualy this is not a name, but a data source address
        label: "Site",
        width: 150,
      },
      {
        sourceName: "assetTypeId",
        label: "Asset Type",
        valueTransformationFunction: (rendererProps: TableCellRendererProps) => (
          <DressedEnum options={assetTypeIconDictionaryWithTooltips} {...rendererProps} />
        ),
      },
      {
        sourceName: "name",
        label: "Name",
        width: 200,
      },
      {
        disableSorting: true,
        actionSet: [
          generateEditButtonConfig(ENTITY_LIB.ASSET_INVENTORY_ITEM, AssetEditForm, [increaseVersion], {
            title: "Edit Asset Details",
          }),
          generateShowButtonConfig(ENTITY_LIB.ASSET_INVENTORY_ITEM, AssetEditForm, {
            title: "Show Asset Details",
          }),
          generateDeleteButtonConfig(ENTITY_LIB.ASSET_INVENTORY_ITEM),
          {
            labelElement: (
              <TableActionButtonFace>
                <SettingsInputComposite />
                &nbsp;<span>Sample Points</span>
              </TableActionButtonFace>
            ),
            name: "samplepoints",
            onClick: {
              type: "navigate",
              url: `${getEntityListRoute(SAMPLEPOINT_ROUTE)}`,
              paramName: "assetId",
            },
            actionCode: DELETE_ACTION,
            actionObject: ENTITY_LIB.ASSET_INVENTORY_ITEM,
            doNotGroup: true,
          },
        ],
      },
    ];

    uiFilters = [
      {
        disable: props.currentEnterpriseId !== ALL_ENTERPRISES_CODE,
        filterType: "select-async",
        filterParams: {
          optionDefinitionEndpoint: ENTERPRISE_API_URL,
          optionCodeField: "id",
          optionLabelField: "name",
          renderEmptyValueAs: "ALL ENTERPRISES",
          autoComplete: true,
          placeholder: "ALL ENTERPRISES",
        },
        filterDataSourceField: "site.enterpriseId", // Because column uses name, but filter uses id
      },
      {
        filterType: "select-async",
        filterParams: {
          optionDefinitionEndpoint: SITE_API_URL,
          optionCodeField: "id",
          optionLabelField: "name",
          renderEmptyValueAs: "ALL SITES",
          autoComplete: true,
          placeholder: "ALL SITES",
          // TODO: refactor, ugly code
          filterUrl:
            props.currentEnterpriseId !== ALL_ENTERPRISES_CODE
              ? `?filter=enterpriseId::eq::${props.currentEnterpriseId}`
              : undefined,
        },
        filterDataSourceField: "siteId", // Because column uses name, but filter uses id
      },
      {
        filterType: "text",
        filterDataSourceField: "name",
        filterMatchType: FilterMatchTypes.CONTAINS,
        filterParams: {
          placeholder: "Asset Name",
        },
      },
    ];
  }

  const baseFilters: FilterCriteria[] = [];

  baseFilters.push(
    filterBy(
      "site.enterpriseId",
      "=",
      translateValue(props.currentEnterpriseId, [[ALL_ENTERPRISES_CODE, undefined]])
    )
    // {
    //   criteriaType: FilterMatchTypes.EQUAL,
    //   attributeName: 'site.enterpriseId',
    //   attributeValue:  translateValue(props.currentEnterpriseId, [[ALL_ENTERPRISES_CODE, undefined]])
    // }
  );

  // baseFilters.push({
  //   criteriaType: FilterMatchTypes.EQUAL,
  //   attributeName: 'notifications.notificationStatus',
  //   attributeValue: AlertAcknowledgementStatus.NOT_ACKNOWLEDGED
  // });

  // baseFilters.push({
  //   criteriaType: FilterMatchTypes.EQUAL,
  //   attributeName: 'notifications.userId',
  //   attributeValue: props.userData.userId
  // });

  const dataSource: DataSourceParamsType = {
    apiUrl: API_LIB.ASSET_INVENTORY_LIST,
    // customUrlRequestFragment: `groupId=${props.assetGroupId}`
  };
  if (props.assetGroupId) {
    dataSource.customUrlRequestFragment = `groupId=${props.assetGroupId}`;
  }

  let initialRequest: UnifiedDataRequestType = {
    fieldList: [
      "name",
      "assetTypeId",
      "siteId",
      "site.name",
      "site.enterprise.name",
      "samplePoints",
      "samplePoints.currentStatus",
      "samplePoints.name",
      "location",
      // 'events.id'
    ],
    filter: {
      baseFilters,
    },
    isPaginationEnabled: true,
    rowsPerPage: 10,
    pageNum: 0,
    sortByColumn: "name",
    sortDirection: SORT_ASC,
  };

  const initialMapRequest: UnifiedDataRequestType = {
    fieldList: [
      "name",
      "assetTypeId",
      "siteId",
      "site.name",
      "site.enterprise.name",
      "samplePoints",
      "samplePoints.currentStatus",
      "samplePoints.name",
      "location",
      // 'events.id'
    ],
    filter: {
      baseFilters,
    },
    // isPaginationEnabled: true,
    // rowsPerPage: 100,
    // pageNum: 0,
    sortByColumn: "name",
    sortDirection: SORT_ASC,
  };

  const refinedRequestFromInput = refineRequest(props.inputRequest, uiFilters);
  initialRequest = mergeRecords(initialRequest, refinedRequestFromInput) as UnifiedDataRequestType;

  // end of temporary

  const [currentAssetId, setCurrentAssetId] = useState(null as ObjectIdType);

  const mapContent: InjectedContentSignatureType = (
    inputData: Array<any>,
    activeEntityIds: Array<ObjectIdType>
  ) => {
    return <AssetGeoMap inputData={inputData} activeEntityIds={activeEntityIds} />;
  };

  const headerActions: Array<ActionButtonConfig> = [
    generateCreateButtonConfig(
      ENTITY_LIB.ASSET_INVENTORY_ITEM,
      "ADD ASSET",
      AssetEditForm,
      [increaseVersion],
      { title: "Add Asset" }
    ),
  ];

  const allowedTableVariants = [
    TableBodyLayoutType.FULL_WIDTH_CARD_SINGLE,
    TableBodyLayoutType.FULL_WIDTH_INJECTED_CONTENT,
  ];

  if (props.userData.isSuperAdmin) {
    allowedTableVariants.push(TableBodyLayoutType.FULL_WIDTH_HTML);
  }

  let restrictActions: Array<string>;

  if (props.assetGroupId) {
    restrictActions = [CREATE_ACTION, DELETE_ACTION];
  }

  return (
    <React.Fragment>
      <ConditionalRender condition={displayMode !== TableBodyLayoutType.FULL_WIDTH_INJECTED_CONTENT}>
        <CrudQueryTableBinding
          dataSource={dataSource}
          externalTriggerValues={[props.currentEnterpriseId, version, props.assetGroupId]}
          intialQuery={initialRequest}
          title={props.title}
          tableColumns={tableColumns}
          tableVariant={displayMode}
          // actionsAllowed={allowedActions}
          enableModeSwitch={true}
          radioChangeFunction={(newValue) => setDisplayMode(newValue as TableBodyLayoutType)}
          highlightedRowsPrimaryKeys={currentAssetId ? [currentAssetId] : undefined}
          onRowClickFunction={(id: ObjectIdType, object: Record<string, any>) => setCurrentAssetId(id)}
          uiFilters={uiFilters}
          injectedContent={mapContent}
          headerActions={headerActions}
          allowUrlReplacementForFilters={props.allowUrlReplacementForFilters}
          allowReinitialization={props.allowReinitialization}
          allowedTableVariants={allowedTableVariants}
          restrictActions={restrictActions}
        />
      </ConditionalRender>
      <ConditionalRender condition={displayMode === TableBodyLayoutType.FULL_WIDTH_INJECTED_CONTENT}>
        <CrudQueryTableBinding
          dataSource={dataSource}
          externalTriggerValues={[props.currentEnterpriseId, version, props.assetGroupId]}
          intialQuery={initialMapRequest}
          title={props.title}
          tableColumns={tableColumns}
          tableVariant={displayMode}
          actionsAllowed={[]}
          enableModeSwitch={true}
          radioChangeFunction={(newValue) => setDisplayMode(newValue as TableBodyLayoutType)}
          highlightedRowsPrimaryKeys={currentAssetId ? [currentAssetId] : undefined}
          onRowClickFunction={(id: ObjectIdType, object: Record<string, any>) => setCurrentAssetId(id)}
          uiFilters={[]}
          injectedContent={mapContent}
          headerActions={[]}
          allowUrlReplacementForFilters={props.allowUrlReplacementForFilters}
          allowReinitialization={props.allowReinitialization}
          allowedTableVariants={allowedTableVariants}
          // disableFilters={true}
          disablePaginationSection={true}
          restrictActions={restrictActions}
        />
      </ConditionalRender>
    </React.Fragment>
  );

  // return (
  //   <TableDataProvider
  //     valueWhichTriggersDataUpdate={[props.currentEnterpriseId, props.assetGroupId]}
  //     baseDataApiConf={baseApiConfig}
  //     render={
  //       ( data: Array<ObjectWithIdFieldType>,
  //         isDataLoading: boolean,
  //         total: number,
  //         refreshCallback: Function, // TODO: add signature
  //         deleteCallback: Function, // TODO: add signature
  //         updateCallback: Function, // TODO: add signature
  //         dataApiConf: TableDataParameters,
  //         dispatchFilterUpdateEvent?: (action: FilterUpdateEventType, payload: FilterUpdatePayloadType) => void
  //       ) =>
  //         <EntityTable
  //           title="Assets"
  //           +tableData={data}
  //           +isDataLoading={isDataLoading}
  //           +total={total}
  //           requestDataCallback={refreshCallback}
  //           onDeleteCallback={deleteCallback}
  //           +columns={tableColumns}
  //           +popupDialog={popupDialogs}
  //           +(morphed into queryParams) - parameters={dataApiConf} // TODO: remove this field
  //           onRowClickFunction={props.onRowClickFunction}
  //           +actionsAllowed={allowedActions}
  //           +highlightedRowsPrimaryKeys={props.activeRowPrimaryKeys}
  //           currentEnterpriseId={props.currentEnterpriseId}
  //           userData={props.userData}
  //           +dispatchFilterUpdateEvent={dispatchFilterUpdateEvent}
  //           +tableVariant={displayMode}
  //           +enableModeSwitch={true}
  //           +radioChangeFunction={newValue => setDisplayMode(newValue as TableVariantType)}
  //         />
  //     }
  //   />
  // );
}
