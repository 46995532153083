import { TableCellRendererProps } from "./TableCellRenderer.d";
import React from "react";
import { hasAttributeByPath, getAttributeByPath } from "components/Crud/EntityUtils";
import { isSpecified } from "components/Utils/MiscUtils";
import { getDictionaryLabelElementByCode } from "components/Dictionaries/DictionariesUtils";

export interface DressedDropdownOption {
  code: number | boolean | string;
  label: string;
  labelElement: JSX.Element;
}

export interface DressedEnumEnumProps {
  options: Array<DressedDropdownOption>;
}

// TODO: merge with ENUM as it's no big difference betwen and icon and a color

export function DressedEnum(props: TableCellRendererProps & DressedEnumEnumProps) {
  let value = undefined;

  if (hasAttributeByPath(props.obj, props.fieldName)) {
    const code = getAttributeByPath(props.obj, props.fieldName);

    if (isSpecified(code)) {
      value = getDictionaryLabelElementByCode(props.options, code);
    }
  }

  return <div>{value}</div>;
}
