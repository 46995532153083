import React from "react";
import { FieldConfiguration } from "components/Crud/Form/Fields/FieldConfiguration.d";
import { API_LIB } from "components/Utils/API_LIB";
import { DataCrudEntityFormBinding } from "components/Crud/Form/DataCrudEntityFormBinding";
import { ChangeUserPasswordFormLayout } from "./ChangeUserPasswordFormLayout";
import { EntityFormParams } from "components/Crud/Form/EntityForm.d";

export const CURRENT_PASSWORD_KEY = "currentPassword";
export const NEW_PASSWORD_KEY = "newPassword";
export const CONFIRM_PASSWORD_KEY = "confirmPassword";

export function ChangeUserPasswordForm(props: EntityFormParams) {
  const currentUser = props.userData;

  const getFieldConfigs: FieldConfiguration[] = [
    {
      fieldName: CURRENT_PASSWORD_KEY,
      label: "Current Password",
      type: "password",
      hideIf: (value: string, obj: any, editType: string): boolean => {
        return props.id !== currentUser.userId;
      },
      validation: {
        minLength: 6,
        required: true,
      },
    },
    {
      fieldName: NEW_PASSWORD_KEY,
      label: "New Password",
      type: "password",
      validation: {
        minLength: 6,
        required: true,
      },
    },
    {
      fieldName: CONFIRM_PASSWORD_KEY,
      label: "Confirm Password",
      type: "password",
      validation: {
        required: true,
        minLength: 6,
        validationFunction: (value: string, obj: any) => {
          return obj[NEW_PASSWORD_KEY] === obj[CONFIRM_PASSWORD_KEY];
        },
        errorMessage: "Passwords do not match",
      },
    },
  ];

  const dataSource = {
    apiUrl: API_LIB.CHANGE_PASSWORD,
  };

  const userNameForLayout = props.dataObject ? props.dataObject.username : undefined;

  return (
    <DataCrudEntityFormBinding
      id={props.id}
      dataSource={dataSource}
      action={props.action}
      title={props.title}
      fieldConfigurations={getFieldConfigs}
      onCloseAdditionalActions={props.onCloseAdditionalActions}
      showCloseButton={props.showCloseButton}
      userData={props.userData}
      currentEnterpriseId={props.currentEnterpriseId}
      doNotPreFetchData={true}
      layoutComponent={(layoutProps: any) => (
        <ChangeUserPasswordFormLayout {...layoutProps} extraProps={{ username: userNameForLayout }} />
      )}
      // formDataPreSubmitProcessor={preSubmitProcessor}
    />
  );
}
