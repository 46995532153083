import React, { ChangeEvent, useEffect } from "react";
import OutlinedFieldWrapper from "./OutlinedFieldWrapper";
import { TextField, MenuItem, makeStyles, useTheme } from "@material-ui/core";
import {
  MenuItemType,
  INPUT_VARIANT,
  FieldConfiguration,
  SelectAsyncConfiguration,
} from "./FieldConfiguration.d";
import { safeFieldValue } from "../EntityFormCommon";
import { elementLabelStyle } from "components/Elements/elementLabel.style";
import { useMediaQueryMatchDown } from "components/Utils/LayoutUtils";
import { CustomThemeType } from "assets/jss/muiTheme";
import clsx from "clsx";

export interface OutlinedSelectFieldProps {
  value: any;
  defaultValue?: any;
  helperText?: string;
  fieldConfig: FieldConfiguration;
  disabled?: boolean;
  errorFlag: boolean;
  customHeight?: string;
  onChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;

  optionsArray: Array<MenuItemType>;
  displayEmptyValueAs?: string;
  required?: boolean;
  setFieldValueDirect?: {
    (field: never, value: any, shouldValidate?: boolean): void;
    (field: string, value: any): void;
  };
  valueWasModified?: boolean;
}

const DEFAULT_MENU_ITEM_KEY = "defaultMenuItemKey";

const useLabelStyles = makeStyles(elementLabelStyle, { name: "OutlinedSelectField" });

function OutlinedSelectField(props: OutlinedSelectFieldProps) {
  const labelClasses = useLabelStyles({});
  const isSmallScreen = useMediaQueryMatchDown("sm");

  let safeValue = safeFieldValue(props.value, props.defaultValue, "");

  const menuOptions = props.optionsArray || [];

  if (menuOptions.findIndex((item) => item.code === safeValue) < 0) {
    safeValue = "";
  }

  let displayEmptySelectProps: {
    displayEmpty: boolean;
    renderValue: (value: any) => React.ReactNode;
  };

  if (props.displayEmptyValueAs) {
    displayEmptySelectProps = {
      displayEmpty: true,
      renderValue: (value: any) => {
        let resultValue: string;

        if (value === "") {
          resultValue = props.displayEmptyValueAs;
        } else {
          const resultItem = menuOptions.find((optionItem: MenuItemType) => optionItem.code === value);
          if (resultItem !== undefined) {
            resultValue = resultItem.label;
          }
        }
        return resultValue;
      },
    };
  }

  let customizedInputProps: {
    style: { height: string };
  };

  if (props.customHeight) {
    customizedInputProps = {
      style: { height: props.customHeight },
    };
  }

  // TODO: I don't know how to do it better. And we shouldnt have a long list of options anyway.
  // TODO: best solution is to lift it ABOVE rendering to formik level
  const isAutoComplete = (props.fieldConfig.fieldParams as SelectAsyncConfiguration).autoComplete;
  const optionsLength = menuOptions.length;
  const optionsString = menuOptions.map((item) => item.code).join(",");
  const isRequired = props.required;
  const setFieldValueDirect = props.setFieldValueDirect;
  const fieldName = props.fieldConfig.fieldName;
  const firstOptionCode = menuOptions.length > 0 ? menuOptions[0].code : "";

  useEffect(() => {
    if (isRequired && !isAutoComplete && optionsLength === 1 && firstOptionCode !== "") {
      setFieldValueDirect(fieldName, firstOptionCode);
    }
  }, [
    isAutoComplete,
    optionsString,
    optionsLength,
    isRequired,
    setFieldValueDirect,
    fieldName,
    firstOptionCode,
  ]);

  const theme = useTheme() as CustomThemeType;

  return (
    <OutlinedFieldWrapper>
      <TextField
        style={{
          borderRadius: "4px",
          backgroundColor: props.valueWasModified ? theme.customColors.modifiedField : undefined,
        }}
        // TODO: Params similar to CustomTextField
        id={props.fieldConfig.fieldName}
        helperText={props.helperText}
        onChange={props.onChange}
        name={props.fieldConfig.fieldName}
        value={safeValue} // props.value}
        variant={INPUT_VARIANT}
        size="small"
        fullWidth
        select // TODO: support disable dropdown if hideInputAdornmentIfNoOptions
        InputProps={customizedInputProps}
        disabled={props.disabled}
        error={props.errorFlag}
        label={props.fieldConfig.label}
        // required={props.fieldConfig.validation ? props.fieldConfig.validation.required : undefined}
        required={props.required}
        SelectProps={displayEmptySelectProps}
      >
        {menuOptions.map((optionItem: MenuItemType) => (
          <MenuItem
            key={optionItem.code || DEFAULT_MENU_ITEM_KEY}
            value={optionItem.code}
            style={{ display: optionItem.hideForInput ? "none" : undefined }}
          >
            <span
              className={clsx(
                isSmallScreen ? labelClasses.labelMobile : labelClasses.labelDesktop,
                labelClasses.ellipsisOnOverflow
              )}
              style={{ maxWidth: "100%" }}
            >
              {optionItem.emphasize ? <em>{optionItem.label}</em> : optionItem.label}
            </span>
          </MenuItem>
        ))}
      </TextField>
    </OutlinedFieldWrapper>
  );
}

export default OutlinedSelectField;
