import React from "react";
import { FieldConfiguration } from "components/Crud/Form/Fields/FieldConfiguration.d";
import { USER_MEMBERSHIP_API_URL } from "./UserMembership.const";
import { USER_API_URL } from "views/User/User.const";
import { ENTERPRISE_API_URL } from "views/Enterprise/Enterprise.const";
import { DataCrudEntityFormBinding } from "components/Crud/Form/DataCrudEntityFormBinding";
import { roleOptions } from "./UserMembership.dict";
import { EntityFormParams } from "components/Crud/Form/EntityForm.d";

export interface UserMembershipProps {
  userId: number;
}

export function UserMembershipEditForm(props: EntityFormParams & UserMembershipProps) {
  const getFieldConfigs = (): FieldConfiguration[] => {
    return [
      {
        fieldName: "userId",
        label: "User",
        defaultValue: props.userId,
        disabled: true,
        type: "select-async",
        fieldParams: {
          optionDefinitionEndpoint: USER_API_URL,
          optionCodeField: "id",
          optionLabelField: "username",
        },
      },
      {
        fieldName: "enterpriseId",
        label: "Enterprise",
        type: "select-async",
        validation: {
          required: true,
        },
        fieldParams: {
          optionDefinitionEndpoint: ENTERPRISE_API_URL,
          optionCodeField: "id",
          optionLabelField: "name",
          autoComplete: true,
        },
      },
      {
        fieldName: "role",
        label: "Role",
        type: "select",
        validation: { required: true },
        fieldParams: { options: roleOptions },
      },
    ];
  };

  const dataSource = {
    apiUrl: USER_MEMBERSHIP_API_URL,

    // customUrlRequestFragment: `groupId=${props.assetGroupId}`
  };

  return (
    <DataCrudEntityFormBinding
      id={props.id} // TODO: do we need this???
      dataSource={dataSource}
      action={props.action}
      title={props.title}
      fieldConfigurations={getFieldConfigs()}
      onCloseAdditionalActions={props.onCloseAdditionalActions}
      showCloseButton={props.showCloseButton}
      userData={props.userData}
      currentEnterpriseId={props.currentEnterpriseId}
    />
  );
}
