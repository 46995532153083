import { MenuItemType } from "components/Crud/Form/Fields/FieldConfiguration.d";
import { DressedDropdownOption } from "components/Crud/Table/CellRenderers/DressedEnum";
import { isNonEmptyArray, isSpecified } from "components/Utils/MiscUtils";
import { getAttributeByPath } from "components/Crud/EntityUtils";

// TODO: use this function in other places where appropriate
// TODO: typefy output based on input
export const getDictionaryItemByCode = (
  dictionary: Array<MenuItemType | DressedDropdownOption | Record<string, any>>,
  requestedCode: string | number | boolean,
  codeField?: string
) => {
  // Note: Some numeric data returned from API can returned as text
  // Note: parseInt('10z', 10) will return 10;
  // Note: Number.isNaN() - requiries number argument. Unbelievable.

  let result: MenuItemType | DressedDropdownOption | Record<string, any>;

  if (isNonEmptyArray(dictionary)) {
    result = dictionary.find((dictionaryItem) => {
      let checkResult: boolean;

      const dictionaryItemCode = codeField
        ? getAttributeByPath(dictionaryItem, codeField)
        : dictionaryItem.code;

      if (typeof dictionaryItemCode === typeof requestedCode) {
        checkResult = dictionaryItemCode === requestedCode;
      } else if (typeof dictionaryItemCode === "string" && typeof requestedCode === "number") {
        if (!isNaN((dictionaryItemCode as unknown) as number)) {
          checkResult = parseInt(dictionaryItemCode, 10) === requestedCode;
        } else {
          checkResult = false;
        }
      } else if (typeof dictionaryItemCode === "number" && typeof requestedCode === "string") {
        if (!isNaN((requestedCode as unknown) as number)) {
          checkResult = parseInt(requestedCode, 10) === dictionaryItemCode;
        } else {
          checkResult = false;
        }
      } else {
        // Not really needed, but Lint complains with not all paths return value otherwise
        checkResult = false;
      }

      return checkResult;
    });
  }

  return result;
};

export const getDictionaryLabelByCode = (
  dictionary: Array<MenuItemType | DressedDropdownOption>,
  requestedCode: string | number | boolean,
  codeField?: string,
  labelField?: string
) => {
  const dictionaryItem = getDictionaryItemByCode(dictionary, requestedCode, codeField);

  let result: any = undefined;

  if (isSpecified(dictionaryItem)) {
    result = labelField ? getAttributeByPath(dictionaryItem, labelField) : dictionaryItem.label;
  }

  return result;
};

export const getDictionaryLabelElementByCode = (
  dictionary: Array<DressedDropdownOption>,
  requestedCode: string | number | boolean,
  codeField?: string
) => {
  const dictionaryItem = getDictionaryItemByCode(dictionary, requestedCode, codeField) as DressedDropdownOption;

  const result = isSpecified(dictionaryItem) ? dictionaryItem.labelElement : undefined;

  return result;
};
