import React from "react";
import { FieldConfiguration } from "components/Crud/Form/Fields/FieldConfiguration.d";
import { SENSOR_API_URL } from "./Sensor.const";
import { DataCrudEntityFormBinding } from "components/Crud/Form/DataCrudEntityFormBinding";
import { EntityFormParams } from "components/Crud/Form/EntityForm.d";

export function SensorEditForm(props: EntityFormParams) {
  const getFieldConfigs = (): FieldConfiguration[] => {
    return [];
  };

  const dataSource = {
    apiUrl: SENSOR_API_URL,
    // customUrlRequestFragment: `groupId=${props.assetGroupId}`
  };

  return (
    <DataCrudEntityFormBinding
      id={props.id} // TODO: do we need this???
      dataSource={dataSource}
      action={props.action}
      title={props.title}
      fieldConfigurations={getFieldConfigs()}
      onCloseAdditionalActions={props.onCloseAdditionalActions}
      showCloseButton={props.showCloseButton}
      userData={props.userData}
      currentEnterpriseId={props.currentEnterpriseId}
    />
  );
}
