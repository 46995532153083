import React, { useEffect, useState } from "react";
import { ObjectWithIdFieldType, ObjectIdType } from "components/Utils/Object.d";
import { TableColumnParameters, OnRowClickFunctionType } from "../EntityTable.d";
import { EntityTableUnifiedTableRowRenderer } from "../Content/EntityTableUnifiedTableRowRenderer";
import { EventDispatchersType } from "../StatelessTable.d";
import { RenderIfSpecified } from "layouts/RenderIfSpecified";
import { arrayIncludes, isNonEmptyArray, isSpecified } from "components/Utils/MiscUtils";

export interface EntityTableRowsetProps {
  tableData: Array<ObjectWithIdFieldType>;
  columns: TableColumnParameters[];
  onRowClickFunction?: OnRowClickFunctionType;
  eventDispatchers?: EventDispatchersType;
  highlightedRowsPrimaryKeys?: Array<ObjectIdType>;
  actionsAllowed?: Array<string>;
  zeroPadding?: boolean;
  tableRowContainerElement: "tr" | "div";
  autoFocusOnFirstRow?: boolean;
  restrictActions?: Array<string>;
}

export function EntityTableRowset(props: EntityTableRowsetProps) {
  const [focusOnFirstRowPerformed, setFocusOnFirstRowPerformed] = useState(false);

  // TODO: support guard conditions for buttons
  // TODO: extract array isSelected calculation outside
  const onRowClickFunctionCallback = props.onRowClickFunction;

  useEffect(() => {
    if (
      !focusOnFirstRowPerformed &&
      props.autoFocusOnFirstRow &&
      isNonEmptyArray(props.tableData) &&
      isSpecified(onRowClickFunctionCallback)
    ) {
      const rowDataObject = props.tableData[0];
      onRowClickFunctionCallback(rowDataObject.id, rowDataObject);
      setFocusOnFirstRowPerformed(true);
    }
  }, [props.tableData, props.autoFocusOnFirstRow, onRowClickFunctionCallback, focusOnFirstRowPerformed]);

  return (
    <RenderIfSpecified value={props.tableData}>
      {(tableData: Array<ObjectWithIdFieldType>) => {
        return (
          <React.Fragment>
            {tableData.map((rowDataObject: ObjectWithIdFieldType, rowIndex: number) => {
              const isSelected = arrayIncludes(props.highlightedRowsPrimaryKeys, rowDataObject.id);

              return (
                <EntityTableUnifiedTableRowRenderer
                  key={rowIndex}
                  isSelected={isSelected}
                  onRowClickFunction={props.onRowClickFunction}
                  rowDataObject={rowDataObject}
                  tableRowContainerElement={props.tableRowContainerElement}
                  zeroPadding={props.zeroPadding}
                  eventDispatchers={props.eventDispatchers}
                  tableColumns={props.columns}
                  restrictActions={props.restrictActions}
                />
              );
            })}
          </React.Fragment>
        );
      }}
    </RenderIfSpecified>
  );
}
