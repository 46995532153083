import SessionService from "./SessionService";

export class StorageUtils {
  // Storage methods

  public static updateUserActivityDetails(obj: Record<string, any>) {
    const storage = StorageUtils.getStorage(StorageUtils.isLocalPersisted());

    const currentValue = storage.getItem(SessionService.SESSION_USER_ACTIVITY_PREFIX);

    const newActivityDetails = Object.assign({}, JSON.parse(currentValue), obj);

    storage.setItem(SessionService.SESSION_USER_ACTIVITY_PREFIX, JSON.stringify(newActivityDetails));
  }

  public static isLocalPersisted(): boolean {
    return window.localStorage.getItem(SessionService.SESSION_STORAGE_KEY) != null;
  }

  public static getStorage(rememberMe: boolean): Storage {
    if (rememberMe) {
      return window.localStorage;
    } else {
      return window.sessionStorage;
    }
  }

  public static clear() {
    window.localStorage.clear();
    window.sessionStorage.clear();
  }
}
