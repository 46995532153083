import { StyleRulesCallback, Theme } from "@material-ui/core";

export const entityTableUnifiedTableBodyRendererStyle: StyleRulesCallback<Theme, {}> = (theme) => ({
  singleColumnCardRowStyle: {
    overflowY: "auto",
    flexShrink: 1,
    flexGrow: 1,
    flexBasis: 0,
    "& > div": {
      width: "100%",
    },
  },
  doubleColumnCardRowStyle: {
    display: "flex",
    flexWrap: "wrap",
    marginLeft: "-12px",
    marginRight: "-12px",
  },
});
