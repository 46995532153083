export const businessTypeOptions = [
  { code: 1, label: "Agriculture" },
  { code: 2, label: "Mining" },
];

export const enterpriseStatusOptions = [
  { code: 0, label: "Disabled" },
  { code: 1, label: "Active" },
];

export const enterpriseTypeOptions = [
  { code: 1, label: "Individual" },
  { code: 2, label: "Group" },
  { code: 3, label: "Government" },
  { code: 5, label: "Corporate" },
];
