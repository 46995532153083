import { StyleRulesCallback, Theme } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";

const customRadioStyle: StyleRulesCallback<Theme, {}> = (theme) => ({
  root: {
    display: "flex",
    height: "36px",
    boxSizing: "border-box",
    borderColor: grey[300],
    borderStyle: "solid",
    borderWidth: "1px",
    borderTopLeftRadius: "4px",
    borderBottomLeftRadius: "4px",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
    cursor: "pointer",
    overflow: "hidden",
    "& > div": {
      flexGrow: 1,
      display: "flex",
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  radioIconLabel: {
    "& button": {
      color: theme.palette.text.secondary,
    },
  },
  radioTextLabel: {
    fontSize: "14px",
    color: theme.palette.text.primary,
    paddingLeft: "16px",
    paddingRight: "16px",
  },
  checked: {
    backgroundColor: grey[200],
  },
  unchecked: {
    backgroundColor: "white",
  },
});

export default customRadioStyle;
