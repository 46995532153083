import React from "react";
import { makeStyles, List, Divider } from "@material-ui/core";
import { sidebarStyle } from "./sidebarStyle";
import clsx from "clsx";
import { CardSubTitle } from "components/Elements/Typography/CardSubTitle";
import { isNonEmptyArray } from "components/Utils/MiscUtils";

export interface SidebarItemGroup {
  groupTitle?: string | JSX.Element;
  hideGroupWithEmptyContent?: boolean;
  open: boolean;
  children: JSX.Element[] | JSX.Element;
}

const useStyles = makeStyles(sidebarStyle, { name: "SidebarItemGroup" });

export function SidebarItemGroup(props: SidebarItemGroup) {
  const classes = useStyles({});

  return (
    <div>
      {props.hideGroupWithEmptyContent &&
      (!props.children || !isNonEmptyArray(props.children)) ? undefined : (
        <React.Fragment>
          {props.groupTitle && (
            <div className={classes.groupTitleItem}>
              {props.open && (
                <div
                  className={clsx(
                    classes.groupTitleDivider,
                    props.open ? classes.sideBarContentContainerOpen : classes.sideBarContentContainerClosed
                  )}
                >
                  <CardSubTitle>{props.groupTitle}</CardSubTitle>
                </div>
              )}
              {!props.open && <Divider className={classes.groupTitleDivider} />}
            </div>
          )}
          <div
            className={clsx(
              props.open ? classes.sideBarContentContainerOpen : classes.sideBarContentContainerClosed
            )}
          >
            <List className={classes.list}>{props.children}</List>
          </div>
        </React.Fragment>
      )}
    </div>
  );
}
