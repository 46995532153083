import React from "react";
import { FieldConfiguration } from "components/Crud/Form/Fields/FieldConfiguration.d";
import { MONITOR_API_URL } from "./Monitor.const";
import { SITE_API_URL } from "views/Site/Site.const";
import { DataCrudEntityFormBinding } from "components/Crud/Form/DataCrudEntityFormBinding";
import { ALL_ENTERPRISES_CODE } from "views/Enterprise/Enterprise.const";
import { EntityFormParams } from "components/Crud/Form/EntityForm.d";

export function MonitorEditForm(props: EntityFormParams) {
  const getFieldConfigs = (): FieldConfiguration[] => {
    // const userData = props.userData;
    // const showEnterpriseField = UserUtils.hasMultipleMemberships(userData) || userData.isSuperAdmin;

    return [
      {
        fieldName: "name",
        label: "Name",
        type: "text",
        validation: {
          required: true,
        },
      },
      {
        fieldName: "commsAddress",
        label: "Comms Address",
        disabled: true,
        type: "text",
      },
      {
        fieldName: "siteId",
        label: "Site",
        type: "select-async", // type: showEnterpriseField ? 'select-async' : 'hidden',
        fieldParams: {
          optionDefinitionEndpoint: SITE_API_URL,
          optionCodeField: "id",
          optionLabelField: "name",
          filterUrl:
            props.currentEnterpriseId !== ALL_ENTERPRISES_CODE
              ? `?filter=enterpriseId::eq::${props.currentEnterpriseId}`
              : undefined,
          autoComplete: true,
        },
      },
      {
        fieldName: "fwVersion.code",
        label: "Firmware version",
        disabled: true,
        type: "text",
      },
      {
        fieldName: "hwVersion.code",
        label: "Hardware version",
        disabled: true,
        type: "text",
      },
    ];
  };

  const dataSource = {
    apiUrl: MONITOR_API_URL,
    customUrlRequestFragment: `join=fwVersion&join=hwVersion`,
    // customUrlRequestFragment: `groupId=${props.assetGroupId}`
  };

  return (
    <DataCrudEntityFormBinding
      id={props.id} // TODO: do we need this???
      dataSource={dataSource}
      action={props.action}
      title={props.title}
      fieldConfigurations={getFieldConfigs()}
      onCloseAdditionalActions={props.onCloseAdditionalActions}
      showCloseButton={props.showCloseButton}
      userData={props.userData}
      currentEnterpriseId={props.currentEnterpriseId}
    />
  );
}
