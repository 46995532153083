import { useEffect, useState, useContext, useReducer } from "react";
import { MenuItemType, SelectAsyncConfiguration } from "../Form/Fields/FieldConfiguration.d";
import { getDropdownOptionsFromDB } from "components/Utils/CRUDUtils";
import { FetchResultType, OPERATION_ERROR } from "components/Utils/CRUDUtils.d";
import { MessageBannerDispatcherContext } from "components/Dashboard/Message/MessageBannerFrame";
import { twoArraysAreIdentical } from "components/Utils/ObjectUtils";
import { getAttributeByPath } from "../EntityUtils";
import { FieldsInProgressActionTypeEnum, DispatchFieldsInProgressFunctionType } from "../Form/EntityForm.d";
import { AsyncValuesReducer } from "./AsyncSelectOptionsProvider.reducer";
import { AsyncValuesAction, ValueOptionsStateType } from "./AsyncSelectOptionsProvider.d";

export interface AsyncSelectOptionsProviderProps {
  fieldName: string;
  fieldParams: SelectAsyncConfiguration;
  value: string | number;
  children: (
    valueOptions: Array<MenuItemType>,
    sanitizedValue: string | number,
    valueOptionsAreAvailable: boolean
  ) => JSX.Element;
  filterUrl?: string; // TODO: refactor. current implementation is to ensure refetching on change
  extraStaticValues?: Array<MenuItemType>;
  dispatchFieldsInProgress?: DispatchFieldsInProgressFunctionType;
}

const initialAsyncValuesReducerState: ValueOptionsStateType = {
  valueOptionsAreAvailable: false,
  valueOptions: [{ code: "", label: "Loading..." }] as Array<MenuItemType>,
};

// IMPORTANT SCENARIO TO FACTOR IN:
// Quite often props.value might change before values are fetched from DB
// For example when parent component does some kind of data fetching as well
function AsyncSelectOptionsProvider(props: AsyncSelectOptionsProviderProps) {
  const messageBannerDispatcher = useContext(MessageBannerDispatcherContext);

  // TODO: static values should be available even if other options were not loaded

  const [valueOptions, dispatchSetValueOptions] = useReducer(
    AsyncValuesReducer,
    initialAsyncValuesReducerState
  );

  const [staticOptions, setStaticOptions] = useState(props.extraStaticValues);

  const staticOptionsChanged = !twoArraysAreIdentical(staticOptions, props.extraStaticValues);

  useEffect(() => {
    if (staticOptionsChanged) {
      setStaticOptions(props.extraStaticValues);
    }
  }, [props.extraStaticValues, staticOptionsChanged]);

  const propsFieldName = props.fieldName;
  const propsFieldParamsOptionDefinitionEndpoint = props.fieldParams.optionDefinitionEndpoint;
  const propsFieldParamsOptionCodeField = props.fieldParams.optionCodeField;
  const propsFieldParamsOptionLabelField = props.fieldParams.optionLabelField;
  const propsFieldParamsFetchResultTransformer = props.fieldParams.fetchResultTransformer;
  const propsFieldParamsDisableFetching = props.fieldParams.disableFetching;
  const propsFilterUrl = props.filterUrl;

  const dispatchFieldsInProgress = props.dispatchFieldsInProgress;
  useEffect(() => {
    let didCancel = false;

    if (!propsFieldParamsDisableFetching) {
      const asyncFunctionWrapper = async () => {
        // setValueOptionsAreAvailable(false);
        dispatchSetValueOptions({ action: AsyncValuesAction.FLUSH_VALUES });

        const sysdate = new Date();
        if (dispatchFieldsInProgress) {
          dispatchFieldsInProgress({
            type: FieldsInProgressActionTypeEnum.ADD,
            payload: { field: propsFieldName, timeStamp: sysdate.getTime() },
          });
        }

        const options: FetchResultType = await getDropdownOptionsFromDB(
          `${propsFieldParamsOptionDefinitionEndpoint}${propsFilterUrl || ""}`
        );

        if (dispatchFieldsInProgress) {
          dispatchFieldsInProgress({
            type: FieldsInProgressActionTypeEnum.REMOVE,
            payload: { field: propsFieldName, timeStamp: sysdate.getTime() },
          });
        }

        if (options.status.type === OPERATION_ERROR) {
          messageBannerDispatcher(
            /* .dispatchMessage */ {
              messageType: OPERATION_ERROR,
              messageText: `Error while fetching options for ${propsFieldName}`,
            }
          );

          console.error(`Error ${options.status.message} while fetching options for ${propsFieldName}`);
        } else if (!didCancel) {
          let dbOptions: any;

          if (propsFieldParamsFetchResultTransformer) {
            dbOptions = propsFieldParamsFetchResultTransformer(options.data);
          } else {
            dbOptions = options.data;
          }

          let newSetOfValues = dbOptions.map((dbCodeLabel: Record<string, unknown>) => {
            return {
              code: getAttributeByPath(dbCodeLabel, propsFieldParamsOptionCodeField),
              label: getAttributeByPath(dbCodeLabel, propsFieldParamsOptionLabelField),
            };
          });

          if (staticOptions && staticOptions.length > 0) {
            newSetOfValues = staticOptions.concat(newSetOfValues);
          }

          dispatchSetValueOptions({
            action: AsyncValuesAction.UPDATE_VALUES,
            payload: newSetOfValues,
          });
          // setValueOptions(newSetOfValues);

          // setValueOptionsAreAvailable(true);
        }
      };

      asyncFunctionWrapper();
    }

    return () => {
      didCancel = true;
    };
  }, [
    propsFieldName,
    propsFieldParamsOptionDefinitionEndpoint,
    propsFieldParamsOptionCodeField,
    propsFieldParamsOptionLabelField,
    propsFieldParamsFetchResultTransformer,
    propsFilterUrl,
    propsFieldParamsDisableFetching,
    staticOptions,
    messageBannerDispatcher,
    dispatchFieldsInProgress,
  ]);

  // Resetting the dictionary of the possible options

  const valueOptionsAreAvailable = valueOptions.valueOptionsAreAvailable;

  const sanitizedValue = valueOptionsAreAvailable ? props.value : "";

  return props.children(valueOptions.valueOptions, sanitizedValue, valueOptions.valueOptionsAreAvailable);
}

export default AsyncSelectOptionsProvider;
