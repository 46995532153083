import { FilterMatchTypes } from "./CRUDUtils.d";

// Note: at the moment function only accepts arrays with 2 elements. entry value and translation value
export const translateValue = (inputValue: any, translator: Array<any> | Function | Map<any, any>) => {
  // TODO: support other types of translators
  // TODO: typefy array to contain 2 element arrays or objects with 2 props

  let resultValue: any = inputValue;

  if (translator && Array.isArray(translator)) {
    const translatorEntry = translator.find((entry) => entry[0] === inputValue);

    if (translatorEntry !== undefined) {
      resultValue = translatorEntry[1];
    }
  }

  return resultValue;
};

export const pcntOf = (percentValue: string | number, inputValue: number) => {
  const parsedPercent = typeof percentValue === "number" ? percentValue : parseFloat(percentValue);

  return (inputValue * parsedPercent) / 100;
};

export const isSpecified = (inputValue: any) => {
  return inputValue !== null && inputValue !== undefined;
};

export const isFormSpecified = (inputValue: any) => {
  return inputValue !== null && inputValue !== undefined && inputValue !== "";
};

export const isNonEmptyArray = (inputArray: any, size?: number) => {
  const defaultSize = size || 1;

  return isSpecified(inputArray) && Array.isArray(inputArray) && inputArray.length >= defaultSize
    ? true
    : false;
};

export const arrayIncludes = (inputArray: Array<any>, inputValue: any) => {
  const result = false;

  if (isNonEmptyArray(inputArray)) {
    return inputArray.findIndex((arrayItem) => arrayItem === inputValue) >= 0;
  }

  return result;
};

export const glueArrays = (inputArrayHierarchy: Array<Array<any>>) => {
  let result: Array<any> = [];

  for (let i = 0; i < inputArrayHierarchy.length; i++) {
    if (inputArrayHierarchy[i].length > 0) {
      result = result.concat(inputArrayHierarchy[i]);
    }
  }

  return result;
};

export const rgbToHex = (rgb: number) => {
  let hex = Number(rgb).toString(16);
  if (hex.length < 2) {
    hex = `0${hex}`;
  }

  return hex;
};

export const fullColorHex = function (r: number, g: number, b: number) {
  const red = rgbToHex(r);
  const green = rgbToHex(g);
  const blue = rgbToHex(b);
  return `#${red}${green}${blue}`;
};

export type filterByType =
  | "==="
  | "="
  | "=="
  | ">"
  | "<"
  | "%"
  | "<>"
  | "!="
  | "empty"
  | "not empty"
  | "!empty";

export const toFilterMatchType = (matchType: filterByType): FilterMatchTypes => {
  let filterMatchType: FilterMatchTypes;

  switch (matchType) {
    case "<":
      filterMatchType = FilterMatchTypes.LOWER_THAN;
      break;
    case ">":
      filterMatchType = FilterMatchTypes.GREATER_THAN;
      break;
    case "%":
      filterMatchType = FilterMatchTypes.CONTAINS;
      break;
    case "<>":
    case "!=":
      filterMatchType = FilterMatchTypes.NOT_EQUAL;
      break;
    case "empty":
      filterMatchType = FilterMatchTypes.IS_EMPTY;
      break;
    case "not empty":
    case "!empty":
      filterMatchType = FilterMatchTypes.IS_NOT_EMPTY;
      break;
    case "=":
    case "==":
    case "===":
    default:
      filterMatchType = FilterMatchTypes.EQUAL;
  }

  return filterMatchType;
};

export const filterBy = (
  attributeName: string,
  matchType: filterByType,
  attributeValue: string | number,
  individualKey?: boolean
) => {
  const criteriaType = toFilterMatchType(matchType);

  return {
    attributeName: attributeName,
    criteriaType: criteriaType,
    attributeValue: attributeValue,
    individualKey: individualKey,
  };
};
