import { StyleRulesCallback, Theme } from "@material-ui/core";

export const graphComponentHeaderContainerStyle: StyleRulesCallback<Theme, {}> = (theme) => ({
  root: {
    paddingLeft: "24px",
    paddingRight: "24px",
    display: "flex",
    height: "56px",
    boxShadow: "inset -1px -1px 0 0 #EEEEEE",
    alignItems: "center",
  },
});
