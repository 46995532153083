import React from "react";
import { ObjectIdType } from "components/Utils/Object.d";
import { MainPanelWithParamsProps } from "components/Dashboard/MainPanelWithParams.d";
import { MonitorSummaryContent } from "./MonitorSummaryContent";

export interface MonitorSummaryScreenProps {
  monitorId: ObjectIdType;
}

export function MonitorSummaryScreen(props: MainPanelWithParamsProps<MonitorSummaryScreenProps>) {
  const monitorId = props.match.params.monitorId;

  return <MonitorSummaryContent monitorId={monitorId} userData={props.userData} />;
}
