import React, { useState, useEffect } from "react";
import LinkIcon from "@material-ui/icons/Link";
import { SAMPLEPOINT_API_URL, SAMPLEPOINT_ENTITY_NAME } from "./SamplePoint.const";
import { getEntityListRoute } from "components/Utils/RouteUtils";
import { DataOwnerParameters, UnifiedDataRequestType, SORT_ASC } from "components/Utils/CRUDUtils.d";
import { generateEntityPathString } from "components/Utils/CRUDUtils";
import {
  SAMPLEPOINT_SENSOR_PAIRING_ACTION,
  SAMPLEPOINT_SENSOR_PAIRING_ROUTE,
} from "./SamplePointSensorPairing/SamplePointSensorPairing.const";
import { ROUTE_LIB } from "components/Utils/RouteList";
import { BaseListProps, TableColumnParameters, TableColumnFilter } from "components/Crud/Table/EntityTable.d";
import {
  generateCreateButtonConfig,
  generateEditButtonConfig,
  generateShowButtonConfig,
  generateDeleteButtonConfig,
} from "components/Buttons/ButtonsCommon";
import { DataSourceParamsType } from "components/Crud/DataProvider/DataProviderCommon.d";
import { filterBy } from "components/Utils/MiscUtils";
import { CrudQueryTableBinding } from "components/Crud/Table/CrudQueryTableBinding";
import { useVersion } from "components/Utils/CustomHooks";
import { TableActionButtonFace } from "components/Buttons/TableActionButtonFace";
import { SamplePointEditForm } from "./SamplePointEditForm";
import { ObjectIdType } from "components/Utils/Object.d";

export interface SamplePointListProps {
  assetId: ObjectIdType;
}

export function SamplePointList(props: BaseListProps & SamplePointListProps) {
  const [version, increaseVersion] = useVersion();

  const TITLE_SUFFIX = "Sample Points";
  const [title, setTitle] = useState(TITLE_SUFFIX);

  useEffect(() => {
    const dataOwner: DataOwnerParameters[] = [
      {
        endpointUrl: `${ROUTE_LIB.ASSET}/${props.assetId}`,
        fields: ["name"],
        titlePrefixTemplate: ":name > ",
      },
    ];

    generateEntityPathString(dataOwner, (value: string) => setTitle(`${value}${TITLE_SUFFIX}`));
  }, [props.assetId]);

  const columns: TableColumnParameters[] = [
    // { name: 'id', label: 'Id', width: 50, align: 'center'},
    { sourceName: "name", label: "Name" },
    { sourceName: "sampleType.name", label: "Sample Type" },
    {
      disableSorting: true,
      actionSet: [
        {
          labelElement: (
            <TableActionButtonFace>
              <LinkIcon />
              &nbsp;<span>Sensor Pairing</span>
            </TableActionButtonFace>
          ),
          name: "sensor_pairing",
          onClick: {
            type: "navigate",
            url: getEntityListRoute(SAMPLEPOINT_SENSOR_PAIRING_ROUTE),
            paramName: "samplePointId",
            urlSearch: { siteId: { valueSource: "asset.siteId" } },
          },
          actionCode: SAMPLEPOINT_SENSOR_PAIRING_ACTION,
          actionObject: SAMPLEPOINT_ENTITY_NAME,
        },
        generateEditButtonConfig(SAMPLEPOINT_ENTITY_NAME, SamplePointEditForm, [increaseVersion], {
          title: "Edit Sample Point Details",
        }),
        generateShowButtonConfig(SAMPLEPOINT_ENTITY_NAME, SamplePointEditForm, {
          title: "Show Sample Point Details",
        }),
        generateDeleteButtonConfig(SAMPLEPOINT_ENTITY_NAME),
      ],
    },
  ];

  const uiFilters: Array<TableColumnFilter> = [];

  const dataSource: DataSourceParamsType = {
    apiUrl: SAMPLEPOINT_API_URL,
  };

  const initialRequest: UnifiedDataRequestType = {
    sortByColumn: "name",
    sortDirection: SORT_ASC,
    fieldList: ["name", "sampleType.name", "asset.siteId"],
    filter: {
      baseFilters: [filterBy("assetId", "=", props.assetId)],
    },
    isPaginationEnabled: true,
    rowsPerPage: 10,
    pageNum: 0,
  };

  const headerActions = [
    generateCreateButtonConfig(
      SAMPLEPOINT_ENTITY_NAME,
      "ADD SAMPLE POINT",
      SamplePointEditForm,
      [increaseVersion],
      { assetId: props.assetId, title: "Add Sample Point" }
    ),
  ];

  return (
    <CrudQueryTableBinding
      externalTriggerValues={[props.assetId, props.currentEnterpriseId, props.userData, version]}
      dataSource={dataSource}
      intialQuery={initialRequest}
      title={title}
      tableColumns={columns}
      onRowClickFunction={props.onRowClickFunction}
      // actionsAllowed={allowedActions}
      highlightedRowsPrimaryKeys={props.activeRowPrimaryKeys}
      uiFilters={uiFilters}
      headerActions={headerActions}
    />
  );
}
