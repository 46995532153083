import React, { useState, useRef } from "react";
import { Popper, ClickAwayListener, Grow } from "@material-ui/core";

export interface PopupMenuProps {
  clickableVisibleItem: (handleClick: (event: React.MouseEvent<Element, MouseEvent>) => void) => JSX.Element;
  children: (menuCloseActions: () => void, disableMenuInput: () => void) => JSX.Element;
}

function PopupMenu(props: PopupMenuProps) {
  // TODO: Popper logic must be componetized
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [menuInputDisabled, setMenuInputDisabled] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const _popperAnchorRef = useRef(null);

  const stopEventFromReachingCompetingHandler = (event: React.MouseEvent<Element, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleMenuClick = (event: React.MouseEvent<Element, MouseEvent>) => {
    stopEventFromReachingCompetingHandler(event);

    if (menuIsOpen) {
      closeMenu();
    } else {
      openMenu();
    }
  };

  const openMenu = () => {
    setMenuInputDisabled(false);
    setMenuIsOpen(true);
    setAnchorEl(_popperAnchorRef.current);
  };

  const closeMenu = (): void => {
    setMenuIsOpen(false);
    setAnchorEl(null);
  };

  // TODO: do proper type casting
  const handlePopperClickAway = (event: React.MouseEvent<any, MouseEvent>) => {
    if (menuIsOpen) {
      stopEventFromReachingCompetingHandler(event);
      closeMenu();
    }
  };

  const disableMenuInput = (): void => {
    setMenuInputDisabled(true);
  };

  // TODO: this is wild hack to hide Menu A, but keep it in the hierarchy if it initiates menu B
  // TODO: find a better way to support this functionality
  // TODO: move to styles and clsx
  const popperStyle: Record<string, any> = {};

  if (menuInputDisabled) {
    popperStyle.display = "none";
  } else {
    popperStyle.zIndex = 2000;
  }

  return (
    // TODO: why Popper was introduced for the UserMenu? Why simple Menu was not used???? Was it because MUI did not support it?
    <div ref={_popperAnchorRef}>
      {props.clickableVisibleItem(handleMenuClick)}
      <Popper
        open={menuIsOpen}
        anchorEl={anchorEl}
        transition
        // keepMounted={true}
        style={popperStyle}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handlePopperClickAway} touchEvent={false}>
            <Grow {...TransitionProps} in={menuIsOpen} style={{ transformOrigin: "0 0 0" }}>
              {props.children(closeMenu, disableMenuInput)}
            </Grow>
          </ClickAwayListener>
        )}
      </Popper>
    </div>
  );
}

export default PopupMenu;
