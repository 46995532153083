import History from "history";
import { hasAttributeByPath, getAttributeByPath } from "components/Crud/EntityUtils";
import { LIST_URL_SUFIX, EDIT_URL_SUFIX } from "components/Crud/Entity.const";
import { TemplateParams, populateStringTemplate } from "components/Utils/StringUtils";
import { ROUTE_LIB } from "./RouteList";
import { UserParams } from "views/User/UserParams.d";
import { EnterpriseIdType } from "views/Enterprise/Enterprise.d";
import { isNonEmptyArray, isSpecified, isFormSpecified } from "./MiscUtils";
import { PropertyFilterCriteriaType, FilterMatchTypes, UnifiedDataRequestType, Order } from "./CRUDUtils.d";
import produce from "immer";
import { TableColumnFilter } from "components/Crud/Table/EntityTable.d";
import { ObjectIdType } from "./Object.d";
import { RouteSearchKeys } from "./RouteUtils.d";
import { EntityActionType } from "components/Crud/Entity.d";

export const APP_URL_PREFIX = "/app";
export const DEFAULT_ASSET_ROUTE = `${APP_URL_PREFIX}${ROUTE_LIB.ASSET_INVENTORY}/list`;
export const DEFAULT_ALL_ALERTS_ROUTE = `${APP_URL_PREFIX}${ROUTE_LIB.OVERVIEW_ALL_ALERTS}/list`;
export const DEFAULT_OVERVIEW_ROUTE = `${APP_URL_PREFIX}${ROUTE_LIB.OVERVIEW_SUMMARY}`;

export const DYNAMIC_ID = "/:id";
export const DYNAMIC_ACTION = "/:action";

export function defaultRouteByUserAndEnterprise(userData: UserParams, enterpriseId: EnterpriseIdType) {
  let result: string;

  if (!isSpecified(userData) || (!userData.isSuperAdmin && !isNonEmptyArray(userData.memberships))) {
    result = DEFAULT_OVERVIEW_ROUTE;
  } else if (userData.isSuperAdmin) {
    result = DEFAULT_ASSET_ROUTE;
  } else {
    result = DEFAULT_ALL_ALERTS_ROUTE;
  }

  return result;
}

export function getAggregatedPageRoute(entityUrl: string): string {
  return `${APP_URL_PREFIX}${entityUrl}`;
}

export function generateEntityActionRoute(entityUrl: string, action: EntityActionType, id?: ObjectIdType) {
  // TODO: somewhat duplicates the functions (getEntityEditRoute, getEntityListRoute) below. Refactor.

  const actionUrlSuffix = `/${action}`;
  const idUrlSuffix = id ? `${id}` : "";

  return `${APP_URL_PREFIX}${entityUrl}${actionUrlSuffix}/${idUrlSuffix}`;
}

export function getEntityEditRoute(entityUrl: string, id?: number, action?: EntityActionType): string {
  if (id) {
    return `${APP_URL_PREFIX}${entityUrl}${EDIT_URL_SUFIX}/${id}?`;
  } else {
    return `${APP_URL_PREFIX}${entityUrl}${DYNAMIC_ACTION}${DYNAMIC_ID}?`;
  }
}

export function getEntityListRoute(entityUrlTemplate: string, params?: TemplateParams): string {
  let filledUrl: string = entityUrlTemplate;
  if (params) {
    filledUrl = populateStringTemplate(entityUrlTemplate, params);
  }

  const result = `${APP_URL_PREFIX}${filledUrl}${LIST_URL_SUFIX}`;

  return result;
}

// verifies if routeName is the one active (in browser input)
export function activeRoute(routeName: string) {
  const pathname = window.location.pathname;

  /* TODO: legacy code, find out which cases were planned to be supported and check them.
  let segLength = routeName.split('/').length;
  // TODO improve this
  let prePath = (segLength > 2 && (!routeName.includes('dashboard'))) ? 
                  routeName.substring(0, routeName.lastIndexOf('/')) : routeName;

  let result = pathname.indexOf(prePath) > -1; */

  return pathname === routeName;
}

export function parseSearchStringIntoScreenRequest(inputLocation: History.Location) {
  const searchString = inputLocation.search;

  const result = {
    request: {} as Partial<UnifiedDataRequestType>,
    tabId: undefined as number,
  };

  if (isFormSpecified(searchString)) {
    const searchParams = new URLSearchParams(searchString);

    searchParams.forEach((value: string, key: string) => {
      if (key === RouteSearchKeys.PAGE_NUM) {
        result.request.pageNum = parseInt(value, 10);
      } else if (key === RouteSearchKeys.ROWS_PER_PAGE) {
        result.request.rowsPerPage = parseInt(value, 10);
      } else if (key === RouteSearchKeys.SORT_BY) {
        result.request.sortByColumn = value;
      } else if (key === RouteSearchKeys.SORT_DIRECTION) {
        result.request.sortDirection = value as Order;
      } else if (key === RouteSearchKeys.TAB_ID) {
        result.tabId = parseInt(value, 10);
      } else {
        if (!result.request.hasOwnProperty("filter")) {
          result.request.filter = {
            propertyFilters: {} as PropertyFilterCriteriaType,
          };
        }
        const newPropertyFilter = {
          attributeValue: value,
          criteriaType: FilterMatchTypes.DEFAULT,
        };

        result.request.filter.propertyFilters[key] = newPropertyFilter;
      }
    });
  }

  if (result.tabId === undefined) {
    delete result.tabId;
  }

  return result;
}

export function refineRequest(
  inputRequest: Partial<UnifiedDataRequestType>,
  uiFilters: Array<TableColumnFilter>
) {
  const FILTER_TYPE_TEXT = "text";

  const result = produce(inputRequest || {}, (draftRequest) => {
    if (hasAttributeByPath(draftRequest, "filter.propertyFilters")) {
      const propertyFilters = getAttributeByPath(draftRequest, "filter.propertyFilters");
      const filterKeys: Array<string> = Object.keys(propertyFilters);

      filterKeys.forEach((keyItem) => {
        const fieldInUiFilter = uiFilters.find((filterItem) => filterItem.filterDataSourceField === keyItem);

        if (fieldInUiFilter === undefined) {
          delete draftRequest.filter.propertyFilters[keyItem];
        } else {
          const propertyFilter = draftRequest.filter.propertyFilters[keyItem];

          if (
            propertyFilter.criteriaType === FilterMatchTypes.DEFAULT &&
            fieldInUiFilter.filterType === FILTER_TYPE_TEXT
          ) {
            propertyFilter.criteriaType = FilterMatchTypes.CONTAINS;
          } else if (propertyFilter.criteriaType === FilterMatchTypes.DEFAULT) {
            propertyFilter.criteriaType = FilterMatchTypes.EQUAL;
            propertyFilter.attributeValue = parseInt(propertyFilter.attributeValue as string, 10);
          }
        }
      });

      // Remove empty property filters
      if (Object.keys(draftRequest.filter.propertyFilters).length === 0) {
        delete draftRequest.filter.propertyFilters;
      }
    }

    if (
      draftRequest.hasOwnProperty("filter") &&
      (!draftRequest.filter.hasOwnProperty("propertyFilters") || draftRequest.filter === undefined)
    ) {
      delete draftRequest.filter;
    }
  });

  return result;
}
