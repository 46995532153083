import React from "react";
import { FieldConfiguration } from "components/Crud/Form/Fields/FieldConfiguration.d";
import { ALL_ENTERPRISES_CODE } from "views/Enterprise/Enterprise.const";
import { SITE_API_URL } from "views/Site/Site.const";
import { API_LIB } from "components/Utils/API_LIB";
import { DataCrudEntityFormBinding } from "components/Crud/Form/DataCrudEntityFormBinding";
import { AssetTypeEnum } from "components/Dictionaries/Dictionaries.d";
import { EntityFormParams } from "components/Crud/Form/EntityForm.d";

export function AssetEditForm(props: EntityFormParams) {
  const getFieldConfigs = (): FieldConfiguration[] => {
    // const userData = props.userData;
    // const currentEnterpriseId = props.currentEnterpriseId;

    // const disableEntepriseField: boolean = (currentEnterpriseId === ALL_ENTERPRISES_CODE || currentEnterpriseId === NONE_SELECT_CODE) ? false : true ;
    // const showEnterpriseField = UserUtils.hasMultipleMemberships(userData) || userData.isSuperAdmin;

    return [
      {
        fieldName: "name",
        label: "Name",
        type: "text",
        validation: {
          required: true,
        },
      },
      {
        fieldName: "assetTypeId",
        label: "Asset Type",
        type: "select-async",
        validation: { required: true },
        fieldParams: {
          optionDefinitionEndpoint: API_LIB.ASSET_TYPE,
          optionCodeField: "id",
          optionLabelField: "name",
        },
        defaultValue: AssetTypeEnum.WATER_TANK,
      },
      {
        fieldName: "userComments",
        label: "Comments",
        type: "text",
        fieldParams: { rows: 5 },
        validation: {
          maxLength: 2048,
        },
      },
      {
        fieldName: "operatorComments",
        label: "Operator Comments",
        type: "text",
        hideIf: (value: string, obj: any, editType: string): boolean => {
          return !props.userData.isSuperAdmin;
        },
        fieldParams: { rows: 5 },
        validation: {
          maxLength: 2048,
        },
      },
      {
        fieldName: "siteId",
        label: "Site",
        type: "select-async", // type: showEnterpriseField ? 'select-async' : 'hidden',
        // defaultValue: translateValue(currentEnterpriseId, [[ALL_SITES_CODE, NONE_SELECT_CODE]]),
        // disabled: disableEntepriseField,
        fieldParams: {
          optionDefinitionEndpoint: SITE_API_URL,
          optionCodeField: "id",
          optionLabelField: "name",
          filterUrl:
            props.currentEnterpriseId !== ALL_ENTERPRISES_CODE
              ? `?filter=enterpriseId::eq::${props.currentEnterpriseId}`
              : undefined,
          autoComplete: true,
        },
        validation: { required: true },
      },
    ];
  };

  const dataSource = {
    apiUrl: API_LIB.ASSET_INVENTORY_LIST,

    // customUrlRequestFragment: `groupId=${props.assetGroupId}`
  };

  return (
    <DataCrudEntityFormBinding
      id={props.id} // TODO: do we need this???
      dataSource={dataSource}
      action={props.action}
      title={props.title}
      fieldConfigurations={getFieldConfigs()}
      onCloseAdditionalActions={props.onCloseAdditionalActions}
      showCloseButton={props.showCloseButton}
      userData={props.userData}
      currentEnterpriseId={props.currentEnterpriseId}
    />
  );
}
