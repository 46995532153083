import React from "react";
import moment from "moment-timezone";
import { priorityDictionary } from "components/Dictionaries/DictionariesCommon";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import { blueGrey } from "@material-ui/core/colors";
import { useMediaQueryMatchDown } from "components/Utils/LayoutUtils";
import { makeStyles } from "@material-ui/core";
import { activeAlertCardStyle } from "views/Alert/activeAlertCardStyle";
import { isFormSpecified, isNonEmptyArray } from "components/Utils/MiscUtils";
import { getDictionaryItemByCode } from "components/Dictionaries/DictionariesUtils";
import { EventLevel } from "components/Dictionaries/Dictionaries.d";
import { VariantType } from "./Alert.d";

export interface AlertLevelMessageBlockProps {
  message: string; // alertData.event.message
  eventLevelId: EventLevel; // alertData.event.level
  eventDate: Date; //alertData.event.date
  actionedBy?: Array<string>;
  variant?: "default" | VariantType;
}

const useStyles = makeStyles(activeAlertCardStyle, { name: "ActiveAlertCard" });

export function AlertLevelMessageBlock(props: AlertLevelMessageBlockProps) {
  const isSmallScreen = useMediaQueryMatchDown("sm");

  let calculatedVariant: VariantType;
  if (props.variant === "large") {
    calculatedVariant = "large";
  } else if (props.variant === "small") {
    calculatedVariant = "small";
  } /* (props.variant || 'default') === 'default'*/ else {
    calculatedVariant = isSmallScreen ? "small" : "large";
  }

  const classes = useStyles({});

  const eventLevelItem = getDictionaryItemByCode(
    priorityDictionary,
    props.eventLevelId
  ) as Record<string, any>;
  const alertDate = moment(props.eventDate).format("hh:mm A DD/MM/YYYY");

  const actionedByString = isNonEmptyArray(props.actionedBy)
    ? props.actionedBy.join(", ")
    : null;

  return (
    <div style={{ display: "flex" /* , alignItems: 'start' */ }}>
      <div className={classes.alertInformation}>
        <div
          className={classes.title}
          style={{
            display: "flex",
            alignItems: "center",
            height: "24px",
            fontSize: calculatedVariant === "small" ? "12px" : "16px",
          }}
        >
          <span style={{ fontWeight: "bold" }}>{eventLevelItem.label}</span>
          <span style={{ paddingLeft: "16px" /* , paddingRight: '3px' */ }}>
            <AccessTimeIcon
              fontSize="small"
              htmlColor={blueGrey[600]}
              style={{ height: "12px" }}
            />
          </span>
          <span
            style={{
              color: blueGrey[600],
              fontSize: "12px",
            }}
          >
            {alertDate}
          </span>
        </div>
        <div>
          <div>
            <span
              style={{
                fontSize: calculatedVariant === "small" ? "12px" : "14px",
                lineHeight: calculatedVariant === "small" ? "20px" : "21px",
              }}
              className={classes.messageStyle}
            >
              {`${props.message}`}
            </span>
          </div>
        </div>
        {isFormSpecified(actionedByString) && (
          <div
            style={{
              marginTop: "16px",
              height: "24px",
              display: "flex",
              alignItems: "center",
              color: blueGrey[600],
              fontSize: calculatedVariant === "small" ? "10px" : "12px",
              boxSizing: "border-box",
              paddingRight: "12px",
            }}
          >
            {`Also actioned by ${actionedByString}`}
          </div>
        )}
      </div>
    </div>
  );
}

/*                             <div 
  style={{
    fontSize: isSmallScreen === true ? '12px' : '14px', 
    fontWeight: 'bold',
    color: theme.palette.text.primary
  }}
>
  {moreNotificationsString}
</div> */
