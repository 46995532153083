import React, { useMemo, useEffect } from "react";
import { ObjectIdType } from "components/Utils/Object.d";
import { API_LIB } from "components/Utils/API_LIB";
import { DataSourceParamsType } from "components/Crud/DataProvider/DataProviderCommon.d";
import { UnifiedDataRequestType } from "components/Utils/CRUDUtils.d";
import { filterBy, translateValue } from "components/Utils/MiscUtils";
import { useDatasetManager } from "components/Crud/DataProvider/DatasetManager";
import { QueryEventAction } from "components/Crud/DataProvider/DataQuery.d";
import { ActionButtonHandlerComponent } from "components/Buttons/ActionButtonHandlerComponent";
import { EventDispatchersType } from "components/Crud/Table/StatelessTable.d";
import { CRUD_DISPATCHER } from "components/Crud/DataProvider/DataCrud";
import { FetchlessFormikEntityForm } from "components/Crud/Form/FetchlessFormikEntityForm";
import { getAttributeByPath } from "components/Crud/EntityUtils";
import { CREATE_ACTION, DELETE_ACTION } from "components/Crud/Entity.const";
import { ENTITY_LIB } from "components/Utils/CRUDEntityList";
import AddIcon from "@material-ui/icons/Add";
import { assetGroupUserEditFormFieldsSpec } from "./AssetGroupUserEdit.formFields";
import { useTheme, Button, Paper } from "@material-ui/core";
import { usePopupOperationController } from "popups/usePopupOperationController";
import ActionDelete from "@material-ui/icons/Delete";
import { CrudEventAction } from "components/Crud/DataProvider/DataCrud.d";
import { userTitleDictionary } from "views/User/User.dict";
import { FlexSpaceFiller } from "layouts/FlexSpaceFiller";
import NotificationsOffIcon from "@material-ui/icons/NotificationsOff";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import { ALL_ENTERPRISES_CODE } from "views/Enterprise/Enterprise.const";
import { OnClickDialog, OnClickDispatchEvent } from "components/Buttons/Buttons.d";
import { getDictionaryLabelByCode } from "components/Dictionaries/DictionariesUtils";

export interface GroupUsersNewProps {
  enterpriseId: ObjectIdType;
  groupId: ObjectIdType;
}

// const useStyles = makeStyles(groupUserNewStyle, {name: 'GroupUsersNew'});

export function GroupUsersNew(props: GroupUsersNewProps) {
  // const classes = useStyles({});

  const dataSource: DataSourceParamsType = {
    apiUrl: API_LIB.ASSET_GROUP_USER,
    //    customUrlRequestFragment: `filter=assetId::eq::${props.assetId}`
  };

  const initialRequest: UnifiedDataRequestType = useMemo(
    () => ({
      fieldList: [
        "userId",
        "user.username",
        "user.title",
        "user.firstName",
        "user.lastName",
        "notify",
        "assetGroupId",
      ],
      filter: {
        baseFilters: [
          filterBy("assetGroupId", "=", props.groupId),
          filterBy(
            "assetGroup.enterpriseId",
            "=",
            translateValue(props.enterpriseId, [[ALL_ENTERPRISES_CODE, undefined]])
          ),
        ],
      },
      // isPaginationEnabled: true,
      // rowsPerPage: 10,
      // pageNum: 0,
      // sortByColumn: 'name',
      // sortDirection: SORT_ASC
    }),
    [props.groupId, props.enterpriseId]
  );

  const [dataset, dispatchRequest] = useDatasetManager(dataSource, { notifyOnMutationSuccess: false });

  useEffect(() => {
    dispatchRequest({
      action: QueryEventAction.REQUEST_DATA,
      payload: initialRequest,
      originator: "GroupUsersNew - initial load",
    });
  }, [dispatchRequest, initialRequest]);

  const theme = useTheme();
  const assetGroupEditFormFields = assetGroupUserEditFormFieldsSpec(props.enterpriseId, props.groupId, theme);

  // Bits copied from DataCrudEntityFormBinding
  const eventDispatchersForForm: EventDispatchersType = {};
  eventDispatchersForForm[CRUD_DISPATCHER] = dispatchRequest;

  const AssetGroupUserEditForm = (props: any) => (
    <FetchlessFormikEntityForm
      // tempOperationResult={operationResult}
      // triggerCloseOnSuccesOf={[CrudEventAction.CREATE, CrudEventAction.UPDATE, CrudEventAction.DELETE]}
      formData={props.dataObject}
      id={props.id}
      action={props.action}
      title={props.title}
      isDataLoading={false} //{crudStatus.state === DataCrudOperationStatus.EXECUTING}
      fieldConfigurations={assetGroupEditFormFields}
      eventDispatchers={eventDispatchersForForm}
      onCloseAdditionalActions={props.onCloseAdditionalActions}
      showCloseButton={props.showCloseButton}
      userData={props.userData}
      currentEnterpriseId={props.currentEnterpriseId}
      layoutComponent={props.layoutComponent}
      conditionalFieldConfiguration={props.conditionalFieldConfiguration}
      formDataPreSubmitProcessor={props.formDataPreSubmitProcessor}
      doNotPreFetchData={true} //{props.doNotPreFetchData}
      warning={props.warning}
    />
  );

  // let createTriggerConditionButtonConfig = generateCreateButtonConfig(
  //   ENTITY_LIB.TRIGGER_CONDITION,
  //   'Add',
  //   TriggerConditionEditForm,
  //   [],
  //   {
  //     title: 'Add Trigger Condition',
  //     // warning: warningValue
  //   }
  // );

  const addAssetGroupUserButtonConfig = {
    actionCode: CREATE_ACTION,
    actionObject: ENTITY_LIB.TRIGGER_CONDITION,
    name: "add",
    labelElement: <AddIcon fontSize="small" />,
    onClick: {
      type: "dialog",
      dialogId: AssetGroupUserEditForm,
      onCloseCallbacks: [] as Array<() => void>,
      extraPropsObject: {
        title: "Add User",
        // warning: warningValue
      },
    } as OnClickDialog,
  };

  const deleteAssetGroupUserButtonConfig = {
    actionObject: ENTITY_LIB.TRIGGER_CONDITION,
    actionCode: DELETE_ACTION,
    name: "delete",
    labelElement: <ActionDelete fontSize="small" />,
    onClick: {
      type: "dispatchEvent",
      dispatcherName: CRUD_DISPATCHER,
      action: CrudEventAction.DELETE,
    } as OnClickDispatchEvent,
    confirm: { message: "Are you sure you want to remove user from the group?", dialogButton: "Yes" },
  };

  const operationResult = dataset.lastOperationResult;
  usePopupOperationController(operationResult);

  return (
    <Paper>
      <div style={{ display: "flex", boxShadow: "inset 0 -1px 0 0 #EEEEEE", height: "56px" }}>
        <FlexSpaceFiller />
        <ActionButtonHandlerComponent
          buttonConfig={addAssetGroupUserButtonConfig}
          id={null}
          object={null}
          eventDispatchers={eventDispatchersForForm}
        />
      </div>
      <div>
        <div>
          {dataset.dataArray.map((item, index) => {
            const userTitleId = getAttributeByPath(item, "user.title");
            const userTitleString = getDictionaryLabelByCode(userTitleDictionary, userTitleId) || "";

            const fullUserName = `${userTitleString} ${getAttributeByPath(
              item,
              "user.firstName"
            )} ${getAttributeByPath(item, "user.lastName")}`;

            return (
              <div
                key={index}
                style={{
                  height: "56px",
                  display: "flex",
                  alignItems: "center",
                  boxShadow: "inset 0 -1px 0 0 #EEEEEE",
                }}
              >
                <div style={{ flexGrow: 1, padding: "0 16px" }}>
                  <div style={{ color: "#263238", fontWeight: 500, fontSize: "14px" }}>
                    {getAttributeByPath(item, "user.username")}
                  </div>
                  <div style={{ color: "#546E7A", fontSize: "12px" }}>{fullUserName}</div>
                </div>
                <div>
                  <Button
                    onClick={() => {
                      dispatchRequest({
                        action: CrudEventAction.UPDATE,
                        payload: {
                          ...item,
                          notify: !item.notify,
                        },
                        originator: "TriggerCard - is enabled check box",
                      });
                    }}
                  >
                    {item.notify ? (
                      <NotificationsActiveIcon htmlColor={theme.palette.primary.main} />
                    ) : (
                      <NotificationsOffIcon htmlColor={"#546E7A"} />
                    )}
                  </Button>
                </div>
                <div>
                  <ActionButtonHandlerComponent
                    buttonConfig={deleteAssetGroupUserButtonConfig}
                    id={item.id} // TODO: this id is ignored for some reason
                    object={item} //{dataArrayItem.dataObject}
                    eventDispatchers={eventDispatchersForForm}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Paper>
  );
}

// <div className={classes.checkBox}>
// </div>
