import React from "react";
import NotificationsNoneOutlinedIcon from "@material-ui/icons/NotificationsNoneOutlined";
import { DiscIcon } from "components/IconWrapper/DiscIcon";
import { useTheme } from "@material-ui/core";

export interface AlertBadgeProps {
  size: "small" | "default";
  color?: string;
}

export function AlertBadge(props: AlertBadgeProps) {
  const theme = useTheme();

  let discSize: number;

  switch (props.size) {
    case "small":
      discSize = 30;
      break;
    case "default":
      discSize = 40;
      break;
    default:
      discSize = 40;
  }

  const colorCode = props.color || theme.palette.warning.main;

  return (
    <DiscIcon
      discSize={discSize}
      iconSize={props.size}
      htmlBackgroundColor={colorCode}
      icon={NotificationsNoneOutlinedIcon}
    />
  );
}
