import React from "react";
import {
  MenuItemType,
  SelectConfiguration,
  SelectAsyncConfiguration,
  FieldConfiguration,
} from "components/Crud/Form/Fields/FieldConfiguration.d";
import { FilterMatchTypes, PropertyFilterCriteriaType } from "components/Utils/CRUDUtils.d";
import OutlinedSelectField from "components/Crud/Form/Fields/OutlinedSelectField";
import AsyncSelectOptionsProvider from "components/Crud/DataProvider/AsyncSelectOptionsProvider";
import CustomTextField from "components/Crud/Form/Fields/CustomTextField";
import { TextFilterParams, TableColumnFilter } from "../EntityTable.d";
import { ColumnLayout } from "layouts/ColumnLayout";
import { AutoCompleteDropDown } from "components/Crud/Form/Fields/AutoCompleteDropDown";
import { RowLayout } from "layouts/RowLayout";

export interface TableFilterSectionProps {
  activeFilterValues: PropertyFilterCriteriaType;
  filters: Array<TableColumnFilter>;
  onFiltersChange: (event: any) => void;
  direction?: "row" | "column";
}

export function TableFiltersSection(props: TableFilterSectionProps) {
  // TODO: unify rendering with form field rendering
  // TODO: refactor

  const filterUpdateEventPayload = (filterItem: TableColumnFilter, value: string | number) => {
    const result: PropertyFilterCriteriaType = {};

    let trimmedValue = value;

    if (typeof trimmedValue === "string" && trimmedValue.length > 0) {
      trimmedValue = trimmedValue.trimStart() /* .trim() */;
    }

    result[filterItem.filterDataSourceField] = {
      attributeValue: trimmedValue,
      criteriaType: filterItem.filterMatchType || FilterMatchTypes.EQUAL,
    };

    return result;
  };

  // TODO: typefy onChange signature
  const dispatchFieldUpdateEvent = (filterItem: TableColumnFilter, event: any) => {
    const resultEvent = Object.assign(
      {},
      props.activeFilterValues,
      filterUpdateEventPayload(filterItem, event.target.value)
    );

    props.onFiltersChange(resultEvent);

    // if (props.allowUrlReplacementForFilters) {
    //   generateQueryStringByPropertyFilter(resultEvent, location, history);
    // }
  };

  const RenderComponent = (props.direction || "row") === "row" ? ColumnLayout : RowLayout;

  return (
    <RenderComponent spacingName="buttonSpacing">
      {props.filters
        .filter((filterItem) => !filterItem.disable)
        .map((filterItem, index) => {
          const filterFieldValue =
            (props.activeFilterValues &&
              props.activeFilterValues[filterItem.filterDataSourceField] &&
              props.activeFilterValues[filterItem.filterDataSourceField].attributeValue) ||
            "";

          // TODO: unify with Form field Renderer
          if (filterItem.filterType === "select") {
            const newOptionsArray: Array<MenuItemType> = [
              {
                code: undefined,
                label: (filterItem.filterParams as SelectConfiguration).renderEmptyValueAs || "All",
              },
              ...(filterItem.filterParams as SelectConfiguration).options,
            ];

            const fieldConfig: FieldConfiguration = {
              fieldName: filterItem.filterDataSourceField,
              label: undefined,
              type: "select", // type: showEnterpriseField ? 'select-async' : 'hidden',
              // defaultValue: translateValue(currentEnterpriseId, [[ALL_SITES_CODE, NONE_SELECT_CODE]]),
              // disabled: disableEntepriseField,
              fieldParams: { options: newOptionsArray },
            };

            return (
              <div key={index} style={{ minWidth: "150px" }}>
                <OutlinedSelectField
                  onChange={(event: any) => dispatchFieldUpdateEvent(filterItem, event)}
                  fieldConfig={fieldConfig}
                  value={filterFieldValue}
                  errorFlag={false}
                  displayEmptyValueAs={(filterItem.filterParams as SelectConfiguration).renderEmptyValueAs}
                  optionsArray={newOptionsArray}
                  customHeight="36px"
                />
              </div>
            );
          } else if (
            filterItem.filterType === "select-async" &&
            (filterItem.filterParams as SelectAsyncConfiguration).autoComplete
          ) {
            const fieldConfig: FieldConfiguration = {
              fieldName: filterItem.filterDataSourceField,
              label: undefined,
              type: "select-async",
              fieldParams: filterItem.filterParams as SelectAsyncConfiguration,
            };

            return (
              <div key={index} style={{ minWidth: "150px" }}>
                <AutoCompleteDropDown
                  fieldConfig={fieldConfig}
                  required={false}
                  onChange={(event: any) => dispatchFieldUpdateEvent(filterItem, event)}
                  helperText={undefined}
                  errorFlag={false}
                  disabled={false}
                  value={filterFieldValue}
                  customHeight="36px"
                />
              </div>
            );
          } else if (
            filterItem.filterType === "select-async" &&
            !(filterItem.filterParams as SelectAsyncConfiguration).autoComplete
          ) {
            return (
              <div key={index} style={{ minWidth: "150px" }}>
                <AsyncSelectOptionsProvider
                  fieldName={filterItem.filterDataSourceField}
                  fieldParams={filterItem.filterParams as SelectAsyncConfiguration}
                  value={filterFieldValue}
                  filterUrl={(filterItem.filterParams as SelectAsyncConfiguration).filterUrl as string}
                >
                  {(optionsArray: Array<MenuItemType>, sanitizedValue: string | number) => {
                    // TODO: use value wrapper. Perhaps it will go away if we redesign and add clear filter button
                    const newOptionsArray: Array<MenuItemType> = [
                      {
                        code: undefined,
                        label:
                          (filterItem.filterParams as SelectAsyncConfiguration).renderEmptyValueAs || "All",
                      },
                      ...optionsArray,
                    ];

                    // TODO: !!! what do we need to update this field config for?
                    const fieldConfig: FieldConfiguration = {
                      fieldName: filterItem.filterDataSourceField,
                      label: undefined,
                      type: "select", // type: showEnterpriseField ? 'select-async' : 'hidden',
                      // defaultValue: translateValue(currentEnterpriseId, [[ALL_SITES_CODE, NONE_SELECT_CODE]]),
                      // disabled: disableEntepriseField,
                      fieldParams: { options: newOptionsArray },
                    };

                    return (
                      <OutlinedSelectField
                        onChange={(event: any) => dispatchFieldUpdateEvent(filterItem, event)}
                        fieldConfig={fieldConfig}
                        value={sanitizedValue}
                        errorFlag={false}
                        displayEmptyValueAs={
                          (filterItem.filterParams as SelectAsyncConfiguration).renderEmptyValueAs
                        }
                        optionsArray={newOptionsArray}
                        customHeight="36px"
                      />
                    );
                  }}
                </AsyncSelectOptionsProvider>
              </div>
            );
          } else if (filterItem.filterType === "text") {
            const fieldConfig: FieldConfiguration = {
              // TODO: either unify other fields according to this, or remove this
              // label: colDesc.label,
              placeholder: (filterItem.filterParams as TextFilterParams).placeholder,
              fieldName: filterItem.filterDataSourceField,
              type: filterItem.filterType,
            };

            return (
              <div key={index}>
                <CustomTextField
                  onChange={(event: any) => dispatchFieldUpdateEvent(filterItem, event)}
                  errorFlag={false}
                  value={filterFieldValue}
                  fieldConfig={fieldConfig}
                  customHeight="36px"
                />
              </div>
            );
          } else {
            return undefined;
          }
        })}
    </RenderComponent>
  );
}
