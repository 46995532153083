import React from "react";

import { MainPanelWithParamsProps } from "components/Dashboard/MainPanelWithParams.d";
import { EntityEditRouteParams } from "components/Dashboard/EntityEditRouteParams.d";
import { UserMembershipEditForm, UserMembershipProps } from "./UserMembershipEditForm";

export function UserMembershipEditScreen(
  props: MainPanelWithParamsProps<EntityEditRouteParams & UserMembershipProps>
) {
  return (
    <UserMembershipEditForm
      id={props.match.params.id}
      userId={props.match.params.userId}
      action={props.match.params.action}
      showCloseButton={false}
      currentEnterpriseId={props.currentEnterpriseId}
      userData={props.userData}
    />
  );
}
