import React from "react";
import { ObjectWithIdFieldType } from "components/Utils/Object.d";
import { TableRowCardRow } from "./TableRowCardRow";
import { TableRowStandard } from "./TableRowStandard";
import { EventDispatchersType } from "../StatelessTable.d";
import { TableColumnParameters, OnRowClickFunctionType } from "../EntityTable.d";

export interface RowRendererProps {
  rowDataObject: ObjectWithIdFieldType;
  onRowClickFunction?: OnRowClickFunctionType;
  isSelected: boolean;
  tableColumns: TableColumnParameters[];
  eventDispatchers?: EventDispatchersType;
  zeroPadding?: boolean;
  restrictActions?: Array<string>;
}

export interface EntityTableUnifiedTableRowRendererProps {
  tableRowContainerElement: "tr" | "div";
  rowDataObject: ObjectWithIdFieldType;
  onRowClickFunction?: OnRowClickFunctionType;
  isSelected: boolean;
  tableColumns: TableColumnParameters[];
  eventDispatchers?: EventDispatchersType;
  zeroPadding?: boolean;
  restrictActions?: Array<string>;
}

export function EntityTableUnifiedTableRowRenderer(props: EntityTableUnifiedTableRowRendererProps) {
  let RowRendererComponent: (props: RowRendererProps) => JSX.Element;

  if (props.tableRowContainerElement === "div") {
    RowRendererComponent = TableRowCardRow;
  } else if (props.tableRowContainerElement === "tr") {
    RowRendererComponent = TableRowStandard;
  }

  return (
    <RowRendererComponent
      isSelected={props.isSelected}
      onRowClickFunction={props.onRowClickFunction}
      rowDataObject={props.rowDataObject}
      zeroPadding={props.zeroPadding}
      eventDispatchers={props.eventDispatchers}
      tableColumns={props.tableColumns}
      restrictActions={props.restrictActions}
    />
  );
}
