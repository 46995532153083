import React from "react";

export interface TableSingleValueRowProps {
  columnCount: number;
  children: JSX.Element | Array<JSX.Element> | string;
}

export function TableSingleValueRow(props: TableSingleValueRowProps) {
  return (
    <tr>
      <td style={{ textAlign: "center" }} colSpan={props.columnCount}>
        {props.children}
      </td>
    </tr>
  );
}
