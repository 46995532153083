import React from "react";
import { ALL_ENTERPRISES_CODE } from "views/Enterprise/Enterprise.const";
import { EnterpriseIdType } from "views/Enterprise/Enterprise.d";
import { UserParams } from "views/User/UserParams.d";
import { PortletCard } from "components/Cards/PortletCard";
import { AlertAcknowledgementStatus } from "views/Alert/Alert.d";
import { ALERT_ENTITY_NAME } from "views/Alert/Alert.const";
import { getAggregatedPageRoute } from "components/Utils/RouteUtils";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { ROUTE_LIB } from "components/Utils/RouteList";
import { SORT_DESC, UnifiedDataRequestType } from "components/Utils/CRUDUtils.d";
import { CrudQueryTableBinding } from "components/Crud/Table/CrudQueryTableBinding";
import { filterBy, translateValue } from "components/Utils/MiscUtils";
import { TableColumnParameters, TableColumnFilter } from "components/Crud/Table/EntityTable.d";
import { TableCellRendererProps } from "components/Crud/Table/CellRenderers/TableCellRenderer.d";
import { DressedEnum } from "components/Crud/Table/CellRenderers/DressedEnum";
import { sampleTypeIconDictionary, priorityDictionary } from "components/Dictionaries/DictionariesCommon";
import { DateCellRenderer } from "components/Crud/Table/CellRenderers/DateCellRenderer";
import { ACTION_NAVIGATE_TO_ASSET } from "views/asset/Asset/Asset.const";
import { DataSourceParamsType } from "components/Crud/DataProvider/DataProviderCommon.d";
import { API_LIB } from "components/Utils/API_LIB";
import { ActionButtonConfig } from "components/Buttons/Buttons.d";

export interface LatestAlertsPortletProps {
  userData: UserParams;
  currentEnterpriseId: EnterpriseIdType;
}

export const NAVIGATE_TO_ALL_ALERTS = "navigateToAllUserAlerts";

export function LatestAlertsPortlet(props: LatestAlertsPortletProps) {
  const bottomActionSet: Array<ActionButtonConfig> = [
    {
      labelElement: (
        <div style={{ display: "flex" }}>
          <span>SEE ALL</span>&nbsp;
          <ArrowForwardIcon fontSize="small" />
        </div>
      ),
      name: "viewDetail",
      onClick: {
        type: "navigate",
        // TODO: it has nothing to do with Asset Group
        url: getAggregatedPageRoute(ROUTE_LIB.OVERVIEW_ALL_ALERTS),
      },
      actionCode: NAVIGATE_TO_ALL_ALERTS,
      actionObject: ALERT_ENTITY_NAME,
    },
  ];
  const tableColumns: TableColumnParameters[] = [
    {
      sourceName: "event.samplePoint.asset.site.name", // TODO: actualy this is not a name, but a data source address
      label: "Site",
    },
    {
      sourceName: "event.samplePoint.asset.name",
      label: "Asset Name",
      width: 200,
    },
    {
      sourceName: "event.samplePoint.sampleTypeId",
      label: "Type",
      width: 200,
      valueTransformationFunction: (rendererProps: TableCellRendererProps) => (
        <DressedEnum options={sampleTypeIconDictionary} {...rendererProps} />
      ),
    },
    {
      sourceName: "event.message",
      label: "Alert",
      width: 200,
    },
    {
      sourceName: "event.samplePoint.currentStatus.value",
      label: "Current Value",
    },
    {
      sourceName: "event.level",
      label: "Priority",
      width: 200,
      valueTransformationFunction: (rendererProps: TableCellRendererProps) => (
        <DressedEnum options={priorityDictionary} {...rendererProps} />
      ),
    },
    {
      sourceName: "event.date",
      label: "Time Triggered",
      width: 200,
      valueTransformationFunction: (rendererProps: TableCellRendererProps) => (
        <DateCellRenderer userFriendlyFormat={false} {...rendererProps} />
      ),
    },
    {
      sourceName: "id",
      label: "Action",
      disableSorting: true,
      actionSet: [
        {
          labelElement: (
            <div>
              <span>VIEW</span>
            </div>
          ),
          name: "viewDetail",
          onClick: {
            type: "navigate",
            // TODO: it has nothing to do with Asset Group
            url: getAggregatedPageRoute(ROUTE_LIB.ASSET_SUMMARY),
            paramName: "entityId",
            paramSource: "event.samplePoint.asset.id",
          },
          actionCode: ACTION_NAVIGATE_TO_ASSET,
          actionObject: ALERT_ENTITY_NAME,
        },
      ],
    },
  ];

  const uiFilters: Array<TableColumnFilter> = [];

  const dataSource: DataSourceParamsType = {
    apiUrl: API_LIB.NOTIFICATIONS, // `/notification?join=event&join=samplePoints&join=asset`, // '/notification',
  };

  const initialRequest: UnifiedDataRequestType = {
    fieldList: [
      "event.samplePoint.asset.name",
      "event.samplePoint.sampleTypeId",
      "event.message",
      "event.level",
      "event.date",
      "event.samplePoint.asset.site.name",
      "event.samplePoint.currentStatus",
    ], // ['label', 'receiverType', 'enterprise.name'],
    filter: {
      baseFilters: [
        filterBy("userId", "=", props.userData.userId),
        filterBy(
          "site.enterpriseId",
          "=",
          translateValue(props.currentEnterpriseId, [[ALL_ENTERPRISES_CODE, undefined]])
        ),
        filterBy("notificationStatus", "=", AlertAcknowledgementStatus.RAISED),
      ],
    },
    isPaginationEnabled: true,
    rowsPerPage: 5,
    pageNum: 0,
    sortByColumn: "event.date",
    sortDirection: SORT_DESC,
  };

  return (
    <PortletCard title="Latest Alerts" portletAction={bottomActionSet}>
      <CrudQueryTableBinding
        externalTriggerValues={[props.currentEnterpriseId, props.userData]}
        dataSource={dataSource}
        intialQuery={initialRequest}
        // title={props.title}
        tableColumns={tableColumns}
        // onRowClickFunction={props.onRowClickFunction}
        // actionsAllowed={allowedActions}
        // highlightedRowsPrimaryKeys={props.activeRowPrimaryKeys}
        disableFilters={true}
        disablePaginationSection={true}
        uiFilters={uiFilters}
        paperless={true}
      />
    </PortletCard>
  );
}
