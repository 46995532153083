import { StyleRulesCallback, Theme } from "@material-ui/core/styles";

export const openDrawerWidth = 279; // TODO: it should be 280 but border-box doesn't work for some reason
export const closedDrawerWidth = (16 + 8) * 2 + 20 - 1; // TODO: it should be 36 but border-box doesn't work for some reason

// TODO:     // marginTop: '48px',
export const sidebarStyle: StyleRulesCallback<Theme, {}> = (theme) => ({
  root: {
    height: "100%",
    // maxWidth: `${openDrawerWidth}px`
  },
  drawerOpen: {
    width: `${openDrawerWidth}px`,
  },
  drawerClosed: {
    width: `${closedDrawerWidth}px`,
  },
  drawerCloseMobile: {
    width: 0,
  },
  drawerPaper: {
    // paddingRight: '16px',
    position: "relative", // IMPORTANT
    backgroundColor: "#FFFFFF", // Note: old color for reference - '#36404E'
  },
  sideBarContentContainerOpen: {
    paddingLeft: "16px",
    paddingRight: "16px",
  },
  sideBarContentContainerClosed: {
    paddingLeft: "16px",
    paddingRight: "16px",
  },
  list: {
    paddingTop: "0",
    paddingBottom: "0",
    // TODO: - victim
    // marginTop: '20px',
    // paddingLeft: '0',
    // marginBottom: '0',
    // listStyle: 'none',
  },
  addGroupButton: {
    backgroundColor: theme.palette.text.secondary,
    minWidth: "8px",
    paddingLeft: "8px",
    paddingRight: "8px",
  },
  divider: {
    backgroundColor: "grey",
  },
  addNewGroupSection: {
    paddingLeft: "8px",
  },
  addNewGroupText: {
    marginLeft: "32px",
  },
  warningText: {
    marginLeft: "20px",
    color: "grey",
  },
  warningTextCollapsed: {
    marginLeft: "20px",
  },
  groupTitleDivider: {
    // TODO: Rename to match the purpose
    // TODO: Roboto Medium
    color: theme.palette.text.secondary,
    lineHeight: "13px",
    marginTop: "20px",
  },
  groupTitleItem: {
    height: "30px",
  },
});
