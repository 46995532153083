import Button from "@material-ui/core/Button";
import buttonStyle from "components/Buttons/buttonStyle";
import clsx from "clsx";
import React, { ReactNode, MouseEventHandler } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ColorType } from "assets/ColorType";

interface BasicButtonProps {
  color?: ColorType;
  round?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  children?: ReactNode;
  onClick?: MouseEventHandler;
  size?: "small" | "medium" | "large"; // Note: not properly typed in MUI
}

const useStyles = makeStyles(buttonStyle, { name: "BasicButton" });

function BasicButton(props: BasicButtonProps) {
  const { color, round, children, fullWidth, disabled, size, ...rest } = props;

  const classes = useStyles({});

  const btnClassesCombo = clsx(classes.root, props.color && classes[props.color], {
    [classes.round]: props.round,
    [classes.fullWidth]: props.fullWidth,
    [classes.disabled]: props.disabled,
  });

  return (
    <Button {...rest} size={props.size ? props.size : "small"} className={btnClassesCombo}>
      {children}
    </Button>
  );
}

export default BasicButton;
