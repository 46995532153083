import React from "react";
import { EnterpriseList } from "./EnterpriseList";
import { MainPanelWithParamsProps } from "components/Dashboard/MainPanelWithParams.d";
import { parseSearchStringIntoScreenRequest } from "components/Utils/RouteUtils";

export interface EnterpriseInventoryScreenProps {}

export function EnterpriseInventoryScreen(props: MainPanelWithParamsProps<EnterpriseInventoryScreenProps>) {
  const requestFromUrl = parseSearchStringIntoScreenRequest(props.location);

  return (
    <EnterpriseList
      userData={props.userData}
      currentEnterpriseId={props.currentEnterpriseId}
      inputRequest={requestFromUrl.request}
      allowUrlReplacementForFilters={true}
      allowReinitialization={true}
    />
  );
}
