import { EntityActionType } from "./Entity.d";

export const EDIT_ACTION: EntityActionType = "edit";
export const SHOW_ACTION: EntityActionType = "show";
export const CREATE_ACTION: EntityActionType = "create";
export const DELETE_ACTION: EntityActionType = "delete";
export const LIST_ACTION: EntityActionType = "list";

export const EDIT_URL_SUFIX = `/${EDIT_ACTION}`;
export const SHOW_URL_SUFIX = `/${SHOW_ACTION}`;
export const CREATE_URL_SUFIX = `/${CREATE_ACTION}`;
export const DELETE_URL_SUFIX = `/${DELETE_ACTION}`;
export const LIST_URL_SUFIX = `/${LIST_ACTION}`;
