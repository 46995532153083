import React from "react";
import { SiteList } from "./SiteList";
import { MainPanelWithParamsProps } from "components/Dashboard/MainPanelWithParams.d";
import { parseSearchStringIntoScreenRequest } from "components/Utils/RouteUtils";

export interface SiteInventoryScreenProps {}

export function SiteInventoryScreen(props: MainPanelWithParamsProps<SiteInventoryScreenProps>) {
  const requestFromUrl = parseSearchStringIntoScreenRequest(props.location);

  return (
    <SiteList
      userData={props.userData}
      currentEnterpriseId={props.currentEnterpriseId}
      inputRequest={requestFromUrl.request}
      allowUrlReplacementForFilters={true}
      allowReinitialization={true}
    />
  );
}
