import React, { useRef, useEffect } from "react";
import { isSpecified } from "./MiscUtils";
import { RectPropsType, DrawTextPropsType } from "./CanvasUtils.d";

export function roundedRectangle(ctx: CanvasRenderingContext2D, rectProps: RectPropsType) {
  ctx.save();

  ctx.strokeStyle = rectProps.color.stroke;
  ctx.lineWidth = 2;
  ctx.fillStyle = rectProps.color.fill;

  const { x, y, w, h, r } = rectProps;

  if (isSpecified(r)) {
    let br, bl, tl, tr;
    if (typeof r === "number") {
      br = r;
      bl = r;
      tl = r;
      tr = r;
    } else {
      br = r.br || 0;
      bl = r.bl || 0;
      tr = r.tr || 0;
      tl = r.tl || 0;
    }

    ctx.beginPath();
    ctx.moveTo(x + tl, y);
    ctx.lineTo(x + w - tr, y);
    ctx.quadraticCurveTo(x + w, y, x + w, y + tr);
    ctx.lineTo(x + w, y + h - br);
    ctx.quadraticCurveTo(x + w, y + h, x + w - br, y + h);
    ctx.lineTo(x + bl, y + h);
    ctx.quadraticCurveTo(x, y + h, x, y + h - bl);
    ctx.lineTo(x, y + tl);
    ctx.quadraticCurveTo(x, y, x + tl, y);
    ctx.closePath();
  } else {
    ctx.rect(x, y, w, h);
  }

  ctx.fill();
  ctx.stroke();

  ctx.restore();
}

export function drawText(
  ctx: CanvasRenderingContext2D,
  inputText: string,
  props: DrawTextPropsType,
  measureOnly?: boolean
) {
  const result: Record<string, any> = {};

  ctx.save();

  let { containerWidth, containerHeight, size, color, x, y, justify, align } = props;

  let displayedText = inputText;

  size = size || 10;
  color = color || "white";

  result.x = x;
  result.y = y;

  ctx.font = `bold ${size}px sans-serif`;
  ctx.fillStyle = color;

  ctx.strokeStyle = (props.outerStroke && props.outerStroke.color) || "black";
  ctx.lineWidth = (props.outerStroke && props.outerStroke.width) || 2;

  result.lineWidth = ctx.lineWidth;
  ctx.lineJoin = "miter"; // Note: to avoid extra tails on sharp letters like M, W, V
  ctx.miterLimit = 2; // Note

  const textMeasurements = ctx.measureText(displayedText);
  result.width = textMeasurements.width + ctx.lineWidth * 2;

  const ellipsisMeasurements = ctx.measureText("...");
  const ellipsisWidth = ellipsisMeasurements.width + ctx.lineWidth * 2;

  if (justify === "center") {
    x = containerWidth / 2 - textMeasurements.width / 2;
    result.x = x;
  }

  //Ellipsis
  //TODO: optimize the algorithm
  const { right } = props.padding || { left: undefined, right: undefined, top: undefined, bottom: undefined };
  const rightPadding = right || 0;
  if (result.x + result.width + rightPadding > containerWidth) {
    const desiredTextWidth = containerWidth - ellipsisWidth - rightPadding - result.x;
    let sliceSize = 1;
    let newTextMeasurements = ctx.measureText(displayedText.slice(0, sliceSize));

    while (
      sliceSize <= displayedText.length &&
      newTextMeasurements.width + ctx.lineWidth * 2 <= desiredTextWidth
    ) {
      newTextMeasurements = ctx.measureText(displayedText.slice(0, sliceSize));
      sliceSize++;
    }
    displayedText = `${displayedText.slice(0, sliceSize - 1)}...`;
  }

  let calculatedHeight = size * 1.5;

  // TODO: make calculated Height using proper measurements with textMeasurements.actualBoundingBoxAscent etc
  // textMeasurements.actualBoundingBoxDescent + textMeasurements.actualBoundingBoxAscent;
  // if (calculatedHeight < size) {
  //   // TODO: I don't know why sometimes it is smaller
  //   calculatedHeight = size;
  // }
  result.descentOffset = textMeasurements.actualBoundingBoxDescent;
  calculatedHeight = calculatedHeight + ctx.lineWidth * 2;
  result.height = calculatedHeight;

  if (align === "center") {
    y = containerHeight / 2 + calculatedHeight / 2 - size * 0.5;
    result.y = y;
  }

  if (!measureOnly) {
    const strokeDisabled = props.outerStroke && props.outerStroke.width === 0;

    if (!strokeDisabled) {
      ctx.strokeText(displayedText, x, y);
    }

    ctx.fillText(displayedText, x, y);
  }

  ctx.restore();

  return result;
}

export function CanvasComponentForTesting(props: { canvasMarker: any }) {
  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef && containerRef.current) {
      containerRef.current.appendChild(props.canvasMarker);
    }
  }, [props.canvasMarker]);

  return <div ref={containerRef} />;
}
