export const ROUTE_LIB = {
  ASSET_GROUP_ASSET: "/assetGroupAsset/:groupId", // TODO: change to group id
  ASSET_GROUP: "/asset-group",
  ASSET_GROUP_PREVIEW: `/asset-group/preview`,
  DYNAMIC_GROUP_CONFIGURATION: "/asset-group",
  ASSET: "/asset",
  ASSET_INVENTORY: "/asset-inventory",
  SWITCH_ENTERPRISE_ROUTE: "/session/membership/switch",
  OVERVIEW_SUMMARY: "/overview-summary",
  OVERVIEW_ALL_ALERTS: "/overview-all-alerts",
  ASSET_SUMMARY: "/assetSummary/:entityId",
  TRIGGER_TEMPLATE: "/trigger-templates",
};
