import React, { CSSProperties } from "react";
import { isSpecified } from "components/Utils/MiscUtils";

export interface RowWrapperProps {
  id?: string;
  children: string | JSX.Element | Array<JSX.Element>;
  flexGrow?: number;
  style?: CSSProperties;
}

export function RowWrapper(props: RowWrapperProps) {
  const styleObject = {} as CSSProperties;

  if (isSpecified(props.flexGrow)) {
    styleObject.flexGrow = props.flexGrow;
  }

  if (isSpecified(props.style)) {
    Object.assign(styleObject, props.style);
  }

  return (
    <div id={props.id} style={styleObject}>
      {props.children}
    </div>
  );
}
