export const ENTITY_LIB = {
  GROUP_CONFIGURATION_NOTIFY_USERS: "groupConfigurationNotifyUsers",
  ASSET_GROUP: "assetGroup",
  ASSET_INVENTORY_ITEM: "assetInventoryItem",
  NOTIFICATION: "notification",
  TRIGGER: "trigger",
  TRIGGER_CONDITION: "triggerCondition",
  TRIGGER_TEMPLATE: "triggerTemplate",
  TRIGGER_CONDITION_TEMPLATE: "triggerConditionTemplate",
};
