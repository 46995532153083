import * as React from "react";
import { ENTERPRISE_API_URL, ENTERPRISE_ENTITY_NAME } from "./Enterprise.const";
import { FieldConfiguration } from "components/Crud/Form/Fields/FieldConfiguration.d";
import { DataCrudEntityFormBinding } from "components/Crud/Form/DataCrudEntityFormBinding";
import { API_LIB } from "components/Utils/API_LIB";
import { EnterpriseEditFormLayout } from "./EnterpriseEditFormLayout";
import { useTheme } from "@material-ui/core";
import { businessTypeOptions, enterpriseTypeOptions, enterpriseStatusOptions } from "./Enterprise.dict";
import { EntityFormParams } from "components/Crud/Form/EntityForm.d";

export function EnterpriseEditForm(props: EntityFormParams) {
  const theme = useTheme();

  const getFieldConfigs = (): FieldConfiguration[] => {
    return [
      {
        fieldName: "name",
        label: "Enterprise Name",
        type: "text",
        validation: {
          required: true,
        },
      },
      {
        fieldName: "businessType",
        label: "Business Type",
        type: "select",
        defaultValue: businessTypeOptions[0].code,
        fieldParams: { options: businessTypeOptions },
        validation: {
          required: true,
        },
      },
      {
        fieldName: "enterpriseType",
        label: "Enterprise Type",
        type: "select",
        defaultValue: enterpriseTypeOptions[0].code,
        fieldParams: { options: enterpriseTypeOptions },
        validation: {
          required: true,
        },
      },
      {
        fieldName: "enterpriseStatus",
        label: "Enterprise Status",
        type: "select",
        defaultValue: enterpriseStatusOptions[1].code,
        fieldParams: { options: enterpriseStatusOptions },
        validation: {
          required: true,
        },
      },
      // Billing Address
      {
        fieldName: "billingAddress.name",
        label: "Name",
        type: "text",
      },
      {
        fieldName: "billingAddress.line1",
        label: "Line 1",
        type: "text",
      },
      {
        fieldName: "billingAddress.line2",
        label: "Line 2",
        type: "text",
      },
      {
        fieldName: "billingAddress.city",
        label: "City",
        type: "text",
      },
      {
        fieldName: "billingAddress.postCode",
        label: "Post Code",
        type: "text",
      },
      {
        fieldName: "billingAddress.stateId",
        label: "State",
        type: "select-async",
        fieldParams: {
          optionDefinitionEndpoint: API_LIB.ADDRESS_STATES,
          optionCodeField: "id",
          optionLabelField: "abbreviation",
        },
      },
      // Shipping Address
      {
        fieldName: "useBillingAddressForShipping",
        label: "User Billing Address For Shipping",
        type: "boolean",
        defaultValue: true,
        fieldParams: { color: theme.palette.primary.main },
      },
      {
        fieldName: "shippingAddress.name",
        label: "Name",
        type: "text",
      },
      {
        fieldName: "shippingAddress.line1",
        label: "Line 1",
        type: "text",
      },
      {
        fieldName: "shippingAddress.line2",
        label: "Line 2",
        type: "text",
      },
      {
        fieldName: "shippingAddress.city",
        label: "City",
        type: "text",
      },
      {
        fieldName: "shippingAddress.postCode",
        label: "Post Code",
        type: "text",
      },
      {
        fieldName: "shippingAddress.stateId",
        label: "State",
        type: "select-async",
        fieldParams: {
          optionDefinitionEndpoint: API_LIB.ADDRESS_STATES,
          optionCodeField: "id",
          optionLabelField: "abbreviation",
        },
      },
    ];
  };

  const dataSource = {
    apiUrl: ENTERPRISE_API_URL,
    entityName: ENTERPRISE_ENTITY_NAME,
    // customUrlRequestFragment: `groupId=${props.assetGroupId}`
  };

  return (
    <DataCrudEntityFormBinding
      id={props.id} // TODO: do we need this???
      dataSource={dataSource}
      action={props.action}
      title={props.title}
      fieldConfigurations={getFieldConfigs()}
      onCloseAdditionalActions={props.onCloseAdditionalActions}
      showCloseButton={props.showCloseButton}
      userData={props.userData}
      currentEnterpriseId={props.currentEnterpriseId}
      layoutComponent={EnterpriseEditFormLayout}
    />
  );
}
