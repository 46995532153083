import React, { useState } from "react";
import { CardSubTitle } from "components/Elements/Typography/CardSubTitle";
import { Button, makeStyles } from "@material-ui/core";
import { FieldConfiguration } from "components/Crud/Form/Fields/FieldConfiguration.d";
import { BUTTON_VARIANT } from "components/Buttons/ButtonsCommon";
import {
  datePlusInterval,
  dateMinusInterval,
  startOfDayDate,
  startOfToday,
  endOfToday,
} from "components/Utils/DateUtils";
import { intervalOptions } from "components/Graph/GraphCommon";
import produce from "immer";
import OutlinedSelectField from "components/Crud/Form/Fields/OutlinedSelectField";
import {
  GraphDateRangePickerDialog,
  GraphDateRangePickerEventAction,
  GraphDateRangePickerEventType,
} from "./GraphDateRangePickerDialog";
import { isSpecified } from "components/Utils/MiscUtils";
import { useMediaQueryMatchDown } from "components/Utils/LayoutUtils";
import { graphComponentRangeNavigationStyle } from "./GraphComponentRangeNavigationStyle";
import { ConditionalRender } from "layouts/ConditionalRender";
import { RowLayout } from "layouts/RowLayout";
import { elementLabelStyle } from "components/Elements/elementLabel.style";
import buttonStyle from "components/Buttons/buttonStyle";
import { GraphDataRequestParametersType, IntervalOptions } from "./Graph.d";

export interface GraphComponentRangeNavigationProps {
  values: GraphDataRequestParametersType;
  onChange: (newValues: GraphDataRequestParametersType) => void;
  timeZone: string;
}

const useStyles = makeStyles(graphComponentRangeNavigationStyle, { name: "GraphComponentRangeNavigation" });
const useLabelStyles = makeStyles(elementLabelStyle, { name: "GraphComponentRangeNavigation" });
const useButtonStyles = makeStyles(buttonStyle, { name: "GraphComponentRangeNavigation" });

export function GraphComponentRangeNavigation(props: GraphComponentRangeNavigationProps) {
  const [datePickerDialog, setDatePickerDialog] = useState(false);
  const isSmallScreen = useMediaQueryMatchDown("sm");

  const classes = useStyles({});
  const labelClasses = useLabelStyles({});
  const buttonClasses = useButtonStyles({});

  const handleClickPrev = () => {
    const newIntervalConfig = produce(props.values, (draftValues) => {
      const displayedStartDate = dateMinusInterval(
        draftValues.requestedRange.displayedRangeStartDate,
        draftValues.definedInterval,
        props.timeZone
      );
      const displayedEndDate = dateMinusInterval(
        draftValues.requestedRange.displayedRangeEndDate,
        draftValues.definedInterval,
        props.timeZone
      );

      draftValues.requestedRange = {
        displayedRangeStartDate: displayedStartDate,
        displayedRangeEndDate: displayedEndDate,
        requestedRangeStartDate: dateMinusInterval(
          displayedStartDate,
          IntervalOptions.ONE_DAY,
          props.timeZone
        ),
        requestedRangeEndDate: datePlusInterval(displayedEndDate, IntervalOptions.ONE_DAY, props.timeZone),
      };
    });

    props.onChange(newIntervalConfig);
  };

  const handleClickNext = () => {
    const newIntervalConfig = produce(props.values, (draftValues) => {
      const displayedStartDate = datePlusInterval(
        draftValues.requestedRange.displayedRangeStartDate,
        draftValues.definedInterval,
        props.timeZone
      );
      const displayedEndDate = datePlusInterval(
        draftValues.requestedRange.displayedRangeEndDate,
        draftValues.definedInterval,
        props.timeZone
      );

      draftValues.requestedRange = {
        displayedRangeStartDate: displayedStartDate,
        displayedRangeEndDate: displayedEndDate,
        requestedRangeStartDate: dateMinusInterval(
          displayedStartDate,
          IntervalOptions.ONE_DAY,
          props.timeZone
        ),
        requestedRangeEndDate: datePlusInterval(displayedEndDate, IntervalOptions.ONE_DAY, props.timeZone),
      };
    });

    props.onChange(newIntervalConfig);
  };

  const handleResetToCurrentDate = () => {
    // TODO: previous calculation does not fit in the current model
    // definedInterval: prevState.definedInterval === IntervalOptions.CUSTOM_INTERVAL ?
    // IntervalOptions.TWO_WEEKS : prevState.definedInterval;

    const newIntervalConfig = produce(props.values, (draftValues) => {
      const intervalKey =
        draftValues.definedInterval === IntervalOptions.CUSTOM_INTERVAL
          ? IntervalOptions.TWO_WEEKS
          : draftValues.definedInterval;

      const displayedStartDate = dateMinusInterval(
        datePlusInterval(startOfToday(props.timeZone), IntervalOptions.ONE_DAY, props.timeZone),
        intervalKey,
        props.timeZone
      );
      const displayedEndDate = endOfToday(props.timeZone);

      draftValues.requestedRange = {
        displayedRangeStartDate: displayedStartDate,
        displayedRangeEndDate: displayedEndDate,
        requestedRangeStartDate: dateMinusInterval(
          displayedStartDate,
          IntervalOptions.ONE_DAY,
          props.timeZone
        ),
        requestedRangeEndDate: datePlusInterval(displayedEndDate, IntervalOptions.ONE_DAY, props.timeZone),
      };
    });

    props.onChange(newIntervalConfig);
  };

  const handleIntervalChange = (newValue: IntervalOptions) => {
    // when interval is changed - End date stays the same, start date changes
    if (newValue && newValue !== IntervalOptions.CUSTOM_INTERVAL) {
      const newIntervalConfig = produce(props.values, (draftValues) => {
        draftValues.definedInterval = newValue;

        const displayedStartDate = dateMinusInterval(
          datePlusInterval(
            startOfDayDate(draftValues.requestedRange.displayedRangeEndDate, props.timeZone),
            IntervalOptions.ONE_DAY,
            props.timeZone
          ),
          draftValues.definedInterval,
          props.timeZone
        );
        const displayedEndDate = draftValues.requestedRange.displayedRangeEndDate;

        console.log("displayedStartDate", displayedStartDate);
        console.log("displayedEndDate", displayedEndDate);

        draftValues.requestedRange = {
          displayedRangeStartDate: displayedStartDate,
          displayedRangeEndDate: displayedEndDate,
          requestedRangeStartDate: dateMinusInterval(
            displayedStartDate,
            IntervalOptions.ONE_DAY,
            props.timeZone
          ),
          requestedRangeEndDate: datePlusInterval(displayedEndDate, IntervalOptions.ONE_DAY, props.timeZone),
        };
      });

      props.onChange(newIntervalConfig);
    } else {
      // Temporary
      const newIntervalConfig = produce(props.values, (draftValues) => {
        draftValues.definedInterval = newValue;
      });

      props.onChange(newIntervalConfig);
    }

    if (newValue === IntervalOptions.CUSTOM_INTERVAL) {
      setDatePickerDialog(true);
    }
  };

  const handleIntervalFieldChange = (event: React.ChangeEvent<{ value: unknown }>) =>
    handleIntervalChange(event.target.value as IntervalOptions);

  const fieldConfig: FieldConfiguration = {
    fieldName: "select_id",
    label: "Interval",
    type: "select", // type: showEnterpriseField ? 'select-async' : 'hidden',
    // defaultValue: translateValue(currentEnterpriseId, [[ALL_SITES_CODE, NONE_SELECT_CODE]]),
    // disabled: disableEntepriseField,
    fieldParams: { options: intervalOptions },
  };

  const disablePrevIntervalButton =
    props.values.definedInterval === IntervalOptions.CUSTOM_INTERVAL || !props.values.definedInterval;
  const disableNextIntervalButton =
    props.values.definedInterval === IntervalOptions.CUSTOM_INTERVAL ||
    (props.values.requestedRange &&
      props.values.requestedRange.displayedRangeEndDate >= endOfToday(props.timeZone)) ||
    !props.values.definedInterval;

  // let disableCustomIntervalPicker = (props.values.definedInterval !== IntervalOptions.CUSTOM_INTERVAL);

  const renderIntervalNameForButton = (intervalId: IntervalOptions) => {
    let resultValue: string;

    if (intervalId === IntervalOptions.CUSTOM_INTERVAL) {
      resultValue = "";
    } else {
      const chosenIntervalOption = intervalOptions.find((item) => item.code === intervalId);
      resultValue = chosenIntervalOption.label || "";
    }

    return resultValue;
  };

  const graphDatePickerEventHandler = (event: GraphDateRangePickerEventType) => {
    if (event.action === GraphDateRangePickerEventAction.CANCEL) {
      setTimeout(() => setDatePickerDialog(false), 500);
    } else if (event.action === GraphDateRangePickerEventAction.APPLY_INTERVAL) {
      props.onChange(event.payload);
    }
  };

  const PrevButton = (
    <Button
      fullWidth
      variant={BUTTON_VARIANT}
      color="primary"
      // style={{color: 'white', backgroundColor: '#1976d2'}}
      disabled={disablePrevIntervalButton}
      onClick={handleClickPrev}
      classes={{
        root: isSmallScreen ? buttonClasses.rootMobile : undefined,
        label: isSmallScreen ? labelClasses.labelMobile : labelClasses.labelDesktop,
      }}
    >
      {`${isSmallScreen ? "Prev" : "Previous"} ${renderIntervalNameForButton(props.values.definedInterval)}`}
    </Button>
  );

  const NextButton = (
    <Button
      fullWidth
      variant={BUTTON_VARIANT}
      color="primary"
      // style={{color: 'white', backgroundColor: '#1976d2'}}
      disabled={disableNextIntervalButton}
      onClick={handleClickNext}
      classes={{
        root: isSmallScreen ? buttonClasses.rootMobile : undefined,
        label: isSmallScreen ? labelClasses.labelMobile : labelClasses.labelDesktop,
      }}
    >
      {`Next ${renderIntervalNameForButton(props.values.definedInterval)}`}
    </Button>
  );

  const TodayButton = (
    <Button
      fullWidth
      variant={BUTTON_VARIANT}
      disabled={
        isSpecified(props.values.requestedRange)
          ? startOfToday(props.timeZone) <= props.values.requestedRange.displayedRangeEndDate &&
            props.values.requestedRange.displayedRangeEndDate <= endOfToday(props.timeZone)
          : true
      }
      onClick={handleResetToCurrentDate}
      classes={{
        root: isSmallScreen ? buttonClasses.rootMobile : undefined,
        label: isSmallScreen ? labelClasses.labelMobile : labelClasses.labelDesktop,
      }}
    >
      {`To current date`}
    </Button>
  );

  const TimeScaleDropdown = (
    <OutlinedSelectField
      onChange={handleIntervalFieldChange}
      fieldConfig={fieldConfig}
      value={props.values.definedInterval}
      errorFlag={false}
      optionsArray={intervalOptions}
      customHeight={isSmallScreen ? "33px" : "36px"}
    />
  );

  return (
    <ConditionalRender
      condition={isSmallScreen}
      onTrue={
        <div className={classes.graphSubGroupStyle}>
          <GraphDateRangePickerDialog
            open={datePickerDialog}
            values={props.values}
            eventDispatcher={graphDatePickerEventHandler}
            timeZone={props.timeZone}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div>{PrevButton}</div>
            <div>{TimeScaleDropdown}</div>
            <div>{NextButton}</div>
          </div>
        </div>
      }
      onFalse={
        <React.Fragment>
          <div className={classes.graphSubGroupStyle}>
            <div className={classes.groupTitleItem}>
              <CardSubTitle>{"GRAPH RANGE"}</CardSubTitle>
            </div>
            {TimeScaleDropdown}
          </div>
          <div className={classes.graphSubGroupStyle}>
            <div className={classes.groupTitleItem}>
              <CardSubTitle>{"NAVIGATE"}</CardSubTitle>
            </div>
            <GraphDateRangePickerDialog
              open={datePickerDialog}
              values={props.values}
              eventDispatcher={graphDatePickerEventHandler}
              timeZone={props.timeZone}
            />
            <RowLayout spacingName="buttonSpacing">
              <div>{PrevButton}</div>
              <div>{NextButton}</div>
              <div>{TodayButton}</div>
            </RowLayout>
          </div>
        </React.Fragment>
      }
    />
  );
}
