import { StyleRulesCallback, Theme } from "@material-ui/core";

export const multiLineCardValueStyle: StyleRulesCallback<Theme, {}> = (theme) => ({
  root: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "21px",
    color: theme.palette.text.primary,
  },
});
