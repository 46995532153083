import React from "react";
import { UserMembershipList } from "./UserMembershipList";
import { MainPanelWithParamsProps } from "components/Dashboard/MainPanelWithParams.d";
import { parseSearchStringIntoScreenRequest } from "components/Utils/RouteUtils";

export interface UserMembershipScreenProps {
  userId: number;
}

export function UserMembershipScreen(
  props: MainPanelWithParamsProps<UserMembershipScreenProps> & UserMembershipScreenProps
) {
  const requestFromUrl = parseSearchStringIntoScreenRequest(props.location);

  const userId = props.userId;
  console.log("UserMembershipScreenProps", props);

  return (
    <UserMembershipList
      userId={userId}
      userData={props.userData}
      currentEnterpriseId={props.currentEnterpriseId}
      inputRequest={requestFromUrl.request}
      allowUrlReplacementForFilters={true}
      allowReinitialization={true}
    />
  );
}
