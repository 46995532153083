import React from "react";
import { TableBody, makeStyles } from "@material-ui/core";
import { TableBodyLayoutType } from "../EntityTable.d";
import { entityTableUnifiedTableBodyRendererStyle } from "./entityTableUnifiedTableBodyRendererStyle";
import clsx from "clsx";

export type tableBodyContainerElement = "tbody" | "div";

export interface EntityTableUnfiedTableBodyRendererProps {
  children: JSX.Element | Array<JSX.Element>;
  variant: TableBodyLayoutType;
}

const useStyles = makeStyles(entityTableUnifiedTableBodyRendererStyle, {
  name: "EntityTableUnfiedTableBodyRenderer",
});

export function EntityTableUnfiedTableBodyRenderer(props: EntityTableUnfiedTableBodyRendererProps) {
  const classes = useStyles({});
  // TODO: refactor into flexible conifiguration format using renderers

  if (
    props.variant === TableBodyLayoutType.FULL_WIDTH_CARD_SINGLE ||
    props.variant === TableBodyLayoutType.HALF_WIDTH_INJECTED_CONTENT ||
    props.variant === TableBodyLayoutType.FULL_WIDTH_INJECTED_CONTENT
  ) {
    return (
      <div id="test_x" className={clsx(classes.singleColumnCardRowStyle)}>
        {props.children}
      </div>
    );
  } else if (props.variant === TableBodyLayoutType.FULL_WIDTH_CARD_MULTI) {
    return <div className={classes.doubleColumnCardRowStyle}>{props.children}</div>;
  } else if (props.variant === TableBodyLayoutType.FULL_WIDTH_HTML) {
    return <TableBody>{props.children}</TableBody>;
  } else {
    return null;
  }
}
