import React from "react";
import { FormLayoutElement } from "components/Crud/Form/Layout/FormLayoutElement";
import { FormLayoutProps } from "components/Crud/Form/Layout/FormLayout.d";
import { isNonEmptyArray, isSpecified } from "components/Utils/MiscUtils";
import { CardSubTitle } from "components/Elements/Typography/CardSubTitle";

export function SamplePointConfigurationLayout(props: FormLayoutProps) {
  // TODO: find more elegant solution to extract fieldConfigurations data, probably to organize into hierachy
  const fieldConfigurationItems = props.fieldConfigurations.filter(
    (item) => item.fieldName.indexOf("fieldConfigurations[") >= 0
  );

  const groupNames: Array<string> = [];

  if (isNonEmptyArray(fieldConfigurationItems)) {
    fieldConfigurationItems.forEach((item) => {
      const configId = parseInt(
        item.fieldName.substring(item.fieldName.indexOf("[") + 1, item.fieldName.indexOf("]")),
        10
      );

      if (!isSpecified(groupNames[configId]) && isSpecified(item.groupName)) {
        groupNames[configId] = item.groupName;
      }
    });
  }

  return (
    <div>
      <div style={{ height: "40px", display: "flex", alignItems: "center" }}>
        <CardSubTitle>{"PRIMARY PARAMETERS"}</CardSubTitle>
      </div>
      <FormLayoutElement fieldId="assetId" {...props} />
      <FormLayoutElement fieldId="name" {...props} />
      <FormLayoutElement fieldId="sampleTypeId" {...props} />
      <div style={{ display: "flex" }}>
        {groupNames.map((groupName: string, index: number) => {
          return (
            <div key={index}>
              <div style={{ height: "40px", display: "flex", alignItems: "center" }}>
                <CardSubTitle>{groupName.toUpperCase()}</CardSubTitle>
              </div>
              <FormLayoutElement fieldId={`fieldConfigurations[${index}].sampleFieldId`} {...props} />
              <FormLayoutElement fieldId={`fieldConfigurations[${index}].id`} {...props} />
              <FormLayoutElement fieldId={`fieldConfigurations[${index}].params.offset`} {...props} />
              <FormLayoutElement fieldId={`fieldConfigurations[${index}].params.minValue`} {...props} />
              <FormLayoutElement fieldId={`fieldConfigurations[${index}].params.maxValue`} {...props} />
              <FormLayoutElement fieldId={`fieldConfigurations[${index}].params.totalCapacity`} {...props} />
            </div>
          );
        })}
      </div>
    </div>
  );
}
