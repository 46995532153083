import { StyleRulesCallback, Theme } from "@material-ui/core/styles";

export const popupDialogComponentStyle: StyleRulesCallback<Theme, {}> = (theme) => ({
  dialogPaperBody: {
    minWidth: "40vw",
  },
  dialogPaperBodySmallScreen: {
    margin: "auto",
    maxWidth: "none",
  },
});
