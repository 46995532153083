import React from "react";
import clsx from "clsx";
import { Table, makeStyles } from "@material-ui/core";
import tableStyle from "../tableStyle";

export type TableElementContainerType = "table" | "div";

export interface EntityTableUnifiedTableRendererProps {
  children: JSX.Element | Array<JSX.Element>;
  tableContainerElement: TableElementContainerType;
}

const useStyles = makeStyles(tableStyle, { name: "EntityTableUnifiedTableRenderer" });

export function EntityTableUnifiedTableRenderer(props: EntityTableUnifiedTableRendererProps) {
  const classes = useStyles({});

  const tableElementContainer = props.tableContainerElement || "table";

  if (tableElementContainer === "table") {
    return (
      <Table size="small" padding="none" className={clsx(classes.table)}>
        {props.children}
      </Table>
    );
  } /*if (tableElementContainer === 'div')*/ else {
    return (
      <div style={{ display: "flex", flexDirection: "column", minHeight: "100%" }}>{props.children}</div>
    );
  }
}
