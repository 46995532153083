export enum AssetTypeEnum {
  WATER_TANK = 1,
  ELECTRIC_FENCE = 2,
  PADDOCK = 3,
  TROUGH = 4,
}

export enum SampleTypeEnum {
  BATTERY = 1,
  LEVEL = 2,
  LOCATION = 3,
  RAIN_FALL = 4,
  ELECTRIC_FENCE_POWER = 5,
  PRESSURE = 6,
  FLOW = 7,
  STATE = 8,
  LEVEL_MIN = 9,
  LEVEL_AVERAGE = 10,
}

export enum EventLevel {
  INFO = 1,
  WARNING = 2,
  ERROR = 3,
  CRITICAL = 4,
}
