import { StyleRulesCallback, Theme } from "@material-ui/core/styles";
import { openDrawerWidth, closedDrawerWidth } from "../Sidebar/sidebarStyle";

export const headerStyle: StyleRulesCallback<Theme, {}> = (theme) => ({
  appBar: {
    backgroundColor: theme.palette.primary.main,
    boxShadow: "none",
    borderBottom: "0",
    marginBottom: "0",
    width: "100%",
    zIndex: 1029,
    color: "#FFFFFF",
    border: "0",
    borderRadius: "0px",
    padding: "0",
    display: "block",
  },
  sideBarWideSection: {
    boxSizing: "border-box",
    width: `${openDrawerWidth}px`,
    display: "flex",
  },
  sideBarWideSectionSmallScreen: {
    // boxSizing: 'border-box',
    // width: `${openDrawerWidth}px`,
    display: "flex",
  },
  sideBarClosedSection: {
    boxSizing: "border-box",
    width: `${closedDrawerWidth}px`,
    textAlign: "center",
  },
  sideBarClosedSectionSmallScreen: {
    // boxSizing: 'border-box',
    // width: `${closedDrawerWidth}px`,
    textAlign: "center",
  },
  container: {
    display: "flex",
    // TODO: not sure if it is still useful justifyContent: 'space-between',
    flexFlow: "row nowrap", // TODO: perhaps not applicable with new Enterprise\Site options
  },
  drawerButton: {
    alignSelf: "center",
    color: "white",
  },
  smallLogoButtonText: {
    padding: "6px 0",
  },
  smallLogoButtonRoot: {
    minWidth: "34px",
  },
});
