import { TableCellRendererProps } from "./TableCellRenderer.d";
import React from "react";
import { getAttributeByPath } from "components/Crud/EntityUtils";
import { dateToString } from "components/Utils/DateUtils";

export interface DateCellRendererProps {
  userFriendlyFormat: boolean;
}

export function DateCellRenderer(props: TableCellRendererProps & DateCellRendererProps) {
  // TODO: conversion was done, without code optimisation. Perhaps it can be optimized as well

  const result = dateToString(getAttributeByPath(props.obj, props.fieldName), props.userFriendlyFormat);

  return <div>{result}</div>;
}
