import { StyleRulesCallback, Theme } from "@material-ui/core";

export const regularCardValueStyle: StyleRulesCallback<Theme, {}> = (theme) => ({
  root: {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "24px",
    color: theme.palette.text.primary,
    whiteSpace: "nowrap",
  },
  trendUpward: {
    color: theme.palette.success.dark,
  },
  trendDownward: {
    color: theme.palette.warning.main,
  },
});
