import { StyleRulesCallback, Theme } from "@material-ui/core";

const outlinedFieldWrapperStyle: StyleRulesCallback<Theme, {}> = (theme) => ({
  root: {
    marginTop: 8,
    marginBottom: 8,
  },
});

export default outlinedFieldWrapperStyle;
