import React from "react";
import { INPUT_VARIANT } from "./FieldConfiguration.d";
import { InputLabel, OutlinedInput, FormControl } from "@material-ui/core";
import OutlinedFieldWrapper from "./OutlinedFieldWrapper";

function convertObjectToNestedDiv(obj: any, level: number) {
  const levelPrefix = <span style={{ whiteSpace: "pre" }}>{"   ".repeat(level)}</span>;

  if (typeof obj === "string" || typeof obj === "number" || typeof obj === "boolean") {
    return (
      <div>
        {levelPrefix}
        {obj.toString()},
      </div>
    );
  } else if (obj === null) {
    return <div>{levelPrefix}NULL,</div>;
  } else if (obj === undefined) {
    return <div>{levelPrefix}undefined,</div>;
  }

  const result: any = [];

  const keys = Object.keys(obj);

  keys.forEach((val: any, index: number) => {
    if (Array.isArray(obj[val])) {
      result.push(
        <div key={index}>
          {levelPrefix}
          <span style={{ fontWeight: "bold" }}>
            {'"'}
            {val}
            {'"'}
          </span>
          : <span>{"["}</span>
          <div>
            {obj[val].map((subVal: any, subIndex: number) => (
              <div key={subIndex}>{convertObjectToNestedDiv(subVal, level + 1)}</div>
            ))}
          </div>
          {levelPrefix}
          <span>{"],"}</span>
        </div>
      );
    } else if (typeof obj[val] === "object") {
      result.push(
        <div key={index}>
          {levelPrefix}
          <span style={{ fontWeight: "bold" }}>
            {'"'}
            {val}
            {'"'}
          </span>
          : <span>{"{"}</span>
          <div>{convertObjectToNestedDiv(obj[val], level + 1)}</div>
          {levelPrefix}
          <span>{"},"}</span>
        </div>
      );
    } else {
      result.push(
        <div key={index}>
          {levelPrefix}
          <span style={{ fontWeight: "bold" }}>
            {'"'}
            {val}
            {'"'}
          </span>
          : <span>{obj[val].toString()},</span>
        </div>
      );
    }
  });

  return (
    <div>
      {typeof obj === "object" ? (
        <div>
          {levelPrefix}
          <span>{"{"}</span>
        </div>
      ) : (
        ""
      )}
      {result}
      {typeof obj === "object" ? (
        <div>
          {levelPrefix}
          <span>{"}"}</span>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export function FormattedJSONField(props: any) {
  const result: any = (
    <div style={{ overflowY: "auto", height: "20em", width: "100%" }}>
      {convertObjectToNestedDiv(props.value, 0)}
    </div>
  );

  return (
    <OutlinedFieldWrapper>
      <FormControl variant={INPUT_VARIANT} fullWidth>
        <InputLabel shrink={true}>
          <div style={{ backgroundColor: "white" }}>{props.label}</div>
        </InputLabel>
        <OutlinedInput
          labelWidth={0}
          notched={true}
          margin="dense"
          disabled={props.disabled}
          multiline
          inputComponent={() => result}
        />
      </FormControl>
    </OutlinedFieldWrapper>
  );
}
