import React, { useEffect, useState } from "react";
import { FormLayoutElementProps } from "./FormLayoutElement";
import {
  SelectAsyncConfiguration,
  FilterUrlFunctionType,
  DisableFetchingFunctionType,
} from "../Fields/FieldConfiguration.d";
import produce from "immer";
import { getAttributeByPath } from "components/Crud/EntityUtils";
import { SHOW_ACTION, CREATE_ACTION } from "components/Crud/Entity.const";
import { getRequiredFlagValue, getDisabledFlagValue } from "../EntityFormCommon";
import { isSpecified, isNonEmptyArray } from "components/Utils/MiscUtils";
import { twoArraysAreIdentical } from "components/Utils/ObjectUtils";
import { FormFieldRenderer } from "../FormFieldRenderer";
import { FieldsInProgressActionTypeEnum } from "../EntityForm.d";

export function FormLayoutElementDefined(props: FormLayoutElementProps) {
  let fieldConfig = props.fieldConfigurations.find(
    (configurationItem) => configurationItem.fieldName === props.fieldId
  );

  let fieldValue: any;
  let initialFieldValue: any;
  let fieldIsRequired: boolean;
  let fieldIsDisabledPerConfig: boolean;
  let errorFlag: boolean;
  let helperText: string;
  let disabled: boolean;

  // PT - 1
  fieldValue = getAttributeByPath(props.values, fieldConfig.fieldName);

  initialFieldValue = getAttributeByPath(props.initialValues, fieldConfig.fieldName);
  fieldIsRequired = getRequiredFlagValue(fieldValue, fieldConfig, props.values, props.action);
  fieldIsDisabledPerConfig = getDisabledFlagValue(fieldValue, fieldConfig, props.values);
  disabled = props.editModeOn === false || props.action === SHOW_ACTION || fieldIsDisabledPerConfig;
  helperText = props.errors[fieldConfig.fieldName];
  errorFlag = isSpecified(helperText);

  // PT - 2
  if (fieldConfig.type === "select-async") {
    if (typeof (fieldConfig.fieldParams as SelectAsyncConfiguration).filterUrl === "function") {
      const newFilterUrl = ((fieldConfig.fieldParams as SelectAsyncConfiguration)
        .filterUrl as FilterUrlFunctionType)(props.values);

      fieldConfig = produce(fieldConfig, (draftFieldConfig) => {
        (draftFieldConfig.fieldParams as SelectAsyncConfiguration).filterUrl = newFilterUrl;
      });
    }

    if (typeof (fieldConfig.fieldParams as SelectAsyncConfiguration).disableFetching === "function") {
      const calcDisableFetchingValue = ((fieldConfig.fieldParams as SelectAsyncConfiguration)
        .disableFetching as DisableFetchingFunctionType)(props.values);

      fieldConfig = produce(fieldConfig, (draftFieldConfig) => {
        (draftFieldConfig.fieldParams as SelectAsyncConfiguration).disableFetching = calcDisableFetchingValue;
      });
    }
  }

  const [requireFieldRecalculation, setRequireFieldRecalculation] = useState({
    requireFlag: props.action === CREATE_ACTION ? null : false,
    values: props.values,
    action: props.action,
    initialValues: props.initialValues,
  });

  let dependentFieldsValuesArray, dependentFieldsValuesArrayPrev;

  let dependentValuesDiffer = false;
  if (isNonEmptyArray(fieldConfig.dependsOnField)) {
    dependentFieldsValuesArray = fieldConfig.dependsOnField.map((item) =>
      getAttributeByPath(props.values, item)
    );
    dependentFieldsValuesArrayPrev = fieldConfig.dependsOnField.map((item) =>
      getAttributeByPath(requireFieldRecalculation.values, item)
    );
    dependentValuesDiffer = !twoArraysAreIdentical(
      dependentFieldsValuesArrayPrev,
      dependentFieldsValuesArray
    );
  }

  // if (fieldConfig.dependsOnField) {
  //   console.log('DEPENDS ON FIELD', fieldConfig.dependsOnField);
  //   console.log('DEPENDS ON FIELD ARRAY',  JSON.stringify(dependentFieldsValuesArray));
  //   console.log('PREV VALUES', JSON.stringify(dependentFieldsValuesArrayPrev));
  //   console.log('ARE THEY DIFFERENT', dependentValuesDiffer);
  // }

  if (dependentValuesDiffer) {
    setRequireFieldRecalculation({
      requireFlag: true,
      values: props.values,
      action: props.action,
      initialValues: props.initialValues,
    });
  }

  ///
  const fieldName = props.fieldId;
  const setFieldValueDirect = props.setFieldValueDirect;
  const fieldValueCalculator = fieldConfig.defaultValue;
  const dispatchFieldsInProgress = props.dispatchFieldsInProgress;
  useEffect(() => {
    const calculateValueAsync = async () => {
      if (requireFieldRecalculation.requireFlag === true || requireFieldRecalculation.requireFlag === null) {
        if (typeof fieldValueCalculator === "function") {
          const sysdate = new Date();
          dispatchFieldsInProgress({
            type: FieldsInProgressActionTypeEnum.ADD,
            payload: { field: fieldName, timeStamp: sysdate.getTime() },
          });
          const recalculatedValue = await fieldValueCalculator(
            requireFieldRecalculation.values,
            requireFieldRecalculation.action,
            requireFieldRecalculation.initialValues
          );

          dispatchFieldsInProgress({
            type: FieldsInProgressActionTypeEnum.REMOVE,
            payload: { field: fieldName, timeStamp: sysdate.getTime() },
          });

          setFieldValueDirect(fieldName, recalculatedValue);

          setRequireFieldRecalculation((prevVersion) => {
            return {
              requireFlag: false,
              values: prevVersion.values,
              action: prevVersion.action,
              initialValues: prevVersion.initialValues,
            };
          });
        }
      }
    };

    calculateValueAsync();
  }, [
    requireFieldRecalculation,
    fieldName,
    setFieldValueDirect,
    fieldValueCalculator,
    dispatchFieldsInProgress,
  ]);

  return (
    <FormFieldRenderer
      fieldConfig={fieldConfig}
      fieldValue={fieldValue}
      // fieldDefaultValue={localDefaultValue.value}// {defaultValue}
      initialFieldValue={initialFieldValue}
      helperText={helperText}
      errorFlag={errorFlag}
      disabled={disabled}
      handleChange={props.handleChange}
      required={fieldIsRequired}
      setFieldValueDirect={props.setFieldValueDirect}
      dispatchFieldsInProgress={props.dispatchFieldsInProgress}
    />
  );
}
