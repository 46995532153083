import React from "react";
import { MainPanelWithParamsProps } from "components/Dashboard/MainPanelWithParams.d";
import { UserList } from "./UserList";
import { parseSearchStringIntoScreenRequest } from "components/Utils/RouteUtils";

export interface UserInventoryScreenProps {}

export function UserInventoryScreen(props: MainPanelWithParamsProps<UserInventoryScreenProps>) {
  const requestFromUrl = parseSearchStringIntoScreenRequest(props.location);

  return (
    <UserList
      userData={props.userData}
      currentEnterpriseId={props.currentEnterpriseId}
      inputRequest={requestFromUrl.request}
      allowUrlReplacementForFilters={true}
      allowReinitialization={true}
    />
  );
}
