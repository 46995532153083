import { ObjectIdType, ObjectWithKnownFieldsType } from "components/Utils/Object.d";
import {
  EventDataType,
  DataProviderOperationStatusType,
  EventDataTypeInternal,
  DataSourceParamsType,
} from "./DataProviderCommon.d";

export interface DataCrudDataType {
  data: Record<string, any>;
  transactionId: number;
}

export enum CrudEventAction {
  CREATE = "CREATE",
  READ = "READ",
  UPDATE = "UPDATE",
  DELETE = "DELETE",
}

export type CreateEventPayloadType = Record<string, any>;
export type ReadEventPayloadType = { id: ObjectIdType };
export type UpdateEventPayloadType = ObjectWithKnownFieldsType<{ id?: ObjectIdType }>;
export type DeleteEventPayloadType = { id: ObjectIdType };

export type CrudEventPayloadType =
  | CreateEventPayloadType
  | ReadEventPayloadType
  | UpdateEventPayloadType
  | DeleteEventPayloadType;

export type DataCrudEventType = EventDataType<CrudEventAction, CrudEventPayloadType>;
export type DataCrudEventTypeInternal = EventDataTypeInternal<CrudEventAction, CrudEventPayloadType>;

export enum DataCrudOperationStatus {
  INITIAL = "INITIAL",
  EXECUTING = "EXECUTING",
  APPLIED_SUCCESSFULLY = "APPLIED_SUCCESSFULLY",
  ERROR = "EXECUTION_ERROR",
}

export type DataCrudOperationStatusType = DataProviderOperationStatusType<
  CrudEventAction,
  DataCrudOperationStatus,
  DataCrudDataType
>;

export interface DataCrudProps {
  dataSource: DataSourceParamsType;
  prefetchId?: ObjectIdType;
  externalTriggerValues?: Array<any>;
  render: (
    crudData: DataCrudDataType,
    crudRequest: DataCrudEventType,
    crudStatus: Record<string, any>,
    dispatchCrudEvent: (event: DataCrudEventType) => void,
    activeTransactionId?: number
  ) => JSX.Element;
}
