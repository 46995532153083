import { StyleRulesCallback, Theme } from "@material-ui/core/styles";
import { blueGrey } from "@material-ui/core/colors";

export const tableActionButtonFaceStyle: StyleRulesCallback<Theme, {}> = (theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "16px",
    paddingRight: "16px",
    boxSizing: "border-box",
    height: "30px",
    width: "100%",
    borderRadius: "4px",
    justifyContent: "center",
    fontSize: "12px",
  },
  "& span": {
    fontSize: "12px",
  },
  default: {
    border: "1px solid #E0E0E0",
    // backgroundColor: '#f5f5f5',
    "& span": {
      // color: theme.palette.text.primary,
      color: theme.palette.primary.main,
      textTransform: "uppercase",
    },
    "& svg": {
      // color: theme.palette.text.secondary
      color: theme.palette.primary.main,
    },
  },
  unobtrusive: {
    border: "1px solid #E0E0E0",
    // backgroundColor: '#f5f5f5',
    "& span": {
      // color: theme.palette.text.primary,
      color: "#263238",
      textTransform: "uppercase",
    },
    "& svg": {
      // color: theme.palette.text.secondary
      color: blueGrey[600],
    },
  },
  important: {
    backgroundColor: theme.palette.warning.main,
    boxShadow: "0 1px 1px 0 rgba(0,0,0,0.14)",
    "& span": {
      // color: theme.palette.text.primary,
      color: "white",
      textTransform: "uppercase",
    },
    "& svg": {
      // color: theme.palette.text.secondary
      color: "white",
    },
  },
});
