import React from "react";
import { AssetGroupList } from "./AssetGroupList";
import { MainPanelWithParamsProps } from "components/Dashboard/MainPanelWithParams.d";
import { parseSearchStringIntoScreenRequest } from "components/Utils/RouteUtils";

export interface AssetGroupInventoryScreenProps {}

export function AssetGroupInventoryScreen(props: MainPanelWithParamsProps<AssetGroupInventoryScreenProps>) {
  const requestFromUrl = parseSearchStringIntoScreenRequest(props.location);

  return (
    <AssetGroupList
      userData={props.userData}
      currentEnterpriseId={props.currentEnterpriseId}
      inputRequest={requestFromUrl.request}
      allowUrlReplacementForFilters={true}
      allowReinitialization={true}
    />
  );
}
