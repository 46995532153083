import React from "react";
import { makeStyles } from "@material-ui/core";
import { outlinedBadgeStyle } from "./outlinedBadgeStyle";

export interface OutlinedBadgeProps {
  children: string | JSX.Element;
  htmlColor: string;
}

const useStyles = makeStyles(outlinedBadgeStyle, { name: "OutlinedBadge" });

export function OutlinedBadge(props: OutlinedBadgeProps) {
  const classes = useStyles({});

  const styleColorObject = {
    color: props.htmlColor,
    borderColor: props.htmlColor,
  };

  return (
    <div className={classes.root} style={styleColorObject}>
      {props.children}
    </div>
  );
}
