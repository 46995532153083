import React from "react";
import { SAMPLEPOINT_API_URL, SAMPLEPOINT_ENTITY_NAME } from "./SamplePoint.const";
import { UnifiedDataRequestType, SORT_ASC } from "components/Utils/CRUDUtils.d";
import { BaseListProps, TableColumnParameters, TableColumnFilter } from "components/Crud/Table/EntityTable.d";
import {
  generateCreateButtonConfig,
  generateEditButtonConfig,
  generateShowButtonConfig,
  generateDeleteButtonConfig,
} from "components/Buttons/ButtonsCommon";
import { DataSourceParamsType } from "components/Crud/DataProvider/DataProviderCommon.d";
import { filterBy } from "components/Utils/MiscUtils";
import { CrudQueryTableBinding } from "components/Crud/Table/CrudQueryTableBinding";
import { useVersion } from "components/Utils/CustomHooks";
import { SamplePointEditForm } from "./SamplePointEditForm";
import { ObjectIdType } from "components/Utils/Object.d";
import { ScreenSectionTitle } from "components/Elements/Typography/ScreenSectionTitle";

export interface SamplePointListAssetSummaryProps {
  assetId: ObjectIdType;
  autoFocusOnFirstRow?: boolean;
  contentToDisplayWhenNoData?: string | JSX.Element;
}

export function SamplePointListAssetSummary(props: BaseListProps & SamplePointListAssetSummaryProps) {
  const [version, increaseVersion] = useVersion();

  const columns: TableColumnParameters[] = [
    // { name: 'id', label: 'Id', width: 50, align: 'center'},
    { sourceName: "name", label: "Name" },
    { sourceName: "sampleType.name", label: "Sample Type" },
    {
      disableSorting: true,
      actionSet: [
        generateEditButtonConfig(SAMPLEPOINT_ENTITY_NAME, SamplePointEditForm, [increaseVersion], {
          assetId: props.assetId,
          title: "Edit Sensor Details",
        }),
        generateShowButtonConfig(SAMPLEPOINT_ENTITY_NAME, SamplePointEditForm, {
          assetId: props.assetId,
          title: "Show Sensor Details",
        }),
        generateDeleteButtonConfig(SAMPLEPOINT_ENTITY_NAME),
      ],
    },
  ];

  const uiFilters: Array<TableColumnFilter> = [];

  const dataSource: DataSourceParamsType = {
    apiUrl: SAMPLEPOINT_API_URL,
  };

  const initialRequest: UnifiedDataRequestType = {
    sortByColumn: "name",
    sortDirection: SORT_ASC,
    fieldList: ["name", "sampleType.name", "asset.siteId"],
    filter: {
      baseFilters: [filterBy("assetId", "=", props.assetId)],
    },
    isPaginationEnabled: true,
    rowsPerPage: 10,
    pageNum: 0,
  };

  const headerActions = [
    generateCreateButtonConfig(
      SAMPLEPOINT_ENTITY_NAME,
      "ADD SENSOR",
      SamplePointEditForm,
      [increaseVersion],
      { assetId: props.assetId, title: "Add Sensor" }
    ),
  ];

  return (
    <CrudQueryTableBinding
      externalTriggerValues={[props.assetId, props.currentEnterpriseId, props.userData, version]}
      dataSource={dataSource}
      intialQuery={initialRequest}
      title={<ScreenSectionTitle>{"Sensors"}</ScreenSectionTitle>}
      tableColumns={columns}
      onRowClickFunction={props.onRowClickFunction}
      // actionsAllowed={allowedActions}
      highlightedRowsPrimaryKeys={props.activeRowPrimaryKeys}
      uiFilters={uiFilters}
      headerActions={headerActions}
      autoFocusOnFirstRow={props.autoFocusOnFirstRow}
      contentToDisplayWhenNoData={props.contentToDisplayWhenNoData}
    />
  );
}
