import React from "react";
import { makeStyles } from "@material-ui/core";
import { moreActionsButtonFaceStyle } from "./moreActionsButtonFaceStyle";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

export interface MoreActionsButtonFaceProps {}

const useStyles = makeStyles(moreActionsButtonFaceStyle, { name: "MoreActionsButtonFace" });

export function MoreActionsButtonFace(props: MoreActionsButtonFaceProps) {
  const classes = useStyles({});

  return (
    <div className={classes.root}>
      <ExpandMoreIcon fontSize="small" />
      &nbsp;<div>{"MORE ACTIONS"}</div>
    </div>
  );
}
