import React from "react";
import { AssetCardCellContainer, CardCellLabelVariantType } from "./AssetCardCellContainer";
import { AlertBadge } from "components/Elements/Badge/AlertBadge";
import { isSpecified } from "components/Utils/MiscUtils";
import { getAttributeByPath } from "components/Crud/EntityUtils";
import { priorityDictionary } from "components/Dictionaries/DictionariesCommon";
import { AlertLevelMessageBlock } from "views/Alert/AlertLevelMessageBlock";
import { getDictionaryItemByCode } from "components/Dictionaries/DictionariesUtils";

export interface LatestAlertCardCellRendererProps {
  latestPendingNotification: Record<string, any>;
  pendingNotificationsCount: number;
  borderRight: boolean;
  borderLeft: boolean;
}

export function LatestAlertCardCellRenderer(props: LatestAlertCardCellRendererProps) {
  const displayLabel = `ALERTS (${
    isSpecified(props.pendingNotificationsCount) ? props.pendingNotificationsCount : 0
  })`;

  const alertMessage = isSpecified(props.latestPendingNotification)
    ? getAttributeByPath(props.latestPendingNotification, "message")
    : "";

  const eventLevelId = isSpecified(props.latestPendingNotification)
    ? getAttributeByPath(props.latestPendingNotification, "level")
    : undefined;

  const eventLevelItem = getDictionaryItemByCode(priorityDictionary, eventLevelId) as Record<string, any>;

  const alertDate = isSpecified(props.latestPendingNotification)
    ? getAttributeByPath(props.latestPendingNotification, "date")
    : null;

  return (
    <AssetCardCellContainer
      label={displayLabel}
      labelVariant={CardCellLabelVariantType.NORMAL}
      contentVerticalAlignment="middle"
      borderLeft={props.borderLeft}
      borderRight={props.borderRight}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {props.pendingNotificationsCount > 0 && <AlertBadge size="small" color={eventLevelItem.colorCode} />}
        <div style={{ width: "20px" }} />
        {alertMessage && (
          <div>
            <AlertLevelMessageBlock
              message={alertMessage}
              eventLevelId={eventLevelId}
              eventDate={alertDate}
              variant="small"
            />
            {props.pendingNotificationsCount > 1 && (
              <div style={{ height: "12px", fontSize: "11px", fontWeight: "bold" }}>
                {`+ ${props.pendingNotificationsCount - 1} more`}
              </div>
            )}
          </div>
        )}
      </div>
    </AssetCardCellContainer>
  );
}
