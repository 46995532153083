import React, { useContext } from "react";
import ActionButton from "./ActionButton";
import { generateUrlSearchFromSourceAndConfig, GLOBAL_DISPATCHER } from "./ButtonsCommon";
import { populateStringTemplate } from "components/Utils/StringUtils";
import { ObjectIdType } from "components/Utils/Object.d";
import { getAttributeByPath } from "components/Crud/EntityUtils";
import { EventDispatchersType } from "components/Crud/Table/StatelessTable.d";
import { isSpecified } from "components/Utils/MiscUtils";
import { useActiveUser, useActiveEnterprise } from "components/Utils/CustomHooks";
import { isActionAllowed } from "access-control/RBACEngine";
import {
  ActionButtonConfig,
  OnClickAction,
  OnClickDialog,
  OnClickDispatchEvent,
} from "./Buttons.d";
import { GlobalEventContext, GlobalPopupContext } from "GlobalContext";
import { GlobalEvent } from "GlobalContext.d";

// TODO: current architecture with invidial Callbacks is dependent on the config contents
export interface ActionButtonHandlerComponentProps {
  buttonConfig: ActionButtonConfig;
  id: ObjectIdType;
  object?: Record<string, any>;
  eventDispatchers?: EventDispatchersType;
  preActionCallback?: () => void;
  postActionCallback?: () => void;
}

export function ActionButtonHandlerComponent(props: ActionButtonHandlerComponentProps) {
  const globalEventContext = useContext(GlobalEventContext);

  const globalPopupContext = useContext(GlobalPopupContext);

  // Allowed Actions Segment
  const activeUser = useActiveUser();
  const activeEnterprise = useActiveEnterprise();

  const actionIsAllowed = isActionAllowed(
    props.buttonConfig.actionCode,
    activeEnterprise.id,
    activeUser,
    [],
    props.buttonConfig.actionObject
  );

  let ComponentToRender = null;

  if (actionIsAllowed) {
    let action;

    const navigateTo = (url: string) => {
      const navigateEvent = {
        type: GlobalEvent.NAVIGATE,
        payload: url,
        originator: "ActionButtonHandlerComponent.navigateTo",
      };

      if (globalEventContext /* .dispatchEvent */) {
        globalEventContext(/* .dispatchEvent */ navigateEvent);
      } else {
        console.error(
          "ActionButtonHandlerComponent - globalEventContext is not defined. Attempted event: ",
          JSON.stringify(navigateEvent)
        );
      }
    };

    // TODO: actions should be async, we dont want to be stuck
    // TODO: redo actions into message dispatcher
    if (props.buttonConfig.onClick.type === "action") {
      action = () => (props.buttonConfig.onClick as OnClickAction).fn(props.id, props.object);
    } else if (props.buttonConfig.onClick.type === "dialog") {
      action = () => {
        globalPopupContext.openPopup(
          (props.buttonConfig.onClick as OnClickDialog).dialogId,
          props.id,
          props.buttonConfig.actionCode,
          (props.buttonConfig.onClick as OnClickDialog).onCloseCallbacks,
          (props.buttonConfig.onClick as OnClickDialog).extraPropsObject,
          props.object
        );
      };
    } else if (props.buttonConfig.onClick.type === "navigate") {
      // Main String bit
      const routeParams: Record<string, any> = {};
      const paramName = props.buttonConfig.onClick.paramName ? props.buttonConfig.onClick.paramName : "id";

      // TODO: clean up the code
      const sourceValue = props.buttonConfig.onClick.paramSource
        ? getAttributeByPath(props.object, props.buttonConfig.onClick.paramSource)
        : props.id;

      routeParams[paramName] = sourceValue;

      const constNavigationMainUrl = populateStringTemplate(
        (props.buttonConfig.onClick).url,
        routeParams
      );

      // Search String bit;
      const searchUrl = generateUrlSearchFromSourceAndConfig(
        props.object,
        props.buttonConfig.onClick.urlSearch
      );

      const constNavigationUrl = `${constNavigationMainUrl}${searchUrl}`;

      action = () => navigateTo(constNavigationUrl);
    } else if (props.buttonConfig.onClick.type === "dispatchEvent") {
      action = () => {
        const buttonConfig = props.buttonConfig.onClick as OnClickDispatchEvent;

        const dispatcherName = buttonConfig.dispatcherName;
        const payload = buttonConfig.eventFormingFunction
          ? buttonConfig.eventFormingFunction(props.object)
          : props.object;

        if (isSpecified(props.eventDispatchers) && isSpecified(props.eventDispatchers[dispatcherName])) {
          props.eventDispatchers[dispatcherName]({
            action: buttonConfig.action,
            payload: payload,
            originator: "ActionButtonHandlerComponent::onClick.type = dispatchEvent",
          });
        } else if (dispatcherName === GLOBAL_DISPATCHER) {
          globalEventContext({
            type: buttonConfig.action as GlobalEvent,
            payload: payload,
            originator: "ActionButtonHandlerComponent::onClick.type = dispatchEvent",
          });
        } else {
          console.error(
            `ActionButtonHandlerComponent - props.eventDispatchers[${dispatcherName}] is not specified`
          );
        }
      };
    }

    const labelElement =
      typeof props.buttonConfig.labelElement === "function"
        ? props.buttonConfig.labelElement(props.object)
        : props.buttonConfig.labelElement;

    ComponentToRender = (
      <ActionButton
        color="white"
        labelElement={labelElement}
        confirm={props.buttonConfig.confirm}
        action={action}
        preActionCallback={props.preActionCallback}
        postActionCallback={props.postActionCallback}
      />
    );
  }

  return ComponentToRender;
}
