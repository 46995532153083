import React from "react";
import { makeStyles } from "@material-ui/core";
import { cardSubTitleStyle } from "./cardSubTitleStyle";

export interface CardSubTitleProps {
  // TODO: check code for the use of JSX.Element and JSX.Element and keep string only
  children: string | JSX.Element | Array<JSX.Element>;
}

const useStyles = makeStyles(cardSubTitleStyle, { name: "CardSubTitle" });

export function CardSubTitle(props: CardSubTitleProps) {
  const classes = useStyles({});
  // TODO: change typography to span
  return <span className={classes.root}>{props.children}</span>;
}
