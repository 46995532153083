import React from "react";
import { SvgIcon, useTheme } from "@material-ui/core";
import { IconsPathDescriptorType } from "../IconsCommon";

export const TrendArrowUpSvgPathObject = (color: string): IconsPathDescriptorType => {
  return {
    path:
      "m 5.8235173,15.820895 2.284662,2.284662 7.3865247,-7.386524 1.624224,1.624224 -0.264544,-5.2904868 -5.274729,-0.2559872 1.665554,1.6655544 z",
    strokeWidth: "1px",
    strokeColor: color,
    fillColor: color,
  };
};

export function TrendArrowUp(props: any) {
  const theme = useTheme();

  let color = theme.palette.success.dark;

  // TODO: this is a hack, color can be inherited from htmlColor properly
  if (props.htmlColor) {
    color = props.htmlColor;
  }

  const svgPathObject = TrendArrowUpSvgPathObject(color);

  return (
    <SvgIcon {...props}>
      <path
        d={svgPathObject.path}
        fill={svgPathObject.fillColor}
        strokeWidth={svgPathObject.strokeWidth}
        stroke={svgPathObject.strokeColor}
      />
    </SvgIcon>
  );
}
