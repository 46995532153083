import React from "react";
import { TrendArrowFlat } from "assets/icons/Trend/TrendArrowFlat";
import { TrendArrowDown } from "assets/icons/Trend/TrendArrowDown";
import { TrendArrowUp } from "assets/icons/Trend/TrendArrowUp";
import { TrendArrowUnknown } from "assets/icons/Trend/TrendArrowUnknown";
import { trendBadgeStyle } from "./trendBadgeStyle";
import { makeStyles } from "@material-ui/core";

export interface TrendBadgeProps {
  trend: number;
}

const useStyles = makeStyles(trendBadgeStyle, { name: "TrendBadge" });

export function TrendBadge(props: TrendBadgeProps) {
  const classes = useStyles({});

  let trendIcon: JSX.Element = null;

  if (props.trend === 0) {
    trendIcon = <TrendArrowFlat fontSize="small" />;
  } else if (props.trend < 0) {
    trendIcon = <TrendArrowDown fontSize="small" />;
  } else if (props.trend > 0) {
    trendIcon = <TrendArrowUp fontSize="small" />;
  } else {
    trendIcon = <TrendArrowUnknown />;
  }

  return <div className={classes.root}>{trendIcon}</div>;
}
