import React, { useState, useRef, useEffect, useCallback } from "react";
import { isNonEmptyArray, isSpecified } from "components/Utils/MiscUtils";
import { IconsPathDescriptorType } from "assets/icons/IconsCommon";
import { GeoEntityType } from "./GeoMap.d";

export const GOOGLE_MAP_API_KEY = "AIzaSyB-Vuc93QLqq0TTn26sbjVDSQ-ZFTBFruU"; // 'AIzaSyB3EtEBZPduMGUEqzDlrI4NknM9nac5HFk'; TODO temporary!
export const scriptId = "geoMapScript";

export interface GeoMapProps {
  geoEntityList: Array<GeoEntityType>;
  centeredAt?: { lat: number; lng: number };
}
export interface GeoMapState {
  markerSet: Array<any>;
  googleMap: any;
}

const DEFAULT_MAP_CENTER = {
  lat: -35.308244,
  lng: 149.124368,
};

const createMarker = (
  googleMap: any,
  title: string,
  label: string | google.maps.MarkerLabel,
  lattitude: number,
  longitude: number,
  customIconSvgPathObject?: IconsPathDescriptorType | { url: any },
  inputInfoWindow?: google.maps.InfoWindowOptions,
  targetOffset?: { x: number; y: number }
) => {
  // https://stackoverflow.com/questions/47434579/google-map-label-placement
  const markerConfigObject: google.maps.MarkerOptions = {
    position: { lat: lattitude, lng: longitude },
    title: title,
    label: label,
    map: googleMap,
  };

  if (customIconSvgPathObject && !customIconSvgPathObject.hasOwnProperty("url")) {
    markerConfigObject.icon = {
      path: (customIconSvgPathObject as IconsPathDescriptorType).path,
      fillColor: (customIconSvgPathObject as IconsPathDescriptorType).fillColor,
      strokeColor: (customIconSvgPathObject as IconsPathDescriptorType).strokeColor,
    };
  } else {
    // hint: use https://jakearchibald.github.io/svgomg/
    // https://ubilabs.net/en/news/create-google-maps-markers-with-inline-svg-2016-01-08
    markerConfigObject.icon = {
      url: (customIconSvgPathObject as { url: any }).url,
      // TODO: we don't use labels at this stage
      // labelOrigin: new google.maps.Point(0, 54)
    };

    if (isSpecified(targetOffset)) {
      markerConfigObject.icon.anchor = new google.maps.Point(targetOffset.x, targetOffset.y);
    }
  }

  const marker = new window.google.maps.Marker(markerConfigObject);

  if (inputInfoWindow) {
    // https://developers.google.com/maps/documentation/javascript/examples/infowindow-simple
    const infowindow = new google.maps.InfoWindow(inputInfoWindow);

    marker.addListener("click", () => {
      infowindow.open(googleMap, marker);
    });
  }

  return marker;
};

export function GeoMap(props: GeoMapProps) {
  const googleMapContainerRef = useRef();
  // const scriptLoadedRef = useRef(false);

  const [markerSet, setMarkerSet] = useState([]);
  const [googleMap, setGoogleMap] = useState(undefined);
  const [initialLoad, setInitialLoad] = useState(true);

  const markerSetRef = useRef([]);

  const createGoogleMap = useCallback(() => {
    let mapCenter: { lat: number; lng: number };

    if (isSpecified(props.centeredAt)) {
      mapCenter = props.centeredAt;
    } else if (isNonEmptyArray(props.geoEntityList)) {
      mapCenter = {
        lat: props.geoEntityList[0].lattitude,
        lng: props.geoEntityList[0].longitude,
      };
    } else {
      mapCenter = DEFAULT_MAP_CENTER;
    }

    const map = new window.google.maps.Map(googleMapContainerRef.current, {
      zoom: 16,
      center: mapCenter,
      mapTypeId: google.maps.MapTypeId.HYBRID,
      // disableDefaultUI: true
    });
    // https://sandbox.idre.ucla.edu/dh150/tutorials/google-maps-and-geojson-adding-an-infowindow
    // let kmlLayer = new window.google.maps.KmlLayer({
    //   url: 'https://developers.google.com/maps/documentation/javascript/examples/kml/westcampus.kml',
    //   suppressInfoWindows: true,
    //   preserveViewport: false,
    //   map: map
    // });

    // TODO: this code was changing label color on switching map type
    // it's not needed at this stage, but if the functionality is required - it has to be changed
    // map.addListener('maptypeid_changed', function () {
    //   // TODO: temporary code while we dont know how to style marker labels properly
    //   // Change Marker Label Color in case map type change
    //   // See ideas here:
    //   // https://webdesign.tutsplus.com/tutorials/getting-creative-with-the-google-maps-api--webdesign-13380
    //   // https://github.com/bilal-karim/gmaps-samples-v3/blob/master/latlng-to-coord-control/latlng-to-coord-control.html
    //   // https://medium.com/@barvysta/google-marker-api-lets-play-level-1-dynamic-label-on-marker-f9b94f2e3585
    //   let typeToColor: Record<string, any>, type, color: string, label;

    //   typeToColor = {
    //     'terrain': 'black',
    //     'roadmap': 'black',
    //     'hybrid': 'white',
    //     'satellite': 'white',
    //   };

    //   // type = map.getMapTypeId();
    //   // color = typeToColor[type];
    //   // if (isNonEmptyArray(markerSetRef.current)) {
    //   //   markerSetRef.current.forEach(markerItem => {
    //   //     label = markerItem.getLabel();
    //   //     label.color = color;
    //   //     markerItem.setLabel(label);
    //   //   });
    //   // }
    // });

    return map;
  }, [props.centeredAt, props.geoEntityList]);

  useEffect(() => {
    if (initialLoad) {
      setGoogleMap(createGoogleMap());

      // TODO: commented for NWP-317
      // let scriptMapReference = document.getElementById(scriptId);

      // scriptMapReference.onload = () => {
      //   // scriptLoadedRef.current = true;
      //   setGoogleMap(createGoogleMap());
      // };

      // if (!scriptMapReference) {
      //   const googleMapApiScriptElement = document.createElement('script');
      //   googleMapApiScriptElement.id = scriptId;

      //   googleMapApiScriptElement.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}`; // ?libraries=places`; // &libraries=places`;
      //   window.document.body.appendChild(googleMapApiScriptElement);

      //   googleMapApiScriptElement.onload = () => {
      //     // scriptLoadedRef.current = true;
      //     setGoogleMap(createGoogleMap());
      //   };
      // } else {
      //   setGoogleMap(createGoogleMap());
      // }

      setInitialLoad(false);
    }
  }, [
    initialLoad,
    createGoogleMap /* TODO: should be safe since even if the map is changed, we defer to initial load*/,
  ]);

  useEffect(() => {
    if (isSpecified(props.centeredAt) && isSpecified(googleMap)) {
      googleMap.panTo(props.centeredAt);
    }
  }, [props.centeredAt, googleMap]);

  const bulkAddMarkers = useCallback((googleMap: any, inputGeoEntityList: Array<GeoEntityType>) => {
    setMarkerSet([]);

    if (isNonEmptyArray(inputGeoEntityList)) {
      const bounds = new google.maps.LatLngBounds();
      const localMarkerSet = inputGeoEntityList.map((geoItem) => {
        return createMarker(
          googleMap,
          geoItem.title,
          geoItem.label,
          geoItem.lattitude,
          geoItem.longitude,
          geoItem.customIconSvgPathObject,
          geoItem.infoWindow,
          geoItem.targetOffset
        );
      });

      localMarkerSet.forEach((marker) => bounds.extend(marker.getPosition()));

      setMarkerSet(localMarkerSet);
      googleMap.fitBounds(bounds);
    }
  }, []);

  useEffect(() => {
    // ASSUMPTION: by using the initalLoad we make sure google map is defined only once
    // ASSUMPTION: we assume bulkAddMarkers is static
    if (isNonEmptyArray(markerSetRef.current)) {
      markerSetRef.current.forEach((markerItem) => markerItem.setMap(null));
    }

    if (isSpecified(props.geoEntityList) && isSpecified(googleMap)) {
      bulkAddMarkers(googleMap, props.geoEntityList);
    }
  }, [googleMap, bulkAddMarkers, props.geoEntityList]);

  useEffect(() => {
    // TODO: temporary container to keep markers ref outside of lifecycle, to allow changing their color on map type change
    markerSetRef.current = markerSet;
  }, [markerSet]);

  return (
    <div
      id="google-map"
      ref={googleMapContainerRef}
      style={{ width: "100%", height: "100%", minHeight: "27vh", flexGrow: 1 }}
    />
  );
}
