import React from "react";
import ActionEdit from "@material-ui/icons/Edit";
import ActionShow from "@material-ui/icons/Visibility";
import ActionDelete from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import { getAttributeByPath } from "components/Crud/EntityUtils";
import { EDIT_ACTION, SHOW_ACTION, DELETE_ACTION, CREATE_ACTION } from "components/Crud/Entity.const";
import { CRUD_DISPATCHER } from "components/Crud/DataProvider/DataCrud";
import { CrudEventAction } from "components/Crud/DataProvider/DataCrud.d";
import { TableActionButtonFace } from "./TableActionButtonFace";
import { isSpecified } from "components/Utils/MiscUtils";
import { AddButtonFace } from "./AddButtonFace";
import {
  ActionButtonConfig,
  BUTTON_VARIANT_TYPE,
  UrlSearchConfigType,
  UrlSearchValueAsIsType,
  UrlSearchValueFromSourceType,
  OnClickDispatchEvent,
} from "./Buttons.d";
import { DialogIdCallableType } from "popups/PopupDialogsFrame.d";

export const BUTTON_VARIANT: BUTTON_VARIANT_TYPE = "contained";
export const GLOBAL_DISPATCHER = "GLOBAL_DISPATCHER";

export const generateUrlSearchFromSourceAndConfig = (
  sourceObject: Record<string, any>,
  urlSearchConfig: UrlSearchConfigType
) => {
  let searchUrlString = "";

  if (isSpecified(urlSearchConfig)) {
    const urlSearchObject = new URLSearchParams();

    const keys = Object.keys(urlSearchConfig);
    keys.forEach((keyEntry) => {
      if ((urlSearchConfig[keyEntry] as UrlSearchValueAsIsType).value !== undefined) {
        urlSearchObject.append(keyEntry, (urlSearchConfig[keyEntry] as UrlSearchValueAsIsType).value);
      }
      if ((urlSearchConfig[keyEntry] as UrlSearchValueFromSourceType).valueSource !== undefined) {
        urlSearchObject.append(
          keyEntry,
          getAttributeByPath(
            sourceObject,
            (urlSearchConfig[keyEntry] as UrlSearchValueFromSourceType).valueSource
          )
        );
      }
    });

    searchUrlString = `?${urlSearchObject.toString()}`;
  }
  return searchUrlString;
};

export const generateCreateButtonConfig = (
  actionObject: string,
  buttonName: string,
  dialogId: DialogIdCallableType,
  onCloseCallbacks: Array<() => void>,
  extraPropsObject?: Record<string, any>
): ActionButtonConfig => {
  return {
    actionCode: CREATE_ACTION,
    actionObject: actionObject,
    name: "add",
    labelElement: (
      <AddButtonFace>
        <AddIcon fontSize="small" htmlColor="white" />
        &nbsp;<span>{buttonName}</span>
      </AddButtonFace>
    ),
    onClick: {
      type: "dialog",
      dialogId: dialogId,
      onCloseCallbacks: onCloseCallbacks,
      extraPropsObject: extraPropsObject,
    },
  };
};

export const generateEditButtonConfig = (
  actionObjectEntityName: string,
  dialogId: DialogIdCallableType,
  onCloseCallbacks: Array<() => void>,
  extraPropsObject?: Record<string, any>,
  doNotGroup?: boolean
): ActionButtonConfig => {
  return {
    actionCode: EDIT_ACTION,
    actionObject: actionObjectEntityName,
    name: "edit",
    labelElement: (
      <TableActionButtonFace>
        <ActionEdit />
        &nbsp;<span>Edit</span>
      </TableActionButtonFace>
    ),
    onClick: {
      type: "dialog",
      dialogId: dialogId,
      onCloseCallbacks: onCloseCallbacks,
      extraPropsObject: extraPropsObject,
    },
    doNotGroup: doNotGroup,
  };
};

export const generateShowButtonConfig = (
  actionObject: string,
  dialogId: DialogIdCallableType,
  extraPropsObject?: Record<string, any>,
  doNotGroup?: boolean
): ActionButtonConfig => {
  return {
    actionCode: SHOW_ACTION,
    actionObject: actionObject,
    name: "show",
    labelElement: (
      <TableActionButtonFace>
        <ActionShow />
        &nbsp;<span>Show</span>
      </TableActionButtonFace>
    ),
    onClick: {
      type: "dialog",
      dialogId: dialogId,
      onCloseCallbacks: undefined,
      extraPropsObject: extraPropsObject,
    },
    doNotGroup: doNotGroup,
  };
};

export const generateDeleteButtonConfig = (actionObject: string) => {
  return {
    actionObject: actionObject,
    actionCode: DELETE_ACTION,
    name: "delete",
    labelElement: (
      <TableActionButtonFace>
        <ActionDelete />
        &nbsp;<span>Delete</span>
      </TableActionButtonFace>
    ),
    onClick: {
      type: "dispatchEvent",
      dispatcherName: CRUD_DISPATCHER,
      action: CrudEventAction.DELETE,
    } as OnClickDispatchEvent,
    confirm: { message: "Are you sure you want to delete this?", dialogButton: "Delete" },
  };
};

// export const DEFAULT_DELETE_BUTTON_CONFIG: ActionButtonConfig = {
//   actionCode: DELETE_ACTION,
//   name: 'delete',
//   labelElement: <TableActionButtonFace><ActionDelete/>&nbsp;<span>Delete</span></TableActionButtonFace>,
//   onClick: {
//     type: 'dispatchEvent',
//     dispatcherName: CRUD_DISPATCHER,
//     action: CrudEventAction.DELETE

//   } as OnClickDispatchEvent,
//   confirm: { message: 'Are you sure you want to delete this?', dialogButton: 'Delete'},
// };
