import { MenuItemType } from "../Form/Fields/FieldConfiguration.d";

export type OnValueOptionsAvailableCallbackType = (fieldName: string, options: Array<MenuItemType>) => void;

export enum AsyncValuesAction {
  FLUSH_VALUES = "CLEAR_VALUES",
  UPDATE_VALUES = "UPDATE_VALUES",
}

export interface AsyncValuesEventType {
  action: AsyncValuesAction;
  payload?: any;
}

export interface ValueOptionsStateType {
  valueOptionsAreAvailable: boolean;
  valueOptions: Array<MenuItemType>;
}
