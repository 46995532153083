import React from "react";
import entityTableRowsetStyle from "../Sections/entityTableRowsetStyle";
import { makeStyles, TableRow } from "@material-ui/core";
import { RowRendererProps } from "./EntityTableUnifiedTableRowRenderer";
import clsx from "clsx";
import { EntityTableCell } from "../Sections/EntityTableCell";

const useStyles = makeStyles(entityTableRowsetStyle, { name: "TableRowStandard" });

export function TableRowStandard(props: RowRendererProps) {
  const classes = useStyles({});

  const onClickHandler = props.onRowClickFunction
    ? () => props.onRowClickFunction(props.rowDataObject.id, props.rowDataObject)
    : undefined;

  return (
    <TableRow
      hover
      classes={{
        root: clsx(classes.root),
        hover: classes.hover,
        selected: classes.selected,
      }}
      selected={props.isSelected}
      onClick={onClickHandler}
    >
      {props.tableColumns
        .filter((columnItem) => !columnItem.hide)
        .map((column: any, columnIndex: number) => (
          <EntityTableCell
            key={columnIndex}
            rowDataObject={props.rowDataObject}
            column={column}
            zeroPadding={props.zeroPadding}
            cellContainerElement="td"
            eventDispatchers={props.eventDispatchers}
            restrictActions={props.restrictActions}
          />
        ))}
    </TableRow>
  );
}
