import React from "react";

import { getAttributeByPath } from "components/Crud/EntityUtils";
import { ScreenTitle } from "components/Elements/Typography/ScreenTitle";
import { ObjectIdType } from "components/Utils/Object.d";
import { DataSourceParamsType } from "components/Crud/DataProvider/DataProviderCommon.d";
import {
  DataCrudDataType,
  DataCrudEventType,
  DataCrudOperationStatusType,
} from "components/Crud/DataProvider/DataCrud.d";
import { DataCrud } from "components/Crud/DataProvider/DataCrud";
import { makeStyles } from "@material-ui/core";
import { entityNameScreenTitleStyle } from "./entityNameScreenTitleStyle";

export interface EntityNameScreenTitleProps {
  id: ObjectIdType;
  entityName: string;
  apiUrl: string;
  fieldName?: string;
  externalTriggerValues?: Array<any>;
}

const useStyles = makeStyles(entityNameScreenTitleStyle, { name: "EntityNameScreenTitle" });

export function EntityNameScreenTitle(props: EntityNameScreenTitleProps) {
  const classes = useStyles({});

  const dataSource: DataSourceParamsType = {
    apiUrl: props.apiUrl,
  };

  const defaultDataSourceFieldName = "name";
  const dataSourceFieldName = props.fieldName || defaultDataSourceFieldName;

  return (
    <DataCrud
      prefetchId={props.id}
      dataSource={dataSource}
      externalTriggerValues={[props.id, ...(props.externalTriggerValues || [])]}
      render={(
        crudData: DataCrudDataType,
        crudRequest: DataCrudEventType,
        crudStatus: DataCrudOperationStatusType,
        dispatchCrudEvent: (event: DataCrudEventType) => void
      ) => {
        return (
          <div className={classes.root}>
            <ScreenTitle>{getAttributeByPath(crudData.data || {}, dataSourceFieldName)}</ScreenTitle>
          </div>
        );
      }}
    />
  );
}
