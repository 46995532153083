import { StyleRulesCallback, Theme } from "@material-ui/core";

const chippedSelectFieldStyle: StyleRulesCallback<Theme, {}> = (theme) => ({
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 0,
    maxWidth: "150px",
  },
  dropdown: {
    maxWidth: "200px",
  },
  ellipsisOnOverflow: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflowX: "hidden",
  },
});

export default chippedSelectFieldStyle;
