import React, { useCallback, useMemo } from "react";
import GraphComponent from "components/Graph/GraphComponent";
import { SAMPLEPOINT_API_URL } from "./SamplePoint.const";
import { GraphDataProvider } from "components/Crud/DataProvider/GraphDataProvider";
import { graphDataSetRetrievalUrl } from "components/Graph/GraphCommon";
import { filterBy, isNonEmptyArray } from "components/Utils/MiscUtils";
import { API_LIB } from "components/Utils/API_LIB";
import { getAttributeByPath } from "components/Crud/EntityUtils";
import { SampleTypeEnum } from "components/Dictionaries/Dictionaries.d";
import {
  SampleDateType,
  AnalysisType,
  SampleEndpointResponseSegment,
  RequestedDataRangeType,
  GraphDataProviderDataType,
} from "components/Graph/Graph.d";
import { DatasetRetrievalRequestType } from "components/Crud/DataProvider/GraphDataProvider.d";
import { fetchEntityListByQuery } from "components/Utils/CRUDUtils";
import { SORT_ASC } from "components/Utils/CRUDUtils.d";

interface SamplePointGraphDataViewProps {
  assetId: number;
  title?: string | JSX.Element;
}

export function SamplePointGraphDataView(props: SamplePointGraphDataViewProps) {
  const getListOfDataProviders = useCallback(async () => {
    const parsedResponse = await fetchEntityListByQuery(
      {
        fieldList: ["id", "name", "sampleTypeId", "sampleType"],
        filter: {
          baseFilters: [filterBy("assetId", "=", props.assetId)],
        },
        sortByColumn: "id",
        sortDirection: SORT_ASC,
      },
      SAMPLEPOINT_API_URL
    );

    return parsedResponse;
  }, [props.assetId]);

  const generateDatasetRetrievalUrl = useCallback(
    (sampleTypeId: SampleTypeEnum, id: number, startDate: Date, endDate: Date) => {
      const resultUrl = graphDataSetRetrievalUrl(
        API_LIB.SAMPLE_POINT_SAMPLES_OVER_TIME,
        sampleTypeId,
        id,
        startDate,
        endDate
      );

      return resultUrl;
    },
    []
  );

  const currentValueRetrievalUrl = useCallback(
    (sampleTypeId: SampleTypeEnum, id: number, startDate: Date, endDate: Date) => {
      const resultUrl = `${SAMPLEPOINT_API_URL}?filter=id::eq::${id}`;

      return resultUrl;
    },
    []
  );

  // TODO: technically it can be hoisted
  const currentValueTransformationFunction = useCallback(
    (data: any, originalRequest: DatasetRetrievalRequestType) => {
      // TODO: refactor to ensure robustness
      const result: Array<any> = [];

      if (isNonEmptyArray(data)) {
        const dateUpdated = getAttributeByPath(data[0], "currentStatus.dateUpdated");

        if (originalRequest.rangeStartDate < dateUpdated && dateUpdated < originalRequest.rangeEndDate) {
          const analysisType = getAttributeByPath(data[0], "currentStatus.analysisType");

          if (analysisType === AnalysisType.FORECAST) {
            const value = getAttributeByPath(data[0], "currentStatus.value[0].value");
            const units = getAttributeByPath(data[0], "currentStatus.value[0].units");
            const dateType = getAttributeByPath(data[0], "sampleType.dateType");

            const valueFieldName = getAttributeByPath(data[0], "sampleType.name");

            const resultValueFieldDescriptor = {
              name: valueFieldName,
              units: units,
            };

            const resultObject: { data: Array<SampleEndpointResponseSegment> } = {
              data: [
                {
                  analysisType: analysisType,
                  isValueForecasted: analysisType === AnalysisType.FORECAST,
                  samples: [
                    [
                      dateUpdated,
                      dateType === SampleDateType.TIME_INTERVAL ? undefined : value,
                      dateType === SampleDateType.TIME_INTERVAL ? value : undefined,
                    ],
                  ],
                  fields: [
                    { name: "Date", units: "ms" },
                    dateType === SampleDateType.TIME_INTERVAL
                      ? { name: "Start Date", units: "ms" }
                      : resultValueFieldDescriptor,
                    dateType === SampleDateType.TIME_INTERVAL ? resultValueFieldDescriptor : undefined,
                  ],
                },
              ],
            };

            result.push(resultObject);
          }
        }
      }

      return result;
    },
    []
  );

  // TODO: come up with a better architecture than using memos
  const generateDatasetRetrievalUrlArray = useMemo(
    () => [generateDatasetRetrievalUrl, currentValueRetrievalUrl],
    [generateDatasetRetrievalUrl, currentValueRetrievalUrl]
  );
  const datasetTransformationFunctionsArray = useMemo(() => [undefined, currentValueTransformationFunction], [
    currentValueTransformationFunction,
  ]);

  return (
    <GraphDataProvider
      getListOfDataProviders={getListOfDataProviders}
      generateDatasetRetrievalUrl={generateDatasetRetrievalUrlArray}
      datasetTransformationFunctions={datasetTransformationFunctionsArray}
    >
      {(
        dataArray: Array<SampleEndpointResponseSegment>,
        isDataLoading: boolean,
        dispatchDataRequest: (graphDataRequest: RequestedDataRangeType) => void,
        dataProviders: Array<GraphDataProviderDataType>
      ) => {
        const timeZone = isNonEmptyArray(dataProviders)
          ? getAttributeByPath(dataProviders[0], "asset.site.timezone.code")
          : undefined;
        // TODO: keep for testing purposes timeZone = 'America/Chicago';

        return (
          <GraphComponent
            title={props.title}
            dataArray={dataArray}
            isDataLoading={isDataLoading}
            requestData={dispatchDataRequest}
            dataProviders={dataProviders}
            timeZone={timeZone}
          />
        );
      }}
    </GraphDataProvider>
  );
}
