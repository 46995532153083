import React from "react";
import { MainPanelWithParamsProps } from "components/Dashboard/MainPanelWithParams.d";
import { parseSearchStringIntoScreenRequest } from "components/Utils/RouteUtils";
import { SensorListScreen } from "./SensorListScreen";
import { ObjectIdType } from "components/Utils/Object.d";

export interface SensorInventoryScreenProps {
  monitorConfigurationId?: number;
  monitorId?: ObjectIdType;
}

export function SensorInventoryScreen(
  props: MainPanelWithParamsProps<SensorInventoryScreenProps> & SensorInventoryScreenProps
) {
  const requestFromUrl = parseSearchStringIntoScreenRequest(props.location);

  return (
    <SensorListScreen
      userData={props.userData}
      currentEnterpriseId={props.currentEnterpriseId}
      inputRequest={requestFromUrl.request}
      allowUrlReplacementForFilters={true}
      allowReinitialization={true}
      monitorConfigurationId={props.monitorConfigurationId}
      monitorId={props.monitorId}
    />
  );
}
