import React from "react";
import { isSpecified, isNonEmptyArray } from "components/Utils/MiscUtils";
import {
  mergeFirstLevelPropertyIntoRequest,
  mergeFirstLevelPropertiesIntoRequest,
  generateQueryStringByPropertyFilter,
} from "components/Utils/CRUDUtils";
import { QueryEventAction } from "../DataProvider/DataQuery.d";
import { Paper, Box, TablePagination, makeStyles, LinearProgress } from "@material-ui/core";
import { TableDividerElement } from "./TableDividerElement";
import clsx from "clsx";
import tableStyle from "./tableStyle";
import { EntityTableUnifiedTableRenderer } from "./Content/EntityTableUnifiedTableRenderer";
import { EntityTableColumnHead } from "./Sections/EntityTableColumnHead";
import { EntityTableUnfiedTableBodyRenderer } from "./Content/EntityTableUnfiedTableBodyRenderer";
import { TableSingleValueRow } from "./TableSingleValueRow";
import { EntityTableRowset } from "./Sections/EntityTableRowset";
import { EntityTableHeader } from "./Sections/EntityTableHeader";
import { StatelessTableProps } from "./StatelessTable.d";
import { QUERY_DISPATCHER } from "../DataProvider/DataQuery";
import { dataDisplayOptions } from "components/Dictionaries/DictionariesCommon";
import { TableBodyLayoutType } from "./EntityTable.d";
import { useHistory, useLocation } from "react-router-dom";
import { ConditionalRender } from "layouts/ConditionalRender";
import { ActionButtonConfig } from "components/Buttons/Buttons.d";
import { Order, SORT_ASC, SORT_DESC } from "components/Utils/CRUDUtils.d";

const useStyles = makeStyles(tableStyle, { name: "StatelessTable" });

export function StatelessTable(props: StatelessTableProps) {
  const classes = useStyles({});

  const history = useHistory();
  const location = useLocation();

  // Code
  const rowsPerPageOptions = [5, 10, 25, 50];

  const tableVariant: TableBodyLayoutType = props.tableVariant || TableBodyLayoutType.FULL_WIDTH_HTML;

  let Surface: React.ElementType;

  if (tableVariant === TableBodyLayoutType.FULL_WIDTH_HTML && !props.paperless) {
    Surface = Paper;
  } else {
    Surface = Box;
  }

  const columnCount = props.tableColumns.length + 1;

  const handleSort = (sortColumn: string) => {
    if (isSpecified(props.queryParams)) {
      // TODO: should sort with event which contains direction and a column
      let sortDirection: Order;

      if (props.queryParams.sortByColumn === sortColumn) {
        sortDirection = props.queryParams.sortDirection === SORT_DESC ? SORT_ASC : SORT_DESC;
      } else {
        sortDirection = SORT_DESC;
      }

      const sortRequest = {
        sortDirection: sortDirection,
        sortByColumn: sortColumn,
      };

      const newDataRequest = mergeFirstLevelPropertiesIntoRequest(props.queryParams, sortRequest);

      props.eventDispatchers[QUERY_DISPATCHER]({
        action: QueryEventAction.REQUEST_DATA,
        payload: newDataRequest,
        originator: "StatelessTable::handleSort",
      });

      if (props.allowUrlReplacementForFilters) {
        // TODO: probably it should be done at the DataQueryBindingLevel
        generateQueryStringByPropertyFilter(newDataRequest, location, history);
      }
    }
  };

  // const handleFiltersUpdate = (event: PropertyFilterCriteriaType) => {
  //   props.onRequestDataEventDispatcher({
  //     action: QueryEventAction.REQUEST_DATA,
  //     payload: mergePropertyFiltersIntoRequest(props.queryParams, event),
  //     originator: 'StatelessTable::handleFiltersUpdate'
  //   });
  // };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPageNum: number
  ) => {
    const newDataRequest = mergeFirstLevelPropertyIntoRequest(props.queryParams, "pageNum", newPageNum);

    props.eventDispatchers[QUERY_DISPATCHER]({
      action: QueryEventAction.REQUEST_DATA,
      payload: newDataRequest,
      originator: "StatelessTable::handleChangeRowsPerPage",
    });

    if (props.allowUrlReplacementForFilters) {
      // TODO: probably it should be done at the DataQueryBindingLevel
      generateQueryStringByPropertyFilter(newDataRequest, location, history);
    }
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newDataRequest = mergeFirstLevelPropertyIntoRequest(
      props.queryParams,
      "rowsPerPage",
      parseInt(event.target.value, 10)
    );

    props.eventDispatchers[QUERY_DISPATCHER]({
      action: QueryEventAction.REQUEST_DATA,
      payload: newDataRequest,
      originator: "StatelessTable::handleChangeRowsPerPage",
    });

    if (props.allowUrlReplacementForFilters) {
      // TODO: probably it should be done at the DataQueryBindingLevel
      generateQueryStringByPropertyFilter(newDataRequest, location, history);
    }
  };

  const noDataContent = props.contentToDisplayWhenNoData || <div>There are no results.</div>;

  // TODO: temporary. refactor to better code
  const allowedTableVariants = isSpecified(props.allowedTableVariants)
    ? props.allowedTableVariants
    : [TableBodyLayoutType.FULL_WIDTH_HTML];

  const availableDisplayOptions = dataDisplayOptions.filter((item) =>
    allowedTableVariants.includes(item.code as TableBodyLayoutType)
  );

  let headerActions: Array<ActionButtonConfig>;

  if (isNonEmptyArray(props.headerActions)) {
    const restrictedHeaderActions = props.restrictActions || [];
    headerActions = props.headerActions.filter((item) => !restrictedHeaderActions.includes(item.actionCode));
  }

  return (
    <div
      className={
        tableVariant !== TableBodyLayoutType.FULL_WIDTH_HTML
          ? classes.tableWrapperScrollWithinPage
          : classes.tableWrapperScrollFullPage
      }
    >
      <div
        className={
          tableVariant !== TableBodyLayoutType.FULL_WIDTH_HTML
            ? classes.tableContentWrapperScrollWithinPage
            : classes.tableContentWrapperScrollFullPage
        }
      >
        <EntityTableHeader
          queryParams={props.queryParams}
          title={props.title}
          onRequestDataEventDispatcher={props.eventDispatchers[QUERY_DISPATCHER]}
          actionsAllowed={props.actionsAllowed}
          uiFilters={props.uiFilters}
          headerActions={headerActions}
          enableModeSwitch={props.enableModeSwitch}
          radioChangeFunction={props.radioChangeFunction}
          radioActiveValue={tableVariant}
          radioOptions={availableDisplayOptions}
          disableFilters={props.disableFilters}
          allowUrlReplacementForFilters={props.allowUrlReplacementForFilters}
        />

        {tableVariant !== TableBodyLayoutType.FULL_WIDTH_HTML && <TableDividerElement />}
        <div
          style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}
          className={clsx(
            tableVariant === TableBodyLayoutType.FULL_WIDTH_HTML && classes.tableResponsive,
            classes.marginTop,
            tableVariant === TableBodyLayoutType.FULL_WIDTH_CARD_MULTI && classes.table2ColumnCardProtector
          )}
        >
          <Surface
            style={{
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
              boxSizing: "border-box",
              margin: "1px",
            }}
          >
            {tableVariant === TableBodyLayoutType.FULL_WIDTH_INJECTED_CONTENT && props.isDataLoading && (
              <div>
                <LinearProgress />
              </div>
            )}
            <div id="sub-surface" style={{ display: "flex", flexGrow: 1 }}>
              <ConditionalRender condition={tableVariant !== TableBodyLayoutType.FULL_WIDTH_INJECTED_CONTENT}>
                <div style={{ flexBasis: 0, flexGrow: 1 }}>
                  <EntityTableUnifiedTableRenderer
                    tableContainerElement={
                      tableVariant === TableBodyLayoutType.FULL_WIDTH_HTML ? "table" : "div"
                    }
                  >
                    {tableVariant === TableBodyLayoutType.FULL_WIDTH_HTML &&
                      props.disableTableColumns !== true && (
                        <EntityTableColumnHead
                          sortByColumn={
                            isSpecified(props.queryParams) ? props.queryParams.sortByColumn : undefined
                          }
                          sortDirection={
                            isSpecified(props.queryParams) ? props.queryParams.sortDirection : undefined
                          }
                          handleSort={handleSort}
                          columns={props.tableColumns}
                        />
                      )}
                    <EntityTableUnfiedTableBodyRenderer variant={tableVariant}>
                      {props.isDataLoading ? (
                        tableVariant === TableBodyLayoutType.FULL_WIDTH_HTML ? (
                          <TableSingleValueRow columnCount={columnCount}>
                            <LinearProgress />
                          </TableSingleValueRow>
                        ) : (
                          <div>
                            <LinearProgress />
                          </div>
                        )
                      ) : ((props.tableData && props.tableData.length) || 0) === 0 ? (
                        tableVariant === TableBodyLayoutType.FULL_WIDTH_HTML ? (
                          <TableSingleValueRow columnCount={columnCount}>{noDataContent}</TableSingleValueRow>
                        ) : (
                          <React.Fragment>{noDataContent}</React.Fragment>
                        )
                      ) : (
                        /*                         <div>There are no results.</div> */
                        <EntityTableRowset
                          tableData={props.tableData}
                          columns={props.tableColumns}
                          actionsAllowed={props.actionsAllowed}
                          onRowClickFunction={props.onRowClickFunction}
                          highlightedRowsPrimaryKeys={props.highlightedRowsPrimaryKeys}
                          zeroPadding={props.zeroPadding}
                          tableRowContainerElement={
                            tableVariant === TableBodyLayoutType.FULL_WIDTH_HTML ? "tr" : "div"
                          }
                          eventDispatchers={props.eventDispatchers}
                          autoFocusOnFirstRow={props.autoFocusOnFirstRow}
                          restrictActions={props.restrictActions}
                        />
                      )}
                    </EntityTableUnfiedTableBodyRenderer>
                  </EntityTableUnifiedTableRenderer>
                </div>
              </ConditionalRender>
              {tableVariant === TableBodyLayoutType.HALF_WIDTH_INJECTED_CONTENT ||
              tableVariant === TableBodyLayoutType.FULL_WIDTH_INJECTED_CONTENT ? (
                <div
                  className={clsx(
                    classes.injectedContent,
                    tableVariant === TableBodyLayoutType.HALF_WIDTH_INJECTED_CONTENT &&
                      classes.injectedContentHalf
                  )}
                >
                  {props.injectedContent(props.tableData, props.highlightedRowsPrimaryKeys)}
                </div>
              ) : null}
            </div>
            {tableVariant && !props.disablePaginationSection ? (
              <TablePagination
                rowsPerPageOptions={rowsPerPageOptions}
                component="div"
                count={props.totalRows || 0}
                rowsPerPage={props.queryParams.rowsPerPage}
                page={props.totalRows ? props.queryParams.pageNum : 0}
                backIconButtonProps={{ "aria-label": "Previous Page" }}
                nextIconButtonProps={{ "aria-label": "Next Page" }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            ) : null}
          </Surface>
        </div>
      </div>
    </div>
  );
}
