import React, { useState, useEffect } from "react";
import { DataOwnerParameters } from "components/Utils/CRUDUtils.d";
import { generateEntityPathString } from "components/Utils/CRUDUtils";
import { BaseListProps } from "components/Crud/Table/EntityTable.d";
import { ObjectIdType } from "components/Utils/Object.d";
import { MonitorConfigurationList } from "./MonitorConfigurationList";

export interface MonitorConfigurationListScreenProps {
  monitorId: ObjectIdType;
}

export function MonitorConfigurationListScreen(props: BaseListProps & MonitorConfigurationListScreenProps) {
  const TITLE_SUFFIX = "Monitor Configuration";

  const [title, setTitle] = useState(TITLE_SUFFIX);

  useEffect(() => {
    const dataOwner: DataOwnerParameters[] = [
      {
        endpointUrl: `/monitor/${props.monitorId}`,
        fields: ["name"],
        titlePrefixTemplate: "Monitor :name > ",
      },
    ];

    generateEntityPathString(dataOwner, (value: string) => setTitle(`${value}${TITLE_SUFFIX}`));
  }, [props.monitorId]);

  return (
    <MonitorConfigurationList
      title={title}
      onRowClickFunction={props.onRowClickFunction}
      activeRowPrimaryKeys={props.activeRowPrimaryKeys}
      userData={props.userData}
      currentEnterpriseId={props.currentEnterpriseId}
      monitorId={props.monitorId}
    />
  );
}
