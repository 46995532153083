import React from "react";
import { isSpecified } from "components/Utils/MiscUtils";

export interface RotatingLoadingArrowCircleProps {
  isRotating?: boolean;
}

export function RotatingLoadingArrowCircle(props: RotatingLoadingArrowCircleProps) {
  const localIsRotating = isSpecified(props.isRotating) ? props.isRotating : true;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100">
      <g>
        <path
          d="M 58.55050358, 26.50768448 A 25 25 0 0 1 75,50 L 80,50 L 72.5 55 L 65, 50 L 70,50 A 20 20 0 0 0 56.84040287,31.20614758 Z"
          fill="#546e7a"
          stroke="#546e7a"
        />
        <path
          transform="rotate(90, 50, 50)"
          d="M 58.55050358, 26.50768448 A 25 25 0 0 1 75,50 L 80,50 L 72.5 55 L 65, 50 L 70,50 A 20 20 0 0 0 56.84040287,31.20614758 Z"
          fill="#546e7a"
          stroke="#546e7a"
        />
        <path
          transform="rotate(180, 50, 50)"
          d="M 58.55050358, 26.50768448 A 25 25 0 0 1 75,50 L 80,50 L 72.5 55 L 65, 50 L 70,50 A 20 20 0 0 0 56.84040287,31.20614758 Z"
          fill="#546e7a"
          stroke="#546e7a"
        />
        <path
          transform="rotate(270, 50, 50)"
          d="M 58.55050358, 26.50768448 A 25 25 0 0 1 75,50 L 80,50 L 72.5 55 L 65, 50 L 70,50 A 20 20 0 0 0 56.84040287,31.20614758 Z"
          fill="#546e7a"
          stroke="#546e7a"
        />
        {localIsRotating && (
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="0 50 50"
            to="360 50 50"
            repeatCount="indefinite"
            dur="7s"
          />
        )}
      </g>
    </svg>
  );
}
