import { TokenUtils } from "components/Session/TokenUtils";
import { getAttributeByPath, setAttributeByPath, hasAttributeByPath } from "../Crud/EntityUtils";
import { EDIT_ACTION, CREATE_ACTION } from "../Crud/Entity.const";
import { FieldConfiguration } from "../Crud/Form/Fields/FieldConfiguration.d";
import { preUrl } from "views/constants";
import { populateStringTemplate } from "./StringUtils";
import { ObjectIdType } from "./Object.d";
import { isSpecified, arrayIncludes, isFormSpecified, isNonEmptyArray } from "./MiscUtils";
import produce from "immer";
import History from "history";
import { RouteSearchKeys } from "./RouteUtils.d";
import { GlobalFetchCache } from "./FetchCache";
import { EntityActionType } from "components/Crud/Entity.d";
import {
  RequestNode,
  ColumnRequestObject,
  UnifiedDataRequestType,
  FilterCriteria,
  FilterMatchTypes,
  FilterParameters,
  PaginationState,
  FetchResultStatusType,
  OPERATION_SUCCESS,
  OPERATION_ERROR,
  DataOwnerParameters,
  FetchResultType,
  PropertyFilterCriteriaType,
} from "./CRUDUtils.d";

const LEAVES = "LEAVES";
const NODES = "NODES";
const NODE_NAME = "NODE_NAME";

function convertTreeIntoRequest(tree: RequestNode, path: Array<string>, mutatingObject: ColumnRequestObject) {
  const isRoot = () => !(path && tree[NODE_NAME]);

  const pathAtThisNode = !isRoot() ? [...path, tree[NODE_NAME]] : [];

  if (isRoot() && tree.hasOwnProperty(LEAVES)) {
    mutatingObject.fields = tree[LEAVES].join(",");
  }

  if (!isRoot() && tree.hasOwnProperty(LEAVES)) {
    mutatingObject.join.push(`${pathAtThisNode.join(".")}::${tree[LEAVES].join(",")}`);
  }

  if (!isRoot() && tree.hasOwnProperty(NODES) && !tree.hasOwnProperty(LEAVES)) {
    mutatingObject.join.push(`${pathAtThisNode.join(".")}`);
  }

  if (tree.hasOwnProperty(NODES)) {
    tree[NODES].forEach((node: RequestNode) => convertTreeIntoRequest(node, pathAtThisNode, mutatingObject));
  }
}

function generateUrlByRequestObject(requestObject: UnifiedDataRequestType): URLSearchParams {
  const urlParamsForColumns =
    requestObject.fieldList && generateURLSearchParamsForColumnsToShow(requestObject.fieldList);

  // TODO: we try to ger rid of requestObject.filterCriteria
  const urlParamsForFilter = generateURLSearchParamsForFilter(requestObject.filter);

  const urlParamsForPagination = generateURLSearchParamsForPagination(requestObject.isPaginationEnabled, {
    rowsPerPage: requestObject.rowsPerPage,
    pageNum: requestObject.pageNum,
    sortByColumn: requestObject.sortByColumn,
    sortDirection: requestObject.sortDirection,
  });

  const urlSearchParams = mergeURLSearchParams(
    urlParamsForColumns,
    urlParamsForFilter,
    urlParamsForPagination
  );

  return urlSearchParams;
}

function extractColumnsToFetch(columns: string[]) {
  const columnNames = columns.map((column) =>
    column.split(".").map((columnName) => {
      let transformedColumnName;

      // TODO: the following if might be redundant after requested columns and columns displayed were separated
      if (columnName.indexOf("[") < 0) {
        transformedColumnName = columnName;
      } else {
        transformedColumnName = columnName.slice(0, columnName.indexOf("["));
      }

      return transformedColumnName;
    })
  );

  return columnNames;
}

export function generateColumnsRequestObject(columns: string[]) {
  const columnNames = extractColumnsToFetch(columns);

  const urlParams: RequestNode = { NODE_NAME: null };

  let movingRefTarget: RequestNode = urlParams;

  columnNames.forEach((column) => {
    movingRefTarget = urlParams;

    column.forEach((field, i) => {
      if (i < column.length - 1) {
        if (!movingRefTarget.hasOwnProperty(NODES)) {
          movingRefTarget[NODES] = [];
        }
        if (movingRefTarget[NODES].findIndex((element: RequestNode) => element.NODE_NAME === field) < 0) {
          movingRefTarget[NODES].push({ NODE_NAME: field });
        }
        movingRefTarget = movingRefTarget[NODES].find((element: RequestNode) => element[NODE_NAME] === field);
      } else if (i === column.length - 1) {
        if (movingRefTarget.hasOwnProperty(LEAVES)) {
          if (movingRefTarget[LEAVES].indexOf(field) < 0) {
            movingRefTarget[LEAVES].push(field);
          }
        } else {
          movingRefTarget[LEAVES] = [field];
        }
      }
    });
  });

  const result: ColumnRequestObject = { fields: null, join: [] };

  convertTreeIntoRequest(urlParams, undefined, result);

  return result;
}

export function generateURLSearchParamsForColumnsToShow(columns: string[]) {
  const urlParams = new URLSearchParams();

  const columnsRequestObject: ColumnRequestObject = generateColumnsRequestObject(columns);

  if (columnsRequestObject.hasOwnProperty("fields") && columnsRequestObject.fields) {
    urlParams.append("fields", columnsRequestObject.fields);
  }

  if (columnsRequestObject.hasOwnProperty("join") && columnsRequestObject.join.length > 0) {
    columnsRequestObject.join.forEach((joinSegment) => {
      urlParams.append("join", joinSegment);
    });
  }

  return urlParams;
}

export function exactractConfiguredFields(
  sourceObject: Record<string, any>,
  fieldConfigurations: FieldConfiguration[],
  action: EntityActionType
) {
  const result = {};

  for (const fieldConfig of fieldConfigurations) {
    let extractedValue: any = undefined;

    if (hasAttributeByPath(sourceObject, fieldConfig.fieldName)) {
      extractedValue = getAttributeByPath(sourceObject, fieldConfig.fieldName);
    } else {
      // TODO: factor in the fact that default value can be a function
      let defaultValue: any = undefined;

      if (fieldConfig.defaultValue !== undefined && typeof fieldConfig.defaultValue === "function") {
        // TODO: factor in Initial Values
        defaultValue = fieldConfig.defaultValue(sourceObject, action, {});
        console.log("exactractConfiguredFields");
      } else if (fieldConfig.defaultValue !== undefined && typeof fieldConfig.defaultValue !== "function") {
        defaultValue = fieldConfig.defaultValue;
      }

      extractedValue = defaultValue;
    }

    setAttributeByPath(result, fieldConfig.fieldName, extractedValue);
  }

  return result;
}

export function convertUIValuesToDB(fieldConfigurations: FieldConfiguration[], uiValues: Partial<any>) {
  // TODO: this is a form function.
  const result = {};

  for (const fieldConfig of fieldConfigurations) {
    const value = getAttributeByPath(uiValues, fieldConfig.fieldName);

    let convertedValue: any;

    if (
      arrayIncludes(["select", "select-async", "number", "date", "date-time"], fieldConfig.type) &&
      value === ""
    ) {
      convertedValue = null;
    } else if (arrayIncludes(["date", "date-time"], fieldConfig.type)) {
      // .getTime() - works with Dates only.
      // valueOf() works with both number and Date and string and is functionally equivalent to getTime()

      convertedValue = value ? value.valueOf() : null;
    } else {
      convertedValue = value;
    }

    setAttributeByPath(result, fieldConfig.fieldName, convertedValue);
  }

  return result;
}

export function getFilterQuery(filters: FilterCriteria[], urlParams: URLSearchParams) {
  const urlParamsClone = new URLSearchParams(urlParams.toString());

  for (const filter of filters) {
    if (filter.hasOwnProperty("attributeValue") && isSpecified(filter.attributeValue)) {
      if (isSpecified(filter.individualKey)) {
        // TODO: support operations other than =
        urlParamsClone.append(filter.attributeName, `${filter.attributeValue}`);
      } else {
        urlParamsClone.append(
          "filter",
          `${filter.attributeName}::${getFilterAction(filter.criteriaType)}::${filter.attributeValue}`
        );
      }
    }
  }

  return urlParamsClone;
}

export function mergeURLSearchParams(...urlSearchParamsGroups: URLSearchParams[]) {
  const result = new URLSearchParams();

  urlSearchParamsGroups.forEach((urlSearchParamsGroup) => {
    if (urlSearchParamsGroup) {
      urlSearchParamsGroup.forEach((value, key) => result.append(key, value));
    }
  });

  return result;
}

export function getFilterAction(val: FilterMatchTypes) {
  let retVal = "";

  switch (val) {
    case FilterMatchTypes.EQUAL:
      retVal = "eq";
      break;
    case FilterMatchTypes.NOT_EQUAL:
      retVal = "ne";
      break;
    case FilterMatchTypes.GREATER_THAN:
      retVal = "gt";
      break;
    case FilterMatchTypes.LOWER_THAN:
      retVal = "lt";
      break;
    case FilterMatchTypes.CONTAINS:
      retVal = "$contL";
      break;
    default:
      throw new Error("Unknown filter type");
  }

  return retVal;
}

export function generateURLSearchParamsForFilter(tableFilter: FilterParameters) {
  let allFilters: FilterCriteria[] = [];
  let baseFilterKeys: Array<string> = [];

  // If there are any base filters
  if (tableFilter.baseFilters) {
    allFilters = tableFilter.baseFilters.slice();
    baseFilterKeys = tableFilter.baseFilters.map((item) => item.attributeName);
  }

  // TODO: need to decide if property filters need to support individualKey as baseFilters
  if (tableFilter.propertyFilters) {
    const keys = Object.keys(tableFilter.propertyFilters);

    keys.forEach((key) => {
      const filterCondition = tableFilter.propertyFilters[key];

      if (isSpecified(filterCondition) && filterCondition.attributeValue !== "") {
        let baseFilterTakesPrecedence: boolean;
        if (baseFilterKeys.includes(key)) {
          const baseFilterItem = tableFilter.baseFilters.find((item) => item.attributeName === key);
          if (isFormSpecified(baseFilterItem.attributeValue)) {
            baseFilterTakesPrecedence = true;
          } else {
            baseFilterTakesPrecedence = false;
          }
        } else {
          baseFilterTakesPrecedence = false;
        }

        if (!baseFilterTakesPrecedence) {
          allFilters.push({
            attributeName: key,
            criteriaType: filterCondition.criteriaType,
            attributeValue: filterCondition.attributeValue,
          });
        }
      }
    });
  }

  const urlParams = getFilterQuery(allFilters, new URLSearchParams());

  return urlParams;
}

export const generateQueryStringByPropertyFilter = (
  dataRequest: UnifiedDataRequestType,
  location: History.Location,
  history: any
) => {
  // TODO: add unit test

  const propertyFilters = dataRequest.filter.propertyFilters;
  let filterKeys: Array<string>;
  let filterArray: Array<string>;

  if (propertyFilters) {
    filterKeys = Object.keys(propertyFilters).filter(
      (item) => isSpecified(propertyFilters[item]) && isFormSpecified(propertyFilters[item].attributeValue)
    );

    filterArray = filterKeys.map((item) => {
      const result = `${item}=${propertyFilters[item].attributeValue}`;

      return result;
    });
  }

  if (isNonEmptyArray(filterArray) || isSpecified(dataRequest.pageNum)) {
    let resultArray: Array<string> = [];

    if (isNonEmptyArray(filterArray)) {
      resultArray = resultArray.concat(filterArray.slice());
    }

    if (isSpecified(dataRequest.pageNum)) {
      resultArray.push(`${RouteSearchKeys.PAGE_NUM}=${dataRequest.pageNum}`);
    }
    if (isSpecified(dataRequest.rowsPerPage)) {
      resultArray.push(`${RouteSearchKeys.ROWS_PER_PAGE}=${dataRequest.rowsPerPage}`);
    }
    if (isSpecified(dataRequest.sortByColumn)) {
      resultArray.push(`${RouteSearchKeys.SORT_BY}=${dataRequest.sortByColumn}`);
    }
    if (isSpecified(dataRequest.sortDirection)) {
      resultArray.push(`${RouteSearchKeys.SORT_DIRECTION}=${dataRequest.sortDirection}`);
    }

    const newPathString = `${location.pathname}?${resultArray.join("&")}`;

    history.replace(newPathString);
  } else {
    history.replace(location.pathname);
  }
};

export function generateURLSearchParamsForPagination(
  isPaginationEnabled: boolean,
  paginationParams?: PaginationState
) {
  const urlParams = new URLSearchParams();

  if (isPaginationEnabled) {
    urlParams.append("per_page", String(paginationParams.rowsPerPage));
    urlParams.append("page", String(paginationParams.pageNum + 1)); // Page Numbering. Material UI - starts with 0, BackEnd - starts with 1
  }

  if (isSpecified(paginationParams.sortByColumn)) {
    urlParams.append(
      "sort",
      `${paginationParams.sortByColumn},${paginationParams.sortDirection.toUpperCase()}`
    );
  }

  return urlParams;
}

export async function getAuthHeaders() {
  const headers: Record<string, any> = await TokenUtils.getAuthHeader();
  const contentType = "Content-Type";

  headers[contentType] = "application/json";

  return headers;
}

export async function defaultHeaders(method?: "GET" | "POST" | "DELETE" | "PATCH"): Promise<RequestInit> {
  const headers = await getAuthHeaders();

  return {
    headers: headers,
    mode: "cors",
    cache: "no-cache",
    method: method || "GET",
  };
}

export function decodeFetchHttpResult(
  fetchHttpResult: any /*TODO typefy*/,
  attemptedOperation: string
): FetchResultStatusType {
  let statusResult: FetchResultStatusType;

  if (fetchHttpResult.ok) {
    statusResult = { type: OPERATION_SUCCESS, message: `Operation ${attemptedOperation} was successful` };
  } else {
    statusResult = { type: OPERATION_ERROR, message: "" };

    if (fetchHttpResult.status) {
      statusResult.code = fetchHttpResult.status;

      switch (fetchHttpResult.status) {
        case 400:
          statusResult.message = `Invalid request format.`;
          break;
        case 401:
          statusResult.message = `Invalid credentials accessing the server.`;
          break;
        case 403:
          statusResult.message = `You don't have permissions to see this resource`;
          break;
        default:
          statusResult.message = `Error loading entities.`;
          break;
      }
    } else {
      statusResult.message = `Could not connect to the server. Please try again later.`;
    }
  }

  return statusResult;
}

export async function generateEntityPathString(dataOwner: DataOwnerParameters[], callback: Function) {
  // TODO: probably this function should be a separate component rather than a function
  // Asynchronously get list
  let result = "";

  if (dataOwner) {
    const titleRequests = dataOwner.map(async (dataOwnerConfig) => {
      const urlObject = new URL(`${preUrl}${dataOwnerConfig.endpointUrl}`);

      // TODO: make the name of the function more generic
      urlObject.search = generateURLSearchParamsForColumnsToShow(dataOwnerConfig.fields).toString();

      const url = urlObject.toString();

      // TODO: improve error handling and pass error to callback
      const httpResponseData = await fetchDataByFullURL(url, "retrieve object name");

      const titlePrefix = populateStringTemplate(
        `${dataOwnerConfig.titlePrefixTemplate}`,
        httpResponseData.data
      );

      if (httpResponseData.status.type !== OPERATION_SUCCESS) {
        console.error(`generateEntityPathString Error ${httpResponseData.status.message}`);
      }

      return titlePrefix;
    });

    result = await Promise.all(titleRequests).then((titlePrefixValuesArray: Array<any>) => {
      return titlePrefixValuesArray.join("");
    });
  }

  callback(result);
}

// WILD HACKO
class HackoFetch {
  // _getTokenSilentlyRef: GetTokeSilentlyType;
  _auth0Client: any;

  // constructor() {
  //   this.setGetTokenSilentlyRef = this.setGetTokenSilentlyRef.bind(this);
  //   this.getTokenSilently = this.getTokenSilently.bind(this);
  // }

  setAuth0Client(auth0Client: any) {
    this._auth0Client = auth0Client;
  }

  async getTokenSilently() {
    const result = await this._auth0Client.getTokenSilently();

    return result;
  }
}
export const globalFetcher = new HackoFetch();
// WILD HACKO

export async function getDropdownOptionsFromDB(apiUrl: string): Promise<FetchResultType> {
  const url = `${preUrl}${apiUrl}`;

  const result = await fetchDataByFullURL(url, "retrieve dropdown options");

  return result;
}

export async function fetchEntityById(
  id: ObjectIdType,
  apiUrl: string,
  customUrlRequestFragment?: string
): Promise<FetchResultType> {
  // TODO: probably refactor to explicitly specify which fields are to be fetched, without assuming that it comes in the apiUrl

  let url = `${preUrl}${apiUrl}/${id}`;

  if (isSpecified(customUrlRequestFragment)) {
    url = `${url}?${customUrlRequestFragment}`;
  }

  const result = await fetchDataByFullURL(url, "retrieve data");

  return result;
}

function queryStringByRequestObjectAndCustomUrl(
  requestObject: UnifiedDataRequestType,
  customUrlRequestFragment?: string
) {
  const searchParams: URLSearchParams = requestObject ? generateUrlByRequestObject(requestObject) : undefined;

  const urlRequestFragment = [];
  if (customUrlRequestFragment && customUrlRequestFragment.length > 0) {
    urlRequestFragment.push(customUrlRequestFragment);
  }
  if (searchParams && searchParams.toString().length > 0) {
    urlRequestFragment.push(searchParams.toString());
  }

  return urlRequestFragment.join("&");
}

export async function fetchEntityListByQuery(
  requestObject: UnifiedDataRequestType,
  apiUrl: string,
  customUrlRequestFragment?: string
): Promise<FetchResultType> {
  // let searchParams: URLSearchParams = generateUrlByRequestObject(requestObject);

  // let urlRequestFragment = [];
  // if (customUrlRequestFragment && customUrlRequestFragment.length > 0) {
  //   urlRequestFragment.push(customUrlRequestFragment);
  // }
  // if (searchParams && searchParams.toString().length > 0) {
  //   urlRequestFragment.push(searchParams.toString());
  // }

  // const url = `${preUrl}${apiUrl}?${urlRequestFragment.join('&')}`;
  const constUrlRequestString = queryStringByRequestObjectAndCustomUrl(requestObject, customUrlRequestFragment);

  const url = `${preUrl}${apiUrl}?${constUrlRequestString}`;

  const fetchResult = await fetchDataByFullURL(url, "retrieve data");

  const result: FetchResultType = { status: fetchResult.status };

  if (fetchResult.status.type === OPERATION_SUCCESS) {
    if (fetchResult.data.data) {
      result.data = fetchResult.data.data;
      result.totalRows = fetchResult.data.total;
    } else {
      result.data = fetchResult.data;
      result.totalRows = fetchResult.data.length;
    }
  }

  return result;
}

export async function createOrEditEntity(
  action: EntityActionType,
  id: any,
  body: any,
  apiUrl: string,
  customUrlRequestFragment?: string,
  requestObject?: UnifiedDataRequestType
): Promise<FetchResultType> {
  let endpointUrl: string;
  let requestMethod: "PATCH" | "POST";

  if (action === EDIT_ACTION) {
    endpointUrl = `${preUrl}${apiUrl}/${id}`;
    requestMethod = "PATCH";
  } else if (action === CREATE_ACTION) {
    endpointUrl = `${preUrl}${apiUrl}`;
    requestMethod = "POST";
  }

  const queryString = queryStringByRequestObjectAndCustomUrl(requestObject, customUrlRequestFragment);
  // if (isSpecified(customUrlRequestFragment)) {
  //   endpointUrl = `${endpointUrl}?${customUrlRequestFragment}`;
  // }
  let urlRequestString = `${endpointUrl}`;
  if (isSpecified(queryString) && queryString !== "") {
    urlRequestString = `${urlRequestString}?${queryString}`;
  }

  const result = await fetchDataByFullURL(urlRequestString, "save data", requestMethod, JSON.stringify(body));

  return result;
}

export async function fetchDatasetForDataProvider(apiUrl: string): Promise<FetchResultType> {
  // TODO: MOVE URL generation to query engine

  const url = `${preUrl}${apiUrl}`;

  const result = await fetchDataByFullURL(url, "get dataset for a provided");

  return result;
}

const _parseJSON = (response: any) => {
  return response.text().then(function (text: any) {
    return text ? JSON.parse(text) : {};
  });
};

export const tempFunc = async () => {
  const result = await exportFunctions.fetchDataByFullURL("a", CREATE_ACTION, "POST", {});

  return result;
};

export const fetchDataByFullURL = async (
  apiUrl: string,
  operationName: string,
  requestMethod?: "PATCH" | "POST" | "GET",
  body?: any,
  useCache?: boolean
): Promise<FetchResultType> => {
  let result: FetchResultType = undefined;

  const localRequestMethod = requestMethod || "GET";

  if (useCache === true && localRequestMethod === "GET") {
    const cacheData = GlobalFetchCache.retrieve(apiUrl);
    if (cacheData !== undefined) {
      result = {
        status: {
          type: OPERATION_SUCCESS,
          message: "RETRIEVED FROM CACHE",
        },
        data: cacheData.data,
        totalRows: cacheData.totalRows,
      };
    }
  }

  if (result === undefined) {
    const headers = await defaultHeaders(localRequestMethod);

    const httpResponse = await fetch(apiUrl, { ...headers, body: body }).catch((error) => error);

    result = { status: decodeFetchHttpResult(httpResponse, operationName) };

    if (httpResponse.ok) {
      result.data = await _parseJSON(httpResponse); // httpResponse.json();

      if (Array.isArray(result.data)) {
        result.totalRows = result.data.length;
      }
    }

    if (useCache === true && localRequestMethod === "GET" && result.status.type === OPERATION_SUCCESS) {
      GlobalFetchCache.add(apiUrl, { data: result.data, totalRows: result.totalRows });
    }
  }

  return result;
};

export async function deleteEntityById(id: ObjectIdType, apiUrl: string): Promise<FetchResultType> {
  const url = `${preUrl}${apiUrl}/${id}`;

  const headers = await defaultHeaders("DELETE");

  const httpResponse = await fetch(url, headers).catch((error) => error);

  const result: FetchResultType = { status: decodeFetchHttpResult(httpResponse, "delete record") };

  return result;
}

export const mergeFirstLevelPropertyIntoRequest = (
  queryParamsValues: UnifiedDataRequestType,
  propertyName: string,
  propertyValue: any
) => {
  const result = produce(queryParamsValues, (draftQueryParamsValues: Record<string, any>) => {
    draftQueryParamsValues[propertyName] = propertyValue;
  });

  return result;
};

// TODO: find where it is used and swap with mergeRecords
export const mergeFirstLevelPropertiesIntoRequest = (
  queryParamsValues: UnifiedDataRequestType,
  propertiesObject: Record<string, any>
) => {
  const result = produce(queryParamsValues, (draftQueryParamsValues) => {
    Object.assign(draftQueryParamsValues, propertiesObject);
  });

  return result;
};

// TODO: find where it is used and swap with mergeRecords
export const mergePropertyFiltersIntoRequest = (
  queryParamsValues: UnifiedDataRequestType,
  propertyFilters: PropertyFilterCriteriaType
) => {
  // JSON.stringify behaves weird

  const updatedQueryParamsValues = produce(queryParamsValues, (queryParamsDraft) => {
    if (isSpecified(queryParamsDraft.filter.propertyFilters)) {
      Object.assign(queryParamsDraft.filter.propertyFilters, propertyFilters);
    } else {
      queryParamsDraft.filter.propertyFilters = propertyFilters;
    }
  });

  return updatedQueryParamsValues;
};

const exportFunctions = {
  fetchDataByFullURL,
  tempFunc,
};

export default exportFunctions;
