import React, { useState } from "react";
import { Tabs, Tab } from "@material-ui/core";
import TabPanel from "./TabPanel";
import { isNonEmptyArray } from "components/Utils/MiscUtils";

export interface TabbedViewProps {
  tabLabels: Array<string>;
  hideTabs?: Array<number>;
  disableTabs?: Array<number>;
  render: Array<
    (
      // activeTabId: number,
      switchToTab: (tabId: number) => void
    ) => JSX.Element
  >;
  initialTabId?: number;
}

export function TabbedView(props: TabbedViewProps) {
  const [activeTabId, setActiveTabId] = useState(props.initialTabId || 0);

  const handleChangeTabId = (event: React.ChangeEvent, newTabId: number) => {
    setActiveTabId(newTabId);
  };

  const labelsToRender = isNonEmptyArray(props.hideTabs)
    ? props.tabLabels.filter((item, index) => !props.hideTabs.includes(index))
    : props.tabLabels;
  const tabsToRender = isNonEmptyArray(props.hideTabs)
    ? props.render.filter((item, index) => !props.hideTabs.includes(index))
    : props.render;

  return (
    <div className="TabbedView" style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <Tabs
        value={activeTabId}
        onChange={handleChangeTabId}
        indicatorColor="primary"
        textColor="inherit"
        style={{ borderBottom: "1px solid #E0E0E0" }}
      >
        {labelsToRender.map((tabLabelItem: string, index: number) => {
          const disableTab = isNonEmptyArray(props.disableTabs) && props.disableTabs.includes(index);

          return <Tab label={tabLabelItem} key={index} disabled={disableTab} />;
        })}
      </Tabs>
      {tabsToRender.map((renderItem, index) => {
        return (
          <TabPanel activeTabId={activeTabId} index={index} key={index}>
            {renderItem(/* activeTabId,  */ setActiveTabId)}
          </TabPanel>
        );
      })}
    </div>
  );
}
