import { FieldConfiguration } from "components/Crud/Form/Fields/FieldConfiguration.d";
import { Theme } from "@material-ui/core";
import { ObjectIdType } from "components/Utils/Object.d";
import { getAttributeByPath } from "components/Crud/EntityUtils";
import { valueTypeDictionary, conditionTypeDictionary } from "./TriggerDictionaries";
import { API_LIB } from "components/Utils/API_LIB";
import { ValueType } from "./Trigger.d";

// const sampleFieldFetchResultTransformer = (input: Record<string, any> | Array<Record<string, any>>): Array<Record<string, any>> => {
//   // let result = getAttributeByPath(input, '[0].sampleFields');
//   let result: Array<Record<string, any>> = [];

//   if (isNonEmptyArray(input)) {
//     result = glueArrays(input.map((item: Record<string, any>) => item.sampleFields));
//   }

//   return result;
// };

const hideIfValueTypeIsRate = (
  value: string,
  obj: Record<string, any>,
  editType: string,
  initialValues: Record<string, any>
) => {
  const valueTypeId = getAttributeByPath(obj, "valueType");

  const result = valueTypeId === ValueType.VALUE;

  return result;
};

const requiredIfValueTypeIsRate = (value: string | number, obj: Record<string, any>) => {
  const valueTypeId = getAttributeByPath(obj, "valueType");

  const result = valueTypeId === ValueType.RATE;

  return result;
};

export const triggerConditionEditFormFieldSpec = (
  id: ObjectIdType,
  theme: Theme
): Array<FieldConfiguration> => [
  {
    fieldName: "triggerTemplateId",
    label: "Trigger Template Id",
    type: "number",
    defaultValue: id,
    hideIf: () => true,
    validation: { required: true },
  },
  {
    fieldName: "sampleFieldId",
    label: "Sample Field Id",
    type: "select-async",
    validation: { required: true },
    fieldParams: {
      optionCodeField: "id",
      optionLabelField: "name",
      optionDefinitionEndpoint: API_LIB.SAMPLE_FIELD,
      // filterUrl: `?join=sampleFields`, // &filter=id::eq::${sampleTypeId}`,
      // filterUrl: sampleFieldFilterUrlFunction,
      // fetchResultTransformer: sampleFieldFetchResultTransformer,
      // disableFetching: disableFetchingSampleFieldFunction
    },
  },
  {
    fieldName: "valueType",
    label: "Value Type",
    type: "select",
    validation: { required: true },
    fieldParams: {
      options: valueTypeDictionary,
    },
  },
  {
    fieldName: "params[thresholdSensitivity]",
    label: "Threshold Sensitivity",
    type: "number",
    validation: { required: false },
  },
  {
    fieldName: "params[window]",
    label: "Window (seconds)",
    type: "number",
    validation: {
      required: requiredIfValueTypeIsRate,
    },
  },
  {
    fieldName: "params[duration]",
    label: "Duration (seconds)",
    type: "number",
    validation: { required: false },
    hideIf: hideIfValueTypeIsRate,
  },
  {
    fieldName: "conditionType",
    label: "Condition Type",
    type: "select",
    validation: { required: true },
    fieldParams: {
      options: conditionTypeDictionary,
    },
  },

  {
    fieldName: "value",
    label: "Value",
    type: "number",
    validation: { required: false },
  },
  {
    fieldName: "useForecast",
    label: "Use Forecast",
    type: "boolean",
    defaultValue: false,
    fieldParams: {
      color: theme.palette.primary.main,
    },
    validation: { required: true },
  },
];
