import React from "react";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { isSpecified } from "components/Utils/MiscUtils";

export interface ColumnWrapperProps {
  children: string | JSX.Element | Array<JSX.Element>;
  flexGrow?: number;
  width?: string;
  height?: string;
  alignSelf?: string;
  id?: string;
}

export function ColumnWrapper(props: ColumnWrapperProps) {
  const styleObject = { boxSizing: "border-box" } as CSSProperties;

  if (isSpecified(props.flexGrow)) {
    styleObject.flexGrow = props.flexGrow;
  }

  if (isSpecified(props.width)) {
    styleObject.width = props.width;
  }

  if (isSpecified(props.height)) {
    styleObject.height = props.height;
  }

  if (isSpecified(props.alignSelf)) {
    styleObject.alignSelf = props.alignSelf;
  }

  return (
    <div id={props.id} style={styleObject}>
      {props.children}
    </div>
  );
}
