import * as React from "react";
import { USER_API_URL } from "./User.const";
import { userTitleDictionary } from "./User.dict";
import { getAttributeByPath } from "components/Crud/EntityUtils";
import { CREATE_ACTION } from "components/Crud/Entity.const";
import { FieldConfiguration } from "components/Crud/Form/Fields/FieldConfiguration.d";
import { DataCrudEntityFormBinding } from "components/Crud/Form/DataCrudEntityFormBinding";
import { roleOptions } from "./Membership/UserMembership.dict";
import { ENTERPRISE_API_URL, ALL_ENTERPRISES_CODE } from "views/Enterprise/Enterprise.const";
import { useTheme } from "@material-ui/core";
import produce from "immer";
import { isNonEmptyArray, isSpecified, isFormSpecified } from "components/Utils/MiscUtils";
import { formFieldIsEmpty } from "components/Crud/Form/EntityFormCommon";
import { useActiveEnterprise } from "components/Utils/CustomHooks";
import { UserEditFormLayout } from "./UserEditFormLayout";
import { isValidPhoneNumber, isValidEmail } from "components/Utils/StringUtils";
import { DataSourceParamsType } from "components/Crud/DataProvider/DataProviderCommon.d";
import { EntityFormParams } from "components/Crud/Form/EntityForm.d";

export const PASSWORD_KEY = "password";
export const CONFIRM_PASSWORD_KEY = "confirmPassword";
export const IS_SUPER_ADMIN_KEY = "isSuperAdmin";
export const FIRST_MEMBERSHIP_ROLE = `memberships[0].role`;
export const FIRST_MEMBERSHIP_ID = `memberships[0].id`;
export const FIRST_MEMBERSHIP_ENTERPRISE = "memberships[0].enterpriseId";
export const FIELD_DO_VERIFY_EMAIL = "doVerifyEmail";

export const REREQUEST_EMAIL_VERIFICATION = "rerequestEmailVerification";
export const REQUEST_RESET_PASSWORD = "requestResetPassword";

const fieldIsDisabledForSuperAdminCallBack = (fieldValue: string | number, obj: Record<string, any>) => {
  const isSuperAdmin = getAttributeByPath(obj, IS_SUPER_ADMIN_KEY) === true;
  const fieldIsEmpty = formFieldIsEmpty(fieldValue);

  const result = isSuperAdmin && fieldIsEmpty;

  return result;
};

const fieldIsHiddenForSuperAdminCallBack = (value: string, obj: Record<string, any>) => {
  const isSuperAdmin = getAttributeByPath(obj, IS_SUPER_ADMIN_KEY) === true;
  const fieldIsEmpty = formFieldIsEmpty(value);

  const result = isSuperAdmin && fieldIsEmpty;

  return result;
};

const hideIfNumberIsNotSpecified = (
  value: string,
  obj: Record<string, any>,
  editType: string,
  initialValues: Record<string, any>
) => {
  const result = !isFormSpecified(getAttributeByPath(obj, "phoneNumber"));

  return result;
};

// class UserEditForm extends React.Component<EntityFormParams, {}> {
export function UserEditForm(props: EntityFormParams) {
  const theme = useTheme();
  const hidePasswordField = (value: string, obj: any, editType: string): boolean => {
    return editType !== CREATE_ACTION;
  };

  const currentEnterprise = useActiveEnterprise();

  const getFieldConfigs = (): FieldConfiguration[] => {
    let fieldConfigs: FieldConfiguration[] = [
      {
        fieldName: "title",
        label: "Title",
        type: "select",
        defaultValue: userTitleDictionary[0].code,
        fieldParams: {
          options: userTitleDictionary,
        },
      },
      {
        fieldName: "firstName",
        label: "First Name",
        type: "text",
        validation: { required: true },
      },
      {
        fieldName: "lastName",
        label: "Last Name",
        type: "text",
        validation: { required: true },
      },
      {
        fieldName: "email",
        label: "Email",
        type: "email",
        validation: {
          required: true,
          validationFunction: (value: string) => isValidEmail(value),
          errorMessage: "Invalid email address",
        },
      },
      {
        fieldName: "enableEmailNotifications",
        label: "Notifications",
        type: "boolean",
        defaultValue: true,
        fieldParams: {
          color: theme.palette.primary.main,
        },
      },
      {
        fieldName: "phoneNumber",
        label: "Phone Number",
        type: "text",
        validation: {
          required: false,
          validationFunction: (value: string) => isValidPhoneNumber(value),
          errorMessage: "Invalid phone number",
        },
      },
      {
        fieldName: "enableSmsNotifications",
        label: "Notifications",
        type: "boolean",
        defaultValue: true,
        hideIf: hideIfNumberIsNotSpecified,
        fieldParams: {
          color: theme.palette.primary.main,
        },
      },
      {
        fieldName: "username",
        label: "Username",
        type: "text",
        validation: {
          required: true,
          // regExp: /^[A-Za-z0-9]+(?:[ _.-][A-Za-z0-9]+)*$/,
          regExp: /^[a-zA-Z0-9]((?!(\.|))|\.(?!(_|\.))|[a-zA-Z0-9]){0,13}[a-zA-Z0-9]$/,
          errorMessage: "Invalid username",
        },
      },
      {
        fieldName: PASSWORD_KEY,
        label: "Password",
        type: "password",
        defaultValue: "",
        hideIf: hidePasswordField,
        validation: {
          minLength: 6,
          regExp: /^[A-Za-z0-9$@$!%*?/#&()-]{6,99}$/,
          required: true,
        },
      },
      {
        fieldName: CONFIRM_PASSWORD_KEY,
        label: "Confirm Password",
        defaultValue: "",
        type: "password",
        hideIf: hidePasswordField,
        validation: {
          required: true,
          minLength: 6,
          validationFunction: (value: string, obj: Record<string, any>) => {
            return obj[PASSWORD_KEY] === obj[CONFIRM_PASSWORD_KEY];
          },
          errorMessage: "Passwords do not match",
        },
      },
      {
        fieldName: FIELD_DO_VERIFY_EMAIL,
        label: "Send Verification Email",
        defaultValue: true,
        type: "boolean",
        hideIf: (
          value: string,
          obj: Record<string, any>,
          editType: string,
          initialValues: Record<string, any>
        ): boolean => {
          let result: boolean;

          if (editType === CREATE_ACTION) {
            result = false;
          } else {
            if (!isSpecified(obj.email) || !isSpecified(initialValues)) {
              result = true;
            } else {
              result = initialValues.email === obj.email;
            }
          }

          return result;
        },
        fieldParams: { color: theme.palette.primary.main },
      },
      {
        fieldName: "isEmailVerified",
        label: "Is Email Verified",
        disabled: true,
        type: "boolean",
        fieldParams: { color: theme.palette.secondary.main },
        defaultValue: false, // TODO: add default value for the boolean field
      },
      {
        fieldName: IS_SUPER_ADMIN_KEY,
        label: "Superadmin",
        type: "boolean",
        fieldParams: { color: theme.palette.primary.main },
        hideIf: () => !props.userData.isSuperAdmin,
        defaultValue: false,
      },
    ];

    if (props.action === CREATE_ACTION /*  || props.action === EDIT_ACTION */) {
      const roleIsDisabledCallback = (fieldValue: string | number, obj: Record<string, any>) => {
        return fieldIsDisabledForSuperAdminCallBack(fieldValue, obj);
      };

      const enterpriseIsDisabledCallBack = (fieldValue: string | number, obj: Record<string, any>) => {
        let result: boolean;

        if (props.currentEnterpriseId !== ALL_ENTERPRISES_CODE) {
          result = true;
        } else {
          result = fieldIsDisabledForSuperAdminCallBack(fieldValue, obj);
        }

        return result;
      };

      const roleIsHiddenCallBack = (value: string, obj: Record<string, any>) => {
        return fieldIsHiddenForSuperAdminCallBack(value, obj);
      };

      const enterpriseIsHiddenCallBack = (value: string, obj: Record<string, any>) => {
        let result: boolean;

        if (props.currentEnterpriseId !== ALL_ENTERPRISES_CODE) {
          result = true;
        } else {
          result = fieldIsHiddenForSuperAdminCallBack(value, obj);
        }

        return result;
      };

      const fieldMustBeEmptyForSuperAdminCallBack = (value: string | number, obj: any) => {
        // required checks that field is populate if required
        // This check, checks that field is NOT populated if isSuperAdmin
        const isSuperAdmin = getAttributeByPath(obj, IS_SUPER_ADMIN_KEY) === true;
        const fieldIsNotEmpty = !formFieldIsEmpty(value);

        let result: boolean;
        if (isSuperAdmin && fieldIsNotEmpty) {
          result = false;
        } else {
          result = true;
        }

        return result;
      };

      const fieldIsRequiredForNotSuperAdminCallBack = (value: string | number, obj: Record<string, any>) => {
        const result = !(obj[IS_SUPER_ADMIN_KEY] === true);

        return result;
      };

      fieldConfigs = fieldConfigs.concat([
        {
          fieldName: FIRST_MEMBERSHIP_ID,
          label: "membershipId",
          type: "number",
          hideIf: () => true, // roleIsHiddenCallBack,
          disabled: true,
        },
        {
          fieldName: FIRST_MEMBERSHIP_ROLE,
          label: "Role",
          type: "select",
          hideIf: roleIsHiddenCallBack,
          disabled: roleIsDisabledCallback,
          validation: {
            required: fieldIsRequiredForNotSuperAdminCallBack,
            validationFunction: fieldMustBeEmptyForSuperAdminCallBack,
            errorMessage: "Role must be empty if user is a super admin",
          },
          fieldParams: { options: roleOptions },
        },
        {
          fieldName: FIRST_MEMBERSHIP_ENTERPRISE,
          label: "Enterprise",
          type: props.currentEnterpriseId !== ALL_ENTERPRISES_CODE ? "select" : "select-async",
          hideIf: enterpriseIsHiddenCallBack,
          disabled: enterpriseIsDisabledCallBack,
          defaultValue:
            props.currentEnterpriseId !== ALL_ENTERPRISES_CODE ? props.currentEnterpriseId : undefined,
          validation: {
            required: fieldIsRequiredForNotSuperAdminCallBack,
            validationFunction: fieldMustBeEmptyForSuperAdminCallBack,
            errorMessage: "Enterprise must be empty if user is a super admin",
          },
          fieldParams:
            props.currentEnterpriseId !== ALL_ENTERPRISES_CODE
              ? { options: [{ code: currentEnterprise.id, label: currentEnterprise.name }] }
              : {
                  optionDefinitionEndpoint: ENTERPRISE_API_URL,
                  optionCodeField: "id",
                  optionLabelField: "name",
                  autoComplete: true,
                },
        },
      ]);
    }

    return fieldConfigs;
  };

  const dataSource: DataSourceParamsType = {
    apiUrl: USER_API_URL,
  };

  if (props.currentEnterpriseId !== ALL_ENTERPRISES_CODE) {
    dataSource.customUrlRequestFragment = `join=memberships&filter=memberships.enterpriseId::eq::${props.currentEnterpriseId}`;
  }

  const preSubmitProcessor = (object: Record<string, any>) => {
    let result: Record<string, any>;

    result = produce(object, (draftObject) => {
      if (draftObject.hasOwnProperty("memberships")) {
        if (isNonEmptyArray(draftObject.memberships)) {
          draftObject.memberships = draftObject.memberships.filter((item: any) => {
            const membershipHasParameters =
              (isSpecified(item.role) && item.role !== "") ||
              (isSpecified(item.enterpriseId) && item.enterpriseId);

            return membershipHasParameters;
          });
        }

        if (!isNonEmptyArray(draftObject.memberships)) {
          delete draftObject.memberships;
        }
      }

      if (draftObject.hasOwnProperty("isEmailVerified")) {
        delete draftObject.isEmailVerified;
      }
    });

    return result;
  };

  return (
    <DataCrudEntityFormBinding
      id={props.id} // TODO: do we need this???
      dataSource={dataSource}
      action={props.action}
      title={props.title}
      fieldConfigurations={getFieldConfigs()}
      onCloseAdditionalActions={props.onCloseAdditionalActions}
      showCloseButton={props.showCloseButton}
      userData={props.userData}
      currentEnterpriseId={props.currentEnterpriseId}
      formDataPreSubmitProcessor={preSubmitProcessor}
      layoutComponent={UserEditFormLayout}
    />
  );
}
