import React from "react";
import PriorityHighIcon from "@material-ui/icons/PriorityHigh";
import { useTheme } from "@material-ui/core";

export function TrendArrowUnknown(props: any) {
  const theme = useTheme();
  const color = theme.palette.text.secondary;

  return <PriorityHighIcon htmlColor={color} {...props} />;
}
