import React from "react";
import People from "@material-ui/icons/People";
import Public from "@material-ui/icons/Public";
import Business from "@material-ui/icons/Business";
import Home from "@material-ui/icons/Home";
import SettingsInputComposite from "@material-ui/icons/SettingsInputComposite";
import { ENTERPRISE_ROUTE } from "views/Enterprise/Enterprise.const";
import {
  MONITOR_API_URL as MONITOR_ROUTE,
  MONITOR_CONFIGURATION_ROUTE,
  MONITOR_SUMMARY_ROUTE,
} from "views/hardware/Monitor/Monitor.const";
import {
  SENSOR_ROUTE,
  SENSOR_BY_MONITOR_CONFIGURATION_ROUTE,
  SENSOR_BY_MONITOR_ROUTE,
  SENSOR_GRAPH_ROUTE,
} from "views/hardware/Sensor/Sensor.const";
import { USER_API_URL as USER_ROUTE } from "views/User/User.const";
import { USER_MEMBERSHIP_ROUTE } from "views/User/Membership/UserMembership.const";
import EnterpriseSelection from "components/EnterpriseSelection";
import { SamplePointList } from "views/asset/SamplePoint/SamplePointList";
import { SAMPLEPOINT_ROUTE } from "views/asset/SamplePoint/SamplePoint.const";
import { ASSET_DETAILS_ROUTE } from "views/asset/Asset/Asset.const";
import { PACKET_LOG_ROUTE } from "views/hardware/Monitor/PacketLog/PacketLog.const";
import {
  getEntityListRoute,
  getEntityEditRoute,
  getAggregatedPageRoute,
} from "../components/Utils/RouteUtils";
import { MonitorEditScreen } from "views/hardware/Monitor/MonitorEditScreen";
import { EnterpriseEditScreen } from "views/Enterprise/EnterpriseEditScreen";
import { UserEditScreen } from "views/User/UserEditScreen";
import { SensorEditScreen } from "views/hardware/Sensor/SensorEditScreen";
import { PacketLogEditScreen } from "views/hardware/Monitor/PacketLog/PacketLogEditScreen";
import { SamplePointEditScreen } from "views/asset/SamplePoint/SamplePointEditScreen";
import { UserMembershipEditScreen } from "views/User/Membership/UserMembershipEditScreen";
import SensorGraphPage from "views/hardware/Sensor/SensorGraphPage";
import SamplePointGraphPage from "views/asset/SamplePoint/SamplePointGraphPage";

import SearchScreen, { SEARCH_SCREEN_ROUTE } from "views/Search/SearchScreen";
import { UserRole } from "components/Crud/EntityGuard.d";
import { SITE_ROUTE } from "views/Site/Site.const";
import { ROUTE_LIB } from "components/Utils/RouteList";
import { SAMPLEPOINT_SENSOR_PAIRING_ROUTE } from "views/asset/SamplePoint/SamplePointSensorPairing/SamplePointSensorPairing.const";
import AssetGroupConfigurationScreen from "views/AssetGroup/AssetGroupConfigurationScreen";
import { OverviewSummaryScreen } from "views/Overview/OverviewSummaryScreen";
import { AlertsOverviewScreen } from "views/Alert/AlertsOverviewScreen";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { AssetSummaryScreen } from "views/asset/Asset/AssetSummaryScreen";
import { SamplePointSensorPairingScreen } from "views/asset/SamplePoint/SamplePointSensorPairing/SamplePointSensorPairingScreen";

import { ReactComponent as AssetGroupIcon } from "assets/icons/ProfessionalSet/AssestGroup.svg";
import { ReactComponent as AssetIcon } from "assets/icons/ProfessionalSet/Asset.svg";
import { ReactComponent as HomeIcon } from "assets/icons/ProfessionalSet/Home.svg";
import { TriggerTemplateList } from "layouts/AlertTriggers/TriggerTemplateList";
import TitleIcon from "@material-ui/icons/Title";
import { MonitorSummaryScreen } from "views/hardware/Monitor/MonitorSummaryScreen";
import { MonitorConfigurationListScreen } from "views/hardware/Monitor/Configurations/MonitorConfigurationListScreen";
import { PacketLogListScreen } from "views/hardware/Monitor/PacketLog/PacketLogListScreen";
import { MonitorInventoryScreen } from "views/hardware/Monitor/MonitorInventoryScreen";
import { AssetInventoryScreen } from "views/asset/Asset/AssetInventoryScreen";
import { UserInventoryScreen } from "views/User/UserInventoryScreen";
import { EnterpriseInventoryScreen } from "views/Enterprise/EnterpriseInventoryScreen";
import { SiteInventoryScreen } from "views/Site/SiteInventoryScreen";
import { UserMembershipScreen } from "views/User/Membership/UserMembershipScreen";
import { SensorInventoryScreen } from "views/hardware/Sensor/SensorInventoryScreen";
import { AssetGroupInventoryScreen } from "views/AssetGroup/AssetGroupInventory";
import { DashboardRoute, SideBarCategory } from "components/Utils/RouteUtils.d";

export const LOGIN_ROUTE = "/login";
export const FORGOT_PASSWORD_ROUTE = "/forgotPassword";

const dashboardRoutes: DashboardRoute[] = [
  {
    path: getAggregatedPageRoute(ROUTE_LIB.OVERVIEW_SUMMARY),
    sidebarName: "Your Summary",
    icon: <HomeIcon />,
    component: OverviewSummaryScreen,
    show: false, // [UserRole.ALL_USERS]
    category: SideBarCategory.OVERVIEW,
    availableForNoEnterprise: true,
  },
  {
    path: getEntityListRoute(ROUTE_LIB.TRIGGER_TEMPLATE),
    sidebarName: "Trigger Templates",
    icon: <TitleIcon fontSize="small" />,
    component: TriggerTemplateList,
    show: false, // [UserRole.SUPER_ADMIN],
    category: SideBarCategory.ADMIN_VIEWS,
  },
  {
    path: getAggregatedPageRoute(ROUTE_LIB.OVERVIEW_ALL_ALERTS),
    sidebarName: "All Alerts",
    icon: <NotificationsIcon fontSize="small" />,
    component: AlertsOverviewScreen,
    show: [UserRole.ALL_USERS],
    category: SideBarCategory.OVERVIEW,
  },
  {
    path: getEntityListRoute(ROUTE_LIB.ASSET_INVENTORY),
    sidebarName: "All Assets",
    icon: <AssetIcon />,
    component: AssetInventoryScreen,
    show: [UserRole.ALL_USERS],
    category: SideBarCategory.OVERVIEW,
  },
  {
    path: getEntityEditRoute(ROUTE_LIB.DYNAMIC_GROUP_CONFIGURATION),
    component: AssetGroupConfigurationScreen,
    componentProps: ["groupId"],
    show: false,
  },
  {
    path: getAggregatedPageRoute(ROUTE_LIB.ASSET_GROUP_ASSET),
    component: AssetInventoryScreen,
    show: false,
    componentProps: ["groupId"],
  },
  {
    path: getAggregatedPageRoute(ROUTE_LIB.ASSET_SUMMARY),
    component: AssetSummaryScreen,
    show: false,
    componentProps: ["entityId"],
  },
  {
    path: getAggregatedPageRoute(ROUTE_LIB.ASSET_GROUP),
    sidebarName: "Asset Groups",
    icon: <AssetGroupIcon />,
    component: AssetGroupInventoryScreen,
    show: [UserRole.SUPER_ADMIN, UserRole.ADMIN],
    category: SideBarCategory.SYSTEM,
  },
  {
    path: getEntityListRoute(MONITOR_ROUTE),
    sidebarName: "Monitors",
    icon: <Public fontSize="small" />,
    component: MonitorInventoryScreen,
    show: [UserRole.SUPER_ADMIN, UserRole.ADMIN],
    category: SideBarCategory.SYSTEM,
  },
  {
    path: getEntityListRoute(SENSOR_ROUTE),
    sidebarName: "Sensors",
    icon: <SettingsInputComposite fontSize="small" />,
    component: SensorInventoryScreen,
    show: [UserRole.SUPER_ADMIN, UserRole.ADMIN],
    category: SideBarCategory.SYSTEM,
  },
  {
    path: getEntityListRoute(USER_ROUTE),
    sidebarName: "Users",
    icon: <People fontSize="small" />,
    component: UserInventoryScreen,
    show: [UserRole.SUPER_ADMIN, UserRole.ADMIN],
    category: SideBarCategory.ADMIN_VIEWS,
  },
  {
    path: getEntityListRoute(SITE_ROUTE),
    sidebarName: "Sites",
    icon: <Home fontSize="small" />,
    component: SiteInventoryScreen,
    show: [UserRole.ADMIN, UserRole.SUPER_ADMIN],
    category: SideBarCategory.ADMIN_VIEWS,
  },
  {
    path: getEntityListRoute(ENTERPRISE_ROUTE),
    sidebarName: "Enterprises",
    icon: <Business fontSize="small" />,
    component: EnterpriseInventoryScreen,
    show: [UserRole.SUPER_ADMIN],
    category: SideBarCategory.ADMIN_VIEWS,
    disableIfEnterpriseIsSelected: true,
  },
  {
    // TODO: there's a duplicate route with similar path. Do we need it?
    path: getEntityListRoute(MONITOR_CONFIGURATION_ROUTE),
    component: MonitorConfigurationListScreen,
    componentProps: ["monitorId"],
    show: false,
  },
  {
    path: getAggregatedPageRoute(MONITOR_SUMMARY_ROUTE),
    component: MonitorSummaryScreen,
    componentProps: ["monitorId"],
    show: false,
  },
  {
    path: getEntityListRoute(PACKET_LOG_ROUTE),
    component: PacketLogListScreen,
    componentProps: ["monitorId"],
    show: false,
  },
  {
    path: getEntityListRoute(USER_MEMBERSHIP_ROUTE),
    component: UserMembershipScreen,
    componentProps: ["userId"],
    show: false,
  },
  {
    path: getEntityListRoute(SAMPLEPOINT_ROUTE),
    component: SamplePointList,
    componentProps: ["assetId"],
    show: false,
  },
  {
    path: ASSET_DETAILS_ROUTE,
    component: SamplePointGraphPage,
    componentProps: ["assetId"],
    show: false,
  },
  {
    path: SENSOR_GRAPH_ROUTE,
    component: SensorGraphPage,
    componentProps: ["sensorId"],
    show: false,
  },
  {
    path: getEntityListRoute(SENSOR_BY_MONITOR_ROUTE),
    component: SensorInventoryScreen,
    componentProps: ["monitorId"],
    show: false,
  },
  {
    path: getEntityListRoute(SENSOR_BY_MONITOR_CONFIGURATION_ROUTE),
    component: SensorInventoryScreen,
    componentProps: ["monitorId", "monitorConfigurationId"],
    show: false,
  },
  {
    path: getAggregatedPageRoute(SAMPLEPOINT_SENSOR_PAIRING_ROUTE),
    component: SamplePointSensorPairingScreen,
    componentProps: ["samplePointId"],
    show: false,
  },
  {
    path: SEARCH_SCREEN_ROUTE,
    component: SearchScreen,
    show: false,
  },
  {
    path: getAggregatedPageRoute(ROUTE_LIB.SWITCH_ENTERPRISE_ROUTE),
    component: EnterpriseSelection,
    show: false,
  },
  {
    path: getEntityEditRoute(ENTERPRISE_ROUTE),
    component: EnterpriseEditScreen,
    show: false,
  },
  {
    path: getEntityEditRoute(PACKET_LOG_ROUTE),
    component: PacketLogEditScreen,
    show: false,
  },
  {
    path: getEntityEditRoute(MONITOR_ROUTE),
    component: MonitorEditScreen,
    componentProps: ["monitorId"],
    show: false,
  },
  {
    path: getEntityEditRoute(USER_MEMBERSHIP_ROUTE),
    component: UserMembershipEditScreen,
    componentProps: ["userId"],
    show: false,
  },
  {
    path: getEntityEditRoute(USER_ROUTE),
    component: UserEditScreen,
    show: false,
    availableForNoEnterprise: true,
  },
  {
    path: getEntityEditRoute(SENSOR_ROUTE),
    component: SensorEditScreen,
    show: false,
  },
  {
    path: getEntityEditRoute(SAMPLEPOINT_ROUTE),
    component: SamplePointEditScreen,
    componentProps: ["assetId"],
    show: false,
  },
  // {
  //   path: '/',
  //   redirect: true,
  //   to: getEntityListRoute(TANK_ROUTE),
  //   show: true
  // },
];

export default dashboardRoutes;
