import { StyleRulesCallback, Theme } from "@material-ui/core";

export const screenTitleStyle: StyleRulesCallback<Theme, {}> = (theme) => ({
  root: {
    color: theme.palette.text.primary,
  },
  largeScreen: {
    fontSize: "35px",
    lineHeight: "40px",
  },
  smallScreen: {
    fontSize: "28px",
    lineHeight: "32px",
  },
});
