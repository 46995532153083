import React, { MouseEventHandler, useContext } from "react";
import { ListItem, IconButton, ListItemIcon, ListItemText, makeStyles, Paper } from "@material-ui/core";
import MoreVert from "@material-ui/icons/MoreVert";
import Build from "@material-ui/icons/Build";
import Delete from "@material-ui/icons/Delete";
import clsx from "clsx";
import { sidebarItemStyle } from "./sidebarItemstyle";
import { EDIT_ACTION, DELETE_ACTION } from "components/Crud/Entity.const";
import PopupMenu from "components/PopupMenu/PopupMenu";
import { getEntityEditRoute, defaultRouteByUserAndEnterprise } from "components/Utils/RouteUtils";
import { deleteEntityById } from "components/Utils/CRUDUtils";
import { OPERATION_SUCCESS, OPERATION_ERROR } from "components/Utils/CRUDUtils.d";
import { API_LIB } from "components/Utils/API_LIB";
import { ROUTE_LIB } from "components/Utils/RouteList";
import { ObjectIdType } from "components/Utils/Object.d";
import { MessageBannerDispatcherContext } from "../Message/MessageBannerFrame";
import { ActionButtonHandlerComponent } from "components/Buttons/ActionButtonHandlerComponent";
import { useMediaQueryMatchDown } from "components/Utils/LayoutUtils";
import { TableActionButtonFace } from "components/Buttons/TableActionButtonFace";
import { ENTITY_LIB } from "components/Utils/CRUDEntityList";
import { useActiveEnterprise, useActiveUser } from "components/Utils/CustomHooks";
import { ActionButtonConfig, OnClickAction } from "components/Buttons/Buttons.d";
import { GlobalEventContext } from "GlobalContext";
import { GlobalEvent } from "GlobalContext.d";
import { EntityActionType } from "components/Crud/Entity.d";

export interface SidebarItemProps {
  active: boolean;
  path: string;
  label: string;
  icon?: JSX.Element;
  showConfigButton?: boolean;
  allowedActions?: Array<EntityActionType>;
  entityId?: number; // TODO: not all side bar items need entityId
  open: boolean;
}

// TODO: make it highlighted the way Google mail is highlighted so that it doesn't move if it's too far
const useStyles = makeStyles(sidebarItemStyle, { name: "SidebarItem" });

function SidebarItem(props: SidebarItemProps) {
  const classes = useStyles({});

  const messageBannerDispatcher = useContext(MessageBannerDispatcherContext);
  const globalEventContext = useContext(GlobalEventContext);

  const listItemClasses = clsx(classes.itemLink, props.active && classes.itemLinkActive);

  const listItemTextClasses = clsx(classes.itemText, classes.ellipsisOnOverflow);

  const isSmallScreen = useMediaQueryMatchDown("sm");

  // TODO: redo using hook function
  const handleSideBarItemClick = () => {
    globalEventContext(
      /* .dispatchEvent */ {
        type: GlobalEvent.NAVIGATE,
        payload: props.path,
        originator: "SidebarItem.handleSideBarItemClick",
      }
    );

    if (isSmallScreen) {
      globalEventContext(
        /* .dispatchEvent */ {
          type: GlobalEvent.SET_SIDEBAR_OPEN,
          payload: { isOpen: !props.open },
          originator: "SidebarItem.handleSideBarItemClick",
        }
      );
    }
  };

  const userData = useActiveUser();
  const currentEnterprise = useActiveEnterprise();

  const asyncDeleteSidebarItem = async (entityId: ObjectIdType) => {
    const result = await deleteEntityById(entityId, API_LIB.ASSET_GROUP);

    if (result.status.type === OPERATION_ERROR) {
      messageBannerDispatcher(
        /* .dispatchMessage */ { messageType: OPERATION_ERROR, messageText: `Delete failed` }
      );

      console.error(`Error ${result.status.message} while fetching options for ${entityId}`);
    } else {
      messageBannerDispatcher(
        /* .dispatchMessage */ { messageType: OPERATION_SUCCESS, messageText: `Deleted successfully` }
      );

      globalEventContext(
        /* .dispatchEvent */ {
          type: GlobalEvent.REFRESH_SIDEBAR,
          originator: "SidebarItem.asyncDeleteSidebarItem",
        }
      );

      globalEventContext(
        /* .dispatchEvent */ {
          type: GlobalEvent.NAVIGATE,
          payload: defaultRouteByUserAndEnterprise(userData, currentEnterprise.id),
          originator: "SidebarItem.asyncDeleteSidebarItem",
        }
      );
    }
  };

  const configureConfig: ActionButtonConfig = {
    actionObject: ENTITY_LIB.ASSET_GROUP,
    actionCode: EDIT_ACTION,
    name: "configure",
    labelElement: (
      <TableActionButtonFace>
        <Build fontSize="small" />
        &nbsp;
        <span
          style={{ width: "200px" }}
          className={classes.ellipsisOnOverflow}
        >{`Configure ${props.label}`}</span>
      </TableActionButtonFace>
    ),
    onClick: {
      type: "navigate",
      url: getEntityEditRoute(ROUTE_LIB.DYNAMIC_GROUP_CONFIGURATION, props.entityId),
      paramName: "IGNORED", // TODO: unify route updates behaviour
    },
  };

  const deleteConfig: ActionButtonConfig = {
    actionObject: ENTITY_LIB.ASSET_GROUP,
    actionCode: DELETE_ACTION,
    name: "delete",
    labelElement: (
      <TableActionButtonFace>
        <Delete fontSize="small" />
        &nbsp;
        <span
          style={{ width: "200px" }}
          className={classes.ellipsisOnOverflow}
        >{`Delete ${props.label}`}</span>
      </TableActionButtonFace>
    ),
    onClick: {
      type: "action",
      fn: asyncDeleteSidebarItem,
    } as OnClickAction,
    confirm: {
      message: `Are you sure you want to delete ${props.label}`,
      dialogButton: "Delete", // TODO: ISSUE: looks like it doesn't work
    },
  };

  return (
    <div className={classes.root}>
      <div className={clsx(classes.item)} onClick={handleSideBarItemClick}>
        <ListItem button disableGutters className={listItemClasses}>
          {props.icon && <ListItemIcon className={classes.itemIcon}>{props.icon}</ListItemIcon>}
          {props.open && (
            <ListItemText primary={props.label} className={listItemTextClasses} disableTypography={true} />
          )}
          {props.showConfigButton && props.open && (
            <div className={classes.configIcon}>
              {/* TODO: modify prop so as no to pass function exlicitly */}
              <PopupMenu
                clickableVisibleItem={(handleClick: MouseEventHandler) => (
                  <IconButton onClick={handleClick}>
                    <MoreVert fontSize="small" className={classes.configItem} />
                  </IconButton>
                )}
              >
                {(menuCloseActions: () => void, disableMenuInput: () => void) => {
                  return (
                    // NOTE: without this menu stays Open after a click
                    <Paper>
                      <div>
                        {props.allowedActions.indexOf(EDIT_ACTION) >= 0 && (
                          <div className={classes.grouppedButtons}>
                            <ActionButtonHandlerComponent
                              buttonConfig={configureConfig}
                              id={props.entityId}
                              preActionCallback={disableMenuInput}
                            />
                          </div>
                        )}
                        {props.allowedActions.indexOf(DELETE_ACTION) >= 0 && (
                          <div className={classes.grouppedButtons}>
                            <ActionButtonHandlerComponent
                              buttonConfig={deleteConfig}
                              id={props.entityId}
                              preActionCallback={disableMenuInput}
                            />
                          </div>
                        )}
                      </div>
                    </Paper>
                  );
                }}
              </PopupMenu>
            </div>
          )}
        </ListItem>
      </div>
    </div>
  );
}

export default SidebarItem;
