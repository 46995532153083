import { StyleRulesCallback, Theme } from "@material-ui/core";

export const elementLabelStyle: StyleRulesCallback<Theme, {}> = (theme) => ({
  labelMobile: {
    whiteSpace: "nowrap",
    fontSize: "12px",
  },
  labelDesktop: {
    whiteSpace: "nowrap",
    fontSize: "14px",
  },
  ellipsisOnOverflow: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
});
