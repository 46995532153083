export interface CacheContentType {
  data: Record<string, any>;
  totalRows?: number;
}

export interface CacheRecord {
  content: CacheContentType;
  timestamp: number;
}

const DEFAULT_EXPIRATION = 5 * 60 * 1000;

class Cache {
  _cacheMap = new Map();
  constructor() {
    this.add = this.add.bind(this);
    this.retrieve = this.retrieve.bind(this);
  }

  add(url: string, content: CacheContentType, expireIn?: number) {
    const localExpireIn = expireIn || DEFAULT_EXPIRATION;

    const cacheRecord: CacheRecord = {
      content: content,
      timestamp: new Date().getTime(),
    };

    this._cacheMap.set(url, cacheRecord);

    setTimeout(() => {
      this._cacheMap.delete(url);
    }, localExpireIn);
  }

  retrieve(url: string) {
    const cacheRecord: CacheRecord = this._cacheMap.get(url);

    let result: CacheContentType;

    if (cacheRecord) {
      result = cacheRecord.content;
    }

    return result;
  }
}

export const GlobalFetchCache = new Cache();
