import React, { useMemo, useEffect, useState, useContext, useCallback } from "react";
import { ROUTE_LIB } from "components/Utils/RouteList";
import { DataSourceParamsType } from "components/Crud/DataProvider/DataProviderCommon.d";
import { ObjectIdType } from "components/Utils/Object.d";
import { UnifiedDataRequestType } from "components/Utils/CRUDUtils.d";
import { filterBy, isNonEmptyArray, isSpecified, translateValue } from "components/Utils/MiscUtils";
import { useDatasetManager } from "components/Crud/DataProvider/DatasetManager";
import { QueryEventAction } from "components/Crud/DataProvider/DataQuery.d";
import { FetchlessFormikEntityForm } from "components/Crud/Form/FetchlessFormikEntityForm";
import { groupEditFields } from "./AssetGroupEdit.formFields";
import { getAttributeByPath, setAttributeByPath } from "components/Crud/EntityUtils";
import { CREATE_ACTION, EDIT_ACTION } from "components/Crud/Entity.const";
import { UserParams } from "views/User/UserParams.d";
import { EventDispatchersType } from "components/Crud/Table/StatelessTable.d";
import { CRUD_DISPATCHER } from "components/Crud/DataProvider/DataCrud";
import { SAMPLE_TYPE_API } from "views/asset/SamplePoint/SamplePoint.const";
import { useSimpleAsyncData } from "components/Crud/DataProvider/useSimpleAsyncData";
import { API_LIB } from "components/Utils/API_LIB";
import { MenuItemType } from "components/Crud/Form/Fields/FieldConfiguration.d";
import { SITE_API_URL } from "views/Site/Site.const";
import produce from "immer";
import { TabbedView } from "layouts/TabbedView";
import AssetGroupResultsPreview from "./AssetGroupResultsPreview";
import { useOperationController } from "popups/usePopupOperationController";
import { GroupUsersNew } from "./AssetGroupUsersNew";
import { ALL_ENTERPRISES_CODE } from "views/Enterprise/Enterprise.const";
import { generateEntityActionRoute } from "components/Utils/RouteUtils";
import { GlobalEventContext } from "GlobalContext";
import { GlobalEvent } from "GlobalContext.d";
import { EntityActionType } from "components/Crud/Entity.d";

export interface AssetGroupConfigurationNewExtendedViewProps {
  id: ObjectIdType;
  currentEnterpriseId: ObjectIdType;
  action: EntityActionType;
  userData: UserParams;
}

export const extractItemsForDictionary = (
  inputArray: Array<Record<string, any>>,
  codeField: string,
  labelField: string
) => {
  // TODO: use this function where appropriate
  let result: Array<MenuItemType> = [];

  if (isNonEmptyArray(inputArray)) {
    result = inputArray.map((dbCodeLabel: Record<string, any>) => {
      return {
        code: getAttributeByPath(dbCodeLabel, codeField),
        label: getAttributeByPath(dbCodeLabel, labelField),
      };
    });
  }

  return result;
};

const removeNonEssentialPreviewRequestFields = (previewRequest: Record<string, any>) => {
  if (!isSpecified(previewRequest)) {
    return undefined;
  }

  const result = produce(previewRequest, (draftPreviewRequest) => {
    delete draftPreviewRequest.name;
    delete draftPreviewRequest.comments;
  });

  return result;
};

// TODO: remove irrelevant user group components
export function AssetGroupConfigurationNewExtendedView(props: AssetGroupConfigurationNewExtendedViewProps) {
  const globalEventContext = useContext(GlobalEventContext);

  const dataSource: DataSourceParamsType = {
    apiUrl: ROUTE_LIB.ASSET_GROUP,
  };

  const initialRequest: UnifiedDataRequestType = useMemo(
    () => ({
      fieldList: ["name", "comments", "enterpriseId", "sites.name", "assetTypes.id", "sampleTypes.id"],
      filter: {
        baseFilters: [
          filterBy("id", "=", props.id || -1),
          filterBy(
            "enterpriseId",
            "=",
            translateValue(props.currentEnterpriseId, [[ALL_ENTERPRISES_CODE, undefined]])
          ),
        ],
      },
    }),
    [props.id, props.currentEnterpriseId]
  );

  const [dataset, dispatchRequest] = useDatasetManager(dataSource);
  // Bits copied from DataCrudEntityFormBinding
  const eventDispatchersForForm: EventDispatchersType = {};
  eventDispatchersForForm[CRUD_DISPATCHER] = dispatchRequest;

  useEffect(() => {
    dispatchRequest({
      action: QueryEventAction.REQUEST_DATA,
      payload: initialRequest,
      originator: "AssetGroupConfigurationNewExtendedView - load on mount",
    });
  }, [dispatchRequest, initialRequest]);

  const sampleTypeDictionary = useSimpleAsyncData(SAMPLE_TYPE_API);
  const assetTypeDictionary = useSimpleAsyncData(API_LIB.ASSET_TYPE);
  const siteDictionary = useSimpleAsyncData(
    `${SITE_API_URL}?filter=enterpriseId::eq::${props.currentEnterpriseId}` /* , '[0].sampleFields' */
  );

  const fieldConfigurations = groupEditFields(
    props.currentEnterpriseId,
    extractItemsForDictionary(sampleTypeDictionary.data, "id", "name"),
    extractItemsForDictionary(assetTypeDictionary.data, "id", "name"),
    extractItemsForDictionary(siteDictionary.data, "id", "name")
  );

  const formWithMultipleSelectPlainNumbersToIds = (inputFormData: Record<string, any>) => {
    let formData = inputFormData;

    const multipleFields = fieldConfigurations.filter((item) => item.type === "select-multiple");

    if (isNonEmptyArray(multipleFields)) {
      formData = produce(inputFormData, (draftResult) => {
        multipleFields.forEach((field) => {
          const values = getAttributeByPath(draftResult, field.fieldName);

          if (isNonEmptyArray(values)) {
            const newValues = values.map((value: any) => ({ id: value }));
            setAttributeByPath(draftResult, field.fieldName, newValues);
          }
        });
      });
    }

    return formData;
  };

  const presubmitData = (inputObject: Record<string, any>) => {
    const result = formWithMultipleSelectPlainNumbersToIds(inputObject);

    return result;
  };

  const formWithMultipleSelectIdsToPlainNumbers = (inputFormData: Record<string, any>) => {
    let formData = inputFormData;

    const multipleFields = fieldConfigurations.filter((item) => item.type === "select-multiple");

    if (isNonEmptyArray(multipleFields)) {
      formData = produce(inputFormData, (draftResult) => {
        multipleFields.forEach((field) => {
          const values = getAttributeByPath(draftResult, field.fieldName);

          if (isNonEmptyArray(values)) {
            const newValues = values.map((value: any) => value.id);
            setAttributeByPath(draftResult, field.fieldName, newValues);
          }
        });
      });
    }

    return formData;
  };

  let formData = undefined as Record<string, any>;
  if (isNonEmptyArray(dataset.dataArray)) {
    formData = formWithMultipleSelectIdsToPlainNumbers(dataset.dataArray[0]);
  }

  const [activeFilter, setActiveFilter] = useState(removeNonEssentialPreviewRequestFields(formData));

  const objectId = isNonEmptyArray(dataset.dataArray) ? dataset.dataArray[0].id : undefined;

  const refreshSideBar = useCallback(
    (isSuccess: boolean, relatedId?: ObjectIdType) => {
      if (isSuccess && props.action === CREATE_ACTION) {
        globalEventContext({
          type: GlobalEvent.NAVIGATE,
          payload: generateEntityActionRoute(ROUTE_LIB.DYNAMIC_GROUP_CONFIGURATION, EDIT_ACTION, relatedId),
          originator: "Post Group Creation",
        });
      }

      globalEventContext({
        type: GlobalEvent.REFRESH_SIDEBAR,
        originator: "AssetGroupConfigurationExtendedView.asyncUpdateData",
      });
    },
    [globalEventContext, props.action]
  );

  const operationResult = dataset.lastOperationResult;
  useOperationController(operationResult, refreshSideBar, { onAddSuccess: true, onUpdateSuccess: true });

  return (
    <div>
      <div style={{ display: "flex" }}>
        <div style={{ width: "320px" /*, flexBasis: '0', flexGrow: 1 */ }}>
          <TabbedView
            tabLabels={["CONFIGURATION", "USERS"]}
            initialTabId={0}
            render={[
              (switchToTab: (tabId: number) => void) => {
                return (
                  <FetchlessFormikEntityForm
                    // tempOperationResult={operationResult}
                    // triggerCloseOnSuccesOf={[CrudEventAction.CREATE, CrudEventAction.UPDATE, CrudEventAction.DELETE]}
                    formData={formData}
                    id={objectId}
                    action={props.action}
                    // title={props.title}
                    isDataLoading={false} //{crudStatus.state === DataCrudOperationStatus.EXECUTING}
                    fieldConfigurations={fieldConfigurations}
                    eventDispatchers={eventDispatchersForForm}
                    // onCloseAdditionalActions={props.onCloseAdditionalActions}
                    showCloseButton={false}
                    userData={props.userData}
                    currentEnterpriseId={props.currentEnterpriseId}
                    // layoutComponent={props.layoutComponent}
                    // conditionalFieldConfiguration={props.conditionalFieldConfiguration}
                    formDataPreSubmitProcessor={presubmitData}
                    doNotPreFetchData={true} //{props.doNotPreFetchData}
                    notifyOnValidValuesChange={(input: Record<string, any>) => {
                      setActiveFilter(
                        removeNonEssentialPreviewRequestFields(formWithMultipleSelectPlainNumbersToIds(input))
                      );
                    }}
                  />
                );
              },
              (switchToTab: (tabId: number) => void) => {
                return (
                  <React.Fragment>
                    {!isSpecified(formData) || !isSpecified(formData.id) ? (
                      <p style={{ color: "red" }}>Save Form First</p>
                    ) : (
                      <GroupUsersNew enterpriseId={formData.enterpriseId} groupId={formData.id} />
                    )}
                  </React.Fragment>
                );
              },
            ]}
          />
        </div>
        <div style={{ flexBasis: 0, flexGrow: 1, paddingLeft: "20px" }}>
          <AssetGroupResultsPreview
            filterConfiguration={activeFilter}
            trigger={undefined} //{didSaveData === true}
            userData={props.userData}
            currentEnterpriseId={props.currentEnterpriseId}
          />
        </div>
      </div>
    </div>
  );
}
