import { FieldConfiguration } from "components/Crud/Form/Fields/FieldConfiguration.d";
import { ObjectIdType } from "components/Utils/Object.d";
import { Theme } from "@material-ui/core";
import { USER_API_URL } from "views/User/User.const";

export const assetGroupUserEditFormFieldsSpec = (
  enterpriseId: ObjectIdType,
  groupId: ObjectIdType,
  theme: Theme
): Array<FieldConfiguration> => [
  {
    label: "Asset Group",
    fieldName: "assetGroupId",
    type: "number",
    hideIf: () => true,
    defaultValue: groupId,
    validation: {
      required: true,
    },
  },
  {
    label: "Username",
    fieldName: "userId",
    type: "select-async",
    validation: {
      required: true,
    },
    fieldParams: {
      optionDefinitionEndpoint: USER_API_URL,
      optionCodeField: "id",
      optionLabelField: "username",
      filterUrl: `?join=memberships&filter=memberships.enterpriseId::eq::${enterpriseId}`,
      autoComplete: true,
    },
  },
  {
    label: "Notify",
    fieldName: "notify",
    type: "boolean",
    defaultValue: true,
    fieldParams: {
      color: theme.palette.primary.main,
    },
  },
];
