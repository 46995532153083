import React from "react";
import { Checkbox, makeStyles, CheckboxProps } from "@material-ui/core";

export interface ColouredCheckBoxProps {
  customcolor: string;
}
export interface ColouredCheckBoxStyleProps {
  color: string;
}

const useStyles = makeStyles(
  {
    root: (styleProps: ColouredCheckBoxStyleProps) => ({
      color: styleProps.color,
    }),
  },
  {
    name: "ColouredCheckBox",
  }
);

export function ColouredCheckBox(props: ColouredCheckBoxProps & CheckboxProps) {
  const classes = useStyles({ color: props.customcolor });

  return <Checkbox color="default" classes={{ root: classes.root }} {...props} />;
}
