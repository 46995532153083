import { isSpecified, isNonEmptyArray, glueArrays } from "components/Utils/MiscUtils";
import {
  distinctCount,
  mergeObjectArrays,
  propagateObjectToArray,
  arrayETL,
} from "components/Utils/ObjectUtils";
import { getAttributeByPath } from "components/Crud/EntityUtils";
import {
  SupportedChartTypes,
  SampleDateType,
  SampleEndpointResponseSegment,
  GraphPointValueType,
  Sample,
  SampleEndpointResponse,
  DataSourceDescriptorType,
} from "./Graph.d";
import { CustomJoinSegmentsPluginSegmentType } from "./Plugins/CustomJoinSegmentsPlugin.d";
import { chartConfigByType } from "./GraphConfigurationUtils";

export const findDotsToConnect = (chartDataWithConfig: Array<any>) => {
  // TODO: typefy
  const result: Array<CustomJoinSegmentsPluginSegmentType> = [];

  const chartDataWithConfigOnlyLines = chartDataWithConfig.filter(
    (item) => item.calculatedChartType !== SupportedChartTypes.BAR
  );

  if (isNonEmptyArray(chartDataWithConfigOnlyLines)) {
    const distinctSamplePointsWithCount = distinctCount(chartDataWithConfigOnlyLines, "samplePoint.id");

    const samplePointWithMoreThanOneSegment = distinctSamplePointsWithCount.filter((item) => item.count > 1);

    samplePointWithMoreThanOneSegment.forEach((item) => {
      const samplePointSegments = chartDataWithConfigOnlyLines.filter(
        (samplePointData) => samplePointData.samplePoint.id === item.key
      );

      // At this stage assuming the segments are sorted. TODO: assume it's not

      for (let i = 0; i < samplePointSegments.length; i++) {
        const currentSegmentLastData = isNonEmptyArray(samplePointSegments[i].samples)
          ? samplePointSegments[i].samples[samplePointSegments[i].samples.length - 1]
          : undefined;

        let nextSegmentFirstData;

        if (i + 1 < samplePointSegments.length) {
          nextSegmentFirstData = samplePointSegments[i + 1].samples[0];
        }
        // TODO: generalize scale names identification
        if (currentSegmentLastData && nextSegmentFirstData) {
          result.push({
            scales: { scaleX: "x-axis-0", scaleY: samplePointSegments[i].samplePoint.units },
            stroke: { style: samplePointSegments[i].samplePoint.color, lineWidth: 1.25 },
            start: {
              x: currentSegmentLastData[0],
              y: parseFloat(
                samplePointSegments[i].samplePoint.dateType === SampleDateType.TIME_INTERVAL
                  ? currentSegmentLastData[2]
                  : currentSegmentLastData[1]
              ),
            },
            finish: {
              x: nextSegmentFirstData[0],
              y: parseFloat(
                samplePointSegments[i + 1].samplePoint.dateType === SampleDateType.TIME_INTERVAL
                  ? nextSegmentFirstData[2]
                  : nextSegmentFirstData[1]
              ),
            },
          });
        }
      }
    });
  }

  return result;
};

export const convertEndpointDataToXY = (
  incomingData: SampleEndpointResponseSegment,
  timeInterval: SampleDateType
): Array<GraphPointValueType> => {
  let resultDataArray: Array<{ x: any; y: any }>;

  if (isSpecified(incomingData.samples)) {
    const chartType = getAttributeByPath(incomingData, "chartConfig.type");

    resultDataArray = incomingData.samples.map((sampleData: any, index: number) => {
      return {
        x: sampleData[0] - (chartType === SupportedChartTypes.BAR ? 12 * 60 * 60 * 1000 : 0),
        y: timeInterval === SampleDateType.TIME_INTERVAL ? sampleData[2] : sampleData[1],
      };
    });
  } else {
    resultDataArray = [];
  }

  return resultDataArray;
};

export const convertEndpointDataToTableData = (
  incomingData: SampleEndpointResponseSegment,
  timeInterval: SampleDateType,
  sourceId: number
) => {
  let resultData: Array<any>;

  if (!incomingData.isValueForecasted) {
    resultData = incomingData.samples.map((sampleData: Sample, index: number) => {
      const resultItem: Record<string, any> = {};

      resultItem.date = sampleData[0];

      const datasetValue = timeInterval === SampleDateType.TIME_INTERVAL ? sampleData[2] : sampleData[1];
      const datasetUnits =
        timeInterval === SampleDateType.TIME_INTERVAL
          ? incomingData.fields[2].units
          : incomingData.fields[1].units;

      resultItem.value = `${datasetValue} ${datasetUnits}`;
      resultItem.sourceId = sourceId;
      resultItem.sampleTypeId = getAttributeByPath(incomingData, "samplePoint.sampleTypeId");

      return resultItem;
    });
  }

  return resultData;
};

export const determineChartType = (sampleTypeName: string, dateType: SampleDateType) => {
  if (sampleTypeName === "Rain Fall") {
    return SupportedChartTypes.BAR;
  } else if (dateType === SampleDateType.TIME_INTERVAL) {
    return SupportedChartTypes.STEPPED_LINE;
  } else {
    return SupportedChartTypes.LINE;
  }
};

export const convertSamplePointDataToGraphDataWithConfig = (
  propsDataArray: Array<SampleEndpointResponse>,
  propsDataSourceDescriptors: Array<DataSourceDescriptorType>
) => {
  let calculatedChartDataWithConfig: Array<any>;

  if (propsDataArray.length === propsDataSourceDescriptors.length) {
    const mergedSamplesAndDatasources = mergeObjectArrays(propsDataArray, propsDataSourceDescriptors);

    const samplePointMergedAtSampleLevel = mergedSamplesAndDatasources.map((arrayItem) => {
      const result = propagateObjectToArray(
        arrayItem,
        [
          { e: "id", l: "samplePoint.id" },
          { e: "name", l: "samplePoint.name" },
          { e: "sampleTypeName", l: "samplePoint.sampleTypeName" },
          { e: "sampleTypeId", l: "samplePoint.sampleTypeId" }, // NEW
          { e: "dateType", l: "samplePoint.dateType" },
          { e: "color", l: "samplePoint.color" },
          { e: "units", l: "samplePoint.units" }, // units arent captured for sensors
        ],
        arrayItem.data
      );

      return result;
    });

    const flatData = glueArrays(samplePointMergedAtSampleLevel);

    const chartWithChartType = arrayETL(flatData, [
      { e: "samples" },
      { e: "fields" },
      { e: "samplePoint" },
      { e: "analysisType" },
      { e: "isValueForecasted" },
      {
        e: ["samplePoint.sampleTypeName", "samplePoint.dateType"],
        t: determineChartType,
        l: "calculatedChartType",
      },
    ]);

    const datasetConfigByDataDescriptor = (
      samplePointName: string,
      samplePointUnits: string,
      calculatedChartType: SupportedChartTypes,
      samplePointColor: string
    ) => {
      return chartConfigByType(samplePointName, calculatedChartType, samplePointColor, samplePointUnits);
    };

    calculatedChartDataWithConfig = arrayETL<any>(chartWithChartType, [
      { e: "samples" },
      { e: "fields" },
      { e: "samplePoint" },
      { e: "analysisType" },
      { e: "calculatedChartType" },
      { e: "isValueForecasted" },
      {
        e: ["samplePoint.name", "samplePoint.units", "calculatedChartType", "samplePoint.color"],
        t: datasetConfigByDataDescriptor,
        l: "chartConfig",
      },
    ]);
  } else {
    calculatedChartDataWithConfig = [];
  }

  return calculatedChartDataWithConfig;
};
