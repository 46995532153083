import React from "react";
import { UserParams } from "views/User/UserParams.d";
import { UserUtils } from "views/User/UserCommon";
import { withStyles } from "@material-ui/core";
import userLoginButtonStyle from "components/Dashboard/Header/userLoginButtonStyle";

interface UserLoginButtonProps {
  classes: {
    text: string;
    circle: string;
    svg: string;
  };
  userData: UserParams;
}

function UserLoginButton(props: UserLoginButtonProps) {
  const userInitials = UserUtils.getInitials(props.userData);

  return (
    <svg className={props.classes.svg}>
      <circle cx="50%" cy="50%" r="40%" className={props.classes.circle} />
      <text className={props.classes.text} x="50%" y="50%" dy=".3em">
        {userInitials}
      </text>
      {userInitials}
    </svg>
  );
}

export default withStyles(userLoginButtonStyle)(UserLoginButton);
