import React from "react";

import { MainPanelWithParamsProps } from "components/Dashboard/MainPanelWithParams.d";
import { EntityEditRouteParams } from "components/Dashboard/EntityEditRouteParams.d";
import { PacketLogEditForm } from "./PacketLogEditForm";

export function PacketLogEditScreen(props: MainPanelWithParamsProps<EntityEditRouteParams>) {
  return (
    <PacketLogEditForm
      id={props.match.params.id}
      action={props.match.params.action}
      showCloseButton={false}
      currentEnterpriseId={props.currentEnterpriseId}
      userData={props.userData}
    />
  );
}
