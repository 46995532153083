import React from "react";
import {
  MONITOR_CONFIGURATION_API_URL,
  MONITOR_SENSOR_LIST_ACTION,
  MONITOR_CONFIGURATION_ENTITY_NAME,
} from "../Monitor.const";
import SettingsInputComposite from "@material-ui/icons/SettingsInputComposite";
import { SENSOR_BY_MONITOR_CONFIGURATION_ROUTE } from "views/hardware/Sensor/Sensor.const";
import { DateCellRenderer } from "components/Crud/Table/CellRenderers/DateCellRenderer";
import { getEntityListRoute } from "components/Utils/RouteUtils";
import { UnifiedDataRequestType, SORT_DESC } from "components/Utils/CRUDUtils.d";
import { TableCellRendererProps } from "components/Crud/Table/CellRenderers/TableCellRenderer.d";
import { populateStringTemplate } from "components/Utils/StringUtils";
import { BaseListProps, TableColumnParameters, TableColumnFilter } from "components/Crud/Table/EntityTable.d";
import { DataSourceParamsType } from "components/Crud/DataProvider/DataProviderCommon.d";
import { filterBy } from "components/Utils/MiscUtils";
import { CrudQueryTableBinding } from "components/Crud/Table/CrudQueryTableBinding";
import { TableActionButtonFace } from "components/Buttons/TableActionButtonFace";
import { ObjectIdType } from "components/Utils/Object.d";

export interface MonitorConfigurationListProps {
  monitorId: ObjectIdType;
  title?: string;
}

export function MonitorConfigurationList(props: BaseListProps & MonitorConfigurationListProps) {
  const baseSensorRoute = populateStringTemplate(SENSOR_BY_MONITOR_CONFIGURATION_ROUTE, {
    monitorId: props.monitorId,
  });

  const columns: TableColumnParameters[] = [
    { sourceName: "id", label: "Id", width: 50, align: "center" },
    { sourceName: "configBlockId", label: "Block Id", width: 100, align: "center" },
    {
      sourceName: "receivedDate",
      label: "Date",
      width: 100,
      align: "center",
      valueTransformationFunction: (rendererProps: TableCellRendererProps) => (
        <DateCellRenderer userFriendlyFormat={false} {...rendererProps} />
      ),
    }, // TODO: perhaps theres more elegant way
    {
      disableSorting: true,
      actionSet: [
        {
          labelElement: (
            <TableActionButtonFace>
              <SettingsInputComposite />
              &nbsp;<span>Sensors</span>
            </TableActionButtonFace>
          ),
          name: "Sensors",
          onClick: {
            type: "navigate",
            url: getEntityListRoute(baseSensorRoute),
            paramName: "monitorConfigurationId",
          },
          actionCode: MONITOR_SENSOR_LIST_ACTION,
          actionObject: MONITOR_CONFIGURATION_ENTITY_NAME,
        },
      ],
    },
  ];

  const uiFilters: Array<TableColumnFilter> = [];

  const getMonitorId = () => {
    if (!props.monitorId) {
      throw new Error("Monitor Id is not set");
    }

    return props.monitorId;
  };

  const dataSource: DataSourceParamsType = {
    apiUrl: MONITOR_CONFIGURATION_API_URL,
  };

  const initialRequest: UnifiedDataRequestType = {
    sortByColumn: "receivedDate",
    sortDirection: SORT_DESC,
    fieldList: ["configBlockId", "configBlockId", "receivedDate"],
    filter: {
      baseFilters: [filterBy("monitorId", "=", getMonitorId())],
    },
    isPaginationEnabled: true,
    rowsPerPage: 10,
    pageNum: 0,
  };

  return (
    <CrudQueryTableBinding
      externalTriggerValues={[props.currentEnterpriseId, props.userData]}
      dataSource={dataSource}
      intialQuery={initialRequest}
      title={props.title}
      tableColumns={columns}
      onRowClickFunction={props.onRowClickFunction}
      // actionsAllowed={allowedActions}
      highlightedRowsPrimaryKeys={props.activeRowPrimaryKeys}
      uiFilters={uiFilters}
    />
  );
}
