import React from "react";
import { makeStyles } from "@material-ui/core";
import { subordinateCardValueStyle } from "./subordinateCardValueStyle";

export interface SubordinateCardValueProps {
  children: string;
}

const useStyles = makeStyles(subordinateCardValueStyle, { name: "SubordinateCardValue" });

export function SubordinateCardValue(props: SubordinateCardValueProps) {
  const classes = useStyles({});

  return <span className={classes.root}>{props.children}</span>;
}
