import { StyleRulesCallback, Theme } from "@material-ui/core";

export const cardSubTitleStyle: StyleRulesCallback<Theme, {}> = (theme) => ({
  root: {
    // TODO: Roboto Medium
    color: theme.palette.text.secondary,
    fontSize: "11px",
    lineHeight: "13px",
    fontWeight: 500,
    letterSpacing: "0.33px", // TODO: is it important?
    fontFamily: '"Roboto", "Helvetica", sans-serif',
  },
});
