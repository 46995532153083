import React from "react";
import { SENSOR_API_URL, SENSOR_GRAPH_ROUTE, SENSOR_GRAPH_ACTION, SENSOR_ENTITY_NAME } from "./Sensor.const";
import AssessmentOutlined from "@material-ui/icons/AssessmentOutlined";
import { UnifiedDataRequestType, SORT_ASC, FilterMatchTypes } from "components/Utils/CRUDUtils.d";
import { BaseListProps, TableColumnParameters, TableColumnFilter } from "components/Crud/Table/EntityTable.d";
import { DataSourceParamsType } from "components/Crud/DataProvider/DataProviderCommon.d";
import { CrudQueryTableBinding } from "components/Crud/Table/CrudQueryTableBinding";
import { useVersion } from "components/Utils/CustomHooks";
import {
  generateEditButtonConfig,
  generateShowButtonConfig,
  generateDeleteButtonConfig,
  generateCreateButtonConfig,
} from "components/Buttons/ButtonsCommon";
import { TableActionButtonFace } from "components/Buttons/TableActionButtonFace";
import { ALL_ENTERPRISES_CODE } from "views/Enterprise/Enterprise.const";
import { filterBy, translateValue } from "components/Utils/MiscUtils";
import { SensorEditForm } from "./SensorEditForm";
import { SimpleEnum } from "components/Crud/Table/CellRenderers/SimpleEnum";
import { TableCellRendererProps } from "components/Crud/Table/CellRenderers/TableCellRenderer.d";
import { SAMPLE_POINT_TYPES } from "components/Dictionaries/DictionariesCommon";
import { ObjectIdType } from "components/Utils/Object.d";
import { mergeRecords } from "components/Utils/ObjectUtils";
import { SAMPLE_TYPE_API } from "views/asset/SamplePoint/SamplePoint.const";
import { refineRequest } from "components/Utils/RouteUtils";

export interface SensorListProps {
  monitorConfigurationId?: number;
  monitorId?: ObjectIdType;
  title?: string;
}

export function SensorList(props: BaseListProps & SensorListProps) {
  const [version, increaseVersion] = useVersion();

  const columns: TableColumnParameters[] = [
    {
      sourceName: "id",
      label: "Id",
    },
    {
      sourceName: "label",
      label: "Label",
    },
    {
      sourceName: "sensorLib.sampleTypeId",
      label: "Type",
      valueTransformationFunction: (rendererProps: TableCellRendererProps) => (
        <SimpleEnum options={SAMPLE_POINT_TYPES} {...rendererProps} />
      ),
    },
    { sourceName: "device.serialNumber", label: "Serial Number" },
    {
      disableSorting: true,
      actionSet: [
        generateEditButtonConfig(SENSOR_ENTITY_NAME, SensorEditForm, [increaseVersion], {
          title: "Edit Sensor Details",
        }),
        generateShowButtonConfig(SENSOR_ENTITY_NAME, SensorEditForm, { title: "Show Sensor Details" }),
        generateDeleteButtonConfig(SENSOR_ENTITY_NAME),
        {
          labelElement: (
            <TableActionButtonFace>
              <AssessmentOutlined fontSize="small" />
              &nbsp;<span>Details</span>
            </TableActionButtonFace>
          ),
          doNotGroup: true,
          name: "Details",
          onClick: { type: "navigate", url: SENSOR_GRAPH_ROUTE, paramName: "sensorId" },
          actionCode: SENSOR_GRAPH_ACTION,
          actionObject: SENSOR_ENTITY_NAME,
        },
      ],
    },
  ];

  const uiFilters: Array<TableColumnFilter> = [
    {
      filterType: "text",
      filterDataSourceField: "id",
      filterMatchType: FilterMatchTypes.EQUAL,
      filterParams: {
        placeholder: "Sensor Id",
      },
    },
    {
      filterType: "select-async",
      filterParams: {
        optionDefinitionEndpoint: SAMPLE_TYPE_API,
        optionCodeField: "id",
        optionLabelField: "name",
        renderEmptyValueAs: "ALL SAMPLE TYPES",
      },
      filterDataSourceField: "sensorLib.sampleTypeId", // Because column uses name, but filter uses id
    },
    {
      filterType: "text",
      filterDataSourceField: "device.serialNumber",
      filterMatchType: FilterMatchTypes.EQUAL,
      filterParams: {
        placeholder: "Serial Number",
      },
    },
  ];

  let endpoint = SENSOR_API_URL;
  let paginationEnabled = true;

  if (props.monitorConfigurationId) {
    endpoint = `${SENSOR_API_URL}/by_monitor_configuration/${props.monitorConfigurationId}`;
    paginationEnabled = false;
  } else if (props.monitorId) {
    endpoint = `${SENSOR_API_URL}/by_monitor/${props.monitorId}`;
    paginationEnabled = false;
  }

  const dataSource: DataSourceParamsType = {
    apiUrl: endpoint,
  };

  let initialRequest: UnifiedDataRequestType = {
    sortByColumn: "id",
    sortDirection: SORT_ASC,
    fieldList: ["sensorLib.sampleType.name", "device.serialNumber"],
    filter: {
      baseFilters: [
        filterBy(
          "device.site.enterpriseId",
          "=",
          translateValue(props.currentEnterpriseId, [[ALL_ENTERPRISES_CODE, undefined]])
        ),
      ],
    },
    isPaginationEnabled: paginationEnabled,
    rowsPerPage: 10,
    pageNum: 0,
  };

  // to use URL defined query params
  const refinedRequestFromInput = refineRequest(props.inputRequest, uiFilters);
  initialRequest = mergeRecords(initialRequest, refinedRequestFromInput) as UnifiedDataRequestType;

  const headerActions = [
    generateCreateButtonConfig(SENSOR_ENTITY_NAME, "ADD SENSOR", SensorEditForm, [increaseVersion], {
      title: "Add Sensor",
    }),
  ];

  return (
    <CrudQueryTableBinding
      externalTriggerValues={[props.currentEnterpriseId, props.userData, version]}
      dataSource={dataSource}
      intialQuery={initialRequest}
      title={props.title}
      tableColumns={columns}
      onRowClickFunction={props.onRowClickFunction}
      // actionsAllowed={allowedActions}
      highlightedRowsPrimaryKeys={props.activeRowPrimaryKeys}
      uiFilters={uiFilters}
      headerActions={headerActions}
      // to use URL defined query params
      allowUrlReplacementForFilters={props.allowUrlReplacementForFilters}
      allowReinitialization={props.allowReinitialization}
    />
  );
}
