import React, { RefObject } from "react";
import Chart from "chart.js";
import { generateChartOptions } from "./GraphConfigurationUtils";
import moment from "moment-timezone";
import { joinSegmentsPlugin } from "./Plugins/CustomJoinSegmentsPlugin";
import { getAttributeByPath } from "components/Crud/EntityUtils";
import { isNonEmptyArray } from "components/Utils/MiscUtils";
import { ChartType, ChartConfigurationOptions } from "./Graph.d";
import { CustomJoinSegmentsPluginSegmentType } from "./Plugins/CustomJoinSegmentsPlugin.d";

interface GraphWidgetProps {
  chartType: ChartType;
  data?: any[];
  dataConfig?: any[];
  chartConfig: ChartConfigurationOptions;
  joinSegmentsConfig?: Array<CustomJoinSegmentsPluginSegmentType>;
}

// TODO: globalDotsOnly is a global setting. For individual data sets use different technique
// https://www.chartjs.org/docs/latest/general/performance.html#disable-line-drawing
// TODO: Decimation for https://www.chartjs.org/docs/latest/general/performance.html#data-decimation

class GraphWidget extends React.Component<GraphWidgetProps> {
  _chartRef: RefObject<HTMLCanvasElement>;
  _chart: any;

  constructor(props: GraphWidgetProps) {
    super(props);
    this._chartRef = React.createRef<HTMLCanvasElement>();

    this.buildChart = this.buildChart.bind(this);
  }

  componentDidMount() {
    this.buildChart();
  }
  componentDidUpdate() {
    this.buildChart();
  }

  buildChart() {
    const myChartRef = this._chartRef.current.getContext("2d");

    if (typeof this._chart !== "undefined") {
      // TODO: built for speed. I'm not super cool about it
      this._chart.destroy();
    }

    const formDataSet = (config: Partial<any>, data: Array<any>) => {
      const { gradientFill, ...remainingConfig } = config;

      const result = Object.assign({}, { data: data }, { ...remainingConfig });

      if (gradientFill) {
        const gradient = myChartRef.createLinearGradient(0, 0, 0, 300);

        gradient.addColorStop(0, config.borderColor);
        gradient.addColorStop(0.5, "#FFFFFF00");
        gradient.addColorStop(1, "#FFFFFF00");

        result.fill = true;
        result.backgroundColor = gradient;
      }

      return result;
    };

    const chartOptions = generateChartOptions(this.props.chartConfig);

    let chartData: Partial<any> = {
      datasets: this.props.dataConfig.map((configItem: any, index: number) =>
        formDataSet(configItem, this.props.data[index])
      ),
    };

    moment.tz.setDefault(this.props.chartConfig.timeZone);

    Chart.defaults.LineWithLine = Chart.defaults.line;

    const customLineWithLine = Chart.controllers.line.extend({
      draw: function (ease: any) {
        Chart.controllers.line.prototype.draw.call(this, ease);

        if (this.chart.tooltip._active && this.chart.tooltip._active.length) {
          const ctx = this.chart.ctx;

          const activePoint = this.chart.tooltip._active[0];
          const activeToolTipColor = activePoint._model.borderColor;

          const activePointToolTipPosition = activePoint.tooltipPosition();

          const topY = this.chart.chartArea.top; // activePoint.tooltipPosition().y - 20;
          const bottomY = this.chart.chartArea.bottom; // this.chart.scales[scaleName].bottom;

          // draw line
          ctx.save();
          ctx.beginPath();
          ctx.moveTo(activePointToolTipPosition.x, topY);
          ctx.lineTo(activePointToolTipPosition.x, bottomY);
          ctx.lineWidth = 0.5;
          ctx.strokeStyle = activeToolTipColor;
          ctx.stroke();
          ctx.restore();

          // draw Circle/
          /*TODO: strange test case:
              comment the code below, keep it in the redefined Chart.controllers.bar.prototype.draw;
              Open graph with LineWithLine - only -> ER: only standard hover circle is displayed on hover. AR = ER
              Open graph with LineWithLine AND bar -> 
                ER:
                - line chart (only standard hover circle is displayed on hover), 
                - bar chart custom circle is displayed
                AR:
                - bar chart = ER
                - line chart - both hover and custom circle are displayed on hover
              */
          ctx.save();
          ctx.beginPath();
          ctx.arc(activePointToolTipPosition.x, activePointToolTipPosition.y, 3, 0, 2 * Math.PI);
          ctx.fillStyle = activeToolTipColor;
          ctx.fill();
          ctx.stroke();
        }
      },
    });
    Chart.controllers.LineWithLine = customLineWithLine;

    // Chart.defaults.BarWithLine = Chart.defaults.bar;

    // TODO: see Bar Controller comment - https://www.chartjs.org/docs/latest/developers/charts.html
    // because of this new bar property must be attached to the dataset, not sure how to do it at this stage
    const originalDrawBar = Chart.controllers.bar.prototype.draw;
    // TODO: need to redefine this once, currently it is redefined every time chart is rendered, thus original is no longer original on 2nd run
    Chart.controllers.bar.prototype.draw = function (ease: any) {
      originalDrawBar.call(this, ease);

      if (this.chart.tooltip._active && this.chart.tooltip._active.length) {
        const ctx = this.chart.ctx;

        const activePoint = this.chart.tooltip._active[0];
        const activeToolTipColor = activePoint._model.borderColor;

        const activePointToolTipPosition = activePoint.tooltipPosition();

        const topY = this.chart.chartArea.top; // activePoint.tooltipPosition().y - 20;
        const bottomY = this.chart.chartArea.bottom; // this.chart.scales[scaleName].bottom;

        // draw line
        ctx.save();
        ctx.beginPath();
        ctx.moveTo(activePointToolTipPosition.x, topY);
        ctx.lineTo(activePointToolTipPosition.x, bottomY);
        ctx.lineWidth = 0.5;
        ctx.strokeStyle = activeToolTipColor;
        ctx.stroke();
        ctx.restore();

        // draw Circle
        ctx.save();
        ctx.beginPath();
        ctx.arc(activePointToolTipPosition.x, activePointToolTipPosition.y, 3, 0, 2 * Math.PI);
        ctx.fillStyle = activeToolTipColor;
        ctx.fill();
        ctx.stroke();
      }
    };

    const configuredYAxis = getAttributeByPath(chartOptions, "scales.yAxes");
    if (isNonEmptyArray(configuredYAxis) && isNonEmptyArray(chartData.datasets)) {
      chartData = {
        datasets: chartData.datasets.filter((item: any) =>
          configuredYAxis.map((item: any) => item.id).includes(item.yAxisID)
        ),
        labels: chartData.labels,
      };
    }

    this._chart = new Chart(myChartRef, {
      type: "line", // this.props.chartType, // 'BarWithLine', // 'LineWithLine', // this.props.chartType,
      data: chartData,
      options: chartOptions,
      plugins: [joinSegmentsPlugin],
      // @ts-ignore TODO: typefy. Ignoring because this is a custom type for a plugin which does not exist on the core Chart.js
      joinSegmentDots: this.props.joinSegmentsConfig,
      // NOTE: The following block demonstrates how to use custom built vertical line plugin
      // plugins: [verticalLinePlugin],
      // For GLOBAL plugins Use Chart.plugins.register(verticalLinePlugin); Don't forget to include once only
      // @ ts-ignore TODO: typefy. Ignoring because this is a custom type for a plugin which does not exist on the core Chart.js
      // NOTE: as an Example lineAtDataPointIndex: {pointsByIndex: [50, 100], label: 'Data Points'} as VerticalLinePluginType,
      // @ts-ignore TODO: typefy. Ignoring because this is a custom type for a plugin which does not exist on the core Chart.js
      // lineAtTickPointIndex: {pointsByValue: [startOfDayDate(new Date()).getTime()],
      // label: 'TODAY', lineConfig: {lineColor: 'blue', labelColor: 'green', labelAlignment: 'left'}} as VerticalLinePluginType
    });

    moment.tz.setDefault();
  }

  render() {
    return (
      <div>
        <canvas id="myChart" ref={this._chartRef} />
      </div>
    );
  }
}

export default GraphWidget;
