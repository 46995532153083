import { TableCellRendererProps } from "./TableCellRenderer.d";
import React from "react";
import { hasAttributeByPath, getAttributeByPath } from "components/Crud/EntityUtils";
import { MenuItemType } from "components/Crud/Form/Fields/FieldConfiguration.d";
import { isSpecified } from "components/Utils/MiscUtils";
import { getDictionaryLabelByCode } from "components/Dictionaries/DictionariesUtils";

export interface SimpleEnumProps {
  options: MenuItemType[];
}

export function SimpleEnum(props: TableCellRendererProps & SimpleEnumProps) {
  let value = "";

  if (hasAttributeByPath(props.obj, props.fieldName)) {
    const code = getAttributeByPath(props.obj, props.fieldName);

    if (isSpecified(code)) {
      value = getDictionaryLabelByCode(props.options, code);
    }
  }

  return <div>{value}</div>;
}
