import React from "react";
import { FormLayoutProps } from "components/Crud/Form/Layout/FormLayout.d";
import { FormLayoutElement } from "components/Crud/Form/Layout/FormLayoutElement";
import { CURRENT_PASSWORD_KEY, NEW_PASSWORD_KEY, CONFIRM_PASSWORD_KEY } from "./ChangeUserPasswordForm";

export function ChangeUserPasswordFormLayout(props: FormLayoutProps) {
  return (
    <div>
      <div>
        <b>{"Username: "}</b>
        {props.extraProps ? props.extraProps.username : "username not provided"}
      </div>
      <FormLayoutElement fieldId={CURRENT_PASSWORD_KEY} {...props} />
      <FormLayoutElement fieldId={NEW_PASSWORD_KEY} {...props} />
      <FormLayoutElement fieldId={CONFIRM_PASSWORD_KEY} {...props} />
    </div>
  );
}
