import { grey } from "@material-ui/core/colors";
import { StyleRulesCallback, Theme } from "@material-ui/core";
import { defaultFont } from "assets/jss/material-dashboard-react";

const entityTableRowsetStyle: StyleRulesCallback<Theme, {}> = (theme) => ({
  regularTableCell: {
    display: "table-cell",
    verticalAlign: "middle",
    height: "71px",
    boxShadow: "inset 0 -1px 0 0 #EEEEEE",
  },
  defaultCellFont: {
    ...defaultFont,
    color: theme.palette.text.primary,
    fontSize: "14px",
    fontWeight: 400,
  },
  cardColumn: {
    flex: "0 0 50%",
  },
  divRow: {
    display: "flex",
    flexDirection: "row",
  },
  transparentPaper: {
    backgroundColor: "transparent",
  },
  cardRowWithGutter: {
    marginBottom: "20px",
  },
  root: {
    "&$selected": {
      backgroundColor: grey[200], // lightBlue[100]
    },
    "&$hover:hover": {
      backgroundColor: grey[100], // lightBlue[50]
    },
    "&$hover:hover&$selected": {
      backgroundColor: grey[200], // lightBlue[100]
    },
  },
  tableWithGuttersRow: {
    backgroundColor: "white",
  },
  selected: {},
  hover: {},
  noWrapColumnContent: {
    whiteSpace: "nowrap",
  },
  mdSpecCellPaddingForMuiPaddingNone: {
    paddingTop: "6px",
    paddingRight: "16px",
    paddingLeft: "16px",
    paddingBottom: "6px",
    boxSizing: "border-box",
  },
  zeroPadding: {
    padding: 0,
  },
});

export default entityTableRowsetStyle;
