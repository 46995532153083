import React, { useEffect, useState, useMemo } from "react";
import { FieldConfiguration } from "components/Crud/Form/Fields/FieldConfiguration.d";
import { SAMPLEPOINT_API_URL } from "./SamplePoint.const";
import { API_LIB } from "components/Utils/API_LIB";
import { DataCrudEntityFormBinding } from "components/Crud/Form/DataCrudEntityFormBinding";
import { FetchResultType, OPERATION_SUCCESS, SORT_ASC } from "components/Utils/CRUDUtils.d";
import { fetchEntityListByQuery } from "components/Utils/CRUDUtils";
import { ObjectIdType } from "components/Utils/Object.d";
import { isSpecified, filterBy } from "components/Utils/MiscUtils";
import { hasAttributeByPath, getAttributeByPath } from "components/Crud/EntityUtils";
import { SamplePointConfigurationLayout } from "./SamplePointConfigurationLayout";
import { ALL_ENTERPRISES_CODE } from "views/Enterprise/Enterprise.const";
import { SampleTypeEnum } from "components/Dictionaries/Dictionaries.d";
import { ConfigRequestType } from "./SamplePoint.d";
import { SAMPLE_TYPE_API } from "./SamplePoint.const";
import { EntityFormParams } from "components/Crud/Form/EntityForm.d";

export interface SamplePointEditFormProps {
  assetId: number;
}

// TODO: check whether it works correctly
export function SamplePointEditForm(props: EntityFormParams & SamplePointEditFormProps) {
  const isSuperAdmin = props.userData.isSuperAdmin;

  const baseFieldConfig: FieldConfiguration[] = useMemo(
    () => [
      {
        fieldName: "assetId",
        label: "Asset",
        defaultValue: props.assetId,
        // disabled: true,
        hideIf: () => !(isSuperAdmin === true),
        type: "select-async",
        fieldParams: {
          optionDefinitionEndpoint: API_LIB.ASSET_GROUP_ASSET,
          optionCodeField: "id",
          optionLabelField: "name",
          autoComplete: isSuperAdmin,
          filterUrl: isSpecified(props.assetId)
            ? `?filter=id::eq::${props.assetId}`
            : props.currentEnterpriseId !== ALL_ENTERPRISES_CODE
            ? `?filter=site.enterpriseId::eq::${props.currentEnterpriseId}`
            : undefined,
        },
      },
      {
        fieldName: "name",
        label: "Name",
        type: "text",
      },
      {
        fieldName: "sampleTypeId",
        label: "Type",
        type: "select-async", // TODO create variables
        fieldParams: {
          optionDefinitionEndpoint: SAMPLE_TYPE_API,
          optionCodeField: "id",
          optionLabelField: "name",
        },
      },
    ],
    [props.assetId, props.currentEnterpriseId, isSuperAdmin]
  );

  const [fieldConfigs, setFieldConfigs] = useState(baseFieldConfig);

  const [requestedType, setRequestedType] = useState({
    requestedTypeId: undefined,
    presetValues: undefined,
  } as ConfigRequestType);

  useEffect(() => {
    const performFieldConfigurationReset = async (request: ConfigRequestType) => {
      // TODO: move outside of the use effect by properly managing a dependensy and break down into parts
      // TODO: this implementation of fields configuration is a wild hack caused by Formik concept and limitiation of Formik 1.x version.
      // Upgrade Formik and Redo properly

      const sampleTypeId: ObjectIdType = request.requestedTypeId;

      const result: FetchResultType = await fetchEntityListByQuery(
        {
          fieldList: ["sampleFields.id", "sampleFields.name", "sampleFields.units.abbreviation"],
          filter: {
            baseFilters: [filterBy("id", "=", sampleTypeId)],
          },
          sortByColumn: "sampleFields.id",
          sortDirection: SORT_ASC,
        },
        SAMPLE_TYPE_API
      );

      if (result.status.type === OPERATION_SUCCESS) {
        const sampleFieldsArray = result.data[0].sampleFields;

        let newFieldConfig = baseFieldConfig.slice();

        newFieldConfig.forEach((configItem, index) => {
          if (hasAttributeByPath(request.presetValues, configItem.fieldName)) {
            newFieldConfig[index].defaultValue = getAttributeByPath(
              request.presetValues,
              configItem.fieldName
            );
          }
        });

        sampleFieldsArray.forEach((sampleFieldItem: any, index: number) => {
          const unitsName = getAttributeByPath(sampleFieldItem, "units.abbreviation");

          newFieldConfig = newFieldConfig.concat(
            {
              fieldName: `fieldConfigurations[${index}].sampleFieldId`,
              label: `Sample Field Id`, //  `${sampleFieldItem.name}
              groupName: sampleFieldItem.name,
              defaultValue: sampleFieldItem.id,
              disabled: true,
              type: "number",
            },
            {
              fieldName: `fieldConfigurations[${index}].id`,
              label: `Id`,
              groupName: sampleFieldItem.name,
              disabled: true,
              type: "number",
            },
            {
              fieldName: `fieldConfigurations[${index}].params.offset`,
              label: `offset (${unitsName})`,
              defaultValue: 0,
              hideIf: () => !isSuperAdmin,
              groupName: sampleFieldItem.name,
              type: "number",
            },
            {
              fieldName: `fieldConfigurations[${index}].params.minValue`,
              label: `minValue (${unitsName})`,
              defaultValue: 0,
              hideIf: () => !isSuperAdmin,
              groupName: sampleFieldItem.name,
              type: "number",
            },
            {
              fieldName: `fieldConfigurations[${index}].params.maxValue`,
              label: `maxValue (${unitsName})`,
              groupName: sampleFieldItem.name,
              type: "number",
            }
          );

          if (sampleTypeId === SampleTypeEnum.LEVEL) {
            newFieldConfig = newFieldConfig.concat({
              fieldName: `fieldConfigurations[${index}].params.totalCapacity`,
              label: `totalCapacity (Litres)`,
              groupName: sampleFieldItem.name,
              type: "number",
            });
          }
        });

        setFieldConfigs(newFieldConfig);
      }
    };

    if (requestedType.requestedTypeId) {
      performFieldConfigurationReset(requestedType);
    }
  }, [requestedType, baseFieldConfig, isSuperAdmin]);

  const dataSource = {
    apiUrl: SAMPLEPOINT_API_URL,
    // customUrlRequestFragment: `groupId=${props.assetGroupId}`
  };

  const conditionalFieldConfiguration = (request: Record<string, any>) => {
    if (isSpecified(request.sampleTypeId) && request.sampleTypeId !== requestedType.requestedTypeId) {
      setTimeout(() => setRequestedType({ requestedTypeId: request.sampleTypeId, presetValues: request }));
    }
  };

  return (
    <DataCrudEntityFormBinding
      id={props.id} // TODO: do we need this???
      dataSource={dataSource}
      action={props.action}
      title={props.title}
      fieldConfigurations={fieldConfigs}
      onCloseAdditionalActions={props.onCloseAdditionalActions}
      showCloseButton={props.showCloseButton}
      userData={props.userData}
      currentEnterpriseId={props.currentEnterpriseId}
      conditionalFieldConfiguration={conditionalFieldConfiguration}
      layoutComponent={SamplePointConfigurationLayout}
    />
  );
}
