import React from "react";
import clsx from "clsx";
import { TableHead, TableRow, Hidden, TableCell, TableSortLabel, makeStyles } from "@material-ui/core";
import { TableColumnParameters } from "../EntityTable.d";
import tableStyle from "../tableStyle";
import { Order } from "components/Utils/CRUDUtils.d";

export interface EntityTableColumnHeadProps {
  sortByColumn?: string;
  sortDirection?: Order;
  handleSort: (sortColumn: string) => void;
  columns: TableColumnParameters[];
}

const useStyles = makeStyles(tableStyle, { name: "EntityTableColumnHead" });

export function EntityTableColumnHead(props: EntityTableColumnHeadProps) {
  const classes = useStyles({});

  return (
    <TableHead>
      <TableRow className={clsx(classes.tableHeaderRow, classes.tableHeaderRoundedChildCells)}>
        {props.columns
          .filter((columnItem) => !columnItem.hide)
          .map((column: TableColumnParameters, index: number) => (
            <Hidden {...column.hideForBreakpoints} key={index}>
              <TableCell
                align={column.align}
                className={clsx(column.noWrapColumnHeader && classes.noWrapColumnHeader)}
                classes={{ paddingNone: classes.mdSpecCellPaddingForMuiPaddingNone }}
                style={{ width: column.width }}
              >
                {column.sourceName && typeof column.sourceName === "string" ? (
                  column.disableSorting ? (
                    <span className={classes.tableHeadCell}>{column.label} </span>
                  ) : (
                    <TableSortLabel
                      active={column.sourceName === props.sortByColumn}
                      direction={props.sortDirection}
                      onClick={() => props.handleSort(column.sourceName as string)}
                    >
                      <span className={classes.tableHeadCell}>{column.label} </span>
                    </TableSortLabel>
                  )
                ) : undefined}
              </TableCell>
            </Hidden>
          ))}
      </TableRow>
    </TableHead>
  );
}
