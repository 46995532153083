import React from "react";
import { amber } from "@material-ui/core/colors";
import OpacityTwoToneIcon from "@material-ui/icons/OpacityTwoTone";
import { RadioOptionType } from "components/Radio/CustomRadio";
import { IconButton, Tooltip, Box, Button } from "@material-ui/core";
import BorderAll from "@material-ui/icons/BorderAll";
import ViewModuleIcon from "@material-ui/icons/ViewModule";
import ViewStreamOutlinedIcon from "@material-ui/icons/ViewStreamOutlined";
import { OutlinedBadge } from "components/Elements/Badge/OutlinedBadge";
import { globalMuiTheme } from "assets/jss/muiTheme";
import OpacityIcon from "@material-ui/icons/Opacity";
import { ReactComponent as MapIcon } from "assets/icons/ProfessionalSet/Map.svg";
import { ReactComponent as WaterTankIcon } from "assets/icons/ProfessionalSet/WaterTank.svg";
import { ReactComponent as ElectricFenceIcon } from "assets/icons/ProfessionalSet/ElectricFence.svg";
import { ReactComponent as TroughIcon } from "assets/icons/ProfessionalSet/Trough.svg";
import { ReactComponent as PaddockIcon } from "assets/icons/ProfessionalSet/Paddock.svg";
import { ReactComponent as PressureIcon } from "assets/icons/ProfessionalSet/Pressure.svg";
import { ReactComponent as FlowIcon } from "assets/icons/ProfessionalSet/Flow.svg";
import { TableBodyLayoutType } from "components/Crud/Table/EntityTable.d";
import { assetRoundedRectangleBanner } from "components/Elements/DynamicImages/AssetRoundedRectangleBanner";

import { RainFallComponent } from "assets/icons/ProfessionalSet/RainFallComponent";
import { PressureComponent } from "assets/icons/ProfessionalSet/PressureComponent";
import { FlowComponent } from "assets/icons/ProfessionalSet/FlowComponent";
import { SampleTypeEnum, AssetTypeEnum, EventLevel } from "./Dictionaries.d";

// TODO: This is an obsolete dictionary.
export const sampleTypeIconDictionary = [
  {
    code: 2,
    label: "Water Level",
    labelElement: <OpacityTwoToneIcon fontSize="small" htmlColor={globalMuiTheme.palette.text.secondary} />,
  },
  {
    code: 6,
    label: "Pressure",
    labelElement: <PressureIcon />,
  },
  {
    code: 7,
    label: "Flow",
    labelElement: <FlowIcon />,
  },
];

export const SAMPLE_POINT_TYPES = [
  {
    code: SampleTypeEnum.BATTERY,
    label: "Battery" /* iconFunction: (htmlColor: string) => (<OpacityIcon htmlColor={htmlColor}/>) */,
  },
  {
    code: SampleTypeEnum.LEVEL,
    label: "Level",
    iconFunction: (htmlColor: string) => <OpacityIcon htmlColor={htmlColor} />,
  },
  {
    code: SampleTypeEnum.LOCATION,
    label: "Location" /* iconFunction: (htmlColor: string) => (<OpacityIcon htmlColor={htmlColor}/>) */,
  },
  {
    code: SampleTypeEnum.RAIN_FALL,
    label: "Rain Fall",
    iconFunction: (htmlColor: string) => <RainFallComponent htmlColor={htmlColor} />,
  },
  {
    code: SampleTypeEnum.ELECTRIC_FENCE_POWER,
    label:
      "Electric Fence Power" /* iconFunction: (htmlColor: string) => (<OpacityIcon htmlColor={htmlColor}/>) */,
  },
  {
    code: SampleTypeEnum.PRESSURE,
    label: "Pressure",
    iconFunction: (htmlColor: string) => <PressureComponent htmlColor={htmlColor} />,
  },
  {
    code: SampleTypeEnum.FLOW,
    label: "Flow",
    iconFunction: (htmlColor: string) => <FlowComponent htmlColor={htmlColor} />,
  },
];

export const ASSET_TYPES = [{ code: 1, label: "Water Tank" }];
export const assetTypeMarkerSvgDictionary = [
  {
    code: AssetTypeEnum.WATER_TANK,
    svgCode: '<svg xmlns="http://www.w3.org/2000/svg" height="48" width="24"><path d="M 23,15 C 23,29 13,44 12,46 11,44 1,29 1,15 1,-3.0000002 23,-3.0000002 23,15 Z" fill="navy" stroke="#000"/><path d="m 9.2750583,18.797272 c 1.2029997,0 1.7109997,-1.194 1.1789997,-2.125 l -1.1389997,-2.008 -1.218,2.008 c -0.602,1.108 0.076,2.125 1.178,2.125 z M 19.664058,9.8482718 V 19.999272 c 0,2.166 -15.2669997,2.166 -15.2669997,0 V 9.8482718 c 0.055,-2.089 15.3209997,-2.081 15.2669997,0 z m -15.1059997,0.124 c 1.623,1.6300002 14.9599997,1.5200002 15.0099997,-0.349" fill="none" stroke="#fff" stroke-width="1.5" stroke-miterlimit="10"/></svg>'.replace(
      /navy/g,
      "#307FBE"
    ),
  },
  {
    code: AssetTypeEnum.ELECTRIC_FENCE,
    svgCode: '<svg xmlns="http://www.w3.org/2000/svg" height="48" width="24"><path d="M 23,15 C 23,29 13,44 12,46 11,44 1,29 1,15 1,-3.0000002 23,-3.0000002 23,15 Z" fill="navy" stroke="#000"/><path d="M 5.429,11.127 V 23.171 M 18.465,11.127 V 23.171 M 2.429,19.506 H 21.464 M 9.495,15.08 H 2.429 m 19.036,0 h -9.06 m 0.316,-5.13 h 0.966 l -1.732,2.496 0.512,-1.892 h -0.905 l 1.063,-2.929 h 1.022 z" fill="none" stroke="#fff" stroke-width="1.5" stroke-miterlimit="10"/></svg>'.replace(
      /navy/g,
      "#307FBE"
    ),
  },
  {
    code: AssetTypeEnum.PADDOCK,
    svgCode: '<svg xmlns="http://www.w3.org/2000/svg" height="48" width="24"><path d="M 23,15 C 23,29 13,44 12,46 11,44 1,29 1,15 1,-3.0000002 23,-3.0000002 23,15 Z" fill="navy" stroke="#000"/><path d="M 11.515,21.748 3.197,14.937 12,9 l 8.318,5.937 z m -3.848,-3.469 8.664,-6.465 m -8.504,0 8.344,6.331" fill="none" stroke="#fffffd" stroke-width="1.5" stroke-miterlimit="10"/></svg>'.replace(
      /navy/g,
      "#307FBE"
    ),
  },
  {
    code: AssetTypeEnum.TROUGH,
    svgCode: '<svg xmlns="http://www.w3.org/2000/svg" height="48" width="24"><path d="M 23,15 C 23,29 13,44 12,46 11,44 1,29 1,15 1,-3.0000002 23,-3.0000002 23,15 Z" fill="navy" stroke="#000"/><path d="M 18.2195,20.282 H 5.1235004 l -1.202,-6.511 H 19.9875 Z m -6.067,-6.762 2.035,-3.77 h 4.142 l 1.972,3.625" fill="none" stroke="#fff" stroke-width="1.5" stroke-miterlimit="10"/></svg>'.replace(
      /navy/g,
      "#307FBE"
    ),
  },
];

export const assetTypeMarkerCanvasDictionary = [
  {
    code: AssetTypeEnum.WATER_TANK,
    canvasMarker: assetRoundedRectangleBanner, // canvasFarmbotWaterDropMarker, // canvasWaterTankMarker
  },
  {
    code: AssetTypeEnum.ELECTRIC_FENCE,
    canvasMarker: assetRoundedRectangleBanner, // canvasFarmbotWaterDropMarker, // canvasElectricFenceMarker
  },
  {
    code: AssetTypeEnum.PADDOCK,
    canvasMarker: assetRoundedRectangleBanner, // canvasFarmbotWaterDropMarker, // canvasPaddockMarker
  },
  {
    code: AssetTypeEnum.TROUGH,
    canvasMarker: assetRoundedRectangleBanner, // canvasFarmbotWaterDropMarker, // canvasTroughMarker
  },
];

export const assetTypeIconDictionaryWithTooltips = [
  {
    code: AssetTypeEnum.WATER_TANK,
    label: "Water Tank",
    labelElement: (
      <Tooltip title="Water Tank" arrow>
        <Box>
          <WaterTankIcon />
        </Box>
      </Tooltip>
    ),
    pureIcon: <WaterTankIcon />,
  },
  {
    code: AssetTypeEnum.ELECTRIC_FENCE,
    label: "Electric Fence",
    labelElement: (
      <Tooltip title="Electric Fence" arrow>
        <Box>
          <ElectricFenceIcon />
        </Box>
      </Tooltip>
    ),
    pureIcon: <ElectricFenceIcon />,
  },
  {
    code: AssetTypeEnum.PADDOCK,
    label: "Paddock",
    labelElement: (
      <Tooltip title="Paddock" arrow>
        <Box>
          <PaddockIcon />
        </Box>
      </Tooltip>
    ),
    pureIcon: <PaddockIcon />,
  },
  {
    code: AssetTypeEnum.TROUGH,
    label: "Trough",
    labelElement: (
      <Tooltip title="Trough" arrow>
        <Box>
          <TroughIcon />
        </Box>
      </Tooltip>
    ),
    pureIcon: <TroughIcon />,
  },
];

// TODO: add support for label element to the drop down list

export const priorityDictionary = [
  /*   {
    code: EventLevel.INFO, 
    label: 'Info', 
    labelElement: <OutlinedBadge htmlColor={globalMuiTheme.palette.info.main}>{'INFO'}</OutlinedBadge>
  }, */
  {
    code: EventLevel.WARNING,
    label: "Warning",
    labelElement: <OutlinedBadge htmlColor={amber[500]}>{"WARNING"}</OutlinedBadge>,
    colorCode: amber[500],
    textColor: "black",
  },
  {
    code: EventLevel.ERROR,
    label: "Urgent",
    labelElement: <OutlinedBadge htmlColor={globalMuiTheme.palette.warning.main}>{"URGENT"}</OutlinedBadge>,
    colorCode: globalMuiTheme.palette.warning.main,
    textColor: "white",
  },
  {
    code: EventLevel.CRITICAL,
    label: "Critical",
    labelElement: <OutlinedBadge htmlColor={globalMuiTheme.palette.error.main}>{"CRITICAL"}</OutlinedBadge>,
    colorCode: globalMuiTheme.palette.error.main,
    textColor: "white",
  },
];

export const dataDisplayOptions: Array<RadioOptionType> = [
  // TODO: typefy option type
  {
    code: TableBodyLayoutType.FULL_WIDTH_CARD_SINGLE,
    label: undefined,
    labelElement: (
      <Button>
        <ViewStreamOutlinedIcon fontSize="small" />
        <span style={{ paddingLeft: "8px", whiteSpace: "nowrap" }}>LIST</span>
      </Button>
    ),
  },
  {
    code: TableBodyLayoutType.FULL_WIDTH_CARD_MULTI,
    label: undefined,
    labelElement: (
      <IconButton size="medium">
        <ViewModuleIcon fontSize="small" />
      </IconButton>
    ),
  },
  {
    code: TableBodyLayoutType.FULL_WIDTH_HTML,
    label: undefined,
    labelElement: (
      <Button>
        <BorderAll fontSize="small" />
        <span style={{ paddingLeft: "8px", whiteSpace: "nowrap" }}>TABLE</span>
      </Button>
    ),
  },
  {
    code: TableBodyLayoutType.HALF_WIDTH_INJECTED_CONTENT,
    label: undefined,
    labelElement: (
      <IconButton size="medium">
        <MapIcon />
      </IconButton>
    ),
  },
  {
    code: TableBodyLayoutType.FULL_WIDTH_INJECTED_CONTENT,
    label: undefined,
    labelElement: (
      <Button>
        <MapIcon />
        <span style={{ paddingLeft: "8px", whiteSpace: "nowrap" }}>MAP</span>
      </Button>
    ),
  },
];
