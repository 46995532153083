import produce from "immer";
import {
  FieldsInProgressStateType,
  FieldsInProgressActionType,
  FieldsInProgressActionTypeEnum,
} from "./EntityForm.d";
import { ReducerFunctionType } from "global.d";

export const fieldsInProgressReducer: ReducerFunctionType<
  FieldsInProgressStateType,
  FieldsInProgressActionType
> = (state, action) => {
  let result: any; // Record<string, number>;

  const actionPayload = action.payload;

  switch (action.type) {
    case FieldsInProgressActionTypeEnum.ADD:
      result = produce(state, (draftState: Record<string, number>) => {
        draftState[actionPayload.field] = actionPayload.timeStamp;
      });

      return result;
    case FieldsInProgressActionTypeEnum.REMOVE:
      result = produce(state, (draftState: Record<string, number>) => {
        if (draftState.hasOwnProperty(actionPayload.field)) {
          if (draftState[actionPayload.field] === actionPayload.timeStamp) {
            delete draftState[actionPayload.field];
          }
        }
      });

      return result;
    default:
      return state;
  }
};
