import React, { useEffect, useRef } from "react";
import { FieldConfiguration } from "./Fields/FieldConfiguration.d";
import { EntityActionType } from "../Entity.d";
import { LayoutComponentType } from "./Layout/FormLayout.d";
import { DefaultFormLayout } from "./Layout/DefaultFormLayout";
import { useActiveUser } from "components/Utils/CustomHooks";
import { ObjectIdType } from "components/Utils/Object.d";
import {
  SetFieldValueDirectFunctionType,
  DispatchFieldsInProgressFunctionType,
  NotifyOnValidValuesChangeFunctionType,
} from "./EntityForm.d";

export interface BlockOfFormikFormFieldsProps {
  values: Record<string, any>;
  initialValues: Record<string, any>;
  errors: Record<string, any>;
  handleChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;

  fieldsToShow: Record<string, boolean>;
  fieldConfigurations: FieldConfiguration[];
  editModeOn: boolean;
  action: EntityActionType;

  layoutComponent?: LayoutComponentType;
  id?: ObjectIdType;
  setFieldValueDirect: SetFieldValueDirectFunctionType;
  notifyOnValidValuesChange?: NotifyOnValidValuesChangeFunctionType;
  dispatchFieldsInProgress: DispatchFieldsInProgressFunctionType;
}

export function BlockOfFormikFormFields(props: BlockOfFormikFormFieldsProps) {
  const displayedFields = props.fieldConfigurations.filter((fieldConfig) => {
    return props.fieldsToShow[fieldConfig.fieldName];
  });

  const RendererComponent = props.layoutComponent || DefaultFormLayout;

  const userData = useActiveUser();

  const localValues = useRef(props.values);
  const propsValues = props.values;
  const propsNotifyOnValidValuesChange = props.notifyOnValidValuesChange;
  const errorsCount = Object.entries(props.errors || {}).length;
  useEffect(() => {
    // We seriously rely on notifyValuesOnChange and local values immutability
    if (localValues.current !== propsValues && propsNotifyOnValidValuesChange && errorsCount === 0) {
      localValues.current = propsValues;

      propsNotifyOnValidValuesChange(propsValues);
    }
  }, [propsValues, propsNotifyOnValidValuesChange, errorsCount]);

  return (
    <React.Fragment>
      <RendererComponent
        values={props.values}
        initialValues={props.initialValues}
        errors={props.errors}
        fieldConfigurations={displayedFields}
        handleChange={props.handleChange}
        action={props.action}
        editModeOn={props.editModeOn}
        userData={userData}
        id={props.id}
        setFieldValueDirect={props.setFieldValueDirect}
        dispatchFieldsInProgress={props.dispatchFieldsInProgress}
      />
    </React.Fragment>
  );
}
