import React, { CSSProperties } from "react";
import { makeStyles } from "@material-ui/core";
import { columnLayoutStyle } from "./columnLayoutStyle";
import { SpacingNameType } from "assets/jss/muiTheme";
import clsx from "clsx";

export interface ColumnLayoutProps {
  styleProps?: CSSProperties;
  children: JSX.Element | Array<JSX.Element>;
  spacingName?: SpacingNameType;
  equalWidth?: boolean;
  height?: string;
  style?: Record<string, any>;
}

const useStyles = makeStyles(columnLayoutStyle, { name: "ColumnLayout" });

const defaultSpacing: SpacingNameType = "noSpacing";

export function ColumnLayout(props: ColumnLayoutProps) {
  const spacingName = props.spacingName || defaultSpacing;
  const classes = useStyles(
    Object.assign({ spacingName: spacingName, height: props.height }, props.styleProps)
  );

  // TODO: should we prewrap components in a ColumnWrapper?

  return (
    <div className={clsx(classes.root, props.equalWidth && classes.equalWidth)} style={props.style}>
      {props.children}
    </div>
  );
}
