import { Order, SORT_ASC } from "./CRUDUtils.d";
import { isNonEmptyArray, isSpecified } from "./MiscUtils";
import { getAttributeByPath, setAttributeByPath } from "components/Crud/EntityUtils";
import produce from "immer";
import { ObjectWithIdFieldType } from "./Object.d";
import {
  ETLInstructionType,
  SingleInputFunction,
  MultipleInputFunction,
  DistinctCountResultType,
} from "./ObjectUtils.d";

export function extractFieldsToList(anyObjArray: Array<Record<string, any>>, fieldName: string) {
  if (anyObjArray) {
    const conversionFunction = (inputObject: Record<string, any>) => getAttributeByPath(inputObject, fieldName);

    return anyObjArray.map((anyObj: ObjectWithIdFieldType) => conversionFunction(anyObj));
  } else {
    return [];
  }
}

export function mergeScalarArrayToObjectArray(
  objectArray: Array<Record<string, any>>,
  fieldPath: string,
  scalarArray: Array<any>
) {
  if (objectArray.length > scalarArray.length) {
    throw new Error("Scalar array cannot be bigger than object array");
  }

  const resultObjectArray = objectArray.slice();

  for (let i = 0; i < resultObjectArray.length; i++) {
    setAttributeByPath(resultObjectArray[i], fieldPath, scalarArray[i]);
  }

  return resultObjectArray;
}

// objectArrayB - can be zero length
export function mergeObjectArrays(
  objectArrayA: Array<Record<string, any>>,
  objectArrayB: Array<Record<string, any>>
) {
  if (objectArrayB.length > 0 && objectArrayA.length !== objectArrayB.length) {
    throw new Error("mergeObjectArrays: both arrays must be of the same length");
  }

  const resultObjectArray = objectArrayA.map((arrayItem: Record<string, any>, index: number) => {
    return Object.assign({}, arrayItem, objectArrayB[index]);
  });

  return resultObjectArray;
}

export const etlConst = (value: any) => {
  return () => value;
};

export const etlNonSpecifiedDefault = (defaultValue: any) => {
  return (givenValue: any) => (isSpecified(givenValue) ? givenValue : defaultValue);
};

function generateSpreadArgumentsFlagArray(etlInstructions: Array<ETLInstructionType>) {
  const result = etlInstructions
    .map((etlItem) => etlItem.e)
    .map((extractInstructionItem) => {
      if (isSpecified(extractInstructionItem) && Array.isArray(extractInstructionItem)) {
        return true;
      } else {
        return false;
      }
    });

  return result;
}

function generateExtractFunctionsArray(etlInstructions: Array<ETLInstructionType>) {
  const result = etlInstructions
    .map((etlItem) => etlItem.e)
    .map((extractInstructionItem) => {
      if (!isSpecified(extractInstructionItem)) {
        return (): any => undefined;
      } else if (Array.isArray(extractInstructionItem)) {
        return (inputObject: Record<string, any>) =>
          (extractInstructionItem).map((fieldPathItem) => {
            return getAttributeByPath(inputObject, fieldPathItem);
          });
      } else {
        // string
        return (inputObject: Record<string, any>) =>
          getAttributeByPath(inputObject, extractInstructionItem);
      }
    });

  return result;
}

function generateTransformFunctionsArray(etlInstructions: Array<ETLInstructionType>) {
  const result = etlInstructions
    .map((etlItem) => etlItem.t)
    .map((transformExtractionItem: SingleInputFunction | MultipleInputFunction, index: number) => {
      if (!isSpecified(transformExtractionItem)) {
        return (value: any) => value;
      }
      if (Array.isArray(etlInstructions[index].e)) {
        return (...values: any) => (transformExtractionItem as MultipleInputFunction)(...values);
      } else {
        return (value: any) => transformExtractionItem(value);
      }
    });

  return result;
}

function generateLoadFunctionsArray(etlInstructions: Array<ETLInstructionType>) {
  const result = etlInstructions
    .map((etlItem) => etlItem.l)
    .map((loadInstructionItem: string, index: number) => {
      const loadPath = loadInstructionItem || (etlInstructions[index].e as string);

      if (typeof loadPath !== "string") {
        throw new Error(`arrayETL:Load path is incorrect ${loadPath}`);
      }

      return (outputObject: Record<string, any>, value: any) =>
        setAttributeByPath(outputObject, loadPath, value);
    });

  return result;
}

// This function mutates target
function applyETLtoRecord(
  source: Record<string, any>,
  target: Record<string, any>,
  extractFunction: (inputObject: Record<string, any>) => any,
  spreadTransformationArguments: boolean,
  transformFunction: SingleInputFunction | MultipleInputFunction, // (...values: any) => any,
  loadFunction: (outputObject: Record<string, any>, value: any) => any
) {
  const values = extractFunction(source);

  let value;
  let transformationFunction;

  if (spreadTransformationArguments) {
    transformationFunction = transformFunction as MultipleInputFunction;

    value = transformationFunction(...values);
  } else {
    transformationFunction = transformFunction as SingleInputFunction;
    value = transformationFunction(values);
  }
  loadFunction(target, value);
}

export function propagateObjectToArray(
  sourceObject: Record<string, any>,
  etlInstructions: Array<ETLInstructionType>,
  targetObjectArray: Array<Record<string, any>>
) {
  let resultArray: Array<Record<string, any>> = [];

  if (isNonEmptyArray(targetObjectArray)) {
    const extractFunctionsArray = generateExtractFunctionsArray(etlInstructions);

    const spreadArgumentsFlags = generateSpreadArgumentsFlagArray(etlInstructions);

    const transformFunctionsArray: Array<
      SingleInputFunction | MultipleInputFunction
    > = generateTransformFunctionsArray(etlInstructions);

    const loadFunctionsArray = generateLoadFunctionsArray(etlInstructions);

    resultArray = targetObjectArray.map((arrayItem: Record<string, any>) => {
      const resultItem = Object.assign({}, arrayItem);

      for (let i = 0; i < extractFunctionsArray.length; i++) {
        applyETLtoRecord(
          sourceObject,
          resultItem,
          extractFunctionsArray[i],
          spreadArgumentsFlags[i],
          transformFunctionsArray[i],
          loadFunctionsArray[i]
        );
      }

      return resultItem;
    });
  }

  return resultArray;
}

export function arrayETL<T>(
  inputArray: Array<Record<string, any>>,
  etlInstructions: Array<ETLInstructionType>
): Array<T> {
  let resultArray: Array<T> = []; // Array<Record<string, any>> = [];

  if (isNonEmptyArray(inputArray)) {
    const extractFunctionsArray = generateExtractFunctionsArray(etlInstructions);

    const spreadArgumentsFlags = generateSpreadArgumentsFlagArray(etlInstructions);

    const transformFunctionsArray: Array<
      SingleInputFunction | MultipleInputFunction
    > = generateTransformFunctionsArray(etlInstructions);

    const loadFunctionsArray = generateLoadFunctionsArray(etlInstructions);

    resultArray = inputArray.map((arrayItem: Record<string, any>) => {
      const resultItem = {} as T;

      for (let i = 0; i < extractFunctionsArray.length; i++) {
        applyETLtoRecord(
          arrayItem,
          resultItem,
          extractFunctionsArray[i],
          spreadArgumentsFlags[i],
          transformFunctionsArray[i],
          loadFunctionsArray[i]
        );
      }

      return resultItem;
    });
  }

  return resultArray;
}

export function cloneObject<T>(obj: T): T {
  return JSON.parse(JSON.stringify(obj));
}

export function compareObjects<T>(obj1: T, obj2: T): boolean {
  // TODO: support without stringify
  return JSON.stringify(obj1) === JSON.stringify(obj2);
}

export function twoArraysAreIdentical(arr1: Array<any>, arr2: Array<any>): boolean {
  // TODO: support without stringify

  if (Object.is(arr1, arr2)) {
    return true;
  }

  if ((isSpecified(arr1) && !isSpecified(arr2)) || (!isSpecified(arr1) && isSpecified(arr2))) {
    return false;
  }

  if (
    (Array.isArray(arr1) && !Array.isArray(arr2)) ||
    (!Array.isArray(arr1) && Array.isArray(arr2)) ||
    (!Array.isArray(arr1) && !Array.isArray(arr2))
  ) {
    return false;
  }

  if (arr1.length !== arr2.length) {
    return false;
  }

  const arrayLength = arr1.length;

  let itemByItemResult = true;
  for (let i = 0; i < arrayLength; i++) {
    if (Object.is(arr1[i], arr2[i])) {
      // Do nothing. For readability purposes
    } else if (Array.isArray(arr1[i]) && Array.isArray(arr2[i])) {
      itemByItemResult = twoArraysAreIdentical(arr1[i], arr2[i]) === false ? false : itemByItemResult;
    } else if (typeof arr1[i] === "object" && typeof arr2[i] === "object") {
      itemByItemResult = compareObjects(arr1[i], arr2[i]) === false ? false : itemByItemResult;
    } else {
      itemByItemResult = false;
    }
  }

  return itemByItemResult;
}

export function subtractScalarArrays(arr1: Array<any>, arr2: Array<any>) {
  return arr1.filter((arr1Value) => arr2.indexOf(arr1Value) === -1);
}

export const generateObjectTemplateFromKeysArray = (inputArray: Array<string>) => {
  const result: Record<string, any> = {};

  inputArray.forEach((keyItem) => {
    result[keyItem] = undefined;
  });

  return result;
};

export const sortObjectArrayBy = (
  inputArray: Array<Record<string, any>>,
  fieldName: string,
  sortDirection?: Order
) => {
  const resultArray = inputArray.slice();

  const multiplier = (sortDirection || SORT_ASC) === SORT_ASC ? 1 : -1;

  resultArray.sort((itemA, itemB) => {
    const result = (itemA[fieldName] - itemB[fieldName]) * multiplier;

    return result;
  });

  return resultArray;
};

export const distinctCount = (
  inputArray: Array<Record<string, any>>,
  fieldPath: string
): Array<DistinctCountResultType> => {
  const result: Array<DistinctCountResultType> = [];

  if (isNonEmptyArray(inputArray)) {
    inputArray.forEach((arrayItem) => {
      const keyValue = getAttributeByPath(arrayItem, fieldPath);

      const resultArrayKeyItem = result.find((item) => item.key === keyValue);

      if (isSpecified(resultArrayKeyItem)) {
        resultArrayKeyItem.count = resultArrayKeyItem.count + 1;
      } else {
        result.push({ key: keyValue, count: 1 });
      }
    });
  }

  return result;
};

//TODO: support multiple parameters
//TODO: support more complicated array logic. Currently it just joins them. look for twoArraysAreIdentical
//TODO: non-scalara arrays should be merged on PK (which could be a composite)
//TODO: typefy return signature based on generics
export const mergeRecords = (baseRecord: Record<string, any>, extraRecord: Record<string, any>) => {
  const result = produce(baseRecord, (draftBaseRecord) => {
    let objectKeys = Object.keys(extraRecord);
    objectKeys = objectKeys.filter((key) => extraRecord[key] !== undefined);

    objectKeys.forEach((key) => {
      const baseRecordPropertyType = typeof draftBaseRecord[key];
      const extraRecordPropertyType = typeof extraRecord[key];
      const baseRecordPropertyIsArray = Array.isArray(draftBaseRecord[key]);
      const extraRecordPropertyIsArray = Array.isArray(extraRecord[key]);

      if (draftBaseRecord.key !== undefined) {
        if (draftBaseRecord.key !== null && extraRecord.key !== null) {
          if (baseRecordPropertyType !== extraRecordPropertyType) {
            throw Error(`property type mismatch typeof baseRecord[${key}]=${baseRecordPropertyType}
            vs typeof extraRecord[${key}]=${extraRecordPropertyType}`);
          }
          if (baseRecordPropertyIsArray !== extraRecordPropertyIsArray) {
            throw Error(`property array type mismatch baseRecord[${key}].isArray=${baseRecordPropertyIsArray} 
            vs extraRecord[${key}].isArray=${extraRecordPropertyIsArray}`);
          }
        }
      }

      if (extraRecord.key === null) {
        draftBaseRecord[key] = null;
      } else if (["bigint", "number", "boolean", "null", "string"].includes(extraRecordPropertyType)) {
        draftBaseRecord[key] = extraRecord[key];
      } else if (extraRecordPropertyIsArray && isNonEmptyArray(extraRecord[key])) {
        if (!isSpecified(draftBaseRecord[key])) {
          draftBaseRecord[key] = extraRecord[key].slice();
        } else {
          draftBaseRecord[key] = draftBaseRecord[key].concat(extraRecord[key]);
        }
      } else if (extraRecordPropertyType === "object") {
        if (draftBaseRecord[key] === undefined) {
          draftBaseRecord[key] = {};
        }
        draftBaseRecord[key] = mergeRecords(draftBaseRecord[key], extraRecord[key]);
      }
    });
  });

  return result;
};
