import { StyleRulesCallback, Theme } from "@material-ui/core";

export const portletCardStyle: StyleRulesCallback<Theme, {}> = (theme) => ({
  root: {
    height: "100%",
  },
  paperRoot: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  header: {
    height: "56px",
    boxShadow: "inset 0 -1px 0 0 #EEEEEE",
    paddingLeft: "24px",
    display: "flex",
    alignItems: "center",
    boxSizing: "border-box",
  },
  content: {
    flexGrow: 1,
  },
  portletContentItem: {
    height: "78px",
    boxShadow: "inset 0 -1px 0 0 #EEEEEE",
    paddingLeft: "24px",
    paddingRight: "24px",
    display: "flex",
    alignItems: "center",
    boxSizing: "border-box",
  },
  footer: {
    height: "62px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    paddingRight: "24px",
  },
});
