import React from "react";
import { AssetCardCellContainer, CardCellLabelVariantType } from "./AssetCardCellContainer";
import { extractSamplePointsCurrentValues } from "views/asset/SamplePoint/SamplePointCommon";
import { CurrentValueCardBlock } from "./CurrentValueCardBlock";
import { useMediaQueryMatchDown } from "components/Utils/LayoutUtils";
import { SamplePointCurrentValueType, SamplePoint } from "views/asset/SamplePoint/SamplePoint.d";

export interface AssetMeasurementCardCellRendererProps {
  samplePoints: Array<SamplePoint>;
  borderRight: boolean;
  borderLeft: boolean;
}

export function AssetMeasurementCardCellRenderer(props: AssetMeasurementCardCellRendererProps) {
  const currentValues = extractSamplePointsCurrentValues(props.samplePoints);

  const isSmallScreen = useMediaQueryMatchDown("sm");

  return (
    <AssetCardCellContainer
      label="CURRENT VALUES"
      labelVariant={CardCellLabelVariantType.NORMAL}
      contentVerticalAlignment="middle"
      borderLeft={props.borderLeft}
      borderRight={props.borderRight}
    >
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {currentValues.map((valueItem: SamplePointCurrentValueType, index: number) => {
          return (
            <div
              key={index}
              style={{
                flexGrow: isSmallScreen ? 0.5 : undefined,
                flexBasis: isSmallScreen ? 0 : undefined,
                paddingLeft: index === 0 || isSmallScreen ? 0 : "4px",
                paddingRight: index === currentValues.length - 1 || isSmallScreen ? 0 : "4px",
                paddingTop: "4px",
                paddingBottom: "4px",
              }}
            >
              <CurrentValueCardBlock
                name={valueItem.name}
                valueUnits={valueItem.valueUnits}
                trend={valueItem.trend}
              />
            </div>
          );
        })}
      </div>
    </AssetCardCellContainer>
  );
}
