import { StyleRulesCallback, Theme } from "@material-ui/core/styles";

export const autoCompleteDropwDownStyle: StyleRulesCallback<Theme, {}> = (theme) => ({
  onTopOfPopup: {
    zIndex: 10000,
  },
  ellipsisOnOverflow: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
});
