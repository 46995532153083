import React, { useContext, useCallback } from "react";
import { ALERT_ENTITY_NAME, ACKNOWLEDGE_ALERT_ACTION, ACTION_ALERT_ACTION } from "./Alert.const";
import { createOrEditEntity } from "components/Utils/CRUDUtils";
import {
  UnifiedDataRequestType,
  OPERATION_ERROR,
  FilterMatchTypes,
  SORT_DESC,
} from "components/Utils/CRUDUtils.d";
import { filterBy, isSpecified, translateValue } from "components/Utils/MiscUtils";
import { ObjectIdType } from "components/Utils/Object.d";
import { mergeRecords } from "components/Utils/ObjectUtils";
import {
  BaseListProps,
  TableColumnParameters,
  TableColumnFilter,
  TableBodyLayoutType,
} from "components/Crud/Table/EntityTable.d";
import { TableCellRendererProps } from "components/Crud/Table/CellRenderers/TableCellRenderer.d";
import { priorityDictionary } from "components/Dictionaries/DictionariesCommon";
import { API_LIB } from "components/Utils/API_LIB";
import { DataSourceParamsType } from "components/Crud/DataProvider/DataProviderCommon.d";
import { CrudQueryTableBinding } from "components/Crud/Table/CrudQueryTableBinding";
import { TableActionButtonFace } from "components/Buttons/TableActionButtonFace";
import { getAttributeByPath } from "components/Crud/EntityUtils";
import { EDIT_ACTION, CREATE_ACTION } from "components/Crud/Entity.const";
import { useVersion } from "components/Utils/CustomHooks";
import { MessageBannerDispatcherContext } from "components/Dashboard/Message/MessageBannerFrame";
import { EnterpriseLevelAlertRowCard } from "./EnterpriseLevelAlertRowCard";
import { ENTITY_LIB } from "components/Utils/CRUDEntityList";
import { ACTION_NAVIGATE_TO_ASSET } from "views/asset/Asset/Asset.const";
import { getAggregatedPageRoute, refineRequest } from "components/Utils/RouteUtils";
import { ROUTE_LIB } from "components/Utils/RouteList";
import { ALL_ENTERPRISES_CODE } from "views/Enterprise/Enterprise.const";
import { getDictionaryItemByCode } from "components/Dictionaries/DictionariesUtils";
import { AlertAcknowledgementStatus } from "./Alert.d";
import { alertNotificationDictionary } from "./Alert.dict";

export interface AlertListProps {
  title: string;
}

export function AlertList(props: BaseListProps & AlertListProps) {
  const messageBannerDispatcher = useContext(MessageBannerDispatcherContext);
  const [version, increaseVersion] = useVersion();

  const asyncAlertAcknowledgement = useCallback(
    async (id: ObjectIdType, object: Record<string, any>) => {
      const notificationId = getAttributeByPath(object, "notifications[0].id");

      const result = await createOrEditEntity(
        EDIT_ACTION,
        notificationId,
        { notificationStatus: AlertAcknowledgementStatus.ACKNOWLEDGED },
        API_LIB.NOTIFICATIONS
      );

      if (result.status.type === OPERATION_ERROR) {
        messageBannerDispatcher({
          messageType: OPERATION_ERROR,
          messageText: `Alert Acknowledgement failed`,
        });

        console.error(
          `Error ${result.status.message} while acknowledging notification with id =  ${notificationId}`
        );
      } else {
        increaseVersion();
      }
    },
    [messageBannerDispatcher, increaseVersion]
  );

  const userId = props.userData.userId;

  const asyncAlertAction = useCallback(
    async (id: ObjectIdType, object: Record<string, any>) => {
      const eventId = getAttributeByPath(object, "id");

      const result = await createOrEditEntity(
        CREATE_ACTION,
        eventId,
        {
          userId: userId,
        },
        `/event/action/${eventId}`
      );

      if (result.status.type === OPERATION_ERROR) {
        messageBannerDispatcher({
          messageType: OPERATION_ERROR,
          messageText: `Alert Actioning failed`,
        });

        console.error(
          `Error ${result.status.message} while acknowledging notification with id =  ${eventId}`
        );
      } else {
        increaseVersion();
      }
    },
    [messageBannerDispatcher, increaseVersion, userId]
  );

  const tableColumns: TableColumnParameters[] = [
    {
      sourceName: ["level", "message", "date"],
      // label: 'Action',
      disableSorting: true,
      width: "100%",
      valueTransformationFunction: (rendererProps: TableCellRendererProps) => (
        <EnterpriseLevelAlertRowCard {...rendererProps} />
      ),
      actionSet: [
        {
          labelElement: <div style={{ whiteSpace: "nowrap" }}>{"VIEW ASSET"}</div>,
          name: "viewDetail",
          onClick: {
            type: "navigate",
            // TODO: it has nothing to do with Asset Group
            url: getAggregatedPageRoute(ROUTE_LIB.ASSET_SUMMARY),
            paramName: "entityId",
            paramSource: "assetId",
          },
          actionCode: ACTION_NAVIGATE_TO_ASSET,
          actionObject: ENTITY_LIB.ASSET_INVENTORY_ITEM,
        },
        {
          labelElement: (obj: Record<string, any>) => {
            const eventLevelItem = getDictionaryItemByCode(priorityDictionary, obj.level) as Record<
              string,
              any
            >;

            return (
              <TableActionButtonFace variant="important" htmlColor={eventLevelItem.colorCode}>
                <span style={{ minWidth: "100px", color: eventLevelItem.textColor }}>Acknowledge</span>
              </TableActionButtonFace>
            );
          },
          name: "acknowledgeAlert",
          onClick: {
            type: "action",
            fn: asyncAlertAcknowledgement,
          },
          displayCondition: (obj: Record<string, any>) => {
            const notificationStatus = getAttributeByPath(obj, "notifications[0].notificationStatus");

            const displayResult =
              isSpecified(notificationStatus) &&
              notificationStatus !== AlertAcknowledgementStatus.ACKNOWLEDGED;

            return displayResult;
          },
          doNotGroup: true,
          actionCode: ACKNOWLEDGE_ALERT_ACTION,
          actionObject: ALERT_ENTITY_NAME,
        },
        {
          labelElement: (
            <TableActionButtonFace variant="unobtrusive">
              <span style={{ minWidth: "100px", fontWeight: 500 }}>Action</span>
            </TableActionButtonFace>
          ),
          name: "acknowledgeAlert",
          onClick: {
            type: "action",
            fn: asyncAlertAction,
          },
          displayCondition: (obj: Record<string, any>) => {
            const notificationStatus = getAttributeByPath(obj, "notifications[0].notificationStatus");

            const displayResult =
              isSpecified(notificationStatus) &&
              notificationStatus !== AlertAcknowledgementStatus.ACKNOWLEDGED;

            return displayResult;
          },
          doNotGroup: true,
          actionCode: ACTION_ALERT_ACTION,
          actionObject: ALERT_ENTITY_NAME,
        },
      ],
    },
  ];

  const uiFilters: Array<TableColumnFilter> = [
    {
      filterType: "text",
      filterDataSourceField: "asset.name",
      filterMatchType: FilterMatchTypes.CONTAINS,
      filterParams: {
        placeholder: "Asset Name",
      },
    },
    {
      filterType: "select",
      filterParams: {
        options: priorityDictionary,
        renderEmptyValueAs: "ALL PRIORITIES",
      },
      filterDataSourceField: "level",
    },
    {
      filterType: "select",
      filterParams: {
        options: alertNotificationDictionary,
        renderEmptyValueAs: "ALL NOTIFICATION STATUSES",
      },
      filterDataSourceField: "notifications.notificationStatus",
    },
  ];

  const dataSource: DataSourceParamsType = {
    apiUrl: API_LIB.EVENT, // `/notification?join=event&join=samplePoints&join=asset`, // '/notification',
  };

  let initialRequest: UnifiedDataRequestType = {
    fieldList: [
      // Do not add join to notifications
      "assetId",
      "asset.name",
      "asset.assetTypeId",
      "asset.site.name",
      "asset.site.enterpriseId",
      "date",
      "level",
      "message",
      "notifications.id",
      "notifications.notificationStatus",
      "notifications.userId",
      "eventActions.id",
      "eventActions.userId",
      "eventActions.user.id",
      "eventActions.user.firstName",
      "eventActions.user.lastName",
    ],
    filter: {
      baseFilters: [
        // filterBy('notifications.userId', '=', props.userData.userId),
        // filterBy('assetId', '=', props.assetId),
        filterBy(
          "asset.site.enterpriseId",
          "=",
          translateValue(props.currentEnterpriseId, [[ALL_ENTERPRISES_CODE, undefined]])
        ),
      ],
    },
    isPaginationEnabled: true,
    rowsPerPage: 10,
    pageNum: 0,
    sortByColumn: "date",
    sortDirection: SORT_DESC,
  };

  const refinedRequestFromInput = refineRequest(props.inputRequest, uiFilters);
  initialRequest = mergeRecords(initialRequest, refinedRequestFromInput) as UnifiedDataRequestType;

  return (
    <CrudQueryTableBinding
      externalTriggerValues={[props.currentEnterpriseId, props.userData, version]}
      dataSource={dataSource}
      intialQuery={initialRequest}
      title={props.title}
      tableColumns={tableColumns}
      tableVariant={TableBodyLayoutType.FULL_WIDTH_CARD_SINGLE}
      onRowClickFunction={props.onRowClickFunction}
      // actionsAllowed={allowedActions}
      highlightedRowsPrimaryKeys={props.activeRowPrimaryKeys}
      // disableFilters={props.disableFilters}
      // disablePaginationSection={props.disablePaginationSection}
      uiFilters={uiFilters}
      // paperless={props.paperless}
      contentToDisplayWhenNoData={<div style={{ padding: "24px" }}>None</div>}
      allowUrlReplacementForFilters={props.allowUrlReplacementForFilters}
      allowReinitialization={props.allowReinitialization}
    />
  );
}
