import { ObjectIdType } from "components/Utils/Object.d";
import { MessageBannerDispatcherContextType } from "components/Dashboard/Message/MessageBannerFrame";
import { OPERATION_ERROR, OPERATION_SUCCESS } from "components/Utils/CRUDUtils.d";
import { createOrEditEntity } from "components/Utils/CRUDUtils";
import { EDIT_ACTION } from "components/Crud/Entity.const";
import { PACKET_LOG_REQUEST_REPROCESSING } from "./PacketLog.const";

export const handleRequestPacketLogReprocessing = async (
  packetId: ObjectIdType,
  inputMessageBannerDispatcher?: MessageBannerDispatcherContextType
) => {
  // Generalize this function
  const result = await createOrEditEntity(
    EDIT_ACTION,
    packetId,
    {}, // POST BODY
    PACKET_LOG_REQUEST_REPROCESSING
  );

  if (result.status.type === OPERATION_ERROR) {
    if (inputMessageBannerDispatcher) {
      inputMessageBannerDispatcher(
        /* .dispatchMessage */ {
          messageType: OPERATION_ERROR,
          messageText: `Request to reprocess packet failed`,
        }
      );
    }

    console.error(
      `Error ${result.status.message} while requesting to reprocess packet with id =  ${packetId}`
    );
  } else {
    if (inputMessageBannerDispatcher) {
      inputMessageBannerDispatcher(
        /* .dispatchMessage */ {
          messageType: OPERATION_SUCCESS,
          messageText: `Successfully requested packet reprocessing`,
        }
      );
    }
  }
};
