import { UserRole } from "components/Crud/EntityGuard.d";
import { RBAC_LIST } from "./RoleBasedAccessControlList";
import { UserParams } from "views/User/UserParams.d";
import { subtractScalarArrays } from "components/Utils/ObjectUtils";
import { EntityActionType } from "components/Crud/Entity.d";
import { EnterpriseIdType } from "views/Enterprise/Enterprise.d";
import { arrayIncludes } from "components/Utils/MiscUtils";

export function rolesListIncludeUserRole(
  userData: UserParams,
  currentEnterpriseId: EnterpriseIdType,
  roleList?: Array<UserRole> | boolean
) {
  let result = false;

  if (Array.isArray(roleList) && roleList.indexOf(UserRole.ALL_USERS) >= 0) {
    result = true;
  } else if (
    Array.isArray(roleList) &&
    userData.isSuperAdmin &&
    roleList.indexOf(UserRole.SUPER_ADMIN) >= 0
  ) {
    result = true;
  } else if (Array.isArray(roleList)) {
    const activeMemebership = userData.memberships.find(
      (element) => element.enterpriseId === currentEnterpriseId
    );

    if (activeMemebership) {
      result = roleList.indexOf(activeMemebership.role) >= 0;
    }
  }

  return result;
}

export function calculateAllowedActions(
  currentEnterpriseId: EnterpriseIdType,
  userData: UserParams,
  restrictActions: Array<string>,
  entityName: string
): Array<EntityActionType> {
  let allowedActions: Array<EntityActionType> = [];

  if (userData.isSuperAdmin) {
    allowedActions = allowedCRUDActions(UserRole.SUPER_ADMIN, entityName);
  } else {
    const activeMemebership = userData.memberships.find(
      (element) => element.enterpriseId === currentEnterpriseId
    );

    if (activeMemebership) {
      // TODO: this is a HACK. Derive membership properly
      allowedActions = allowedCRUDActions(activeMemebership.role, entityName);
    }
  }

  if (restrictActions) {
    allowedActions = allowedActions.filter(
      (value) => restrictActions.findIndex((restrictedValue: any) => value === restrictedValue) < 0
    );
  }

  return allowedActions;
}

export function isActionAllowed(
  actionCode: string,
  currentEnterpriseId: EnterpriseIdType,
  userData: UserParams,
  restrictActions: Array<string>,
  actionObject: string
): boolean {
  const actionObjectAllowedActions = calculateAllowedActions(
    currentEnterpriseId,
    userData,
    restrictActions,
    actionObject
  );
  const actionIsAllowed = arrayIncludes(actionObjectAllowedActions, actionCode);

  return actionIsAllowed;
}

function filterUserEntityActions(userRoleId: UserRole, entityType: string, permissionValue: boolean) {
  let result: Array<string>;

  result = RBAC_LIST.filter(
    (value) =>
      value.userRoleId === userRoleId &&
      value.entityType === entityType &&
      value.permissionValue === permissionValue
  ).map((value) => value.entityAction);

  return result;
}

function allowedCRUDActions(userRoleId: UserRole, entityType: string) {
  const actionsPermittedForAllUsers = filterUserEntityActions(UserRole.ALL_USERS, entityType, true);

  const userRoleAllowedActions = filterUserEntityActions(userRoleId, entityType, true);

  let result: Array<EntityActionType> = [].concat(
    actionsPermittedForAllUsers ? actionsPermittedForAllUsers : [],
    userRoleAllowedActions ? userRoleAllowedActions : []
  );

  result = subtractScalarArrays(result, filterUserEntityActions(UserRole.ALL_USERS, entityType, false));
  result = subtractScalarArrays(result, filterUserEntityActions(userRoleId, entityType, false));

  return result;
}
