// TODO: Check if this screen is needed at all
import React, { useState, useEffect } from "react";
import { generateEntityPathString } from "components/Utils/CRUDUtils";
import { DataOwnerParameters, UnifiedDataRequestType } from "components/Utils/CRUDUtils.d";
import { BaseListProps } from "components/Crud/Table/EntityTable.d";
import { ObjectIdType } from "components/Utils/Object.d";
import { SensorList } from "./SensorList";

export interface SensorListScreenProps {
  monitorConfigurationId?: number;
  monitorId?: ObjectIdType;
  inputRequest?: Partial<UnifiedDataRequestType>;
}

export function SensorListScreen(props: BaseListProps & SensorListScreenProps) {
  const TITLE_SUFFIX = "Sensors";

  const [title, setTitle] = useState(TITLE_SUFFIX);

  useEffect(() => {
    let dataOwner: DataOwnerParameters[];

    if (props.monitorId) {
      dataOwner = [
        {
          endpointUrl: `/monitor/${props.monitorId}`,
          fields: ["name"],
          titlePrefixTemplate: "Monitor :name > ",
        },
      ];
    }

    generateEntityPathString(dataOwner, (value: string) => setTitle(`${value}${TITLE_SUFFIX}`));
  }, [props.monitorId]);

  return (
    <SensorList
      title={title}
      onRowClickFunction={props.onRowClickFunction}
      activeRowPrimaryKeys={props.activeRowPrimaryKeys}
      userData={props.userData}
      currentEnterpriseId={props.currentEnterpriseId}
      monitorId={props.monitorId}
      allowUrlReplacementForFilters={props.allowUrlReplacementForFilters}
      allowReinitialization={props.allowReinitialization}
      inputRequest={props.inputRequest}
    />
  );
}
