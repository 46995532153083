import React, { useMemo } from "react";
import ReactDOMServer from "react-dom/server";
import { ObjectIdType } from "components/Utils/Object.d";
import { GeoMap } from "./GeoMap";
import { isNonEmptyArray, isSpecified } from "components/Utils/MiscUtils";
import { getAttributeByPath, hasAttributeByPath } from "components/Crud/EntityUtils";
import { extractSamplePointsCurrentValues } from "views/asset/SamplePoint/SamplePointCommon";
import { CurrentValueCardBlock } from "components/Crud/Table/CellRenderers/AssetCardCells/CurrentValueCardBlock";
import { CardSubTitle } from "components/Elements/Typography/CardSubTitle";
import {
  assetTypeMarkerCanvasDictionary,
  assetTypeIconDictionaryWithTooltips,
} from "components/Dictionaries/DictionariesCommon";
import { assetRoundedRectangleBanner } from "components/Elements/DynamicImages/AssetRoundedRectangleBanner";
import { useTheme } from "@material-ui/core";
import { SamplePointCurrentValueType } from "views/asset/SamplePoint/SamplePoint.d";
import { getDictionaryItemByCode } from "components/Dictionaries/DictionariesUtils";
import { GeoEntityType } from "./GeoMap.d";

export interface AssetGeoMapProps {
  inputData: Array<any>;
  activeEntityIds: Array<ObjectIdType>;
}

export function AssetGeoMap(props: AssetGeoMapProps) {
  const theme = useTheme();
  const themeGreen = theme.palette.success.dark;
  const themeOrange = theme.palette.warning.main;

  const geoEntities: Array<GeoEntityType> = useMemo(() => {
    let result: Array<GeoEntityType>;

    const itemsWithGeoCoordinates = isNonEmptyArray(props.inputData)
      ? props.inputData.filter((geoEntityItem) => hasAttributeByPath(geoEntityItem, "location.coordinates"))
      : undefined;

    if (isNonEmptyArray(itemsWithGeoCoordinates)) {
      result = itemsWithGeoCoordinates.map((geoEntityItem) => {
        const geoEntityItemShortName =
          (geoEntityItem.name || "").length > 50
            ? `${geoEntityItem.name.slice(0, 50)}...`
            : geoEntityItem.name;

        // coordinates formate is X and Y, not lattitude and longiture. hence X - long, Y - lat
        const coordinate = getAttributeByPath(geoEntityItem, "location.coordinates");
        // #388e3c
        // console.log('GEO ENTITY ITEM', geoEntityItem);
        // let currentValuesLine = `<div>${name} <b><span style="color: ${valuesColor}">${value} ${units}</span><b></div>`;

        let assetTypeSegment: JSX.Element;
        const iconRecord = getDictionaryItemByCode(
          assetTypeIconDictionaryWithTooltips,
          geoEntityItem.assetTypeId
        ) as Record<string, any>;

        if (iconRecord) {
          assetTypeSegment = iconRecord.pureIcon;
        }

        const samplePoints = geoEntityItem.samplePoints;

        let currentValuesHTML;
        let currentValues;
        if (isSpecified(samplePoints)) {
          currentValues = extractSamplePointsCurrentValues(samplePoints);

          // const sheets = new ServerStyleSheets();
          currentValuesHTML = ReactDOMServer.renderToString(
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div>{assetTypeSegment}</div>
                <div>
                  <b>{geoEntityItemShortName}</b>
                </div>
              </div>
              <div>
                <CardSubTitle>{"CURRENT VALUES"}</CardSubTitle>
                {currentValues.map((currentValueItem: SamplePointCurrentValueType, index: number) => {
                  return (
                    <CurrentValueCardBlock
                      key={index}
                      name={currentValueItem.name}
                      valueUnits={currentValueItem.valueUnits}
                      trend={currentValueItem.trend}
                    />
                  );
                })}
                <a href={`/app/assetSummary/${geoEntityItem.id}`}>VIEW DETAIL</a>
              </div>
            </div>
          );
        }

        const canvasMarkerFunctionItem = assetTypeMarkerCanvasDictionary.find(
          (item) => item.code === geoEntityItem.assetTypeId
        );
        let canvasMarkerFunctionExecutable;
        if (canvasMarkerFunctionItem) {
          canvasMarkerFunctionExecutable = canvasMarkerFunctionItem.canvasMarker;
        } else {
          canvasMarkerFunctionExecutable = assetRoundedRectangleBanner; // canvasFarmbotWaterDropMarker;
        }

        let resultCanvasMarker;
        let markerCanvas: HTMLCanvasElement;
        // let labelCanvas: HTMLCanvasElement;
        // let markerLabelComboCanvas: HTMLCanvasElement;

        if (canvasMarkerFunctionExecutable) {
          let valueString, unitString;
          let valueColor: string = undefined;
          let discColor: string = themeGreen;

          if (isNonEmptyArray(currentValues)) {
            const valueUnits = currentValues[0].valueUnits;
            const valueTrend = currentValues[0].trend;

            if (valueTrend > 0) {
              valueColor = themeGreen;
            } else if (valueTrend < 0) {
              discColor = themeOrange;
              valueColor = themeOrange;
            }

            if (isNonEmptyArray(valueUnits)) {
              let valueItem = valueUnits.find((item) => item.units === "%");

              if (!valueItem) {
                valueItem = valueUnits[0];
              }

              if (typeof valueItem.value === "number") {
                valueString = valueItem.value.toString();
              } else if (typeof valueItem.value === "string") {
                valueString = valueItem.value;
              }
              unitString = valueItem.units;
            }
          }

          // let color = 'black';
          // if (props.t)
          markerCanvas = canvasMarkerFunctionExecutable(
            valueString,
            unitString,
            geoEntityItemShortName,
            valueColor,
            discColor
          );
          // labelCanvas = selfAdjustableLabel(geoEntityItemShortName, {color: '#5dbde2', size: 12, outerStroke: {width: 3}})

          // markerLabelComboCanvas = markerLabelCombo(markerCanvas, labelCanvas);

          // let markerLabelComboCanvas = document.createElement('canvas');
          // markerLabelComboCanvas.width = Math.max(markerCanvas.width, labelCanvas.width);
          // markerLabelComboCanvas.height = markerCanvas.height + labelCanvas.height;

          // let markerLabelComboContext = markerLabelComboCanvas.getContext('2d');
          // // canvasMarker = canvasMarkerFunctionItem.canvasMarker(valueString, unitString).toDataURL();
          // markerLabelComboContext.drawImage(labelCanvas, 0, 0);
          // markerLabelComboContext.drawImage(markerCanvas, 0, 0);

          resultCanvasMarker = markerCanvas.toDataURL();
        }

        const infoWindowContent = `${currentValuesHTML}`;

        const resultMarkerConfig: GeoEntityType = {
          id: geoEntityItem.id,
          title: geoEntityItemShortName,
          // label: {
          //   text: geoEntityItemShortName,
          //   color: 'white',
          //   fontWeight: 'bold'
          // },
          lattitude: coordinate[1],
          longitude: coordinate[0],
          customIconSvgPathObject: {
            url: resultCanvasMarker,
          },
          infoWindow: {
            content: infoWindowContent,
          },
        };

        if (isSpecified(markerCanvas)) {
          resultMarkerConfig.infoWindow.pixelOffset = new google.maps.Size(0, markerCanvas.height);
        }

        // if (isSpecified(labelCanvas) && isSpecified(markerCanvas)) {
        //   resultMarkerConfig.targetOffset = {
        //     x: markerCanvas.width / 2,
        //     y: labelCanvas.height
        //   };
        // }

        return resultMarkerConfig;
      });
    }

    return result;
  }, [props.inputData, themeGreen, themeOrange]);

  let activeEntityCoordinates: { lat: number; lng: number };

  if (isNonEmptyArray(geoEntities) && isNonEmptyArray(props.activeEntityIds)) {
    const activeEntity = geoEntities.find((geoEntityItem) => geoEntityItem.id === props.activeEntityIds[0]);

    if (activeEntity) {
      activeEntityCoordinates = {
        lat: activeEntity.lattitude,
        lng: activeEntity.longitude,
      };
    }
  }

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column", flexGrow: 1 }}>
      <GeoMap geoEntityList={geoEntities} centeredAt={activeEntityCoordinates} />
    </div>
  );
}
