import React from "react";
import { IconComponentProps } from "./ProfessionalSet.d";

export function FlowComponent(props: IconComponentProps) {
  const localHtmlColor = props.htmlColor || "#414959";

  return (
    <svg x="0px" y="0px" height="24" width="24">
      <path
        style={{ fill: "none", stroke: localHtmlColor, strokeWidth: 1.5, strokeMiterlimit: 10 }}
        d="M5.738,5.394c0.044-2.089,12.568-2.082,12.523,0
          v6.151 M5.87,5.518c1.331,1.63,12.272,1.52,12.313-0.349 M5.741,11.545V5.299 M3.389,11.073h17.076 M3.389,20.117h17.076
          M6.037,15.578l-0.921-0.555v0.598v0.512L6.037,15.578z M11.966,15.578l-0.921-0.555v0.598v0.512L11.966,15.578z M17.896,15.578
          l-0.921-0.555v0.598v0.512L17.896,15.578z"
      />
    </svg>
  );
}
