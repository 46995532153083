import React, { useReducer, useEffect, useState, useContext, useRef } from "react";
import { MessageBannerDispatcherContext } from "components/Dashboard/Message/MessageBannerFrame";
import {
  fetchDataByFullURL,
  fetchEntityListByQuery,
  createOrEditEntity,
  deleteEntityById,
} from "components/Utils/CRUDUtils";
import { OPERATION_SUCCESS, FetchResultType, OPERATION_ERROR } from "components/Utils/CRUDUtils.d";
import { DataSourceParamsType } from "./DataProviderCommon.d";
import { ObjectWithIdFieldType } from "components/Utils/Object.d";
import { QueryEventAction } from "./DataQuery.d";
import { CrudEventAction } from "./DataCrud.d";
import { preUrl } from "views/constants";
import { CREATE_ACTION, EDIT_ACTION } from "../Entity.const";
import produce from "immer";
import {
  DatasetManagerDatasetType,
  DatasetManagerStatusType,
  DatasetReducerEventActionType,
  NotificationFlagTypes,
  DataRequestType,
  DatasetMutationErrorPayloadType,
  DatasetLoadErrorPayloadType,
  DatasetLoadSuccessPayloadType,
  DatasetMutationExecutionPayloadType,
  DatasetMutationSuccessPayloadType,
  DatasetLoadExecutionPayloadType,
} from "./DatasetManager.d";
import { datasetReducer } from "./DatasetManager.reducer";

const initialState: DatasetManagerDatasetType = {
  status: DatasetManagerStatusType.STATIC,
  mutatingItemId: null,
  dataArray: [] as Array<ObjectWithIdFieldType>,
  totalRows: 0,
  lastOperationResult: null,
};

export function useDatasetManager(
  dataSource: DataSourceParamsType,
  notificationFlags?: NotificationFlagTypes
): [DatasetManagerDatasetType, React.Dispatch<DataRequestType>] {
  const messageBannerDispatcher = useContext(MessageBannerDispatcherContext);

  const {
    notifyOnLoadSuccess = false,
    notifyOnLoadFailure = true,
    notifyOnMutationSuccess = true,
    notifyOnMutationFailure = true,
  } = notificationFlags || {};

  const [request, dispatchRequest] = useState({} as DataRequestType);
  const [dataset, dispatchDatasetActionRequest] = useReducer(datasetReducer, initialState);

  const { apiUrl, customUrlRequestFragment, useUrlAsIs, additionalRequestParams } = dataSource;

  // TODO: currently base request is not populated with data UNTIL first load request is called
  const baseRequest = useRef(undefined); // I don't want it to trigger rerender

  useEffect(() => {
    let didCancel = false;

    const fetchData = async () => {
      let result: FetchResultType;

      if (request.action === QueryEventAction.REQUEST_DATA) {
        //
        // QueryEventAction.REQUEST_DATA
        //

        dispatchDatasetActionRequest({
          action: DatasetReducerEventActionType.LOADING,
          payload: undefined as DatasetLoadExecutionPayloadType,
          originator: "useDatasetManager - reload",
        });

        if (useUrlAsIs) {
          const additionalRequestParamsCopy = additionalRequestParams || {};

          result = await fetchDataByFullURL(
            `${preUrl}${apiUrl}`,
            "fetch as is",
            additionalRequestParamsCopy.method,
            additionalRequestParamsCopy.body
          );
        } else {
          result = await fetchEntityListByQuery(request.payload, apiUrl, customUrlRequestFragment);

          // worst comes to worst: use baseRequest.current = request.payload
          // fieldList?: Array<string>;
          // filter: FilterParameters;
          // isPaginationEnabled?: boolean; // TODO: copied as is to minimize amount of changes. Refactor
          // rowsPerPage?: number;
          // pageNum?: number;
          // sortByColumn?: string;
          // sortDirection?: Order;

          baseRequest.current = {
            fieldList: request.payload.fieldList,
            filter: {},
          };
        }

        if (!didCancel && result.status.type === OPERATION_SUCCESS) {
          dispatchDatasetActionRequest({
            action: DatasetReducerEventActionType.POPULATE,
            payload: {
              data: result.data,
              totalRows: result.totalRows,
            } as DatasetLoadSuccessPayloadType,
            originator: "useDatasetManager - reload",
          });
        } else if (!didCancel && result.status.type !== OPERATION_SUCCESS) {
          dispatchDatasetActionRequest({
            action: DatasetReducerEventActionType.SET_ERROR,
            payload: {
              operationType: DatasetReducerEventActionType.POPULATE,
              message: result.status.message,
              relatedId: null,
            } as DatasetLoadErrorPayloadType,
            originator: "useDatasetManager - reload",
          });
        }

        if (result.status.type === OPERATION_SUCCESS && notifyOnLoadSuccess) {
          messageBannerDispatcher({ messageType: OPERATION_SUCCESS, messageText: "Loaded successfully" });
        } else if (!(result.status.type === OPERATION_SUCCESS) && notifyOnLoadFailure) {
          messageBannerDispatcher({ messageType: OPERATION_ERROR, messageText: "Error while loading data" });
        }
      } else if (request.action === CrudEventAction.CREATE) {
        //
        // CrudEventAction.CREATE
        //

        dispatchDatasetActionRequest({
          action: DatasetReducerEventActionType.MUTATING,
          payload: {
            id: null,
          } as DatasetMutationExecutionPayloadType,
          originator: "useDatasetManager - create",
        });

        result = await createOrEditEntity(
          CREATE_ACTION,
          undefined,
          request.payload,
          apiUrl,
          customUrlRequestFragment,
          baseRequest.current
        );

        if (!didCancel && result.status.type === OPERATION_SUCCESS) {
          dispatchDatasetActionRequest({
            action: DatasetReducerEventActionType.ADD,
            payload: {
              ...result.data,
            } as DatasetMutationSuccessPayloadType,
            originator: "useDatasetManager - create",
          });
        } else if (!didCancel && result.status.type !== OPERATION_SUCCESS) {
          dispatchDatasetActionRequest({
            action: DatasetReducerEventActionType.SET_ERROR,
            payload: {
              operationType: DatasetReducerEventActionType.ADD,
              message: result.status.message,
              relatedId: null, // TODO: how would we associate with create popup
            } as DatasetMutationErrorPayloadType,
            originator: "useDatasetManager - reload",
          });
        }

        if (result.status.type === OPERATION_SUCCESS && notifyOnMutationSuccess) {
          messageBannerDispatcher({ messageType: OPERATION_SUCCESS, messageText: "Added successfully" });
        } else if (!(result.status.type === OPERATION_SUCCESS) && notifyOnMutationFailure) {
          messageBannerDispatcher({
            messageType: OPERATION_ERROR,
            messageText: "Error while creating object",
          });
        }
      } else if (request.action === CrudEventAction.UPDATE) {
        //
        // CrudEventAction.UPDATE
        //

        dispatchDatasetActionRequest({
          action: DatasetReducerEventActionType.MUTATING,
          payload: {
            id: request.payload.id,
          } as DatasetMutationExecutionPayloadType,
          originator: "useDatasetManager - update",
        });

        const payloadWithoutId = produce(request.payload, (draftPayload: Record<string, any>) => {
          delete draftPayload.id;
        });

        result = await createOrEditEntity(
          EDIT_ACTION,
          request.payload.id,
          payloadWithoutId,
          apiUrl,
          customUrlRequestFragment,
          baseRequest.current
        );

        if (!didCancel && result.status.type === OPERATION_SUCCESS) {
          dispatchDatasetActionRequest({
            action: DatasetReducerEventActionType.UPDATE,
            payload: {
              relatedId: request.payload.id,
              ...result.data,
            } as DatasetMutationSuccessPayloadType,
            originator: "useDatasetManager - update",
          });
        } else if (!didCancel && result.status.type !== OPERATION_SUCCESS) {
          dispatchDatasetActionRequest({
            action: DatasetReducerEventActionType.SET_ERROR,
            payload: {
              operationType: DatasetReducerEventActionType.UPDATE,
              message: result.status.message,
              relatedId: request.payload.id,
            } as DatasetMutationErrorPayloadType,
            originator: "useDatasetManager - reload",
          });
        }

        if (result.status.type === OPERATION_SUCCESS && notifyOnMutationSuccess) {
          messageBannerDispatcher({ messageType: OPERATION_SUCCESS, messageText: "Updated successfully" });
        } else if (!(result.status.type === OPERATION_SUCCESS) && notifyOnMutationFailure) {
          messageBannerDispatcher({
            messageType: OPERATION_ERROR,
            messageText: "Error while updating object",
          });
        }
      } else if (request.action === CrudEventAction.DELETE) {
        //
        // CrudEventAction.DELETE
        //

        dispatchDatasetActionRequest({
          action: DatasetReducerEventActionType.MUTATING,
          payload: {
            id: request.payload.id,
          } as DatasetMutationExecutionPayloadType,
          originator: "useDatasetManager - update",
        });

        result = await deleteEntityById(request.payload.id, apiUrl);

        if (!didCancel && result.status.type === OPERATION_SUCCESS) {
          dispatchDatasetActionRequest({
            action: DatasetReducerEventActionType.DELETE,
            payload: {
              relatedId: request.payload.id,
              id: request.payload.id,
            } as DatasetMutationSuccessPayloadType,
            originator: "useDatasetManager - update",
          });
        } else if (!didCancel && result.status.type !== OPERATION_SUCCESS) {
          dispatchDatasetActionRequest({
            action: DatasetReducerEventActionType.SET_ERROR,
            payload: {
              operationType: DatasetReducerEventActionType.DELETE,
              relatedId: request.payload.id,
              message: result.status.message,
            } as DatasetMutationErrorPayloadType,
            originator: "useDatasetManager - reload",
          });
        }

        if (result.status.type === OPERATION_SUCCESS && notifyOnMutationSuccess) {
          messageBannerDispatcher({ messageType: OPERATION_SUCCESS, messageText: "Deleted successfully" });
        } else if (!(result.status.type === OPERATION_SUCCESS) && notifyOnMutationFailure) {
          messageBannerDispatcher({
            messageType: OPERATION_ERROR,
            messageText: "Error while deleting object",
          });
        }
      }
    };

    fetchData();

    return () => {
      didCancel = true;
    };
  }, [
    request,
    messageBannerDispatcher,
    apiUrl,
    customUrlRequestFragment,
    useUrlAsIs,
    additionalRequestParams,
    notifyOnLoadSuccess,
    notifyOnLoadFailure,
    notifyOnMutationSuccess,
    notifyOnMutationFailure,
  ]);

  return [dataset, dispatchRequest];
}
