import React, { useEffect } from "react";
import { ObjectIdType } from "components/Utils/Object.d";
import { EventDispatchersType } from "components/Crud/Table/StatelessTable.d";
import { DataSourceParamsType } from "components/Crud/DataProvider/DataProviderCommon.d";
import { useDatasetManager } from "components/Crud/DataProvider/DatasetManager";
import { useTheme, Paper, makeStyles } from "@material-ui/core";
import { UnifiedDataRequestType, SORT_ASC } from "components/Utils/CRUDUtils.d";
import { conditionTypeDictionary, valueTypeDictionary } from "./TriggerDictionaries";
import { CRUD_DISPATCHER } from "components/Crud/DataProvider/DataCrud";
import { FetchlessFormikEntityForm } from "components/Crud/Form/FetchlessFormikEntityForm";
import { ENTITY_LIB } from "components/Utils/CRUDEntityList";
import { FlexSpaceFiller } from "layouts/FlexSpaceFiller";
import { ActionButtonHandlerComponent } from "components/Buttons/ActionButtonHandlerComponent";
import { QueryEventAction } from "components/Crud/DataProvider/DataQuery.d";
import { OutlinedBadge } from "components/Elements/Badge/OutlinedBadge";
import { EDIT_ACTION, DELETE_ACTION, CREATE_ACTION } from "components/Crud/Entity.const";
import SettingsIcon from "@material-ui/icons/Settings";
import ActionDelete from "@material-ui/icons/Delete";
import { CrudEventAction } from "components/Crud/DataProvider/DataCrud.d";
import { API_LIB } from "components/Utils/API_LIB";
import RefreshIcon from "@material-ui/icons/Refresh";
import IconButton from "@material-ui/core/IconButton";
import { useSimpleAsyncData } from "components/Crud/DataProvider/useSimpleAsyncData";
import { priorityDictionary } from "components/Dictionaries/DictionariesCommon";
import { triggerConditionEditFormFieldSpec } from "./TriggerConditionTemplate.formField";
import { usePopupOperationController } from "popups/usePopupOperationController";
import { alertTriggerCardStyle } from "./alertTriggerCardStyle";
import clsx from "clsx";
import AddIcon from "@material-ui/icons/Add";
import { MultiLineCardValue } from "components/Elements/Typography/MultiLineCardValue";
import { RegularCardValue } from "components/Elements/Typography/RegularCardValue";
import { CardSubTitle } from "components/Elements/Typography/CardSubTitle";
import { OnClickDialog, OnClickDispatchEvent, ActionButtonConfig } from "components/Buttons/Buttons.d";
import {
  getDictionaryLabelByCode,
  getDictionaryLabelElementByCode,
} from "components/Dictionaries/DictionariesUtils";

const initialRequest: UnifiedDataRequestType = {
  // fieldList: []
  filter: {
    baseFilters: [
      // filterBy('triggerId')
    ],
  },
  isPaginationEnabled: true,
  rowsPerPage: 10,
  pageNum: 0,
  sortByColumn: "id",
  sortDirection: SORT_ASC,
};

export interface TriggerTemplateCardProps {
  id: ObjectIdType;
  name: string;
  dataObject: Record<string, any>;
  actionsSet: Array<ActionButtonConfig>;
  errorMessage?: string;
  eventDispatchers?: EventDispatchersType;
}

const useStyles = makeStyles(alertTriggerCardStyle, { name: "TriggerTemplateCard" });

export function TriggerTemplateCard(props: TriggerTemplateCardProps) {
  const classes = useStyles({});

  const dataSource: DataSourceParamsType = {
    apiUrl: API_LIB.TRIGGER_CONDITION_TEMPLATE,
    customUrlRequestFragment: `filter=triggerTemplateId::eq::${props.id}`,
  };

  const [triggerConditions, dispatchTriggerConditionsRequest] = useDatasetManager(dataSource);

  const theme = useTheme();

  const triggerConditionEditFormFields = triggerConditionEditFormFieldSpec(props.id, theme);

  // Bits copied from DataCrudEntityFormBinding
  const eventDispatchersForForm: EventDispatchersType = {};
  eventDispatchersForForm[CRUD_DISPATCHER] = dispatchTriggerConditionsRequest;

  const TriggerTemplateConditionEditForm = (props: any) => (
    <FetchlessFormikEntityForm
      // tempOperationResult={operationResult}
      // triggerCloseOnSuccesOf={[CrudEventAction.CREATE, CrudEventAction.UPDATE, CrudEventAction.DELETE]}
      formData={props.dataObject}
      id={props.id}
      action={props.action}
      title={props.title}
      isDataLoading={false} //{crudStatus.state === DataCrudOperationStatus.EXECUTING}
      fieldConfigurations={triggerConditionEditFormFields}
      eventDispatchers={eventDispatchersForForm}
      onCloseAdditionalActions={props.onCloseAdditionalActions}
      showCloseButton={props.showCloseButton}
      userData={props.userData}
      currentEnterpriseId={props.currentEnterpriseId}
      layoutComponent={props.layoutComponent}
      conditionalFieldConfiguration={props.conditionalFieldConfiguration}
      formDataPreSubmitProcessor={props.formDataPreSubmitProcessor}
      doNotPreFetchData={true} //{props.doNotPreFetchData}
      warning={props.warning}
    />
  );

  // let createTriggerConditionButtonConfig = generateCreateButtonConfig(
  //   ENTITY_LIB.TRIGGER_CONDITION_TEMPLATE,
  //   'Add',
  //   TriggerTemplateConditionEditForm,
  //   [],
  //   {
  //     title: 'Add Trigger Template Condition',
  //     // warning: warningValue
  //   }
  // );

  const createTriggerConditionButtonConfig = {
    actionCode: CREATE_ACTION,
    actionObject: ENTITY_LIB.TRIGGER_CONDITION_TEMPLATE,
    name: "add",
    labelElement: <AddIcon fontSize="small" />,
    onClick: {
      type: "dialog",
      dialogId: TriggerTemplateConditionEditForm,
      onCloseCallbacks: [] as Array<() => void>,
      extraPropsObject: {
        title: "Add Trigger Condition Template",
        // warning: warningValue
      },
    } as OnClickDialog,
  };

  const editTriggerConditionButtonConfig = {
    actionCode: EDIT_ACTION,
    actionObject: ENTITY_LIB.TRIGGER_CONDITION_TEMPLATE,
    name: "edit",
    labelElement: <SettingsIcon fontSize="small" />,
    onClick: {
      type: "dialog",
      dialogId: TriggerTemplateConditionEditForm,
      onCloseCallbacks: [] as Array<() => void>,
      extraPropsObject: {
        title: "Edit Trigger Condition Template",
        // warning: warningValue,
      },
    } as OnClickDialog,
    doNotGroup: true,
  };

  const deleteTriggerConditionButtonConfig = {
    actionObject: ENTITY_LIB.TRIGGER_CONDITION_TEMPLATE,
    actionCode: DELETE_ACTION,
    name: "delete",
    labelElement: <ActionDelete fontSize="small" />,
    onClick: {
      type: "dispatchEvent",
      dispatcherName: CRUD_DISPATCHER,
      action: CrudEventAction.DELETE,
    } as OnClickDispatchEvent,
    confirm: {
      message: "Are you sure you want to delete Trigger Condition Template?",
      dialogButton: "Delete",
    },
  };

  useEffect(() => {
    dispatchTriggerConditionsRequest({
      action: QueryEventAction.REQUEST_DATA,
      payload: initialRequest,
      originator: "Initial Load of Trigger Conditions Templates",
    });
  }, [dispatchTriggerConditionsRequest]);

  const sampleFieldDictionary = useSimpleAsyncData(API_LIB.SAMPLE_FIELD);

  const operationResult = triggerConditions.lastOperationResult;
  usePopupOperationController(operationResult);

  return (
    <div style={{ padding: "8px 0" /* , width: '50vw' */ }}>
      <Paper className={clsx(classes.boxToHighlightOnHover)}>
        <div style={{ display: "flex", padding: "16px" }}>
          <div
            style={{
              flexBasis: 0,
              flexGrow: 1,
              paddingLeft: "16px",
              borderRightColor: "#e0e0e0",
              borderRightStyle: "solid",
              borderRightWidth: "0.25px",
              paddingRight: "16px",
            }}
          >
            <div style={{ display: "flex", alignItems: "center", height: "28px" }}>
              <div style={{ padding: "0 0px" }}>
                <b>{props.name}</b>
              </div>
              <FlexSpaceFiller />
              <div
                /* className="showOnHover"  */ style={{
                  display: "flex",
                  alignContent: "center",
                  color: "navy",
                }}
              >
                {props.actionsSet.map((actionConfigItem, index) => {
                  return (
                    <ActionButtonHandlerComponent
                      key={index}
                      buttonConfig={actionConfigItem}
                      id={props.id}
                      object={props.dataObject}
                      eventDispatchers={props.eventDispatchers}
                    />
                  );
                })}
              </div>
            </div>
            <div style={{ color: "red" }}>{props.errorMessage}</div>
            <div style={{ display: "flex", padding: "8px 0" }}>
              <div style={{ flexGrow: 1 }}>
                <MultiLineCardValue>{props.dataObject.message}</MultiLineCardValue>
              </div>
              <div style={{ width: "100px" }}>
                {getDictionaryLabelElementByCode(priorityDictionary, props.dataObject.eventLevel)}
              </div>
            </div>
          </div>
          <div style={{ flexBasis: 0, flexGrow: 1, paddingLeft: "16px" }}>
            <div style={{ display: "flex", alignItems: "center", height: "28px" }}>
              <CardSubTitle>CONDITIONS</CardSubTitle>
              <FlexSpaceFiller />
              <IconButton
                onClick={() =>
                  dispatchTriggerConditionsRequest({
                    action: QueryEventAction.REQUEST_DATA,
                    payload: initialRequest,
                    originator: "Refresh Trigger Conditions",
                  })
                }
              >
                <RefreshIcon />
              </IconButton>
              <ActionButtonHandlerComponent
                buttonConfig={createTriggerConditionButtonConfig}
                id={null}
                object={null}
                eventDispatchers={props.eventDispatchers}
              />
            </div>
            {triggerConditions.dataArray.length === 0 ? (
              <div>
                <div>
                  <i>
                    <MultiLineCardValue>{"This trigger does not have any conditions."}</MultiLineCardValue>
                  </i>
                </div>
                <div>
                  <i>
                    <MultiLineCardValue>{"Click on + to configure it."}</MultiLineCardValue>
                  </i>
                </div>
              </div>
            ) : null}
            {triggerConditions.dataArray.map((dataArrayItem, index) => {
              const sampleFieldValue = `${getDictionaryLabelByCode(
                sampleFieldDictionary.data,
                dataArrayItem.sampleFieldId as number,
                "id",
                "name"
              )}`;

              return (
                <div
                  key={index}
                  className={clsx(
                    classes.containerWithHiddenForHooverElements,
                    classes.boxToHighlightOnHover
                  )}
                  style={{
                    height: "36px",
                    boxSizing: "border-box",
                    display: "flex",
                    padding: "4px 0",
                    alignItems: "center",
                  }}
                >
                  <div style={{ padding: "0 4px" }}>
                    <RegularCardValue>{sampleFieldValue}</RegularCardValue>
                  </div>
                  <div style={{ padding: "0 4px" }}>
                    <RegularCardValue>
                      {getDictionaryLabelByCode(valueTypeDictionary, dataArrayItem.valueType as number)}
                    </RegularCardValue>
                  </div>
                  <div style={{ padding: "0 4px" }}>
                    <RegularCardValue>
                      {getDictionaryLabelByCode(
                        conditionTypeDictionary,
                        dataArrayItem.conditionType as number
                      )}
                    </RegularCardValue>
                  </div>
                  <div style={{ padding: "0 4px" }}>
                    <RegularCardValue>{dataArrayItem.value as string | number}</RegularCardValue>
                  </div>
                  <div style={{ padding: "0 4px" }}>
                    {dataArrayItem.useForecast ? (
                      <OutlinedBadge htmlColor={"green"}>USE FORECAST</OutlinedBadge>
                    ) : (
                      <OutlinedBadge htmlColor={"grey"}>DO NOT USE FORECAST</OutlinedBadge>
                    )}
                  </div>
                  <div style={{ flexGrow: 1 }} />
                  <div className="showOnHover" style={{ padding: "0 4px" }}>
                    <ActionButtonHandlerComponent
                      buttonConfig={editTriggerConditionButtonConfig}
                      id={dataArrayItem.id}
                      object={dataArrayItem}
                      // eventDispatchers={eventDispatchersForForm}
                    />
                    <ActionButtonHandlerComponent
                      buttonConfig={deleteTriggerConditionButtonConfig}
                      id={dataArrayItem.id} // TODO: this id is ignored for some reason
                      object={dataArrayItem} //{dataArrayItem.dataObject}
                      eventDispatchers={eventDispatchersForForm}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Paper>
    </div>
  );
}
