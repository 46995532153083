import React from "react";
import { TableCellRendererProps } from "../TableCellRenderer.d";
import { getAttributeByPath } from "components/Crud/EntityUtils";
import { AssetNameAndTypeCardCellRenderer } from "./AssetNameAndTypeCardCellRenderer";
import { AssetMeasurementCardCellRenderer } from "./AssetMeasurementCardCellRenderer";
import { LatestAlertCardCellRenderer } from "./LatestAlertCardCellRenderer";
import { BasicActionSetRenderer } from "../BasicActionSetRenderer";
import { ConditionalRender } from "layouts/ConditionalRender";
import { useMediaQueryMatchDown } from "components/Utils/LayoutUtils";

export function SingleRowAssetCard(props: TableCellRendererProps) {
  const pendingNotificationsCount = getAttributeByPath(props.obj, "pendingNotificationCount");

  const latestPendingNotification = getAttributeByPath(props.obj, "latestPendingNotification");

  const isSmallScreen = useMediaQueryMatchDown("sm");

  if (isSmallScreen) {
    return (
      <div style={{ padding: "16px 0" }}>
        <div>
          <AssetNameAndTypeCardCellRenderer
            assetName={getAttributeByPath(props.obj, "name")}
            assetTypeId={getAttributeByPath(props.obj, "assetTypeId")}
            locationName={getAttributeByPath(props.obj, "site.name")}
            statusId={getAttributeByPath(props.obj, "id")}
            borderRight={false}
            borderLeft={false}
            borderBottom={true}
          />
        </div>
        <div style={{ padding: "16px 0", boxSizing: "border-box" }}>
          <AssetMeasurementCardCellRenderer
            samplePoints={getAttributeByPath(props.obj, "samplePoints")}
            borderRight={false}
            borderLeft={false}
          />
        </div>
        <div>
          <ConditionalRender condition={pendingNotificationsCount > 0}>
            <LatestAlertCardCellRenderer
              latestPendingNotification={latestPendingNotification}
              pendingNotificationsCount={pendingNotificationsCount}
              borderRight={false}
              borderLeft={false}
            />
          </ConditionalRender>
        </div>
        <div>
          <BasicActionSetRenderer
            obj={props.obj}
            fieldName={props.fieldName}
            actionProps={props.actionProps}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div
        style={{
          display: "flex",
          flexGrow: 1,
          minHeight: "120px",
          paddingTop: "24px",
          paddingBottom: "24px",
          boxSizing: "border-box",
        }}
      >
        <div style={{ boxSizing: "border-box", width: "258px" }}>
          <AssetNameAndTypeCardCellRenderer
            assetName={getAttributeByPath(props.obj, "name")}
            assetTypeId={getAttributeByPath(props.obj, "assetTypeId")}
            locationName={getAttributeByPath(props.obj, "site.name")}
            statusId={getAttributeByPath(props.obj, "id")}
            borderRight={true}
            borderLeft={false}
          />
        </div>
        <AssetMeasurementCardCellRenderer
          samplePoints={getAttributeByPath(props.obj, "samplePoints")}
          borderRight={false}
          borderLeft={false}
        />
        <div style={{ flexGrow: 1 }}>
          <ConditionalRender condition={pendingNotificationsCount > 0}>
            <LatestAlertCardCellRenderer
              latestPendingNotification={latestPendingNotification}
              pendingNotificationsCount={pendingNotificationsCount}
              borderRight={true}
              borderLeft={true}
            />
          </ConditionalRender>
        </div>
        <div>
          <BasicActionSetRenderer
            obj={props.obj}
            fieldName={props.fieldName}
            actionProps={props.actionProps}
          />
        </div>
      </div>
    );
  }
}
