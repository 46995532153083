import { StyleRulesCallback, Theme } from "@material-ui/core";

export const userEditFormLayoutStyle: StyleRulesCallback<Theme, {}> = (
  theme
) => ({
  emailInfoContainer: {
    display: "flex",
    alignItems: "center",
  },
  emailField: {
    flexGrow: 1,
  },
  sendVerificationBlock: {
    display: "flex",
    alignItems: "center",
    height: "42px",
  },
  resetPasswordBlock: {
    display: "flex",
    alignItems: "center",
    height: "42px",
  },
  isEmailVerifiedBlock: {
    // TODO: add properties to the Outlined Badge itself
    "& > div": {
      height: "36px",
    },
  },
  notWord: {
    display: "flex",
    justifyContent: "center",
  },
});
