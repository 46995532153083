import React, { useState, ChangeEvent, useContext } from "react";
import { Button, makeStyles, Paper } from "@material-ui/core";
import { switchEnterpriseStyle } from "components/switchEnterpriseStyle";
import { NONE_SELECT_CODE, MenuItemType } from "./Crud/Form/Fields/FieldConfiguration.d";
import {
  ENTERPRISE_API_URL,
  ALL_ENTERPRISES_LABEL,
  ALL_ENTERPRISES_CODE,
} from "views/Enterprise/Enterprise.const";
import { FieldConfiguration } from "components/Crud/Form/Fields/FieldConfiguration.d";
import { UserParams } from "views/User/UserParams.d";
import { BUTTON_VARIANT } from "./Buttons/ButtonsCommon";
import { isSpecified } from "./Utils/MiscUtils";
import { FormFieldRenderer } from "./Crud/Form/FormFieldRenderer";
import { ScreenTitle } from "./Elements/Typography/ScreenTitle";
import { ObjectIdType } from "./Utils/Object.d";
import { EnterpriseIdType } from "views/Enterprise/Enterprise.d";
import { GlobalEventContext } from "GlobalContext";
import { GlobalEvent } from "GlobalContext.d";

interface ScenarioSpecificSelectConfigType {
  fieldConfig: FieldConfiguration;
  extraStaticValues?: Array<MenuItemType>;
}

interface EnterpriseSelectionProps {
  userData: UserParams;
  currentEnterpriseId: EnterpriseIdType;
}

const FIELD_KEY = "enterpriseId";
const FIELD_LABEL = "Enterprise";

const useStyles = makeStyles(switchEnterpriseStyle, { name: "EnterpriseSelection" });

function EnterpriseSelection(props: EnterpriseSelectionProps) {
  const classes = useStyles({});

  const globalEventContext = useContext(GlobalEventContext);

  const userRoleSpecificDataAndConfig = (userData: UserParams) => {
    const resultConfig: ScenarioSpecificSelectConfigType = { fieldConfig: undefined };
    let enterpriseId: EnterpriseIdType;

    if (!userData.isSuperAdmin) {
      enterpriseId = isSpecified(props.currentEnterpriseId) ? props.currentEnterpriseId : NONE_SELECT_CODE;

      resultConfig.fieldConfig = {
        label: FIELD_LABEL,
        fieldName: FIELD_KEY,
        type: "select",
        fieldParams: {
          options: props.userData.memberships.map((membershipItem) => {
            return {
              code: membershipItem.enterprise.id,
              label: membershipItem.enterprise.name,
            };
          }),
        },
      };
    } else {
      enterpriseId = isSpecified(props.currentEnterpriseId)
        ? props.currentEnterpriseId
        : ALL_ENTERPRISES_CODE;

      resultConfig.fieldConfig = {
        label: FIELD_LABEL,
        fieldName: FIELD_KEY,
        type: "select-async",
        fieldParams: {
          optionDefinitionEndpoint: ENTERPRISE_API_URL,
          optionCodeField: "id",
          optionLabelField: "name",
          // TODO: decide once and for all. Extra static values OR render empty field as
          extraStaticValues: [{ code: ALL_ENTERPRISES_CODE, label: ALL_ENTERPRISES_LABEL }],
          autoComplete: true,
        },
      };
    }

    return { enterpriseId, resultConfig };
  };

  const sessionUserData = props.userData;

  const {
    enterpriseId: currentEnterpriseId,
    resultConfig: userRoleSpecificSelectConfig,
  } = userRoleSpecificDataAndConfig(sessionUserData);

  const [chosenEnterpriseId, setChosenEnterpriseId] = useState(currentEnterpriseId);

  const handleClickSwitchEnterprise = () => {
    globalEventContext(
      /* .dispatchEvent */ {
        type: GlobalEvent.CHANGE_CURRENT_ENTERPRISE,
        payload: { enterpriseId: chosenEnterpriseId },
        originator: "EnterpriseSelection.handleClickSwitchEnterprise",
      }
    );
  };

  return (
    <div>
      <div className={classes.titleContainer}>
        <ScreenTitle>Switch Enterprise</ScreenTitle>
      </div>
      <Paper>
        <div className={classes.formContainer}>
          <FormFieldRenderer
            fieldConfig={userRoleSpecificSelectConfig.fieldConfig}
            fieldValue={
              chosenEnterpriseId || (userRoleSpecificSelectConfig.fieldConfig.defaultValue as ObjectIdType)
            }
            initialFieldValue={
              props.currentEnterpriseId ||
              (userRoleSpecificSelectConfig.fieldConfig.defaultValue as ObjectIdType)
            }
            helperText={undefined}
            errorFlag={false}
            required={true}
            disabled={false}
            handleChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
              setChosenEnterpriseId(event.target.value)
            }
          />

          <div className={classes.buttonContainer}>
            <Button
              variant={BUTTON_VARIANT}
              color="primary"
              type="submit"
              onClick={handleClickSwitchEnterprise}
            >
              Switch
            </Button>
          </div>
        </div>
      </Paper>
    </div>
  );
}

export default EnterpriseSelection;
