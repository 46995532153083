import React from "react";
import { DataCrud, CRUD_DISPATCHER } from "../DataProvider/DataCrud";
import { DataCrudDataType, DataCrudEventType, DataCrudOperationStatusType } from "../DataProvider/DataCrud.d";
import { DataQuery, QUERY_DISPATCHER } from "../DataProvider/DataQuery";
import {
  DataQueryDataType,
  DataQueryOperationStatusType,
  DataQueryEventType,
  DataQueryOperationStatus,
} from "../DataProvider/DataQuery.d";
import { UnifiedDataRequestType } from "components/Utils/CRUDUtils.d";
import { EventDispatchersType, InjectedContentSignatureType } from "./StatelessTable.d";
import { StatelessTable } from "./StatelessTable";
import { DataSourceParamsType } from "../DataProvider/DataProviderCommon.d";
import {
  TableColumnParameters,
  TableColumnFilter,
  TableBodyLayoutType,
  OnRowClickFunctionType,
} from "./EntityTable.d";
import { ActionButtonConfig } from "components/Buttons/Buttons.d";

export interface CrudQueryTableBindingProps {
  dataSource: DataSourceParamsType;
  intialQuery: UnifiedDataRequestType;
  tableVariant?: TableBodyLayoutType;
  tableColumns: TableColumnParameters[];
  actionsAllowed?: Array<string>;
  radioChangeFunction?: (newValue: string | number | boolean) => void;
  title?: string | JSX.Element;
  externalTriggerValues?: Array<any>;
  highlightedRowsPrimaryKeys?: Array<any>;
  enableModeSwitch?: boolean;
  onRowClickFunction?: OnRowClickFunctionType;
  disableFilters?: boolean;
  disablePaginationSection?: boolean;

  additionalHeaderActions?: Array<string | JSX.Element>; // TODO: refactor properly
  headerActions?: Array<ActionButtonConfig>;

  uiFilters?: Array<TableColumnFilter>;
  disableTableColumns?: boolean;
  injectedContent?: InjectedContentSignatureType;
  paperless?: boolean;
  contentToDisplayWhenNoData?: string | JSX.Element;
  autoFocusOnFirstRow?: boolean;
  allowReinitialization?: boolean;
  allowUrlReplacementForFilters?: boolean;
  allowedTableVariants?: Array<TableBodyLayoutType>;
  restrictActions?: Array<string>;
}

export function CrudQueryTableBinding(props: CrudQueryTableBindingProps) {
  return (
    <DataCrud
      dataSource={props.dataSource}
      render={(
        crudData: DataCrudDataType,
        crudRequest: DataCrudEventType,
        crudStatus: DataCrudOperationStatusType,
        dispatchCrudEvent: (event: DataCrudEventType) => void
      ) => {
        return (
          <DataQuery
            externalTriggerValues={[crudData.transactionId, ...props.externalTriggerValues]}
            dataSource={props.dataSource}
            initialQuery={props.intialQuery}
            allowReinitialization={props.allowReinitialization}
            render={(
              queryData: DataQueryDataType,
              queryParams: UnifiedDataRequestType,
              queryStatus: DataQueryOperationStatusType,
              dispatchQueryEvent: (event: DataQueryEventType) => void
            ) => {
              const eventDispatchers: EventDispatchersType = {};
              eventDispatchers[QUERY_DISPATCHER] = dispatchQueryEvent;
              eventDispatchers[CRUD_DISPATCHER] = dispatchCrudEvent;

              return (
                <StatelessTable
                  title={props.title}
                  paperless={props.paperless}
                  isDataLoading={queryStatus.state === DataQueryOperationStatus.LOADING}
                  tableColumns={props.tableColumns}
                  tableData={queryData.data}
                  totalRows={queryData.totalRows}
                  eventDispatchers={eventDispatchers}
                  queryParams={queryParams}
                  tableVariant={props.tableVariant}
                  actionsAllowed={props.actionsAllowed}
                  enableModeSwitch={props.enableModeSwitch}
                  radioChangeFunction={props.radioChangeFunction}
                  highlightedRowsPrimaryKeys={props.highlightedRowsPrimaryKeys}
                  onRowClickFunction={props.onRowClickFunction}
                  disableFilters={props.disableFilters}
                  disablePaginationSection={props.disablePaginationSection}
                  disableTableColumns={props.disableTableColumns}
                  uiFilters={props.uiFilters}
                  additionalContent={dispatchCrudEvent}
                  injectedContent={props.injectedContent}
                  headerActions={props.headerActions}
                  contentToDisplayWhenNoData={props.contentToDisplayWhenNoData}
                  autoFocusOnFirstRow={props.autoFocusOnFirstRow}
                  allowUrlReplacementForFilters={props.allowUrlReplacementForFilters}
                  allowedTableVariants={props.allowedTableVariants}
                  restrictActions={props.restrictActions}
                />
              );
            }}
          />
        );
      }}
    />
  );
}
