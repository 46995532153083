import React from "react";
import { Dialog, makeStyles } from "@material-ui/core";
import { ObjectWithIdFieldType } from "components/Utils/Object.d";
import { isSpecified } from "components/Utils/MiscUtils";
import { EntityActionType } from "components/Crud/Entity.d";
import { popupDialogComponentStyle } from "./popupDialogComponentsStyle";
import { useMediaQueryMatchDown } from "components/Utils/LayoutUtils";
import clsx from "clsx";
import { useActiveUser, useActiveEnterprise } from "components/Utils/CustomHooks";
import { DialogIdCallableType, DialogParamsType } from "./PopupDialogsFrame.d";

export interface PopupDialogComponentsProps {
  // userData: UserParams;
  // currentEnterpriseId: EnterpriseIdType;
  tableData?: Array<ObjectWithIdFieldType>;
  openDialogId?: DialogIdCallableType;
  dialogParams?: DialogParamsType; // {id?: ObjectIdType, action?: string, extraPropsObject?: Record<string, any>, dataObject?: Record<string, any>};
  closeDialogWindow: (wasDataChanged?: boolean) => void;
}

const useStyles = makeStyles(popupDialogComponentStyle, { name: "PopupDialogComponents" });

export function PopupDialogComponents(props: PopupDialogComponentsProps) {
  const classes = useStyles({});
  const isSmallScreen = useMediaQueryMatchDown("sm");

  let DialogComponent: JSX.Element;

  const userData = useActiveUser();
  const enterpriseData = useActiveEnterprise();

  if (props.openDialogId) {
    const id = props.dialogParams ? props.dialogParams.id : undefined;
    const action = props.dialogParams ? props.dialogParams.action : undefined;
    const additionalParams =
      props.dialogParams && props.dialogParams.extraPropsObject ? props.dialogParams.extraPropsObject : {};
    const dataObject = props.dialogParams ? props.dialogParams.dataObject : undefined;

    const DialogContentComponent = props.openDialogId;

    DialogComponent = (
      <Dialog
        open={true}
        classes={{
          paper: clsx(classes.dialogPaperBody, isSmallScreen && classes.dialogPaperBodySmallScreen),
        }}
        maxWidth={false}
      >
        <DialogContentComponent
          userData={userData /* props.userData */}
          currentEnterpriseId={enterpriseData.id /* props.currentEnterpriseId */}
          id={id}
          action={action as EntityActionType}
          showCloseButton={true}
          title={
            isSpecified(additionalParams) && isSpecified(additionalParams.title)
              ? additionalParams.title
              : action
          }
          warning={
            isSpecified(additionalParams) && isSpecified(additionalParams.warning)
              ? additionalParams.warning
              : undefined
          }
          onCloseAdditionalActions={props.closeDialogWindow}
          {...additionalParams}
          dataObject={dataObject}
        />
      </Dialog>
    );
  }

  return <div>{DialogComponent}</div>;
}
