import { EnterpriseIdType } from "views/Enterprise/Enterprise.d";

export enum GlobalEvent {
  CHANGE_CURRENT_ENTERPRISE = "CHANGE_CURRENT_ENTERPRISE",
  REFRESH_SIDEBAR = "REFRESH_SIDEBAR",
  NAVIGATE = "NAVIGATE",
  LOGOUT = "LOGOUT",
  LOGIN = "LOGIN",
  SET_SIDEBAR_OPEN = "SET_SIDEBAR_OPEN",
  TOGGLE_SIDEBAR_IS_OPEN = "TOGGLE_SIDEBAR_IS_OPEN",
  SET_ACTIVE_USER = "SET_ACTIVE_USER",
}

export interface GlobalEventMessageType {
  type: GlobalEvent;
  payload?: any;
  originator: string; // Event originator for logging, debugging and tracing purposes
}

// TODO: cleanup and add globally commented - /* .dispatchEvent */
/* export interface GlobalEventDispatcherContextType {
  dispatchEvent: (event: GlobalEventMessageType) => void;
} */
export type GlobalEventDispatcherContextType = (event: GlobalEventMessageType) => void;

export interface EnterpriseDataType {
  id: EnterpriseIdType;
  name: string;
}
