import * as React from "react";
import { FieldConfiguration } from "components/Crud/Form/Fields/FieldConfiguration.d";
import { SITE_API_URL } from "./Site.const";
import { ENTERPRISE_API_URL, ALL_ENTERPRISES_CODE } from "views/Enterprise/Enterprise.const";
import { UserUtils } from "views/User/UserCommon";
import { DataCrudEntityFormBinding } from "components/Crud/Form/DataCrudEntityFormBinding";
import { API_LIB } from "components/Utils/API_LIB";
import { SiteEditFormLayout } from "./SiteEditFormLayout";
import { useTheme } from "@material-ui/core";
import { SiteShippingDetailsSourceEnum } from "./Site.d";
import { siteShippingDetailsSourceOptions } from "./Site.dict";
import { EntityFormParams } from "components/Crud/Form/EntityForm.d";

export function SiteEditForm(props: EntityFormParams) {
  const userData = props.userData;
  const showEnterpriseField = UserUtils.hasMultipleMemberships(userData) || userData.isSuperAdmin;
  const theme = useTheme();

  const getFieldConfigs = (): FieldConfiguration[] => {
    return [
      {
        fieldName: "name",
        label: "Site Name",
        type: "text",
        validation: {
          required: true,
        },
      },
      {
        fieldName: "timezoneId",
        label: "Time Zone",
        type: "select-async", // TODO create variables
        fieldParams: {
          optionDefinitionEndpoint: "/timezone",
          optionCodeField: "id",
          optionLabelField: "code",
        },
        validation: {
          required: true,
        },
      },
      {
        fieldName: "enterpriseId",
        label: "Enterprise",
        type: showEnterpriseField ? "select-async" : "hidden",
        defaultValue:
          props.currentEnterpriseId !== ALL_ENTERPRISES_CODE ? props.currentEnterpriseId : undefined,
        disabled: props.currentEnterpriseId !== ALL_ENTERPRISES_CODE,
        fieldParams: {
          optionDefinitionEndpoint: ENTERPRISE_API_URL,
          optionCodeField: "id",
          optionLabelField: "name",
          autoComplete: true,
        },
        validation: {
          required: showEnterpriseField,
        },
      },
      // Billing Address
      {
        fieldName: "useEnterpriseBilling",
        label: "Use Enterprise Billing Address",
        type: "boolean",
        defaultValue: true,
        fieldParams: { color: theme.palette.primary.main },
      },
      {
        fieldName: "billingAddress.name",
        label: "Name",
        type: "text",
      },
      {
        fieldName: "billingAddress.line1",
        label: "Line 1",
        type: "text",
      },
      {
        fieldName: "billingAddress.line2",
        label: "Line 2",
        type: "text",
      },
      {
        fieldName: "billingAddress.city",
        label: "City",
        type: "text",
      },
      {
        fieldName: "billingAddress.postCode",
        label: "Post Code",
        type: "text",
      },
      {
        fieldName: "billingAddress.stateId",
        label: "State",
        type: "select-async",
        fieldParams: {
          optionDefinitionEndpoint: API_LIB.ADDRESS_STATES,
          optionCodeField: "id",
          optionLabelField: "abbreviation",
        },
      },
      // Shipping Address
      {
        fieldName: "shippingDetailsSource",
        label: "Shipping Address Source",
        type: "select",
        defaultValue: SiteShippingDetailsSourceEnum.ENTERPRISE_SHIPPING,
        fieldParams: { options: siteShippingDetailsSourceOptions },
      },
      {
        fieldName: "shippingAddress.name",
        label: "Name",
        type: "text",
      },
      {
        fieldName: "shippingAddress.line1",
        label: "Line 1",
        type: "text",
      },
      {
        fieldName: "shippingAddress.line2",
        label: "Line 2",
        type: "text",
      },
      {
        fieldName: "shippingAddress.city",
        label: "City",
        type: "text",
      },
      {
        fieldName: "shippingAddress.postCode",
        label: "Post Code",
        type: "text",
      },
      {
        fieldName: "shippingAddress.stateId",
        label: "State",
        type: "select-async",
        fieldParams: {
          optionDefinitionEndpoint: API_LIB.ADDRESS_STATES,
          optionCodeField: "id",
          optionLabelField: "abbreviation",
        },
      },
    ];
  };

  const dataSource = {
    apiUrl: SITE_API_URL,

    // customUrlRequestFragment: `groupId=${props.assetGroupId}`
  };

  return (
    <DataCrudEntityFormBinding
      id={props.id} // TODO: do we need this???
      dataSource={dataSource}
      action={props.action}
      title={props.title}
      fieldConfigurations={getFieldConfigs()}
      onCloseAdditionalActions={props.onCloseAdditionalActions}
      showCloseButton={props.showCloseButton}
      userData={props.userData}
      currentEnterpriseId={props.currentEnterpriseId}
      layoutComponent={SiteEditFormLayout}
    />
  );
}
