import { AlertAcknowledgementStatus } from "./Alert.d";

export const alertNotificationDictionary = [
  {
    code: AlertAcknowledgementStatus.RAISED,
    label: "Not Acknowledged",
  },
  {
    code: AlertAcknowledgementStatus.ACKNOWLEDGED,
    label: "Acknowledged",
  },
  {
    code: AlertAcknowledgementStatus.SENT,
    label: "Sent",
    hideForInput: true,
  },
  {
    code: AlertAcknowledgementStatus.DELIVERY_FAILED,
    label: "Delivery Failed",
    hideForInput: true,
  },
];
