import React from "react";
import { makeStyles } from "@material-ui/core";
import { assetCardCellStyle } from "./assetCardCellStyle";
import { assetTypeIconDictionaryWithTooltips } from "components/Dictionaries/DictionariesCommon";
import { CardCellLabelVariantType, AssetCardCellContainer } from "./AssetCardCellContainer";
import clsx from "clsx";
import { regularCardValueStyle } from "components/Elements/Typography/regularCardValueStyle";

export interface AssetNameAndTypeCardCellRendererProps {
  assetName: string;
  assetTypeId: number;
  locationName: string;
  statusId: number;
  borderRight: boolean;
  borderLeft: boolean;
  borderBottom?: boolean;
}

const useStyles = makeStyles(assetCardCellStyle, { name: "AssetNameAndTypeCardCellRenderer" });
const useStylesRegularCard = makeStyles(regularCardValueStyle, { name: "AssetNameAndTypeCardCellRenderer" });

export function AssetNameAndTypeCardCellRenderer(props: AssetNameAndTypeCardCellRendererProps) {
  const classes = useStyles({});
  const classesRegularCard = useStylesRegularCard({});

  const assetTypeSegmentItem = assetTypeIconDictionaryWithTooltips.find(
    (item) => item.code === props.assetTypeId
  );

  let assetTypeSegment: JSX.Element;

  if (assetTypeSegmentItem) {
    assetTypeSegment = assetTypeSegmentItem.labelElement;
  }

  return (
    <AssetCardCellContainer
      labelVariant={CardCellLabelVariantType.NONE}
      contentVerticalAlignment="top"
      borderLeft={props.borderLeft}
      borderRight={props.borderRight}
      borderBottom={props.borderBottom}
    >
      <div style={{ display: "flex" /* , width: '258px' */ }}>
        <div className={classes.assetIcon}>{assetTypeSegment}</div>
        <div style={{ width: "180px" }}>
          <div className={clsx(classes.assetName, classes.ellipsisOnOverflow)}>{props.assetName}</div>
          <div className={clsx(classes.ellipsisOnOverflow, classesRegularCard.root)}>
            {props.locationName}
          </div>
          <div>
            <div className={classes.statusSegment}>
              {/* <ColorDisc size={12} htmlBackgroundColor={statusColor}/>
              <div style={{paddingLeft: '8px'}}><SubordinateCardValue>{statusSegment}</SubordinateCardValue></div> */}
            </div>
          </div>
        </div>
      </div>
    </AssetCardCellContainer>
  );
}

/*
  const classes = useStyles({});

  let assetTypeSegmentItem = assetTypeIconDictionaryWithTooltips.find(item => item.code === props.assetTypeId);

  let assetTypeSegment: JSX.Element;

  if (assetTypeSegmentItem) {
    assetTypeSegment = assetTypeSegmentItem.labelElement;
  }

  const nameRef = useRef(null);
  const [ displayEllipsis, setDisplayEllipsis ] = useState(false);

  useEffect(() => {
    if (nameRef.current.scrollHeight > nameRef.current.clientHeight) {
      setDisplayEllipsis(true);
    }
  }, []);

  return (
    <AssetCardCellContainer
      labelVariant={CardCellLabelVariantType.NONE}
      contentVerticalAlignment="top"
      borderLeft={props.borderLeft}
      borderRight={props.borderRight}
    >
      <div style={{display: 'flex',  }}>
      <div className={classes.assetIcon}>{assetTypeSegment}</div>
      <div>
        <div ref={nameRef} style={{overflow: 'hidden',  maxHeight: '60px'}} className={classes.assetName}>{props.assetName}</div>
        <ConditionalRender condition={displayEllipsis}>
          <div style={{lineHeight: '12px'}}>...</div>
        </ConditionalRender>
      </div>
    </div>      
  </AssetCardCellContainer>
);
}
*/
