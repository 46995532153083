import React from "react";
import { makeStyles } from "@material-ui/core";
import { secondaryCardValueStyle } from "./secondaryCardValueStyle";

export interface SecondaryCardValueProps {
  children: string;
}

const useStyles = makeStyles(secondaryCardValueStyle, { name: "SecondaryCardValue" });

export function SecondaryCardValue(props: SecondaryCardValueProps) {
  const classes = useStyles({});

  return <span className={classes.root}>{props.children}</span>;
}
