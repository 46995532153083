export const SENSOR_API_URL = "/sensor";
export const SENSOR_ROUTE = "/sensor";
export const SENSOR_SAMPLE_API_URL = "/sensor/sample";
export const SENSOR_SAMPLE_ROUTE = "/sensor/sample";
export const SENSOR_BY_MONITOR_CONFIGURATION_ROUTE =
  "/monitor/:monitorId/configuration/:monitorConfigurationId/sensor";
export const SENSOR_BY_MONITOR_ROUTE = "/monitor/:monitorId/sensor";
export const SENSOR_MONITOR_API_URL = "/sensor/by_monitor_configuration";
export const SENSOR_ENTITY_NAME = "sensor";

export const SENSOR_GRAPH_ACTION = "sensor_graph";
export const SENSOR_GRAPH_ROUTE = "/app/sensor/details/:sensorId";
