import React from "react";
import { makeStyles } from "@material-ui/core";
import { assetCardCellStyle } from "./assetCardCellStyle";
import clsx from "clsx";

export enum CardCellLabelVariantType {
  NORMAL = "normal",
  NONE = "none",
}

export type CardCellContentVerticalAligmentType = "top" | "middle" | "bottom";

export const CONTENT_ALIGN_TOP: CardCellContentVerticalAligmentType = "top";
export const CONTENT_ALIGN_MIDDLE: CardCellContentVerticalAligmentType = "middle";
export const CONTENT_ALIGN_BOTTOM: CardCellContentVerticalAligmentType = "bottom";

export interface AssetCardCellContainerProps {
  label?: string | JSX.Element; // TODO: add support for JSX.Element
  labelVariant: CardCellLabelVariantType;
  children: JSX.Element | Array<JSX.Element>;
  contentVerticalAlignment: CardCellContentVerticalAligmentType;
  borderRight?: boolean;
  borderLeft?: boolean;
  borderBottom?: boolean;
}

const useStyles = makeStyles(assetCardCellStyle, { name: "AssetCardCellContainer" });

export function AssetCardCellContainer(props: AssetCardCellContainerProps) {
  const classes = useStyles({});

  const cardWrapperClassName = clsx(classes.root);

  const innerBoxClassName = clsx(classes.cardCellInnerBoxRowFormat, {
    [classes.cellBorderRight]: props.borderRight,
    [classes.cellBorderLeft]: props.borderLeft,
    [classes.cellBorderBottom]: props.borderBottom,
  });

  const headerClassName = clsx(classes.cardCellHeader, {
    // TODO: restyle using CardSubTitle component
    [classes.cardCellTitleText]: props.labelVariant === CardCellLabelVariantType.NORMAL,
    [classes.cardCellTitleNone]: props.labelVariant === CardCellLabelVariantType.NONE,
  });

  const contentClassName = clsx(classes.cardCellContent, {
    [classes.cardCellContentTop]: props.contentVerticalAlignment === CONTENT_ALIGN_TOP,
    [classes.cardCellContentMiddle]: props.contentVerticalAlignment === CONTENT_ALIGN_MIDDLE,
    [classes.cardCellContentBottom]: props.contentVerticalAlignment === CONTENT_ALIGN_BOTTOM,
  });

  return (
    <div className={cardWrapperClassName}>
      <div className={innerBoxClassName}>
        <div className={headerClassName}>{props.label}</div>
        <div className={contentClassName}>{props.children}</div>
      </div>
    </div>
  );
}
