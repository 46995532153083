import React from "react";

import { SamplePointGraphDataView } from "./SamplePointGraphDataView";
import { BaseEntityProps } from "components/Crud/Entity.d";
import { Paper } from "@material-ui/core";

export interface SamplePointGraphPageProps {
  assetId: number;
}

function SamplePointGraphPage(props: BaseEntityProps & SamplePointGraphPageProps) {
  return (
    <Paper style={{ boxSizing: "border-box", height: "100%" }}>
      <SamplePointGraphDataView title="Asset Graph" assetId={props.assetId} />
    </Paper>
  );
}

export default SamplePointGraphPage;
