import "date-fns";
import React from "react";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  KeyboardDateTimePicker,
  DatePicker,
  TimePicker,
  DateTimePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment-v1";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import OutlinedFieldWrapper from "./OutlinedFieldWrapper";
import { materialUIPickersStyle } from "./materialUIPickersStyle";
import { useMediaQueryMatchDown } from "components/Utils/LayoutUtils";
import { safeFieldValue } from "../EntityFormCommon";
import moment, { Moment } from "moment-timezone";
import { isSpecified } from "components/Utils/MiscUtils";
import { DatePickerView } from "@material-ui/pickers/DatePicker/DatePicker";
import { CustomThemeType } from "assets/jss/muiTheme";

export type TypePickerType = "date" | "time" | "date-time";
export type TypeBehavior = "inline" | "dialog";

export interface DatePickerProps {
  // TODO: add field config
  pickerType: TypePickerType;
  behavior: TypeBehavior;
  format?: string;
  value: Date | number | string; // TODO: number and string added because of TypeScript error
  defaultValue?: Date | number | string;
  onChange: (event: any) => void; // (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void; // Function;
  id: string;
  name?: string; // TODO: duplication
  label?: string;
  disabled?: boolean;
  variant?: "outlined" | "filled" | "standard";
  helperText?: string;
  errorFlag?: boolean;
  required?: boolean;
  dateRangeToHighlight?: Partial<any>;
  disableFuture?: boolean;
  datePickerVariant?: undefined | "inline" | "static";
  timeZone?: string;
  valueWasModified?: boolean;
}

const MLSECS_IN_DAY = 24 * 60 * 60 * 1000;

// TODO: test if it works correctly for timezones
// TODO: Ability to select a range. Public request: https://github.com/mui-org/material-ui-pickers/issues/364

const useStyles = makeStyles(materialUIPickersStyle, { name: "MaterialUIPickers" });

export function MaterialUIPickers(props: DatePickerProps) {
  const classes = useStyles({});

  const isSmallScreen = useMediaQueryMatchDown("sm");

  let safeValue = safeFieldValue(props.value, props.defaultValue, null);

  if (typeof safeValue === "number" || typeof safeValue === "string") {
    safeValue = new Date(safeValue);
  }

  safeValue = safeValue && props.timeZone ? moment(safeValue).tz(props.timeZone) : safeValue;

  // TODO: implement statefull and stateless components scenarios
  // const [selectedDate, setSelectedDate] = React.useState<Date | null>(
  //   new Date('2014-08-18T21:11:54'),
  // );

  const handleDateChange = (date: Moment) => {
    // TODO: implement statefull and stateless components scenarios setSelectedDate(date);
    const dateToSend = date;

    if (props.pickerType === "date" && isSpecified(dateToSend)) {
      dateToSend.startOf("day");
    }

    let valueToSend = null;
    if (isSpecified(dateToSend)) {
      valueToSend = dateToSend.toDate();
    }

    const changeEventToDispatch = {
      target: { name: props.id, value: valueToSend /* .getTime()*/ },
    };

    props.onChange(changeEventToDispatch); // Mimick how other fields do it
  };

  const customConfigBasedOnBehavior = (behavior: TypeBehavior) => {
    const resultConfig: Partial<any> = {};

    if (behavior === "inline") {
      resultConfig.disableToolbar = true;
      resultConfig.variant = "inline";
      resultConfig.autoOk = true;
    } else if (behavior === "dialog") {
      // do nothing
    }

    return resultConfig;
  };

  const getMUIPickerByType = (pickerType: TypePickerType) => {
    switch (pickerType) {
      case "time":
        return isSmallScreen ? TimePicker : KeyboardTimePicker;
      case "date-time":
        return isSmallScreen ? DateTimePicker : KeyboardDateTimePicker;
      case "date":
        return isSmallScreen ? DatePicker : KeyboardDatePicker;
      default:
        return isSmallScreen ? DatePicker : KeyboardDatePicker;
    }
  };

  const MUIPickerComponent = getMUIPickerByType(props.pickerType);

  const renderDay = (
    day: Moment,
    selectedDate: Moment,
    dayInCurrentMonth: boolean,
    dayComponent: JSX.Element
  ) => {
    // TODO: test for the date time operation

    if (props.dateRangeToHighlight) {
      const rangeStartMlsec = props.dateRangeToHighlight.start.getTime();
      const rangeEndMlsec = props.dateRangeToHighlight.end.getTime();
      const dayMsecs = day.toDate().getTime();

      let dayComponentToDraw = dayComponent;

      if (
        rangeEndMlsec - rangeStartMlsec < MLSECS_IN_DAY &&
        rangeStartMlsec <= dayMsecs &&
        dayMsecs <= rangeEndMlsec
      ) {
        dayComponentToDraw = <div className={classes.singleDayRange}>{dayComponentToDraw}</div>;
      } else if (rangeStartMlsec + MLSECS_IN_DAY <= dayMsecs && dayMsecs <= rangeEndMlsec - MLSECS_IN_DAY) {
        dayComponentToDraw = <div className={classes.dateBetween}>{dayComponentToDraw}</div>;
      } else if (rangeStartMlsec <= dayMsecs && dayMsecs <= rangeStartMlsec + MLSECS_IN_DAY - 1) {
        dayComponentToDraw = (
          <div className={classes.startDateBackground}>
            <div className={classes.startDate}>{dayComponentToDraw}</div>
          </div>
        );
      } else if (rangeEndMlsec - MLSECS_IN_DAY + 1 <= dayMsecs && dayMsecs <= rangeEndMlsec) {
        dayComponentToDraw = (
          <div className={classes.endDateBackground}>
            <div className={classes.endDate}>{dayComponentToDraw}</div>
          </div>
        );
      }

      return dayComponentToDraw;
    } else {
      return dayComponent;
    }
  };

  const theme = useTheme() as CustomThemeType;

  return (
    <OutlinedFieldWrapper>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <MUIPickerComponent
          style={{
            borderRadius: "4px",
            backgroundColor: props.valueWasModified ? theme.customColors.modifiedField : undefined,
          }}
          error={props.errorFlag}
          required={props.required}
          helperText={props.helperText}
          {...customConfigBasedOnBehavior(props.behavior)}
          format={props.format}
          disabled={props.disabled}
          disableFuture={props.disableFuture}
          // margin="normal"
          inputVariant={props.variant}
          id={props.id}
          name={props.name}
          label={props.label}
          value={safeValue} // props.value || null/*TODO: implement stateful and stateless components scenarios selectedDate*/}
          onChange={handleDateChange}
          renderDay={renderDay}
          size="small"
          variant={props.datePickerVariant}
          // weird looking, but otherwise it complains
          views={
            ["date"] as ("hours" | "minutes" | "seconds")[] &
              ("date" | "year" | "month" | "hours" | "minutes")[] &
              DatePickerView[]
          }
        />
      </MuiPickersUtilsProvider>
    </OutlinedFieldWrapper>
  );
}
