import React, { useState } from "react";
import { FormLayoutElement } from "components/Crud/Form/Layout/FormLayoutElement";
import { FormLayoutProps } from "components/Crud/Form/Layout/FormLayout.d";
import { CardSubTitle } from "components/Elements/Typography/CardSubTitle";
import { ConditionalRender } from "layouts/ConditionalRender";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { IconButton } from "@material-ui/core";
import { useActiveUser } from "components/Utils/CustomHooks";
import { EDIT_ACTION } from "components/Crud/Entity.const";
import { FAKE_UNITS_FIELD } from "./Trigger.formFields";

export function TriggerFormLayout(props: FormLayoutProps) {
  const [showAdvancedParametersFlag, setShowAdvancedParametersFlag] = useState(false);

  const showAdvancedParametersDecision = showAdvancedParametersFlag;

  const user = useActiveUser();

  return (
    <div>
      <FormLayoutElement fieldId="assetId" {...props} />
      <div style={{ height: "40px", display: "flex", alignItems: "center" }}>
        <CardSubTitle>{"BASE PARAMETERS"}</CardSubTitle>
      </div>
      <FormLayoutElement fieldId="eventLevel" {...props} />
      <div style={{ display: "flex" }}>
        <div style={{ flexBasis: 0, flexGrow: 0.5 }}>
          <FormLayoutElement fieldId="conditions[0].samplePoint.id" {...props} />
        </div>
        <div style={{ flexBasis: 0, flexGrow: 0.5 }}>
          <FormLayoutElement fieldId="conditions[0].sampleField.id" {...props} />
        </div>
      </div>
      <div style={{ display: "flex" }}>
        <div style={{ flexBasis: 0, flexGrow: 0.3 }}>
          <FormLayoutElement fieldId="conditions[0].valueType" {...props} />
        </div>
        <div style={{ flexBasis: 0, flexGrow: 0.3 }}>
          <FormLayoutElement fieldId="conditions[0].conditionType" {...props} />
        </div>
        <div style={{ flexBasis: 0, flexGrow: 0.25 }}>
          <FormLayoutElement fieldId="conditions[0].value" {...props} />
        </div>
        <div style={{ flexBasis: 0, flexGrow: 0.15 }}>
          <FormLayoutElement fieldId={FAKE_UNITS_FIELD} {...props} />
        </div>
        {/* <FormLayoutElement fieldId="units" {...props}/> */}
      </div>
      <FormLayoutElement fieldId="message" {...props} />
      <ConditionalRender condition={props.action === EDIT_ACTION}>
        <FormLayoutElement fieldId="isEnabled" {...props} />
      </ConditionalRender>
      <ConditionalRender condition={user.isSuperAdmin}>
        <ConditionalRender
          condition={showAdvancedParametersDecision}
          onFalse={
            <div style={{ height: "40px", display: "flex", alignItems: "center" }}>
              <CardSubTitle>{"ADVANCED PARAMETERS"}</CardSubTitle>
              <IconButton onClick={() => setShowAdvancedParametersFlag(true)}>
                <ExpandMoreIcon />
              </IconButton>
            </div>
          }
          onTrue={
            <React.Fragment>
              <div style={{ height: "40px", display: "flex", alignItems: "center" }}>
                <CardSubTitle>{"ADVANCED PARAMETERS"}</CardSubTitle>
                <IconButton onClick={() => setShowAdvancedParametersFlag(false)}>
                  <ExpandLessIcon />
                </IconButton>
              </div>
              <FormLayoutElement fieldId="conditions[0].useForecast" {...props} />
              <FormLayoutElement fieldId="conditions[0].params[window]" {...props} />
              <FormLayoutElement fieldId="timeBeforeReTrigger" {...props} />
              <FormLayoutElement fieldId="conditions[0].params[thresholdSensitivity]" {...props} />
              <FormLayoutElement fieldId="conditions[0].params[duration]" {...props} />
              <FormLayoutElement fieldId="comments" {...props} />
            </React.Fragment>
          }
        />
      </ConditionalRender>
    </div>
  );
}

// TODO: Previous Config: remove in July
//      <div style={{display: 'flex'}}>
// <div style={{flexBasis: 0, flexGrow: 0.5}}><FormLayoutElement fieldId="name" {...props}/></div>
// <div style={{flexBasis: 0, flexGrow: 0.5}}><FormLayoutElement fieldId="eventLevel" {...props}/></div>
// </div>
