import React from "react";
import { MonitorList } from "./MonitorList";
import { MainPanelWithParamsProps } from "components/Dashboard/MainPanelWithParams.d";
import { parseSearchStringIntoScreenRequest } from "components/Utils/RouteUtils";

export interface MonitorInventoryScreenProps {}

export function MonitorInventoryScreen(props: MainPanelWithParamsProps<MonitorInventoryScreenProps>) {
  const requestFromUrl = parseSearchStringIntoScreenRequest(props.location);

  return (
    <MonitorList
      userData={props.userData}
      currentEnterpriseId={props.currentEnterpriseId}
      inputRequest={requestFromUrl.request}
      allowUrlReplacementForFilters={true}
      allowReinitialization={true}
    />
  );
}
