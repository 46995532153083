import { fullColorHex } from "components/Utils/MiscUtils";
import { IntervalOptions, ComponentModeType } from "./Graph.d";
import { RadioOptionType } from "components/Radio/CustomRadio";
import { SampleTypeEnum } from "components/Dictionaries/Dictionaries.d";

export const intervalOptions = [
  { code: IntervalOptions.CUSTOM_INTERVAL, label: "Custom" },
  { code: IntervalOptions.TWO_DAYS, label: "2 days" },
  { code: IntervalOptions.TWO_WEEKS, label: "2 weeks" },
  { code: IntervalOptions.ONE_MONTH, label: "1 month" },
];

export const intervalMap = new Map();
intervalMap.set(IntervalOptions.ONE_DAY, { days: 1 }); // (2 * MLSECS_IN_DAY));
intervalMap.set(IntervalOptions.TWO_DAYS, { days: 2 }); // (2 * MLSECS_IN_DAY));
intervalMap.set(IntervalOptions.TWO_WEEKS, { weeks: 2 }); // (14 * MLSECS_IN_DAY));
// TODO: implement smart date manipulation for month to factor in 31, 30, 28 and 29 month days
intervalMap.set(IntervalOptions.ONE_MONTH, { months: 1 }); // (30 * MLSECS_IN_DAY));

export const DEFAULT_CHART_COLOR_KEY = "defaultChartColorKey";

export const CHART_COLOR_MAP = new Map();
CHART_COLOR_MAP.set(DEFAULT_CHART_COLOR_KEY, fullColorHex(0, 131, 72));

CHART_COLOR_MAP.set(SampleTypeEnum.LEVEL, fullColorHex(45, 128, 194));
CHART_COLOR_MAP.set(SampleTypeEnum.RAIN_FALL, fullColorHex(138, 0, 122));
CHART_COLOR_MAP.set(SampleTypeEnum.PRESSURE, fullColorHex(134, 44, 254));
CHART_COLOR_MAP.set(SampleTypeEnum.ELECTRIC_FENCE_POWER, fullColorHex(229, 100, 255));
// CHART_COLOR_MAP.set('Average Pressure', fullColorHex(46, 76, 26));
// CHART_COLOR_MAP.set('Flow In' /* In*/, fullColorHex(154, 154, 154));
// CHART_COLOR_MAP.set('Flow Out 1', fullColorHex(255, 215, 65));
CHART_COLOR_MAP.set(SampleTypeEnum.FLOW, fullColorHex(123, 91, 80));

export const dataDisplayOptions: Array<RadioOptionType> = [
  { code: ComponentModeType.GRAPH, label: "GRAPH", labelElement: null },
  { code: ComponentModeType.TABLE, label: "TABLE", labelElement: null },
];

export const graphDataSetRetrievalUrl = (
  sourceUrl: string,
  sampleTypeId: SampleTypeEnum,
  id: number,
  startDate: Date,
  endDate: Date
) => {
  let resultUrl = `${sourceUrl}/${id}/${startDate.getTime()}/${endDate.getTime()}`;

  if (sampleTypeId === SampleTypeEnum.RAIN_FALL) {
    resultUrl = `${resultUrl}?aggregationType=DAY`;
  }

  return resultUrl;
};
