import React from "react";
import { makeStyles } from "@material-ui/core";
import { EnterpriseIdType } from "views/Enterprise/Enterprise.d";
import { UserParams } from "views/User/UserParams.d";
import { headerEnterpriseNameStyle } from "./headerEnterpriseNameStyle";

export interface HeaderEnterpriseNameProps {
  userData: UserParams;
  currentEnterpriseId: EnterpriseIdType;
  currentEnterpriseName: string;
}

const useStyles = makeStyles(headerEnterpriseNameStyle, { name: "HeaderEnterpriseName" });

export function HeaderEnterpriseName(props: HeaderEnterpriseNameProps) {
  const classes = useStyles({});

  return (
    <div className={classes.root}>
      <div className={classes.textContent}>{props.currentEnterpriseName}</div>
    </div>
  );
}
