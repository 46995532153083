import { StyleRulesCallback, Theme } from "@material-ui/core/styles";
import { defaultFont } from "assets/jss/material-dashboard-react";

export const sidebarItemStyle: StyleRulesCallback<Theme, {}> = (theme) => ({
  root: {
    display: "flex",
    width: "100%",
  },
  item: {
    textDecoration: "none",
    flexGrow: 1,
    // TODO: I dont know if it is still relevant. Need to decide whether to keep it or to remove
    // position: 'relative',
    // display: 'block',
    // it appears that text decoration is already set to none by Material-UI so this change doesn't have any effect
  },
  itemLink: {
    height: "46px",
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: "8px",
    borderRadius: "6px",
    "&:hover": {
      backgroundColor: "#5dbde280", // blue[100], // '#2D80C2',
    },
  },
  itemLinkActive: {
    backgroundColor: "#5dbde233", // blue[50]
  },
  grouppedButtons: {
    "& button": {
      width: "100%",
    },
  },
  itemIcon: {
    width: "22px",
    minWidth: "24px",
    height: "22px",
    textAlign: "center",
    verticalAlign: "middle",
    color: theme.palette.text.secondary, // 'rgba(255, 255, 255, 0.8)', // TODO: replicate this to the icon
    // TODO: I dont know if it is still relevant. Need to decide whether to keep it or to remove
    // float: 'left',
    // marginRight: '15px',
  },
  configIcon: {
    // display: 'flex',
    // alignItems: 'center',
    /*float: 'right'*/
    color: theme.palette.text.secondary,
  },
  itemText: {
    ...defaultFont,
    margin: "0",
    marginLeft: "16px",
    lineHeight: "22px",
    fontSize: "14px",
    fontWeight: 500,
    color: theme.palette.text.primary, // '#FFFFFF',
    whiteSpace: "nowrap", // Very important property to avoid height extension on collapse,
    boxSizing: "border-box",
    width: "100px", // TODO: needed for ellipsisOnOverflow to work, but size isn't 100 for some reason
  },
  popupMenuItem: {
    marginLeft: "8px",
  },
  ellipsisOnOverflow: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
});
