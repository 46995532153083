import React from "react";

import { MainPanelWithParamsProps } from "components/Dashboard/MainPanelWithParams.d";
import { EntityEditRouteParams } from "components/Dashboard/EntityEditRouteParams.d";
import { SamplePointEditForm } from "./SamplePointEditForm"; // TODO: there's another class with the same name

export function SamplePointEditScreen(
  props: MainPanelWithParamsProps<EntityEditRouteParams & { assetId: number }>
) {
  return (
    <SamplePointEditForm
      id={props.match.params.id}
      action={props.match.params.action}
      assetId={props.match.params.assetId}
      showCloseButton={false}
      currentEnterpriseId={props.currentEnterpriseId}
      userData={props.userData}
    />
  );
}
