import React from "react";
import {
  MONITOR_API_URL,
  MONITOR_ENTITY_NAME,
  MONITOR_SUMMARY_ROUTE,
  MONITOR_SUMMARY_ACTION,
} from "views/hardware/Monitor/Monitor.const";
import Description from "@material-ui/icons/Description";
import { getAggregatedPageRoute, refineRequest } from "components/Utils/RouteUtils";
import { FilterMatchTypes, SORT_ASC, UnifiedDataRequestType } from "components/Utils/CRUDUtils.d";
import {} from "components/Utils/RouteUtils";
import { SimpleEnum } from "components/Crud/Table/CellRenderers/SimpleEnum";
import { TableCellRendererProps } from "components/Crud/Table/CellRenderers/TableCellRenderer.d";
import { ENTERPRISE_API_URL, ALL_ENTERPRISES_CODE } from "views/Enterprise/Enterprise.const";
import { SITE_API_URL } from "views/Site/Site.const";
import {
  BaseListProps,
  TableColumnParameters,
  TableColumnFilter,
  TableBodyLayoutType,
} from "components/Crud/Table/EntityTable.d";
import {
  generateShowButtonConfig,
  generateEditButtonConfig,
  generateCreateButtonConfig,
  generateDeleteButtonConfig,
} from "components/Buttons/ButtonsCommon";
import { translateValue, filterBy } from "components/Utils/MiscUtils";
import { DataSourceParamsType } from "components/Crud/DataProvider/DataProviderCommon.d";
import { CrudQueryTableBinding } from "components/Crud/Table/CrudQueryTableBinding";
import { useVersion } from "components/Utils/CustomHooks";
import { TableActionButtonFace } from "components/Buttons/TableActionButtonFace";
import { MonitorEditForm } from "./MonitorEditForm";
import { DateCellRenderer } from "components/Crud/Table/CellRenderers/DateCellRenderer";
import { mergeRecords } from "components/Utils/ObjectUtils";
import { COMMS_TYPE_OPTIONS } from "./Monitor.dict";

export function MonitorList(props: BaseListProps) {
  const [version, increaseVersion] = useVersion();

  const tableColumns: TableColumnParameters[] = [
    {
      sourceName: "name",
      label: "Name",
      width: 100,
    },
    {
      sourceName: "commsTypeId",
      label: "Comms Type",
      hideForBreakpoints: { mdDown: true },
      valueTransformationFunction: (rendererProps: TableCellRendererProps) => (
        <SimpleEnum options={COMMS_TYPE_OPTIONS} {...rendererProps} />
      ),
    },
    {
      sourceName: "commsAddress",
      label: "Address",
      width: 100,
      hideForBreakpoints: { mdDown: true },
    },
    {
      sourceName: "lastPacketLog[0].dateReceived",
      label: "Last Packet Received",
      hideForBreakpoints: { mdDown: true },
      valueTransformationFunction: (rendererProps: TableCellRendererProps) => (
        <DateCellRenderer userFriendlyFormat={true} {...rendererProps} />
      ),
    },
    {
      sourceName: "site.enterprise.name",
      label: "Enterprise",
      hide: props.currentEnterpriseId !== ALL_ENTERPRISES_CODE,
      hideForBreakpoints: { mdDown: true }, // TODO: hide for super admin
      width: 150,
    },
    {
      sourceName: "site.name",
      label: "Site",
      hideForBreakpoints: { mdDown: true },
      width: 150,
    },
    {
      disableSorting: true,
      actionSet: [
        {
          labelElement: (
            <TableActionButtonFace>
              <Description />
              &nbsp;<span>Summary</span>
            </TableActionButtonFace>
          ),
          name: "monitorSummary",
          onClick: {
            type: "navigate",
            url: getAggregatedPageRoute(MONITOR_SUMMARY_ROUTE),
            paramName: "monitorId",
          },
          actionCode: MONITOR_SUMMARY_ACTION,
          actionObject: MONITOR_ENTITY_NAME,
          doNotGroup: true,
        },
        generateEditButtonConfig(MONITOR_ENTITY_NAME, MonitorEditForm, [increaseVersion], {
          title: "Edit Monitor Details",
        }),
        generateShowButtonConfig(MONITOR_ENTITY_NAME, MonitorEditForm, { title: "Show Monitor Details" }),
        generateDeleteButtonConfig(MONITOR_ENTITY_NAME),
      ],
    },
  ];

  const uiFilters: Array<TableColumnFilter> = [
    {
      filterType: "text",
      filterDataSourceField: "commsAddress",
      filterMatchType: FilterMatchTypes.CONTAINS,
      filterParams: {
        placeholder: "Address (ESN)",
      },
    },
    {
      disable: props.currentEnterpriseId !== ALL_ENTERPRISES_CODE,
      filterType: "select-async",
      filterParams: {
        optionDefinitionEndpoint: ENTERPRISE_API_URL,
        optionCodeField: "id",
        optionLabelField: "name",
        renderEmptyValueAs: "ALL ENTERPRISES",
        autoComplete: true,
        placeholder: "ALL ENTERPRISES",
      },
      filterDataSourceField: "site.enterpriseId", // Because column uses name, but filter uses id
    },
    {
      filterType: "select-async",
      filterParams: {
        optionDefinitionEndpoint: SITE_API_URL,
        optionCodeField: "id",
        optionLabelField: "name",
        renderEmptyValueAs: "ALL SITES",
        filterUrl:
          props.currentEnterpriseId !== ALL_ENTERPRISES_CODE
            ? `?filter=enterpriseId::eq::${props.currentEnterpriseId}`
            : undefined,
        autoComplete: true,
        placeholder: "ALL SITES",
      },
      filterDataSourceField: "siteId", // Because column uses name, but filter uses id
    },
  ];

  const dataSource: DataSourceParamsType = {
    apiUrl: MONITOR_API_URL,
  };

  let initialRequest: UnifiedDataRequestType = {
    sortByColumn: "name",
    sortDirection: SORT_ASC,
    fieldList: ["name", "commsTypeId", "commsAddress", "site.enterprise.name", "site.name"],
    filter: {
      baseFilters: [
        filterBy(
          "site.enterpriseId",
          "=",
          translateValue(props.currentEnterpriseId, [[ALL_ENTERPRISES_CODE, undefined]])
        ),
      ],
    },
    isPaginationEnabled: true,
    rowsPerPage: 10,
    pageNum: 0,
  };

  // to use URL defined query params
  const refinedRequestFromInput = refineRequest(props.inputRequest, uiFilters);
  initialRequest = mergeRecords(initialRequest, refinedRequestFromInput) as UnifiedDataRequestType;

  const headerActions = [
    generateCreateButtonConfig(MONITOR_ENTITY_NAME, "ADD MONITOR", MonitorEditForm, [increaseVersion], {
      title: "Add Monitor",
    }),
  ];

  return (
    <CrudQueryTableBinding
      externalTriggerValues={[props.currentEnterpriseId, version]}
      dataSource={dataSource}
      intialQuery={initialRequest}
      title="Monitors"
      tableColumns={tableColumns}
      onRowClickFunction={props.onRowClickFunction}
      // actionsAllowed={allowedActions}
      highlightedRowsPrimaryKeys={props.activeRowPrimaryKeys}
      tableVariant={TableBodyLayoutType.FULL_WIDTH_HTML}
      enableModeSwitch={false}
      uiFilters={uiFilters}
      headerActions={headerActions}
      // to use URL defined query params
      allowUrlReplacementForFilters={props.allowUrlReplacementForFilters}
      allowReinitialization={props.allowReinitialization}
    />
  );
}
