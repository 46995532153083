import { StyleRulesCallback, Theme } from "@material-ui/core";

export const mainScreenContentHeaderStyle: StyleRulesCallback<Theme, {}> = (theme) => ({
  root: {
    // paddingBottom: '21px'
  },
  subChildren: {
    height: "21px",
    display: "flex",
  },
  subTitleSection: {
    height: "21px",
  },
  contentRow: {
    display: "flex",
    // flexWrap: 'wrap',
    alignItems: "center",
  },
  contentRowLargeScreen: {
    lineHeight: "40px",
  },
  contentRowSmallScreen: {
    lineHeight: "32px",
  },
});
